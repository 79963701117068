//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import classNames             from 'classnames';
import _                      from 'lodash';
import { DropTarget }         from 'react-dnd';
import { DragSource }         from 'react-dnd';
import { connect }            from 'react-redux';
import { compose }            from 'redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions }  from '@slices/activeProject';
import { DragDropActions }       from '@slices/dragDrop';
import AccessoryTypes            from '@constants/AccessoryTypes';
import DragTypes                 from '@constants/DragTypes';
import DropValidation            from '@constants/DropValidation';
import ProductCategoryType       from '@constants/ProductCategoryType';
import Routes                    from '@constants/Routes';
import SlotType                  from '@constants/SlotType';
import SelectionHelper           from '@helper/SelectionHelper';
import StateHelper               from '@helper/State';
import { DesignerLayoutActions } from '@slices/designerLayout';

import SfpTopLeftToBottomToRightCalculator     from './BoundsCalculators/SfpTopLeftToBottomToRight';
import SfpTopRightToBottomToLeftCalculator     from './BoundsCalculators/SfpTopRightToBottomToLeft';
import SfpLayout                               from './SfpLayout';
import SlotAlignment                           from './SlotAlignment';
import styles                                  from './styles.module.scss';
import dragDropConfiguration                   from '../../dragDrop/slot';
import DoubleSlotDropZone                      from '../DoubleSlotDropZone';
import ProductImageRenderer                    from '../ProductImageRenderer';
import PropTypes                               from '../PropTypes';
import ChassisLayout                           from '../SelectedProductImageRenderer/ChassisLayout';
import SelectedProductSlotImageNumbering       from '../SelectedProductSlotImageNumbering';
import SelectedProductSlotUniPortImageRenderer from '../SelectedProductSlotUniPortImageRenderer';
import slot                                    from '../Slot';
import SlotOverlay                             from '../SlotOverlay';
import TextDropZone                            from '../TextDropZone';
import CornerStyle                             from '../TextDropZone/CornerStyle';

class Component extends React.Component {
    viewReference = null;

    componentWillUnmount() {
        if (this.viewReference) {
            this.viewReference.removeEventListener('mousedown', this.stopEventPropagation, false);
            this.viewReference.removeEventListener('touchstart', this.stopEventPropagation, false);
        }
    }

    getMainStyle = () => {
        const y     = this.props.y * this.props.scale;
        const style = {
            left:   this.props.x * this.props.scale,
            height: this.props.height * this.props.scale,
            width:  this.props.width * this.props.scale,
        };

        switch (this.props.alignTo) {
            // @formatter:off
            case SlotAlignment.bottom: style.bottom = y;

                break;
            case SlotAlignment.top:    style.top = y;

                break;
            // @formatter:on
        }

        if (this.props.chassisLayout === ChassisLayout.vertical) {
            style.transformOrigin = 'top left';
            style.transform       = SelectionHelper.get(
                this.props.categoryType,
                {
                    [ProductCategoryType.extender]: 'rotate(90deg)',
                    [ProductCategoryType.matrix]:   'rotate(-90deg)',
                },
            );
        }

        return style;
    };

    render() {
        if (!this.props.isUnused) {
            const hasProduct           = this.props.hasProduct();
            const mainStyle            = this.getMainStyle();
            const data                 = _.get(this, 'props.data');
            const productData          = _.get(data, 'productData');
            const productImageRenderer = (
                productData ?
                    (
                        <ProductImageRenderer
                            enableSlotImageWhitespaceWorkaround={true}
                            isReadOnly={false}
                            productData={productData}
                            scale={this.props.scale}
                        >
                            {this.renderSelectedOverlay()}
                            {
                                hasProduct
                                    ? (
                                        <SlotOverlay
                                            slotData={data}
                                        />
                                    )
                                    : null
                            }
                        </ProductImageRenderer>
                    )
                    : null
            );

            let baseObject = (
                <div
                    className={styles.selectedProductSlotImageRenderer}
                    onClick={this.props.slotClicked}
                    style={mainStyle}
                >
                    {
                        hasProduct && this.props.dragSourceEnabled ?
                            this.props.connectDragSource(
                                <div>
                                    {productImageRenderer}
                                </div>,
                                this.props.getDragOptions(this.props.isLocked),
                            ) :
                            productImageRenderer

                    }
                    {
                        this.props.pathname.startsWith(Routes.designerProductDetails)
                            ? (
                                <SelectedProductSlotImageNumbering
                                    categoryType={this.props.categoryType}
                                    chassisLayout={this.props.chassisLayout}
                                    column={this.props.column}
                                    index={this.props.visibleIndex}
                                    isCpu={this.props.isCpu}
                                    row={this.props.row}
                                    scale={this.props.scale}
                                    slotType={this.props.type}
                                    warnings={this.props.data.warnings}
                                />
                            )
                            : null
                    }
                    {this.renderUniPortImageRenderer()}
                    {this.renderDropZones()}
                </div>
            );

            // This should be removed
            // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-441
            if (
                !this.props.isLocked &&
                !this.props.isDoubleSlot &&
                this.props.dropValidation === DropValidation.allowed
            ) {
                baseObject = this.props.connectDropTarget(baseObject);
            }

            if (hasProduct) {
                // We need to wrap this here to prevent mousedown and touchdown events to
                // get passed "upper" to the PanAndZoomControl since we dont want to pan if the user
                // drags a slot around.
                //
                // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-167
                return (
                    <div ref={this.setViewReference}>
                        {baseObject}
                    </div>
                );
            }

            return baseObject;
        }

        return null;
    }

    renderDefaultDropZone = () => {
        if (!this.props.nextSlotIsDoubleSlot) {
            return (
                <TextDropZone
                    canDrop={this.props.canDrop}
                    cornerStyle={CornerStyle.square}
                    dropValidation={this.props.dropValidation}
                    isOver={this.props.isOver}
                />
            );
        }
    };

    renderDoubleSlotDropZone = () => {
        if (this.props.isDoubleSlot) {
            return [
                <div
                    className={classNames(
                        styles.selectedProductSlotImageRendererDoubleSlotDropZoneWrapper,
                        styles.selectedProductSlotImageRendererDoubleSlotDropZoneWrapperTop,
                        (
                            this.props.canDrop ?
                                styles.selectedProductSlotImageRendererDoubleSlotDropZoneWrapperDragging :
                                null
                        ),
                    )}
                    key={'dropZoneTop'}
                >
                    <DoubleSlotDropZone
                        accessoryType={this.props.accessoryType}
                        data={this.props.data}
                        dropValidationForward={this.props.dropValidation}
                        index={this.props.index}
                        isLocked={this.props.isLocked}
                        type={this.props.type}
                    />
                </div>,
                <div
                    className={classNames(
                        styles.selectedProductSlotImageRendererDoubleSlotDropZoneWrapper,
                        styles.selectedProductSlotImageRendererDoubleSlotDropZoneWrapperBottom,
                        (
                            this.props.canDrop ?
                                styles.selectedProductSlotImageRendererDoubleSlotDropZoneWrapperDragging :
                                null
                        ),
                    )}
                    key={'dropZoneBottom'}
                >
                    <DoubleSlotDropZone
                        accessoryType={this.props.accessoryType}
                        data={this.props.data}
                        dropValidationForward={this.props.dropValidationPrevious}
                        index={this.props.index - 1}
                        isLocked={this.props.isLocked}
                        type={this.props.type}
                    />
                </div>,
            ];
        }

        return null;
    };

    renderDropZones = () => {
        if (this.props.isDoubleSlot) {
            return this.renderDoubleSlotDropZone();
        }

        return this.renderDefaultDropZone();
    };

    renderSelectedOverlay = () => {
        return (
            <div
                className={classNames(
                    styles.selectedProductSlotImageRendererSelectedOverlay,
                    (
                        this.props.isSelected() ?
                            styles.selectedProductSlotImageRendererSelectedOverlayVisible :
                            null
                    ),
                )}
            />
        );
    };

    renderUniPortImageRenderer = () => {
        const configurableSfpCount = _.get(this, 'props.data.productData.configurableSfpCount', 0);

        if (configurableSfpCount > 0) {
            const sfpPorts         = [];
            const productData      = this.props.data.productData;
            const layoutDefinition = productData.layoutDefinition;

            for (let sfpIndex = 0; sfpIndex < configurableSfpCount; ++sfpIndex) {
                const sfpIndexStartingBy1 = sfpIndex + 1;

                if (this.props.data.sfps) {
                    const currentSfp = this.props.data.sfps[sfpIndex];
                    let sfpBounds    = null;

                    switch (layoutDefinition.layout) {
                        case SfpLayout.topLeftToBottomToRight:
                            sfpBounds = SfpTopLeftToBottomToRightCalculator.getBounds(
                                sfpIndexStartingBy1,
                                layoutDefinition,
                                this.props.chassisLayout,
                            );

                            break;

                        case SfpLayout.topRightToBottomToLeft:
                            sfpBounds = SfpTopRightToBottomToLeftCalculator.getBounds(
                                sfpIndexStartingBy1,
                                layoutDefinition,
                                this.props.chassisLayout,
                            );

                            break;
                    }

                    sfpPorts.push(<SelectedProductSlotUniPortImageRenderer
                        chassisLayout={this.props.chassisLayout}
                        data={currentSfp}
                        dropEnabled={this.props.isSelected()}
                        height={sfpBounds.height}
                        index={sfpIndex}
                        scale={this.props.scale}
                        slotData={this.props.data}
                        width={sfpBounds.width}
                        x={sfpBounds.x}
                        y={sfpBounds.y}
                    />);
                }
            }

            return sfpPorts;
        }

        return null;
    };

    setViewReference = (reference) => {
        this.viewReference = reference;

        if (reference) {
            reference.addEventListener('mousedown', this.stopEventPropagation, false);
            reference.addEventListener('touchstart', this.stopEventPropagation, false);
        }
    };

    stopEventPropagation = (event) => {
        event.stopPropagation();
    };
}

Component.propTypes = {
    accessoryType:        PropTypes.oneOf(Object.values(AccessoryTypes)),
    alignTo:              PropTypes.oneOf(Object.values(SlotAlignment)),
    categoryType:         PropTypes.oneOf(Object.values(ProductCategoryType)),
    chassisLayout:        PropTypes.oneOf(Object.values(ChassisLayout)),
    data:                 PropTypes.object,
    dragSourceEnabled:    PropTypes.bool,
    getDragOptions:       PropTypes.func,
    hasProduct:           PropTypes.func,
    height:               PropTypes.number,
    index:                PropTypes.number,
    isCpu:                PropTypes.bool,
    isDoubleSlot:         PropTypes.bool,
    isLocked:             PropTypes.bool,
    isSelected:           PropTypes.func,
    isUnused:             PropTypes.bool,
    nextSlotIsDoubleSlot: PropTypes.bool,
    scale:                PropTypes.number,
    slotClicked:          PropTypes.func,
    type:                 PropTypes.oneOf(Object.values(SlotType)),
    visibleIndex:         PropTypes.number,
    width:                PropTypes.number,
    x:                    PropTypes.number,
    y:                    PropTypes.number,
};

Component.defaultProps = {
    accessoryType:        null,
    alignTo:              '',
    categoryType:         null,
    chassisLayout:        '',
    data:                 {},
    dragSourceEnabled:    true,
    getDragOptions:       _.noop,
    hasProduct:           _.noop,
    height:               10,
    index:                10,
    isCpu:                false,
    isDoubleSlot:         false,
    isLocked:             false,
    isSelected:           _.noop,
    isUnused:             false,
    nextSlotIsDoubleSlot: false,
    scale:                1,
    slotClicked:          _.noop,
    type:                 SlotType.slot,
    visibleIndex:         10,
    width:                10,
    x:                    1,
    y:                    1,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(
    ActiveProjectActions,
    DesignerLayoutActions,
    DragDropActions,
), dispatch);

const mapStateToProps = (state) => (
    {
        draggingActionActive: state.dragDrop.isDragging,
        pathname:             state.router.location.pathname,
        selectedProduct:      StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
        selectedSlot:         state.activeProject.selectedSlot,
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    DropTarget(
        [
            DragTypes.add,
            DragTypes.sort,
        ],
        dragDropConfiguration.dropBehavior,
        dragDropConfiguration.dropCollect,
    ),
    DragSource(
        DragTypes.sort,
        dragDropConfiguration.dragBehavior,
        dragDropConfiguration.dragCollect,
    ),
)(slot(Component));

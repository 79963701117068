//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                from 'i18next';
import _                   from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect }         from 'react-redux';

import Routes from '@constants/Routes';

import faqData            from '../../data/faq';
import LeftPaneLinkButton from '../LeftPaneLinkButton';

const Component = () => {
    function renderItems() {
        const items = [];

        for (const faqDataEntry of faqData) {
            const titleLanguageKey = `help${_.startCase(faqDataEntry.index).replace(/ /g, '')}Title`;

            items.push(<LeftPaneLinkButton
                key={faqDataEntry.index}
                label={I18n.t(titleLanguageKey)}
                route={`${Routes.helpDetails}/${faqDataEntry.index}`}
            />);
        }

        return items;
    }

    return (
        <div>
            {renderItems()}
        </div>
    );
};

Component.propTypes = {};

Component.defaultProps = {};

export default connect(
    null,
    null,
)(withTranslation()(Component));

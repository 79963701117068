//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames             from 'classnames';
import I18n                   from 'i18next';
import _                      from 'lodash';
import { DropTarget }         from 'react-dnd';
import { DragSource }         from 'react-dnd';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { compose }            from 'redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import { DragDropActions } from '@slices/dragDrop';
import { SmartActions }    from '@slices/smart';
import DragTypes           from '@constants/DragTypes';
import StateHelper              from '@helper/State';

import styles                       from './styles.module.scss';
import dragDropConfiguration        from '../../dragDrop/product';
import Icon                         from '../Icon';
import IconType                     from '../Icon/IconType';
import ProductInformationDrawer     from '../ProductInformationDrawer';
import ProductInformationDrawerMode from '../ProductInformationDrawer/ProductInformationDrawerMode';
import ProductTitle                 from '../ProductTitle';
import PropTypes                    from '../PropTypes';
import TextDropZone                 from '../TextDropZone';
import CornerStyle                  from '../TextDropZone/CornerStyle';

class Component extends React.Component {
    duplicateButtonPressed = (event) => {
        this.props.duplicateSelectedProduct({
            confirmed: false,
        });

        event.preventDefault();
        event.stopPropagation();
    };

    deleteButtonPressed = (event) => {
        this.props.deleteProduct({
            categoryType: this.props.selectedProduct.categoryType,
            id:           this.props.selectedProduct.id,
        });

        event.preventDefault();
        event.stopPropagation();
    };

    productClicked = () => {
        if (this.props.slotIsSelected) {
            // We just have to deselect the slot since the product is already
            // in "selectedProduct"
            this.props.deselectSlot();
        } else {
            this.props.setRightPaneSetVisibility({
                visible: true,
            });
        }
    };

    render() {
        return this.props.connectDragSource(this.props.connectDropTarget(<div
            className={classNames(
                styles.productDetailItemDetails,
                (
                    !this.props.slotIsSelected ?
                        styles.productDetailItemDetailsSelected :
                        null
                ),
            )}
            onClick={this.productClicked}
        >
            {this.renderDropZone()}
            <ProductTitle
                customName={this.props.selectedProduct.customName}
                productData={this.props.selectedProduct.productData}
            />
            {this.renderProductSpecifications()}
        </div>));
    }

    renderDropZone = () => {
        return (
            <TextDropZone
                canDrop={this.props.canDrop}
                cornerStyle={CornerStyle.square}
                extraStyle={styles.productDetailItemInformationDropZoneExtraStyle}
                isOver={this.props.isOver}
            />
        );
    };

    renderProductSpecifications = () => {
        return (
            <ProductInformationDrawer
                mode={ProductInformationDrawerMode.full}
                product={this.props.selectedProduct}
                wrapperClassName={styles.productDetailItemInformationWrapper}
            >
                <div className={styles.productDetailItemInformationActions}>
                    <a
                        className={styles.productDetailItemDuplicateButton}
                        onClick={this.duplicateButtonPressed}
                        title={I18n.t('duplicateExtender')}
                    >
                        <Icon iconType={IconType.duplicate} />
                    </a>
                    <a
                        className={styles.productDetailItemDeleteButton}
                        onClick={this.deleteButtonPressed}
                    >
                        <Icon iconType={IconType.trashBin} />
                    </a>
                </div>
            </ProductInformationDrawer>
        );
    };
}

Component.propTypes = {
    data: PropTypes.object,
};

Component.defaultProps = {
    data: {},
};

const mapStateToProps = (state) => {
    return {
        selectedProduct:             StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct) || {},
        selectedProductCategoryType: _.get(state, 'activeProject.selectedProduct.categoryType'),
        slotIsSelected:              _.get(state, 'activeProject.selectedSlot.index', null) !== null,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(
    ActiveProjectActions,
    DragDropActions,
    SmartActions,
), dispatch);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    DropTarget(
        [
            DragTypes.add,
            DragTypes.sort,
        ],
        dragDropConfiguration.dropBehavior,
        dragDropConfiguration.dropCollect,
    ),
    DragSource(
        DragTypes.sort,
        dragDropConfiguration.dragBehavior,
        dragDropConfiguration.dragCollect,
    ),
)(withTranslation()(Component));

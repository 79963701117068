//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ReactTooltip from 'react-tooltip';
import { put }      from 'redux-saga/effects';
import { select }   from 'redux-saga/effects';

import Routes                    from '@constants/Routes';
import { DesignerLayoutActions } from '@slices/designerLayout';

const locationChange = function* () {
    requestAnimationFrame(ReactTooltip.rebuild);

    const designerLayout = yield select((state) => state.designerLayout);
    const location       = yield select((state) => state.router.location);

    if (location.pathname === Routes.designerProjectDetails && !designerLayout.rightPaneVisible) {
        yield put(DesignerLayoutActions.setRightPaneSetVisibility({
            visible: true,
        }));
    }
};

export default {
    locationChange,
};

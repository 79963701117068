//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

import WarningType      from '@constants/WarningType';
import LimitationHelper from '@helper/Limitations';

import ActiveProjectsFactory from '../factories/activeProjects';

class limitationShowPowerConsumptionThresholdWarning {
    static updateProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        productWarnings,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        if (product.productData.recommendedPowerConsumptionInMilliamps < powerConsumptionInMilliamps) {
            if (!LimitationHelper.productHasFanWithChangeSet(changeSet, categoryType, productIndex, product)) {
                productWarnings.push(ActiveProjectsFactory.getWarning(
                    WarningType.information,
                    I18n.t('limitationShowPowerConsumptionThresholdWarning'),
                ));

                return true;
            }
        }

        return false;
    };

    static updateSubProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        subProduct,
        subProductIndex,
        productWarnings,
        subProductWarnings,
        subProductCategory,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        return false;
    };
}

export default limitationShowPowerConsumptionThresholdWarning;

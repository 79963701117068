import React from 'react';

import PropTypes from '../PropTypes';
import SerialLabel from '../SerialLabel';
import SerialLabelAlignment from '../SerialLabel/SerialLabelAlignment';

const Component = ({ value }) => (
    <SerialLabel
        alignment={SerialLabelAlignment.left}
        serial={value}
    />
);

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

export default Component;

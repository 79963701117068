//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import Overlays                 from '@constants/Overlays';
import { ActiveProjectActions } from '@slices/activeProject';
import Overlay                  from '@components/Overlay';
import OverlayType              from '@components/Overlay/OverlayType';
import TextHeadline             from '@components/TextHeadline';

const Component = ({
   allowDontShowAgain,
   ...props
}) => {
    const title = I18n.t('hitLimitationOverlayTitle');
    const text  = I18n.t('hitLimitationOverlayText');

    return (
        <Overlay
            allowDontShowAgain={allowDontShowAgain}
            id={Overlays.hitLimitation}
            title={title}
            overlayType={OverlayType.dialog}
            {...props}
        >
            <TextHeadline text={text} />
        </Overlay>
    );
};

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default connect(
    null,
    mapDispatchToProps,
)(withTranslation()(Component));

import React from 'react';

import DeviceTypeLabel from '../DeviceTypeLabel';
import DeviceTypeLabelAlignment from '../DeviceTypeLabel/DeviceTypeLabelAlignment';
import PropTypes from '../PropTypes';

const Component = ({ value }) => (
    <DeviceTypeLabel
        alignment={DeviceTypeLabelAlignment.left}
        deviceType={value}
    />
);

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import SlotType                 from '@constants/SlotType';
import TestIds                  from '@constants/TestIds';
import Cast                     from '@helper/Cast';
import StateHelper              from '@helper/State';

import styles          from './styles.module.scss';
import SlotSfpListSlot from '../SlotSfpListSlot';

class Component extends React.Component {
    renderSlots = () => {
        const slots      = [];
        const totalCount = _.get(this, 'props.selectedSlot.productData.configurableSfpCount', 0);
        const sfps       = _.get(this, 'props.selectedSlot.sfps') || {};

        for (const sfpIndex in sfps) {
            if (!sfps.hasOwnProperty(sfpIndex)) {
                continue;
            }

            const sfpIndexInteger = Cast.int(sfpIndex);
            const currentSlot     = sfps[sfpIndex];

            slots.push(<SlotSfpListSlot
                data={currentSlot}
                key={`slot${sfpIndex}`}
                index={sfpIndexInteger}
                totalCount={totalCount}
                type={SlotType.accessory}
            />);
        }

        return slots;
    };

    render() {
        return (
            <div
                data-testId={TestIds.slotSfpList}
                className={styles.slotSfpListWrapper}
            >
                <h3 className={styles.slotSfpListTitle}>
                    {I18n.t('sfpModules')}
                </h3>
                <div className={styles.slotSfpList}>
                    {this.renderSlots()}
                </div>
            </div>
        );
    }
}

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        selectedSlot: StateHelper.getSelectedSlot(state),
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

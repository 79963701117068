//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { Component } from 'react';
import React         from 'react';

import I18n       from 'i18next';
import { Helmet } from 'react-helmet';

import Header          from '@components/Header';
import TextContainer   from '@components/TextContainer';
import TextLong        from '@components/TextLong';
import TitleBanner     from '@components/TitleBanner';
import TitleBannerMode from '@components/TitleBanner/TitleBannerMode';
import PageTitleHelper from '@helper/PageTitle';

class Screen extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>{PageTitleHelper.getPageTitle(I18n.t('pageNotFoundPageTitle'))}</title>
                </Helmet>
                <Header />
                <TitleBanner
                    mode={TitleBannerMode.text}
                    text={I18n.t('pageNotFound')}
                />
                <TextContainer>
                    <TextLong text={I18n.t('pageNotFoundText')} />
                </TextContainer>
            </div>
        );
    }
}

export default Screen;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames             from 'classnames';
import _                      from 'lodash';
import { DragSource }         from 'react-dnd';
import { DropTarget }         from 'react-dnd';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { compose }            from 'redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import DragTypes                from '@constants/DragTypes';
import Limitations              from '@constants/Limitations';
import TestIds                  from '@constants/TestIds';
import Cast                     from '@helper/Cast';
import DataProvider             from '@helper/DataProvider';
import StateHelper              from '@helper/State';

import styles                from './styles.module.scss';
import dragDropConfiguration from '../../dragDrop/sfp';
import ProductQuickSelect    from '../ProductQuickSelect';
import PropTypes             from '../PropTypes';
import slotSfp               from '../SlotSfp';

class Component extends React.Component {
    getProductQuickSelectProducts = () => {
        const products                       = DataProvider.getMatrixFlexIoBoards();
        const productsWithAllowingLimitation = [];
        const selectedSlotLimitations        = _.get(this.props, 'selectedSlot.productData.limitations', []);
        const normalizedSubSlotIndex         = this.props.index + 1;

        for (const product of products) {
            for (const selectedSlotLimitation of selectedSlotLimitations) {
                if (selectedSlotLimitation.type === Limitations.limitToCardsInSlot) {
                    const splittedValue        = selectedSlotLimitation.value.split(',');
                    const limitationSlot       = Cast.int(splittedValue[0]);
                    const limitationPartNumber = splittedValue[1].trim();

                    if (
                        normalizedSubSlotIndex === limitationSlot &&
                        product.partNo === limitationPartNumber
                    ) {
                        productsWithAllowingLimitation.push(product);

                        break;
                    }
                }
            }
        }

        return productsWithAllowingLimitation;
    };

    getLabelForProduct = (product) => {
        const firstDataTransferItem = _.get(product, 'dataTransfer[0]', product.partNo);

        return firstDataTransferItem;
    };

    getSubSlotNumber = () => {
        return this.props.index + 1;
    };

    productQuickSelectChanged = (option) => {
        this.props.setSubSlot({
            productId:    option.value,
            subSlotIndex: this.props.index,
        });
    };

    render() {
        return (
            <div
                data-testId={TestIds.subSlotListSlot}
                className={classNames(
                    styles.subSlotListSlot,
                    (
                        this.props.index % 2 === 0 ?
                            styles.subSlotListSlotOdd :
                            styles.subSlotListSlotEven
                    ),
                )}
            >
                <div className={styles.subSlotListSlotWrapper}>
                    <div className={styles.slotSfpListSlotContent}>
                        <div className={styles.slotSfpListSlotNumber}>
                            {this.getSubSlotNumber()}
                        </div>
                        {this.renderTitle()}
                    </div>
                </div>
            </div>
        );
    }

    renderTitle = () => {
        const selectedSubSlot           = _.get(
            this.props.selectedSlot,
            [
                'subSlots',
                this.props.index,
            ],
        );
        const selectedSubSlotPartNumber = _.get(selectedSubSlot, 'productData.partNo');

        return (
            <div className={styles.subSlotListSlotTitleWrapper}>
                <span className={styles.subSlotListSlotTitleEmpty}>
                    <ProductQuickSelect
                        onChange={this.productQuickSelectChanged}
                        getLabelForProduct={this.getLabelForProduct}
                        products={this.getProductQuickSelectProducts()}
                        selectedPartNumber={selectedSubSlotPartNumber}
                    />
                </span>
            </div>
        );
    };
}

Component.propTypes = {
    index: PropTypes.number,
};

Component.defaultProps = {
    index: -1,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(ActiveProjectActions), dispatch);

const mapStateToProps = (state) => (
    {
        selectedProduct:     StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
        selectedProductMeta: state.activeProject.selectedProduct,
        selectedSlot:        StateHelper.getSelectedSlot(state),
        selectedSlotMeta:    state.activeProject.selectedSlot,
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    DropTarget(
        [
            DragTypes.add,
            DragTypes.sort,
        ],
        dragDropConfiguration.dropBehavior,
        dragDropConfiguration.dropCollect,
    ),
    DragSource(
        DragTypes.sort,
        dragDropConfiguration.dragBehavior,
        dragDropConfiguration.dragCollect,
    ),
)(slotSfp(withTranslation()(Component)));

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n          from 'i18next';
import isTouchDevice from 'is-touch-device';
import _             from 'lodash';

import styles from './styles.module.scss';
import Icon   from '../Icon';

const Component = ({
    hideIconForEmptyValue = false,
    icon                  = null,
    value                 = [],
    onIconMouseHover,
    onIconMouseLeave,
}) => {
    const getValue       = (currentValue) => {
        const valueIsArray = _.isArray(currentValue);

        if (
            currentValue &&
            (
                !valueIsArray || currentValue.length > 0
            )
        ) {
            return (
                valueIsArray ?
                    currentValue.join(', ') :
                    currentValue
            );
        }

        return I18n.t('emptyPlaceholderShort');
    };
    const iconMouseHover = () => {
        if (
            !isTouchDevice() &&
            onIconMouseHover
        ) {
            onIconMouseHover();
        }
    };
    const iconMouseLeave = () => {
        if (
            !isTouchDevice() &&
            iconMouseHover
        ) {
            onIconMouseLeave();
        }
    };
    const onClick        = () => {
        if (
            isTouchDevice() &&
            this.props.onIconMouseHover
        ) {
            iconMouseHover();
        }
    };
    const renderIcon     = () => {
        if (icon) {
            if (
                !value &&
                hideIconForEmptyValue
            ) {
                return null;
            }

            return (
                <span
                    className={styles.productDataValueLabelIconWrapper}
                    onClick={onClick}
                >
                    <Icon
                        iconType={icon}
                    />
                </span>
            );
        }

        return null;
    };
    const safeValue      = getValue(value);

    return (
        <span title={safeValue}>
            {renderIcon()}
            {safeValue}
        </span>
    );
};

export default Component;

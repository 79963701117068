import React from 'react';

import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ProductActions } from '@slices/product';
import { SmartActions }   from '@slices/smart';
import ProductSlotTypes   from '@constants/ProductSlotType';
import SlotType           from '@constants/SlotType';
import DataProvider       from '@helper/DataProvider';
import StateHelper        from '@helper/State';

import styles                 from './styles.module.scss';
import ProductQuickSelect     from '../ProductQuickSelect';
import ProductQuickSelectMode from '../ProductQuickSelect/ProductQuickSelectMode';
import PropTypes              from '../PropTypes';
import Tooltip                from '../Tooltip';
import TooltipTheme           from '../Tooltip/TooltipTheme';

const getProductQuickSelectProducts = (selectedProduct, selectedSlot, endOfLifeProductsVisible) => {
    const slots         = DataProvider.staticGetSlotProductsForProductCategoryTypeAndSlotType(
        _.get(selectedProduct, 'productData.categoryType', null),
        SlotType.slot,
        _.get(selectedProduct, 'productData.subCategoryType', null),
    );
    const powerSupplies = DataProvider.staticGetSlotProductsForProductCategoryTypeAndSlotType(
        _.get(selectedProduct, 'productData.categoryType', null),
        SlotType.powerSupplyUnit,
        _.get(selectedProduct, 'productData.subCategoryType', null),
    );
    const products      = _.concat(slots, powerSupplies);
    const releasedData  = DataProvider.getCompatibleProducts(
        products,
        endOfLifeProductsVisible,
        true,
        _.get(selectedProduct, 'productData.productSlotType') !== ProductSlotTypes.chassis,
        selectedProduct,
        selectedSlot,
        true,
    );

    return releasedData;
};

const productQuickSelectChanged = (props, smartAction, showIncompatibleProductOverlay, showEndOfLifeProductOverlay, showHitLimitationOverlay, option) => {
    const { isIncompatible, isEndOfLife, hitLimitation } = option.additionalData;

    if (isIncompatible) {
        return showIncompatibleProductOverlay(option.additionalData);
    }

    if (isEndOfLife) {
        return showEndOfLifeProductOverlay(option.additionalData);
    }

    if (hitLimitation) {
        return showHitLimitationOverlay(option.additionalData);
    }

    smartAction({
        productId:              option.value,
        triggeredByDoubleClick: true,
    });
};

const Component = ({
                       selectedProduct = {},
                       selectedProductMeta = {},
                       endOfLifeProductsVisible,
                       selectedSlot,
                       t,
                       smartAction,
                       showIncompatibleProductOverlay,
                       showEndOfLifeProductOverlay,
                       showHitLimitationOverlay,
                   }) => {
    return (
        <div className={styles.quickSearchWrapper}>
            <div className={styles.productQuickSearchWrapper}>
                <ProductQuickSelect
                    hideSingleValue={true}
                    keepFocusAfterChange={true}
                    mode={ProductQuickSelectMode.full}
                    onChange={(option) => productQuickSelectChanged({
                        selectedProduct,
                        selectedSlot,
                        endOfLifeProductsVisible,
                    }, smartAction, showIncompatibleProductOverlay, showEndOfLifeProductOverlay, showHitLimitationOverlay, option)}
                    placeholderKey={t('quickSearch')}
                    getProducts={() => getProductQuickSelectProducts(selectedProduct, selectedSlot, endOfLifeProductsVisible)}
                />
            </div>
            <div className={styles.tooltipWrapper}>
                <Tooltip
                    text={t('quickSearchTip')}
                    theme={TooltipTheme.orange}
                />
            </div>
        </div>
    );
};

Component.propTypes = {
    endOfLifeProductsVisible:       PropTypes.any,
    selectedProduct:                PropTypes.object,
    selectedProductMeta:            PropTypes.object,
    selectedSlot:                   PropTypes.any,
    showEndOfLifeProductOverlay:    PropTypes.func,
    showHitLimitationOverlay:       PropTypes.func,
    showIncompatibleProductOverlay: PropTypes.func,
    smartAction:                    PropTypes.func,
    t:                              PropTypes.func,
};

Component.defaultProps = {
    selectedProduct:     {},
    selectedProductMeta: {},
};

const mapStateToProps = (state) => (
    {
        endOfLifeProductsVisible: state.settings.endOfLifeProductsVisible,
        selectedProduct:          StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
        selectedProductMeta:      state.activeProject.selectedProduct,
        selectedSlot:             StateHelper.getSelectedSlot(state),
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(
    ProductActions,
    SmartActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';
import PropTypes  from 'prop-types';

import SelectionHelper from '@helper/SelectionHelper';

import TitleSubTitleButtonTheme from './TitleSubTitleButtonTheme';
import styles                   from './styles.module.scss';
import Icon                     from '../Icon';
import IconType                 from '../Icon/IconType';
import Link                     from '../Link';

const Component = ({
    iconType,
    onClick,
    route,
    subTitle,
    theme,
    disabled,
    title,
}) => {
    const getButtonStyles     = () => {
        return classNames(
            styles.titleSubTitleButton,
            SelectionHelper.get(theme, {
                [TitleSubTitleButtonTheme.default]: styles.titleSubTitleButtonDefault,
                [TitleSubTitleButtonTheme.orange]:  styles.titleSubTitleButtonOrange,
            }),
            {
                [styles.disabled]: disabled,
            },
        );
    };
    const buttonStyles        = getButtonStyles();
    const renderButtonContent = () => {
        return [
            <div
                className={styles.titleSubTitleButtonIcon}
                key={'icon'}
            >
                <Icon iconType={iconType} />
            </div>,
            <h5
                className={styles.titleSubTitleButtonSubTitle}
                key={'h5'}
            >
                {subTitle}
            </h5>,
            <h3
                className={styles.titleSubTitleButtonTitle}
                key={'h3'}
            >
                {title}
            </h3>,
        ];
    };
    const innerOnClick        = () => {
        if (disabled) {
            return;
        }

        onClick();
    };

    return route
        ? (
            <Link
                className={buttonStyles}
                to={route}
                disabled={disabled}
            >
                {renderButtonContent()}
            </Link>
        )
        : (
            <div
                className={buttonStyles}
                onClick={innerOnClick}
            >
                {renderButtonContent()}
            </div>
        );
};

Component.propTypes = {
    iconType: PropTypes.oneOf(Object.values(IconType)),
    onClick:  PropTypes.func,
    route:    PropTypes.string,
    subTitle: PropTypes.string,
    theme:    PropTypes.oneOf(Object.values(TitleSubTitleButtonTheme)),
    title:    PropTypes.string,
};

Component.defaultProps = {
    iconType: null,
    onClick:  _.noop,
    route:    '',
    subTitle: '',
    theme:    TitleSubTitleButtonTheme.default,
    title:    '',
};

export default Component;

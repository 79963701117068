//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

/**
 * @see This enum is also present in the frontend project src/Entity/ProductSlotType.php
 */
const ProductSlotTypes = {
    addOnModule:         'addOnModule',
    chassis:             'chassis',
    chassisCustomDesign: 'chassisCustomDesign',
    chassisAccessory:    'chassisAccessory',
    cpuBoard:            'cpuBoard',
    equipment:           'equipment',
    frontPlate:          'frontPlate',
    ioBoard:             'ioBoard',
    mainboard:           'mainboard',
    otherModule:         'otherModule',
    usb20Module:         'usb20Module',
    // Everything below relates to FullIP (FullHD and 4K)
    accessory:        'accessory',
    easyline:         'easyline',
    flexline:         'flexline',
    matrixManagement: 'matrixManagement',
    media4Connect:    'media4Connect',
    scalableline:     'scalableline',
    usb:              'usb',
};

module.exports = ProductSlotTypes;

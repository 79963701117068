//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import _ from 'lodash';

import SlotDefaultBounds from '@constants/SlotDefaultBounds';

import GapCalculator from './GapCalculator';
import SlotAlignment from '../../SelectedProductSlotImageRenderer/SlotAlignment';
import ChassisLayout from '../ChassisLayout';

const MatrixSlotDefaultBounds = SlotDefaultBounds.matrix;

const MatrixCompactSfpDefaultBounds = SlotDefaultBounds.matrixCompactSfp;

const MatrixFlexFrontPlateBounds = SlotDefaultBounds.matrixFlexFrontPlate;

/**
 * @see This file also exists in the backend src/BoundsCalculators/MatrixSlotLeftTopToRightBottom.php
 */
class MatrixSlotLeftTopToRightBottom {
    // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-621
    static getSlotBounds(productData) {
        if (_.get(productData, 'layoutDefinition.availableRowCount', 0) > 0) {
            return MatrixFlexFrontPlateBounds;
        }

        if (productData.uniDefinition) {
            return MatrixCompactSfpDefaultBounds;
        }

        return MatrixSlotDefaultBounds;
    }

    static getBoundsChassisLayoutHorizontalX = (bounds, layoutDefinition, productData) => {
        const slotBounds = this.getSlotBounds(productData);
        let x            = 0;

        // @formatter:off
        x -= 1;                                                                // Fix some rendering issues (@see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-298)
        x += layoutDefinition.start.x;                                         // Start position
        x += GapCalculator.getGap(layoutDefinition.gap.column, bounds.column); //
        x += (bounds.column - 1) * slotBounds.width;                           //
        // @formatter:on

        return x;
    };

    static getBoundsChassisLayoutHorizontalY = (bounds, layoutDefinition, productData) => {
        const slotBounds = this.getSlotBounds(productData);
        let y            = 0;

        // @formatter:off
        y += 3;                                                          // Fix some rendering issues (@see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-298)
        y += layoutDefinition.start.y;                                   // Start position
        y -= GapCalculator.getGap(layoutDefinition.gap.row, bounds.row); //
        y -= (bounds.row - 1) * slotBounds.height;                       //
        // @formatter:on

        return y;
    };

    static getCurrentColumnChassisLayoutHorizontal = (slotIndexStartingBy1, layoutDefinition) => {
        const currentColumn = Math.ceil(slotIndexStartingBy1 / layoutDefinition.rowCount);

        return currentColumn;
    };

    static getCurrentRowChassisLayoutHorizontal = (slotIndexStartingBy1, layoutDefinition) => {
        const currentRow = Math.ceil(slotIndexStartingBy1 / layoutDefinition.columnCount);

        return currentRow;
    };

    static getBounds = (slotIndexStartingBy1, layoutDefinition, productData) => {
        const bounds = this.getDefaultBounds(layoutDefinition, productData);

        switch (layoutDefinition.chassisLayout) {
            case ChassisLayout.horizontal:
                this.updateBoundsChassisLayoutHorizontal(
                    slotIndexStartingBy1,
                    layoutDefinition,
                    bounds,
                    productData,
                );

                break;

            case ChassisLayout.vertical:
                // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-412

                break;
        }

        return bounds;
    };

    static getDefaultBounds = (layoutDefinition, productData) => {
        const slotBounds = this.getSlotBounds(productData);

        return {
            alignTo:       SlotAlignment.top,
            chassisLayout: layoutDefinition.chassisLayout,
            height:        slotBounds.height,
            x:             0,
            y:             0,
            width:         slotBounds.width,
        };
    };

    static updateBoundsChassisLayoutHorizontal = (slotIndexStartingBy1, layoutDefinition, bounds, productData) => {
        bounds.column = this.getCurrentColumnChassisLayoutHorizontal(slotIndexStartingBy1, layoutDefinition);
        bounds.row    = this.getCurrentRowChassisLayoutHorizontal(slotIndexStartingBy1, layoutDefinition);
        bounds.x      = this.getBoundsChassisLayoutHorizontalX(bounds, layoutDefinition, productData);
        bounds.y      = this.getBoundsChassisLayoutHorizontalY(bounds, layoutDefinition, productData);
    };
}

export default MatrixSlotLeftTopToRightBottom;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import styles    from './styles.module.scss';
import PropTypes from '../PropTypes';
import Tooltip   from '../Tooltip';

const Component = ({
    children    = [],
    text        = '',
    tooltipText = '',
}) => {
    const hasText           = () => {
        return text;
    };
    const renderWithoutText = () => {
        return (
            <div className={styles.overlayPane}>
                <div className={classNames(styles.overlayPane, styles.overlayPaneNoText)}>
                    {children}
                </div>
            </div>
        );
    };
    const renderWithText    = () => {
        return (
            <div className={styles.overlayPane}>
                <div className={styles.overlayPaneLeft}>
                    <strong className={styles.overlayPaneLeftText}>
                        {text}
                    </strong>
                    {renderTooltip()}
                </div>
                <div className={classNames(styles.overlayPaneRight)}>
                    {children}
                </div>
            </div>
        );
    };
    const renderTooltip     = () => {
        if (tooltipText) {
            return (
                <Tooltip text={tooltipText} />
            );
        }

        return null;
    };

    if (hasText()) {
        return renderWithText();
    }

    return renderWithoutText();
};

Component.propTypes = {
    children:    PropTypes.children,
    text:        PropTypes.string,
    tooltipText: PropTypes.string,
};

Component.defaultProps = {
    children:    [],
    text:        '',
    tooltipText: '',
};

export default Component;

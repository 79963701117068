import React from 'react';

import { Tab } from 'react-tabs';

import styles from './styles.module.scss';
import PropTypes from '../PropTypes';

const Component = ({
    disabled = false,
    selected = false,
    testId   = '',
    text     = '',
}) => (
    <Tab
        data-testId={testId}
        className={styles.subLevelTabButton}
        disabled={disabled}
        disabledClassName={styles.subLevelTabButtonDisabled}
        selected={selected}
        selectedClassName={styles.subLevelTabButtonSelected}
    >
        <h3 className={styles.subLevelTabButtonText}>{text}</h3>
        <span className={styles.subLevelTabButtonArrow} />
    </Tab>
);

Component.tabsRole = 'Tab';

Component.propTypes = {
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    testId: PropTypes.string,
    text: PropTypes.string,
};

export default Component;

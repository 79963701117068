//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames             from 'classnames';
import _                      from 'lodash';
import { DragSource }         from 'react-dnd';
import { getEmptyImage }      from 'react-dnd-html5-backend';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';

import { DragDropActions } from '@slices/dragDrop';
import DragTypes           from '@constants/DragTypes';
import ProductCategoryType from '@constants/ProductCategoryType';
import ProductSlotType     from '@constants/ProductSlotType';

import styles                from './styles.module.scss';
import dragDropConfiguration from '../../dragDrop/tableRow';
import PropTypes             from '../PropTypes';

class Component extends React.Component {
    componentDidMount() {
        const { connectDragPreview } = this.props;

        if (connectDragPreview) {
            // This hides the default table row to make it possible to drag a custom
            // element using the drag view.
            connectDragPreview(getEmptyImage(), {
                captureDraggingState: true,
            });
        }
    }

    render() {
        const {
            connectDragSource,
            isDragging,
            productHitLimitation,
            productIsIncompatible,
            productIsEndOfLife,
        } = this.props;

        return connectDragSource(<div
            className={classNames(
                'productTableRow',
                productHitLimitation
                    ? styles.hitLimitation
                    : null,
                productIsIncompatible
                    ? styles.isIncompatible
                    : null,
                productIsEndOfLife
                    ? styles.isEndOfLife
                    : null,
                isDragging
                    ? 'dragging'
                    : null,
            )}
        >
            {this.props.children}
        </div>);
    }
}

Component.propTypes = {
    children:              PropTypes.children,
    productCategoryType:   PropTypes.oneOf(Object.values(ProductCategoryType)),
    productHitLimitation:  PropTypes.bool,
    productId:             PropTypes.string,
    productIsEndOfLife:    PropTypes.bool,
    productIsIncompatible: PropTypes.bool,
    productSlotType:       PropTypes.oneOf(Object.values(ProductSlotType)),
};

Component.defaultProps = {
    children:              [],
    productCategoryType:   null,
    productId:             '',
    productHitLimitation:  false,
    productIsIncompatible: false,
    productIsEndOfLife:    false,
    productSlotType:       null,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(DragDropActions), dispatch);

export default compose(
    connect(
        null,
        mapDispatchToProps,
    ),
    DragSource(
        DragTypes.add,
        dragDropConfiguration.dragBehavior,
        dragDropConfiguration.dragCollect,
    ),
)(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import { ActiveProjectActions } from '@slices/activeProject';
import AccessoryTypes           from '@constants/AccessoryTypes';
import ProductCategoryType      from '@constants/ProductCategoryType';
import ProductSlotType          from '@constants/ProductSlotType';
import Routes                   from '@constants/Routes';
import SlotType                 from '@constants/SlotType';
import AssemblyOrder            from '@helper/AssemblyOrder';
import Cast                     from '@helper/Cast';
import DataProvider             from '@helper/DataProvider';

import LimitationsCalculator      from '../calculators/limitations';
import PowerConsumptionCalculator from '../calculators/powerConsumption';
import { SmartActions }           from '@slices/smart';

const checkIfAllOddSlotsAreFilled = (slots) => {
    for (let index = 0; index < slots.length; index++) {
        const slot             = slots[index];
        const visibleSlotIndex = index + 1;

        // if the visible slot index is odd and there is no product in the slot then not all odd slots are filled
        if (visibleSlotIndex % 2 === 1 && !slot.productData) {
            return false;
        }
    }

    return true;
};

const findNextFreeSlotIndex = (
    selectedProductMeta,
    selectedProduct,
    slots,
    productId,
    slotType,
    singleOrderMode,
    assemblyOrder,
) => {
    let slotIsValid            = null;
    const slotProductData      = DataProvider.getById(productId);
    const allOddSlotsAreFilled = checkIfAllOddSlotsAreFilled(slots);

    for (const index in slots) {
        const slot = slots[index];

        // skip current slot when the order is 1.3.5
        if (assemblyOrder === AssemblyOrder.ORDER_135) {
            const slotIndex = Cast.int(index) + 1;

            // skip even slots except when all odd slots are filled
            if (!allOddSlotsAreFilled && slotIndex % 2 === 0) {
                continue;
            }
        }

        if (!slot.productData) {
            const indexInteger  = Cast.int(index);
            const nextSlotIndex = indexInteger + 1;

            if (slots.length > nextSlotIndex) {
                const nextSlot            = slots[nextSlotIndex];
                const nextSlotProductData = nextSlot.productData;

                if (nextSlotProductData && nextSlotProductData.heightUnits > 1) {
                    continue;
                }

                // Don't skip blind plates when we are just adding a sfp on a single slot
                if (slotProductData && slotProductData.type !== AccessoryTypes.sfp) {
                    const unusedSlots  = _.get(selectedProduct, 'productData.layoutDefinition.unused', []);
                    const slotIsUnused = unusedSlots.indexOf(nextSlotIndex) > -1;

                    if (slotIsUnused) {
                        // Skip the unused slot for the autofill
                        continue;
                    }
                }
            }

            // When a slot type is set we also have to validate if the next slot is a valid drop zone at all
            if (slotType) {
                const futureSelectedProduct    = _.cloneDeep(selectedProduct);
                const futureSelectedSubProduct = futureSelectedProduct.subProducts[slotType][indexInteger];

                if (futureSelectedSubProduct) {
                    futureSelectedSubProduct.productData              = slotProductData;
                    futureSelectedProduct.powerConsumptionInMilliAmps = PowerConsumptionCalculator.getPowerConsumption(
                        futureSelectedProduct,
                        futureSelectedProduct.subProducts,
                    );
                    slotIsValid                                       = LimitationsCalculator.slotIsValid(
                        futureSelectedProduct,
                        futureSelectedSubProduct,
                        futureSelectedProduct.powerConsumptionInMilliAmps,
                        selectedProductMeta.categoryType,
                        indexInteger,
                    );

                    if (!slotIsValid) {
                        continue;
                    }
                }
            }

            if (slotIsValid !== false) {
                return indexInteger;
            }

            return -1;
        }
    }

    // If only one slot of a type exists, we allow to overwrite it
    if (slots.length === 1 && !singleOrderMode) {
        return 0;
    }

    return -1;
};

const getSetSlotAction = (selectedProductMeta, product, type, productId, assemblyOrder) => {
    console.log(
        'smartActionRedirector: getSetSlotAction: selectedProductMeta, product, type, productId',
        selectedProductMeta,
        product,
        type,
        productId,
        assemblyOrder,
    );

    const nextSlotIndex = findNextFreeSlotIndex(
        selectedProductMeta,
        product,
        product.subProducts[type],
        productId,
        type,
        false,
        assemblyOrder,
    );

    if (nextSlotIndex > -1) {
        return ActiveProjectActions.setProductSlot({
            confirmed:       false,
            isBulk:          false,
            productId,
            productSlotType: product.productSlotType,
            slotIndex:       nextSlotIndex,
            targetSlotType:  type,
        });
    }

    return null;
};

const smartActionRedirector = (store) => (next) => (action) => {
    const { type, payload = {} }                     = action;
    const { productId, triggeredByDoubleClick } = payload;

    if (type === SmartActions.smartAction().type) {
        const state              = store.getState();
        const activeProjectState = state.activeProject;
        const singleOrderContext = window.location.pathname === Routes.designerProductSingleOrders;
        const detailPageOpened   = window.location.pathname.startsWith(Routes.designerProductDetails);
        const product            = DataProvider.getById(productId);
        const assemblyOrder      = state.settings.assemblyOrder;
        let nextAction           = null;

        // We are in the single order context
        if (singleOrderContext) {
            const categoryType             = product.categoryType;
            const existingSingleOrderIndex = _.findIndex(
                activeProjectState.singleOrders[categoryType],
                {
                    productData: {
                        partNo: productId,
                    },
                },
            );

            // Check if the product is already on the single order list
            if (existingSingleOrderIndex === -1) {
                const nextSingleSlotIndex = findNextFreeSlotIndex(
                    activeProjectState.selectedProduct,
                    null,
                    activeProjectState.singleOrders[categoryType],
                    null,
                    null,
                    true,
                );

                // Check whether we are running out of single order slots
                if (nextSingleSlotIndex === -1) {
                    nextAction = ActiveProjectActions.addSingleOrderSlot({
                        categoryType,
                        productId: productId,
                    });
                }
                // We sill have some single order slots
                else {
                    nextAction = ActiveProjectActions.setSingleOrderSlot({
                        categoryType,
                        productId:        productId,
                        singleOrderIndex: nextSingleSlotIndex,
                    });
                }
            }
            // The product is not on the single order list
            else {
                nextAction = ActiveProjectActions.increaseSingleOrderSlotAmount({
                    categoryType,
                    index: existingSingleOrderIndex,
                });
            }
        }
        // We are in the default designer mode
        else {
            const productData = DataProvider.getById(productId);

            // Only do something if the product category matches the active product category
            // or new product is selected
            if (
                productData.categoryType === activeProjectState.activeProductCategoryType &&
                (
                    productData.categoryType === activeProjectState.selectedProduct.categoryType ||
                    activeProjectState.selectedProduct.categoryType === null
                )
            ) {
                // No product is selected
                if (
                    !activeProjectState.selectedProduct ||
                    activeProjectState.selectedProduct.index === null ||
                    // Since we changed the ui to allow "soft selection" of an product,
                    // checking for "selectedProduct" is not clear enough to determine whether
                    // the user is on the detail page so we also check the url.
                    (
                        !detailPageOpened &&
                        triggeredByDoubleClick
                    )
                ) {
                    // Check whether the user dropped any chassis
                    if (product.productSlotType === ProductSlotType.chassis) {
                        nextAction = ActiveProjectActions.addProduct({
                            categoryType:    product.categoryType,
                            fromSmartAction: true,
                            productId:       productId,
                        });
                    }
                    // The user dropped anything but a chassis
                    else {

                    }
                }
                // A product is selected
                else {
                    // Check whether the user dropped any chassis
                    if (product.productSlotType === ProductSlotType.chassis) {
                        if (detailPageOpened) {
                            nextAction = ActiveProjectActions.setSelectedProductData({
                                productId: productId,
                            });
                        } else {
                            nextAction = ActiveProjectActions.addProduct({
                                categoryType:    product.categoryType,
                                fromSmartAction: true,
                                productId:       productId,
                            });
                        }
                    }
                    // The user dropped anything but a chassis
                    else {
                        const currentProduct = activeProjectState.products[activeProjectState.selectedProduct.categoryType][activeProjectState.selectedProduct.index];

                        if (product.productSlotType === ProductSlotType.chassisAccessory) {
                            if (product.type === AccessoryTypes.accessory) {
                                nextAction = getSetSlotAction(
                                    activeProjectState.selectedProduct,
                                    currentProduct,
                                    SlotType.accessory,
                                    productId,
                                    assemblyOrder,
                                );
                            } else if (product.type === AccessoryTypes.fan) {
                                nextAction = getSetSlotAction(
                                    activeProjectState.selectedProduct,
                                    currentProduct,
                                    SlotType.fan,
                                    productId,
                                    assemblyOrder,
                                );
                            } else if (product.type === AccessoryTypes.powerSupplyUnit) {
                                nextAction = getSetSlotAction(
                                    activeProjectState.selectedProduct,
                                    currentProduct,
                                    SlotType.powerSupplyUnit,
                                    productId,
                                    assemblyOrder,
                                );
                            } else if (product.type === AccessoryTypes.sfp) {
                                if (activeProjectState.selectedSlot.index !== null) {
                                    const currentSlot          = activeProjectState.products[activeProjectState.selectedProduct.categoryType][activeProjectState.selectedProduct.index].subProducts[activeProjectState.selectedSlot.slotType][activeProjectState.selectedSlot.index];
                                    const configurableSfpCount = _.get(
                                        currentSlot,
                                        'productData.configurableSfpCount',
                                        0,
                                    );

                                    if (configurableSfpCount > 0) {
                                        const nextSfpIndex = findNextFreeSlotIndex(
                                            activeProjectState.selectedProduct,
                                            currentProduct,
                                            currentSlot.sfps,
                                            productId,
                                            null,
                                        );

                                        if (nextSfpIndex > -1) {
                                            nextAction = ActiveProjectActions.setSfp({
                                                productId: productId,
                                                sfpIndex:  nextSfpIndex,
                                            });
                                        }
                                    }
                                }
                            } else if (product.categoryType === ProductCategoryType.fullIp) {
                                nextAction = getSetSlotAction(
                                    activeProjectState.selectedProduct,
                                    currentProduct,
                                    SlotType.slot,
                                    productId,
                                    assemblyOrder,
                                );
                            }
                        } else if (
                            product.productSlotType === ProductSlotType.mainboard ||
                            product.productSlotType === ProductSlotType.addOnModule ||
                            product.productSlotType === ProductSlotType.usb20Module ||
                            product.productSlotType === ProductSlotType.ioBoard ||
                            product.productSlotType === ProductSlotType.cpuBoard ||
                            product.productSlotType === ProductSlotType.otherModule ||
                            product.productSlotType === ProductSlotType.frontPlate
                        ) {
                            nextAction = getSetSlotAction(
                                activeProjectState.selectedProduct,
                                currentProduct,
                                SlotType.slot,
                                productId,
                                assemblyOrder,
                            );
                        }
                    }
                }
            }
        }

        if (nextAction) {
            console.log('smartActionRedirector nextAction', nextAction);

            next(nextAction);

            return;
        }

        console.warn('Unhandled smart action', action, activeProjectState, product);
    }

    next(action);
};

export default smartActionRedirector;

import { createSlice }        from '@reduxjs/toolkit';
import { bindActionCreators } from 'redux';
import update                 from 'immutability-helper';
import TabIndices             from '@constants/TabIndices';

const initialState = {
    activeMainTabIndex:       TabIndices.mainExtenderTabVario,
    activeSubTabCompactIndex: TabIndices.subTabCompactChassis,
    activeSubTabVarioIndex:   TabIndices.subTabVarioChassisAccessories,
};

const extenderProductBrowserSlice = createSlice({
    name:     'extenderProductBrowser',
    initialState,
    reducers: {
        setActiveSubTabCompactIndex: (state, action) => {
            return update(state, {
                activeSubTabCompactIndex: {
                    $set: action.payload.activeSubTabCompactIndex,
                },
            });
        },
        setActiveMainTabIndex:       (state, action) => {
            return update(state, {
                activeMainTabIndex: {
                    $set: action.payload.activeMainTabIndex,
                },
            });
        },
        setActiveSubTabVarioIndex:   (state, action) => {
            return update(state, {
                activeSubTabVarioIndex: {
                    $set: action.payload.activeSubTabVarioIndex,
                },
            });
        },
        resetAll:              (state) => {
            return initialState;
        },
    },
});

export const ExtenderProductBrowserActions = extenderProductBrowserSlice.actions;

export const ExtenderProductBrowserReducer = extenderProductBrowserSlice.reducer;

export const useExtenderProductBrowser = (dispatch) => bindActionCreators(ExtenderProductBrowserActions, dispatch);

export default extenderProductBrowserSlice;

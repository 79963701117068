import React from 'react';

import I18n                   from 'i18next';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ProjectsActions } from '@slices/projects';

import styles from './styles.module.scss';
import ColorButton         from '../ColorButton';
import ColorButtonTheme    from '../ColorButton/ColorButtonTheme';
import IconType            from '../Icon/IconType';

const Component = ({
    newProject,
}) => {
    const newProjectButtonPressed = () => {
        newProject();
    };

    return (
        <li
            className={styles.projectListEmptyItem}
        >
            <h3>{I18n.t('noProjectsTitle')}</h3>
            <p>{I18n.t('noProjectsText')}</p>
            <ColorButton
                icon={IconType.plus}
                onClick={newProjectButtonPressed}
                text={I18n.t('newProject')}
                theme={ColorButtonTheme.orange}
            />
        </li>
    );
};

Component.propTypes = {};

Component.defaultProps = {};

const mapDispatchToProps = (dispatch) => bindActionCreators(ProjectsActions, dispatch);

export default connect(
    null,
    mapDispatchToProps,
)(withTranslation()(Component));

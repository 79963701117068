//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import _                      from 'lodash';
import { DragSource }         from 'react-dnd';
import { DropTarget }         from 'react-dnd';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';

import { ActiveProjectActions }  from '@slices/activeProject';
import { DragDropActions }       from '@slices/dragDrop';
import DragTypes                 from '@constants/DragTypes';
import DropValidation            from '@constants/DropValidation';
import StateHelper               from '@helper/State';
import { DesignerLayoutActions } from '@slices/designerLayout';

import MarkerOrientationCalculator from './BoundsCalculators/MarkerOrientationCalculator';
import styles                      from './styles.module.scss';
import dragDropConfiguration       from '../../dragDrop/sfp';
import ProductImageRenderer        from '../ProductImageRenderer';
import PropTypes                   from '../PropTypes';
import ChassisLayout               from '../SelectedProductImageRenderer/ChassisLayout';
import slotSfp                     from '../SlotSfp';
import TextDropZone                from '../TextDropZone';
import CornerStyle                 from '../TextDropZone/CornerStyle';

class Component extends React.Component {
    viewReference = null;

    componentWillUnmount() {
        if (this.viewReference) {
            this.viewReference.removeEventListener('mousedown', this.stopEventPropagation, false);
            this.viewReference.removeEventListener('touchstart', this.stopEventPropagation, false);
        }
    }

    getMainStyle = () => {
        const y     = this.props.y * this.props.scale;
        const style = {
            left:      this.props.x * this.props.scale,
            height:    this.props.height * this.props.scale,
            width:     this.props.width * this.props.scale,
            top:       y,
            transform: this.getTransform(),
        };

        return style;
    };

    getTransform = () => {
        const markerOrientations = _.get(this.props.slotData, 'productData.layoutDefinition.markerOrientation', []);
        const markerOrientation  = MarkerOrientationCalculator.getMarkerOrientation(
            markerOrientations,
            this.props.index,
        );
        const transformAngle     = (
            markerOrientation === 'top' ?
                90 :
                -90
        );
        const transformString    = `rotate(${transformAngle}deg)`;

        return transformString;
    };

    render() {
        const hasProduct           = this.props.hasProduct();
        const mainStyle            = this.getMainStyle();
        const productImageRenderer = (
            this.props.data.productData ?
                (
                    <ProductImageRenderer
                        enableSlotImageWhitespaceWorkaround={false}
                        isReadOnly={false}
                        productData={this.props.data.productData}
                        scale={this.props.scale}
                    />
                )
                : null
        );

        let baseObject = (
            <div
                className={styles.selectedProductSlotUniPortImageRenderer}
                style={mainStyle}
            >
                {
                    hasProduct &&
                    this.props.selectedSlotMeta.index !== null ?
                        this.props.connectDragSource(<div>
                            {productImageRenderer}
                        </div>) :
                        productImageRenderer
                }
                {this.renderDropZone()}
            </div>
        );

        if (this.props.dropEnabled) {
            baseObject = this.props.connectDropTarget(baseObject);
        }

        if (hasProduct) {
            // We need to wrap this here to prevent mousedown and touchdown events to
            // get passed "upper" to the PanAndZoomControl since we dont want to pan if the user
            // drags a slot around.
            //
            // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-167
            return (
                <div ref={this.setViewReference}>
                    {baseObject}
                </div>
            );
        }

        return baseObject;
    }

    renderDropZone = () => {
        if (this.props.dropEnabled) {
            const dropValidation = (
                // Since sfps can always get dropped we need no complex logic here
                this.props.canDrop ?
                    DropValidation.allowed :
                    DropValidation.forbidden
            );

            return (
                <TextDropZone
                    canDrop={this.props.canDrop}
                    cornerStyle={CornerStyle.square}
                    dropValidation={dropValidation}
                    isOver={this.props.isOver}
                    textKey={null}
                />
            );
        }

        return null;
    };

    setViewReference = (reference) => {
        this.viewReference = reference;

        if (reference) {
            reference.addEventListener('mousedown', this.stopEventPropagation, false);
            reference.addEventListener('touchstart', this.stopEventPropagation, false);
        }
    };

    stopEventPropagation = (event) => {
        event.stopPropagation();
    };
}

Component.propTypes = {
    chassisLayout: PropTypes.oneOf(Object.values(ChassisLayout)),
    data:          PropTypes.object,
    dropEnabled:   PropTypes.bool,
    hasProduct:    PropTypes.func,
    height:        PropTypes.number,
    index:         PropTypes.number,
    scale:         PropTypes.number,
    slotData:      PropTypes.object,
    width:         PropTypes.number,
    x:             PropTypes.number,
    y:             PropTypes.number,
};

Component.defaultProps = {
    chassisLayout: '',
    data:          {},
    dropEnabled:   true,
    hasProduct:    _.noop,
    height:        10,
    index:         -1,
    scale:         1,
    slotData:      {},
    width:         10,
    x:             1,
    y:             1,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(
    ActiveProjectActions,
    DesignerLayoutActions,
    DragDropActions,
), dispatch);

const mapStateToProps = (state) => (
    {
        draggingActionActive: state.dragDrop.isDragging,
        selectedSlotMeta:     state.activeProject.selectedSlot,
        selectedSlot:         StateHelper.getSelectedSlot(state),
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    DropTarget(
        [
            DragTypes.add,
            DragTypes.sort,
        ],
        dragDropConfiguration.dropBehavior,
        dragDropConfiguration.dropCollect,
    ),
    DragSource(
        DragTypes.sort,
        dragDropConfiguration.dragBehavior,
        dragDropConfiguration.dragCollect,
    ),
)(slotSfp(Component));

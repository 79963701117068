//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import Overlays                 from '@constants/Overlays';
import ProductCategoryType      from '@constants/ProductCategoryType';
import DataProvider             from '@helper/DataProvider';
import ProductTitleHelper       from '@helper/ProductTitle';
import SelectionHelper          from '@helper/SelectionHelper';

import Overlay      from '../Overlay';
import TextHeadline from '../TextHeadline';

const Component = (props) => {
    const {
        products,
        productDeletionContext,
        deleteProductCancel,
        deleteProductConfirm,
        t,
    }                      = props;
    const closeButtonPressed     = () => {
        deleteProductCancel();
    };
    const deleteButtonPressed    = () => {
        deleteProductConfirm({
            categoryType: productDeletionContext.categoryType,
            id:           productDeletionContext.id,
        });
    };
    const getDeleteButtonTextKey = () => {
        return SelectionHelper.get(
            _.get(productDeletionContext, 'categoryType', null),
            {
                [ProductCategoryType.extender]: 'deleteExtenderButtonText',
                [ProductCategoryType.matrix]:   'deleteMatrixButtonText',
                [ProductCategoryType.fullIp]:   'deleteFullIpButtonText',
            },
            'unknownError',
        );
    };
    const getProducts            = () => {
        return products[productDeletionContext.categoryType];
    };
    const getText                = () => {
        if (productDeletionContext) {
            const products = getProducts();
            const product  = _.find(products, ['id', productDeletionContext.id], null);

            if (product) {
                const productData = DataProvider.getById(product.productData.id);
                const productName = ProductTitleHelper.getProductTitle(productData, product.customName);

                switch (productDeletionContext.categoryType) {
                    case ProductCategoryType.extender:
                        return t('deleteExtenderText', {
                            name: productName,
                        });

                    case ProductCategoryType.matrix:
                        return t('deleteMatrixText', {
                            name: productName,
                        });

                    case ProductCategoryType.fullIp:
                        return t('deleteFullIpText', {
                            name: productName,
                        });
                }
            }
        }

        return t('unknownError');
    };
    const getTitle               = () => {
        return SelectionHelper.getTranslation(
            _.get(productDeletionContext, 'categoryType', null),
            {
                [ProductCategoryType.extender]: 'deleteExtenderTitle',
                [ProductCategoryType.matrix]:   'deleteMatrixTitle',
                [ProductCategoryType.fullIp]:   'deleteFullIpTitle',
            },
            'unknownError',
        );
    };

    return (
        <Overlay
            allowDontShowAgain={true}
            closeButtonPressed={closeButtonPressed}
            id={Overlays.deleteProduct}
            okButtonPressed={deleteButtonPressed}
            okButtonTextKey={getDeleteButtonTextKey()}
            title={getTitle()}
            {...props}
        >
            <TextHeadline text={getText()} />
        </Overlay>
    );
};

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => (
    {
        products:               state.activeProject.products,
        productDeletionContext: state.activeProject.productDeletionContext,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push }    from 'connected-react-router';
import { put }     from 'redux-saga/effects';
import { select }  from 'redux-saga/effects';
import queryString from 'query-string';

import { getPathForOverlayKey } from '@components/OverlayManager';
import Overlays                 from '@constants/Overlays';
import Base64                   from '@helper/Base64';
import ApiConfiguration         from '@constants/Api';
import { ExportActions }        from '@slices/export';
import ProjectTitle             from '@helper/ProjectTitle';
import Language                 from '@helper/Language';

const exportAbasSuccess = function* () {
    const route = getPathForOverlayKey(Overlays.exportSuccess);

    yield put(push(route));
};

const exportDsdJsonSuccess = function* () {
    const route = getPathForOverlayKey(Overlays.exportSuccess);

    yield put(push(route));
};

const downloadExport = function* () {
    const activeProject                   = yield select((state) => state.activeProject);
    const settings                        = yield select((state) => state.settings);
    const activeProjectJson               = JSON.stringify(activeProject);
    const encodedString                   = Base64.encode(activeProjectJson);
    const userLanguge                     = settings.language;
    const cardOverlayModeRenderExtenderId = settings.cardOverlayModeRenderExtenderId;
    const queryStringParts                = {
        cardOverlayMode:                 settings.cardOverlayMode,
        language:                        Language.getBackendLanguage(userLanguge),
        cardOverlayModeRenderExtenderId: (
                                             cardOverlayModeRenderExtenderId ?
                                                 'true' :
                                                 'false'
                                         ),
    };
    const queryStringPartsString          = queryString.stringify(queryStringParts);
    const finalPrintPreviewUrl            = `${ApiConfiguration.getBackendUrl()}/project-export.zip?${queryStringPartsString}`;

    yield put(ExportActions.downloadExportStarted());

    try {
        const response   = yield fetch(finalPrintPreviewUrl, {
            method:  'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body:    JSON.stringify({
                projectPayload: encodedString,
            }),
        });
        const blob       = yield response.blob();
        const dateString = new Date().toISOString().replace(/:/g, '-');
        const blobUrl    = URL.createObjectURL(blob);
        const link       = document.createElement('a');
        const title      = ProjectTitle.getTitleOfActiveProject(activeProject);
        link.href        = blobUrl;
        link.download    = title + '-' + dateString + '.zip';

        document.body.appendChild(link);
        link.dispatchEvent(new MouseEvent(
            'click',
            {
                bubbles:    true,
                cancelable: true,
                view:       window,
            },
        ));
        document.body.removeChild(link);

        yield put(ExportActions.downloadExportSuccess());
    } catch (error) {
        yield put(ExportActions.downloadExportFailed());
    }
};

export default {
    downloadExport,
    exportAbasSuccess,
    exportDsdJsonSuccess,
};

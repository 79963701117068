import React from 'react';

import styles from './styles.module.scss';
import PropTypes from '../PropTypes';

const Component = ({ children }) => {
    return (
        <div className={styles.contactItemWrapper}>
            {children}
        </div>
    );
};

Component.propTypes = {
    children: PropTypes.children,
};

Component.defaultProps = {
    children: [],
};

export default Component;

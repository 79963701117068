//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import ChassisLayout from '@components/SelectedProductImageRenderer/ChassisLayout';

import ActiveProjectsFactory from '../factories/activeProjects';

class SlotPositionCalculator {
    static calculateNextState = (
        nextSlotValue,
        state,
        toSlotIndex,
        slotType,
        chassisProductData,
        slotProductData,
        fromSlotIndex,
    ) => {
        const heightUnits         = _.get(slotProductData, 'heightUnits', 0);
        const isDoubleSlotProduct = heightUnits > 1;

        if (isDoubleSlotProduct) {
            switch (chassisProductData.layoutDefinition.chassisLayout) {
                case ChassisLayout.horizontal:
                    // Force the card to be mounted to a odd slot
                    if (
                        (
                            toSlotIndex + 1
                        ) % 2 !== 0
                    ) {
                        toSlotIndex += 1;
                    }

                    break;

                case ChassisLayout.vertical:
                    toSlotIndex += (
                        // Move the card one slot to the left except the last one
                        toSlotIndex < chassisProductData.slotCount - 1 ?
                            1 :
                            0
                    );

                    break;
            }
        }

        const nextState  = {
            products: {
                [state.selectedProduct.categoryType]: {
                    [state.selectedProduct.index]: {
                        subProducts: {
                            [slotType]: {
                                [toSlotIndex]: nextSlotValue,
                            },
                        },
                    },
                },
            },
        };
        const stateSlots = state.products[state.selectedProduct.categoryType][state.selectedProduct.index].subProducts[slotType];

        if (isDoubleSlotProduct) {
            const previousSlotIndex = toSlotIndex - 1;
            const nextSlotIndex     = toSlotIndex + 1;

            //
            // Check whether the slot before the target slot should be cleared
            //
            if (previousSlotIndex >= 0) {
                const previousSlot = stateSlots[previousSlotIndex];

                if (previousSlot.productData) {
                    nextState.products[state.selectedProduct.categoryType][state.selectedProduct.index].subProducts[slotType][previousSlotIndex] = {
                        $set: ActiveProjectsFactory.getEmptyProductSlot(),
                    };
                }
            }

            if (chassisProductData.layoutDefinition.chassisLayout === ChassisLayout.vertical) {
                //
                // Check whether the slot after the target slot should be cleared
                //
                if (stateSlots.length > nextSlotIndex) {
                    const nextSlot = stateSlots[nextSlotIndex];

                    if (nextSlot.productData && nextSlot.productData.heightUnits > 1) {
                        nextState.products[state.selectedProduct.categoryType][state.selectedProduct.index].subProducts[slotType][nextSlotIndex] = {
                            $set: ActiveProjectsFactory.getEmptyProductSlot(),
                        };
                    }
                }
            }
        } else {
            //
            // Check whether the next slot after the target slot should be cleared
            // since it is a double slot
            //
            const nextSlotIndex = toSlotIndex + 1;

            if (nextSlotIndex) {
                const nextSlot = stateSlots[nextSlotIndex];

                if (nextSlot && nextSlot.productData) {
                    const nextSlotHeightUnits = _.get(nextSlot.productData, 'heightUnits', 0);

                    if (nextSlotHeightUnits > 1) {
                        nextState.products[state.selectedProduct.categoryType][state.selectedProduct.index].subProducts[slotType][nextSlotIndex] = {
                            $set: ActiveProjectsFactory.getEmptyProductSlot(),
                        };
                    }
                }
            }
        }

        if (fromSlotIndex || fromSlotIndex === 0) {
            nextState.products[state.selectedProduct.categoryType][state.selectedProduct.index].subProducts[slotType][fromSlotIndex] = {
                $set: ActiveProjectsFactory.getEmptyProductSlot(),
            };
        }

        return nextState;
    };
}

export default SlotPositionCalculator;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import AbasColumns         from '@constants/AbasColumns';
import ProductCategoryType from '@constants/ProductCategoryType';
import SlotType            from '@constants/SlotType';
import Cast                from '@helper/Cast';
import File                from '@helper/File';

class CsvImporter {
    static validFilenamePattern = /[0-9]+_[0-9]+_[0-9]+\.csv/;

    static canImport(zip) {
        const result = !!this.getFirstCsvFileFromFileList(zip.files);

        console.log('CsvImporter canImport:', result);

        return result;
    }

    static validateProduct(row, productName, activeProject) {
        const productTypesToCheck = [ProductCategoryType.extender, ProductCategoryType.matrix];
        let productIndex          = Cast.int(_.get(row, AbasColumns.productPosition, ''));
        productIndex              = (
            productIndex / 10
        ) - 1;
        let partNumberIsCorrect   = false;

        for (let productTypeIndex = 0; productTypeIndex < productTypesToCheck.length; productTypeIndex++) {
            const productType           = productTypesToCheck[productTypeIndex];
            const productPartNumberPath = [
                'products',
                productType,
                productIndex,
                'productData',
                'partNo',
            ];
            const partNumber            = _.get(activeProject, productPartNumberPath, null);

            if (
                partNumber &&
                partNumber === productName
            ) {
                partNumberIsCorrect = true;
            }
        }

        return partNumberIsCorrect;
    }

    static validateSlot(row, slotName, activeProject) {
        const productTypesToCheck     = [
            ProductCategoryType.extender,
            ProductCategoryType.matrix,
        ];
        const subProductCategoryTypes = [
            SlotType.slot,
        ];

        if (slotName.toLowerCase().startsWith('i')) {
            slotName = slotName.substr(1);
        }

        if (slotName.indexOf('_') !== -1) {
            slotName = slotName.substr(
                0,
                slotName.indexOf((
                    '_'
                )),
            );
        }

        let [productIndex, slotIndex] = _.get(row, AbasColumns.productAndSlotIndex, '')
            .split('.')
            .map((chunk) => Cast.int(chunk));
        productIndex                  = (
            productIndex / 10
        ) - 1;

        if (slotIndex >= 100) {
            return true;
        }
        slotIndex -= 1;

        let partNumberIsCorrect = false;

        for (let productTypeIndex = 0; productTypeIndex < productTypesToCheck.length; productTypeIndex++) {
            for (let subProductCategoryTypeIndex = 0; subProductCategoryTypeIndex < subProductCategoryTypes.length; subProductCategoryTypeIndex++) {
                const subProductCategoryType = subProductCategoryTypes[subProductCategoryTypeIndex];
                const productType            = productTypesToCheck[productTypeIndex];
                const slotPartNumberPath     = [
                    'products',
                    productType,
                    productIndex,
                    'subProducts',
                    subProductCategoryType,
                    slotIndex,
                    'productData',
                    'partNo',
                ];
                const partNumber             = _.get(activeProject, slotPartNumberPath, null);

                if (
                    partNumber &&
                    partNumber === slotName
                ) {
                    partNumberIsCorrect = true;
                }
            }
        }

        return partNumberIsCorrect;
    }

    static contentIsValid(rows, activeProject) {
        for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
            const row      = rows[rowIndex];
            const slotName = _.get(row, AbasColumns.slotName, null);

            if (slotName && !this.validateSlot(row, slotName, activeProject)) {
                return false;
            }
        }

        return true;
    }

    static filenameIsValid(filename) {
        return CsvImporter.validFilenamePattern.test(filename.toLowerCase());
    }

    static getCsvFilesFromFileList(files) {
        const csvFiles = _.filter(files, (file) => {
            return CsvImporter.filenameIsValid(file.name);
        });

        return csvFiles;
    }

    static getFirstCsvFileFromFileList(files) {
        const csvFiles = this.getCsvFilesFromFileList(files);

        return _.head(csvFiles);
    }

    static async importFile(file) {
        const content = await File.readFileAsync(file);

        return this.parseCsvFileContent(content);
    }

    static parseCsvFileContent(fileContent) {
        const parsedRows = [];
        const lines      = fileContent.split('\n');
        let columns      = [];
        let headerParsed = false;

        for (let line of lines) {
            line = line.trim();

            const chunks = line.split(';').map((cell) => cell.trim());

            if (!headerParsed) {
                columns      = chunks;
                headerParsed = true;

                continue;
            }

            const parsedRow = _.zipObject(columns, chunks);

            parsedRows.push(parsedRow);
        }

        return parsedRows;
    }
}

export default CsvImporter;

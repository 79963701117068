//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import defaultDragDropConfiguration from './default';

export default {
    dropBehavior: {
        canDrop(props, monitor) {
            const payload = monitor.getItem();

            console.log('SingleOrderAdd: canDrop: payload, props');
            console.table(payload);
            console.table(props);

            return (
                // A matching item is dragged from the data table to the left
                payload.productCategoryType === props.productCategoryType
            );
        },
        drop: (props, monitor, component) => {
            const payload = monitor.getItem();

            console.log('SingleOrderAdd: DropTarget: drop');
            console.table(payload);

            // This is true when the user drags a new card from the product table to
            // the left panel
            if (!payload.fromIndex) {
                props.addSingleOrderSlot({
                    categoryType: props.productCategoryType,
                    productId:    payload.productId,
                });
            }

            return {
                ...payload,
                toAccessoryType: props.accessoryType,
                toIndex:         props.index,
                toType:          props.type,
            };
        },
    },
    dropCollect:  defaultDragDropConfiguration.dropCollect,
};

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _                      from 'lodash';
import { DropTarget }         from 'react-dnd';
import { DragSource }         from 'react-dnd';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { compose }            from 'redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import { DragDropActions }      from '@slices/dragDrop';
import AccessoryTypes           from '@constants/AccessoryTypes';
import DragTypes                from '@constants/DragTypes';
import DropValidation           from '@constants/DropValidation';
import SlotType                 from '@constants/SlotType';

import styles                from './styles.module.scss';
import dragDropConfiguration from '../../dragDrop/slot';
import PropTypes             from '../PropTypes';
import TextDropZone          from '../TextDropZone';
import CornerStyle           from '../TextDropZone/CornerStyle';

class Component extends React.Component {
    render() {
        let baseObject = (
            <div className={styles.doubleSlotDropZone}>
                <TextDropZone
                    canDrop={this.props.canDrop}
                    cornerStyle={CornerStyle.square}
                    dropValidation={this.props.dropValidationForward}
                    key={'doubleSlotDropZone'}
                    isOver={this.props.isOver}
                />
            </div>
        );

        // This should be removed
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-441
        if (!this.props.isLocked && this.props.dropValidationForward === DropValidation.allowed) {
            baseObject = this.props.connectDropTarget(baseObject);
        }

        return baseObject;
    }
}

Component.propTypes = {
    accessoryType:         PropTypes.oneOf(Object.values(AccessoryTypes)),
    data:                  PropTypes.object,
    dropValidationForward: PropTypes.oneOf(Object.values(DropValidation)),
    index:                 PropTypes.number,
    isLocked:              PropTypes.bool,
    type:                  PropTypes.oneOf(Object.values(SlotType)),
};

Component.defaultProps = {
    accessoryType: null,
    data:          {},
    // This cannot be named "dropValidation" since it would be overwritten by the dragDropConfiguration
    dropValidationForward: DropValidation.unknown,
    index:                 -1,
    isLocked:              false,
    type:                  SlotType.slot,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(
    ActiveProjectActions,
    DragDropActions,
), dispatch);

export default compose(
    connect(
        null,
        mapDispatchToProps,
    ),
    DropTarget(
        [
            DragTypes.add,
            DragTypes.sort,
        ],
        dragDropConfiguration.dropBehavior,
        dragDropConfiguration.dropCollect,
    ),
    DragSource(
        DragTypes.sort,
        dragDropConfiguration.dragBehavior,
        dragDropConfiguration.dragCollect,
    ),
)(withTranslation()(Component));

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes         from '../PropTypes';
import UsbLabel          from '../UsbLabel';
import UsbLabelAlignment from '../UsbLabel/UsbLabelAlignment';

const Component = ({
    value,
}) => (
    <UsbLabel
        alignment={UsbLabelAlignment.left}
        usb={value}
    />
);

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

export default Component;

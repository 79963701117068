import React from 'react';

import styles from './styles.module.scss';
import ImagePreview from '../ImagePreview';

const Component = ({
    imageSource = '',
    number      = '',
    text        = '',
    title       = '',
}) => {
    const renderImage = () => {
        if (imageSource) {
            return (
                <div className={styles.helpBulletPointImageWrapper}>
                    <ImagePreview imageSource={imageSource} />
                </div>
            );
        }

        return null;
    };

    return (
        <div className={styles.helpBulletPoint}>
            <div className={styles.helpBulletPointLeft}>
                <div className={styles.helpBulletPointNumber}>
                    {number}
                </div>
            </div>
            <div className={styles.helpBulletPointRight}>
                <div className={styles.helpBulletPointTitle}>
                    {title}
                </div>
                <div className={styles.helpBulletPointText}>
                    {text}
                </div>
                {renderImage()}
            </div>
        </div>
    );
};

export default Component;

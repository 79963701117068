//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import Overlays                 from '@constants/Overlays';
import { ActiveProjectActions } from '@slices/activeProject';

import Overlay      from '../Overlay';
import OverlayType  from '../Overlay/OverlayType';
import TextHeadline from '../TextHeadline';

const Component = ({
    closeProject,
    to,
}) => {
    const okButtonPressed = () => {
        closeProject({
            closeConfirmed:  true,
            routeAfterClose: to,
        });
    };

    return (
        <Overlay
            allowDontShowAgain={true}
            id={Overlays.closeProject}
            okButtonPressed={okButtonPressed}
            okButtonTextKey={'closeProjectButton'}
            overlayType={OverlayType.prompt}
            title={I18n.t('closeProjectTitle')}
        >
            <TextHeadline text={I18n.t('closeProjectText')} />
        </Overlay>
    );
};

const mapStateToProps    = (state) => (
    {
        to: state.activeProject.routeAfterClose,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

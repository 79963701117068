import getDropValidation from './getDropValidation';

export default {
    dragCollect(connect, monitor) {
        return {
            connectDragPreview: connect.dragPreview(),
            connectDragSource:  connect.dragSource(),
            isDragging:         monitor.isDragging(),
            clientOffset:       monitor.getClientOffset(),
        };
    },
    dropCollect(connect, monitor, props) {
        const item                   = monitor.getItem();
        const dropValidation         = getDropValidation(item, props, 0);
        const dropValidationPrevious = getDropValidation(item, props, -1);

        return {
            connectDropTarget: connect.dropTarget(),
            canDrop:           monitor.canDrop(),
            dropValidation,
            dropValidationPrevious,
            isOver:            monitor.isOver(),
        };
    },
};

import React from 'react';

import styles from './styles.module.scss';
import PropTypes from '../PropTypes';

const Component = ({ title }) => (
    <div className={styles.productDragViewWrapper}>
        <div className={styles.productDragView}>
            {title}
        </div>
    </div>
);

Component.propTypes = {
    title: PropTypes.string,
};

Component.defaultProps = {
    title: '',
};

export default Component;

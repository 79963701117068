//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames     from 'classnames';
import I18n           from 'i18next';
import * as KeyCode   from 'keycode-js';
import _              from 'lodash';
import Highlighter    from 'react-highlight-words';
import { connect }    from 'react-redux';
import Select         from 'react-select';
import { components } from 'react-select';

import ProductDataKeys from '@constants/ProductDataKeys';
import ObjectHelper    from '@helper/Object';
import colors          from '@styles/colors.scss';
import fonts           from '@styles/fonts.scss';
import zIndexes        from '@styles/zIndexes.scss';

import ProductQuickSelectMode from './ProductQuickSelectMode';
import styles                 from './styles.module.scss';
import PropTypes              from '../PropTypes';

const customStyles = {
    control:           (provided, state, test) => {
        return {
            ...provided,
            alignItems:   'baseline',
            borderRadius: (
                state.isFocused ?
                    '4px 4px 0 0' :
                    4
            ),
            borderColor:  `${state.isFocused ?
                colors.colorOrange :
                colors.colorGrayLighter
            } !important`,
            fontFamily:   fonts.mainFont,
            height:       21,
            minHeight:    21,
            boxShadow:    'none',
        };
    },
    dropdownIndicator: () => (
        {
            'cursor':         'pointer',
            'width':          '36px',
            'height':         '100%',
            'position':       'absolute',
            'top':            0,
            'right':          0,
            'display':        'flex',
            'alignItems':     'center',
            'paddingLeft':    6,
            'paddingRight':   6,
            'justifyContent': 'flex-end',
            'color':          colors.colorGrayLighter,
            'boxSizing':      'border-box',
            '&:hover':        {
                'svg': {
                    '*': {
                        fill: colors.colorOrange,
                    },
                },
            },
        }
    ),

    indicatorsContainer: () => (
        {}
    ),
    indicatorSeparator:  () => (
        {
            display: 'none',
        }
    ),
    input:               (provided, state) => {
        return {
            position:        'absolute',
            top:             3,
            overflow:        'hidden',
            left:            0,
            outline:         'none !important',
            width:           '100%',
            color:           colors.colorGrayLight,
            padding:         '0 0 0 6px',
            fontSize:        11,
            textTransform:   'none',
            fontWeight:      'normal',
            fontFamily:      fonts.fontSourceSansPro,
            backgroundColor: (
                state.value.length > 0 ?
                    colors.colorWhite :
                    colors.colorTransparent
            ),
        };
    },
    menu:                () => (
        {
            position:       'absolute',
            zIndex:         zIndexes.zIndexFilterMenu,
            width:          '100%',
            top:            20,
            boxShadow:      '0 5px 10px 0 #0000000c',
            borderWidth:    1,
            borderStyle:    'solid',
            borderColor:    colors.colorOrange,
            borderTopWidth: 0,
            borderRadius:   '0 0 4px 4px',
            boxSizing:      'border-box',
            maxHeight:      160,
        }
    ),
    menuList:            () => (
        {
            background: colors.colorWhite,
            padding:    2,
            maxHeight:  148,
            overflow:   'auto',
        }
    ),
    option:
                         (provided, state) => {
                             return {
                                 backgroundColor: (
                                     state.isSelected || state.isFocused ?
                                         colors.colorOrangeLighter2 :
                                         colors.colorGrayLighter2
                                 ),
                                 color:           colors.colorGrayLight,
                                 cursor:          'pointer',
                                 position:        'relative',
                                 borderRadius:    3,
                                 fontFamily:      fonts.fontSourceSansPro,
                                 fontSize:        12,
                                 fontWeight:      'normal',
                                 paddingLeft:     4,
                                 paddingRight:    4,
                                 paddingTop:      2,
                                 paddingBottom:   2,
                                 overflow:        'hidden',
                                 textOverflow:    'ellipsis',
                                 marginTop:       1,
                                 textTransform:   'none',
                                 transition:      `background-color ${colors.defaultTransitionSpeed} linear`,
                             };
                         },
    noOptionsMessage:    () => (
        {
            color:         colors.colorGrayLight,
            fontSize:      12,
            textTransform: 'none',
            fontWeight:    'normal',
            paddingLeft:   4,
            paddingRight:  4,
            paddingTop:    2,
            paddingBottom: 2,
            fontFamily:    fonts.fontSourceSansPro,
        }
    ),
    placeholder:         () => (
        {
            color:         colors.colorGrayLighter,
            fontSize:      12,
            textTransform: 'none',
            fontWeight:    'normal',
            fontFamily:    fonts.fontSourceSansPro,
            whiteSpace:    'nowrap',
        }
    ),
    singleValue:         (provided, state) => {
        const style = {
            ...provided,
            top: '42%',
        };

        if (state.selectProps.hideSingleValue) {
            style.display = 'none';
        }

        return style;
    },
    valueContainer:      (provided, state) => {
        return {
            ...provided,
            height:   21,
            padding:  '0 0 0 5px',
            fontSize: 12,
        };
    },
};

/**
 * This is used as static variable to prevent weird scrolling behavior when using
 * the mouse wheel to scroll.
 *
 * @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-963
 * @type {boolean}
 */
let scrollToActiveElement = false;

/**
 * This is a workaround since react select is not able to keep track of the
 * options when the selected option changes using the keyboard.
 *
 * @see https://github.com/JedWatson/react-select/issues/2976
 */
class SupportKeyboardScrollOption extends React.Component {
    constructor(props) {
        super(props);

        this.option = React.createRef();
    }

    componentDidUpdate() {
        if (
            this.option.current
            && this.props.isFocused
            && scrollToActiveElement
        ) {
            scrollToActiveElement = false;

            this.option.current.scrollIntoView(false);
        }
    }

    render() {
        return (
            <div ref={this.option}>
                {this.renderContent()}
            </div>
        );
    }

    renderContent = () => {
        if (this.props.selectProps.mode === ProductQuickSelectMode.full) {
            return this.renderFull();
        }

        return this.renderLight();
    };

    renderFull = () => {
        const data = this.props.data.additionalData;

        return (
            <components.Option
                {...this.props}
            >
                <strong className={styles.productQuickSelectFullOptionHeadline}>
                    {data.partNo}
                </strong>
                <p className={styles.productQuickSelectFullOptionDescription}>
                    <Highlighter
                        highlightClassName={styles.productQuickSelectFullOptionDescriptionHighlighted}
                        searchWords={[this.props.selectProps.inputValue]}
                        autoEscape={true}
                        textToHighlight={data[I18n.t('descriptionKey')]}
                    />
                </p>
            </components.Option>
        );
    };

    renderLight = () => {
        return (
            <components.Option
                {...this.props}
            />
        );
    };
}

class Component extends React.Component {
    lastInputValue = null;

    select = null;

    constructor(props) {
        super(props);

        this.state = {
            products: this.props.products,
        };
    }

    fullSearchFilterOption = (option, inputValue) => {
        this.lastInputValue = inputValue;

        if (inputValue) {
            const whiteList    = [
                ProductDataKeys.PART_NO,
                ProductDataKeys.DISPLAY_PART_NUMBER,
            ];
            const pickedValues = ObjectHelper.pickValues(
                option.data.additionalData,
                whiteList,
                null,
            );
            const values       = Object.values(pickedValues);
            const containsKey  = ObjectHelper.containsKeyWithValue(
                values,
                inputValue,
            );

            return containsKey;
        }

        return true;
    };

    getLabelForProduct = (product) => {
        const { getLabelForProduct } = this.props;

        if (getLabelForProduct) {
            return getLabelForProduct(product);
        }

        return product.partNo;
    };

    getOptions = () => {
        const options = [];

        for (const product of this.state.products) {
            const label = this.getLabelForProduct(product);

            options.push({
                additionalData: product,
                label,
                value:          product.partNo,
            });
        }

        return options;
    };

    onFocus = () => {
        // If this property callback is set, the dropdown data is calculated as late
        // as possible to avoid rendering delays
        if (this.props.getProducts) {
            const products = this.props.getProducts();

            this.setState({
                products,
            });
        }
    };

    render() {
        return (
            <div className={styles.productQuickSelectWrapper}>
                {this.renderSelect()}
                {this.renderEmptyText()}
            </div>
        );
    }

    renderEmptyText = () => {
        return (
            <span
                className={classNames(
                    styles.productQuickSelectEmptyText,
                    {
                        [styles.productQuickSelectEmptyTextDraggingActive]: this.props.draggingActionActive,
                    },
                )}
            >
                {I18n.t('empty')}
            </span>
        );
    };

    renderSelect = () => {
        const options             = this.getOptions();
        const { hideSingleValue } = this.props;
        const selectedValue       = (
            hideSingleValue ?
                null :
                _.find(
                    options,
                    {
                        value: this.props.selectedPartNumber,
                    },
                )
        );

        return (
            <Select
                autosize={false}
                className={classNames(
                    styles.productQuickSelect,
                    {
                        [styles.productQuickSelectDraggingActive]: this.props.draggingActionActive,
                    },
                )}
                components={{
                    Option: SupportKeyboardScrollOption,
                }}
                controlShouldRenderValue={true}
                closeMenuOnSelect={!this.props.keepFocusAfterChange}
                escapeClearsValue={true}
                filterOption={this.fullSearchFilterOption}
                hideSingleValue={hideSingleValue}
                hideSelectedOptions={false}
                isClearable={false}
                isMulti={false}
                mode={this.props.mode}
                noOptionsMessage={() => {
                    return I18n.t('productQuickSelectNoMatches');
                }}
                onChange={this.props.onChange}
                onKeyDown={(event) => {
                    if (
                        event.keyCode === KeyCode.KEY_UP
                        || event.keyCode === KeyCode.KEY_DOWN
                    ) {
                        scrollToActiveElement = true;
                    }
                }}
                onFocus={this.onFocus}
                options={options}
                placeholder={I18n.t(this.props.placeholderKey)}
                styles={customStyles}
                value={selectedValue}
            />
        );
    };
}

Component.propTypes = {
    getLabelForProduct:   PropTypes.func,
    getProducts:          PropTypes.func,
    hideSingleValue:      PropTypes.bool,
    keepFocusAfterChange: PropTypes.bool,
    mode:                 PropTypes.oneOf(Object.values(ProductQuickSelectMode)),
    onChange:             PropTypes.func,
    placeholderKey:       PropTypes.string,
    products:             PropTypes.array,
    selectedPartNumber:   PropTypes.string,
};

Component.defaultProps = {
    getLabelForProduct:   null,
    getProducts:          null,
    hideSingleValue:      false,
    keepFocusAfterChange: false,
    mode:                 ProductQuickSelectMode.light,
    onChange:             _.noop,
    placeholderKey:       'enterProductNumber',
    products:             [],
    selectedPartNumber:   null,
};

const mapStateToProps = (state) => (
    {
        draggingActionActive: state.dragDrop.isDragging,
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);

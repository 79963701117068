//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

let lastClientOffset = null;

class MouseMoveDebounce {
    static clientOffsetChanged(clientOffset) {
        let changed = true;

        if (clientOffset && lastClientOffset && lastClientOffset.x === clientOffset.x && lastClientOffset.y === clientOffset.y) {
            changed = false;
        }

        lastClientOffset = clientOffset;

        return changed;
    }
}

export default MouseMoveDebounce;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';

const Component = ({
    breakAfter = false,
    text       = '',
}) => (
    <div
        className={classNames(
            styles.textLong,
            breakAfter
                ? styles.textLongBreakAfter
                : null,
        )}
    >
        {text}
    </div>
);

export default Component;

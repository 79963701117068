export default {
    catx1g:       'catx1g',
    catx3g:       'catx3g',
    fiber10g:     'fiber10g',
    fiber1g:      'fiber1g',
    fiber3g:      'fiber3g',
    fiber6g:      'fiber6g',
    ipcatx1g:     'ipcatx1g',
    ipfiber10gmm: 'ipfiber10gmm',
    ipfiber1g:    'ipfiber1g',
    uni:          'uni',
};

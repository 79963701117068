import React from 'react';

import classNames from 'classnames';
import { withTranslation } from 'react-i18next';

import DropValidation from '@constants/DropValidation';
import SelectionHelper from '@helper/SelectionHelper';

import CornerStyle from './CornerStyle';
import styles from './styles.module.scss';
import PropTypes from '../PropTypes';

const Component = ({
    canDrop        = false,
    cornerStyle    = CornerStyle.square,
    dropValidation = DropValidation.unknown,
    extraStyle     = null,
    isOver         = false,
    textKey        = 'placeHere',
    t,
}) => {
    const renderInner = () => {
        return (
            <div className={styles.textDropZoneInner}>
                {textKey
                    ? (
                        <span className={styles.textDropZoneInnerText}>{t(textKey)}</span>
                    )
                    : null}
            </div>
        );
    };

    return (
        <div
            className={classNames(
                styles.textDropZone,
                SelectionHelper.get(cornerStyle, {
                    [CornerStyle.round]: styles.textDropZoneRoundCornerStyleRound,
                    [CornerStyle.square]: styles.textDropZoneRoundCornerStyleSquare,
                }),
                canDrop
                    ? styles.textDropZoneCanDrop
                    : null,
                SelectionHelper.get(dropValidation, {
                    [DropValidation.allowed]: styles.textDropZoneDropValidationAllowed,
                    [DropValidation.forbidden]: styles.textDropZoneDropValidationForbidden,
                }),
                canDrop && isOver
                    ? styles.textDropZoneIsOver
                    : null,
                extraStyle,
            )}
        >
            {renderInner()}
        </div>
    );
};

Component.propTypes = {
    canDrop: PropTypes.bool,
    cornerStyle: PropTypes.oneOf(Object.values(CornerStyle)),
    dropValidation: PropTypes.oneOf(Object.values(DropValidation)),
    extraStyle: PropTypes.string,
    isOver: PropTypes.bool,
    textKey: PropTypes.string,
};

export default withTranslation()(Component);

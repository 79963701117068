//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { Component } from 'react';
import React         from 'react';

import I18n        from 'i18next';
import { Helmet }  from 'react-helmet';
import { connect } from 'react-redux';

import Header          from '@components/Header';
import TextContainer   from '@components/TextContainer';
import TitleBanner     from '@components/TitleBanner';
import TitleBannerMode from '@components/TitleBanner/TitleBannerMode';
import Languages       from '@constants/Languages';
import PageTitleHelper from '@helper/PageTitle';
import SelectionHelper from '@helper/SelectionHelper';

class Screen extends Component {
    /**
     * This texts are from https://www.ihse.de/datenschutz/
     * @see https://www.ihse.de/datenschutz/
     *
     * @returns {*}
     */
    renderDe = () => {
        return (
            <TextContainer autoStyleChildren={true}>
                <p>
                    Der Schutz Ihrer Privatsphäre ist für die IHSE GmbH sehr wichtig. Nachstehend informieren wir Sie
                    ausführlich über den Umgang mit Ihren Daten.
                </p>
                <h3>
                    Erhebung und Verarbeitung von personenbezogenen Daten
                </h3>
                <p>
                    Wenn sie unsere Website besuchen, erfassen wir keine personenbezogenen Daten (z.B. Name, Anschrift,
                    Telefonnummern, E-Mail Adressen), außer wenn Sie uns solche Daten freiwillig zur Verfügung stellen.
                    Mit der vollständigen Bearbeitung der Anfrage werden Ihre Daten im Rahmen der Vorschriften des
                    Telemediengesetzes und Bundesdatenschutzgesetzes erfasst, verwendet und gespeichert. Wir verwenden
                    die von Ihnen dabei mitgeteilten Daten zur Bearbeitung Ihrer Anfrage. Ein Widerruf ist Ihnen
                    jederzeit möglich.
                </p>
                <p>
                    Wenn Sie über Telekommunikationsdienste auf unsere Websites zugreifen, werden kommunikationsbezogene
                    Angaben (z. B. Internet-Protokoll-Adresse) bzw. nutzungsbezogene Angaben (z.B. Angaben zu
                    Nutzungsbeginn und -dauer sowie zu den von Ihnen genutzten Telekommunikationsdiensten) mit
                    technischen Mitteln automatisch erzeugt. Diese können eventuell Rückschlüsse auf personenbezogene
                    Daten zulassen. Soweit eine Erfassung, Verarbeitung und Verwendung Ihrer kommunikations- bzw.
                    nutzungsbezogenen Angaben zwingend notwendig ist, unterliegt diese den gesetzlichen Regelungen zum
                    Datenschutz.
                </p>
                <h3>
                    Weitergabe und Nutzung von personenbezogenen Daten
                </h3>
                <p>
                    Eine Weitergabe Ihrer Daten an Dritte zu Werbezwecken erfolgt von IHSE GmbH nicht. Die von Ihnen
                    über
                    E-Mail mitgeteilten Daten verwenden wir zur Beantwortung Ihrer Anfragen und für eine Kontaktaufnahme
                    durch IHSE.
                </p>
                <p>
                    Personenbezogene Daten, die Sie durch eine Kontaktanfrage, eine Newsletter-Anmeldung oder direkte
                    geschäftliche Beziehungen an uns mitgeteilt haben, werden von uns mit Hilfe eines
                    Kundenbeziehungssystems (Engl. Customer-Relationship-Management-System, kurz CRM-System) verarbeitet
                    und
                    gepflegt.
                </p>
                <p>
                    Das aktuell verwendete CRM-System wird von der Firma Zoho Corporation, 4141 Hacienda Drive
                    Pleasanton,
                    CA 94588, USA betrieben und angeboten. Weitere Informationen können Sie auf der Internetseite von
                    Zoho
                    einsehen: <a
                        target={'_blank'}
                        href={'https://www.zoho.com/de/privacy.html'}
                        rel="noreferrer"
                    >https://www.zoho.com/de/privacy.html</a>
                </p>
                <p>
                    Die Daten, die an Zoho übermittelt werden, dienen nur zum Zweck der gewünschten Kontaktaufnahme und
                    werden auch nur zu diesem Zweck weiter genutzt, nicht etwa zum Newsletter-Versand. Diesem muss
                    separat
                    zugestimmt werden.
                </p>
                <p>
                    Über die Bestellung des Newsletters speichern wir Ihre Email-Adresse. Die Bestellung der Newsletter
                    wird
                    zu Nachweiszwecken protokolliert. Die Abmeldung ist jederzeit möglich, z. B. durch einen Link zum
                    Abmelden in der Fußzeile jedes Newsletters. Nach der Abmeldung der Newsletter wird Ihre
                    Email-Adresse
                    gelöscht.
                </p>
                <h3>
                    Auskunftsrecht
                </h3>
                <p>
                    Nach dem Bundesdatenschutzgesetz haben Sie ein Recht auf unentgeltliche Auskunft über Ihre
                    gespeicherten
                    Daten sowie ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten.
                </p>
                <h3>
                    Ansprechpartner für Datenschutz
                </h3>
                <p>
                    Bei Fragen zum Datenschutz wenden Sie sich an unseren Datenschutzbeauftragten:
                    <br />
                    <br />
                    Ulrich Behnke
                    Büro BUL-MA
                    Dornierstraße 4
                    88677 Markdorf
                    Tel.: 07544 – 9 49 99 09
                    Email: <a href={'mailto:ulrich.behnke@bul-ma.de'}>ulrich.behnke@bul-ma.de</a>
                </p>
                <h3>
                    Schutz der gespeicherten Daten
                </h3>
                <p>
                    Um Ihre personenbezogenen Daten vor Verlust, Zugriff unberechtigter Personen, Zerstörung und
                    Manipulation zu schützen, setzen wir technische / organisatorische Maßnahmen ein. Diese Maßnahmen
                    werden
                    fortlaufend angepasst, verbessert und entsprechen dem Stand der Technik. Auszuschließen ist es
                    nicht,
                    dass doch unverschlüsselte Daten von Dritten eingesehen werden können. Wir weisen darauf hin, dass
                    bei
                    der Datenübertragung über das Internet (z. B. bei der E-Mail-Kommunikation) eine sichere Übertragung
                    nicht gewährleistet ist. Sensible Daten sollten daher gar nicht oder nur über eine sichere
                    Verbindung
                    (TLS/SSL) übertragen werden.
                </p>
                <h3>
                    Datenaufbewahrung/-löschung
                </h3>
                <p>
                    Ihre Daten werden für den Zweck verarbeitet, für den sie erhoben wurden bzw. sie einer Einwilligung
                    zugestimmt haben. Wenn sie keine Speicherung Ihrer Daten wünschen, müssen sie uns dies schriftlich
                    mitteilen.
                </p>
                <h3>
                    Minderjährigenschutz
                </h3>
                <p>
                    Die Einwilligung zur Verarbeitung personenbezogener Daten kann nur durch eine volljährige Person
                    erteilt
                    werden. Daten von Minderjährigen werden von uns grundsätzlich nicht erhoben. Nach Bekanntwerden
                    einer
                    solchen Verarbeitung werden die Daten sofort gelöscht.
                </p>
                <h3>
                    Online-Bewerbungen
                </h3>
                <p>
                    Auf Grundlage der gesetzlichen Vorgaben (Art. 6 Abs. 1 lit. b und f DSGVO und §26 BDSG) verarbeiten
                    wir Ihre Bewerberdaten nur im Rahmen des Bewerberverfahrens. Die Datenverarbeitung erfolgt zur
                    Erfüllung unserer (vor-)vertraglichen Verpflichtungen.
                </p>
                <p>
                    Die für die Zusendung der Bewerberunterlagen notwendigen Daten, sind im Online-Formular
                    gekennzeichnet. Die notwendigen Daten in den Bewerbungsunterlagen, ergeben sich aus der
                    Stellenbeschreibung. Grundsätzlich gehören dazu Angaben zur Person, Post- und Kontaktadresse,
                    Anschreiben, Lebenslauf und die Zeugnisse. Sie können zusätzlich freiwillige Angaben von ihnen
                    angeben.
                </p>
                <p>
                    Mit der Zusendung ihrer Bewerbungsunterlagen erklären Sie sich bereit, dass ihre Daten zum Zweck des
                    Bewerbungsverfahrens, auf Grundlage der Datenschutzerklärung, verarbeitet werden dürfen.
                </p>
                <p>
                    Die von Ihnen freiwillig mitgeteilten personenbezogenen Daten besonderer Kategorien (z.B.
                    Gesundheitsdaten oder ethnische Herkunft), werden zusätzlich nach Art. 9 Abs. 2 lit. b DSGVO
                    verarbeitet. Werden im Sinne von Art. 9 Abs. 1 DSGVO im Bewerberverfahren personenbezogenen Daten,
                    die für die Berufsausübung notwendig sind, abgefragt, werden diese Daten zusätzlich nach Art. 9 Abs.
                    2 lit. a DSGVO verarbeitet.
                </p>
                <p>
                    Sie können uns auch ihre Bewerbung per E-Mail schicken. Bitte beachten Sie, dass die Daten nicht
                    verschlüsselt sind und somit von Dritten gelesen werden können. Wir können für diesen Versandweg
                    keine Verantwortung übernehmen.
                </p>
                <p>
                    Weiterhin steht Ihnen die Möglichkeit zur Verfügung, uns die Bewerbung auf dem Postweg zuzusenden.
                </p>
                <p>
                    Im Fall einer erfolgreichen Bewerbung, können die von Ihnen uns zur Verfügung gestellten Daten
                    weiterverarbeitet werden. Konnten wir Sie leider nicht berücksichtigen, werden Ihre Daten gelöscht.
                    Das Gleiche gilt auch bei einer zurückgezogenen Bewerbung.
                </p>
                <p>
                    Wenn kein berechtigter Widerruf von Ihnen erfolgt, werden Ihre Daten nach Ablauf von sechs Monaten
                    gelöscht. Diesen Zeitraum benötigen wir, um etwaige Anschlussfragen zur Bewerbung beantworten zu
                    können und der Nachweispflicht aus dem Gleichbehandlungsgesetzt (AGG) genügen zu können. Rechnungen
                    über etwaige Reisekosten Erstattungen werden auf Grundlage der steuerlichen Vorgaben gespeichert und
                    archiviert.
                </p>
                <p>
                    Ihre Einwilligung können Sie jederzeit schriftlich widerrufen. Bitte richten Sie Ihren Widerruf
                    unter Angabe Ihres vollständigen Namens und E-Mail-Adresse an unseren Datenschutzbeauftragten.
                </p>
                <h3>
                    Analytikprogramme und Cookies
                </h3>
                <p>
                    Wenn Sie auf unsere Websites zugreifen, werden Informationen gesammelt, die nicht einer bestimmten
                    Person zugeordnet sind (z.B. verwendeter Internet-Browser und Betriebssystem; Domain-Name der
                    Website,
                    von der Sie kamen; Anzahl der Besuche; durchschnittliche Verweilzeit; aufgerufene Seiten). Wir und
                    unsere Tochtergesellschaft verwenden diese Informationen, um die Attraktivität unserer Websites zu
                    ermitteln und deren Leistungsfähigkeit und Inhalte zu verbessern
                </p>
                <h3>
                    Leadfeeder
                </h3>
                <p>
                    Um unsere Homepage besser ihren Bedürfnissen anpassen zu können, setzten wird das Tool Leadfeeder
                    (
                    <a
                        target={'_blank'}
                        href={'https://www.leadfeeder.com'}
                        rel="noreferrer"
                    >www.leadfeeder.com
                    </a>
                    ) ein. Ihre IP-Adresse wird erfasst und ausgewertet.
                </p>
                <p>
                    Leadfeeder verwendet zur Auswertung Google Analytics. Die Datenschutzerklärung können sie unter
                    <a
                        target={'_blank'}
                        href={'https://www.leadfeeder.com/privacy/'}
                        rel="noreferrer"
                    >https://www.leadfeeder.com/privacy/</a> einsehen.
                    Der Sitz der Firma ist in Finnland.
                </p>
                <h3>
                    Google Analytics
                </h3>
                <p>
                    Auf unserer Website wird Google Analytics eingesetzt, einen Webanalysedienst der Google Inc.
                    („Google“).
                    Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden
                    und
                    die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten
                    Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in
                    den
                    USA übertragen und dort gespeichert. Ihre IP-Adresse wird von Google jedoch innerhalb der
                    Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
                    Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen
                    Server von Google in den USA übertragen und dort gekürzt. Im Auftrag von Zeppelin wird Google diese
                    Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
                    Websiteaktivitäten
                    zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene
                    Dienstleistungen uns gegenüber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser
                    übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Sie können die
                    Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir
                    weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen
                    dieser
                    Website werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten
                    und
                    auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
                    Verarbeitung
                    dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link
                    (
                    <a
                        target={'_blank'}
                        href={'https://tools.google.com/dlpage/gaoptout?hl=de'}
                        rel="noreferrer"
                    >https://tools.google.com/dlpage/gaoptout?hl=de</a>
                    ) verfügbare Browser-Plugin herunterladen und
                    installieren.
                </p>
                <p>
                    Weitere Informationen zur Google Inc. und Google Analytics finden Sie unter: (
                    <a
                        target={'_blank'}
                        href={'https://www.google.com'}
                        rel="noreferrer"
                    >https://www.google.com</a>
                    ).
                    Die Datenschutzerklärung von Google finden Sie
                    unter: <a
                        target={'_blank'}
                        href={'https://www.google.com/intl/de/privacypolicy.html'}
                        rel="noreferrer"
                    >www.google.com/intl/de/privacypolicy.html</a>
                </p>
                <p>
                    Beim Besuch unserer Webseite werden anonymisierte Nutzungsdaten mittels Zoho SalesIQ (
                    <a
                        target={'_blank'}
                        href={'https://www.zoho.com/de/salesiq'}
                        rel="noreferrer"
                    >https://www.zoho.com/de/salesiq</a>
                    ), einen Webanalysedienst der Zoho Corporation, 4141 Hacienda Drive, Pleasanton, California 94588,
                    USA erhoben.
                </p>
                <p>
                    SalesIQ verwendet so genannte "Cookies", welche eine Analyse unserer Webseite ermöglichen. Diese
                    Cookies sammeln auch Informationen darüber, wie Besucher die Website nutzen, von welcher Website der
                    Benutzer kam, die Anzahl der Besuche jedes Benutzers und die Dauer des Aufenthalts auf den Websites.
                    Sie können durch entsprechende Einstellungen in Ihrem Browser dieses "Tracking" verhindern. Wir
                    speichern diese Daten ausschließlich für statistische Zwecke. Die IP Adressen werden im Rahmen der
                    Gesetzgebung um die letzten Stellen gekürzt, um eine Anonymität zu gewährleisten.
                </p>
                <p>
                    Zoho ist nach dem EU-US Privacy Shield zertifiziert.
                    Unter folgenden Links finden Sie die Datenschutzerklärung und die Cookie-Richtlinie der Zoho
                    Corporation:
                    <ul>
                        <li>
                            <a
                                target={'_blank'}
                                href={'https://www.zoho.com/de/privacy.html'}
                                rel="noreferrer"
                            >
                                https://www.zoho.com/de/privacy.html
                            </a>
                        </li>
                        <li>
                            <a
                                target={'_blank'}
                                href={'https://www.zoho.com/de/privacy/cookie-policy.html'}
                                rel="noreferrer"
                            >
                                https://www.zoho.com/de/privacy/cookie-policy.html
                            </a>
                        </li>
                    </ul>
                </p>
                <h3>
                    Zoho Campaigns
                </h3>
                <p>
                    Für die Versendung von Newslettern und deren Auswertung setzen wir Zoho Campaigns ein, eine
                    E-Mail-Marketing-Software der Zoho Corporation, 4141 Hacienda Drive, Pleasanton, California 94588,
                    USA.
                </p>
                <p>
                    Bei der Newsletter Anmeldung werden Ihre Daten in unserem CRM-System verarbeitet und danach an das
                    Newsletter-Tool Zoho Campaigns übergegeben. In unserem Auftrag verwendet Zoho Campaigns diese
                    Informationen zum Versand und zur statistischen Auswertung der Newsletter. Für die Auswertung
                    beinhalten die versendeten E-Mails Web-Beacons bzw. Tracking-Pixel, die Ein-Pixel-Bilddateien
                    darstellen, die in unseren HTML-Mails integriert sind. So kann festgestellt werden, ob eine
                    Newsletter-Nachricht geöffnet und welche Links angeklickt wurden. Außerdem werden technische
                    Informationen erfasst (z.B. Zeitpunkt des Abrufs, IP-Adresse, Browsertyp und Betriebssystem). Diese
                    Daten werden zur statistischen Analyse von Newsletter Kampagnen verwendet. Die hieraus
                    resultierenden Ergebnisse können genutzt werden, um künftige Newsletter besser an die Interessen der
                    Empfänger anzupassen.
                </p>
                <p>
                    Wenn Sie der Datenanalyse zu statistischen Auswertungszwecken widersprechen möchten, müssen Sie den
                    Newsletter Bezug abbestellen. Zoho Campaigns nutzt die Daten der Newsletter Empfänger nicht, um
                    diese selbst anzuschreiben.
                </p>
                <p>
                    Zum Schutz Ihrer Daten in den USA wurde von Zoho Corporation und IHSE GmbH eine
                    Datenverarbeitungsvereinbarung („Data-Processing-Agreement“) auf Basis der Standardvertragsklauseln
                    der Europäischen Kommission geschlossen, um die Übermittlung Ihrer personenbezogenen Daten an Zoho
                    Campaigns zu ermöglichen. Darüber hinaus ist Zoho Campaigns nach dem us-europäischen
                    Datenschutzabkommen „Privacy Shield“ zertifiziert und verpflichtet sich damit, die
                    EU-Datenschutzvorgaben einzuhalten.
                    Die Datenschutzbestimmungen von Zoho Campaigns können Sie hier
                    einsehen: <a
                        target={'_blank'}
                        href={'https://www.zoho.com/de/privacy.html'}
                        rel="noreferrer"
                    >https://www.zoho.com/de/privacy.html</a>.
                </p>
                <h3>
                    OptinMonster
                </h3>
                <p>
                    OptinMonster ist ein Plugin der Firma Retyp LLC, 7732 Maywood Crest Dr., West Palm Beach, FL 33412,
                    US (
                    <a
                        target={'_blank'}
                        href={'https://optinmonster.com'}
                        rel="noreferrer"
                    >https://optinmonster.com</a>
                    ).
                </p>
                <p>
                    Hierdurch können wir unseren Besuchern per Popup-Layer auf unserer Webseite zusätzliche Angebote zur
                    Verfügung stellen, etwa um eine Eintragung der E-Mail-Adresse zu einem Newsletter zu ermöglichen
                    oder auf Aktionen hinzuweisen. OptinMonster verwendet dazu Cookies. Personenbezogene Daten werden
                    nur durch eine aktive Handlung des Kunden (z.B. der Kunde meldet sich über ein Popup zum Newsletter
                    an) gesammelt. OptinMonster speichert die gesammelten Daten nicht auf den eigenen Servern, sondern
                    leitet diese direkt an ein durch IHSE ausgewähltes Tool weiter.
                </p>
                <p>
                    Wir haben mit OptinMonster einen Vertrag zur Auftragsverarbeitung abgeschlossen, in dem wir
                    OptinMonster zum Schutz Ihrer Daten nach den geltenden Bestimmungen der DSGVO verpflichten. Details
                    zum Umgang mit den persönlichen Daten des Kunden durch OptinMonster werden in den
                    Datenschutzhinweisen von OptinMonster (
                    <a
                        target={'_blank'}
                        href={'https://optinmonster.com/privacy/'}
                        rel="noreferrer"
                    >https://optinmonster.com/privacy/</a>
                    ) näher beschrieben.
                </p>
                <h3>
                    SurveyMonkey
                </h3>
                <p>
                    Zur Erstellung von Umfragen und deren Auswertung setzen wir das Tool von SurveyMonkey
                    (
                    <a
                        target={'_blank'}
                        href={'https://www.surveymonkey.de'}
                        rel="noreferrer"
                    >www.surveymonkey.de</a>
                    ) ein. SurveyMonkey Europe UC hat sich vertraglich verpflichtet, bei
                    SurveyMonkey Inc. (Sitz in der USA) Standardvertragsklauseln für die im Rahmen der
                    Leistungserbringung erfolgende Übermittlung von Daten an SurveyMonkey Inc. einzubinden. Die
                    Datenschutzerklärung können sie
                    unter <a
                        target={'_blank'}
                        href={'https://www.surveymonkey.de/mp/legal/privacy-policy/'}
                        rel="noreferrer"
                    >https://www.surveymonkey.de/mp/legal/privacy-policy/</a> einsehen.
                </p>
                <h3>
                    Google reCAPTCHA
                </h3>
                <p>
                    Wir nutzen “Google reCAPTCHA” (im Folgenden “reCAPTCHA”) auf unseren Websites. Anbieter ist die
                    Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”).
                </p>
                <p>
                    Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf unseren Websites (z.B. in einem
                    Kontaktformular) durch einen Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu
                    analysiert reCAPTCHA das Verhalten des Websitebesuchers anhand verschiedener Merkmale. Diese Analyse
                    beginnt automatisch, sobald der Websitebesucher die Website betritt. Zur Analyse wertet reCAPTCHA
                    verschiedene Informationen aus (z.B. IP-Adresse, Verweildauer des Websitebesuchers auf der Website
                    oder vom Nutzer getätigte Mausbewegungen). Die bei der Analyse erfassten Daten werden an Google
                    weitergeleitet.
                </p>
                <p>
                    Die reCAPTCHA-Analysen laufen vollständig im Hintergrund. Websitebesucher werden nicht darauf
                    hingewiesen, dass eine Analyse stattfindet.
                </p>
                <p>
                    Die Datenverarbeitung erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat
                    ein berechtigtes Interesse daran, seine Webangebote vor missbräuchlicher automatisierter Ausspähung
                    und vor SPAM zu schützen.
                </p>
                <p>
                    Die Nutzungsbedingungen für Dienste und Produkte von Google können Sie unter
                    <a
                        target={'_blank'}
                        href={'https://policies.google.com/terms?hl=de-AT'}
                        rel="noreferrer"
                    >https://policies.google.com/terms?hl=de-AT</a> nachlesen.
                    Weitere Informationen zu Google reCAPTCHA sowie die Datenschutzerklärung von Google entnehmen Sie
                    folgenden Links:
                    <a
                        target={'_blank'}
                        href={'https://www.google.com/intl/de/policies/privacy/ '}
                        rel="noreferrer"
                    >https://www.google.com/intl/de/policies/privacy/</a> und
                    <a
                        target={'_blank'}
                        href={'https://www.google.com/recaptcha/intro/android.html'}
                        rel="noreferrer"
                    >https://www.google.com/recaptcha/intro/android.html</a>.
                </p>
                <h3>
                    Cookies
                </h3>
                <p>
                    Cookies sind kleine Dateien, die auf Ihrem Endgerät abgelegt werden. Diese können genutzt werden, um
                    festzustellen, ob von Ihrem Endgerät bereits eine Kommunikation zu unseren Seiten bestanden hat. Es
                    wird lediglich das Cookie auf Ihrem Endgerät identifiziert. Personenbezogene Daten können dann in
                    Cookies gespeichert werden, wenn Sie zugestimmt haben oder dies technisch unbedingt erforderlich
                    ist, z.B. um einen geschützten Login zu ermöglichen.
                </p>
                <p>
                    Nachfolgend die von uns bzw. unseren Toolpartnern eingesetzten Cookies:
                    <a
                        target={'_blank'}
                        href={'https://www.ihse.de/cookie-erklaerung/'}
                        rel="noreferrer"
                    >https://www.ihse.de/cookie-erklaerung/</a>
                </p>
                <p>
                    1. Erforderliche oder unverzichtbare Cookies
                    (Beispiele: BIPS- und Anmeldesitzungs-Cookies)
                    Diese Cookies sind erforderlich, damit die Websites ordnungsgemäß funktionieren. Beispielsweise zur
                    Authentifizierung. Wenn Sie sich bei unseren Websites anmelden, werden Cookies für die
                    Authentifizierung gesendet, anhand derer wir während einer Browsersitzung erfahren, wer Sie sind.
                </p>
                <p>
                    2. Cookies für die Funktionalität
                    (Beispiel: Cookies für die Spracheinstellung)
                    Mit diesen Cookies werden bestimmte zusätzliche Funktionen auf den Websites aktiviert.
                    Beispielsweise das Speichern Ihrer Einstellungen (wie Benutzername und Sprachauswahl) und das
                    Verhindern der mehrmaligen Teilnahme an ein und derselben Umfrage. Hierdurch wird die Funktionalität
                    der Benutzerfreundlichkeit erhöht.

                </p>
                <p>
                    3. Cookies für Voreinstellungen
                    (z. B. RTP-Cookies zur Anpassung von Inhalten)
                    Cookies für Voreinstellungen, erfassen welche Optionen Sie ausgewählt und welche Einstellungen Sie
                    vorgenommen haben. So können wir beispielsweise Ihre Spracheinstellungen speichern und die Websites
                    Ihren Wünschen entsprechend anpassen.
                </p>
                <p>
                    4. Social-Media-Cookies
                    Wir verwenden auf unserer Website Social Media Cookies (z.B. Facebook, Google Plus) für
                    verhaltensbasierte Werbung, Analytics, Werbung und Marktforschung. Mit Social-Media-Cookies werden
                    Informationen zur Nutzung sozialer Medien erfasst.
                </p>
                <p>
                    5. Analyse-Cookies
                    (Beispiel für Drittanbieter-Cookies: Optimizely/Google Analytics)
                    Analyse-Cookies erfassen Informationen zu Ihrer Nutzung der Websites und ermöglichen es, die
                    Funktionalität der Websites zu optimieren. Beispielsweise können wir Analyse-Cookies entnehmen,
                    welches die meistbesuchten Seiten auf den Websites sind, welche Schwierigkeiten Sie möglicherweise
                    beim Besuch der Websites haben und ob die von uns eingestellte Werbung wirksam ist oder nicht. Auf
                    diese Weise können wir allgemeine (statt individueller) Nutzungsmuster für die Websites ermitteln.
                    Wir nutzen die Informationen zur Analyse des Taffics der Websites.
                </p>
                <p>
                    6. Cookies für die Zielgruppenadressierung und Werbung
                    (Beispiel für Drittanbieter-Cookies mit Zielgruppenadressierung: AdRoll/Google Analytics)
                    Wenn Sie unsere Website nutzen, stimmen Sie der Nutzung und Speicherung von Cookies auf Ihrem
                    Endgerät zu. Sie können unsere Website aber auch ohne Cookies betrachten. Die meisten Browser
                    akzeptieren Cookies automatisch. Sie können das Speichern von Cookies auf Ihrem Endgerät verhindern,
                    indem Sie in Ihren Browser-Einstellungen *keine Cookies akzeptieren* wählen. Auf Ihrem Endgerät
                    können Sie bereits gesetzte Cookies jederzeit löschen. Wie das im Einzelnen funktioniert, entnehmen
                    Sie bitte der Anleitung Ihres Browser bzw. Endgeräte Herstellers. Weitere Informationen zur
                    Deaktivierung von Local Shared Objects finden Sie hier. Wenn Sie keine Cookies akzeptieren, kann
                    dieses aber zu Funktionseinschränkungen unserer Angebote führen.
                </p>
                <h3>
                    Bestellung / Kundenbestellung
                </h3>
                <h4>
                    KVM
                </h4>
                <p>
                    Zur Erstellung eines Angebots über einen KVM-System benötigen wir personenbezogenen Daten (Vor- und
                    Nachname, Anschrift, Email-Anschrift und Telefonnummer), die auf der Grundlage des Art. 6 Abs. 1
                    lit. A DSGVO erhoben werden.
                </p>
                <p>
                    Wenn Sie uns schriftlich mitteilen (
                    <a href={'mailto:sales@ihse.de'}>sales@ihse.de</a>
                    ), dass Sie das Angebot nicht annehmen möchten, löschen wir Ihre Daten nach 6 Wochen.
                </p>
                <p>
                    Bei der Eröffnung eines Kundeskontos/Benutzerpostfach, erheben wir Ihre personenbezogenen Daten in
                    dem dort angegebenen Umfang. Mit Eingabe Ihrer Daten stimmen Sie der Speicherung Ihrer
                    personenbezogenen Datei zu. Die Datenverarbeitung dient dem Zweck, Sie zu unterstützen und zu
                    betreuen. Die Datenerhebung erfolgt auf der Grundlage des Art. 6 Abs. 1 lit. A DSGVO.
                </p>
                <p>
                    Sie können Ihre Einwilligung jederzeit durch Mitteilung an uns widerrufen, ohne dass die
                    Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.
                    Ihr Kundenkonto wird nach 6 Wochen gelöscht.
                </p>
                <h3>
                    Widerspruchsrecht
                </h3>
                <p>
                    Sie haben jederzeit das Recht, gegen die Verarbeitung Ihrer personenbezogenen Daten Widerspruch
                    einzulegen.
                    Wenn sie von Ihren Widerspruchsrecht Gebrauch machen, beenden wir die Verarbeitung Ihrer
                    personenbezogenen Daten. Wenn wir zwingende schutzwürdige Gründe für die Verarbeitung nachweisen
                    können, die Ihre Interessen, Grundrechte und Grundfreiheiten überwiegen, bleibt eine
                    Weiterverarbeitung vorbehalten. Ebenso bei einer Geltendmachung, Ausübung oder Verteidigung von
                    Rechtsansprüchen.
                </p>
            </TextContainer>
        );
    };

    /**
     * This texts are from https://www.ihse.com/data-privacy/
     * @see https://www.ihse.com/data-privacy/
     *
     * @returns {*}
     */
    renderEn = () => {
        return (
            <TextContainer autoStyleChildren={true}>
                <p>
                    The protection of your privacy is crucial for the IHSE GmbH. Below we will inform you in detail
                    about the handling of your data.
                </p>
                <h3>
                    Collection and processing of personal data
                </h3>
                <p>
                    When you visit our website we do not capture any personal data (for example name, address, phone
                    number,
                    email addresses) unless you volunteer such data to us. With the complete processing of the request
                    your
                    data is collected, used and stored in accordance with the German Teleservices Act and the Federal
                    Data
                    Protection Act. We use the data provided by you to process your request. A revocation is possible at
                    any
                    time.
                </p>
                <p>
                    When you access our website via telecommunication services, communication-related information (for
                    example IP address) respectively usage-related information (for example information on the start of
                    use
                    and duration as well as on the telecommunication services used by you) is automatically generated
                    with
                    technical means. This may allow inferences to personal data. As far as a collection, processing and
                    use
                    of your communication or usage-related information is absolutely necessary, this is subject to the
                    statutory provisions on data protection.
                </p>
                <h3>
                    Transfer and use of personal data
                </h3>
                <p>
                    IHSE GmbH does not carry out a transfer of your data to third parties for advertising purposes. We
                    use
                    the data provided by you via email to answer your inquiries and for a contact through IHSE.
                </p>
                <p>
                    Personal data that you have communicated to us through a contact request, a newsletter subscription
                    or
                    direct business relationships are processed and maintained by us using a customer relationship
                    management system (CRM system).
                </p>
                <p>
                    The currently used CRM system is operated and offered by Zoho Corporation, 4141 Hacienda Drive
                    Pleasanton, CA 94588, USA. More information can be found on the Zoho website:
                    <a
                        target={'_blank'}
                        href={'https://www.zoho.com/de/privacy.html'}
                        rel="noreferrer"
                    >https://www.zoho.com/de/privacy.html</a>
                </p>
                <p>
                    The data transmitted to Zoho serve only for the purpose of the desired contact and are used only for
                    this purpose, not for the newsletter dispatch. To this you must agree separately.
                </p>
                <p>
                    By ordering the newsletters, we will save your email address. The order of the newsletter will be
                    logged
                    for verification purposes. You can unsubscribe at any time, for example via the “unsubscribe” link
                    at
                    the bottom of each newsletter. After unsubscribing the newsletter your email address will be
                    deleted.
                </p>
                <h3>
                    Right to information
                </h3>
                <p>
                    According to the Federal Data Protection Act, you have a right to free information about your stored
                    data as well as, if necessary, a right to correction, blocking or deletion of this data.
                </p>
                <h3>
                    Contact for data privacy
                </h3>
                <p>
                    If you have any questions about data protection, please contact our data protection officer:

                    Ulrich Behnke
                    Büro BUL-MA
                    Dornierstraße 4
                    88677 Markdorf
                    Tel.: 07544-949 99 09
                    Email: <a href={'mailto:ulrich.behnke@bul-ma.de'}>ulrich.behnke@bul-ma.de</a>
                </p>
                <h3>
                    Protection of the stored data
                </h3>
                <p>
                    In order to protect your personal data from loss, access by unauthorized persons, destruction and
                    manipulation, we use technical / organizational measures. These measures are continuously adapted,
                    improved and correspond to the state of the art. It can not be ruled out that unencrypted data can
                    be
                    viewed by third parties. We point out that in case of data transmission over the Internet (for
                    example
                    in the case of email communication) a secure transmission is not guaranteed. Sensitive data should
                    not
                    be transmitted at all or only over a secure connection (TLS/SSL).
                </p>
                <h3>
                    Data retention/deletion
                </h3>
                <p>
                    Your data will be processed for the purpose for which it was collected or you consented to. If you
                    don’t
                    wish to continue your data storage, you must inform us in writing.
                </p>
                <h3>
                    Protection of minors
                </h3>
                <p>
                    The consent to the processing of personal data can only be given by a person of legal age. Data of
                    minors are generally not collected by us. Upon notification of such processing, the data will be
                    deleted
                    immediately.
                </p>
                <h3>
                    Online application
                </h3>
                <p>
                    Based on the legal requirements (Art. 6 (1) lit. b and f GDPR and Art. 26 Federal Data Protection
                    Act), we process your applicant data only in the context of the application process. The data
                    processing takes place in order to fulfill our (pre-) contractual obligations.
                </p>
                <p>
                    The data required for sending the application documents are marked in the online form. The necessary
                    data in the application documents can be found in the job description. This includes personal
                    information, postal and contact address, cover letter, CV and certificates. You can also provide
                    information about you voluntary.
                </p>
                <p>
                    By submitting your application, you agree that your data may be processed for the purpose of the
                    application process, based on the Privacy Policy.
                </p>
                <p>
                    The personal data of special categories voluntarily provided by you (for example, health data or
                    ethnic origin) will be additionally processed in accordance with Art. 9 para. b GDPR. If personal
                    data for practicing the profession is requested in the application process, in terms of Art. 9 (1)
                    of the GDPR, that data is additionally processed in accordance with Art. 9 (2) lit. a GDPR.
                </p>
                <p>
                    You can also send us your application by e-mail. Please note that the data is not encrypted and can
                    therefore be read by third parties. We cannot take responsibility for this transmission route.
                </p>
                <p>
                    Furthermore, you have the opportunity to send us the application by mail.
                </p>
                <p>
                    In the case of a successful application, the data provided by you can be further processed. Could we
                    not consider you unfortunately, your data will be deleted. The same applies to a withdrawn
                    application.
                </p>
                <p>
                    If a legitimate revocation is not ensued by you, your data will be deleted after six months. We need
                    this period in order to be able to answer any follow-up questions regarding the application and to
                    be able to fulfill the obligation to provide evidence under the General Equal Treatment Act (AGG).
                    Invoices for any travel expenses refunds are stored and archived based on tax regulations.
                </p>
                <p>
                    You can revoke your consent in writing at any time. Please direct your revocation stating your full
                    name and e-mail address to our data protection officer.
                </p>
                <h3>
                    Analytics programs and cookies
                </h3>
                <p>
                    When you access our website, information is collected that is not associated with a particular
                    person (for example Internet browser and operating system used, domain name of the website from
                    which you came, number of visits, average time spent, pages accessed). We and our affiliate use this
                    information to determine the attractiveness of our websites and to improve their performance and
                    content.
                </p>
                <h3>
                    Leadfeeder
                </h3>
                <p>
                    In order to better adapt our homepage to your needs, we use the tool leadfeeder
                    (
                    <a
                        target={'_blank'}
                        href={'https://www.leadfeeder.com'}
                        rel="noreferrer"
                    >www.leadfeeder.com</a>
                    ). Your IP address is recorded and evaluated. Leadfeeder uses Google Analytics
                    for evaluation. You can view the privacy policy
                    at <a
                        target={'_blank'}
                        href={'https://www.leadfeeder.com/privacy'}
                        rel="noreferrer"
                    >www.leadfeeder.com/privacy</a>. The seat of the
                    company is in Finland.
                </p>
                <h3>
                    Google Analytics
                </h3>
                <p>
                    Our website uses Google Analytics, a web analytics service provided by Google Inc. („Google“).
                    Google Analytics uses so-called „cookies“ text files that are stored on your computer and that allow
                    an analysis of the use of the website by them. The information generated by the cookie about your
                    use of this website is usually transmitted to a Google server in the US and stored there. Your IP
                    address will be shortened beforehand by Google within the member states of the European Union or in
                    other contracting states of the Agreement on the European Economic Area. Only in exceptional cases
                    will the full IP address be sent to a Google server in the US and shortened there. On behalf of
                    Zeppelin, Google will use this information to evaluate its use of the website, to compile reports on
                    the web activities and to provide us with other services related to website and internet usage. The
                    IP address provided from your browser by Google Analytics within the framework of Google Analytics
                    will not be merged with other data provided by Google. You may refuse the use of cookies by
                    selecting the appropriate settings on your browser, however please note that if you do this you may
                    not be able to use the full functionality of this website. You may also prevent the collection of
                    the cookie—generated and website-related data (including your IP address) to Google and the
                    processing of such data by Google by downloading the browser plug-in available at the following link
                    (
                    <a
                        target={'_blank'}
                        href={'https://tools.google.com/dlpage/gaoptout?hl=de'}
                        rel="noreferrer"
                    >https://tools.google.com/dlpage/gaoptout?hl=de</a>
                    ) and install.
                </p>
                <p>
                    More information about Google Inc. And Google Analytics can be found at: (www.google.com).
                    The privacy policy of Google can be found at: (
                    <a
                        target={'_blank'}
                        href={'https://www.google.com/intl/de/privacypolicy.html'}
                        rel="noreferrer"
                    >www.google.com/intl/de/privacypolicy.html</a>
                    )
                </p>
                <h3>
                    Zoho SalesIQ
                </h3>
                <p>
                    When visiting our website, anonymised usage data is collected using Zoho SalesIQ
                    (https://zoho.com/salesiq), a web analytics service provided by Zoho Corporation, 4141 Hacienda
                    Drive, Pleasanton, California 94588, USA. SalesIQ uses so-called “cookies”, which allow an analysis
                    of our website. These cookies also collect information about how visitors use the website, which
                    website the user came from, the number of visits of each user and the length of stay on the
                    websites. You can prevent this “tracking” by appropriate settings in your browser. We store this
                    data for statistical purposes only. The IP addresses are shortened within the framework of the
                    legislation to the last places, in order to ensure anonymity.
                </p>
                <p>
                    Zoho is certified under the EU-US Privacy Shield. The following links contain the privacy policy and
                    cookie policy of Zoho Corporation:
                    <ul>
                        <li>
                            <a
                                target={'_blank'}
                                href={'https://www.zoho.com/de/privacy.html'}
                                rel="noreferrer"
                            >
                                https://www.zoho.com/de/privacy.html
                            </a>
                        </li>
                        <li>
                            <a
                                target={'_blank'}
                                href={'https://www.zoho.com/de/privacy/cookie-policy.html'}
                                rel="noreferrer"
                            >
                                https://www.zoho.com/de/privacy/cookie-policy.html
                            </a>
                        </li>
                    </ul>
                </p>
                <h3>
                    Zoho Campaigns
                </h3>
                <p>
                    For the distribution of newsletters and their evaluation, we use Zoho Campaigns, an E-mail marketing
                    software from Zoho Corporation, 4141 Hacienda Drive, Pleasanton, California 94588, USA.
                </p>
                <p>
                    When signing up for the newsletter, your data will be processed in our CRM system and then
                    transferred to the newsletter tool Zoho Campaigns. On our behalf Zoho Campaigns uses this
                    information for the distribution and statistical evaluation of the newsletter. For the evaluation,
                    the emails sent include web beacons or tracking pixels that represent one-pixel image files that are
                    integrated in our HTML mails. So it can be determined if a newsletter message has been opened and
                    which links have been clicked. In addition, technical information is collected (e.g., time of
                    retrieval, IP address, browser type and operating system). This data is used for the statistical
                    analysis of newsletter campaigns. The resulting results can be used to better tailor future
                    newsletters to the interests of the recipient. If you want to object to the data analysis for
                    statistical evaluation purposes, you must unsubscribe from the newsletter. Zoho Campaigns does not
                    use the data of the newsletter recipients to write to them themselves.
                </p>
                <p>
                    To safeguard your data in the US, Zoho Corporation and IHSE have entered into a („Data Processing
                    Agreement”) based on the standard contract clauses of the European Commission to enable the transfer
                    of your personal information to Zoho Campaigns. In addition, Zoho Campaigns is certified according
                    to the US-European Data Protection Agreement „Privacy Shield” and is committed to complying with EU
                    data protection regulations. The privacy policy of Zoho Campaigns can be found at:
                    <a
                        target={'_blank'}
                        href={'https://www.zoho.com/privacy.html'}
                        rel="noreferrer"
                    >https://www.zoho.com/privacy.html</a>
                </p>
                <h3>
                    OptinMonster
                </h3>
                <p>
                    OptinMonster is a plugin of the company Retyp, LLC., 7732 Maywood Crest Dr., West Palm Beach, FL
                    33412, US (
                    <a
                        target={'_blank'}
                        href={'https://optinmonster.com'}
                        rel="noreferrer"
                    >https://optinmonster.com</a>
                    ).
                </p>
                <p>
                    This allows us to provide our visitors with additional offers via pop-up on our website, for
                    example, to enable registration of the e-mail address in a newsletter or to point out actions.
                    OptinMonster uses cookies for this purpose. Personal data is only collected through an active action
                    of the customer (for example, the customer signs up for the newsletter via a popup). OptinMonster
                    does not store the collected data on its own servers, but forwards it to an IHSE selected tool.
                </p>
                <p>
                    We have entered into an order processing contract with OptinMonster, in which we oblige OptinMonster
                    to protect your data in accordance with the applicable provisions of the GDPR. Details on the
                    handling of the customer’s personal data by OptinMonster are described in more detail in
                    OptinMonster’s privacy policy (
                    <a
                        target={'_blank'}
                        href={'https://optinmonster.com/privacy/'}
                        rel="noreferrer"
                    >https://optinmonster.com/privacy/</a>
                    ).
                </p>
                <h3>
                    SurveyMonkey
                </h3>
                <p>
                    To create surveys and their evaluation, we use the tool from Surveymonkey (
                    <a
                        target={'_blank'}
                        href={'https://www.surveymonkey.de'}
                        rel="noreferrer"
                    >www.surveymonkey.de</a>
                    ).
                    Surveymonkey Europe UC has a contractual commitment to incorporate standard contractual clauses with
                    Surveymonkey Inc. (US based) for the provision of data to SurveymonkeyInc. as part of the provision
                    of services. The privacy policy of Serveymonkey can be found at:
                    (
                    <a
                        target={'_blank'}
                        href={'https://www.surveymonkey.de/mp/legal/privacy-policy'}
                        rel="noreferrer"
                    >www.surveymonkey.de/mp/legal/privacy-policy</a>
                    ).
                </p>
                <h3>
                    Google reCAPTCHA
                </h3>
                <p>
                    We use “Google reCAPTCHA” (hereinafter “reCAPTCHA”) on our websites. Provider is Google Inc., 1600
                    Amphitheatre Parkway, Mountain View, CA 94043, USA (“Google”).
                </p>
                <p>
                    With reCAPTCHA it shall be checked whether the data entry on our web pages (for example in a contact
                    form) is done by a human or by an automated program. For this, reCAPTCHA analyzes the behavior of
                    the website visitor based on various characteristics. This analysis begins automatically as soon as
                    the website visitor enters the website. For analysis, reCAPTCHA evaluates various information (for
                    example, the IP address, the website visitor’s visit time on the website, or user mouse movements).
                    The data collected during the analysis will be forwarded to Google.
                </p>
                <p>
                    The reCAPTCHA analyzes are completely in the background. Website visitors are not advised that an
                    analysis is taking place.
                </p>
                <p>
                    The data processing is based on Art. 6 (1) (f) GDPR. The Website operator has a legitimate interest
                    in protecting its websites from abusive automated spying and SPAM. Google’s Terms of Service and
                    Products can be found
                    at <a
                        target={'_blank'}
                        href={'https://policies.google.com/terms?hl=en-AT'}
                        rel="noreferrer"
                    >https://policies.google.com/terms?hl=en-AT</a>.
                </p>
                <p>
                    For more information about Google reCAPTCHA and Google’s privacy policy, please visit the following
                    links:
                    <a
                        target={'_blank'}
                        href={'https://www.google.com/intl/en/policies/privacy/'}
                        rel="noreferrer"
                    >https://www.google.com/intl/en/policies/privacy/</a> and
                    <a
                        target={'_blank'}
                        href={'https://www.google.com/recaptcha/intro/android.html'}
                        rel="noreferrer"
                    >https://www.google.com/recaptcha/intro/android.html</a>.
                </p>
                <h3>
                    Cookies
                </h3>
                <p>
                    Cookies are small files that are stored on your device. These can be used to determine if there has
                    already been a communication from your device to our sites. Only the cookie on your device will be
                    identified. Personal data may then be stored in cookies if you have agreed or it is technically
                    required, for example to allow a protected login.
                </p>
                <p>
                    Below are the cookies we or our tool partners use:
                    <a
                        target={'_blank'}
                        href={'https://www.ihse.com/cookie-declaration/'}
                        rel="noreferrer"
                    >https://www.ihse.com/cookie-declaration/</a>
                </p>
                <p>
                    1. Required or indispensable cookies
                    (Examples: BIPS cookies and logon session cookies)
                    These Cookies are required for the websites to work properly. For example, cookies for
                    authentication. When you login to our websites, cookies are sent for authentication so we can tell
                    who you are during a browser session.
                </p>
                <p>
                    2. Cookies for functionality
                    (Example: cookies for the language setting)
                    These cookies enable certain additional features on the websites. For example, saving your settings
                    (such as username and language selection) and preventing multiple participation in one and the same
                    survey. This increases the functionality of the user friendliness.
                </p>
                <p>
                    3. Cookies for preferences
                    (For example: RTP cookies to customize content)
                    Cookies for preferences record which options you have selected and what settings you have made. For
                    example, they can save your language settings and customize the website to your liking.
                </p>
                <p>
                    4. Social media cookies
                    We use social media cookies (such as Facebook, Google Plus) on our website for behavior-based
                    advertising, analytics, advertising and market research. Social media cookies are used to gather
                    information about the use of social media.
                </p>
                <p>
                    5. Analysis cookies
                    (Example of third-party cookies: Optimizely/Google Analytics)
                    Analysis cookies collect information about your use of the websites and make it possible to optimize
                    the functionality of the websites. For example, we may extract from the analysis cookies, which are
                    the most visited pages of our websites, what difficulties you may have in visiting the websites and
                    whether or not the advertising we provide is effective. In this way, we can identify general (rather
                    than individual) usage patterns for our websites. We use the information to analyze the traffic of
                    the websites.
                </p>
                <p>
                    6. Cookies for audience targeting and advertising
                    (Example of third-party cookies with audience targeting: AdRoll/Google Analytics)
                    When you use our website, you agree to the use and storage of cookies on your device. You can also
                    view our website without cookies. Most browser automatically accept cookies. You can prevent the
                    storage of cookies on your device by choosing *not to accept cookies* in your browser settings. On
                    your device, you can delete previously set cookies at any time. How this works in detail, please
                    refer to the instructions of your browser or device manufacturer. More information about disabling
                    Local Shared Objects can be found here. If you do not accept cookies, this may lead to functional
                    restrictions of our offers.
                </p>
                <h3>
                    Social Media Plugins
                </h3>
                <h4>
                    1) Facebook
                </h4>
                <p>
                    Our website uses social plugins („plugins”) from the social network Facebook (Facebook Inc., 1
                    Hacker Way, Menlo Park, CA 94025, USA).
                </p>
                <p>
                    In order to increase the protection of your data when visiting our website, the plugin with Shariff
                    Wrapper is integrated into the page. This prevents the transmission of user data to Facebook when a
                    web page is called. Only when you click on the plugin and thus give your consent (Article 6 (1) (a)
                    GDPR) to the privacy policy, does your browser establish a direct connection to the Facebook
                    servers.
                </p>
                <p>
                    The content of the respective plugin is transmitted directly to your browser and integrated into the
                    page. The plugin then transmits data (including your IP address) to Facebook. By integrating the
                    plugins, Facebook also receives information that your browser has accessed the corresponding page of
                    our website if you do not have a profile on Facebook or are logged in. When interacting with the
                    plugins, the information is transmitted directly to a Facebook server and stored there. The
                    information will also be posted on Facebook and displayed there to your contacts.
                </p>
                <p>
                    Based in the US, Facebook Inc. is certified to the US-European Data Protection Convention „Privacy
                    Shield”, which ensures compliance with the level of data protection in the EU.
                </p>
                <p>
                    For information on purpose, extent of data collection and further use of your data, please refer to
                    the privacy policy at
                    <a
                        target={'_blank'}
                        href={'http://www.facebook.com/policy.php'}
                        rel="noreferrer"
                    >http://www.facebook.com/policy.php</a>
                </p>
                <h4>
                    2) LinkedIn
                </h4>
                <p>
                    Our website uses so-called social plugins („plugins”) of the online service LinkedIn (LinkedIn
                    Corporation, 2029 Stierlin Court, Mountain View, CA 94043, USA).
                </p>
                <p>
                    In order to increase the protection of your data when visiting our website, the plugin with Shariff
                    Wrapper is integrated into the page. This prevents the transmission of user data to LinkedIn when a
                    web page is called. Only when you click on the plugin and thus give your consent (Article 6 (1) (a)
                    GDPR) to the privacy policy, does your browser establish a direct connection to the LinkedIn
                    servers.
                </p>
                <p>
                    The content of the respective plugin is transmitted directly to your browser and integrated into the
                    page. The plugin then transmits data (including your IP address) to LinkedIn. By integrating the
                    plugins, LinkedIn also receives information that your browser has accessed the corresponding page of
                    our website if you do not have a profile on LinkedIn or are logged in. When interacting with the
                    plugins, the information is transmitted directly to a LinkedIn server and stored there. The
                    information will also be posted on LinkedIn and displayed there to your contacts.
                </p>
                <p>
                    Based in the US, LinkedIn Inc. is certified to the US-European Data Protection Convention „Privacy
                    Shield”, which ensures compliance with the level of data protection in the EU.
                </p>
                <p>
                    For information on purpose, extent of data collection and further use of your data, please refer to
                    the privacy policy at
                    <a
                        target={'_blank'}
                        href={'https://www.linkedin.com/legal/privacy-policy'}
                        rel="noreferrer"
                    >https://www.linkedin.com/legal/privacy-policy</a>
                </p>
                <h4>
                    3) Twitter
                </h4>
                <p>
                    Our website uses so-called social plugins („plugins”) of the microblogging service Twitter (Twitter
                    Inc., 1355 Market St, Suite 900, San Francisco, CA 94103, USA).
                </p>
                <p>
                    In order to increase the protection of your data when visiting our website, the plugin with Shariff
                    Wrapper is integrated into the page. This prevents the transmission of user data to Twitter when a
                    web page is called. Only when you click on the plugin and thus give your consent (Article 6 (1) (a)
                    GDPR) to the privacy policy, does your browser establish a direct connection to the Twitter servers.
                </p>
                <p>
                    The content of the respective plugin is transmitted directly to your browser and integrated into the
                    page. The plugin then transmits data (including your IP address) to Twitter. By integrating the
                    plugins, Twitter also receives information that your browser has accessed the corresponding page of
                    our website if you do not have a profile on Twitter or are logged in. When interacting with the
                    plugins, the information is transmitted directly to a Twitter server and stored there. The
                    information will also be posted on Twitter and displayed there to your contacts.
                </p>
                <p>
                    Based in the US, Twitter Inc. is certified to the US-European Data Protection Convention „Privacy
                    Shield”, which ensures compliance with the level of data protection in the EU.
                </p>
                <p>
                    For information on purpose, extent of data collection and further use of your data, please refer to
                    the privacy policy at
                    <a
                        target={'_blank'}
                        href={'https://twitter.com/privacy'}
                        rel="noreferrer"
                    >https://twitter.com/privacy</a>
                </p>
                <h4>
                    4) YouTube
                </h4>
                <p>
                    On our website, we use the YouTube embedding feature to view and play videos from the provider
                    „YouTube” (Google LLC., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA).
                </p>
                <p>
                    In order to increase the protection of your data when visiting our website, the plugin with Shariff
                    Wrapper is integrated into the page. This prevents the transmission of user data to YouTube when a
                    web page is called. Only when you click on the plugin and thus give your consent (Article 6 (1) (a)
                    GDPR) to the privacy policy, does your browser establish a direct connection to the YouTube servers.
                </p>
                <p>
                    An advanced privacy mode is used here, according to the provider information storage of user
                    information is only started when playing the video or videos in motion. When YouTube videos start
                    playing, the provider „YouTube” uses cookies to collect usage information. Hints from „YouTube”,
                    this should among other things serve to capture video statistics, improve usability, and prevent
                    abusive practices. If you’re logged in to Google, your data will be assigned directly to your
                    account when you click a video. If you do not wish to be associated with your profile on YouTube,
                    you must log out before activating the button. Google stores your data (even for non-logged in
                    users) as a user profile and evaluates them.
                </p>
                <p>
                    You have a right to object to the formation of these usage profiles. To do this, you must contact
                    YouTube directly to exercise the opposition.
                </p>
                <p>
                    Based in the US, Google LLC. is certified to the US-European Data Protection Convention „Privacy
                    Shield”, which ensures compliance with the level of data protection in the EU.
                </p>
                <p>
                    For information on purpose, extent of data collection and further use of your data, please refer to
                    the privacy policy at
                    <a
                        target={'_blank'}
                        href={'https://www.google.de/intl/de/policies/privacy'}
                        rel="noreferrer"
                    >https://www.google.de/intl/de/policies/privacy</a>
                </p>
                <h4>
                    5) GooglePlus
                </h4>
                <p>
                    Our website uses social plugins („plugins”) of the online service Google Plus (Google LLC., 1600
                    Amphitheatre Parkway, Mountain View, CA 94043, USA).
                </p>
                <p>
                    In order to increase the protection of your data when visiting our website, the plugin with Shariff
                    Wrapper is integrated into the page. This prevents the transmission of user data to Google Plus when
                    a web page is called. Only when you click on the plugin and thus give your consent (Article 6 (1)
                    (a) GDPR) to the privacy policy, does your browser establish a direct connection to the Google Plus
                    servers.
                </p>
                <p>
                    The content of the respective plugin is transmitted directly to your browser and integrated into the
                    page. The plugin then transmits data (including your IP address) to Google Plus. By integrating the
                    plugins, GooglePlus also receives information that your browser has accessed the corresponding page
                    of our website if you do not have a profile on GooglePlus or are logged in. When interacting with
                    the plugins, the information is transmitted directly to a GooglePlus server and stored there. The
                    information will also be posted on LinkedIn and displayed there to your contacts.
                </p>
                <p>
                    Google analyses all available information and links information from different services (such as
                    Google Plus and YouTube).
                </p>
                <h3>
                    Order / customer order
                </h3>
                <h4>
                    KVM
                </h4>
                <p>
                    In order to be able to make an offer for a KVM System, we require some personal data in accordance
                    with Art. 6 Para. 1 lit. A GDPR (first and last name, address, email address and telephone number).
                </p>
                <p>
                    If you then subsequently inform (
                    <a
                        target={'_blank'}
                        href={'mailto:sales@ihse.de'}
                        rel="noreferrer"
                    >sales@ihse.de</a>
                    ) us that you do not wish to accept the offer, we will delete your data after 6 weeks.
                </p>
                <p>
                    When setting up a customer account / user mailbox, we collect your personal data as specified. By
                    entering your data, you agree to the storage of your personal data. The data processing allows us to
                    support you and look after you. The data is collected according to Art. 6 Para. 1 lit. A GDPR.
                </p>
                <p>
                    You can revoke your consent at any time by notifying us. This does not affect the lawfulness of the
                    data handling based on your prior consent until you such time as you revoke it. Your customer
                    account will be deleted after 6 weeks.
                </p>
                <h3>
                    Opposition
                </h3>
                <p>
                    You have the right to object to the processing of your personal data at any time. If you exercise
                    your right to object, we will stop the processing of your personal data. If we can demonstrate
                    compelling legitimate reasons for processing that outweigh your interests, fundamental rights and
                    fundamental freedoms, further processing is reserved. Likewise, in the assertion, exercise or
                    defense of legal claims.
                </p>
            </TextContainer>
        );
    };

    render() {
        return (
            <div>
                <Helmet>
                    <title>{PageTitleHelper.getPageTitle(I18n.t('dataProtectionPageTitle'))}</title>
                </Helmet>
                <Header />
                <TitleBanner
                    mode={TitleBannerMode.text}
                    text={I18n.t('dataProtection')}
                />
                {this.renderLocalizedContent()}
            </div>
        );
    }

    renderLocalizedContent = () => {
        return SelectionHelper.get(
            this.props.language,
            {
                [Languages.german]:  this.renderDe(),
                [Languages.english]: this.renderEn(),
            },
            this.renderEn(),
        );
    };
}

const mapStateToProps = (state) => {
    return {
        language: state.settings.language,
    };
};

export default connect(
    mapStateToProps,
    null,
)(Screen);

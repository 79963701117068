//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

import Overlays from '@constants/Overlays';

import AudioTableFormatter            from '../AudioTableFormatter';
import DataTransferTableFormatter     from '../DataTransferTableFormatter';
import DeviceTypeTableFormatter       from '../DeviceTypeTableFormatter';
import MainboardTypeTableFormatter    from '../MainboardTypeTableFormatter';
import MilliampsTableFormatter        from '../MilliampsTableFormatter';
import NumberOfMonitorsTableFormatter from '../NumberOfMonitorsTableFormatter';
import RedundantTableFormatter        from '../RedundantTableFormatter';
import SerialTableFormatter           from '../SerialTableFormatter';
import TableBooleanFilter             from '../TableBooleanFilter';
import TableBooleanOptionalFilter     from '../TableBooleanOptionalFilter';
import TableMultiSelectFilter         from '../TableMultiSelectFilter';
import TableNumericFilter             from '../TableNumericFilter';
import TablePartNumberFormatter       from '../TablePartNumberFormatter';
import TableTextFilter                from '../TableTextFilter';
import TableTextFormatter             from '../TableTextFormatter';
import TableWarningTextFormatter      from '../TableWarningTextFormatter';
import UsbTableFormatter              from '../UsbTableFormatter';
import VideoConnectorTableFormatter   from '../VideoConnectorTableFormatter';

const getAmountColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableNumericFilter,
        formatter:      TableTextFormatter,
        key:            'amount',
        name:           I18n.t('amount'),
        sortable:       true,
        width:          194,
    };
};

const getAudioColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableMultiSelectFilter,
        formatter:      AudioTableFormatter,
        key:            'audio',
        name:           I18n.t('audio'),
        sortable:       true,
        width:          180,
    };
};

const getConfigurableColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableBooleanFilter,
        formatter:      RedundantTableFormatter,
        key:            'configurable',
        name:           I18n.t('configurable'),
        sortable:       true,
        width:          142,
    };
};

const getDataTransferColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableMultiSelectFilter,
        formatter:      DataTransferTableFormatter,
        key:            'dataTransfer',
        name:           (
            overlay !== Overlays.portOverview ?
                I18n.t('dataTransferWithDash') :
                I18n.t('dataTransferSingular')
        ),
        sortable:       true,
        width:          (
            overlay !== Overlays.portOverview ?
                180 :
                250
        ),
    };
};

const getDescriptionColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableTextFilter,
        formatter:      TableTextFormatter,
        key:            I18n.t('descriptionKeyFull'),
        name:           I18n.t('description'),
        sortable:       false,
    };
};

const getDeviceTypeColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableMultiSelectFilter,
        formatter:      DeviceTypeTableFormatter,
        key:            'deviceType',
        name:           I18n.t('deviceType'),
        sortable:       true,
        width:          128,
    };
};

const getMainboardTypeColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableMultiSelectFilter,
        formatter:      MainboardTypeTableFormatter,
        key:            'mainboardType',
        name:           I18n.t('mainboardType'),
        sortable:       true,
        width:          128,
    };
};

const getDisplayPartNumberColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableTextFilter,
        formatter:      TablePartNumberFormatter,
        key:            'displayPartNumber',
        name:           I18n.t('partNumberShort'),
        sortable:       true,
        // Should be at least 160 to make sure all drop down controls fit into place
        // (only necessary when TableMultiSelectFilter is set)
        width: 220,
    };
};

const getExtenderPortsColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableNumericFilter,
        formatter:      TableTextFormatter,
        key:            'extenderPorts',
        name:           I18n.t('extenderPorts'),
        sortable:       true,
        width:          160,
    };
};

const getHeightUnitsColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableNumericFilter,
        formatter:      TableTextFormatter,
        key:            'heightUnits',
        name:           I18n.t('heightUnits'),
        sortable:       true,
        width:          154,
    };
};

const getHintColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableTextFilter,
        formatter:      TableWarningTextFormatter,
        key:            'hintText',
        name:           I18n.t('information'),
        sortable:       false,
    };
};

const getMatrixPortsColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableNumericFilter,
        formatter:      TableTextFormatter,
        key:            'matrixPorts',
        name:           I18n.t('matrixPorts'),
        sortable:       true,
        width:          160,
    };
};

const getMaximumPowerConsumptionInMilliampsColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableNumericFilter,
        formatter:      MilliampsTableFormatter,
        key:            'maximumPowerConsumptionInMilliamps',
        name:           I18n.t('maximumPowerConsumptionShort'),
        sortable:       true,
        width:          130,
    };
};

const getNumberOfMonitorsColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableNumericFilter,
        formatter:      NumberOfMonitorsTableFormatter,
        key:            'numberOfMonitors',
        name:           I18n.t('numberOfMonitors'),
        sortable:       true,
        width:          100,
    };
};

const getPortCountColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableNumericFilter,
        formatter:      TableTextFormatter,
        key:            'portCount',
        name:           I18n.t('portCount'),
        sortable:       true,
        width:          100,
    };
};

const getPowerConsumptionInMilliampsColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableNumericFilter,
        formatter:      MilliampsTableFormatter,
        key:            'powerConsumptionInMilliamps',
        name:           I18n.t('powerConsumptionWithDash'),
        sortable:       true,
        width:          100,
    };
};

const getRedundantColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableBooleanOptionalFilter,
        formatter:      RedundantTableFormatter,
        key:            'redundant',
        name:           I18n.t('redundant'),
        sortable:       true,
        width:          112,
    };
};

const getResolutionColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableMultiSelectFilter,
        formatter:      TableTextFormatter,
        key:            'resolution',
        name:           I18n.t('resolution'),
        sortable:       true,
        width:          114,
    };
};

const getSerialColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableMultiSelectFilter,
        formatter:      SerialTableFormatter,
        key:            'serial',
        name:           I18n.t('serial'),
        sortable:       true,
        width:          110,
    };
};

const getSlotCountColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableNumericFilter,
        formatter:      TableTextFormatter,
        key:            'slotCount',
        name:           I18n.t('slotCount'),
        sortable:       true,
        width:          100,
    };
};

const getTargetSlotCountColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableNumericFilter,
        formatter:      TableTextFormatter,
        key:            'targetSlot',
        name:           I18n.t('targetSlot'),
        sortable:       true,
        width:          100,
    };
};

const getUsbColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableMultiSelectFilter,
        formatter:      UsbTableFormatter,
        key:            'usb',
        name:           I18n.t('usb'),
        sortable:       true,
        width:          122,
    };
};

const getVideoConnectorColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableMultiSelectFilter,
        formatter:      VideoConnectorTableFormatter,
        key:            'videoConnector',
        name:           I18n.t('videoConnector'),
        sortable:       true,
        width:          154,
    };
};

const getSupportedVideoChannelsColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableNumericFilter,
        formatter:      TableTextFormatter,
        key:            'supportedVideoChannels',
        name:           I18n.t('supportedVideoChannels'),
        sortable:       true,
        width:          154,
    };
};

const getVgaColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableBooleanOptionalFilter,
        formatter:      RedundantTableFormatter,
        key:            'vgaSupport',
        name:           I18n.t('vga'),
        sortable:       true,
        width:          154,
    };
};

const getInterlacedUpgradeColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableBooleanOptionalFilter,
        formatter:      RedundantTableFormatter,
        key:            'interlacedUpgrade',
        name:           I18n.t('interlacedUpgrade'),
        sortable:       true,
        width:          154,
    };
};

const getPowerRedundancyColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableBooleanOptionalFilter,
        formatter:      RedundantTableFormatter,
        key:            'powerRedundancy',
        name:           I18n.t('powerRedundancy'),
        sortable:       true,
        width:          154,
    };
};

const getPowerOverEthernetColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableBooleanOptionalFilter,
        formatter:      RedundantTableFormatter,
        key:            'powerOverEthernet',
        name:           I18n.t('powerOverEthernet'),
        sortable:       true,
        width:          154,
    };
};

const getPowerOverFiberColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableBooleanOptionalFilter,
        formatter:      RedundantTableFormatter,
        key:            'powerOverFiber',
        name:           I18n.t('powerOverFiber'),
        sortable:       true,
        width:          154,
    };
};

const getUsbMemoryDeviceSupportColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableBooleanOptionalFilter,
        formatter:      RedundantTableFormatter,
        key:            'usbMemoryDeviceSupport',
        name:           I18n.t('usbMemoryDeviceSupport'),
        sortable:       true,
        width:          154,
    };
};

const getMatrixEndPointsColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableNumericFilter,
        formatter:      TableTextFormatter,
        key:            'matrixEndpoints',
        name:           I18n.t('matrixEndpoints'),
        sortable:       true,
        width:          154,
    };
};

const getSimultaneousDownscalingColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableBooleanOptionalFilter,
        formatter:      RedundantTableFormatter,
        key:            'simultaneousDownscaling',
        name:           I18n.t('simultaneousDownscaling'),
        sortable:       true,
        width:          154,
    };
};

const getUncompressedColumn = (productCategoryType, productSubCategoryType, overlay) => {
    return {
        filterable:     true,
        filterRenderer: TableBooleanOptionalFilter,
        formatter:      RedundantTableFormatter,
        key:            'uncompressed',
        name:           I18n.t('uncompressed'),
        sortable:       true,
        width:          154,
    };
};

export default {
    get: (productCategoryType, productSubCategoryType, overlay) => {
        return {
            amount:                             getAmountColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            audio:                              getAudioColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            configurable:                       getConfigurableColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            dataTransfer:                       getDataTransferColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            description:                        getDescriptionColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            deviceType:                         getDeviceTypeColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            mainboardType:                      getMainboardTypeColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            displayPartNumber:                  getDisplayPartNumberColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            extenderPorts:                      getExtenderPortsColumn(
                productCategoryType,
                productSubCategoryType,
            ),
            heightUnits:                        getHeightUnitsColumn(
                productCategoryType,
                productSubCategoryType,
            ),
            hint:                               getHintColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            matrixPorts:                        getMatrixPortsColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            maximumPowerConsumptionInMilliamps: getMaximumPowerConsumptionInMilliampsColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            numberOfMonitors:                   getNumberOfMonitorsColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            portCount:                          getPortCountColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            powerConsumptionInMilliamps:        getPowerConsumptionInMilliampsColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            redundant:                          getRedundantColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            resolution:                         getResolutionColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            serial:                             getSerialColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            slotCount:                          getSlotCountColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            targetSlot:                         getTargetSlotCountColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            usb:                                getUsbColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            videoConnector:                     getVideoConnectorColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            supportedVideoChannels:             getSupportedVideoChannelsColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            vga:                                getVgaColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            interlacedUpgrade:                  getInterlacedUpgradeColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            powerRedundancy:                    getPowerRedundancyColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            powerOverEthernet:                  getPowerOverEthernetColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            powerOverFiber:                     getPowerOverFiberColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            usbMemoryDeviceSupport:             getUsbMemoryDeviceSupportColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            matrixEndPoints:                    getMatrixEndPointsColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            simultaneousDownscaling:            getSimultaneousDownscalingColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
            uncompressed:                       getUncompressedColumn(
                productCategoryType,
                productSubCategoryType,
                overlay,
            ),
        };
    },
};

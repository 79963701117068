//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import AccessoryTypes  from '@constants/AccessoryTypes';
import DropResult      from '@constants/DropResult';
import ProductSlotType from '@constants/ProductSlotType';

import defaultDragDropConfiguration from './default';

export default {
    dragBehavior: {
        beginDrag: (props) => {
            console.log('Sfp: DragSource: beginDrag (props)', props);

            props.dragStart();

            return (
                {
                    fromSfpIndex: props.index,
                    productId:    props.data.productData.partNo,
                }
            );
        },
        endDrag(props, monitor, component) {
            const dropResult = monitor.getDropResult();
            props.dragStop();

            if (dropResult) {
                const payload    = monitor.getItem();
                const dropEffect = dropResult.dropEffect;

                console.log('Sfp: DragSource: endDrag');
                console.table(payload);
                console.table(dropResult);

                if (!_.isUndefined(dropResult.toSfpIndex)) {
                    const actionPayload = {
                        fromSfpIndex: dropResult.fromSfpIndex,
                        toSfpIndex:   dropResult.toSfpIndex,
                    };

                    if (dropEffect === DropResult.copy) {
                        props.copySfp(actionPayload);
                    } else {
                        props.moveSfp(actionPayload);
                    }
                }
            }
        },
    },
    dragCollect:  defaultDragDropConfiguration.dragCollect,
    dropBehavior: {
        canDrop(props, monitor) {
            const payload = monitor.getItem();

            console.log(
                'Sfp: canDrop: payload, props',
                payload,
                props,
            );

            return (
                (
                    // We can not drop to our self (so the index has to be different)
                    (
                        !_.isUndefined(payload.fromSfpIndex) &&
                        payload.fromSfpIndex !== props.index
                    )
                    ||
                    // We are dragging a new item that is not a chassis
                    (
                        payload.productSlotType &&
                        payload.productSlotType === ProductSlotType.chassisAccessory &&
                        payload.productAccessoryType === AccessoryTypes.sfp
                    )
                )
            );
        },
        drop: (props, monitor, component) => {
            const payload = monitor.getItem();

            console.log('Sfp: DropTarget: drop');
            console.table(payload);

            // This is true when the user drags a new card from the product table to
            // the right panel
            if (!payload.fromSfpIndex) {
                props.setSfp({
                    productId: payload.productId,
                    sfpIndex:  props.index,
                });
            }

            return {
                ...payload,
                toSfpIndex: props.index,
            };
        },
    },
    dropCollect:  defaultDragDropConfiguration.dropCollect,
};

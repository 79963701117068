//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ReactGA from 'react-ga';

import Environment from './Environment';

class GoogleAnalytics {
    static getGoogleAnalyticsId() {
        if (Environment.isStaging()) {
            return 'UA-38852435-4';
        }

        return 'UA-38852435-3';
    }

    static initialize() {
        const googleAnalyticsId = GoogleAnalytics.getGoogleAnalyticsId();

        ReactGA.initialize(googleAnalyticsId);
        ReactGA.set({
            anonymizeIp: true,
        });
    }

    static trackPageView() {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }
}

export default GoogleAnalytics;

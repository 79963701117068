//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const uuidv1 = require('uuid/v1');

class IdGenerator {
    static getNewId(prefix) {
        const newId = uuidv1();

        if (prefix) {
            return `${prefix}-${newId}`;
        }

        return newId;
    }

    static getNewProductId() {
        return IdGenerator.getNewId('product');
    }

    static getNewProjectId() {
        return IdGenerator.getNewId('project');
    }
}

export default IdGenerator;

import React from 'react';

import I18n from 'i18next';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { SmartActions } from '@slices/smart';
import DataProvider     from '@helper/DataProvider';

import styles from './styles.module.scss';
import ProductQuickSelect from '../ProductQuickSelect';
import ProductQuickSelectMode from '../ProductQuickSelect/ProductQuickSelectMode';
import Tooltip from '../Tooltip';
import TooltipTheme from '../Tooltip/TooltipTheme';

const Component = ({
    smartAction,
}) => {
    function getProductQuickSelectProducts() {
        const allProducts = DataProvider.getAllNoEndOfLife(DataProvider.emptyProductTypeRejectList);
        const uniqueProducts = _.uniqBy(allProducts, 'partNo');

        return uniqueProducts;
    }

    function productQuickSelectChanged(option) {
        smartAction({
            productId: option.value,
            triggeredByDoubleClick: true,
        });
    }

    return (
        <div className={styles.quickSearchWrapper}>
            <div className={styles.productQuickSearchWrapper}>
                <ProductQuickSelect
                    hideSingleValue={true}
                    keepFocusAfterChange={true}
                    mode={ProductQuickSelectMode.full}
                    onChange={productQuickSelectChanged}
                    placeholderKey={I18n.t('quickSearch')}
                    getProducts={getProductQuickSelectProducts}
                />
            </div>
            <div className={styles.tooltipWrapper}>
                <Tooltip
                    text={I18n.t('quickSearchTip')}
                    theme={TooltipTheme.orange}
                />
            </div>
        </div>
    );
};

Component.propTypes = {};

Component.defaultProps = {};

const mapDispatchToProps = (dispatch) => bindActionCreators(SmartActions, dispatch);

export default connect(
    null,
    mapDispatchToProps,
)(withTranslation()(Component));

import React from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';

import TestIds from '@constants/TestIds';
import SelectionHelper from '@helper/SelectionHelper';

import LeftPaneAddButtonTheme from './LeftPaneAddButtonTheme';
import styles from './styles.module.scss';
import Icon from '../Icon';
import IconType from '../Icon/IconType';
import PropTypes from '../PropTypes';

const Component = ({
    onClick,
    text,
    theme,
}) => {
    const getThemeClassName = () => {
        return SelectionHelper.get(
            theme,
            {
                [LeftPaneAddButtonTheme.products]: styles.leftPaneAddButtonThemeProducts,
                [LeftPaneAddButtonTheme.singleOrder]: styles.leftPaneAddButtonThemeSingleOrder,
            },
        );
    };

    return (
        <div
            data-testId={TestIds.leftPaneAddButton}
            className={classNames(
                styles.leftPaneAddButton,
                getThemeClassName(),
            )}
            onClick={onClick}
        >
            <span
                className={styles.leftPaneAddButtonIcon}
            >
                <Icon iconType={IconType.plus} />
            </span>
            <span
                className={styles.leftPaneAddButtonText}
            >
                {text}
            </span>
        </div>
    );
};

Component.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string,
    theme: PropTypes.oneOf(Object.values(LeftPaneAddButtonTheme)),
};

Component.defaultProps = {
    onClick: _.noop,
    text: '',
    theme: '',
};

export default withTranslation()(Component);

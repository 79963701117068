//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ProjectsActions } from '@slices/projects';
import ColorButton         from '@components/ColorButton';
import ColorButtonTheme    from '@components/ColorButton/ColorButtonTheme';
import Overlays            from '@constants/Overlays';
import Routes              from '@constants/Routes';
import I18n                from '@language/i18n';

import Overlay      from '../Overlay';
import OverlayType  from '../Overlay/OverlayType';
import TextHeadline from '../TextHeadline';

const Component = ({
    openLastImportedProject,
}) => {
    const openProjectButtonPressed      = () => {
        openLastImportedProject();
    };
    const renderAdditionalFooterButtons = () => {
        return [
            <ColorButton
                key={'openProject'}
                onClick={openProjectButtonPressed}
                text={I18n.t('openProject')}
                theme={ColorButtonTheme.orange}
            />,
        ];
    };

    return (
        <Overlay
            cancelButtonTextKey={null}
            id={Overlays.importSuccessActiveProject}
            nextRoute={Routes.home}
            overlayType={OverlayType.dialog}
            renderAdditionalFooterButtons={renderAdditionalFooterButtons}
            title={I18n.t('importSuccessNewProjectTitle')}
        >
            <TextHeadline text={I18n.t('importSuccessNewProjectText')} />
        </Overlay>
    );
};

Component.propTypes = {};

Component.defaultProps = {};

const mapDispatchToProps = (dispatch) => bindActionCreators(ProjectsActions, dispatch);

export default connect(null, mapDispatchToProps)(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames  from 'classnames';
import I18n        from 'i18next';
import _           from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';

import StateHelper from '@helper/State';

import styles    from './styles.module.scss';
import Icon      from '../Icon';
import IconType  from '../Icon/IconType';
import PropTypes from '../PropTypes';

const propTypes = {
    offsetIcon: PropTypes.boolean,
};

const Component = ({
    offsetIcon,
    selectedProduct,
}) => {
    const hasFanApplied = _.get(selectedProduct, ['subProducts', 'fan', 0, 'productData']);

    if (hasFanApplied) {
        return (
            <div
                className={classNames([
                    styles.indicator,
                    offsetIcon
                        ? styles.offsetIcon
                        : null,
                ])}
                title={I18n.t('fanIndicatorHelp')}
            >
                <Icon iconType={IconType.fan} />
            </div>
        );
    }

    return null;
};

Component.propTypes = propTypes;

const mapStateToProps = (state) => (
    {
        selectedProduct: StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
    }
);

export default compose(connect(mapStateToProps))(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push }   from 'connected-react-router';
import { put }    from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import { getPathForOverlayKey } from '@components/OverlayManager';
import Overlays                 from '@constants/Overlays';

const showEndOfLifeProductOverlay = function* () {
    const hiddenOverlays = yield select((state) => state.overlays.hiddenOverlays);

    if (!hiddenOverlays[Overlays.endOfLifeProduct]) {
        const route = getPathForOverlayKey(Overlays.endOfLifeProduct);

        yield put(push(route));
    }
};

const showIncompatibleProductOverlay = function* () {
    const hiddenOverlays = yield select((state) => state.overlays.hiddenOverlays);

    if (!hiddenOverlays[Overlays.incompatibleProduct]) {
        const route = getPathForOverlayKey(Overlays.incompatibleProduct);

        yield put(push(route));
    }
};

const showHitLimitationOverlay = function* () {
    const hiddenOverlays = yield select((state) => state.overlays.hiddenOverlays);

    if (!hiddenOverlays[Overlays.hitLimitation]) {
        const route = getPathForOverlayKey(Overlays.hitLimitation);

        yield put(push(route));
    }
};

export default {
    showHitLimitationOverlay,
    showEndOfLifeProductOverlay,
    showIncompatibleProductOverlay,
};

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import CpuLayout               from '@components/SelectedProductImageRenderer/CpuLayout';
import SlotLayout              from '@components/SelectedProductImageRenderer/SlotLayout';
import ProductCategoryType     from '@constants/ProductCategoryType';
import ProductSubCategoryTypes from '@constants/ProductSubCategoryType';

class Slot {
    static hideSlot = (slotIndex, selectedProduct) => {
        const productData         = _.get(selectedProduct, 'productData', null);
        const normalizedSlotIndex = slotIndex + 1;

        if (
            productData &&
            productData.categoryType === ProductCategoryType.matrix &&
            productData.subCategoryType === ProductSubCategoryTypes.flex &&
            productData.mutable
        ) {
            const { layoutDefinition, slotCount } = productData;
            const { availableRowCount }           = layoutDefinition;

            if (
                availableRowCount <
                slotCount &&
                normalizedSlotIndex <= slotCount - availableRowCount
            ) {
                // Hide the n first slots of all matrix flex chassis since they are not mutable
                // and cannot have a default product
                return true;
            }
        }
        return false;
    };

    static getVisibleCpuSlotIndex(index, layoutDefinition, productData) {
        const ioSlots                            = layoutDefinition.io;
        const unusedSlots                        = layoutDefinition.unused;
        const beforeComparer                     = (slot) => slot < index;
        const ioSlotsBefore                      = _.filter(ioSlots, beforeComparer);
        const unusedSlotsBefore                  = _.filter(unusedSlots, beforeComparer);
        const cpuLayout                          = layoutDefinition.cpuLayout;
        const slotLayout                         = layoutDefinition.slotLayout;
        let matrixVisibleIndex                   = index - ioSlotsBefore.length - unusedSlotsBefore.length;
        const correctOrderVisibleIndex           = [
            CpuLayout.topRightToBottomToLeft,
            CpuLayout.rightToLeft,
        ];
        const correctOrderForceInverseSlotLayout = [
            SlotLayout.topRightToBottomToLeft,
        ];
        const correctOrderCpuLayout              = correctOrderVisibleIndex.includes(cpuLayout);
        const correctOrderSlotLayout             = correctOrderForceInverseSlotLayout.includes(slotLayout);
        const inverseOrder                       = correctOrderCpuLayout && !correctOrderSlotLayout;

        if (inverseOrder) {
            const slotCount = productData.slotCount;
            const totalCpus = slotCount - (
                ioSlots.length + unusedSlots.length
            );

            matrixVisibleIndex = (
                totalCpus + 1
            ) - matrixVisibleIndex;
        }

        return matrixVisibleIndex;
    }
}

export default Slot;

import React from 'react';

import classNames from 'classnames';
import _ from 'lodash';

import styles from './styles.module.scss';
import PropTypes from '../PropTypes';

const Component = ({ customName, productData }) => {
    return [
        <h3
            className={classNames(
                styles.productTitle,
                customName
                    ? styles.productTitleCustomName
                    : null,
            )}
            key={'h3'}
        >
            {customName || _.get(productData, 'displayPartNumber', '')}
        </h3>,
        customName
            ? (
                <h4 className={styles.productTitleSubTitle} key={'h4'}>
                    {_.get(productData, 'displayPartNumber')}
                </h4>
            )
            : null,
    ];
};

Component.propTypes = {
    customName: PropTypes.string,
    productData: PropTypes.object,
};

Component.defaultProps = {
    customName: '',
    productData: {},
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import SlotDefaultBounds from '@constants/SlotDefaultBounds';

import GapCalculator from './GapCalculator';
import SlotAlignment from '../../SelectedProductSlotImageRenderer/SlotAlignment';
import ChassisLayout from '../ChassisLayout';

const ExtenderSlotDefaultBounds = SlotDefaultBounds.extender;

/**
 * @see This file also exists in the backend src/BoundsCalculators/ExtenderSlotLeftBottomToTopRight.php
 */
class ExtenderSlotLeftBottomToTopRight {
    static getBoundsChassisLayoutHorizontalX = (bounds, layoutDefinition) => {
        let x = 0;

        // @formatter:off
        x += layoutDefinition.start.x;                                         // Start position
        // Beware: this differs from the frontend by 1px
        x -= 1;                                                                // Fix some rendering issues (@see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-298)
        x += GapCalculator.getGap(layoutDefinition.gap.column, bounds.column); // Add the gaps between the slots
        x += (bounds.column - 1) * ExtenderSlotDefaultBounds.width;            // Add the slot width starting at the second column
        // @formatter:on

        return x;
    };

    static getBoundsChassisLayoutVerticalX = (bounds, layoutDefinition) => {
        let x = 0;

        // @formatter:off
        x += layoutDefinition.start.x;                                      // Start position
        x += 1;                                                             // Fix some rendering issues (@see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-298)
        x += GapCalculator.getGap(layoutDefinition.gap.column, bounds.row); // Add the gaps between the slots
        x += (bounds.row) * ExtenderSlotDefaultBounds.height;               // Add the slot heights
        // @formatter:on

        return x;
    };

    static getBoundsChassisLayoutHorizontalY = (bounds, layoutDefinition) => {
        let y = 0;

        // @formatter:off
        y += layoutDefinition.start.y;                                      // Start position
        y -= ExtenderSlotDefaultBounds.height * layoutDefinition.rowCount;  //
        y += GapCalculator.getGap(layoutDefinition.gap.row, bounds.row);    //
        y += (bounds.row - 1) * ExtenderSlotDefaultBounds.height;           //
        y += 1;                                                             // Fix some rendering issues (@see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-298)
        // @formatter:on

        return y;
    };

    static getBoundsChassisLayoutVerticalY = (bounds, layoutDefinition) => {
        let y = 0;

        // @formatter:off
        y += layoutDefinition.start.y;                                      // Start position
        y -= ExtenderSlotDefaultBounds.width * layoutDefinition.rowCount;   //
        y += GapCalculator.getGap(layoutDefinition.gap.row, bounds.column); //
        y += (bounds.column - 1) * ExtenderSlotDefaultBounds.width;         //
        y -= 1;                                                             // Fix some rendering issues (@see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-298)
        // @formatter:on

        return y;
    };

    static getCurrentColumnChassisLayoutHorizontal = (slotIndexStartingBy1, layoutDefinition) => {
        return (
            Math.ceil(slotIndexStartingBy1 / layoutDefinition.rowCount)
        );
    };

    static getCurrentColumnChassisLayoutVertical = (slotIndexStartingBy1, layoutDefinition) => {
        return (
            Math.ceil(slotIndexStartingBy1 / layoutDefinition.columnCount)
        );
    };

    static getCurrentRowChassisLayoutHorizontal = (slotIndexStartingBy1, layoutDefinition) => {
        return (
            (
                slotIndexStartingBy1 % layoutDefinition.rowCount
            )
            +
            1
        );
    };

    static getCurrentRowChassisLayoutVertical = (slotIndexStartingBy1, layoutDefinition) => {
        return (
            (
                (
                    slotIndexStartingBy1 - 1
                )
                %
                layoutDefinition.columnCount
            )
            +
            1
        );
    };

    static getBounds = (slotIndexStartingBy1, layoutDefinition, productData) => {
        const bounds = this.getDefaultBounds(layoutDefinition);

        switch (layoutDefinition.chassisLayout) {
            case ChassisLayout.horizontal:
                this.updateBoundsChassisLayoutHorizontal(
                    slotIndexStartingBy1,
                    layoutDefinition,
                    bounds,
                );

                break;

            case ChassisLayout.vertical:
                this.updateBoundsChassisLayoutVertical(
                    slotIndexStartingBy1,
                    layoutDefinition,
                    bounds,
                );

                break;
        }

        return bounds;
    };

    static getDefaultBounds = (layoutDefinition) => {
        return {
            alignTo:       SlotAlignment.top,
            chassisLayout: layoutDefinition.chassisLayout,
            height:        ExtenderSlotDefaultBounds.height,
            x:             0,
            y:             0,
            width:         ExtenderSlotDefaultBounds.width,
        };
    };

    static updateBoundsChassisLayoutHorizontal = (slotIndexStartingBy1, layoutDefinition, bounds) => {
        bounds.column = this.getCurrentColumnChassisLayoutHorizontal(slotIndexStartingBy1, layoutDefinition);
        bounds.row    = this.getCurrentRowChassisLayoutHorizontal(slotIndexStartingBy1, layoutDefinition);
        bounds.x      = this.getBoundsChassisLayoutHorizontalX(bounds, layoutDefinition);
        bounds.y      = this.getBoundsChassisLayoutHorizontalY(bounds, layoutDefinition);
    };

    static updateBoundsChassisLayoutVertical = (slotIndexStartingBy1, layoutDefinition, bounds) => {
        bounds.column = this.getCurrentColumnChassisLayoutVertical(slotIndexStartingBy1, layoutDefinition);
        bounds.row    = this.getCurrentRowChassisLayoutVertical(slotIndexStartingBy1, layoutDefinition);
        bounds.x      = this.getBoundsChassisLayoutVerticalX(bounds, layoutDefinition);
        bounds.y      = this.getBoundsChassisLayoutVerticalY(bounds, layoutDefinition);
    };
}

export default ExtenderSlotLeftBottomToTopRight;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import Overlays                 from '@constants/Overlays';
import Routes                   from '@constants/Routes';
import Cast                     from '@helper/Cast';
import ProductTitleHelper       from '@helper/ProductTitle';
import StateHelper              from '@helper/State';

import ErrorBox     from '../ErrorBox';
import Input        from '../Input';
import InputType    from '../Input/InputType';
import Overlay      from '../Overlay';
import OverlayType  from '../Overlay/OverlayType';
import OverlayPane  from '../OverlayPane';
import TextHeadline from '../TextHeadline';

class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            amount: 1,
            error:  null,
        };
    }

    amountChanged = (event) => {
        this.setState({
            amount: Cast.int(event.target.value),
        });
    };

    errorBoxCloseButtonPressed = () => {
        this.setState({
            error: null,
        });
    };

    okButtonValidator = () => {
        return (
            this.state.amount !== null &&
            this.state.amount !== '' &&
            this.state.amount >= 1
        );
    };

    okButtonPressed = () => {
        this.props.duplicateSelectedProduct({
            confirmed: true,
            amount:    this.state.amount,
        });
    };

    render() {
        const selectedProduct = this.props.selectedProduct;

        if (selectedProduct) {
            return (
                <Overlay
                    allowDontShowAgain={true}
                    id={Overlays.duplicateProduct}
                    nextRoute={Routes.designerProductDetails}
                    okButtonPressed={this.okButtonPressed}
                    okButtonValidator={this.okButtonValidator}
                    overlayType={OverlayType.prompt}
                    title={I18n.t('duplicateProductOverlayTitle')}
                >
                    <TextHeadline
                        text={I18n.t(
                            'duplicateProductOverlayText',
                            {
                                name: ProductTitleHelper.getProductCustomNameOrPartNumber(selectedProduct),
                            },
                        )}
                    />
                    {this.renderErrorBox()}
                    {this.renderAmountTextInput()}
                </Overlay>
            );
        }

        return null;
    }

    renderAmountTextInput = () => {
        return (
            <OverlayPane>
                <Input
                    autoFocus={true}
                    min={1}
                    minLength={1}
                    text={I18n.t('duplicateProductOverlayAmount')}
                    type={InputType.number}
                    value={Cast.string(this.state.amount)}
                    valueChanged={this.amountChanged}
                />
            </OverlayPane>
        );
    };

    renderErrorBox = () => {
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-762
        if (this.state.error) {
            return (
                <ErrorBox
                    closeButtonPressed={this.errorBoxCloseButtonPressed}
                    text={this.state.error}
                />
            );
        }

        return null;
    };

    setError = (error) => {
        this.setState({
            error,
        });
    };
}

const mapStateToProps = (state) => (
    {
        selectedProduct: StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(withTranslation()(Component));

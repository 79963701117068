//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { compose }            from 'redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import Limitations              from '@constants/Limitations';
import Overlays                 from '@constants/Overlays';
import SlotType                 from '@constants/SlotType';
import FanType                  from '@constants/FanType';
import Cast                     from '@helper/Cast';
import DataProvider             from '@helper/DataProvider';
import LimitationHelper         from '@helper/Limitations';
import StateHelper              from '@helper/State';

import Overlay            from '../Overlay';
import OverlayType        from '../Overlay/OverlayType';
import OverlayPane        from '../OverlayPane';
import SegmentControl     from '../SegmentControl';
import SegmentControlMode from '../SegmentControl/SegmentControlMode';
import TextHeadline       from '../TextHeadline';

const Component = ({
                       selectedProduct,
                       setProductSlot,
                   }) => {
    const [fanType, setFanType] = React.useState('chassis');
    const fanTypeChanged        = (segmentIndex, segment) => {
        setFanType(segment.key);
    };
    const okButtonPressed       = () => {
        if (fanType === FanType.chassis) {
            const extenderChassisAccessories = DataProvider.getExtenderChassisAccessories();
            const matchingProducts           = LimitationHelper.filterProductListByLimitTo(
                extenderChassisAccessories,
                selectedProduct.productData.partNo,
                false,
                SlotType.fan,
            );

            if (matchingProducts.length > 0) {
                const fanToAdd = matchingProducts[0];

                setProductSlot({
                    confirmed:      false,
                    isBulk:         false,
                    productId:      fanToAdd.partNo,
                    slotIndex:      0,
                    targetSlotType: SlotType.fan,
                });
            }
        } else if (fanType === FanType.module) {
            const recommendsModuleFanInChassisLimitation = LimitationHelper.getSlotLimitation(
                selectedProduct,
                Limitations.recommendsModuleFanInChassis,
            );

            if (recommendsModuleFanInChassisLimitation) {
                const recommendedModuleFanSlot         = Cast.int(recommendsModuleFanInChassisLimitation.value);
                const recommendedModuleFanSlotInternal = recommendedModuleFanSlot - 1;
                const extenderOtherModules             = DataProvider.getExtenderOtherModules();
                const matchingProducts                 = LimitationHelper.filterProductListByLimitTo(
                    extenderOtherModules,
                    selectedProduct.productData.partNo,
                    false,
                    SlotType.fan,
                );

                if (matchingProducts.length > 0) {
                    const fanToAdd = _.find(matchingProducts, (product) => {
                        return product.fanType === FanType.module;
                    });

                    if (fanToAdd) {
                        setProductSlot({
                            confirmed:      false,
                            isBulk:         false,
                            productId:      fanToAdd.partNo,
                            slotIndex:      recommendedModuleFanSlotInternal,
                            targetSlotType: SlotType.slot,
                        });
                    }
                }
            }
        }
    };

    const renderSelectFanTypePane = () => {
        return (
            <OverlayPane text={I18n.t('fanType')}>
                <SegmentControl
                    mode={SegmentControlMode.select}
                    segments={[
                        {
                            key:   'chassis',
                            title: I18n.t('fanTypeChassis'),
                        },
                        {
                            key:   'module',
                            title: I18n.t('fanTypeModule'),
                        },
                    ]}
                    segmentClicked={fanTypeChanged}
                    selectedKey={fanType}
                />
            </OverlayPane>
        );
    };

    return (
        <Overlay
            closable={false}
            id={Overlays.chooseFanType}
            okButtonPressed={okButtonPressed}
            overlayType={OverlayType.dialog}
            title={I18n.t('chooseFanType')}
        >
            <TextHeadline text={I18n.t('chooseFanTypeText')} />
            {renderSelectFanTypePane()}
        </Overlay>
    );
};

const mapStateToProps = (state) => (
    {
        selectedProduct: StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(withTranslation()(Component));

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import SelectionHelper from '@helper/SelectionHelper';

import TextHeadlineTheme from './TextHeadlineTheme';
import styles            from './styles.module.scss';

const Component = ({
    text  = '',
    theme = TextHeadlineTheme.h3Gray,
}) => (
    <h3
        className={classNames(
            styles.textHeadline,
            SelectionHelper.get(theme, {
                [TextHeadlineTheme.h2Black]:     styles.textHeadlineH2Black,
                [TextHeadlineTheme.h3Gray]:      styles.textHeadlineH3Gray,
                [TextHeadlineTheme.h3Black]:     styles.textHeadlineH3Black,
                [TextHeadlineTheme.h3BlackBold]: styles.textHeadlineH3BlackBold,
                [TextHeadlineTheme.h4Black]:     styles.textHeadlineH4Black,
            }),
        )}
    >
        {text}
    </h3>
);

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n       from 'i18next';
import { select } from 'redux-saga/effects';

const handleRehydrate = function* (action) {
    const settings = yield select((state) => state.settings);
    const language = settings.language;

    I18n.changeLanguage(language);
};

export default {
    handleRehydrate,
};

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import update                 from 'immutability-helper';
import { isTablet }           from 'react-device-detect';
import { bindActionCreators } from 'redux';

import Cast      from '@helper/Cast';
import variables from '@styles/variables.scss';

const getBottomPaneSize = () => {
    return (
        (
            window.innerHeight -
            Cast.int(variables.headerHeight) -
            Cast.int(variables.headerStatusBarPaddingHeight)
        ) / 2
    ) - 50;
};

const initialBottomPaneSize = getBottomPaneSize();

const initialLeftPaneSize = 300;

const initialRightPaneSize = isTablet
    ? 260
    : 300;

const initialState = {
    bottomPaneVisible:  false,
    bottomPaneSize:     initialBottomPaneSize,
    leftPaneVisible:    true,
    leftPaneSize:       initialLeftPaneSize,
    tableFilterVisible: true,
    rightPaneVisible:   false,
    rightPaneSize:      initialRightPaneSize,
};

const designerLayoutSlice = createSlice({
    name:     'designerLayout',
    initialState,
    reducers: {
        bottomPaneSetVisibility:     (state, action) => {
            return update(state, {
                bottomPaneVisible: {
                    $set: action.payload.visible,
                },
            });
        },
        bottomPaneSizeChanged:       (state, action) => {
            const { size } = action.payload;

            return update(state, {
                bottomPaneSize: {
                    $set: size,
                },
            });
        },
        bottomPaneToggleVisibility:  (state) => {
            const { bottomPaneVisible } = state;

            return update(state, {
                bottomPaneVisible: {
                    $set: !bottomPaneVisible,
                },
            });
        },
        leftPaneSetVisibility:       (state, action) => {
            const { visible } = action.payload;

            return update(state, {
                leftPaneVisible: {
                    $set: visible,
                },
            });
        },
        leftPaneSizeChanged:         (state, action) => {
            const { size } = action.payload;

            return update(state, {
                leftPaneSize: {
                    $set: size,
                },
            });
        },
        leftPaneToggleVisibility:    (state) => {
            return update(state, {
                leftPaneVisible: {
                    $set: !state.leftPaneVisible,
                },
            });
        },
        tableFilterToggleVisibility: (state) => {
            return update(state, {
                tableFilterVisible: {
                    $set: !state.tableFilterVisible,
                },
            });
        },
        rightPaneSetVisibility:      (state, action) => {
            const { visible } = action.payload;

            return update(state, {
                rightPaneVisible: {
                    $set: visible,
                },
            });
        },
        rightPaneSizeChanged:        (state, action) => {
            const { size } = action.payload;

            return update(state, {
                rightPaneSize: {
                    $set: size,
                },
            });
        },
        rightPaneToggleVisibility:   (state) => {
            return update(state, {
                rightPaneVisible: {
                    $set: !state.rightPaneVisible,
                },
            });
        },
        setBottomPaneSetVisibility:  (state, action) => {
            const { visible } = action.payload;

            return update(state, {
                bottomPaneVisible: {
                    $set: visible,
                },
            });
        },
        setLeftPaneSetVisibility:    (state, action) => {
            const { visible } = action.payload;

            return update(state, {
                leftPaneVisible: {
                    $set: visible,
                },
            });
        },
        setRightPaneSetVisibility:   (state, action) => {
            const { visible } = action.payload;

            return update(state, {
                rightPaneVisible: {
                    $set: visible,
                },
            });
        },
    },
});

export const DesignerLayoutActions = designerLayoutSlice.actions;

export const DesignerLayoutReducer = designerLayoutSlice.reducer;

export const useDesignerLayout = (dispatch) => bindActionCreators(DesignerLayoutActions, dispatch);

export default designerLayoutSlice;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import SelectionHelper from '@helper/SelectionHelper';

import DropDownArrowPosition from './DropDownArrowPosition';
import DropDownTheme         from './DropDownTheme';
import styles                from './styles.module.scss';
import PropTypes             from '../PropTypes';

const Component = ({
    arrowPosition = DropDownArrowPosition.center,
    children      = [],
    theme         = DropDownTheme.orange,
}) => {
    const getDropDownArrowClass = () => {
        return [
            SelectionHelper.get(arrowPosition, {
                [DropDownArrowPosition.center]: styles.dropDownArrowCenter,
                [DropDownArrowPosition.left]:   styles.dropDownArrowLeft,
                [DropDownArrowPosition.right]:  styles.dropDownArrowRight,
            }),
            SelectionHelper.get(theme, {
                [DropDownTheme.black]:  styles.dropDownArrowBlack,
                [DropDownTheme.orange]: styles.dropDownArrowOrange,
            }),
        ];
    };
    const getDropDownClass      = () => {
        return [
            SelectionHelper.get(arrowPosition, {
                [DropDownArrowPosition.center]: styles.dropDownCenter,
                [DropDownArrowPosition.left]:   styles.dropDownLeft,
                [DropDownArrowPosition.right]:  styles.dropDownRight,
            }),
            SelectionHelper.get(theme, {
                [DropDownTheme.black]:  styles.dropDownBlack,
                [DropDownTheme.orange]: styles.dropDownOrange,
            }),
        ];
    };

    return (
        <div
            className={classNames(styles.dropDown, getDropDownClass())}
        >
            <div
                className={classNames(styles.dropDownArrow, getDropDownArrowClass())}
            ></div>
            {children}
        </div>
    );
};

Component.propTypes = {
    arrowPosition: PropTypes.oneOf(Object.values(DropDownArrowPosition)),
    children:      PropTypes.children,
    theme:         PropTypes.oneOf(Object.values(DropDownTheme)),
};

export default Component;

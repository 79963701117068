//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import update                 from 'immutability-helper';
import { bindActionCreators } from 'redux';

const exportSlice = createSlice({
    name:         'Export',
    initialState: {
        exportAbasSuccess:    false,
        exportDsdJsonSuccess: false,
        downloadInProgress:   false,
    },
    reducers:     {
        exportAbasSuccess:     (state) => {
            state.exportAbasSuccess = true;
        },
        exportDsdJsonSuccess:  (state) => {
            state.exportDsdJsonSuccess = true;
        },
        downloadExport:        (state, action) => {
            return state;
        },
        downloadExportStarted: (state, action) => {
            return update(state, {
                downloadInProgress: {
                    $set: true,
                },
            });
        },
        downloadExportSuccess: (state, action) => {
            return update(state, {
                downloadInProgress: {
                    $set: false,
                },
            });
        },
        downloadExportFailed:  (state, action) => {
            return update(state, {
                downloadInProgress: {
                    $set: false,
                },
            });
        },
    },
});

export const ExportActions = exportSlice.actions;

export const ExportReducer = exportSlice.reducer;

export const useExport = (dispatch) => bindActionCreators(ExportActions, dispatch);

export default exportSlice;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

function slotSfp(WrappedComponent) {
    class SlotSfp extends React.Component {
        hasProduct = () => {
            return this.props.data && this.props.data.productData;
        };

        render() {
            const { ...otherProps } = this.props;

            return (
                <WrappedComponent
                    hasProduct={this.hasProduct}
                    {...otherProps}
                />
            );
        }
    }

    return SlotSfp;
}

export default slotSfp;

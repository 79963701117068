import React from 'react';

import moment from 'moment';

import styles from './styles.module.scss';
import PropTypes from '../PropTypes';

const Component = ({
    date,
    fallback = '',
    format   = '',
    postfix  = '',
}) => {
    const getDateString = () => {
        if (date) {
            const lastUpdateMoment = moment(date);
            const formattedDate = lastUpdateMoment.format(format);
            const finalString = formattedDate + (postfix
                ? ` ${postfix}`
                : '');

            return finalString;
        }
        return fallback;
    };

    return (
        <div
            className={styles.formattedDate}
        >
            {getDateString()}
        </div>
    );
};

Component.propTypes = {
    date: PropTypes.any,
    fallback: PropTypes.string,
    format: PropTypes.string,
    postfix: PropTypes.string,
};

Component.defaultProps = {
    date: null,
    fallback: '',
    format: '',
    postfix: '',
};

export default Component;

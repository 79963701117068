//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';

import Input        from '../Input';
import InputElement from '../Input/InputElement';
import InputType    from '../Input/InputType';

class Component extends React.Component {
    descriptionChanged = (event) => {
        this.props.setDescription({
            description: event.target.value,
        });
    };

    ihseProjectNumberChanged = (event) => {
        this.props.setIhseProjectNumber({
            ihseProjectNumber: event.target.value,
        });
    };

    nameOfResponsiblePersonChanged = (event) => {
        this.props.setNameOfResponsiblePerson({
            nameOfResponsiblePerson: event.target.value,
        });
    };

    offerNumberChanged = (event) => {
        this.props.setOfferNumber({
            offerNumber: event.target.value,
        });
    };

    titleChanged = (event) => {
        this.props.setTitle({
            title: event.target.value,
        });
    };

    render() {
        return (
            <div>
                <Input
                    placeholder={I18n.t('enterTextPlaceholder')}
                    text={I18n.t('projectTitle')}
                    tooltip={I18n.t('projectTitleTip')}
                    type={InputType.text}
                    value={this.props.title}
                    valueChanged={this.titleChanged}
                />
                <Input
                    element={InputElement.textarea}
                    placeholder={I18n.t('enterTextPlaceholder')}
                    text={I18n.t('projectDescription')}
                    tooltip={I18n.t('projectDescriptionTip')}
                    type={InputType.text}
                    value={this.props.description}
                    valueChanged={this.descriptionChanged}
                />
                <Input
                    placeholder={I18n.t('enterTextPlaceholder')}
                    text={I18n.t('ihseProjectNumber')}
                    tooltip={I18n.t('ihseProjectNumberTip')}
                    type={InputType.text}
                    value={this.props.ihseProjectNumber}
                    valueChanged={this.ihseProjectNumberChanged}
                />
                <Input
                    placeholder={I18n.t('enterTextPlaceholder')}
                    text={I18n.t('nameOfResponsiblePerson')}
                    tooltip={I18n.t('nameOfResponsiblePersonTip')}
                    type={InputType.text}
                    value={this.props.nameOfResponsiblePerson}
                    valueChanged={this.nameOfResponsiblePersonChanged}
                />
                {this.renderOfferNumberInput()}
            </div>
        );
    }

    renderOfferNumberInput = () => {
        if (this.props.offerFieldVisible) {
            return (
                <Input
                    placeholder={I18n.t('enterOfferNumberPlaceholder')}
                    text={I18n.t('offerNumber')}
                    tooltip={I18n.t('offerNumberTip')}
                    type={InputType.text}
                    value={this.props.offerNumber}
                    valueChanged={this.offerNumberChanged}
                />
            );
        }

        return null;
    };
}

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => (
    {
        description:             _.get(state, 'activeProject.metaData.description', ''),
        ihseProjectNumber:       _.get(state, 'activeProject.metaData.ihseProjectNumber', ''),
        offerNumber:             _.get(state, 'activeProject.metaData.offerNumber', ''),
        offerFieldVisible:       _.get(state, 'settings.offerFieldVisible'),
        nameOfResponsiblePerson: _.get(state, 'activeProject.metaData.nameOfResponsiblePerson', ''),
        title:                   _.get(state, 'activeProject.metaData.title', ''),
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

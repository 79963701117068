import React from 'react';

import { withTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import EquipmentTable from '../EquipmentTable';

const Component = () => {
    return (
        <div className={styles.equipmentProductBrowser}>
            <EquipmentTable />
        </div>
    );
};

Component.propTypes = {};

Component.defaultProps = {};

export default withTranslation()(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import { bindActionCreators } from 'redux';
import update                 from 'immutability-helper';

const initialState = {
    productId: null,
};

const productPreviewSlice = createSlice({
    name:     'productPreview',
    initialState,
    reducers: {
        disablePreview: (state) => {
            return update(state, {
                productId: {
                    $set: initialState.productId,
                },
            });
        },
        enablePreview: (state, action) => {
            const { payload } = action;

            return update(state, {
                productId: {
                    $set: payload.productId,
                },
            });
        },
    },
});

export const ProductPreviewActions = productPreviewSlice.actions;

export const ProductPreviewReducer = productPreviewSlice.reducer;

export const useProductPreview = (dispatch) => bindActionCreators(ProductPreviewActions, dispatch);

export default productPreviewSlice;
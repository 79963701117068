//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import ProductCategoryType from '@constants/ProductCategoryType';
import Routes from '@constants/Routes';
import StateHelper from '@helper/State';

import styles from './styles.module.scss';
import AssemblyOrderSegmentControl from '../AssemblyOrderSegmentControl';
import LeftPaneBackButton from '../LeftPaneBackButton';
import ProductConfigurationQuickSearch from '../ProductConfigurationQuickSearch';
import ProductDetailItem from '../ProductDetailItem';
import ProductSlotList from '../ProductSlotList';
import ProductWarningList from '../ProductWarningList';
import Tip from '../Tip';
import TipStyle from '../Tip/TipStyle';

const Component = ({
    selectedProduct,
}) => {
    const renderImmutableInformation = () => {
        return (
            <Tip
                style={TipStyle.leftPane}
                text={I18n.t('productNoSlotsText')}
                title={I18n.t('productNoSlotsTitle')}
            />
        );
    };

    const renderAssemblyOrder = () => {
        const categoryType = _.get(selectedProduct, 'productData.categoryType', null);

        if (categoryType !== ProductCategoryType.fullIp) {
            return (
                <AssemblyOrderSegmentControl />
            );
        }

        return null;
    };

    const renderQuickSearch = () => {
        return (
            <div className={styles.productConfigurationPaneQuickSearch}>
                <ProductConfigurationQuickSearch />
                {renderAssemblyOrder()}
            </div>
        );
    };

    const renderSlots = () => {
        const productData   = _.get(selectedProduct, 'productData', null);
        const mutable       = _.get(productData, 'mutable', false);
        const uniDefinition = _.get(productData, 'uniDefinition', false);

        if (
            mutable ||
            uniDefinition
        ) {
            return (
                <div className={styles.productConfigurationPaneSlots}>
                    <ProductSlotList />
                </div>
            );
        }

        return renderImmutableInformation();
    };


    if (selectedProduct) {
        return (
            <div className={styles.productConfigurationPane}>
                <LeftPaneBackButton route={Routes.designer} />
                {renderQuickSearch()}
                <ProductDetailItem
                    product={selectedProduct}
                />
                <ProductWarningList />
                {renderSlots()}
            </div>
        );
    }

    return null;
};

const mapStateToProps = (state) => {
    return {
        categoryType:    _.get(state, 'activeProject.selectedProduct.categoryType'),
        selectedProduct: StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
        slotIsSelected:  _.get(state, 'activeProject.selectedSlot.index', false),
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// We just "extend" the original class
// @see https://github.com/adazzle/react-data-grid/blob/master/packages/react-data-grid-addons/src/cells/headerCells/filters/NumericFilter.js
import React from 'react';

import I18n                from 'i18next';
import { Filters }         from 'react-data-grid-addons';
import { withTranslation } from 'react-i18next';

import styles       from './styles.module.scss';
import Tooltip      from '../Tooltip';
import TooltipTheme from '../Tooltip/TooltipTheme';

const TableNumericFilter = Filters.NumericFilter;

TableNumericFilter.prototype.render = function() {
    const inputKey = `header-filter-${this.props.column.key}`;

    return (
        <div className={styles.numericFilterWrapper}>
            <div className={styles.numericFilter}>
                <input
                    key={inputKey}
                    type={'text'}
                    placeholder={I18n.t('numberFilterPlaceholder')}
                    onChange={this.handleChange}
                    onKeyPress={this.handleKeyPress}
                />
            </div>
            <Tooltip
                text={I18n.t('numberFilterTip')}
                theme={TooltipTheme.white}
            />
        </div>
    );
};

export default withTranslation()(TableNumericFilter);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import fileDownload from 'js-file-download';
import _            from 'lodash';

import ProductCategoryType from '@constants/ProductCategoryType';
import ProductSlotType     from '@constants/ProductSlotType';
import SlotType            from '@constants/SlotType';
import Cast                from '@helper/Cast';
import Product             from '@helper/Product';
import ProjectTitleHelper  from '@helper/ProjectTitle';

const abasProductNumberSpace = 10;

const exportBlindPlates = false;

/**
 * @see This enum is also present in the backend project src/Service/ProjectAbasRenderer.php
 */
class AbasExporter {
    static addCsvRow = function(
        csv,
        abasProductNumber,
        abasSubProductNumber,
        identNo,
        amount,
        text,
    ) {
        const rowData                = [];
        const customerNumber         = 99999;
        const finalAbasProductNumber = (
            abasProductNumber + (
                abasSubProductNumber ?
                    `.${abasSubProductNumber}` :
                    ''
            )
        );
        const forerun                = (
            identNo === 12 ?
                0 :
                2
        );
        const ydruckzt               = (
            identNo === 12 ?
                'false' :
                'true'
        );

        rowData.push(customerNumber);
        rowData.push(finalAbasProductNumber);
        rowData.push(identNo);
        rowData.push(amount);
        rowData.push(forerun);
        rowData.push(text);
        rowData.push(ydruckzt);

        const rowString = rowData.join(';');

        csv.push(rowString);
    };

    static exportActiveProject(activeProject, userLanguage, callback) {
        let abasProductNumber         = 10;
        const csv                     = [
            'kunde;pnum;artikel;mge;vorlauf;pftext;ydruckzt',
        ];
        const productCategoryTypes    = activeProject.products;
        const productCategoryTypeKeys = Object.keys(productCategoryTypes);

        for (const productCategoryTypeKey of productCategoryTypeKeys) {
            const products = productCategoryTypes[productCategoryTypeKey];

            for (const product of products) {
                // 99999;10;12;0;0;Tisch 17;false
                this.addCsvRow(
                    csv,
                    abasProductNumber,
                    null,
                    12,
                    0,
                    product.customName,
                );

                // 99999;10.01;90000191;1;2;;true
                this.addCsvRow(
                    csv,
                    abasProductNumber,
                    '01',
                    product.productData.identNo,
                    1,
                    null,
                );

                let abasSubProductNumber   = 100;
                let filledSlotCount        = 1;
                const subProductCategories = Object.keys(product.subProducts);
                const sfpCounterBuffer     = [];

                /**
                 * Iteration overview
                 *
                 * 0) Slots (excluded io boards)
                 * 1) Slots (only io boards)
                 * 2) Power supply units
                 * 3) Accessories
                 * 4) Fans
                 * 5) Sfps (Added to the csv after the sub product iteration)
                 *
                 */
                for (let iteration = 0; iteration < 5; ++iteration) {
                    for (const subProductCategory of subProductCategories) {
                        const subProducts = product.subProducts[subProductCategory];

                        for (const subProductIndex in subProducts) {
                            const subProduct = subProducts[subProductIndex];

                            if (subProduct.productData) {
                                const isLocked = Product.isLockedInProduct(
                                    product.productData,
                                    subProductCategory,
                                    subProductIndex,
                                );

                                if (isLocked) {
                                    // Parts that are shipped with the chassis by default
                                    // have to be hidden in the abas export
                                    // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-681
                                    continue;
                                }
                            }

                            switch (subProductCategory) {
                                case SlotType.accessory:
                                case SlotType.fan:
                                case SlotType.powerSupplyUnit:
                                    // Process this in the second iteration to make sure
                                    // the sorting matches the csv definition
                                    if (
                                        // @formatter:off
                                        (iteration === 2 && subProductCategory === SlotType.powerSupplyUnit) ||
                                        (iteration === 3 && subProductCategory === SlotType.accessory) ||
                                        (iteration === 4 && subProductCategory === SlotType.fan)
                                        // @formatter:on
                                    ) {
                                        if (subProduct.productData) {
                                            // 99999;10.100;90000158;1;2;;true
                                            this.addCsvRow(
                                                csv,
                                                abasProductNumber,
                                                abasSubProductNumber,
                                                subProduct.productData.identNo,
                                                1,
                                                null,
                                            );

                                            abasSubProductNumber += 1;
                                        }
                                    }

                                    break;

                                case SlotType.slot:
                                    const slotIndex = Cast.int(subProductIndex) + 1;

                                    // Process this in the first iteration to make sure
                                    // the sorting matches the csv definition
                                    if (iteration === 0) {
                                        if (
                                            // @formatter:off
                                            subProduct.productData &&
                                            subProduct.productData.productSlotType !== ProductSlotType.cpuBoard
                                            // @formatter:on
                                        ) {
                                            const transformedSlotIndex = this.getTransformedSlotIndex(
                                                slotIndex,
                                                product.productData,
                                            );

                                            // 99999;10.1;90000793;1;2;;true
                                            this.addCsvRow(
                                                csv,
                                                abasProductNumber,
                                                transformedSlotIndex,
                                                subProduct.productData.identNo,
                                                1,
                                                null,
                                            );

                                            ++filledSlotCount;

                                            if (subProduct.sfps) {
                                                for (const sfpIndex in subProduct.sfps) {
                                                    const sfp = subProduct.sfps[sfpIndex];

                                                    if (sfp.productData) {
                                                        const sfpIdentNo = sfp.productData.identNo;

                                                        if (!sfpCounterBuffer[sfpIdentNo]) {
                                                            sfpCounterBuffer[sfpIdentNo] = {
                                                                amount:  1,
                                                                identNo: sfpIdentNo,
                                                            };
                                                        } else {
                                                            ++sfpCounterBuffer[sfpIdentNo].amount;
                                                        }
                                                    }
                                                }
                                            }
                                        } else if (exportBlindPlates) {
                                            // 99999;10.5;20090300;1;2;;true
                                            this.addCsvRow(
                                                csv,
                                                abasProductNumber,
                                                slotIndex,
                                                '20090300',
                                                1,
                                                null,
                                            );

                                            abasSubProductNumber += 1;
                                        }
                                    } else if (iteration === 1) {
                                        if (
                                            // @formatter:off
                                            subProduct.productData &&
                                            subProduct.productData.productSlotType === ProductSlotType.cpuBoard
                                            // @formatter:on
                                        ) {
                                            // 99999;10.1;90000793;1;2;;true
                                            this.addCsvRow(
                                                csv,
                                                abasProductNumber,
                                                abasSubProductNumber,
                                                subProduct.productData.identNo,
                                                1,
                                                null,
                                            );

                                            abasSubProductNumber += 1;
                                        }
                                    }

                                    break;
                            }
                        }
                    }
                }

                const sfpCounterBufferEntries = Object.values(sfpCounterBuffer);

                for (const sfpCounterBufferEntry of sfpCounterBufferEntries) {
                    this.addCsvRow(
                        csv,
                        abasProductNumber,
                        abasSubProductNumber,
                        sfpCounterBufferEntry.identNo,
                        sfpCounterBufferEntry.amount,
                        null,
                    );

                    abasSubProductNumber += 1;
                }

                // Increase the product number
                abasProductNumber += abasProductNumberSpace;
            }
        }

        const singleOrderTypes    = activeProject.singleOrders;
        const singleOrderTypeKeys = Object.keys(singleOrderTypes);

        for (const singleOrderTypeKey of singleOrderTypeKeys) {
            const singleOrderProducts = singleOrderTypes[singleOrderTypeKey];

            for (const singleOrderProduct of singleOrderProducts) {
                if (singleOrderProduct.productData) {
                    // 99999;30;90000780;3;2;;true
                    this.addCsvRow(
                        csv,
                        abasProductNumber,
                        null,
                        singleOrderProduct.productData.identNo,
                        singleOrderProduct.amount,
                        null,
                    );

                    // Increase the product number
                    abasProductNumber += abasProductNumberSpace;
                }
            }
        }

        const fileNameWithoutExtension = ProjectTitleHelper.getProjectFileNameWithoutExtension(_.get(
            activeProject,
            'metaData.title',
        ));
        const fileName                 = `${fileNameWithoutExtension}.csv`;
        const finalCsv                 = csv.join('\n');

        fileDownload(finalCsv, fileName);

        if (callback) {
            callback();
        }
    }

    /**
     * @param slotIndex
     * @param productData
     * @returns {*}
     */
    static getTransformedSlotIndex(slotIndex, productData) {
        const categoryType = productData.categoryType;

        if (categoryType === ProductCategoryType.matrix) {
            const layoutDefinition = productData.layoutDefinition;
            const beforeComparer   = (slot) => slot < slotIndex;
            const ioSlotsBefore    = _.filter(layoutDefinition.io, beforeComparer);

            return ioSlotsBefore.length + 1;
        }

        return slotIndex;
    }
}

export default AbasExporter;

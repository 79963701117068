//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const german = {
    accessories:                                           'Zubehör',
    actions:                                               'Aktionen',
    add:                                                   'Hinzufügen',
    addOnModules:                                          'Zusatzmodule',
    addSingleOrderSlot:                                    'weiteren Eintrag hinzufügen',
    amount:                                                'Anzahl',
    amountInputPlaceholder:                                '0',
    and:                                                   'und',
    appName:                                               '{{applicationName}}',
    assemblyOrder123:                                      '1•2•3',
    assemblyOrder135:                                      '1•3•5',
    assemblyOrder:                                         'Bestückungsreihenfolge',
    audio:                                                 'Audio',
    audioAnalogBidirectional:                              'Analog-bidirektional',
    audioAnalogDigital:                                    'Analog-digital',
    audioBalanced:                                         'Symmetrisch',
    audioDigitalBidirectional:                             'Digital-bidirektional',
    audioDigitalUnidirectional:                            'Digital-unidirektional',
    back:                                                  'Zurück',
    canBeOrderedEmpty:                                     'Kann leer bestellt werden',
    cancel:                                                'Abbrechen',
    cardOverlay:                                           'Karten-Overlay',
    cardOverlayModeArticleNumber:                          'Artikelnummer',
    cardOverlayModeModuleName:                             'Modulname',
    cardOverlayModeNone:                                   'Nichts',
    cardOverlayRenderExtenderId:                           'Extender-ID ebenfalls auf Karten-Overlay anzeigen',
    cardOverlayTooltipText:                                'Diese Einstellung bestimmt, ob eine Karte mit der Artikelnummer, dem von Ihnen vergebenen Namen oder nichts überlagert werden soll.',
    centimeter:                                            'Zentimeter',
    centimeterShort:                                       'cm',
    chassis:                                               'Chassis',
    chassisAccessories:                                    'Chassiszubehör',
    chassisize:                                            'Breite x Höhe x Tiefe',
    chassisizeShort:                                       'B x H x T',
    chooseFanType:                                         'Lüftertyp wählen',
    chooseFanTypeText:                                     'Aufgrund thermischer Gegebenheiten wird zwingend ein Chassis- oder Modullüfter benötigt. Bitte wählen Sie einen Lüftertyp, den Sie automatisch hinzufügen möchten.',
    chosenFile:                                            'Ausgewählte Datei',
    classic:                                               'Classic',
    closeOverlay:                                          'Schließen',
    closeProject:                                          'Schließen',
    closeProjectButton:                                    'Schließen und weiter',
    closeProjectText:                                      'Sie sind im Begriff, das aktive Projekt zu verlassen. Möchten Sie fortfahren? Ihr Projekt wird gespeichert und Sie können es jederzeit über die Projektliste erneut öffnen.',
    closeProjectTitle:                                     'Projekt schließen?',
    comment:                                               'Kommentar',
    company:                                               'Firma',
    conPorts:                                              'CON-Ports',
    conUnit:                                               'CON (Remote)',
    configurable:                                          'Konfigurierbar',
    configurableNo:                                        'Nein',
    configurableYes:                                       'Ja',
    configurationGroup:                                    'Konfiguration {{groupIndex}}',
    configurationIsSavedInBrowserCache:                    'Ihre Konfiguration wird momentan im Browser Cache gespeichert.',
    confirmOverwriteSlotOkButton:                          'Slot überschreiben',
    confirmOverwriteSlotOkTextMoveSlot:                    'Sind Sie sicher, dass Sie die Karte {{toSlot}} auf Position {{toSlotIndex}} durch Verschieben mit der Karte {{fromSlot}} von Position {{fromSlotIndex}} ersetzen möchten?',
    confirmOverwriteSlotOkTextSetSlot:                     'Sind Sie sicher, dass Sie die Karte {{toSlot}} auf Position {{toSlotIndex}} mit der Karte {{newProductNumber}} ersetzen möchten?',
    confirmOverwriteSlotOkTitle:                           'Slot überschreiben?',
    contact:                                               'Kontakt',
    contactOpeningHours:                                   'Öffnungszeiten',
    contactOpeningHoursText1:                              'Montag bis Donnerstag von 8.00 bis 16.30 Uhr',
    contactOpeningHoursText2:                              'Freitag von 8.00 bis 15.00 Uhr',
    contactOpeningHoursText3:                              'Per Telefax/E-Mail natürlich rund um die Uhr.',
    contactOrderProcessingText:                            'Fragen zum laufenden Auftrag/Liefertermin',
    contactOrderProcessingTitle:                           'Order Processing',
    contactPageTitle:                                      'Kontakt',
    contactSalesConsultingText:                            'Beratung, Projektierung & allgemeine Fragen zur Technik',
    contactSalesConsultingTitle:                           'Sales & Consulting',
    contactTechnicalSupportText:                           'After Sales & Support',
    contactTechnicalSupportTitle:                          'Technischer Support',
    contactTitle:                                          'Beratung, Projektierung & allgemeine Fragen zur Technik',
    cpuBoards:                                             'CPU-Karten',
    cpuPorts:                                              'CPU-Ports',
    cpuUnit:                                               'CPU (Lokal)',
    customName:                                            'Eigene Bezeichnung',
    customNameTip:                                         'Zur besseren Übersicht können Sie jedem Chassis und jeder Karte einen eigenen Namen vergeben.',
    dataProtection:                                        'Datenschutzerklärung',
    dataProtectionAbbreviation:                            'DSGVO',
    dataProtectionPageTitle:                               'Datenschutzerklärung',
    dataProtectionShort:                                   'Datenschutz',
    dataTransfer:                                          'Link-Schnittstellen',
    dataTransferCatx1g:                                    'Cat X 1G',
    dataTransferCatx3g:                                    'Cat X 3G',
    dataTransferFiber10g:                                  'Glasfaser 10G',
    dataTransferFiber1g:                                   'Glasfaser 1G',
    dataTransferFiber3g:                                   'Glasfaser 3G',
    dataTransferFiber6g:                                   'Glasfaser 6G',
    dataTransferIpcatx1g:                                  'Cat X 1G IP',
    dataTransferIpfiber10gmm:                              'Glasfaser 10G (MM)',
    dataTransferIpfiber1g:                                 'Glasfaser 1G IP',
    dataTransferSingular:                                  'Link-Schnittstelle',
    dataTransferUni:                                       'Uni',
    dataTransferWithDash:                                  'Link- Schnittstellen',
    debug:                                                 'Debug',
    deleteChassis:                                         'Chassis löschen',
    deleteExtenderButtonText:                              'Chassis löschen',
    deleteExtenderText:                                    'Sind Sie sicher, dass Sie das Extenderchassis {{name}} aus diesem Projekt entfernen möchten? Alle konfigurierten Karten werden ebenfalls gelöscht.',
    deleteExtenderTitle:                                   'Chassis löschen?',
    deleteFullIpButtonText:                                'Gerät löschen',
    deleteFullIpText:                                      'Sind Sie sicher, dass Sie das Gerät {{name}} aus diesem Projekt entfernen möchten?',
    deleteFullIpTitle:                                     'Gerät löschen?',
    deleteMatrixButtonText:                                'Matrix-Switch löschen',
    deleteMatrixText:                                      'Sind Sie sicher, dass Sie das Matrix-Switch-Chassis {{name}} aus diesem Projekt entfernen möchten? Alle konfigurierten Karten werden ebenfalls gelöscht.',
    deleteMatrixTitle:                                     'Matrix-Switch löschen?',
    deleteModules:                                         'Module löschen',
    deleteModulesOverlayAll:                               'Alle Module',
    deleteModulesOverlayEmptyText:                         'Das derzeit geöffnete Chassis enthält keine Module, die gelöscht werden könnten.',
    deleteModulesOverlayOkButtonText:                      'Module löschen',
    deleteModulesOverlayText:                              'Bitte wählen Sie die Artikelnummern der Module aus, die Sie löschen möchten.',
    deleteModulesOverlayTitle:                             'Module löschen',
    deleteModulesTip:                                      'Über diese Aktion können Sie in einem Overlay auswählen, welche Module Sie aus diesem Chassis löschen möchten. Der Klick auf diesen Button selbst löscht noch keine Module sondern blendet lediglich das Overlay ein.',
    deleteOptionButtonText:                                'Option entfernen',
    deleteOptionTitle:                                     'Option entfernen?',
    deleteProject:                                         'Projekt löschen',
    deleteProjectButtonText:                               'Projekt löschen',
    deleteProjectText:                                     'Sind Sie sicher, dass Sie das Projekt "{{title}}" entfernen möchten? Das Projekt wird unwiderruflich gelöscht.',
    deleteProjectTitle:                                    'Projekt löschen?',
    deleteSfpButtonText:                                   'SFP-Modul löschen',
    deleteSfpExtenderText:                                 'Sind Sie sicher, dass Sie das SFP-Modul {{sfpProductNumber}} an Position {{index}} von dieser Karte ({{slotName}}) entfernen möchten?',
    deleteSfpMatrixText:                                   'Sind Sie sicher, dass Sie das SFP-Modul {{sfpProductNumber}} an Position {{index}} von dieser Karte ({{slotName}}) entfernen möchten?',
    deleteSfpTitle:                                        'SFP-Modul löschen?',
    deleteSingleOrderSlotButtonText:                       'Löschen',
    deleteSingleOrderSlotEquipmentText:                    'Sind Sie sicher dass Sie die Equipment-Einzelbestellung {{name}} aus Position {{index}} löschen möchten?',
    deleteSingleOrderSlotExtenderText:                     'Sind Sie sicher dass Sie die Extender-Einzelbestellung {{name}} aus Position {{index}} löschen möchten?',
    deleteSingleOrderSlotFullIpText:                       'Sind Sie sicher dass Sie die Geräte-Einzelbestellung {{name}} aus Position {{index}} löschen möchten?',
    deleteSingleOrderSlotMatrixText:                       'Sind Sie sicher dass Sie die Matrix-Switch-Einzelbestellung {{name}} aus Position {{index}} löschen möchten?',
    deleteSingleOrderSlotTitle:                            'Einzelbestellung löschen?',
    deleteSlotButtonText:                                  'Slot leeren',
    deleteSlotExtenderAccessoryText:                       'Sind Sie sicher, dass Sie das Chassiszubehör mit dem Produkt {{name}} von diesem Extenderchassis entfernen möchten?',
    deleteSlotExtenderFanText:                             'Sind Sie sicher, dass Sie den Lüfter mit dem Produkt {{name}} von diesem Extenderchassis entfernen möchten?',
    deleteSlotExtenderPowerSupplyUnitText:                 'Sind Sie sicher, dass Sie das Netzteil mit dem Produkt {{name}} von diesem Extenderchassis entfernen möchten?',
    deleteSlotExtenderSlotText:                            'Sind Sie sicher, dass Sie den Slot {{index}} mit dem Produkt {{name}} von diesem Extenderchassis leeren möchten?',
    deleteSlotFullIpSlotText:                              'Sind Sie sicher, dass Sie die Option {{index}} mit dem Namen {{name}} entfernen möchten?',
    deleteSlotMatrixAccessoryText:                         'Sind Sie sicher, dass Sie das Chassiszubehör mit dem Produkt {{name}} von diesem Matrixchassis entfernen möchten?',
    deleteSlotMatrixFanText:                               'Sind Sie sicher, dass Sie den Lüfter mit dem Produkt {{name}} von diesem Matrixchassis entfernen möchten?',
    deleteSlotMatrixFanTextRequiredInChassis2:             'Wenn Sie fortfahren wird automatisch ein Chassislüfter hinzugefügt.',
    deleteSlotMatrixFanTextRequiredInChassis:              'Wenn Sie fortfahren wird automatisch ein Lüfterkassettenmodul hinzugefügt.',
    deleteSlotMatrixFanTextRequiredInChassisOverwriteSlot: 'Der Slot {{slotName}} wird dabei mit dem Lüfterkassettenmodul überschrieben.',
    deleteSlotMatrixFanTextRequiredInChassisWithSlot:      'Wenn Sie fortfahren wird automatisch ein Lüfterkassettenmodul auf Position {{slot}} hinzugefügt.',
    deleteSlotMatrixPowerSupplyUnitText:                   'Sind Sie sicher, dass Sie das Netzteil mit dem Produkt {{name}} von diesem Matrixchassis entfernen möchten?',
    deleteSlotMatrixSlotText:                              'Sind Sie sicher, dass Sie den Slot {{index}} mit dem Produkt {{name}} von diesem Matrixchassis leeren möchten?',
    deleteSlotTitle:                                       'Slot leeren?',
    depth:                                                 'Tiefe',
    description:                                           'Beschreibung',
    descriptionKey:                                        'description_deShort',
    descriptionKeyFull:                                    'description_deFull',
    descriptionKeyFullShort:                               'deFull',
    descriptionKeyShort:                                   'deShort',
    deviceType:                                            'Geräte-Typ',
    downloadImage:                                         'Bild herunterladen',
    downloadImageBackend:                                  'Backend-Rendering öffnen',
    downloadImageBackendTip:                               'Öffnet das aktuelle Produkt in der Darstellung, die durch serverseitiges Rendering generiert wurde. Diese Funktion ist eine Debug-Funktion und im Produktiv-System nicht sichtbar.',
    downloadImageTip:                                      'Mit dieser Funktion können Sie ein Bild des von Ihnen konfigurierten Chassis herunterladen. Das kann beispielsweise zur Weiterverwendung in Ihrer Dokumentation oder anderen Planungstools nützlich sein.',
    downloadInProgress:                                    'Wird heruntergeladen...',
    downloadSingleOrderImageTip:                           'Mit dieser Funktion können Sie ein Bild des von Ihnen aktuell ausgewählten Produktes herunterladen.',
    downloads:                                             'Downloads',
    downloadsNoFilesAvailable:                             'Für das ausgewählte Produkt sind derzeit leider keine Downloads verfügbar.',
    downloadsSelectFile:                                   'Nachfolgend sehen Sie alle Downloads für das Produkt {{partNumber}}:',
    dropZoneTextDelete:                                    'Zum Löschen hier fallen lassen.',
    dropZoneTextEquipment:                                 'Ziehen Sie ein Equipment in diese Fläche, um es Ihrem Projekt hinzuzufügen.',
    dropZoneTextExtender:                                  'Ziehen Sie ein Extenderchassis in diese Fläche, um es Ihrem Projekt hinzuzufügen.',
    dropZoneTextMatrix:                                    'Ziehen Sie ein Matrixchassis in diese Fläche, um es Ihrem Projekt hinzuzufügen.',
    duplicateExtender:                                     'Duplizieren',
    duplicateExtenderTip:                                  'Mit dieser Funktion können Sie eine Kopie des aktuell geöffneten Chassis erstellen und diese getrennt vom Original bearbeiten und konfigurieren.',
    duplicateProductOverlayAmount:                         'Duplikatanzahl',
    duplicateProductOverlayText:                           'Geben Sie hier die Anzahl an, wie oft Sie {{name}} duplizieren wollen.',
    duplicateProductOverlayTitle:                          'Duplizieren',
    duplicatedExtenderText:                                'Das Chassis ("{{name}}") wurde erfolgreich dupliziert. Wollen Sie jetzt direkt zur Kopie wechseln?',
    duplicatedExtenderTitle:                               'Chassis dupliziert',
    duplicatedFullIpText:                                  'Das Gerät ("{{name}}") wurde erfolgreich dupliziert. Wollen Sie jetzt direkt zur Kopie wechseln?',
    duplicatedFullIpTitle:                                 'Gerät dupliziert',
    duplicatedMatrixSwitchText:                            'Der Matrix-Switch ("{{name}}") wurde erfolgreich dupliziert. Wollen Sie jetzt direkt zur Kopie wechseln?',
    duplicatedMatrixSwitchTitle:                           'Matrix-Switch dupliziert',
    duplicatedMultiplyExtenderText:                        'Der Extender ("{{name}}") wurde erfolgreich dupliziert. Wollen Sie jetzt direkt zur letzten Kopie wechseln?',
    duplicatedMultiplyFullIpText:                          'Das Gerät ("{{name}}") wurde erfolgreich dupliziert. Wollen Sie jetzt direkt zur letzten Kopie wechseln?',
    duplicatedMultiplyMatrixSwitchText:                    'Der Matrix-Switch ("{{name}}") wurde erfolgreich dupliziert. Wollen Sie jetzt direkt zur letzten Kopie wechseln?',
    eMailAddress:                                          'E-Mail-Adresse',
    easyline:                                              'Easyline (Ohne Matrix Management)',
    efficiency:                                            'Effizienz',
    empty:                                                 'Leer',
    emptyPlaceholderShort:                                 '-',
    enableSingleOrdersText:                                'Einzelbestellungen',
    enableSingleOrdersTooltipText:                         'Wenn Sie Einzelbestellungen aktivieren, wird im linken Paneel aus dem Eintrag "Equipment" der Eintrag "Equipment und Einzelbestellungen". Dort können Sie all unsere Produkte zusätzlich auch einzeln bestellen. Hinzugefügte Einzelbestellungen werden auch angezeigt, wenn Sie diese Einstellung deaktiviert haben, aber in Ihrem Projekt noch Einzelbestellungen existieren.',
    endOfLifeProductOverlayText:                           'Das gewählte Modul ist ein Auslaufprodukt und kann nicht gewählt.',
    endOfLifeProductOverlayTitle:                          'Modul nicht mehr verfügbar',
    enterOfferNumberPlaceholder:                           'Angebotsnummer eingeben...',
    enterOptionNumber:                                     'Optionsnummer eingeben...',
    enterProductNumber:                                    'Produktnummer eingeben...',
    enterTextPlaceholder:                                  'Text eingeben...',
    equipment:                                             'Equipment',
    equipmentAndSingleOrders:                              'Equipment und Einzelbestellungen',
    error:                                                 'Fehler',
    errorsInYourConfiguration:                             'Fehler in Ihrer Konfiguration',
    export:                                                'Exportieren',
    exportAbasTitle:                                       'ABAS',
    exportAbasTitleSubTitle:                               'Export für das ERP-System von {{companyName}}',
    exportDialogText:                                      'Bitte wählen Sie das gewünschte Dateiformat.',
    exportDownloadSuccessText:                             'Die Projektdatei wurde erfolgreich heruntergeladen und befindet sich jetzt am von Ihnen ausgewählten Speicherort.',
    exportDownloadSuccessTitle:                            'Download abgeschlossen',
    exportDracoSystemDesignerSubTitle:                     'Export zur direkten Weiterverwendung im {{applicationName}}',
    exportDracoSystemDesignerTitle:                        '{{applicationName}} ({{exportFilename}})',
    exportDracoTeraToolTitle:                              'Draco Tera Tool',
    exportDracoTeraToolTitleSubTitle:                      'Export zur Weiterverarbeitung im Draco Tera Tool',
    exportError1:                                          'Es wird eine aktive Internetverbindung benötigt um das Projekt zu exportieren.',
    exportIhseSalesMailboxSubTitle:                        'Gerne erstellen wir Ihnen ein unverbindliches Angebot.',
    exportIhseSalesMailboxSubmit:                          'OK',
    exportIhseSalesMailboxSubmitError1:                    'Beim Übertragen Ihrer Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren im Zweifel unseren Vertrieb direkt.',
    exportIhseSalesMailboxSubmitLoading:                   'Lade...',
    exportIhseSalesMailboxSuccessText:                     'Vielen Dank. Wir haben Ihre Anfrage erhalten. Unser Sales-Team wird sich schnellstmöglich mit einem unverbindlichen Angebot bei Ihnen melden!',
    exportIhseSalesMailboxSuccessTitle:                    'Nachricht erhalten',
    exportIhseSalesMailboxTitle:                           '{{companyName}}-Vertriebspostfach',
    extender:                                              'Extender',
    extenderId:                                            'Extender-ID',
    extenderIdTipMainboard:                                'Hier können Sie die Extender-ID dieses Mainboards eingeben.',
    extenderIdTipUSB20:                                    'Hier können Sie die Extender-ID dieser USB-2.0-Karte eingeben.',
    extenderPorts:                                         'Extender-Ports',
    extenderTabCompact:                                    'Assemblies / Compact',
    extenderTabMisc:                                       'Misc',
    extenderTabSecure:                                     'Secure',
    extenderTabVario:                                      '{{varioAlias}}',
    externalPsu:                                           'Externes Netzteil',
    externalPsuShort:                                      'Extern',
    fanAddedAutomaticallyHint:                             'Aufgrund thermischer Gegebenheiten wurde automatisch ein Chassislüfter hinzugefügt.',
    fanIndicatorHelp:                                      'Diese Chassis hat einen Lüfter konfiguriert.',
    fanPower:                                              'Lüfter-Leistung',
    fanType:                                               'Lüfter-Typ',
    fanTypeChassis:                                        'Chassislüfter',
    fanTypeModule:                                         'Lüfterkassettenmodul',
    fileDropZoneText:                                      'Ziehen Sie eine Datei in dieses Fenster oder klicken Sie hier, um eine Dateiauswahl zu öffnen.',
    fileNameDateFormat:                                    'DD-MM-YYYY-HH-mm-ss',
    filterActivePlural:                                    'Filter',
    filterActiveSingular:                                  'Filter',
    firstName:                                             'Vorname',
    flexline:                                              'Flexline',
    frontPlates:                                           'Frontblenden',
    fullIpAndSwitching:                                    'Full Ip Extension & Switching',
    groupSimilarProductsText:                              'Produkte automatisch gruppieren',
    groupSimilarProductsTooltipText:                       'Wenn Sie diese Einstellung aktivieren, werden ähnliche Produkte zu einer Konfiguration zusammengefasst.',
    hasInternalPsu:                                        'Internes Netzteil vorhanden',
    heatDissipation:                                       'Wärmeableitung',
    heatDissipationChassisTip:                             'Die Wärmeableitung des Chassis',
    heatDissipationExternal:                               'extern',
    heatDissipationExternalPsuTip:                         'Die Wärmeableitung des externen Netzteils',
    height:                                                'Höhe',
    heightUnits:                                           'Höheneinheiten',
    help:                                                  'Hilfe',
    helpPageTitle:                                         'Hilfe',
    helpStepByStep1Text:                                   'Neues Projekt auswählen.',
    helpStepByStep1Title:                                  'Schritt 1',
    helpStepByStep2Text:                                   'Projektdaten eingeben.',
    helpStepByStep2Title:                                  'Schritt 2',
    helpStepByStep3Text:                                   'Extender konfigurieren.',
    helpStepByStep3Title:                                  'Schritt 3',
    helpStepByStep4Text:                                   'Matrix-Switch konfigurieren.',
    helpStepByStep4Title:                                  'Schritt 4',
    helpStepByStep5Text:                                   'Angebotsanfrage an {{companyName}} Vertriebsteam senden und/oder Konfiguration lokal speichern.',
    helpStepByStep5Title:                                  'Schritt 5',
    helpStepByStep6Text:                                   'Drucken.',
    helpStepByStep6Title:                                  'Schritt 6',
    helpStepByStepTitle:                                   'Schritt für Schritt',
    hid:                                                   'HID',
    hitLimitationOverlayText:                              'Das ausgewählte Modul kann nicht hinzugefügt werden.',
    hitLimitationOverlayTitle:                             'Hinzufügen wurde blockiert',
    homePage:                                              'Startseite',
    homePageTitle:                                         'Startseite',
    ihseCompanyName:                                       '{{companyName}} GmbH',
    ihseProjectNumber:                                     '{{companyName}}-Projektnummer',
    ihseProjectNumberTip:                                  'Tragen Sie hier Ihre {{companyName}}-Projektnummer ein, sofern Ihnen selbige bereits bekannt ist',
    ihseSalesMailbox:                                      '{{companyName}}-Vertriebspostfach',
    immutableIOBoard:                                      'E/A-Karte',
    import:                                                'Importieren',
    importActiveProject:                                   'Importieren in aktives Projekt',
    importActiveProjectDialogText1:                        'Wollen Sie das Projekt in das aktive Projekt importieren?',
    importActiveProjectDialogText2:                        'Sie können auch ein neues Projekt erstellen, oder den Vorgang abbrechen und zuerst das aktive Projekt schließen.',
    importDialogText1:                                     'Bitte wählen Sie eine Datei aus oder ziehen Sie eine Datei in die Box unterhalb des Textes.',
    importDialogText2:                                     'Unterstützte Formate: {{fileFormats}}',
    importDialogTextContextDesigner:                       'Alle neuen Produkte und Konfigurationen werden zum derzeit aktiven Projekt hinzugefügt.',
    importDialogTextContextNewProject:                     'Alle Produkte und Konfigurationen werden in ein neues Projekt importiert.',
    importError1:                                          'Bitte wählen Sie eine Datei aus, die Sie importieren möchten.',
    importError2:                                          'Die von Ihnen ausgewählte Datei konnte nicht verarbeitet werden. Bitte überprüfen Sie die Datei und versuchen Sie es erneut.',
    importIntoNewProject:                                  'In neues Projekt importieren',
    importSuccessActiveProjectText:                        'Der Import wurde erfolgreich abgeschlossen. Alle in der Datei vorhandenen Produkte und Einzelbestellungen wurden in das derzeit geöffnete Projekt importiert.',
    importSuccessActiveProjectTitle:                       'Import abgeschlossen',
    importSuccessNewProjectText:                           'Der Import wurde erfolgreich abgeschlossen und als neues Projekt angelegt. Klicken Sie auf "Projekt öffnen" im linken Eck dieses Dialogs um das Projekt direkt zu öffnen.',
    importSuccessNewProjectTitle:                          'Import abgeschlossen',
    imprint:                                               'Impressum',
    imprintPageTitle:                                      'Impressum',
    incompatibleProductOverlayText:                        'Das gewählte Modul wird in diesem Chassis nicht unterstützt.',
    incompatibleProductOverlayTitle:                       'Modul inkompatibel',
    information:                                           'Information',
    interlacedUpgrade:                                     'Interlaced Upgrade',
    ioBoards:                                              'E/A-Karten',
    itemNotFound:                                          'Eintrag nicht gefunden',
    language:                                              'Sprache',
    languageEnglish:                                       'English', // Beware: All items starting with "language" should be in the language of the target (so "languageGerman" is "Deutsch" and not "German")
    languageGerman:                                        'Deutsch', // Beware: All items starting with "language" should be in the language of the target (so "languageGerman" is "Deutsch" and not "German")
    lastName:                                              'Nachname',
    lastUpdate:                                            'Letzte Änderung',
    lastUpdateFallback:                                    'Unbekannt',
    lastUpdateFormat:                                      'DD.MM.YYYY HH:mm:ss',
    limitationEndOfLife:                                   'Dieses Produkt hat seine Lebenszeit erreicht und wird nicht weiter produziert.',
    limitationLimitTo:                                     'Diese Karte kann nicht in diesem Chassis verwendet werden.',
    limitationLimitToAmount:                               'Diese Option kann nur 1 mal konfiguriert werden.',
    limitationLimitToAmountOfGroupInChassis:               'Diese Karte darf in der Summe mit den Karten {{productNumbers}} in diesem Chassis maximal {{limit}} mal konfiguriert werden.',
    limitationLimitToCardsInSlot:                          'In diesem Slot darf nur eine Karte vom Typ {{productList}} stecken.',
    limitationLimitToCardsInSlotReverse:                   'Die Karte vom Typ {{partNo}} darf in diesem Chassis nur in folgenden Slots stecken: {{allowedSlotIndexes}}',
    limitationLimitToPsusInSlot:                           'In diesem Slot darf nur ein Netzteil vom Typ {{productList}} stecken.',
    limitationLimitToSlotInChassis:                        'Diese Karte darf in diesem Chassis nur auf folgenden Slots stecken: {{allowedSlotIndexes}}.',
    limitationRecommendsModuleFanInChassis1:               'Aufgrund thermischer Gegebenheiten wird ein Modullüfter an Slot {{slot}} empfohlen.',
    limitationRecommendsModuleFanInChassis2:               'Der Modullüfter muss in der aktuellen Konfiguration auf Slot {{slot}} gesteckt sein.',
    limitationRecommendsModuleFanInChassis3:               'Es muss mindestens ein Modullüfter in der aktuellen Konfiguration auf Slot {{slot}} gesteckt sein.',
    limitationRequiresFanInChassis:                        'Aufgrund thermischer Gegebenheiten wird zwingend ein Chassis- oder Modullüfter benötigt.',
    limitationRequiresModuleInChassis:                     'Das ausgewählte Modul benötigt das Zusatzmodul {{partNo}} auf Slot {{slot}}.',
    limitationRequiresHostModule1:                         'Ein Zusatzmodul kann nicht im ersten Slot eines Chassis stecken.',
    limitationRequiresHostModule2:                         'Ein Zusatzmodul muss auf ein Mainboard gesteckt werden.',
    limitationRequiresHostModule3:                         'Das zugrunde liegende Mainboard unterstützt keine Zusatzmodule.',
    limitationRequiresHostModule4:                         'Der Typ dieses Zusatzmoduls ist nicht mit dem Typ des zugrunde liegenden Mainboards kompatibel (Lokal/Remote).',
    limitationShowPowerConsumptionOverloadWarning:         'Die konfigurierten Module benötigen mehr Energie ({{powerConsumptionInMilliamps}} mA), als vom Chassis zur Verfügung gestellt werden kann ({{maximumPowerConsumptionInMilliamps}} mA).',
    limitationShowPowerConsumptionThresholdWarning:        'Die empfohlene Wärmeableitung wurde überschritten. Bitte sorgen Sie für ausreichende Belüftung. Mindestabstand zwischen Chassis: 0.5 HE.',
    limitationShowUsbWarning:                              'Die Verwendung eines USB-Extender-Moduls in dieser Konfiguration ermöglicht eine maximale Stromaufnahme von {{availablePowerConsumptionInMilliamps}} mA durch angeschlossene USB-Peripheriegeräte. Pro USB-Anschluss sind per Definition nicht mehr als {{usbPowerConsumptionInMilliamps}} mA zulässig.',
    linkResolution:                                        'Link-Auflösung',
    login:                                                 'Login',
    mainFullIp4KTab:                                       '4K',
    mainFullIpFullHdTab:                                   'HD',
    mainboardType:                                         'Technologie',
    mainboards:                                            'Mainboards',
    matrixEndpoints:                                       'Matrix-Endpunkte',
    matrixManagement:                                      'Matrix-Management',
    matrixPorts:                                           'Matrix-Ports',
    matrixSwitch:                                          'Matrix-Switch',
    matrixTabCatx1g:                                       'catx1g',
    matrixTabCompact:                                      'Compact',
    matrixTabEnterprise:                                   '{{enterpriseAlias}}',
    matrixTabFiber1g:                                      'fiber1g',
    matrixTabFiber3g:                                      'fiber3g',
    matrixTabFlex:                                         '{{flexAlias}}',
    matrixTabFlexChassis:                                  'Fertiggeräte',
    matrixTabFlexChassisCustomDesign:                      'Kundenspez. Design',
    matrixTabFlexIOBoards:                                 'I/O-Boards',
    matrixTabHybrid:                                       'hybrid',
    matrixTabUni:                                          'uni',
    maximumPowerConsumption:                               'Maximaler Energieverbrauch',
    maximumPowerConsumptionShort:                          'Max. Energieverbrauch',
    maximumPowerConsumptionTip:                            'Aktueller Energieverbrauch/maximaler Energieverbrauch',
    media4Connect:                                         'Media4Connector',
    milliamps:                                             'Milliampere',
    milliampsShort:                                        'mA',
    millimeter:                                            'Millimeter',
    millimeterShort:                                       'mm',
    milliwatts:                                            'Milliwatt',
    milliwattsShort:                                       'mW',
    miscPorts:                                             'sonstige Ports',
    modulePlural:                                          'Module',
    moduleSingular:                                        'Modul',
    more:                                                  'Mehr',
    nameOfResponsiblePerson:                               'Verantwortlicher',
    nameOfResponsiblePersonFallbackName:                   'Nicht angegeben',
    nameOfResponsiblePersonTip:                            'Geben Sie hier den Namen der für das Projekt maßgebend verantwortlichen Person ein.',
    newProject:                                            'Neues Projekt',
    newVersionHintLaterButton:                             'Später',
    newVersionHintText:                                    'Eine neue Version des {{applicationName}} ist verfügbar',
    newVersionHintUpdateButton:                            'Jetzt aktualisieren',
    no:                                                    'Nein',
    noImageText:                                           'Kein Bild vorhanden',
    noInternetConnectionOverlayText:                       'Für diese Funktion wird leider eine aktive Internetverbindung benötigt.',
    noInternetConnectionOverlayTitle:                      'Internetverbindung benötigt',
    noProjectNameGiven:                                    'Kein Projektname vergeben',
    noProjectsText:                                        'Wählen Sie zwischen folgenden Möglichkeiten: Neues Projekt erstellen oder Importieren.',
    noProjectsTitle:                                       'Keine Projekte vorhanden',
    numberFilterPlaceholder:                               'z.B.: 3, 10-12, >20',
    numberFilterTip:                                       'Geben Sie hier eine Zahl ein, nach der Sie Suchen möchten. Ergänzend können Sie mit einem Bindestrich (-) einen Bereich definieren (Beispiel: "4000-8000") oder mit dem Kleiner- (<) und Größer-Zeichen (>) auch Werte ausgrenzen (Beispiel: ">4000")',
    numberOfMonitors:                                      'Anzahl der Monitore',
    numberOfMonitorsTitle1:                                '1 Monitor / Single-Head',
    numberOfMonitorsTitle2:                                '2 Monitore / Dual-Head',
    offerNumber:                                           'Angebotsnummer',
    offerNumberTip:                                        'In diesem Feld kann die {{companyName}}-Angebotsnummer hinterlegt werden.',
    ok:                                                    'Ok',
    openProject:                                           'Projekt öffnen',
    option:                                                'Option',
    optionalFanCount:                                      'Optionale Lüfter',
    optionalPsuCount:                                      'Optionale Netzteile',
    options:                                               'Optionen',
    optionsTip:                                            'Anzahl der für dieses Gerät verfügbaren Optionen',
    or:                                                    'oder',
    otherComment:                                          'Sonstige Bemerkung',
    otherCommentTip:                                       'In diesem Feld können Sie sonstige Bemerkungen hinterlegen, wie beispielsweise interne Artikel- oder Produktnummern.',
    otherModules:                                          'Sonstiges',
    overlayDontShowAgain:                                  'Diesen Dialog nicht wieder anzeigen.',
    overlayDontShowAgainSwitchToDuplicatedProduct:         'Diesen Dialog nicht wieder anzeigen und immer automatisch wechseln.',
    pageNotFound:                                          'Seite nicht gefunden',
    pageNotFoundPageTitle:                                 'Seite nicht gefunden',
    pageNotFoundText:                                      'Die angeforderte Seite konnte nicht gefunden werden. Bitte klicken Sie auf das Logo im Kopfbereich der Seite, um zur Startseite zurückzukehren. Wenn Sie sich bitte an unseren Support, falls dieses Problem öfters auftritt.',
    pageTitle:                                             '{{applicationName}} - {{companyName}}',
    panelNameBottom:                                       'Unten',
    panelNameLeft:                                         'Links',
    panelNameRight:                                        'Rechts',
    partNumber:                                            'Artikelnummer',
    partNumberShort:                                       'Art.-Nr.',
    partsList:                                             'Stückliste',
    partsListExportCsv:                                    'CSV-Download',
    phoneNumber:                                           'Telefonnummer',
    placeHere:                                             'Hier platzieren',
    port:                                                  'Port',
    portCount:                                             'Ports',
    portCountButtonText:                                   'Details',
    portOverview:                                          'Portübersicht',
    portOverviewErrorTooLessMatrixPorts:                   'Verfügbare Matrix-Ports nicht ausreichend',
    ports:                                                 'Ports',
    portsTip:                                              'Anzahl der im Chassis vorhandenen Ports',
    power:                                                 'Leistung',
    powerConsumption:                                      'Stromaufnahme',
    powerConsumptionCurrent:                               'Insgesamt',
    powerConsumptionMaximum:                               'Maximal',
    powerConsumptionRecommended:                           'Empfohlen',
    powerConsumptionRedundancyBoard:                       'Redundanz-Board',
    powerConsumptionWithDash:                              'Strom- aufnahme',
    powerOverEthernet:                                     'Power over Ethernet',
    powerOverFiber:                                        'Power over Fiber',
    powerRedundancy:                                       'Netzteil-Redundanz',
    print:                                                 'PDF drucken',
    printDialogButton:                                     'Als PDF Drucken',
    printError1:                                           'Um die PDF-Vorschau zu generieren wird eine aktive Internetverbindung benötigt.',
    printPreview:                                          'PDF-Vorschau',
    printPreviewHTML:                                      'HTML-Exportvorschau',
    productCategoryHide:                                   'Ausblenden',
    productCategoryShow:                                   'Anzeigen',
    productImagePreview:                                   'Produktbildvorschau',
    productNoSlotsText:                                    'Dieses Produkt hat keine konfigurierbaren Slots und Karten. Bitte entnehmen Sie den Spezifikationen auf der rechten Seite weitere Details.',
    productNoSlotsTitle:                                   'Information',
    productQuickSelectNoMatches:                           'Keine Treffer',
    project:                                               'Projekt',
    projectDescription:                                    'Projektbeschreibung',
    projectDescriptionTip:                                 'Hier können Sie eine beliebige Beschreibung für Ihr Projekt eintragen.',
    projectFallbackName:                                   'Kein Projektname vergeben',
    projectListSaveTip:                                    'Informationen zur Datenspeicherung',
    projectListSaveTipText:                                'Alle von Ihnen angelegten Projekte und dessen Konfigurationen werden im Speicher ("Local storage") Ihres Browsers gespeichert und gelöscht, wenn Sie die Anwendungsdaten des {{applicationName}}s aus Ihrem Browser löschen.',
    projectSelection:                                      'Projektauswahl',
    projectTitle:                                          'Projektbezeichnung',
    projectTitleTip:                                       'Hier können Sie einen beliebigen Titel für Ihr Projekt angeben. Der Titel wird auch beim Export als Dateiname verwendet.',
    quickSearch:                                           'Schnellsuche',
    quickSearchTip:                                        'Suchen Sie hier ein Produkt anhand der Artikelnummer. Ausgewählte Produkte werden automatisch an die richtige Position hinzugefügt.',
    rackUnits:                                             'Höheneinheiten',
    redundant:                                             'Redundant',
    redundantNo:                                           'Nein',
    redundantYes:                                          'Ja',
    reloadData:                                            'Daten neu laden',
    repeatSlotAmount:                                      'Slotanzahl',
    repeatSlotAmountPlaceholder:                           'Slotanzahl angeben...',
    repeatSlotAmountPowerConsumptionError:                 'Die angegebene Anzahl kann nicht aufgefüllt werden, da die Karten in der Summe den zulässigen Maximalstromverbrauch überschreiten würden.',
    repeatSlotAmountTip:                                   'Geben Sie hier an, wie oft Sie das Produkt {{productId}} in diesem Chassis nach Slot {{slotIndex}} hinzufügen möchten.',
    repeatSlotText:                                        'Sie können die aktuelle Karte ({{productId}}) für nachfolgende Slots wiederholen. Geben Sie hier die Anzahl der Karten ein, die Sie verbauen wollen. Nach dem gerade konfigurierten Slot {{slotIndex}} sind noch {{freeSlots}} weitere Slots frei.',
    repeatSlotTitle:                                       'Weitere Slots füllen',
    requestQuote:                                          'Angebot',
    requestQuoteShort:                                     'Angebot',
    resetAll:                                              'Alles zurücksetzen',
    resetDialogsButtonText:                                'Zurücksetzen',
    resetDialogsText:                                      'Dialoge zurücksetzen',
    resetDialogsTooltip:                                   'Hier können Sie alle Dialoge, die Sie durch die Wahl der "Nicht mehr anzeigen"-Checkbox versteckt haben, wieder anzeigen lassen. Beim Klick auf "Zurücksetzen" werden alle Dialoge zurückgesetzt.',
    resolution:                                            'Max Auflösung',
    reverseLayout:                                         'Reverse-Layout erstellen',
    reverseLayoutTip:                                      'Mit dem "Reverse-Layout" erstellen wir für Sie automatisch die gegensätzliche Konfiguration zur derzeit ausgewählten Chassiskonfiguration. Alle Remote-Karten (R) werden durch lokale Karten ersetzt und alle lokalen Karten entsprechend durch Remote-Karten. Sollte es ein Gegenstück nicht geben, wird der Slot im Reverse-Layout frei gelassen. Alle anderen Karten (ohne "L" oder "R" als erstes Zeichen der Artikelnummer bleiben bestehen und werden in das Reverse-Layout übernommen.',
    reversedExtenderText:                                  'Das Reverse-Layout für den Extender ("{{name}}") wurde erfolgreich erstellt. Wollen Sie jetzt direkt zum erstellten Reverse-Layout wechseln?',
    reversedExtenderTitle:                                 'Reverse-Layout erstellt',
    reversedProductPostfix:                                '(Reverse-Layout)',
    rightPaneEmptyText:                                    'Bitte wählen Sie einen Eintrag im linken Paneel per Doppelklick aus, um hier Details anzuzeigen.',
    rightPaneEmptyTitle:                                   'Keine Auswahl',
    salesMailboxCommentTooltip:                            'Hier können Sie beliebige Informationen und weitere Kommentare eingeben.',
    salesMailboxCompanyError:                              'Bitte geben Sie den Namen der Firma an, für die Sie tätig sind.',
    salesMailboxCompanyTooltip:                            'Bitte nennen Sie uns den Namen der Firma, für die Sie tätig sind.',
    salesMailboxEMailAddressError:                         'Bitte geben Sie Ihre E-Mail-Adresse an.',
    salesMailboxEMailAddressTooltip:                       'Um mit Ihnen in Kontakt treten zu können, benötigen wir Ihre geschäftliche E-Mail-Adresse.',
    salesMailboxExtendedWarrantyText:                      'Garantieverlängerung',
    salesMailboxFirstNameError:                            'Bitte geben Sie Ihren Vornamen an.',
    salesMailboxFirstNameTooltip:                          'Bitte geben Sie Ihren Vornamen an.',
    salesMailboxFurtherConsultationText:                   'Sie wünschen weitere Beratung zu folgenden Themen:',
    salesMailboxLastNameError:                             'Bitte geben Sie Ihren Nachnamen an.',
    salesMailboxLastNameTooltip:                           'Bitte geben Sie Ihren Nachnamen an.',
    salesMailboxPhoneNumberError:                          'Bitte geben Sie Ihre Telefonnummer an.',
    salesMailboxPhoneNumberTooltip:                        'Um mit Ihnen in Kontakt treten zu können, benötigen wir Ihre geschäftliche Telefonnummer.',
    salesMailboxServiceLevelAgreementText:                 'Dienstleistungsvereinbarung (SLA)',
    salesMailboxSparePartsText:                            'Ersatzteile',
    saveNow:                                               'Jetzt exportieren',
    scalableline:                                          'Scalableline',
    screenToSmallSubmit:                                   'Absenden',
    screenToSmallText:                                     'Der {{applicationName}} ist derzeit nur für Geräte mit einer Displaygröße ab 1024x768px optimiert.',
    screenToSmallTitle:                                    'Ihr Display oder Browserfenster ist zu klein.',
    selectMultipleChassisAndDeleteThem:                    'Mehrere Chassis auswählen & löschen',
    selectProductCategoryText:                             'Bitte treffen Sie auf der linken Seite eine Auswahl.',
    selection:                                             'Auswahl',
    serial:                                                'Seriell',
    serialRs232:                                           'RS232',
    serialRs422:                                           'RS422',
    setSelectedDataConfirmButton:                          'Chassis überschreiben',
    setSelectedDataConfirmText:                            'Sie sind im Begriff, das aktuelle Chassis ({{fromName}}) mit {{fromSlots}} Slots gegen ein anderes Chassis ({{toName}}) mit weniger Slots ({{toSlots}}) zu tauschen. Alle bestückten Slots nach Slot {{toSlots}} werden gelöscht. Möchten Sie fortfahren?',
    setSelectedDataConfirmTextToImmutable:                 'Sie sind im Begriff, das aktuelle Chassis ({{fromName}}) mit {{fromSlots}} Slots gegen ein vorkonfiguriertes und unveränderbares Chassis ({{toName}}) zu tauschen. Alle aktuell bestückten Slots werden gelöscht. Möchten Sie fortfahren?',
    setSelectedDataConfirmTitle:                           'Chassis überschreiben?',
    settings:                                              'Einstellungen',
    sfpModules:                                            'SFP-Module',
    showDetails:                                           'Details anzeigen',
    showEndOfLifeProductsFieldText:                        'Zeige Auslaufprodukte an',
    showEndOfLifeProductsFieldTooltipText:                 'Wenn Sie diese Einstellung aktivieren, werden Produkte angezeigt welche sich im Status "End-Of-Life" befinden.',
    showMoreSlots:                                         '{{slots}} weitere Slots anzeigen',
    showOfferNumberFieldText:                              'Zusatzfeld für Angebotsnummer',
    showOfferNumberFieldTooltipText:                       'Wenn Sie diese Einstellung aktivieren, können Sie auf Projektebene eine Angebotsnummer hinterlegen.',
    showOtherCommentFieldText:                             'Zusatzfeld für sonstige Bemerkungen',
    showOtherCommentFieldTooltipText:                      'Wenn Sie diese Einstellung aktivieren, können Sie zu jedem Chassis und jeder gesteckten Karte neben dem Namen in einem zusätzlichen Feld beliebige, sonstige Bemerkungen hinterlegen.',
    simultaneousDownscaling:                               'Downscaling',
    slot:                                                  'Slot',
    slotCount:                                             'Slots',
    slotTypeAccessoryShort:                                'A',
    slotTypeCpuShort:                                      'C',
    slotTypeFanShort:                                      'F',
    slotTypePowerSupplyUnitShort:                          'P',
    slots:                                                 'Slots',
    slotsTip:                                              'Anzahl der im Chassis verfügbaren Slots',
    smartDropZoneAdd:                                      'Hier fallen lassen, um automatisch an sinnvoller Stelle hinzuzufügen.',
    specification:                                         'Spezifikation',
    statusBarEmptyText:                                    'Fügen Sie Ihrem Projekt einen Extender oder Matrix-Switch hinzu, um hier eine Port-Übersicht zu sehen.',
    supportedVideoChannels:                                'Videokanäle',
    supportsPiggyback:                                     'Unterstützt Zusatzmodule',
    tableInputFilterNoMatches:                             'Keine Treffer',
    targetSlot:                                            'Slot',
    timeOClock:                                            'Uhr',
    ultra:                                                 'Ultra',
    uncompressed:                                          'Unkomprimiert',
    unknownError:                                          'Unbekannter Fehler',
    updateBrowserText:                                     'Die Version \'{{browserName}} {{browserVersion}}\' Ihres Browsers wird derzeit nicht unterstützt. Der {{applicationName}} funktioniert am besten mit der aktuellsten Version. Bitte aktualisieren Sie Ihren Browser.',
    updateBrowserTitle:                                    'Nicht unterstützte Browser-Version erkannt',
    usb11:                                                 '1.1',
    usb20:                                                 '2.0',
    usb20Modules:                                          'USB-2.0-Module',
    usb31:                                                 '3.1',
    usb:                                                   'USB',
    usbFlexSpeed20:                                        '2.0 Flexspeed',
    usbFlexspeed20:                                        '2.0 Flexspeed', // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-385
    usbFullSpeed20:                                        '2.0 Fullspeed ',
    usbFullspeed20:                                        '2.0 Fullspeed ', // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-385
    usbHighSpeed20:                                        '2.0 Highspeed',
    usbMemoryDeviceSupport:                                'USB-Memory-Device-Unterstützung',
    version:                                               'Version',
    versionNumberDateFormat:                               'DD.MM.YYYY HH:mm:ss',
    videoConnector:                                        'Videoschnittstelle',
    videoConnectorTypeBnc:                                 'BNC',
    videoConnectorTypeDisplayport:                         'DisplayPort',
    videoConnectorTypeDpHdmi:                              'DP/HDMI',
    videoConnectorTypeDualhead:                            'Dual-Head DVI-D',
    videoConnectorTypeDuallink:                            'Dual-Link DVI-D',
    videoConnectorTypeDvid:                                'DVI-D',
    videoConnectorTypeDvii:                                'DVI-I',
    videoConnectorTypeHdbnc:                               'HDBNC',
    videoConnectorTypeHdmi:                                'HDMI',
    videoConnectorTypeIp:                                  'IP',
    volt:                                                  'Volt',
    voltShort:                                             'V',
    warningErrors:                                         'Fehler',
    warningInformation:                                    'Hinweise',
    warningsInYourConfiguration:                           'Warnungen in Ihrer Konfiguration',
    wattShort:                                             'W',
    width:                                                 'Breite',
    yes:                                                   'Ja',
    yourProjects:                                          'Ihre Projekte',
    zoomControlDragDropHintDetails:                        'Drücken Sie ALT + Drag&Drop, um Karten zu duplizieren.',
    zoomControlDragDropHintPreview:                        'Drücken Sie den Button auf der rechten Seite um die Detail-Ansicht zu öffnen.',
};

module.exports = german;

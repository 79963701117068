//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';
import _    from 'lodash';

import AccessoryTypes      from '@constants/AccessoryTypes';
import ProductCategoryType from '@constants/ProductCategoryType';
import ProductSlotType     from '@constants/ProductSlotType';
import SlotType            from '@constants/SlotType';
import Cast                from '@helper/Cast';
import DataProvider        from '@helper/DataProvider';
import IdGenerator         from '@helper/IdGenerator';
import Limitations         from '@helper/Limitations';
import ProductTitleHelper  from '@helper/ProductTitle';

class ActiveProjectsFactory {
    static duplicateProducts = (product, amount) => {
        const products = [];

        for (let productIndex = 1; productIndex <= amount; productIndex++) {
            products.push(ActiveProjectsFactory.duplicateProduct(product, productIndex));
        }

        return products;
    };

    static duplicateProduct = (product, index = 1) => {
        const visibleIndex                  = index + 1;
        const duplicatedProduct             = _.cloneDeep(product);
        const title                         = ProductTitleHelper.getProductCustomNameOrPartNumber(product);
        duplicatedProduct.id                = IdGenerator.getNewProductId();
        duplicatedProduct.duplicatedFromId  = product.id;
        duplicatedProduct.customName        = (
            `${title} (${visibleIndex})`
        ).trim();
        duplicatedProduct.copiedFromId      = product.id;
        duplicatedProduct.nameChangedByUser = false;
        const subProductKeys                = Object.keys(duplicatedProduct.subProducts);

        for (const subProductCategoryKey of subProductKeys) {
            const subProducts = duplicatedProduct.subProducts[subProductCategoryKey];

            for (const subProductIndex in subProducts) {
                const subProduct = subProducts[subProductIndex];

                subProduct.nameChangedByUser = false;
            }
        }

        return duplicatedProduct;
    };

    static getEmptyState = () => {
        return {};
    };

    static getInitialState = () => {
        return {
            activeProductCategoryType:       null,
            autoFillSlots:                   {
                freeSlotsAfterSlotIndex: null,
                productId:               null,
                slotIndex:               null,
            },
            creationDate:                    new Date(),
            deleteModulesContext:            {
                modules: {},
            },
            downloadOverlayPartNumber:       null,
            duplicatedProduct:               {
                categoryType: null,
                index:        null,
            },
            fanAddedAutomatically:           false,
            hideUnsavedProjectWarning:       false,
            id:                              IdGenerator.getNewProjectId(),
            lastDuplicateAmount:             0,
            lastSelectedProduct:             {
                categoryType: null,
                index:        null,
            },
            lastExportDate:                  null,
            lastUpdateDate:                  new Date(),
            metaData:                        {
                description:             '',
                ihseProjectNumber:       '',
                nameOfResponsiblePerson: '',
                offerNumber:             '',
                title:                   '',
            },
            outdatedProducts:                [],
            productDeletionContext:          {
                categoryType: null,
                id:           null,
            },
            products:                        {
                [ProductCategoryType.extender]: [],
                [ProductCategoryType.matrix]:   [],
                [ProductCategoryType.fullIp]:   [],
            },
            multipleProductsDeletionContext: {
                [ProductCategoryType.matrix]:    {},
                [ProductCategoryType.extender]:  {},
                [ProductCategoryType.equipment]: {},
                [ProductCategoryType.fullIp]:    {},
            },
            chassisGroupToggleContext:       {
                [ProductCategoryType.matrix]:    {},
                [ProductCategoryType.extender]:  {},
                [ProductCategoryType.equipment]: {},
                [ProductCategoryType.fullIp]:    {},
            },
            reversedProduct:                 {
                categoryType: null,
                index:        null,
            },
            routeAfterClose:                 null, // TODO: das muss beim closen und öffnen? resetted werden
            selectedProduct:                 {
                categoryType: null,
                index:        null,
            },
            selectedSingleOrderSlot:         {
                categoryType: null,
                index:        null,
            },
            selectedSlot:                    {
                index:    null,
                slotType: null,
            },
            setSelectedProductIdConfirm:     null,
            sfpDeletionContext:              {
                index: null,
            },
            singleOrders:                    {
                [ProductCategoryType.equipment]: this.getEmptySingleOrderSlots(5),
                [ProductCategoryType.extender]:  this.getEmptySingleOrderSlots(5),
                [ProductCategoryType.matrix]:    this.getEmptySingleOrderSlots(5),
                [ProductCategoryType.fullIp]:    this.getEmptySingleOrderSlots(5),
            },
            singleOrderSlotDeletionContext:  {
                index:        null,
                categoryType: null,
            },
            slotDeletionContext:             {
                index: null,
                type:  null,
            },
            // This context is used when the user is about to overwrite
            // a slot that was already set.
            slotOverwriteContext: {
                actionPayload: null,
                actionType:    null,
            },
        };
    };

    static getEmptyProductSlot = (productData) => {
        const productSlot = {
            customName:   null,
            extenderId:   '',
            otherComment: '',
            productData,
            warnings:     [],
        };

        if (productData && productData.configurableSfpCount) {
            productSlot.sfps = ActiveProjectsFactory.getEmptyProductSlots(productData.configurableSfpCount);
        }

        return productSlot;
    };

    static getEmptyProductSlots = (count, copyFrom) => {
        const slots = [];

        for (let index = 0; index < count; ++index) {
            const newSlot = (
                copyFrom && copyFrom[index] ?
                    copyFrom[index] :
                    ActiveProjectsFactory.getEmptyProductSlot()
            );

            slots.push(newSlot);
        }

        return slots;
    };

    static getEmptySingleOrderSlot = (productData) => {
        return {
            amount: 1,
            productData,
        };
    };

    static getEmptySingleOrderSlots = (count) => {
        const singleOrders = [];

        for (let index = 0; index < count; ++index) {
            const newSingleOrder = ActiveProjectsFactory.getEmptySingleOrderSlot();

            singleOrders.push(newSingleOrder);
        }

        return singleOrders;
    };

    static getProduct = (categoryType, productData) => {
        console.log('factories: activeProject', categoryType, productData);

        const stateProductData = {
            categoryType,
            customName:                  '',
            duplicatedFromId:            null,
            heatDissipation:             {
                chassis:     0,
                externalPsu: 0,
            },
            id:                          IdGenerator.getNewProductId(),
            nameChangedByUser:           false,
            otherComment:                '',
            powerConsumptionInMilliAmps: 0,
            productData,
            reversedFromId:              null,
            subProducts:                 {
                // Make sure to also extend reducer/activeProject.js at
                //     case ActiveProjectTypes.SET_SELECTED_PRODUCT_DATA:
                //     case ActiveProjectTypes.SET_SELECTED_PRODUCT_DATA_CHECK_CONFIRM:
                // when you add new categories here.
                accessory:       ActiveProjectsFactory.getEmptyProductSlots(1),
                fan:             ActiveProjectsFactory.getEmptyProductSlots(_.get(productData, 'optionalFanCount', 0)),
                powerSupplyUnit: ActiveProjectsFactory.getEmptyProductSlots(productData.psuCount),
                slot:            ActiveProjectsFactory.getEmptyProductSlots(productData.slotCount),
            },
            warnings:                    [],
        };

        return this.prefillSlots(stateProductData);
    };

    static getWarning(type, text) {
        return {
            text,
            type,
        };
    }

    static prefillSlots = (stateProductData) => {
        const productData = stateProductData.productData;

        this.prefillSlotsForSlotType(
            stateProductData,
            SlotType.slot,
            _.get(productData, 'layoutDefinition.cpu'),
        );

        this.prefillSlotsForSlotType(
            stateProductData,
            SlotType.slot,
            _.get(productData, 'layoutDefinition.uni'),
        );

        this.prefillSlotsForSlotType(
            stateProductData,
            SlotType.powerSupplyUnit,
            _.get(productData, 'psuDefinition.psu'),
        );

        return stateProductData;
    };

    static prefillSlotsForSlotType = (stateProductData, slotType, definitionList) => {
        // https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-624
        console.log('prefillSlotsForSlotType', stateProductData, definitionList);

        if (definitionList) {
            for (const definition of definitionList) {
                const productData = DataProvider.getById(definition.partNo);

                if (productData) {
                    const slotData = ActiveProjectsFactory.getEmptyProductSlot(productData);

                    if (definition.slot === -1) {
                        console.log('stateProductData.subProducts[slotType]', stateProductData.subProducts[slotType]);

                        for (const slotIndex in stateProductData.subProducts[slotType]) {
                            stateProductData.subProducts[slotType][slotIndex] = slotData;
                        }
                    } else {
                        const internalSlotIndex                                   = definition.slot - 1;
                        stateProductData.subProducts[slotType][internalSlotIndex] = slotData;
                    }
                } else {
                    console.error('Prefilled product part no is unknown', definition.partNo);
                }
            }
        }
    };

    static getCpuSlotNumbersForLayoutDefinition = (layoutDefinition) => {
        const cpuSlots = [];

        if (layoutDefinition) {
            if (layoutDefinition.cpu) {
                for (const cpu of layoutDefinition.cpu) {
                    cpuSlots.push(cpu.slot);
                }
            }

            const highestCpuSlot = layoutDefinition.rowCount * layoutDefinition.columnCount;

            for (let cpuSlot = 1; cpuSlot < highestCpuSlot; ++cpuSlot) {
                if (
                    layoutDefinition.io &&
                    layoutDefinition.io.indexOf(cpuSlot) === -1 &&
                    layoutDefinition.unused &&
                    layoutDefinition.unused.indexOf(cpuSlot) === -1
                ) {
                    cpuSlots.push(cpuSlot);
                }
            }
        }

        const cpuSlotsUnique = _.uniq(cpuSlots);

        return cpuSlotsUnique;
    };

    /**
     * @param targetProductData The actual product object containing keys like "customName"
     * @param fromProductData The raw chassis product data object delivered from ihse
     * @param toProductData The raw chassis product data object delivered from ihse
     * @returns {*}
     */
    static getTransferredSlots = (targetProductData, fromProductData, toProductData) => {
        // https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-624

        /*
        // Uncomment this if you want to get some debug data you can use in "activeProjects.test.js"
        console.log(
            'Data for: activeProjects.test.js',
            'slotList:',
            JSON.stringify(targetProductData),
            'fromProductData:',
            JSON.stringify(fromProductData),
            'toProductData:',
            JSON.stringify(toProductData),
        );
        // */

        // @formatter:off
        targetProductData = this.getTransferredSlotsStep1Cpu(targetProductData, fromProductData, toProductData);
        targetProductData = this.getTransferredSlotsStep2Uni(targetProductData, fromProductData, toProductData);
        targetProductData = this.getTransferredSlotsStep3RemoveUnusedSlots(targetProductData, fromProductData, toProductData);
        targetProductData = this.getTransferredSlotsStep4SkipBlindPlates(targetProductData, fromProductData, toProductData);
        // @formatter:on

        return targetProductData;
    };

    static getTransferredSlotsStep1Cpu = (targetProductData, fromProductData, toProductData) => {
        const fromLayoutDefinition = fromProductData.layoutDefinition;
        const toLayoutDefinition   = toProductData.layoutDefinition;

        if (
            fromLayoutDefinition &&
            fromLayoutDefinition.cpu &&
            toLayoutDefinition &&
            toLayoutDefinition.cpu
        ) {
            const fromCpuSlotNumbers = this.getCpuSlotNumbersForLayoutDefinition(fromLayoutDefinition);
            const toCpuSlotNumbers   = this.getCpuSlotNumbersForLayoutDefinition(toLayoutDefinition);
            const toChassisSlotCount = toLayoutDefinition.rowCount * toLayoutDefinition.columnCount;
            const removedIoCards     = [];
            const emptiedSlotIndexes = [];

            //
            // Add additional slots to the targetProductData array that may be empty since
            // it is possible to scale up from a chassis with 8 slots to a chassis with 20 slots (for example)
            //
            while (targetProductData.length < toChassisSlotCount) {
                targetProductData.push(ActiveProjectsFactory.getEmptyProductSlot());
            }

            let extraSlotsToRemove = fromCpuSlotNumbers.length - toCpuSlotNumbers.length;

            //
            // Move the cpu cards to their new target. This is necessary since the cpu slots are on different
            // positions in different chassis. So we have to move the cpu cards from slot index 1 and 2 to
            // slot 21 and 22 (for example)
            //
            for (const index in fromCpuSlotNumbers) {
                const fromCpuSlotNumber = fromCpuSlotNumbers[index] - 1;

                if (toCpuSlotNumbers.length > index) {
                    const toCpuSlotNumber = toCpuSlotNumbers[index] - 1;

                    if (fromCpuSlotNumber !== toCpuSlotNumber) {
                        if (
                            targetProductData[toCpuSlotNumber].productData &&
                            targetProductData[toCpuSlotNumber].productData.productSlotType === ProductSlotType.ioBoard
                        ) {
                            // Store removed io board that are replaced by cpu cards to re-add them later on
                            // free slots
                            removedIoCards.push(targetProductData[toCpuSlotNumber].productData);
                        }

                        targetProductData[toCpuSlotNumber].productData   = _.cloneDeep(targetProductData[fromCpuSlotNumber].productData);
                        targetProductData[fromCpuSlotNumber].productData = null;

                        emptiedSlotIndexes.push(Cast.int(fromCpuSlotNumber));
                    }
                } else {
                    targetProductData[fromCpuSlotNumber].productData = null;
                }
            }

            //
            // Also add all fixed cpu cards
            //
            for (const cpuLayoutDefinition of toLayoutDefinition.cpu) {
                const cpuSlotIndex = cpuLayoutDefinition.slot - 1;

                if (
                    targetProductData[cpuSlotIndex].productData &&
                    targetProductData[cpuSlotIndex].productData.productSlotType === ProductSlotType.ioBoard
                ) {
                    // Store removed io board that are replaced by cpu cards to re-add them later on
                    // free slots
                    removedIoCards.push(targetProductData[cpuSlotIndex].productData);
                }

                // TODO: Hier fehlt der Support von "Slot: -1"
                targetProductData[cpuSlotIndex].productData = DataProvider.getById(cpuLayoutDefinition.partNo);
            }

            //
            // Iterate all "hidden"/"unknown" cpu slots and remove iocards that may be placed here
            //

            const cpuSlotNumbers = this.getCpuSlotNumbersForLayoutDefinition(toLayoutDefinition);

            for (const cpuSlotNumber of cpuSlotNumbers) {
                const cpuSlotNumberInternalInt = Cast.int(cpuSlotNumber) - 1;

                if (
                    targetProductData[cpuSlotNumberInternalInt] &&
                    targetProductData[cpuSlotNumberInternalInt].productData &&
                    targetProductData[cpuSlotNumberInternalInt].productData.productSlotType === ProductSlotType.ioBoard
                ) {
                    removedIoCards.push(targetProductData[cpuSlotNumberInternalInt].productData);

                    targetProductData[cpuSlotNumberInternalInt].productData = null;
                }
            }

            //
            // Add all cards we previously removed to all free slots
            //

            let tryToReAddRemovedIoCards = true;

            emptiedSlotIndexes.reverse();

            do {
                tryToReAddRemovedIoCards = false;

                for (const targetSlotIndex in targetProductData) {
                    const targetSlotIndexInt = Cast.int(targetSlotIndex);

                    if (
                        toLayoutDefinition.io.indexOf(targetSlotIndexInt + 1) > -1 &&
                        !targetProductData[targetSlotIndex].productData &&
                        removedIoCards.length > 0
                    ) {
                        targetProductData[targetSlotIndex].productData = removedIoCards.shift();
                        tryToReAddRemovedIoCards                       = true;
                        const emptiedSlotIndex                         = emptiedSlotIndexes.indexOf(targetSlotIndexInt);

                        if (emptiedSlotIndex > -1) {
                            emptiedSlotIndexes.splice(emptiedSlotIndex, 1);
                        }
                    }
                }
            } while (tryToReAddRemovedIoCards);

            //
            // Move slots to the left if whe moved cpu slots from "far left" to the right. So for example: If a cpu
            // boar in the "from chassis" was at slot 1 and is in the "to chassis" at slot 20, we have to move all
            // io boards one slot to the left.
            //
            while (emptiedSlotIndexes.length > 0) {
                for (
                    let targetSlotIndex = emptiedSlotIndexes.shift() - 1;
                    targetSlotIndex < targetProductData.length - 1;
                    ++targetSlotIndex
                ) {
                    const nextSlotIndex = targetSlotIndex + 1;

                    if (
                        targetProductData[nextSlotIndex].productData &&
                        targetProductData[nextSlotIndex].productData.productSlotType === ProductSlotType.ioBoard
                    ) {
                        targetProductData[targetSlotIndex].productData = targetProductData[nextSlotIndex].productData;
                        targetProductData[nextSlotIndex].productData   = null;
                    }
                }
            }

            //
            // Remove extra empty slots. This is required when the target chassis has less cpu slots than the
            // source chassis.
            //
            while (extraSlotsToRemove > 0) {
                for (
                    let targetSlotIndex = 0;
                    targetSlotIndex < targetProductData.length - 1;
                    ++targetSlotIndex
                ) {
                    const nextSlotIndex = targetSlotIndex + 1;

                    if (
                        !targetProductData[targetSlotIndex].productData &&
                        targetProductData[nextSlotIndex].productData &&
                        targetProductData[nextSlotIndex].productData.productSlotType === ProductSlotType.ioBoard
                    ) {
                        targetProductData[targetSlotIndex].productData = targetProductData[nextSlotIndex].productData;
                        targetProductData[nextSlotIndex].productData   = null;
                    }
                }

                --extraSlotsToRemove;
            }
        }

        return targetProductData;
    };

    static getTransferredSlotsStep2Uni = (targetProductData, fromProductData, toProductData) => {
        const toUniDefinition = toProductData.uniDefinition;

        if (
            toUniDefinition &&
            toUniDefinition.uni
        ) {
            // Also add all fixed uni cards
            for (const uniLayoutDefinition of toUniDefinition.uni) {
                const slotProductData = DataProvider.getById(uniLayoutDefinition.partNo);
                const sfps            = (
                    slotProductData && slotProductData.configurableSfpCount ?
                        ActiveProjectsFactory.getEmptyProductSlots(slotProductData.configurableSfpCount) :
                        null
                );

                if (uniLayoutDefinition.slot === -1) {
                    for (let slotIndex = 0; slotIndex < toProductData.slotCount; ++slotIndex) {
                        if (!targetProductData[slotIndex]) {
                            targetProductData[slotIndex] = ActiveProjectsFactory.getEmptyProductSlot();
                        }

                        targetProductData[slotIndex].productData = slotProductData;

                        if (!targetProductData[slotIndex].sfps) {
                            targetProductData[slotIndex].sfps = sfps;
                        }
                    }
                } else {
                    targetProductData[uniLayoutDefinition.slot - 1].productData = slotProductData;

                    if (!targetProductData[uniLayoutDefinition.slot - 1].sfps) {
                        targetProductData[uniLayoutDefinition.slot - 1].sfps = sfps;
                    }
                }
            }
        }

        return targetProductData;
    };

    static getTransferredSlotsStep3RemoveUnusedSlots = (targetProductData, fromProductData, toProductData) => {
        const fromLayoutDefinition = fromProductData.layoutDefinition;
        const toLayoutDefinition   = toProductData.layoutDefinition;
        const fromCpuSlotNumbers   = this.getCpuSlotNumbersForLayoutDefinition(fromLayoutDefinition);
        const toCpuSlotNumbers     = this.getCpuSlotNumbersForLayoutDefinition(toLayoutDefinition);

        // Reset all slots to a "virtual base" without any unused slots
        if (
            fromLayoutDefinition &&
            toLayoutDefinition
        ) {
            const changedUnusedPorts = _.xor(toLayoutDefinition.unused, fromLayoutDefinition.unused);

            if (
                changedUnusedPorts.length > 0 &&
                toLayoutDefinition.unused &&
                fromLayoutDefinition.unused &&
                fromLayoutDefinition.unused.length > 0
            ) {
                const fromUnusedPortIndexesAsc      = _.sortBy(fromLayoutDefinition.unused);
                const moveIOCardsAfterSlotToTheLeft = function(beforeSlotIndexInternal) {
                    for (
                        let currentSlotIndex = beforeSlotIndexInternal;
                        currentSlotIndex <= targetProductData.length - 1;
                        ++currentSlotIndex
                    ) {
                        const currentSlot = targetProductData[currentSlotIndex];

                        if (
                            !currentSlot ||
                            !currentSlot.productData
                        ) {
                            let nextSlotIndex = currentSlotIndex + 1;
                            let foundNextSlot = false;

                            do {
                                const nextSlot = targetProductData[nextSlotIndex];

                                if (
                                    nextSlot &&
                                    (
                                        (
                                            nextSlot.productData &&
                                            nextSlot.productData.productSlotType === ProductSlotType.cpuBoard
                                        )
                                        ||
                                        fromUnusedPortIndexesAsc.indexOf(nextSlotIndex + 1) > -1 ||
                                        toCpuSlotNumbers.indexOf(currentSlotIndex + 1) > -1
                                    )
                                ) {
                                    ++nextSlotIndex;
                                } else {
                                    foundNextSlot = true;
                                }
                            } while (!foundNextSlot && nextSlotIndex < targetProductData.length - 1);

                            if (currentSlotIndex < targetProductData.length - 1) {
                                // We move filled and matching slots to the right
                                targetProductData[currentSlotIndex] = _.cloneDeep(targetProductData[nextSlotIndex]);
                                targetProductData[nextSlotIndex]    = ActiveProjectsFactory.getEmptyProductSlot();
                            }
                        }
                    }
                };

                for (const fromUnusedPortIndex of fromUnusedPortIndexesAsc) {
                    const fromUnusedPortIndexInternal = Cast.int(fromUnusedPortIndex) - 1;

                    moveIOCardsAfterSlotToTheLeft(fromUnusedPortIndexInternal);
                }
            }
        }

        return targetProductData;
    };

    static getTransferredSlotsStep4SkipBlindPlates = (targetProductData, fromProductData, toProductData) => {
        const fromLayoutDefinition = fromProductData.layoutDefinition;
        const toLayoutDefinition   = toProductData.layoutDefinition;
        const fromCpuSlotNumbers   = this.getCpuSlotNumbersForLayoutDefinition(fromLayoutDefinition);
        const toCpuSlotNumbers     = this.getCpuSlotNumbersForLayoutDefinition(toLayoutDefinition);

        // Skip blind plates
        if (
            fromLayoutDefinition &&
            toLayoutDefinition
        ) {
            const changedUnusedPorts = _.xor(toLayoutDefinition.unused, fromLayoutDefinition.unused);

            if (
                toLayoutDefinition.unused &&
                toLayoutDefinition.unused.length > 0 &&
                changedUnusedPorts.length > 0
            ) {
                const toUnusedPortIndexesDesc        = _.sortBy(toLayoutDefinition.unused).reverse();
                const moveIOCardsAfterSlotToTheRight = function(afterSlotIndexInternal) {
                    for (
                        let currentSlotIndex = targetProductData.length - 1;
                        currentSlotIndex >= afterSlotIndexInternal;
                        --currentSlotIndex
                    ) {
                        let nextSlotIndex = currentSlotIndex + 1;
                        const currentSlot = targetProductData[currentSlotIndex];

                        if (
                            currentSlot &&
                            currentSlot.productData &&
                            currentSlot.productData.productSlotType === ProductSlotType.ioBoard
                        ) {
                            let foundNextSlot = false;

                            do {
                                const nextSlot = targetProductData[nextSlotIndex];

                                if (
                                    nextSlot &&
                                    (
                                        (
                                            nextSlot.productData &&
                                            nextSlot.productData.productSlotType === ProductSlotType.cpuBoard
                                        )
                                        ||
                                        toUnusedPortIndexesDesc.indexOf(nextSlotIndex + 1) > -1
                                    )
                                ) {
                                    ++nextSlotIndex;
                                } else {
                                    foundNextSlot = true;
                                }
                            } while (!foundNextSlot);

                            // We move filled and matching slots to the right
                            targetProductData[nextSlotIndex]    = _.cloneDeep(targetProductData[currentSlotIndex]);
                            targetProductData[currentSlotIndex] = ActiveProjectsFactory.getEmptyProductSlot();
                        }
                    }
                };

                for (const toUnusedPortIndex of toUnusedPortIndexesDesc) {
                    const toUnusedPortIndexInternal = Cast.int(toUnusedPortIndex) - 1;

                    moveIOCardsAfterSlotToTheRight(toUnusedPortIndexInternal);
                }
            }
        }

        return targetProductData;
    };

    static getTransferredPsuSlots = (targetProductData, psuCount, psuDefinition, transferFrom) => {
        let fixedPsuList       = [...transferFrom];
        const matchingProducts = Limitations.filterProductListByLimitTo(
            DataProvider.getMatrixEnterpriseAccessories(),
            targetProductData.partNo,
        );
        const matchingPsu      = _.find(matchingProducts, {
            type: AccessoryTypes.powerSupplyUnit,
        });

        // Create missing psu slots
        while (fixedPsuList.length < psuCount) {
            fixedPsuList.push(this.getEmptyProductSlot());
        }

        for (const fixedPsuIndex in fixedPsuList) {
            const fixedPsuIndexInt = Cast.int(fixedPsuIndex);
            const fixedPsu         = fixedPsuList[fixedPsuIndex];
            const newPsuDefinition = _.find(_.get(psuDefinition, 'psu', {}), {
                slot: fixedPsuIndexInt + 1,
            });

            if (fixedPsu.productData) {
                // Try to take the linked product in the psu definition first
                if (newPsuDefinition) {
                    fixedPsu.productData = DataProvider.getById(newPsuDefinition.partNo);
                }
                // Otherwise fill all free slots with the first matching power supply unit
                else if (matchingPsu) {
                    fixedPsu.productData = matchingPsu;
                }
            } else if (newPsuDefinition && newPsuDefinition.fix) {
                fixedPsu.productData = DataProvider.getById(newPsuDefinition.partNo);
            }
        }

        // Drop unnecessary psu slots (when downgrading from a bigger chassis to a smaller one with less psus)
        fixedPsuList = fixedPsuList.slice(0, psuCount);

        return fixedPsuList;
    };

    static reverseProduct = (product) => {
        const reversedProduct          = _.cloneDeep(product);
        reversedProduct.id             = IdGenerator.getNewProductId();
        reversedProduct.customName     = (
            `${reversedProduct.customName
            } ${
                I18n.t('reversedProductPostfix')}`
        ).trim();
        reversedProduct.reversedFromId = product.id;
        const subProductKeys           = Object.keys(reversedProduct.subProducts);

        for (const subProductKey of subProductKeys) {
            const products = reversedProduct.subProducts[subProductKey];

            for (const productKey in products) {
                const product     = products[productKey];
                const productData = product.productData;

                if (productData) {
                    // This will return the reversed product if possible or the same product
                    // if the product is not local or remote.
                    // It only returns null when the reversed product does not exist.
                    const reversedProductData = DataProvider.getReversedById(productData.id);

                    if (reversedProductData) {
                        products[productKey].productData = reversedProductData;
                    } else {
                        products[productKey] = ActiveProjectsFactory.getEmptyProductSlot();
                    }
                }
            }
        }

        return reversedProduct;
    };
}

export default ActiveProjectsFactory;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import DataProvider from '@helper/DataProvider';

import GapCalculator from './GapCalculator';
import SlotAlignment from '../../SelectedProductSlotImageRenderer/SlotAlignment';
import ChassisLayout from '../ChassisLayout';

/**
 *  @see This file also exists in the backend src/BoundsCalculators/MatrixPowerSupplyLeftTopToRightBottom.php
 */
class MatrixPowerSupplyLeftTopToRightBottom {
    static getBoundsChassisLayoutHorizontalX = (bounds, psuDefinition) => {
        let x = 0;

        // @formatter:off
        x += psuDefinition.start.x;                                         // Start position
        x += (bounds.column - 1) * bounds.width;                            //
        x += GapCalculator.getGap(psuDefinition.gap.column, bounds.column); //
        // @formatter:on

        return x;
    };

    static getBoundsChassisLayoutVerticalX = (bounds, psuDefinition) => {
        const x = 0;

        // @formatter:off
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-412
        // @formatter:on

        return x;
    };

    static getBoundsChassisLayoutHorizontalY = (bounds, psuDefinition) => {
        let y = 0;

        // @formatter:off
        y += psuDefinition.start.y;                                   // Start position
        y += GapCalculator.getGap(psuDefinition.gap.row, bounds.row); //
        y += (bounds.row - 1) * bounds.height;                        //
        // @formatter:on

        return y;
    };

    static getBoundsChassisLayoutVerticalY = (bounds, psuDefinition) => {
        const y = 0;

        // @formatter:off
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-412
        // @formatter:on

        return y;
    };

    static getCurrentColumnChassisLayoutHorizontal = (psuIndexStartingBy1, psuDefinition) => {
        return psuIndexStartingBy1;
    };

    static getCurrentColumnChassisLayoutVertical = (psuIndexStartingBy1, psuDefinition) => {
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-412
    };

    static getCurrentRowChassisLayoutHorizontal = (psuIndexStartingBy1, psuDefinition) => {
        return 1;
    };

    static getCurrentRowChassisLayoutVertical = (psuIndexStartingBy1, psuDefinition) => {
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-412
    };

    static getBounds = (psuIndexStartingBy1, psuDefinition, productData) => {
        const bounds = this.getDefaultBounds(psuDefinition);

        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-412

        this.updateBoundsChassisLayoutHorizontal(
            psuIndexStartingBy1,
            psuDefinition,
            bounds,
        );

        return bounds;
    };

    static getDefaultBounds = (psuDefinition) => {
        const usedPowerSupplyUnit            = psuDefinition.psu[0];
        const usedPowerSupplyUnitProductData = DataProvider.getById(usedPowerSupplyUnit.partNo);
        const usedPowerSupplyUnitFirstImage  = usedPowerSupplyUnitProductData.images[0];

        return {
            alignTo:       SlotAlignment.top,
            chassisLayout: ChassisLayout.horizontal,
            height:        usedPowerSupplyUnitFirstImage.height,
            x:             0,
            y:             0,
            width:         usedPowerSupplyUnitFirstImage.width,
        };
    };

    static updateBoundsChassisLayoutHorizontal = (psuIndexStartingBy1, psuDefinition, bounds) => {
        bounds.column = this.getCurrentColumnChassisLayoutHorizontal(psuIndexStartingBy1, psuDefinition);
        bounds.row    = this.getCurrentRowChassisLayoutHorizontal(psuIndexStartingBy1, psuDefinition);
        bounds.x      = this.getBoundsChassisLayoutHorizontalX(bounds, psuDefinition);
        bounds.y      = this.getBoundsChassisLayoutHorizontalY(bounds, psuDefinition);
    };

    static updateBoundsChassisLayoutVertical = (psuIndexStartingBy1, psuDefinition, bounds) => {
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-412
    };
}

export default MatrixPowerSupplyLeftTopToRightBottom;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames          from 'classnames';
import { withTranslation } from 'react-i18next';

import { getTitleBannerLeftText }  from '@helper/Configuration';
import { getTitleBannerRightText } from '@helper/Configuration';
import { getTitleBannerSlogan }    from '@helper/Configuration';
import { isBigLogoImageEnabled }   from '@helper/Configuration';
import SelectionHelper             from '@helper/SelectionHelper';

import TitleBannerMode from './TitleBannerMode';
import styles          from './styles.module.scss';
import PropTypes       from '../PropTypes';

const Component = ({
    mode = TitleBannerMode.app,
    text = '',
}) => {
    const renderAppTitle  = () => {
        const strongText       = getTitleBannerLeftText();
        const regularText      = getTitleBannerRightText();
        const slogan           = getTitleBannerSlogan();
        const showBigLogoImage = isBigLogoImageEnabled();

        return (
            <h2
                className={classNames(
                    styles.titleBannerText,
                    {
                        [styles.titleBannerTextImage]: showBigLogoImage,
                    },
                )}
            >
                <span>
                    <strong>
                        {strongText}
                    </strong>
                    {regularText}
                </span>
                <p>
                    {slogan}
                </p>
            </h2>
        );
    };
    const renderTextTitle = () => {
        return (
            <h2 className={styles.titleBannerText}>
                <strong>
                    {text}
                </strong>
            </h2>
        );
    };

    return (
        <div
            className={classNames(
                styles.titleBanner,
                SelectionHelper.get(
                    mode,
                    {
                        [TitleBannerMode.app]:  styles.titleBannerModeApp,
                        [TitleBannerMode.text]: styles.titleBannerModeText,
                    },
                ),
            )}
        >
            <div
                className={styles.titleBannerInner}
            >
                {
                    mode === TitleBannerMode.app ?
                        renderAppTitle() :
                        renderTextTitle()
                }
            </div>
        </div>
    );
};

Component.propTypes = {
    mode: PropTypes.oneOf(Object.values(TitleBannerMode)),
    text: PropTypes.string,
};

Component.defaultProps = {
    mode: TitleBannerMode.app,
    text: '',
};

export default withTranslation()(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import classNames  from 'classnames';
import _           from 'lodash';
import { connect } from 'react-redux';

import ProductCategoryType from '@constants/ProductCategoryType';
import SlotType            from '@constants/SlotType';
import WarningType         from '@constants/WarningType';
import SelectionHelper     from '@helper/SelectionHelper';
import StateHelper         from '@helper/State';

import styles          from './styles.module.scss';
import IconType        from '../Icon/IconType';
import PropTypes       from '../PropTypes';
import ChassisLayout   from '../SelectedProductImageRenderer/ChassisLayout';
import Tooltip         from '../Tooltip';
import TooltipPosition from '../Tooltip/TooltipPosition';
import TooltipTheme    from '../Tooltip/TooltipTheme';

class Component extends React.Component {
    getClassForCategoryType = () => {
        return SelectionHelper.get(
            this.props.categoryType,
            {
                [ProductCategoryType.extender]: styles.selectedProductSlotImageNumberingExtender,
                [ProductCategoryType.matrix]:   this.getClassForCategoryTypeCategoryTypeMatrix(),
            },
        );
    };

    getClassForCategoryTypeCategoryTypeMatrix = () => {
        return SelectionHelper.get(
            this.props.slotType,
            {
                [SlotType.powerSupplyUnit]: classNames(
                    styles.selectedProductSlotImageNumberingMatrix,
                    styles.selectedProductSlotImageNumberingMatrixPowerSupplyUnit,
                ),
                [SlotType.slot]:            classNames(
                    styles.selectedProductSlotImageNumberingMatrix,
                    (
                        this.props.isCpu ?
                            styles.selectedProductSlotImageNumberingMatrixCpu :
                            null
                    ),
                ),
            },
        );
    };

    getClassForChassisLayout = () => {
        return SelectionHelper.get(
            this.props.chassisLayout,
            {
                [ChassisLayout.horizontal]: styles.selectedProductSlotImageNumberingHorizontal,
                [ChassisLayout.vertical]:   styles.selectedProductSlotImageNumberingVertical,
            },
        );
    };

    getClassForEvenOdd = () => {
        return (
            this.props.row % 2 === 1 ?
                styles.selectedProductSlotImageNumberingRowOdd :
                styles.selectedProductSlotImageNumberingRowEven
        );
    };

    getClassName = () => {
        const classForCategoryType  = this.getClassForCategoryType();
        const classForChassisLayout = this.getClassForChassisLayout();
        const classForEvenOdd       = this.getClassForEvenOdd();
        const classNameString       = classNames(
            styles.selectedProductSlotImageNumbering,
            classForChassisLayout,
            classForCategoryType,
            classForEvenOdd,
        );

        return classNameString;
    };

    getIconType = (warning) => {
        if (warning.iconType) {
            return warning.iconType;
        }

        return SelectionHelper.get(
            warning.type,
            {
                [WarningType.error]:       IconType.error,
                [WarningType.information]: IconType.help,
            },
        );
    };

    getIndex = () => {
        if (this.props.categoryType === ProductCategoryType.extender) {
            return this.props.index + 1;
        }

        return this.props.index;
    };

    getStyle = () => {
        return SelectionHelper.get(
            this.props.chassisLayout,
            {
                [ChassisLayout.horizontal]: this.getStyleChassisLayoutHorizontal(),
                [ChassisLayout.vertical]:   this.getStyleChassisLayoutVertical(),
            },
        );
    };

    getStyleChassisLayoutHorizontal = () => {
        return SelectionHelper.get(
            this.props.categoryType,
            {
                [ProductCategoryType.extender]: this.getStyleChassisLayoutHorizontalCategoryTypeExtender(),
                [ProductCategoryType.matrix]:   this.getStyleChassisLayoutHorizontalCategoryTypeMatrix(),
            },
        );
    };

    getStyleChassisLayoutHorizontalCategoryTypeExtender = () => {
        return {};
    };

    getStyleChassisLayoutHorizontalCategoryTypeMatrix = () => {
        return SelectionHelper.get(
            this.props.slotType,
            {
                [SlotType.powerSupplyUnit]: this.getStyleChassisLayoutHorizontalCategoryTypeMatrixPowerSupplyUnit(),
                [SlotType.slot]:            this.getStyleChassisLayoutHorizontalCategoryTypeMatrixSlot(),
            },
        );
    };

    getStyleChassisLayoutHorizontalCategoryTypeMatrixPowerSupplyUnit = () => {
        return {
            bottom:     -38,
            left:       '50%',
            marginLeft: -20,
        };
    };

    getStyleChassisLayoutHorizontalCategoryTypeMatrixSlot = () => {
        const selectedProductIsUniChassis = _.get(this.props.selectedProduct, 'productData.uniDefinition', false);

        if (selectedProductIsUniChassis) {
            return {
                top:        50 * this.props.scale,
                left:       '50%',
                marginLeft: -20,
            };
        }

        // We got a default chassis
        const top = (
            4
            *
            (
                this.props.scale * 1.6
            )
        );

        return (
            this.props.column % 2 === 0 ?
                {
                    right: -24,
                    top,
                }
                : {
                    left: -24,
                    top,
                }
        );
    };

    getStyleChassisLayoutVertical = () => {
        return SelectionHelper.get(
            this.props.categoryType,
            {
                [ProductCategoryType.extender]: this.getStyleChassisLayoutVerticalExtender(),
                [ProductCategoryType.matrix]:   this.getStyleChassisLayoutVerticalMatrix(),
            },
        );
    };

    getStyleChassisLayoutVerticalExtender = () => {
        return {
            left: (
                100
                      +
                      (
                          5 * this.props.scale
                      )
            ) * -1,
        };
    };

    getStyleChassisLayoutVerticalMatrix = () => {
        return (
            this.props.row % 2 === 1 ?
                {
                    right: (
                        (
                            90
                                   +
                                   (
                                       5 * this.props.scale
                                   )
                        )
                               *
                               -1
                    ),
                }
                : {
                    left: -26,
                }
        );
    };

    getWarningStyleChassisLayoutHorizontal = () => {
        return SelectionHelper.get(
            this.props.categoryType,
            {
                [ProductCategoryType.extender]: this.getWarningStyleChassisLayoutHorizontalCategoryTypeExtender(),
                [ProductCategoryType.matrix]:   this.getWarningStyleChassisLayoutHorizontalCategoryTypeMatrix(),
            },
        );
    };

    getWarningStyleChassisLayoutHorizontalCategoryTypeExtender = () => {
        return {};
    };

    getWarningStyleChassisLayoutHorizontalCategoryTypeMatrix = () => {
        return (
            this.props.column % 2 === 1 ?
                {
                    right: 24,
                }
                : {
                    left: 20,
                }
        );
    };

    getWarningStyleChassisLayoutVerticalExtender = () => {
        return {
            left: (
                10 + (
                    30 * this.props.scale
                )
            ) * -1,
        };
    };

    getWarningStyleChassisLayoutVerticalMatrix = () => {
        return (
            this.props.row % 2 === 1 ?
                {
                    right: 24,
                }
                : {
                    left: 100,
                }
        );
    };

    getWarningStyleChassisLayoutVertical = () => {
        return SelectionHelper.get(
            this.props.categoryType,
            {
                [ProductCategoryType.extender]: this.getWarningStyleChassisLayoutVerticalExtender(),
                [ProductCategoryType.matrix]:   this.getWarningStyleChassisLayoutVerticalMatrix(),
            },
        );
    };

    getWarningStyle = () => {
        return SelectionHelper.get(
            this.props.chassisLayout,
            {
                [ChassisLayout.horizontal]: this.getWarningStyleChassisLayoutHorizontal(),
                [ChassisLayout.vertical]:   this.getWarningStyleChassisLayoutVertical(),
            },
        );
    };

    getWarningTooltipTheme = (warning) => {
        return SelectionHelper.get(
            warning.type,
            {
                [WarningType.error]:       TooltipTheme.orange,
                [WarningType.information]: TooltipTheme.gray,
            },
        );
    };

    hasWarnings = () => {
        return this.props.warnings && this.props.warnings.length > 0;
    };

    render() {
        const className = this.getClassName();
        const style     = this.getStyle();

        return (
            <div
                className={className}
                style={style}
            >
                <div className={styles.selectedProductSlotImageNumberingContent}>
                    <span className={styles.selectedProductSlotImageNumberingText}>
                        {this.getIndex()}
                    </span>
                    {this.renderWarnings()}
                </div>
            </div>
        );
    }

    renderWarnings = () => {
        if (this.hasWarnings()) {
            const items        = [];
            const warningStyle = this.getWarningStyle();

            for (const warningIndex in this.props.warnings) {
                const warning = this.props.warnings[warningIndex];

                items.push(<div
                    className={styles.selectedProductSlotImageNumberingWarningItem}
                    key={`warning${warningIndex}`}
                >
                    <div className={styles.selectedProductSlotImageNumberingWarningItemIconWrapper}>
                        {this.renderWarningsTooltip(warning)}
                    </div>
                </div>);
            }

            return (
                <div
                    className={styles.selectedProductSlotImageNumberingWarningWrapper}
                    style={warningStyle}
                >
                    {items}
                </div>
            );
        }

        return null;
    };

    renderWarningsTooltip = (warning) => {
        const iconType = this.getIconType(warning);
        const theme    = this.getWarningTooltipTheme(warning);

        return (
            <Tooltip
                iconType={iconType}
                text={warning.text}
                theme={theme}
                position={
                    this.props.row % 2 === 1 ?
                        TooltipPosition.top :
                        TooltipPosition.bottom
                }
            />
        );
    };
}

Component.propTypes = {
    categoryType:  PropTypes.oneOf(Object.values(ProductCategoryType)),
    chassisLayout: PropTypes.oneOf(Object.values(ChassisLayout)),
    column:        PropTypes.number,
    index:         PropTypes.number,
    isCpu:         PropTypes.bool,
    row:           PropTypes.number,
    scale:         PropTypes.number,
    slotType:      PropTypes.oneOf(Object.values(SlotType)),
    warnings:      PropTypes.array,
};

Component.defaultProps = {
    categoryType:  null,
    chassisLayout: null,
    column:        0,
    index:         0,
    isCpu:         false,
    row:           0,
    scale:         1,
    slotType:      null,
    warnings:      [],
};

const mapStateToProps = (state) => (
    {
        selectedProduct: StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
    }
);

export default connect(
    mapStateToProps,
    null,
)(Component);

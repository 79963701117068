//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import styles    from './styles.module.scss';
import PropTypes from '../PropTypes';

const Component = ({
    children,
    height   = -1,
    testId   = '',
}) => {
    const getStyle = () => {
        const style = {};

        if (height > -1) {
            style.height = height;
        }

        return style;
    };
    const style    = getStyle();

    return (
        <div
            data-testId={testId}
            className={styles.splitPanePanel}
            style={style}
        >
            {children}
        </div>
    );
};

Component.propTypes = {
    children: PropTypes.children,
    height:   PropTypes.number,
    testId:   PropTypes.string,
};

Component.defaultProps = {
    children: null,
    height:   -1,
    testId:   '',
};

export default Component;

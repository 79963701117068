//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                from 'i18next';
import { withTranslation } from 'react-i18next';

import styles    from './styles.module.scss';
import PropTypes from '../PropTypes';

function renderActions(children) {
    return (
        <ul
            className={styles.actionGroupList}
        >
            {children}
        </ul>
    );
}

const Component = ({ children }) => {
    return (
        <div
            className={styles.actionGroup}
        >
            <h3 className={styles.actionGroupTitle}>
                {I18n.t('actions')}
            </h3>
            {renderActions(children)}
        </div>
    );
};

Component.propTypes = {
    children: PropTypes.children,
};

Component.defaultProps = {
    children: [],
};

export default withTranslation()(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames             from 'classnames';
import I18n                   from 'i18next';
import _                      from 'lodash';
import { DragSource }         from 'react-dnd';
import { DropTarget }         from 'react-dnd';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { compose }            from 'redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions }  from '@slices/activeProject';
import { DragDropActions }       from '@slices/dragDrop';
import AccessoryTypes            from '@constants/AccessoryTypes';
import DragTypes                 from '@constants/DragTypes';
import DropValidation            from '@constants/DropValidation';
import SlotType                  from '@constants/SlotType';
import TestIds                   from '@constants/TestIds';
import DataProvider              from '@helper/DataProvider';
import StateHelper               from '@helper/State';
import { DesignerLayoutActions } from '@slices/designerLayout';

import styles                from './styles.module.scss';
import dragDropConfiguration from '../../dragDrop/sfp';
import Icon                  from '../Icon';
import IconType              from '../Icon/IconType';
import ProductQuickSelect    from '../ProductQuickSelect';
import PropTypes             from '../PropTypes';
import slotSfp               from '../SlotSfp';
import TextDropZone          from '../TextDropZone';
import CornerStyle           from '../TextDropZone/CornerStyle';

class Component extends React.Component {
    deleteButtonPressed = (event) => {
        this.props.deleteSfp({
            sfpIndex: this.props.index,
        });

        // Stop the button to select the slot (since this would cancel the delete operation)
        event.stopPropagation();
    };

    getProductQuickSelectProducts = () => {
        const products         = DataProvider.getMatrixProducts();
        const filteredProducts = products.filter((product) => {
            return (
                product.type === AccessoryTypes.sfp &&
                product.subCategoryType === this.props.selectedProduct.productData.subCategoryType &&
                !product.isEndOfLife
            );
        });

        return filteredProducts;
    };

    getSlotNumber = () => {
        return this.props.index + 1;
    };

    hasProduct = () => {
        return this.props.data && this.props.data.productData;
    };

    productQuickSelectChanged = (option) => {
        this.props.setSfp({
            productId: option.value,
            sfpIndex:  this.props.index,
        });
    };

    render() {
        const baseObject = this.props.connectDropTarget(<div
            data-testId={TestIds.slotSfpListSlot}
            className={classNames(
                styles.slotSfpListSlot,
                (
                    this.hasProduct() ?
                        styles.slotSfpListSlotWithItem :
                        null
                ),
                (
                    this.props.index % 2 === 0 ?
                        styles.slotSfpListSlotOdd :
                        styles.slotSfpListSlotEven
                ),
            )}
        >
            {this.renderDeleteButton()}
            <div
                className={styles.slotSfpListSlotWrapper}
            >
                {this.renderDropZone()}
                <div
                    className={styles.slotSfpListSlotContent}
                >
                    <div className={styles.slotSfpListSlotNumber}>
                        {this.getSlotNumber()}
                    </div>
                    {this.renderTitle()}
                </div>
            </div>
        </div>);

        if (this.hasProduct()) {
            return this.props.connectDragSource(
                baseObject,
                {},
            );
        }

        return baseObject;
    }

    renderDeleteButton = () => {
        if (this.hasProduct()) {
            return (
                <span
                    className={styles.productSlotDeleteButton}
                    onClick={this.deleteButtonPressed}
                >
                    <Icon iconType={IconType.trashBin} />
                </span>
            );
        }

        return null;
    };

    renderDropZone = () => {
        const dropValidation = (
            // Since sfps can always get dropped we need no complex logic here
            this.props.canDrop ?
                DropValidation.allowed :
                DropValidation.forbidden
        );

        return (
            <TextDropZone
                canDrop={this.props.canDrop}
                cornerStyle={CornerStyle.square}
                dropValidation={dropValidation}
                isOver={this.props.isOver}
            />
        );
    };

    renderTitle = () => {
        const hasProduct = this.hasProduct();

        return (
            <div
                className={classNames(
                    styles.slotSfpListSlotTitleWrapper,
                    {
                        [styles.slotSfpListSlotTitleWrapperHasTitle]: hasProduct,
                    },
                )}
            >
                {
                    hasProduct ?
                        [
                            <span
                                className={classNames(
                                    styles.slotSfpListSlotTitle,
                                    (
                                        this.props.data.customName ?
                                            styles.slotSfpListSlotTitleWithCustomText :
                                            null
                                    ),
                                )}
                                key={'name'}
                            >
                                {
                                    this.props.data.customName ?
                                        this.props.data.customName :
                                        this.props.data.productData[I18n.t('descriptionKey')]
                                }
                            </span>,
                            <span
                                className={styles.slotSfpListSlotTitleSub}
                                key={'displayPartNumber'}
                            >
                                {this.props.data.productData.displayPartNumber}
                            </span>,
                        ]
                        :
                        this.renderTitleEmpty()
                }
            </div>
        );
    };

    renderTitleEmpty = () => {
        return (
            <span className={styles.slotSfpListSlotTitleEmpty}>
                <ProductQuickSelect
                    onChange={this.productQuickSelectChanged}
                    getProducts={this.getProductQuickSelectProducts}
                />
            </span>
        );
    };
}

Component.propTypes = {
    accessoryType: PropTypes.oneOf(Object.values(AccessoryTypes)),
    data:          PropTypes.object,
    hasProduct:    PropTypes.func,
    index:         PropTypes.number,
    isSelected:    PropTypes.func,
    totalCount:    PropTypes.number,
    type:          PropTypes.oneOf(Object.values(SlotType)),
};

Component.defaultProps = {
    accessoryType: null,
    data:          {},
    hasProduct:    _.noop,
    index:         -1,
    isSelected:    _.noop,
    totalCount:    0,
    type:          SlotType.slot,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(
    ActiveProjectActions,
    DesignerLayoutActions,
    DragDropActions,
), dispatch);

const mapStateToProps = (state) => (
    {
        selectedProduct:     StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
        selectedProductMeta: state.activeProject.selectedProduct,
        selectedSlot:        StateHelper.getSelectedSlot(state),
        selectedSlotMeta:    state.activeProject.selectedSlot,
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    DropTarget(
        [
            DragTypes.add,
            DragTypes.sort,
        ],
        dragDropConfiguration.dropBehavior,
        dragDropConfiguration.dropCollect,
    ),
    DragSource(
        DragTypes.sort,
        dragDropConfiguration.dragBehavior,
        dragDropConfiguration.dragCollect,
    ),
)(slotSfp(withTranslation()(Component)));

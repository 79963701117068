//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import { ActiveProjectActions } from '@slices/activeProject';
import Overlays                 from '@constants/Overlays';

const processActions = [
    ActiveProjectActions.deleteProduct().type,
    ActiveProjectActions.deleteSfp().type,
    ActiveProjectActions.deleteSlot().type,
    ActiveProjectActions.deleteSingleOrderSlot().type,
    ActiveProjectActions.duplicateSelectedProduct().type,
];

const designerLayoutFilter = (store) => (next) => (action) => {
    if (processActions.indexOf(action.type) > -1) {
        const state          = store.getState();
        const hiddenOverlays = state.overlays.hiddenOverlays;
        let newAction        = _.cloneDeep(action);

        switch (action.type) {
            case ActiveProjectActions.deleteProduct().type:
                if (hiddenOverlays[Overlays.deleteProduct]) {
                    newAction.type = ActiveProjectActions.deleteProductConfirm().type;
                }

                break;

            case ActiveProjectActions.deleteSfp().type:
                if (hiddenOverlays[Overlays.deleteSfp]) {
                    newAction.type = ActiveProjectActions.deleteSfpConfirm().type;
                }

                break;

            case ActiveProjectActions.deleteSlot().type:
                if (hiddenOverlays[Overlays.deleteSlot]) {
                    newAction.type = ActiveProjectActions.deleteSlotConfirm().type;
                }

                break;

            case ActiveProjectActions.deleteSingleOrderSlot().type:
                const { categoryType, index } = action.payload;
                const hasProductData          = state.activeProject.singleOrders[categoryType][index].productData;

                if (!hasProductData || hiddenOverlays[Overlays.deleteSingleOrderSlot]) {
                    newAction.type = ActiveProjectActions.deleteSingleOrderSlotConfirm().type;
                }

                break;

            case ActiveProjectActions.duplicateSelectedProduct().type:
                const { confirmed, amount } = action.payload;

                if (confirmed || hiddenOverlays[Overlays.duplicateProduct]) {
                    newAction.type = ActiveProjectActions.duplicateSelectedProductConfirm().type;

                    if (amount > 0) {
                        newAction.amount = amount;
                    } else {
                        newAction.amount = 1;
                    }
                }

                break;

            default:
                newAction = null;

                break;
        }

        if (newAction) {
            next(newAction);

            return;
        }
    }

    next(action);
};

export default designerLayoutFilter;

import React from 'react';

import TestIds from '@constants/TestIds';

import styles from './styles.module.scss';
import PropTypes from '../PropTypes';

const Component = ({ children }) => (
    <div
        data-testId={TestIds.tableRow}
        className={styles.tableRow}
    >
        {children}
        <div
            className={styles.tableRowBorder}
            aria-hidden={true}
        />
    </div>
);

Component.propTypes = {
    children: PropTypes.children,
};

Component.defaultProps = {
    children: [],
};

export default Component;

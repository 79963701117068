//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { Component } from 'react';
import React         from 'react';

import _ from 'lodash';

import DataTransferTypes      from '@constants/DataTransferTypes';
import DeviceTypeShortcuts    from '@constants/DeviceTypeShortcuts';
import ProductSlotType        from '@constants/ProductSlotType';
import ProductSubCategoryType from '@constants/ProductSubCategoryType';
import YesNoOptional          from '@constants/YesNoOptional';
import Cast                   from '@helper/Cast';

export default function withPortCounts(WrappedComponent, calculateDetails) {
    return class extends Component {
        state = {
            ports: null,
        };

        componentDidMount() {
            console.log('WithPortCounts: componentDidMount');

            this.setState({
                ports: this.recalculatePorts(),
            });
        }

        componentWillReceiveProps(nextProps, nextContext) {
            console.log(
                'WithPortCounts: componentWillReceiveProps: this.props, nextProps',
                this.props,
                nextProps,
            );

            if (nextProps.products !== this.props.products) {
                this.setState({
                    ports: this.recalculatePorts(nextProps),
                });
            }
        }

        hasPorts = () => {
            return (
                this.state.ports &&
                (
                    this.state.ports.extenderCpu +
                    this.state.ports.extenderCon +
                    this.state.ports.extenderMisc +
                    this.state.ports.matrix
                )
                > 0
            );
        };

        recalculatePorts = (props) => {
            if (!props) {
                props = this.props;
            }

            console.log('WithPortCounts: recalculatePorts: props.products', props.products);

            const ports             = {
                details:      {},
                extenderCon:  0,
                extenderCpu:  0,
                extenderMisc: 0,
                matrix:       0,
            };
            const addPortsToContext = (context, dataTransferList, portsToAdd) => {
                if (calculateDetails && dataTransferList) {
                    if (!portsToAdd) {
                        portsToAdd = 1;
                    }

                    if (!_.isArray(dataTransferList)) {
                        dataTransferList = [dataTransferList];
                    }

                    for (const dataTransfer of dataTransferList) {
                        if (ports.details && ports.details[context]) {
                            ports.details[context][dataTransfer] += portsToAdd;
                        }
                    }
                }
            };

            if (props.products) {
                const dataTransferTypes = Object.values(DataTransferTypes);

                if (calculateDetails) {
                    ports.details = {
                        extender: {},
                        matrix:   {},
                    };

                    for (const dataTransferType of dataTransferTypes) {
                        ports.details.extender[dataTransferType] = 0;
                        ports.details.matrix[dataTransferType]   = 0;
                    }
                }

                for (const extender of props.products.extender) {
                    if (extender) {
                        const productData = extender.productData;

                        // Non-vario chassis don't have a slot count so they
                        // cant have any slots and we have to count them manually.
                        //
                        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-59
                        if (productData.slotCount === 0) {
                            const { deviceType } = productData;
                            const isKit          = deviceType === DeviceTypeShortcuts.kit;
                            const isCpu          = deviceType === DeviceTypeShortcuts.cpuUnit;
                            const redundant      = _.get(productData, 'redundant', YesNoOptional.no) === YesNoOptional.yes;
                            const target         = isCpu
                                ? 'extenderCpu'
                                : 'extenderCon';
                            let portsToAdd       = 0;

                            if (productData.dataTransfer) {
                                portsToAdd = productData.dataTransfer.length;
                            } else {
                                portsToAdd = 1;
                            }

                            if (isKit) {
                                portsToAdd *= 2;
                            }

                            if (redundant) {
                                portsToAdd *= 2;
                            }

                            ports[target] += portsToAdd;

                            addPortsToContext('extender', productData.dataTransfer, portsToAdd);
                        } else {
                            const slots = extender.subProducts.slot;

                            for (const slot of slots) {
                                const { productData } = slot;
                                const redundant       = _.get(slot, 'productData.redundant', YesNoOptional.no) === YesNoOptional.yes;

                                if (
                                    productData &&
                                    // "Add on modules" have be skipped since they do not represent any port
                                    // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-800
                                    productData.productSlotType !== ProductSlotType.addOnModule
                                ) {
                                    const { deviceType } = productData;
                                    const isCpu          = deviceType === DeviceTypeShortcuts.cpuUnit;
                                    const isCon          = deviceType === DeviceTypeShortcuts.conUnit;
                                    const isOtherModule  = productData.productSlotType === ProductSlotType.otherModule;

                                    // "Other modules" have to be skipped for the extender context
                                    // @see @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-740
                                    if (
                                        !isOtherModule && (
                                            isCpu ||
                                            isCon
                                        )
                                    ) {
                                        const target     = isCpu
                                            ? 'extenderCpu'
                                            : 'extenderCon';
                                        const portsToAdd = (
                                            redundant
                                                ? 2
                                                : (
                                                    productData.dataTransfer ?
                                                        productData.dataTransfer.length :
                                                        1
                                                )
                                        );

                                        ports[target] += portsToAdd;

                                        addPortsToContext('extender', productData.dataTransfer, portsToAdd);
                                    }

                                    if (
                                        isOtherModule &&
                                        productData.portCount
                                    ) {
                                        const otherModulePortCount = Cast.int(productData.portCount);
                                        const portsToAdd           = otherModulePortCount;

                                        ports.matrix += portsToAdd;

                                        addPortsToContext('matrix', productData.dataTransfer, portsToAdd);
                                    }
                                }
                            }
                        }
                    }
                }

                for (const matrix of props.products.matrix) {
                    const matrixProductData = matrix.productData;
                    const slots             = matrix.subProducts.slot;
                    const portTypeCount     = matrixProductData.portTypeCount || {};
                    let portsToAdd          = 0;

                    if (
                        matrixProductData.subCategoryType === ProductSubCategoryType.compact ||
                        matrixProductData.subCategoryType === ProductSubCategoryType.flex
                    ) {
                        portsToAdd += matrixProductData.portCount;

                        for (const dataTransferType of dataTransferTypes) {
                            const portCount = portTypeCount[dataTransferType];

                            if (
                                portCount > 0 &&
                                _.has(ports, ['details', 'matrix', dataTransferType])
                            ) {
                                addPortsToContext('matrix', dataTransferType, portCount);
                            }
                        }

                        if (
                            matrixProductData.subCategoryType === ProductSubCategoryType.flex &&
                            matrixProductData.mutable
                        ) {
                            for (const slot of slots) {
                                if (slot.subSlots) {
                                    for (const subSlot of slot.subSlots) {
                                        const subSlotProductData = subSlot.productData;

                                        if (subSlotProductData.portTypeCount) {
                                            for (const subSlotDataTransferType of subSlotProductData.dataTransfer) {
                                                const portCount = subSlotProductData.portTypeCount[subSlotDataTransferType];

                                                if (portCount > 0) {
                                                    portsToAdd += portCount;

                                                    if (_.has(ports, ['details', 'matrix', subSlotDataTransferType])) {
                                                        addPortsToContext('matrix', subSlotDataTransferType, portCount);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        /*
                           We dont want to count the ports of 480-UNICOMPACT since the ports of the card
                           are already set in the portCount of the chassis.
                        */
                        for (const slot of slots) {
                            const slotProductData = slot.productData;

                            if (slotProductData) {
                                if (slotProductData.productSlotType === ProductSlotType.ioBoard) {
                                    portsToAdd += slotProductData.layoutDefinition.portCount;

                                    addPortsToContext(
                                        'matrix',
                                        slotProductData.dataTransfer,
                                        slotProductData.layoutDefinition.portCount,
                                    );
                                }
                            }
                        }
                    }

                    if (portsToAdd) {
                        ports.matrix += portsToAdd;
                    }
                }
            }

            console.log('WithPortCounts: recalculatePorts', ports);

            return ports;
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    hasPorts={this.hasPorts()}
                    ports={this.state.ports}
                />
            );
        }
    };
}

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// We just "extend" the original class
// @see https://github.com/adazzle/react-data-grid/blob/master/packages/react-data-grid-addons/src/cells/headerCells/filters/AutoCompleteFilter.js
import React from 'react';

import I18n                from 'i18next';
import _                   from 'lodash';
import { Filters }         from 'react-data-grid-addons';
import { withTranslation } from 'react-i18next';

import AudioTypes          from '@constants/AudioTypes';
import DataTransferTypes   from '@constants/DataTransferTypes';
import DeviceTypes         from '@constants/DeviceTypes';
import MainboardTypes      from '@constants/MainboardTypes';
import SerialTypes         from '@constants/SerialTypes';
import UsbTypes            from '@constants/UsbTypes';
import VideoConnectorTypes from '@constants/VideoConnectorTypes';

import TableSelect from '../TableSelect';

function translateTypes(options, types, prefix) {
    for (const option of options) {
        let languageKey = _.findKey(types, (label) => {
            return option && label === option.label;
        });

        if (languageKey) {
            if (prefix) {
                languageKey = prefix + _.upperFirst(languageKey).replace(/ /g, '');
            }

            option.label = I18n.t(languageKey);
        }
    }
}

Filters.AutoCompleteFilter.prototype.render = function() {
    // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-385
    const columnKey = _.get(this, 'props.column.key');

    if (!columnKey) {
        return null;
    }

    if (columnKey === 'audio') {
        translateTypes(
            this.state.options,
            AudioTypes,
        );
    } else if (columnKey === 'dataTransfer') {
        translateTypes(
            this.state.options,
            DataTransferTypes,
            'dataTransfer',
        );
    } else if (columnKey === 'serial') {
        translateTypes(
            this.state.options,
            SerialTypes,
            'serial',
        );
    } else if (columnKey === 'deviceType') {
        translateTypes(
            this.state.options,
            DeviceTypes,
        );
    } else if (columnKey === 'mainboardType') {
        translateTypes(
            this.state.options,
            MainboardTypes,
        );
    } else if (columnKey === 'usb') {
        translateTypes(
            this.state.options,
            UsbTypes,
        );
    } else if (columnKey === 'videoConnector') {
        translateTypes(
            this.state.options,
            VideoConnectorTypes,
        );
    }

    const cleanOptions = Object.values(_.pickBy(this.state.options, _.identity));

    return (
        <TableSelect
            isMulti={(
                this.props.multiSelection !== undefined &&
                this.props.multiSelection !== null ?
                    this.props.multiSelection :
                    true
            )}
            name={`filter-${columnKey}`}
            onChange={this.handleChange}
            options={cleanOptions}
            value={this.state.filters}
        />
    );
};

export default withTranslation()(Filters.AutoCompleteFilter);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import ProductCategoryType     from '@constants/ProductCategoryType';
import ProductSlotType         from '@constants/ProductSlotType';
import ProductSubCategoryTypes from '@constants/ProductSubCategoryType';
import DataProvider            from '@helper/DataProvider';

import PropTypes      from '../PropTypes';
import Table          from '../Table';
import DefaultColumns from '../Table/DefaultColumns';

const Component = ({ productSubCategoryType }) => {
    const getColumnConfiguration = () => {
        const defaultColumns = DefaultColumns.get(
            ProductCategoryType.fullIp,
            productSubCategoryType,
        );

        return [
            defaultColumns.displayPartNumber,
            defaultColumns.description,
        ];
    };
    const getRawData             = () => {
        if (productSubCategoryType === ProductSubCategoryTypes.fullIpFullHd) {
            return DataProvider.getFullIpFullHdAccessories();
        }

        return DataProvider.getFullIp4KAccessories();
    };
    const rawData                = getRawData();

    return (
        <Table
            columnConfiguration={getColumnConfiguration()}
            productCategoryType={ProductCategoryType.fullIp}
            productSlotType={ProductSlotType.addOnModule}
            rawData={rawData}
        />
    );
};

Component.propTypes = {
    productSubCategoryType: PropTypes.oneOf(Object.values(ProductSubCategoryTypes)).isRequired,
};

Component.defaultProps = {
    productSubCategoryType: ProductSubCategoryTypes.fullIpFullHd,
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import I18n       from 'i18next';
import _          from 'lodash';

import styles    from './styles.module.scss';
import PropTypes from '../PropTypes';
import Switch    from '../Switch';
import Tooltip   from '../Tooltip';

class Component extends React.Component {
    render() {
        const props = this.props;

        return (
            <div className={styles.groupSimilarProductSwitch}>
                <div className={styles.groupSimilarProductSwitchLeft}>
                    <strong className={styles.groupSimilarProductSwitchLeftText}>
                        {I18n.t('groupSimilarProductsText')}
                    </strong>
                    {this.renderTooltip()}
                </div>
                <div
                    className={classNames(styles.groupSimilarProductSwitchRight)}
                >
                    <Switch
                        checked={props.checked}
                        onChange={props.onSwitch}
                    />
                </div>
            </div>
        );
    }

    renderTooltip = () => {
        return (
            <Tooltip text={I18n.t('groupSimilarProductsTooltipText')} />
        );
    };
}

Component.propTypes = {
    checked:  PropTypes.bool,
    onSwitch: PropTypes.func,
};

Component.defaultProps = {
    onSwitch: _.noop,
    checked:  false,
};

export default Component;

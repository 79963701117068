import { createSlice } from '@reduxjs/toolkit';
import update          from 'immutability-helper';
import { DebugActions } from '@slices/debug';
import { bindActionCreators } from 'redux';

const initialState = {
    isDragging:       false,
    operationCounter: 0,
};

const DragDropSlice = createSlice({
    name:     'dragDrop',
    initialState,
    reducers: {
        dragStart: (state) => {
            return update(state, {
                isDragging: {
                    $set: true,
                },
            });
        },
        dragStop:  (state) => {
            return update(state, {
                isDragging:       {
                    $set: false,
                },
                operationCounter: {
                    $apply: (value) => value + 1,
                },
            });
        },
        resetAll:  () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(DebugActions.resetAll().type, (state, action) => {
            return update(state, {
                $set: initialState,
            });
        });
    },
});

export const DragDropActions = DragDropSlice.actions;

export const DragDropReducer = DragDropSlice.reducer;

export const useDragDrop = (dispatch) => bindActionCreators(DragDropActions, dispatch);

export default DragDropSlice;

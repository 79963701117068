//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { isTablet }     from 'react-device-detect';
import { DndProvider }  from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

import PropTypes from '../PropTypes';

const Component = ({ children }) => {
    const reference               = React.useRef(null);
    const getBackendConfiguration = () => {
        if (isTablet) {
            return {
                backend: TouchBackend,
                options: {
                    delayTouchStart:   200,
                    ignoreContextMenu: true,
                },
            };
        }

        return {
            backend: HTML5Backend,
            options: {},
        };
    };

    /**
     * @see https://stackoverflow.com/questions/10614481/disable-double-tap-zoom-option-in-browser-on-touch-devices
     *
     * @param event
     */
    const preventDoubleTapZoom = (event) => {
        const currentTimestamp              = event.timeStamp;
        const previousTimestamp             = reference.current.dataset.lastTouch || currentTimestamp;
        const deltaTime                     = currentTimestamp - previousTimestamp;
        const fingers                       = event.touches.length;
        reference.current.dataset.lastTouch = currentTimestamp;

        if (!deltaTime || deltaTime > 500 || fingers > 1) {
            return;
        }

        event.preventDefault();

        if (event.target && event.target.click) {
            event.target.click();
        }
    };

    React.useEffect(() => {
        if (reference.current) {
            reference.current.addEventListener('touchstart', preventDoubleTapZoom);
        }

        return () => {
            if (reference.current) {
                reference.current.removeEventListener('touchstart', preventDoubleTapZoom);
            }
        };
    }, []);

    const backendConfiguration = getBackendConfiguration();

    return (
        <DndProvider
            backend={backendConfiguration.backend}
            options={backendConfiguration.options}
        >
            {isTablet
                ? (
                    <div ref={reference}>
                        {children}
                    </div>
                )
                : (
                    children
                )}
        </DndProvider>
    );
};

Component.propTypes = {
    children: PropTypes.children,
};

Component.defaultProps = {
    children: [],
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                from 'i18next';
import { withTranslation } from 'react-i18next';
import { connect }         from 'react-redux';

import ProductCategoryType from '@constants/ProductCategoryType';
import Routes              from '@constants/Routes';
import { isKVMTecEnabled } from '@helper/Configuration';

import styles                      from './styles.module.scss';
import LeftPaneBackButton          from '../LeftPaneBackButton';
import LeftPaneSingleOrderCategory from '../LeftPaneSingleOrderCategory';
import SingleOrderQuickSearch      from '../SingleOrderQuickSearch';

const Component = ({
    singleOrders,
    singleOrdersEnabled,
}) => {
    const getSingleOrderCount            = (category) => {
        let singleOrderCount        = 0;
        const singleOrderCategories = singleOrders;

        if (singleOrderCategories) {
            const singleOrderCategory = singleOrderCategories[category];

            for (const singleOrder of singleOrderCategory) {
                if (singleOrder.productData) {
                    singleOrderCount += singleOrder.amount;
                }
            }

            return singleOrderCount;
        }

        return singleOrderCount;
    };
    const renderEquipmentProductCategory = () => {
        return (
            <LeftPaneSingleOrderCategory
                label={I18n.t('equipment')}
                productCategoryType={ProductCategoryType.equipment}
            />
        );
    };
    const renderFullIpProductCategory    = () => {
        if (!isKVMTecEnabled()) {
            return null;
        }

        return (
            <LeftPaneSingleOrderCategory
                label={I18n.t('fullIpAndSwitching')}
                productCategoryType={ProductCategoryType.fullIp}
            />
        );
    };
    const renderExtenderProductCategory  = () => {
        if (
            singleOrdersEnabled ||
            getSingleOrderCount(ProductCategoryType.extender) > 0
        ) {
            return (
                <LeftPaneSingleOrderCategory
                    label={I18n.t('extender')}
                    productCategoryType={ProductCategoryType.extender}
                />
            );
        }

        return null;
    };
    const renderMatrixProductCategory    = () => {
        if (
            singleOrdersEnabled ||
            getSingleOrderCount(ProductCategoryType.matrix) > 0
        ) {
            return (
                <
                    LeftPaneSingleOrderCategory
                    label={I18n.t('matrixSwitch')}
                    productCategoryType={ProductCategoryType.matrix}
                />
            );
        }

        return null;
    };

    return (
        <div
            className={styles.singleOrderPane}
        >
            <LeftPaneBackButton route={Routes.designer} />
            <h3>
                {
                    singleOrdersEnabled ?
                        I18n.t('equipmentAndSingleOrders') :
                        I18n.t('equipment')
                } </h3>
            <SingleOrderQuickSearch />
            {renderMatrixProductCategory()}
            {renderExtenderProductCategory()}
            {renderEquipmentProductCategory()}
            {renderFullIpProductCategory()}
        </div>
    );
};

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => (
    {
        singleOrders:        state.activeProject.singleOrders,
        singleOrdersEnabled: state.settings.singleOrdersEnabled,
    }
);

export default connect(
    mapStateToProps,
    null,
)(withTranslation()(Component));

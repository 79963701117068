//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import Overlays                 from '@constants/Overlays';
import SlotType                 from '@constants/SlotType';
import Product                  from '@helper/Product';
import StateHelper              from '@helper/State';

import CheckBox     from '../CheckBox';
import Overlay      from '../Overlay';
import OverlayType  from '../Overlay/OverlayType';
import TextHeadline from '../TextHeadline';

const allModulesPartNumber = 'ALL';

class Component extends React.Component {
    allCheckBoxValueChanged = () => {
        const uniqueModulePartNumbers = Object.keys(this.getSelectedProductModulePartNumbersAndCount());

        uniqueModulePartNumbers.push(allModulesPartNumber);

        this.props.toggleModuleSelection({
            partNo:     uniqueModulePartNumbers,
            totalCount: uniqueModulePartNumbers.length - 1,
        });
    };

    partNoCheckBoxValueChanged = (partNo) => {
        const uniqueModulePartNumbers = Object.keys(this.getSelectedProductModulePartNumbersAndCount());

        this.props.toggleModuleSelection({
            partNo,
            totalCount: uniqueModulePartNumbers.length,
        });
    };

    deleteButtonPressed = () => {
        this.props.applyModuleDeletion();
    };

    getCancelButtonTextKey = () => {
        return 'cancel';
    };

    getOkButtonTextKey = () => {
        return 'deleteModulesOverlayOkButtonText';
    };

    getSelectedProductModulePartNumbersAndCount = () => {
        const partNumbers = {};

        if (this.props.selectedProduct) {
            const subProductKeys = Object.keys(this.props.selectedProduct.subProducts);

            for (const subProductCategoryKey of subProductKeys) {
                if (subProductCategoryKey === SlotType.slot) {
                    const subProducts = this.props.selectedProduct.subProducts[subProductCategoryKey];

                    for (const subProductIndex in subProducts) {
                        const subProduct = subProducts[subProductIndex];

                        if (subProduct.productData) {
                            const isLocked = Product.isLockedInProduct(
                                this.props.selectedProduct.productData,
                                subProductCategoryKey,
                                subProductIndex,
                            );

                            if (!isLocked) {
                                if (!partNumbers[subProduct.productData.partNo]) {
                                    partNumbers[subProduct.productData.partNo] = {
                                        count:  1,
                                        partNo: subProduct.productData.partNo,
                                    };
                                } else {
                                    ++partNumbers[subProduct.productData.partNo].count;
                                }
                            }
                        }
                    }
                }
            }
        }

        return partNumbers;
    };

    getTitle = () => {
        return I18n.t('deleteModulesOverlayTitle');
    };

    render() {
        const uniqueModulePartNumbers = Object.values(this.getSelectedProductModulePartNumbersAndCount());

        if (uniqueModulePartNumbers.length > 0) {
            return this.renderDeleteModulesOverlay(uniqueModulePartNumbers);
        }

        return this.renderNoModulesGivenOverlay();
    }

    renderDeleteModulesOverlay = (uniqueModulePartNumbers) => {
        return (
            <Overlay
                allowDontShowAgain={false}
                cancelButtonTextKey={this.getCancelButtonTextKey()}
                id={Overlays.deleteModules}
                okButtonPressed={this.deleteButtonPressed}
                okButtonTextKey={this.getOkButtonTextKey()}
                title={this.getTitle()}
                {...this.props}
            >
                <TextHeadline text={I18n.t('deleteModulesOverlayText')} />
                <CheckBox
                    checked={
                        this.props.deleteModulesContext &&
                        this.props.deleteModulesContext.modules[allModulesPartNumber]
                    }
                    text={I18n.t('deleteModulesOverlayAll')}
                    valueChanged={this.allCheckBoxValueChanged}
                />
                {uniqueModulePartNumbers.map(this.renderPartNumberCheckBox)}
            </Overlay>
        );
    };

    renderNoModulesGivenOverlay = () => {
        return (
            <Overlay
                allowDontShowAgain={false}
                id={Overlays.deleteModules}
                overlayType={OverlayType.prompt}
                title={this.getTitle()}
                {...this.props}
            >
                <TextHeadline text={I18n.t('deleteModulesOverlayEmptyText')} />
            </Overlay>
        );
    };

    renderPartNumberCheckBox = (partNumberWithCount) => {
        return (
            <CheckBox
                checked={
                    this.props.deleteModulesContext &&
                    this.props.deleteModulesContext.modules[partNumberWithCount.partNo]

                }
                text={
                    `${partNumberWithCount.partNo
                    } (${
                        partNumberWithCount.count
                    } ${
                        I18n.t(partNumberWithCount.count === 1 ?
                            'moduleSingular' :
                            'modulePlural')
                    })`
                }
                valueChanged={() => {
                    this.partNoCheckBoxValueChanged(partNumberWithCount.partNo);
                }}
            />
        );
    };
}

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => (
    {
        deleteModulesContext: state.activeProject.deleteModulesContext,
        selectedProduct:      StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

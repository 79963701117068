//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import DataProvider from './DataProvider';

//
// This class is able to iterate over a project to figure
// out of some of the cached productData entries are timed out
// so the user can decide to update them.
//
class ProductDataUpdateChecker {
    static hasChanges(project) {
        let changedProductNumbers = [].concat(
            this.productsHasChanges(project),
            this.singleOrderHasChanges(project),
        );

        console.log('ProductDataUpdateChecker: found data changes', changedProductNumbers);

        changedProductNumbers = _.uniq(changedProductNumbers);

        return changedProductNumbers;
    }

    static productHasChanges(product) {
        const changedProductNumbers = [];
        const productData           = product.productData;

        if (productData) {
            const productPartNo          = productData.partNo;
            const jsonProductProductData = DataProvider.getById(productPartNo);

            if (!_.isEqual(productData, jsonProductProductData)) {
                changedProductNumbers.push(productPartNo);
            }

            if (product.subProducts) {
                const subProductCategories   = product.subProducts;
                const subProductCategoryKeys = Object.keys(subProductCategories);

                for (const subProductCategory of subProductCategoryKeys) {
                    const subProducts = subProductCategories[subProductCategory];

                    for (const subProduct of subProducts) {
                        const subProductProductData = subProduct.productData;

                        if (subProductProductData) {
                            const subProductPartNo          = subProductProductData.partNo;
                            const jsonSubProductProductData = DataProvider.getById(subProductPartNo);

                            if (!_.isEqual(subProductProductData, jsonSubProductProductData)) {
                                changedProductNumbers.push(subProductPartNo);
                            }

                            const sfps = subProduct.sfps;

                            if (sfps) {
                                for (const sfp of sfps) {
                                    const sfpProductData = sfp.productData;

                                    if (sfpProductData) {
                                        const sfpPartNo          = sfpProductData.partNo;
                                        const jsonSfpProductData = DataProvider.getById(sfpPartNo);

                                        if (!_.isEqual(sfpProductData, jsonSfpProductData)) {
                                            changedProductNumbers.push(sfpPartNo);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return changedProductNumbers;
    }

    static productsHasChanges = (project) => {
        let changedProductNumbers = [];
        const productCategories   = project.products;

        if (productCategories) {
            const productCategoryKeys = Object.keys(productCategories);

            for (const productCategoryKey of productCategoryKeys) {
                const products = productCategories[productCategoryKey];

                for (const product of products) {
                    changedProductNumbers = changedProductNumbers.concat(this.productHasChanges(product));
                }
            }
        }

        return changedProductNumbers;
    };

    static singleOrderHasChanges = (project) => {
        let changedProductNumbers   = [];
        const singleOrderCategories = project.singleOrders;

        if (singleOrderCategories) {
            const singleOrderCategoryKeys = Object.keys(singleOrderCategories);

            for (const singleOrderCategoryKey of singleOrderCategoryKeys) {
                const products = singleOrderCategories[singleOrderCategoryKey];

                for (const product of products) {
                    changedProductNumbers = changedProductNumbers.concat(this.productHasChanges(product));
                }
            }
        }

        return changedProductNumbers;
    };
}

export default ProductDataUpdateChecker;

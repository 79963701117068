//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import update                 from 'immutability-helper';
import { bindActionCreators } from 'redux';

import IdGenerator            from '@helper/IdGenerator';
import { ImportActions }      from '@slices/import';

const initialState = {
    lastImportedProjectId:  null,
    projectDeletionContext: {
        id: null,
    },
    projects:               {},
};

const saveProject = (state, action) => {
    const projectData = action?.payload?.projectData;
    const projectId   = projectData?.id;

    if (projectId) {
        return update(state, {
            projects: {
                [projectId]: {
                    $set: {
                        ...projectData,
                        lastUpdateDate:  new Date(),
                        routeAfterClose: null,
                    },
                },
            },
        });
    }

    return state;
};

const projectsSlice = createSlice({
    name:          'projects',
    initialState,
    reducers:      {
        deleteProject:                          (state, action) => {
            return update(state, {
                projectDeletionContext: {
                    id: {
                        $set: action.payload.id,
                    },
                },
            });
        },
        deleteProjectCancel:                    (state) => {
            return update(state, {
                projectDeletionContext: {
                    $set: initialState.projectDeletionContext,
                },
            });
        },
        deleteProjectConfirm:                   (state) => {
            return update(state, {
                projects:               {
                    $unset: [
                        state.projectDeletionContext.id,
                    ],
                },
                projectDeletionContext: {
                    $set: initialState.projectDeletionContext,
                },
            });
        },
        loadProject:                            (state, action) => {
            return state;
        },
        openLastImportedProject:                (state, action) => {
            return state;
        },
        importIntoNewProject:                   (state, action) => {
            return state;
        },
        newProject:                             (state, action) => {
            return state;
        },
        saveThenClearThenRedirectActiveProject: saveProject,
        saveProject,
        resetAll:                               (state) => {
            return update(state, {
                $set: initialState,
            });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(ImportActions.importNewProject.type, (state, action) => {
            const importToNewProjectProjectData = {
                ...action.payload.projectData,
            };
            const importedProjectId             = `${IdGenerator.getNewProjectId()}-import`;
            importToNewProjectProjectData.id    = importedProjectId;

            return update(state, {
                lastImportedProjectId: {
                    $set: importedProjectId,
                },
                projects:              {
                    [importedProjectId]: {
                        $set: {
                            ...importToNewProjectProjectData,
                            lastUpdateDate:  new Date(),
                            routeAfterClose: null,
                        },
                    },
                },
            });
        });
    },
});

export const ProjectsActions = projectsSlice.actions;

export const ProjectsReducer = projectsSlice.reducer;

export const useProjects = (dispatch) => bindActionCreators(ProjectsActions, dispatch);

export default projectsSlice;

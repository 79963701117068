//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { goBack }             from 'connected-react-router';
import I18n                   from 'i18next';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { withRouter }         from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { ProjectsActions }      from '@slices/projects';
import { getPathForOverlayKey } from '@components/OverlayManager';
import Overlays                 from '@constants/Overlays';
import { ImportActions }        from '@slices/import';

import ColorButton      from '../ColorButton';
import ColorButtonTheme from '../ColorButton/ColorButtonTheme';
import Overlay          from '../Overlay';
import OverlayType      from '../Overlay/OverlayType';
import TextButton       from '../TextButton';
import TextButtonTheme  from '../TextButton/TextButtonTheme';
import TextHeadline     from '../TextHeadline';

const Component = ({
    importIntoNewProject,
    history,
    location,
}) => {
    const closeButtonPressed            = () => {
        history.push(location.pathname);
    };
    const renderAdditionalFooterButtons = () => {
        return [
            <TextButton
                onClick={closeButtonPressed}
                text={I18n.t('cancel')}
                theme={TextButtonTheme.black}
            />,
            <ColorButton
                key={'importIntoNewProject'}
                onClick={importIntoNewProject}
                text={I18n.t('importIntoNewProject')}
                theme={ColorButtonTheme.orange}
            />,
        ];
    };

    return (
        <Overlay
            id={Overlays.importActiveProject}
            title={I18n.t('importActiveProject')}
            renderAdditionalFooterButtons={renderAdditionalFooterButtons}
            overlayType={OverlayType.dialog}
            nextRoute={getPathForOverlayKey(Overlays.import)}
        >
            <TextHeadline text={I18n.t('importActiveProjectDialogText1')} />
            <TextHeadline text={I18n.t('importActiveProjectDialogText2')} />
        </Overlay>
    );
};

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => (
    {
        location: state.router.location,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(
    {
        ...ImportActions,
        ...ProjectsActions,
        goBack,
    },
    dispatch,
);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component)));

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { SalesMailboxActions } from '@slices/salesMailbox';
import Overlays                from '@constants/Overlays';

import styles       from './styles.module.scss';
import CheckBox     from '../CheckBox';
import ErrorBox     from '../ErrorBox';
import Input        from '../Input';
import InputElement from '../Input/InputElement';
import InputType    from '../Input/InputType';
import Overlay      from '../Overlay';
import BackType     from '../Overlay/BackType';
import OverlayType  from '../Overlay/OverlayType';
import TextHeadline from '../TextHeadline';

class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
        };
    }

    commentChanged = (event) => {
        this.props.setComment({
            comment: event.target.value,
        });
    };

    companyChanged = (event) => {
        this.props.setCompany({
            company: event.target.value,
        });
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.lastSubmitFailed !== nextProps.lastSubmitFailed) {
            this.setError(I18n.t('exportIhseSalesMailboxSubmitError1'));
        }
    }

    eMailAddressChanged = (event) => {
        this.props.setEMailAddress({
            eMailAddress: event.target.value,
        });
    };

    errorBoxCloseButtonPressed = () => {
        this.setState({
            error: null,
        });
    };

    extendedWarrantyValueChanged = () => {
        this.props.setExtendedWarranty({
            extendedWarranty: !this.props.extendedWarranty,
        });
    };

    firstNameChanged = (event) => {
        this.props.setFirstName({
            firstName: event.target.value,
        });
    };

    getOkButtonTextKey = () => {
        if (this.props.submitting) {
            return 'exportIhseSalesMailboxSubmitLoading';
        }

        return 'exportIhseSalesMailboxSubmit';
    };

    lastNameChanged = (event) => {
        this.props.setLastName({
            lastName: event.target.value,
        });
    };

    okButtonValidator = () => {
        if (!this.props.submitting) {
            if (!this.props.firstName) {
                this.setError(I18n.t('salesMailboxFirstNameError'));
            } else if (!this.props.lastName) {
                this.setError(I18n.t('salesMailboxLastNameError'));
            } else if (!this.props.eMailAddress) {
                this.setError(I18n.t('salesMailboxEMailAddressError'));
            } else if (!this.props.phoneNumber) {
                this.setError(I18n.t('salesMailboxPhoneNumberError'));
            } else if (!this.props.company) {
                this.setError(I18n.t('salesMailboxCompanyError'));
            } else {
                this.props.submit();
            }
        }

        return false;
    };

    phoneNumberChanged = (event) => {
        this.props.setPhoneNumber({
            phoneNumber: event.target.value,
        });
    };

    render() {
        return (
            <Overlay
                backType={BackType.forceCloseOverlay}
                forceCancelButton={true}
                id={Overlays.salesMailbox}
                okButtonTextKey={this.getOkButtonTextKey()}
                okButtonValidator={this.okButtonValidator}
                overlayType={OverlayType.dialog}
                closeButtonPressed={this.closeButtonPressed}
                title={I18n.t('ihseSalesMailbox')}
            >
                <div className={styles.salesMailboxForm}>
                    <Input
                        placeholder={I18n.t('enterTextPlaceholder')}
                        text={I18n.t('firstName')}
                        tooltip={I18n.t('salesMailboxFirstNameTooltip')}
                        type={InputType.text}
                        value={this.props.firstName}
                        valueChanged={this.firstNameChanged}
                    />
                    <Input
                        placeholder={I18n.t('enterTextPlaceholder')}
                        text={I18n.t('lastName')}
                        tooltip={I18n.t('salesMailboxLastNameTooltip')}
                        type={InputType.text}
                        value={this.props.lastName}
                        valueChanged={this.lastNameChanged}
                    />
                    <Input
                        placeholder={I18n.t('enterTextPlaceholder')}
                        text={I18n.t('eMailAddress')}
                        tooltip={I18n.t('salesMailboxEMailAddressTooltip')}
                        type={InputType.email}
                        value={this.props.eMailAddress}
                        valueChanged={this.eMailAddressChanged}
                    />
                    <Input
                        placeholder={I18n.t('enterTextPlaceholder')}
                        text={I18n.t('phoneNumber')}
                        tooltip={I18n.t('salesMailboxPhoneNumberTooltip')}
                        type={InputType.text}
                        value={this.props.phoneNumber}
                        valueChanged={this.phoneNumberChanged}
                    />
                    <Input
                        placeholder={I18n.t('enterTextPlaceholder')}
                        text={I18n.t('company')}
                        tooltip={I18n.t('salesMailboxCompanyTooltip')}
                        type={InputType.text}
                        value={this.props.company}
                        valueChanged={this.companyChanged}
                    />
                </div>
                <div className={styles.salesMailboxForm}>
                    <Input
                        element={InputElement.textarea}
                        placeholder={I18n.t('enterTextPlaceholder')}
                        text={I18n.t('comment')}
                        tooltip={I18n.t('salesMailboxCommentTooltip')}
                        type={InputType.text}
                        value={this.props.comment}
                        valueChanged={this.commentChanged}
                    />
                </div>
                <div className={styles.salesMailboxBottomPanel}>
                    <TextHeadline text={I18n.t('salesMailboxFurtherConsultationText')} />
                    <div>
                        <CheckBox
                            checked={this.props.serviceLevelAgreement}
                            noMarginBottom={false}
                            text={I18n.t('salesMailboxServiceLevelAgreementText')}
                            valueChanged={this.serviceLevelAgreementValueChanged}
                        />
                        <CheckBox
                            checked={this.props.extendedWarranty}
                            noMarginBottom={false}
                            text={I18n.t('salesMailboxExtendedWarrantyText')}
                            valueChanged={this.extendedWarrantyValueChanged}
                        />
                        <CheckBox
                            checked={this.props.spareParts}
                            noMarginBottom={true}
                            text={I18n.t('salesMailboxSparePartsText')}
                            valueChanged={this.sparePartsValueChanged}
                        />
                    </div>
                </div>
                {this.renderErrorBox()}
            </Overlay>
        );
    }

    renderErrorBox = () => {
        if (this.state.error) {
            return (
                <ErrorBox
                    closeButtonPressed={this.errorBoxCloseButtonPressed}
                    text={this.state.error}
                />
            );
        }

        return null;
    };

    setError = (error) => {
        this.setState({
            error,
        });
    };

    serviceLevelAgreementValueChanged = () => {
        this.props.setServiceLevelAgreement({
            serviceLevelAgreement: !this.props.serviceLevelAgreement,
        });
    };

    sparePartsValueChanged = () => {
        this.props.setSpareParts({
            spareParts: !this.props.spareParts,
        });
    };
}

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => (
    {
        comment:               _.get(state, 'salesMailbox.comment', ''),
        company:               _.get(state, 'salesMailbox.company', ''),
        eMailAddress:          _.get(state, 'salesMailbox.eMailAddress', ''),
        extendedWarranty:      _.get(state, 'salesMailbox.extendedWarranty', false),
        firstName:             _.get(state, 'salesMailbox.firstName', ''),
        lastName:              _.get(state, 'salesMailbox.lastName', ''),
        phoneNumber:           _.get(state, 'salesMailbox.phoneNumber', ''),
        lastSubmitFailed:      _.get(state, 'salesMailbox.lastSubmitFailed', false),
        serviceLevelAgreement: _.get(state, 'salesMailbox.serviceLevelAgreement', false),
        spareParts:            _.get(state, 'salesMailbox.spareParts', false),
        submitting:            _.get(state, 'salesMailbox.submitting', false),
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(SalesMailboxActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

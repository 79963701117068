//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import Routes from '@constants/Routes';

class Navigation {
    static checkForLeavePrompt(currentLocation, targetLocation, dispatchCloseProjectAction) {
        if (
            currentLocation.startsWith(Routes.designer) &&
            !targetLocation.startsWith(Routes.designer)
        ) {
            const actionPayload = {
                closeConfirmed:  false,
                routeAfterClose: targetLocation,
            };

            console.log('Link: linkClicked', {
                actionPayload,
                pathname: currentLocation,
                to:       targetLocation,
            });

            dispatchCloseProjectAction(actionPayload);

            return false;
        }

        return true;
    }
}

export default Navigation;

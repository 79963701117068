//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import WarningType     from '@constants/WarningType';
import SelectionHelper from '@helper/SelectionHelper';

import styles           from './styles.module.scss';
import Icon             from '../Icon';
import IconType         from '../Icon/IconType';
import PropTypes        from '../PropTypes';
import WarningListTheme from '../WarningList/WarningListTheme';

const Component = ({
    data  = {},
    theme,
}) => {
    const getIconType = () => {
        const iconType = _.get(data, 'iconType', false);

        if (iconType) {
            return iconType;
        }

        const type = _.get(data, 'type', false);

        if (type) {
            return SelectionHelper.get(
                type,
                {
                    [WarningType.error]:       IconType.error,
                    [WarningType.information]: IconType.help,
                },
            );
        }

        return null;
    };
    const iconType    = getIconType();
    const text        = _.get(data, 'text', null);
    const type        = _.get(data, 'type', false);

    return (
        <li
            className={classNames(
                styles.warningListItem,
                (
                    SelectionHelper.get(
                        type,
                        {
                            [WarningType.error]:       styles.warningListItemError,
                            [WarningType.information]: styles.warningListItemInformation,
                        },
                    )
                ),
                (
                    SelectionHelper.get(
                        theme,
                        {
                            [WarningListTheme.gray]:   styles.warningListItemGray,
                            [WarningListTheme.orange]: styles.warningListItemOrange,
                        },
                    )
                ),
            )}
        >
            <div className={styles.warningListItemIconWrapper}>
                <Icon iconType={iconType} />
            </div>
            {text}
        </li>
    );
};

Component.propTypes = {
    data:  PropTypes.object,
    theme: PropTypes.oneOf(Object.values(WarningListTheme)),
};

Component.defaultProps = {
    data:  {},
    theme: null,
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// We just "extend" the original class
// @see https://github.com/adazzle/react-data-grid/blob/master/packages/react-data-grid-addons/src/cells/headerCells/filters/NumericFilter.js
import React from 'react';

import I18n                from 'i18next';
import _                   from 'lodash';
import { withTranslation } from 'react-i18next';

import PropTypes   from '../PropTypes';
import TableSelect from '../TableSelect';

class TableBooleanFilter extends React.Component {
    filterValues(row, columnFilter, columnKey) {
        for (const currentColumnFilter of columnFilter.rawValue) {
            if (
                (
                    currentColumnFilter.value === 1 && row[columnKey] === true
                )
                ||
                (
                    currentColumnFilter.value === -1 && row[columnKey] === false
                )
            ) {
                return true;
            }
        }

        return false;
    }

    handleChange = (values) => {
        this.props.onChange({
            filterTerm:   values.length > 0,
            column:       this.props.column,
            rawValue:     values,
            filterValues: this.filterValues,
        });
    };

    render() {
        const columnKey = _.get(this, 'props.column.key');

        return (
            <TableSelect
                isMulti={(
                    this.props.multiSelection !== undefined &&
                    this.props.multiSelection !== null ?
                        this.props.multiSelection :
                        true
                )}
                name={`filter-${columnKey}`}
                onChange={this.handleChange}
                options={[
                    {
                        label: I18n.t('redundantYes'),
                        value: 1,
                    },
                    {
                        label: I18n.t('redundantNo'),
                        value: -1,
                    },
                ]}
                value={null}
            />
        );
    }
}

TableBooleanFilter.propTypes = {
    column:   PropTypes.any,
    onChange: PropTypes.func.isRequired,
};

export default withTranslation()(TableBooleanFilter);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import DropResult from '@constants/DropResult';
import SlotType   from '@constants/SlotType';
import Product    from '@helper/Product';

function slot(WrappedComponent) {
    class Slot extends React.Component {
        getDragOptions = (isLocked) => {
            if (isLocked) {
                return {
                    dropEffect: DropResult.copy,
                };
            }

            return {};
        };

        hasProduct = () => {
            return this.props.data && this.props.data.productData;
        };

        isCpu = () => {
            if (this.props.selectedProduct) {
                const layoutDefinition = this.props.selectedProduct.productData.layoutDefinition;
                const isCpu            = (
                    layoutDefinition &&
                    layoutDefinition.unused &&
                    layoutDefinition.io &&
                    layoutDefinition.unused.indexOf(this.props.index + 1) === -1 &&
                    layoutDefinition.io.indexOf(this.props.index + 1) === -1
                );

                return isCpu;
            }

            return false;
        };

        isDragSourceEnabled = () => {
            const dragSourceEnabled = _.get(this, 'props.data.productData.dragSourceEnabled', true);

            return dragSourceEnabled && this.props.dragSourceEnabled !== false;
        };

        isDoubleSlot = () => {
            return Product.slotIsDoubleSlot(
                this.props.selectedProduct,
                this.props.index,
            );
        };

        isLocked = () => {
            if (this.props.selectedProduct) {
                return Product.isLockedInProduct(
                    this.props.selectedProduct.productData,
                    this.props.type,
                    this.props.index,
                );
            }

            return false;
        };

        isSelected = () => {
            return (
                this.props.type === this.props.selectedSlot.slotType &&
                this.props.index === this.props.selectedSlot.index
            );
        };

        isUnused = () => {
            if (this.props.selectedProduct && this.props.type === SlotType.slot) {
                const chassisProductData = this.props.selectedProduct.productData;

                if (
                    chassisProductData.layoutDefinition &&
                    chassisProductData.layoutDefinition.unused
                ) {
                    const slotNumber = this.props.index + 1;

                    if (chassisProductData.layoutDefinition.unused.indexOf(slotNumber) > -1) {
                        return true;
                    }
                }
            }

            return false;
        };

        render() {
            const { ...otherProps } = this.props;

            return (
                <WrappedComponent
                    getDragOptions={this.getDragOptions}
                    hasProduct={this.hasProduct}
                    dragSourceEnabled={this.isDragSourceEnabled()}
                    isCpu={this.isCpu()}
                    isDoubleSlot={this.isDoubleSlot()}
                    isLocked={this.isLocked()}
                    isSelected={this.isSelected}
                    isUnused={this.isUnused()}
                    slotClicked={this.slotClicked}
                    {...otherProps}
                />
            );
        }

        slotClicked = (event) => {
            if (this.hasProduct()) {
                this.props.setRightPaneSetVisibility({
                    visible: true,
                });

                if (this.isSelected()) {
                    this.props.deselectSlot();
                } else {
                    this.props.selectSlot({
                        index:    this.props.index,
                        slotType: this.props.type,
                    });
                }

                event.preventDefault();
                event.stopPropagation();
            }
        };
    }

    return Slot;
}

export default slot;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as JSZip from 'jszip';
import _          from 'lodash';

class DsdjsonImporter {
    static canImport(zip, file) {
        const fileNames       = Object.keys(zip.files);
        const jsonProjectFile = this.findFirstJsonInFileNameList(fileNames);
        const result          = !!jsonProjectFile;

        console.log('DsdjsonImporter canImport:', result);

        return result;
    }

    static findFirstJsonInFileNameList(fileNameList) {
        const jsonProjectFiles = _.filter(fileNameList, (fileName) => {
            return fileName.endsWith('.json');
        });

        return _.head(jsonProjectFiles);
    }

    static importFile(file, errorCallback, parsedContentCallback) {
        const zip = new JSZip();

        return zip.loadAsync(file).then((zip) => {
            if (zip && zip.files && this.canImport(zip, file)) {
                this.parseProjectFile(zip, parsedContentCallback);
            } else {
                errorCallback();
            }
        }, () => {
            // It may happen that the user directly uploads a json file
            // instead of a zip file containing a json file
            const fileReader = new FileReader();

            fileReader.onload = () => {
                try {
                    const content       = fileReader.result;
                    const parsedContent = JSON.parse(content);

                    parsedContentCallback(parsedContent);
                } catch (exception) {
                    errorCallback();
                }
            };

            fileReader.readAsText(file);
        });
    }

    static parseProjectFile(zip, parsedContentCallback) {
        const fileNames       = Object.keys(zip.files);
        const jsonProjectFile = this.findFirstJsonInFileNameList(fileNames);

        zip.file(jsonProjectFile).async('string').then((content) => {
            const parsedContent = JSON.parse(content);

            parsedContentCallback(parsedContent);
        });
    }
}

export default DsdjsonImporter;

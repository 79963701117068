//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import DataProvider from '@helper/DataProvider';

import SlotAlignment from '../../SelectedProductSlotImageRenderer/SlotAlignment';
import ChassisLayout from '../ChassisLayout';

/**
 *  @see This file also exists in the backend src/BoundsCalculators/MatrixPowerSupplyLeftTopToRightBottom.php
 */
class MatrixPowerSupplyLeftBottomToTopToRightCalculator {
    static getBoundsChassisLayoutHorizontalX = (bounds, psuDefinition) => {
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-1189
    };

    static getBoundsChassisLayoutVerticalX = (bounds, psuDefinition) => {
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-1189
    };

    static getBoundsChassisLayoutHorizontalY = (bounds, psuDefinition) => {
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-1189
    };

    static getBoundsChassisLayoutVerticalY = (bounds, psuDefinition) => {
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-1189
    };

    static getCurrentColumnChassisLayoutHorizontal = (psuIndexStartingBy1, psuDefinition) => {
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-1189
    };

    static getCurrentColumnChassisLayoutVertical = (psuIndexStartingBy1, psuDefinition) => {
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-1189
    };

    static getCurrentRowChassisLayoutHorizontal = (psuIndexStartingBy1, psuDefinition) => {
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-1189
    };

    static getCurrentRowChassisLayoutVertical = (psuIndexStartingBy1, psuDefinition) => {
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-1189
    };

    static getBounds = (psuIndexStartingBy1, psuDefinition, productData) => {
        const bounds = this.getDefaultBounds(psuDefinition);

        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-1189

        return bounds;
    };

    static getDefaultBounds = (psuDefinition) => {
        const usedPowerSupplyUnit            = psuDefinition.psu[0];
        const usedPowerSupplyUnitProductData = DataProvider.getById(usedPowerSupplyUnit.partNo);
        const usedPowerSupplyUnitFirstImage  = usedPowerSupplyUnitProductData.images[0];

        return {
            alignTo:       SlotAlignment.top,
            chassisLayout: ChassisLayout.horizontal,
            height:        usedPowerSupplyUnitFirstImage.height,
            x:             0,
            y:             0,
            width:         usedPowerSupplyUnitFirstImage.width,
        };
    };

    static updateBoundsChassisLayoutHorizontal = (psuIndexStartingBy1, psuDefinition, bounds) => {
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-1189

    };

    static updateBoundsChassisLayoutVertical = (psuIndexStartingBy1, psuDefinition, bounds) => {
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-1189
    };
}

export default MatrixPowerSupplyLeftBottomToTopToRightCalculator;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import { bindActionCreators } from 'redux';

const initialState = {};

const debugSlice = createSlice({
    name:     'debug',
    initialState,
    reducers: {
        resetAll: () => {
            return initialState;
        },
    },
});

export const DebugActions = debugSlice.actions;

export const DebugReducer = debugSlice.reducer;

export const useDebug = (dispatch) => bindActionCreators(DebugActions, dispatch);

export default debugSlice;

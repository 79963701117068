//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { connectRouter }   from 'connected-react-router';
import { combineReducers } from 'redux';

import { DebugReducer }                  from '@slices/debug';
import { DesignerLayoutReducer }         from '@slices/designerLayout';
import { ActiveProjectReducer }          from '@slices/activeProject';
import { DragDropReducer }               from '@slices/dragDrop';
import { ExtenderProductBrowserReducer } from '@slices/extenderProductBrowser';
import { ExportReducer }                 from '@slices/export';
import { FullIpProductBrowserReducer }   from '@slices/fullIpProductBrowser';
import { MatrixProductBrowserReducer }   from '@slices/matrixProductBrowser';
import { OverlayReducer }                from '@slices/overlays';
import { ProductPreviewReducer }         from '@slices/productPreview';
import { ProjectsReducer }               from '@slices/projects';
import { SalesMailboxReducer }           from '@slices/salesMailbox';
import { SettingReducer }                from '@slices/settings';

export default (history) => combineReducers({
    activeProject:          ActiveProjectReducer,
    debug:                  DebugReducer,
    designerLayout:         DesignerLayoutReducer,
    dragDrop:               DragDropReducer,
    extenderProductBrowser: ExtenderProductBrowserReducer,
    export:                 ExportReducer,
    fullIpProductBrowser:   FullIpProductBrowserReducer,
    overlays:               OverlayReducer,
    productPreview:         ProductPreviewReducer,
    projects:               ProjectsReducer,
    matrixProductBrowser:   MatrixProductBrowserReducer,
    salesMailbox:           SalesMailboxReducer,
    settings:               SettingReducer,
    //
    // This key must be "router"
    // @see https://github.com/supasate/connected-react-router
    router: connectRouter(history),
});

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { TabPanel }           from 'react-tabs';
import { bindActionCreators } from 'redux';

import { FullIpProductBrowserActions } from '@slices/fullIpProductBrowser';
import ProductSubCategoryTypes         from '@constants/ProductSubCategoryType';
import ProductSubCategoryType          from '@constants/ProductSubCategoryType';
import Routes                          from '@constants/Routes';
import TabIndices                      from '@constants/TabIndices';
import TestIds                         from '@constants/TestIds';
import StateHelper                     from '@helper/State';

import styles                      from './styles.module.scss';
import FullIpAccessoriesTable      from '../FullIpAccessoriesTable';
import FullIpEasyLineTable         from '../FullIpEasylineTable';
import FullIpFlexlineTable         from '../FullIpFlexlineTable';
import FullIpMatrixManagementTable from '../FullIpMatrixManagementTable';
import FullIpMedia4ConnectTable    from '../FullIpMedia4ConnectTable';
import FullIpScalablelineTable     from '../FullIpScalablelineTable';
import FullIpUsbTable              from '../FullIpUsbTable';
import MainLevelTabButton          from '../MainLevelTabButton';
import MainLevelTabList            from '../MainLevelTabList';
import SubLevelTabButton           from '../SubLevelTabButton';
import SubLevelTabList             from '../SubLevelTabList';
import tabPaneStyle                from '../TabPanel/styles.module.scss';
import Tabs                        from '../Tabs';

class Component extends React.Component {
    mainTabChanged = (tabIndex) => {
        this.props.setActiveMainTabIndex({
            activeMainTabIndex: tabIndex,
        });
    };

    // https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-847
    productIsSelected = () => {
        if (!this.props.selectedProductMeta) {
            return false;
        }

        if (!this.props.selectedProductMeta.categoryType) {
            return false;
        }

        if (!this.props.pathname.startsWith(Routes.designerProductDetails)) {
            return false;
        }

        return true;
    };

    selectedProductIsSubCategoryType = (subCategoryType) => {
        if (!this.productIsSelected()) {
            return false;
        }

        if (!this.props.selectedProduct.productData) {
            return false;
        }

        return this.props.selectedProduct.productData.subCategoryType === subCategoryType;
    };

    render() {
        const productIsSelected = this.productIsSelected();

        return (
            <div className={styles.fullIpProductBrowser}>
                <Tabs
                    selectedIndex={this.props.activeMainTabIndex}
                    onSelect={this.mainTabChanged}
                >
                    {this.renderMainLevelTabList(productIsSelected)}
                    {this.renderFullHdTabPanel()}
                    {this.render4KTabPanel()}
                </Tabs>
            </div>
        );
    }

    renderFullHdTabPanel = () => {
        const activeSubTabFullHdIndex = this.props.activeSubTabFullHdIndex;

        return (
            <TabPanel className={tabPaneStyle.tabPanel}>
                <Tabs
                    selectedIndex={activeSubTabFullHdIndex}
                    onSelect={this.fullIpFullHdSubTabChanged}
                >
                    <SubLevelTabList>
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonFullHdEasyline}
                            disabled={false}
                            selected={activeSubTabFullHdIndex === TabIndices.subTabFullHdEasyline}
                            text={I18n.t('easyline')}
                        />
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonFullHdFlexline}
                            disabled={false}
                            selected={activeSubTabFullHdIndex === TabIndices.subTabFullHdFlexline}
                            text={I18n.t('flexline')}
                        />
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonFullHdUsb}
                            disabled={false}
                            selected={activeSubTabFullHdIndex === TabIndices.subTabFullHdUsb}
                            text={I18n.t('usb')}
                        />
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonFullHdAccessories}
                            disabled={false}
                            selected={activeSubTabFullHdIndex === TabIndices.subTabFullHdAccessories}
                            text={I18n.t('accessories')}
                        />
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonFullHdMatrixManagement}
                            disabled={false}
                            selected={activeSubTabFullHdIndex === TabIndices.subTabFullHdMatrixManagement}
                            text={I18n.t('matrixManagement')}
                        />
                    </SubLevelTabList>
                    <TabPanel>
                        <FullIpEasyLineTable />
                    </TabPanel>
                    <TabPanel>
                        <FullIpFlexlineTable />
                    </TabPanel>
                    <TabPanel>
                        <FullIpUsbTable />
                    </TabPanel>
                    <TabPanel>
                        <FullIpAccessoriesTable productSubCategoryType={ProductSubCategoryTypes.fullIpFullHd} />
                    </TabPanel>
                    <TabPanel>
                        <FullIpMatrixManagementTable productSubCategoryType={ProductSubCategoryTypes.fullIpFullHd} />
                    </TabPanel>
                </Tabs>
            </TabPanel>
        );
    };

    render4KTabPanel = () => {
        const activeSubTab4KIndex = this.props.activeSubTab4KIndex;

        return (
            <TabPanel className={tabPaneStyle.tabPanel}>
                <Tabs
                    selectedIndex={activeSubTab4KIndex}
                    onSelect={this.fullIp4KTabChanged}
                >
                    <SubLevelTabList>
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButton4KMedia4Connect}
                            disabled={false}
                            selected={activeSubTab4KIndex === TabIndices.subTab4KMedia4Connect}
                            text={I18n.t('media4Connect')}
                        />
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButton4KScalableline}
                            disabled={false}
                            selected={activeSubTab4KIndex === TabIndices.subTab4KScalableline}
                            text={I18n.t('scalableline')}
                        />
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButton4KAccessories}
                            disabled={false}
                            selected={activeSubTab4KIndex === TabIndices.subTab4KAccessories}
                            text={I18n.t('accessories')}
                        />
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButton4KMatrixManagement}
                            disabled={false}
                            selected={activeSubTab4KIndex === TabIndices.subTab4KMatrixManagement}
                            text={I18n.t('matrixManagement')}
                        />
                    </SubLevelTabList>
                    <TabPanel>
                        <FullIpMedia4ConnectTable />
                    </TabPanel>
                    <TabPanel>
                        <FullIpScalablelineTable />
                    </TabPanel>
                    <TabPanel>
                        <FullIpAccessoriesTable productSubCategoryType={ProductSubCategoryTypes.fullIp4K} />
                    </TabPanel>
                    <TabPanel>
                        <FullIpMatrixManagementTable productSubCategoryType={ProductSubCategoryTypes.fullIp4K} />
                    </TabPanel>
                </Tabs>
            </TabPanel>
        );
    };

    renderMainLevelTabButton = (mainTabIndex, text, productSubCategoryType, productIsSelected) => {
        const disabled = productIsSelected && !this.selectedProductIsSubCategoryType(productSubCategoryType);

        return (
            <MainLevelTabButton
                testId={`main-level-tab-button-extender-${productSubCategoryType}`}
                disabled={disabled}
                selected={this.props.activeMainTabIndex === mainTabIndex}
                text={text}
            />
        );
    };

    renderMainLevelTabList = (productIsSelected) => {
        return (
            <MainLevelTabList>
                {
                    this.renderMainLevelTabButton(
                        TabIndices.mainFullIpFullHdTab,
                        I18n.t('mainFullIpFullHdTab'),
                        ProductSubCategoryType.none,
                        productIsSelected,
                    )
                }
                {
                    this.renderMainLevelTabButton(
                        TabIndices.mainFullIp4KTab,
                        I18n.t('mainFullIp4KTab'),
                        ProductSubCategoryType.none,
                        productIsSelected,
                    )
                }
            </MainLevelTabList>
        );
    };

    /**
     * @returns {boolean|*}
     * @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-327
     */
    shouldEnableNonChassisTabs = () => {
        const productIsSelected  = this.productIsSelected();
        const singleOrderContext = this.props.pathname === Routes.designerProductSingleOrders;

        return (
            singleOrderContext ||
            productIsSelected
        );
    };

    fullIpFullHdSubTabChanged = (tabIndex) => {
        this.props.setActiveSubTabFullHdIndex({
            activeSubTabFullHdIndex: tabIndex,
        });
    };

    fullIp4KTabChanged = (tabIndex) => {
        this.props.setActiveSubTab4KIndex({
            activeSubTab4KIndex: tabIndex,
        });
    };
}

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        activeMainTabIndex:      state.fullIpProductBrowser.activeMainTabIndex,
        activeSubTabFullHdIndex: state.fullIpProductBrowser.activeSubTabFullHdIndex,
        activeSubTab4KIndex:     state.fullIpProductBrowser.activeSubTab4KIndex,
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-327
        pathname:            state.router.location.pathname,
        selectedProduct:     StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
        selectedProductMeta: state.activeProject.selectedProduct,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(FullIpProductBrowserActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

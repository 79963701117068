//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import ProductCategoryType      from '@constants/ProductCategoryType';
import ProductSlotType          from '@constants/ProductSlotType';
import ProductSubCategoryType   from '@constants/ProductSubCategoryType';
import RenderModes              from '@constants/RenderModes';
import StateHelper              from '@helper/State';

import styles                       from './styles.module.scss';
import Input                        from '../Input';
import InputType                    from '../Input/InputType';
import ProductDataSpecificationList from '../ProductDataSpecificationList';
import RightPaneProductTitle        from '../RightPaneProductTitle';
import SlotSfpList                  from '../SlotSfpList';
import SubSlotList                  from '../SubSlotList';

class Component extends React.Component {
    customNameChanged = (event) => {
        this.props.setSelectedSlotCustomName({
            customName: event.target.value,
        });
    };

    deleteButtonPressed = () => {
        this.props.deleteSlot({
            slotIndex: this.props.selectedSlotMeta.index,
            slotType:  this.props.selectedSlotMeta.slotType,
        });
    };

    extenderIdChanged = (event) => {
        this.props.setSelectedSlotExtenderId({
            extenderId: event.target.value,
        });
    };

    otherCommentChanged = (event) => {
        this.props.setSelectedSlotOtherComment({
            otherComment: event.target.value,
        });
    };

    render() {
        if (this.props.selectedSlot && this.props.selectedSlot.productData) {
            return (
                <div className={styles.slotDetailsPane}>
                    {this.renderTitle()}
                    {this.renderCustomNameInput()}
                    {this.renderOtherCommentInput()}
                    {this.renderExtenderIdInput()}
                    {this.renderSfps()}
                    {this.renderSubSlots()}
                    {this.renderSpecification()}
                </div>
            );
        }

        return null;
    }

    renderCustomNameInput = () => {
        return (
            <Input
                placeholder={I18n.t('enterTextPlaceholder')}
                text={I18n.t('customName')}
                tooltip={I18n.t('customNameTip')}
                type={InputType.text}
                value={this.props.selectedSlot.customName}
                valueChanged={this.customNameChanged}
            />
        );
    };

    renderExtenderIdInput = () => {
        const productData = _.get(this.props, 'selectedSlot.productData');

        if (_.get(productData, 'categoryType') === ProductCategoryType.extender) {
            const isMainboard   = _.get(productData, 'productSlotType') === ProductSlotType.mainboard;
            const isUSB20Module = _.get(productData, 'productSlotType') === ProductSlotType.usb20Module;

            if (isMainboard || isUSB20Module) {
                return (
                    <Input
                        placeholder={I18n.t('enterTextPlaceholder')}
                        text={I18n.t('extenderId')}
                        tooltip={I18n.t(isMainboard ?
                            'extenderIdTipMainboard' :
                            'extenderIdTipUSB20')}
                        type={InputType.text}
                        value={this.props.selectedSlot.extenderId}
                        valueChanged={this.extenderIdChanged}
                    />
                );
            }
        }

        return null;
    };

    renderOtherCommentInput = () => {
        if (this.props.otherCommentFieldVisible) {
            return (
                <Input
                    placeholder={I18n.t('enterTextPlaceholder')}
                    text={I18n.t('otherComment')}
                    tooltip={I18n.t('otherCommentTip')}
                    type={InputType.text}
                    value={this.props.selectedSlot.otherComment}
                    valueChanged={this.otherCommentChanged}
                />
            );
        }

        return null;
    };

    renderSfps = () => {
        if (this.props.selectedSlot.productData.configurableSfpCount > 0) {
            return (
                <SlotSfpList />
            );
        }

        return null;
    };

    renderSubSlots = () => {
        const { productData } = this.props.selectedProduct;

        if (
            productData.categoryType === ProductCategoryType.matrix &&
            productData.subCategoryType === ProductSubCategoryType.flex
        ) {
            const availableRowCount = _.get(productData, 'layoutDefinition.availableRowCount', 0);

            if (
                availableRowCount > 0 &&
                productData.mutable
            ) {
                return (
                    <SubSlotList />
                );
            }
        }
    };

    renderSpecification = () => {
        return (
            <ProductDataSpecificationList
                data={this.props.selectedSlot.productData}
            />
        );
    };

    renderTitle = () => {
        const productData = _.get(this, 'props.selectedSlot.productData', null);
        const renderMode  = _.get(productData, 'renderMode');
        const title       = (
            renderMode === RenderModes.default ?
                productData.displayPartNumber :
                I18n.t('immutableIOBoard')
        );

        return (
            <RightPaneProductTitle
                deleteButtonPressed={this.deleteButtonPressed}
                title={title}
            />
        );
    };
}

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        otherCommentFieldVisible: state.settings.otherCommentFieldVisible,
        selectedProduct:          StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
        selectedSlot:             StateHelper.getSelectedSlot(state),
        selectedSlotMeta:         state.activeProject.selectedSlot,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

/**
 * This class basically exists as an workaround for
 * @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-761
 */
export default class KeyHandler {
    static keyHandlerInstance = null;

    altKeyPressed = false;

    /**
     *
     * @returns {string|null}
     */
    static getInstance() {
        if (KeyHandler.keyHandlerInstance == null) {
            KeyHandler.keyHandlerInstance = new KeyHandler();
        }

        return KeyHandler.keyHandlerInstance;
    }

    getAltKeyPressed() {
        return this.altKeyPressed;
    }

    setAltKeyPressed(altKeyPressed) {
        console.log('KeyHandler: setAltKeyPressed', altKeyPressed);

        this.altKeyPressed = altKeyPressed;
    }
}

import React from 'react';

import I18n from 'i18next';
import { withTranslation } from 'react-i18next';

import Overlays from '@constants/Overlays';

import Overlay from '../Overlay';
import BackType from '../Overlay/BackType';
import OverlayType from '../Overlay/OverlayType';
import TextHeadline from '../TextHeadline';

const Component = () => (
    <Overlay
        backType={BackType.forceCloseOverlay}
        cancelButtonTextKey={null}
        id={Overlays.exportSuccess}
        overlayType={OverlayType.prompt}
        title={I18n.t('exportDownloadSuccessTitle')}
    >
        <TextHeadline text={I18n.t('exportDownloadSuccessText')} />
    </Overlay>
);

export default withTranslation()(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

class Cast {
    static array(value) {
        if (!_.isArray(value)) {
            return [value];
        }

        return value;
    }

    static bool(value) {
        const castedValue = !!value;

        return castedValue;
    }

    static int(value) {
        const castedValue = parseInt(value, 10) || 0;

        return castedValue;
    }

    static string(value) {
        const castedValue = `${value}`;

        return castedValue;
    }
}

export default Cast;

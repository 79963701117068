//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import Overlays                 from '@constants/Overlays';
import DataProvider             from '@helper/DataProvider';
import StateHelper              from '@helper/State';

import Overlay      from '../Overlay';
import OverlayType  from '../Overlay/OverlayType';
import TextHeadline from '../TextHeadline';

const Component = ({
    selectedProduct,
    nextProductId,
    setSelectedProductDataCheckCancel,
    setSelectedProductDataCheckConfirm,
}) => {
    const cancelButtonPressed = () => {
        setSelectedProductDataCheckCancel();
    };
    const getText             = () => {
        const selectedProductData = selectedProduct.productData;
        const nextProductData     = DataProvider.getById(nextProductId);
        const translationData     = {
            fromName:  selectedProductData.displayPartNumber,
            fromSlots: selectedProductData.slotCount,
            toName:    nextProductData.displayPartNumber,
            toSlots:   nextProductData.slotCount,
        };

        if (selectedProductData.mutable && !nextProductData.mutable) {
            return I18n.t('setSelectedDataConfirmTextToImmutable', translationData);
        }

        return I18n.t('setSelectedDataConfirmText', translationData);
    };
    const okButtonPressed     = () => {
        setSelectedProductDataCheckConfirm({
            productId: nextProductId,
        });
    };

    if (selectedProduct && nextProductId) {
        return (
            <Overlay
                allowDontShowAgain={true}
                closeButtonPressed={cancelButtonPressed}
                id={Overlays.setSelectedDataConfirm}
                okButtonPressed={okButtonPressed}
                okButtonTextKey={'setSelectedDataConfirmButton'}
                overlayType={OverlayType.prompt}
                title={I18n.t('setSelectedDataConfirmTitle')}
            >
                <TextHeadline text={getText()} />
            </Overlay>
        );
    }

    return null;
};

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        nextProductId:   state.activeProject.setSelectedProductIdConfirm,
        selectedProduct: StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames          from 'classnames';
import { withTranslation } from 'react-i18next';

import DeviceTypeShortcuts from '@constants/DeviceTypeShortcuts';
import SelectionHelper     from '@helper/SelectionHelper';

import DeviceTypeLabelAlignment from './DeviceTypeLabelAlignment';
import styles                   from './styles.module.scss';
import ProductDataValueLabel    from '../ProductDataValueLabel';
import PropTypes                from '../PropTypes';

const Component = ({
    alignment,
    deviceType,
    value,
}) => {
    const getTitle = () => {
        return SelectionHelper.getTranslation(
            deviceType,
            {
                [DeviceTypeShortcuts.cpuUnit]: 'cpuUnit',
                [DeviceTypeShortcuts.conUnit]: 'conUnit',
            },
        );
    };

    if (deviceType) {
        const title = getTitle();

        if (!title) {
            console.warn('Unsupported device type', deviceType);
        }

        return (
            <span
                className={classNames(
                    styles.deviceTypeLabel,
                    SelectionHelper.get(
                        alignment,
                        {
                            [DeviceTypeLabelAlignment.left]:  styles.deviceTypeLabelLeft,
                            [DeviceTypeLabelAlignment.right]: styles.deviceTypeLabelRight,
                        },
                    ),
                )}
                title={title}
            >
                <span className={styles.deviceTypeText}>
                    {title}
                </span>
            </span>
        );
    }

    return (
        <ProductDataValueLabel value={value} />
    );
};

Component.propTypes = {
    alignment:  PropTypes.oneOf(Object.values(DeviceTypeLabelAlignment)),
    deviceType: PropTypes.string,
};

Component.defaultProps = {
    alignment:  null,
    deviceType: null,
};

export default withTranslation()(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import I18n                from 'i18next';
import _                   from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect }         from 'react-redux';

import ProductDataKeys             from '@constants/ProductDataKeys';
import ProductDataKeysSortPriority from '@constants/ProductDataKeysSortPriority';
import Routes                      from '@constants/Routes';
import StateHelper                 from '@helper/State';

import PropTypes                 from '../PropTypes';
import SpecificationList         from '../SpecificationList';
import SpecificationListItem     from '../SpecificationListItem';
import SpecificationListItemMode from '../SpecificationListItem/SpecificationListItemMode';

const allowProductDataKeys = [
    ProductDataKeys.AUDIO,
    ProductDataKeys.CAN_BE_ORDERED_EMPTY,
    ProductDataKeys.DATA_TRANSFER,
    ProductDataKeys.DEVICE_TYPE,
    ProductDataKeys.DESCRIPTION,
    ProductDataKeys.HAS_INTERNAL_PSU,
    ProductDataKeys.HEAT_DISSIPATION,
    ProductDataKeys.IS_REDUNDANT_STRING,
    ProductDataKeys.MAXIMUM_POWER_CONSUMPTION_IN_MILLIAMPS,
    ProductDataKeys.NUMBER_OF_MONITORS,
    ProductDataKeys.OPTIONAL_FAN_COUNT,
    ProductDataKeys.OPTIONAL_PSU_COUNT,
    ProductDataKeys.PORT_COUNT,
    ProductDataKeys.POWER_CONSUMPTION_IN_MILLIAMPS,
    ProductDataKeys.POWER_IN_MILLIWATTS,
    ProductDataKeys.RACK_UNITS,
    ProductDataKeys.RECOMMENDED_POWER_CONSUMPTION_IN_MILLIAMPS,
    ProductDataKeys.RESOLUTION,
    ProductDataKeys.SERIAL,
    ProductDataKeys.SUPPORTS_PIGGY_BACK,
    ProductDataKeys.USB,
    ProductDataKeys.VIDEO_CONNECTOR,
    ProductDataKeys.WIDTH_IN_MILLIMETERS,
];

class Component extends React.Component {
    getModeForProductDataKey = (productKey, productData) => {
        switch (productKey) {
            case ProductDataKeys.CURRENT_POWER_CONSUMPTION_IN_MILLIAMPS:
                if (_.get(this, 'props.selectedProduct.powerConsumptionInMilliAmps') > productData) {
                    return SpecificationListItemMode.defaultAlert;
                }

                return SpecificationListItemMode.default;

            case ProductDataKeys.DESCRIPTION:
                return SpecificationListItemMode.longValue;

            default:
                return SpecificationListItemMode.default;
        }
    };

    getSpecificationListItem = (productDataKey, productData) => {
        return (
            <SpecificationListItem
                dataKey={productDataKey}
                data={this.props.data}
                key={productDataKey}
                mode={this.getModeForProductDataKey(productDataKey, productData)}
                title={this.getTitleForProductDataKey(productDataKey)}
                tooltip={this.getTooltipForProductDataKey(productDataKey)}
                value={this.getValueForProductDataKey(productDataKey, productData)}
                valuePostfix={this.getValuePostfixForProductDataKey(productDataKey)}
            />
        );
    };

    getSortedProductDataKeys = () => {
        if (_.isObject(this.props.data)) {
            const productDataKeys       = Object.keys(this.props.data);
            const sortedProductDataKeys = _.clone(productDataKeys);

            sortedProductDataKeys.sort((leftProductKey, rightProductKey) => {
                const leftIndex  = ProductDataKeysSortPriority.indexOf(leftProductKey);
                const rightIndex = ProductDataKeysSortPriority.indexOf(rightProductKey);

                // @formatter:off
                if (leftIndex < rightIndex) return -1;

                if (leftIndex > rightIndex) return 1;
                // @formatter:on

                return 0;
            });

            return sortedProductDataKeys;
        }

        return [];
    };

    getTitleForProductDataKey = (productKey) => {
        switch (productKey) {
            // @formatter:off
            case ProductDataKeys.AUDIO:                                           return I18n.t('audio');
            case ProductDataKeys.CAN_BE_ORDERED_EMPTY:                            return I18n.t('canBeOrderedEmpty');
            case ProductDataKeys.CURRENT_HEAT_DISSIPATION:                        return I18n.t('heatDissipation');
            case ProductDataKeys.CURRENT_POWER_CONSUMPTION_IN_MILLIAMPS:          return I18n.t('powerConsumptionCurrent');
            case ProductDataKeys.DATA_TRANSFER:                                   return I18n.t('dataTransfer');
            case ProductDataKeys.DEPTH_IN_MILLIMETERS:                            return I18n.t('depth');
            case ProductDataKeys.DESCRIPTION:                                     return I18n.t('description');
            case ProductDataKeys.DEVICE_TYPE:                                     return I18n.t('deviceType');
            case ProductDataKeys.EFFICIENCY:                                      return I18n.t('efficiency');
            case ProductDataKeys.FAN_POWER_IN_MILLIWATTS:                         return I18n.t('fanPower');
            case ProductDataKeys.HAS_INTERNAL_PSU:                                return I18n.t('hasInternalPsu');
            case ProductDataKeys.HEAT_DISSIPATION:                                return I18n.t('heatDissipation');
            case ProductDataKeys.HEIGHT_IN_MILLIMETERS:                           return I18n.t('height');
            case ProductDataKeys.HEIGHT_UNITS:                                    return I18n.t('heightUnits');
            case ProductDataKeys.IS_REDUNDANT_STRING:                             return I18n.t('redundant');
            case ProductDataKeys.LINK_RESOLUTION:                                 return I18n.t('linkResolution');
            case ProductDataKeys.MAXIMUM_POWER_CONSUMPTION_IN_MILLIAMPS:          return I18n.t('powerConsumptionMaximum');
            case ProductDataKeys.NUMBER_OF_MONITORS:                              return I18n.t('numberOfMonitors');
            case ProductDataKeys.OPTIONAL_FAN_COUNT:                              return I18n.t('optionalFanCount');
            case ProductDataKeys.OPTIONAL_PSU_COUNT:                              return I18n.t('optionalPsuCount');
            case ProductDataKeys.PORT_COUNT:                                      return I18n.t('portCount');
            case ProductDataKeys.POWER_CONSUMPTION_IN_MILLIAMPS:                  return I18n.t('powerConsumption');
            case ProductDataKeys.POWER_IN_MILLIWATTS:                             return I18n.t('power');
            case ProductDataKeys.RACK_UNITS:                                      return I18n.t('rackUnits');
            case ProductDataKeys.RECOMMENDED_POWER_CONSUMPTION_IN_MILLIAMPS:      return I18n.t('powerConsumptionRecommended');
            case ProductDataKeys.REDUNDANCY_BOARD_POWER_CONSUMPTION_IN_MILLIAMPS: return I18n.t('powerConsumptionRedundancyBoard');
            case ProductDataKeys.RESOLUTION:                                      return I18n.t('resolution');
            case ProductDataKeys.SERIAL:                                          return I18n.t('serial');
            case ProductDataKeys.SUPPORTS_PIGGY_BACK:                             return I18n.t('supportsPiggyback');
            case ProductDataKeys.USB:                                             return I18n.t('usb');
            case ProductDataKeys.VIDEO_CONNECTOR:                                 return I18n.t('videoConnector');
            case ProductDataKeys.WIDTH_IN_MILLIMETERS:                            return I18n.t('chassisSizeShort');

            // @formatter:on
            default:
                console.warn('Missing translation for product key: ', productKey);

                return '';
        }
    };

    getTooltipForProductDataKey = (productKey) => {
        switch (productKey) {
            // @formatter:off
            case ProductDataKeys.WIDTH_IN_MILLIMETERS:                            return I18n.t('chassisSize');
            // @formatter:on
        }

        return this.getTitleForProductDataKey(productKey);
    };

    getValueForProductDataKey = (productKey, value) => {
        switch (productKey) {
            case ProductDataKeys.DESCRIPTION:
                return value[I18n.t('descriptionKeyFullShort')];

            case ProductDataKeys.CAN_BE_ORDERED_EMPTY:
            case ProductDataKeys.HAS_INTERNAL_PSU:
            case ProductDataKeys.SUPPORTS_PIGGY_BACK:
                return (
                    value ?
                        I18n.t('yes') :
                        I18n.t('no')
                );

            case ProductDataKeys.WIDTH_IN_MILLIMETERS:
                return [
                    this.props.data[ProductDataKeys.WIDTH_IN_MILLIMETERS],
                    this.props.data[ProductDataKeys.HEIGHT_IN_MILLIMETERS],
                    this.props.data[ProductDataKeys.DEPTH_IN_MILLIMETERS],
                ].join('x');

            case ProductDataKeys.CURRENT_POWER_CONSUMPTION_IN_MILLIAMPS:
                return this.props.selectedProduct.powerConsumptionInMilliAmps;

            case ProductDataKeys.CURRENT_HEAT_DISSIPATION:
                return (
                    this.props.selectedProduct.heatDissipation.chassis +
                    (
                        this.props.selectedProduct.heatDissipation.externalPsu ?
                            (
                                ` (${
                                    this.props.selectedProduct.heatDissipation.externalPsu
                                } ${
                                    I18n.t('heatDissipationExternal')
                                })`
                            ) :
                            ''
                    )
                );

            case ProductDataKeys.HEAT_DISSIPATION: {
                const heatDissipation = _.get(this, 'props.selectedProduct.heatDissipation.chassis', 0);

                if (heatDissipation) {
                    return `${heatDissipation} W`;
                }

                return value;
            }

            default:
                return value;
        }
    };

    getValuePostfixForProductDataKey = (productKey) => {
        // The current post fixes are handled in
        // @see MilliampsLabel
        // @see MilliwattsLabel

        switch (productKey) {
            case ProductDataKeys.DEPTH_IN_MILLIMETERS:
            case ProductDataKeys.HEIGHT_IN_MILLIMETERS:
            case ProductDataKeys.WIDTH_IN_MILLIMETERS:
                return I18n.t('millimeterShort');

            case ProductDataKeys.CURRENT_HEAT_DISSIPATION:
                return I18n.t('wattShort');
        }

        return '';
    };

    render() {
        const productDataKeys = this.getSortedProductDataKeys();
        const items           = [];

        console.log('ProductDataSpecificationList:', 'this.props.selectedSlot.productData', this.props.data);

        for (const productDataKey of productDataKeys) {
            const productData  = this.props.data[productDataKey];
            const shouldRender = this.shouldRenderProductData(productDataKey, productData);

            if (shouldRender) {
                console.log('SlotDetailsPane: renderSpecification', productDataKey);

                // Check whether we are not in the single order mode
                if (this.props.pathname !== Routes.designerProductSingleOrders) {
                    if (productDataKey === ProductDataKeys.MAXIMUM_POWER_CONSUMPTION_IN_MILLIAMPS) {
                        // We manually insert this item here since the current power consumption is
                        // not part of the "real" product data because its calculated depending on
                        // the configured slots
                        items.push(this.getSpecificationListItem(
                            ProductDataKeys.CURRENT_POWER_CONSUMPTION_IN_MILLIAMPS,
                            this.props.data[ProductDataKeys.RECOMMENDED_POWER_CONSUMPTION_IN_MILLIAMPS],
                        ));

                        items.push(this.getSpecificationListItem(
                            ProductDataKeys.CURRENT_HEAT_DISSIPATION,
                            productData,
                        ));
                    }
                }

                items.push(this.getSpecificationListItem(
                    productDataKey,
                    productData,
                ));
            }
        }

        return (
            <SpecificationList
                title={I18n.t('specification')}
            >
                {items}
            </SpecificationList>
        );
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        // For some reason we have to return true here otherwise the component will render the
        // german language initially. This also requires the withTranslation HOC.
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-1078
        return true;
    }

    shouldRenderProductData = (productDataKey, productData) => {
        const indexInWhiteList = allowProductDataKeys.indexOf(productDataKey);

        return (
            indexInWhiteList > -1 &&
            (
                productData &&
                (
                    !_.isArray(productData) ||
                    productData.length > 0
                )
            )
        );
    };
}

Component.propTypes = {
    data: PropTypes.object,
};

Component.defaultProps = {
    data: {},
};

const mapStateToProps = (state) => {
    return {
        language:        state.settings.language,
        pathname:        state.router.location.pathname,
        selectedProduct: StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
    };
};

export default connect(
    mapStateToProps,
    null,
)(withTranslation()(Component));

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions }  from '@slices/activeProject';
import ProductCategoryType       from '@constants/ProductCategoryType';
import TestIds                   from '@constants/TestIds';
import { isKVMTecEnabled }       from '@helper/Configuration';
import { DesignerLayoutActions } from '@slices/designerLayout';

import styles             from './styles.module.scss';
import SegmentControl     from '../SegmentControl';
import SegmentControlMode from '../SegmentControl/SegmentControlMode';

const Component = ({
    setLeftPaneSetVisibility,
    setActiveProductCategory,
}) => {
    const getSegments    = () => {
        const segments = [
            {
                key:   ProductCategoryType.extender,
                title: I18n.t('extender'),
            },
            {
                key:   ProductCategoryType.matrix,
                title: I18n.t('matrixSwitch'),
            },
        ];

        if (isKVMTecEnabled()) {
            segments.push({
                key:   ProductCategoryType.fullIp,
                title: I18n.t('fullIpAndSwitching'),
            });
        }

        return segments;
    };
    const segmentClicked = (segmentIndex, segment) => {
        setLeftPaneSetVisibility({
            visible: true,
        });

        setActiveProductCategory({
            productCategoryType: segment.key,
        });
    };

    return (
        <div
            className={styles.productCategorySelectionPane}
            data-testId={TestIds.productCategorySelectionPane}
        >
            <h3>{I18n.t('selectProductCategoryText')}</h3>
            <SegmentControl
                mode={SegmentControlMode.button}
                segments={getSegments()}
                segmentClicked={segmentClicked}
            />
        </div>
    );
};

Component.propTypes = {};

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(ActiveProjectActions, DesignerLayoutActions), dispatch);
export default connect(null, mapDispatchToProps)(withTranslation()(Component));

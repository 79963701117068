//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { useDispatch } from 'react-redux';

import PartNumberLabel       from '@components/PartNumberLabel';
import PropTypes             from '@components/PropTypes';
import { useActiveProject }  from '@slices/activeProject';
import { useProductPreview } from '@slices/productPreview';

const Component = ({
    value,
}) => {
    const dispatch                            = useDispatch();
    const activeProject                       = useActiveProject(dispatch);
    const productPreview                      = useProductPreview(dispatch);
    const onDataSheetButtonPressed            = () => {
        activeProject.openDownloadOverlay({
            partNumber: value,
        });
    };
    const onProductImagePreviewIconMouseEnter = () => {
        productPreview.enablePreview({
            productId: value,
        });
    };
    const onProductImagePreviewIconMouseLeave = () => {
        productPreview.disablePreview();
    };

    return (
        <PartNumberLabel
            dataSheetButtonPressed={onDataSheetButtonPressed}
            onProductImagePreviewIconMouseHover={onProductImagePreviewIconMouseEnter}
            onProductImagePreviewIconMouseLeave={onProductImagePreviewIconMouseLeave}
            partNumber={value}
        />
    );
};

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames     from 'classnames';
import _              from 'lodash';
import { withRouter } from 'react-router';

import styles    from './styles.module.scss';
import CheckBox  from '../CheckBox';
import PropTypes from '../PropTypes';

const Component = ({
    checkboxChanged = _.noop,
    checked         = false,
    className       = '',
    label           = '',
    showCheckbox    = false,
    children,
}) => {
    const renderLabel    = () => {
        if (!showCheckbox) {
            return (
                <div className={styles.label}>{label}</div>
            );
        }
        return null;
    };
    const renderCheckbox = () => {
        if (showCheckbox) {
            return (
                <div className={styles.checkbox}>
                    <CheckBox
                        checked={checked}
                        noMarginBottom={true}
                        valueChanged={checkboxChanged}
                    />
                </div>
            );
        }
        return null;
    };

    return (
        <div className={classNames(styles.indexAndCheckboxWrapperContainer, className)}>
            {renderLabel()}
            {renderCheckbox()}
            <div
                className={classNames({
                    [styles.blockEvents]: showCheckbox,
                })}
            >
                {children}
            </div>
        </div>
    );
};

Component.propTypes = {
    checkboxChanged: PropTypes.func,
    checked:         PropTypes.bool,
    className:       PropTypes.string,
    label:           PropTypes.string,
    showCheckbox:    PropTypes.bool,
};

Component.defaultProps = {
    className:       '',
    checkboxChanged: _.noop,
    checked:         false,
    label:           '',
    showCheckbox:    false,
};

export default withRouter(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

class AssemblyOrder {
    static ORDER_123 = '123';

    static ORDER_135 = '135';

    static getDefaultAssemblyOrder() {
        return AssemblyOrder.ORDER_123;
    }

    static getChoices() {
        return [
            this.ORDER_123,
            this.ORDER_135,
        ];
    }
}

export default AssemblyOrder;

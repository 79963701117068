//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React, { useState, useEffect } from 'react';

import I18n                from 'i18next';
import { withTranslation } from 'react-i18next';
import { connect }         from 'react-redux';

import DataTransferTypes from '@constants/DataTransferTypes';
import Overlays          from '@constants/Overlays';

import Overlay        from '../Overlay';
import OverlayType    from '../Overlay/OverlayType';
import Table          from '../Table';
import DefaultColumns from '../Table/DefaultColumns';
import withPortCounts from '../WithPortCounts';

const Component = (props) => {
    const {
        hasPorts,
        ports,
        closeButtonPressed,
    }                      = props;
    const getColumnConfiguration = () => {
        const defaultColumns = DefaultColumns.get(null, null, Overlays.portOverview);

        return [
            defaultColumns.dataTransfer,
            defaultColumns.extenderPorts,
            defaultColumns.matrixPorts,
            defaultColumns.hint,
        ];
    };
    const recalculateData        = (props) => {
        if (!props) {
            props = {};
        }

        const data = [];

        if (props.ports && props.ports.details && props.ports.details.extender && props.ports.details.matrix) {
            const dataTransferTypes = Object.values(DataTransferTypes);

            for (const dataTransferType of dataTransferTypes) {
                const rowData = {
                    extenderPorts: props.ports.details.extender[dataTransferType],
                    hintText:      '',
                    matrixPorts:   props.ports.details.matrix[dataTransferType],
                    dataTransfer:  dataTransferType,
                };

                if (rowData.matrixPorts < rowData.extenderPorts) {
                    rowData.hintText = I18n.t('portOverviewErrorTooLessMatrixPorts');
                }

                data.push(rowData);
            }
        }

        return data;
    };
    const [data, setData]        = useState(recalculateData());

    useEffect(() => {
        setData(recalculateData(props));
    }, [hasPorts, ports]);

    const renderTable = () => {
        return (
            <Table
                applyLimitations={false}
                columnConfiguration={getColumnConfiguration()}
                dragDropEnabled={false}
                rawData={data}
            />
        );
    };

    return (
        <Overlay
            closeButtonPressed={closeButtonPressed}
            id={Overlays.portOverview}
            overlayType={OverlayType.table}
            title={I18n.t('portOverview')}
        >
            {renderTable()}
        </Overlay>
    );
};

const mapStateToProps = (state) => (
    {
        products: state.activeProject.products,
    }
);

export default connect(mapStateToProps, null)(withTranslation()(withPortCounts(Component, true)));

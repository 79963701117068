//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice } from '@reduxjs/toolkit';
import { bindActionCreators } from 'redux';
import update from 'immutability-helper';
import { LOCATION_CHANGE } from 'connected-react-router';
import String from '@helper/String';

const initialState = {
    comment: '',
    company: '',
    eMailAddress: '',
    extendedWarranty: false,
    firstName: '',
    lastName: '',
    lastSubmitFailed: false,
    phoneNumber: '',
    spareParts: false,
    submitting: false,
};

const salesMailboxSlice = createSlice({
    name: 'salesMailbox',
    initialState,
    reducers: {
        setLocationChange: (state, action) => {
            return update(state, {
                comment: {
                    $set: '',
                },
                submitting: {
                    $set: false,
                },
            });
        },
        setComment: (state, action) => {
            return update(state, {
                comment: {
                    $set: String.removeIllegalCharacters(action.payload.comment),
                },
            });
        },
        setCompany: (state, action) => {
            return update(state, {
                company: {
                    $set: String.removeIllegalCharacters(action.payload.company),
                },
            });
        },
        setEMailAddress: (state, action) => {
            return update(state, {
                eMailAddress: {
                    $set: String.removeIllegalCharacters(action.payload.eMailAddress),
                },
            });
        },
        setExtendedWarranty: (state, action) => {
            return update(state, {
                extendedWarranty: {
                    $set: action.payload.extendedWarranty,
                },
            });
        },
        setFirstName: (state, action) => {
            return update(state, {
                firstName: {
                    $set: String.removeIllegalCharacters(action.payload.firstName),
                },
            });
        },
        setLastName: (state, action) => {
            return update(state, {
                lastName: {
                    $set: String.removeIllegalCharacters(action.payload.lastName),
                },
            });
        },
        setPhoneNumber: (state, action) => {
            return update(state, {
                phoneNumber: {
                    $set: String.removeIllegalCharacters(action.payload.phoneNumber),
                },
            });
        },
        setServiceLevelAgreement: (state, action) => {
            return update(state, {
                serviceLevelAgreement: {
                    $set: action.payload.serviceLevelAgreement,
                },
            });
        },
        setSpareParts: (state, action) => {
            return update(state, {
                spareParts: {
                    $set: action.payload.spareParts,
                },
            });
        },
        submit: (state, action) => {
            return update(state, {
                submitting: {
                    $set: true,
                },
                lastSubmitFailed: {
                    $set: false,
                },
            });
        },
        submitFailed: (state, action) => {
            return update(state, {
                submitting: {
                    $set: false,
                },
                lastSubmitFailed: {
                    $set: true,
                },
            });
        },
        submitSucceeded: (state, action) => {
            return update(state, {
                submitting: {
                    $set: false,
                },
            });
        },
    },
});

export const SalesMailboxActions = salesMailboxSlice.actions;

export const SalesMailboxReducer = salesMailboxSlice.reducer;

export const useSalesMailbox = (dispatch) => bindActionCreators(SalesMailboxActions, dispatch);

export default salesMailboxSlice;
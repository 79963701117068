//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import isTouchDevice          from 'is-touch-device';
import ReactCursorPosition    from 'react-cursor-position';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ProductPreviewActions } from '@slices/productPreview';
import ProductCategoryType       from '@constants/ProductCategoryType';
import ProductSlotType           from '@constants/ProductSlotType';
import DataProvider              from '@helper/DataProvider';

import styles               from './styles.module.scss';
import ProductImageRenderer from '../ProductImageRenderer';
import PropTypes            from '../PropTypes';
import resizeListener       from '../ResizeListener';

const marginToMouse = 10;

class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            productData:   null,
            mousePosition: {
                x: null,
                y: null,
            },
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.previewProductId !== this.props.previewProductId) {
            const productData = DataProvider.getById(nextProps.previewProductId);

            this.setState({
                productData,
            });
        }
    }

    getClassName = () => {
        if (isTouchDevice()) {
            return styles.productPreviewImageWrapperTablet;
        }

        return styles.productPreviewImageWrapper;
    };

    getOverlayStyles = () => {
        if (!isTouchDevice()) {
            const mousePosition             = this.state.mousePosition;
            const overlayStyles             = {
                left: mousePosition.x + marginToMouse,
            };
            const mouseTopPositionInPercent = mousePosition.y / this.props.windowHeight;

            if (mouseTopPositionInPercent > 0.70) {
                overlayStyles.bottom = this.props.windowHeight - mousePosition.y + marginToMouse;
            } else {
                overlayStyles.top = mousePosition.y + marginToMouse;
            }

            return overlayStyles;
        }

        return {};
    };

    getScale = () => {
        const productData = this.state.productData;

        switch (productData.categoryType) {
            case ProductCategoryType.extender:
                return 0.8;

            case ProductCategoryType.matrix:
                if (productData.productSlotType === ProductSlotType.chassis) {
                    if (productData.slotCount >= 84) {
                        // Reduce the image size for big matrix chassis
                        return 0.4;
                    }

                    return 0.7;
                }

                // Increase the size of the smaller matrix cards
                return 1.0;
        }

        return 0.5;
    };

    mousePositionChanged = (elementDimensions) => {
        if (elementDimensions.position) {
            this.setState({
                mousePosition: elementDimensions.position,
            });
        }
    };

    onOverlayClick = () => {
        this.props.disableProductPreview();
    };

    render() {
        return (
            <ReactCursorPosition
                isEnabled={true}
                onPositionChanged={this.mousePositionChanged}
                shouldDecorateChildren={false}
            >
                {this.props.children}
                {this.renderOverlay()}
            </ReactCursorPosition>
        );
    }

    renderOverlay = () => {
        if (this.props.previewProductId && this.state.productData) {
            const overlayStyles = this.getOverlayStyles();
            const className     = this.getClassName();
            const scale         = this.getScale();

            return (
                <div
                    className={className}
                    onClick={this.onOverlayClick}
                    style={overlayStyles}
                >
                    <ProductImageRenderer
                        hideFallbackText={!isTouchDevice()}
                        id={'preview'}
                        isReadOnly={true}
                        productData={this.state.productData}
                        scale={scale}
                    />
                </div>
            );
        }

        return null;
    };
}

Component.propTypes = {
    children: PropTypes.children,
};

Component.defaultProps = {
    children: null,
};

const mapStateToProps = (state) => (
    {
        previewProductId: state.productPreview.productId,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(ProductPreviewActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(resizeListener(Component));

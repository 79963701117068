/**
 * @see also DeviceTypes
 */
const DeviceTypeShortcuts = {
    conUnit: 'R', // Remote (R)
    cpuUnit: 'L', // Local (L)
    kit:     'K', // Kit (K)
};

module.exports = DeviceTypeShortcuts;

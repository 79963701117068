//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React         from 'react';
import { Component } from 'react';

import _                      from 'lodash';
import { isTablet }           from 'react-device-detect';
import { isSafari }           from 'react-device-detect';
import { isIOS }              from 'react-device-detect';
import { Helmet }             from 'react-helmet';
import { connect }            from 'react-redux';
import { withRouter }         from 'react-router-dom';
import SplitPane              from 'react-split-pane';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions }          from '@slices/activeProject';
import ChooseFanTypeOverlay              from '@components/ChooseFanTypeOverlay';
import CloseProjectOverlay               from '@components/CloseProjectOverlay';
import ConfirmOverwriteSlotOverlay       from '@components/ConfirmOverwriteSlotOverlay';
import DeleteModulesOverlay              from '@components/DeleteModulesOverlay';
import DeleteProductOverlay              from '@components/DeleteProductOverlay';
import DeleteSfpOverlay                  from '@components/DeleteSfpOverlay';
import DeleteSingleOrderSlotOverlay      from '@components/DeleteSingleOrderSlotOverlay';
import DeleteSlotOverlay                 from '@components/DeleteSlotOverlay';
import DownloadOverlay                   from '@components/DownloadOverlay';
import DuplicateProductOverlay           from '@components/DuplicateProductOverlay';
import EndOfLifeProductOverlay           from '@components/EndOfLifeProductOverlay';
import ExportOverlay                     from '@components/ExportOverlay';
import ExportSuccessOverlay              from '@components/ExportSuccessOverlay';
import FillSlotsOverlay                  from '@components/FillSlotsOverlay';
import FilterToggleKeyHandler            from '@components/FilterToggleKeyHandler';
import Header                            from '@components/Header';
import HeaderMode                        from '@components/Header/HeaderMode';
import HitLimitationOverlay              from '@components/HitLimitationOverlay';
import ImportSuccessActiveProjectOverlay from '@components/ImportSuccessActiveProjectOverlay';
import IncompatibleProductOverlay        from '@components/IncompatibleProductOverlay';
import LeftPaneRouter                    from '@components/LeftPaneRouter';
import OptionalChassisFanIndicator       from '@components/OptionalChassisFanIndicator';
import OverlayManager                    from '@components/OverlayManager';
import PartsListOverlay                  from '@components/PartsListOverlay';
import PortOverviewOverlay               from '@components/PortOverviewOverlay';
import PrintPreviewOverlay               from '@components/PrintPreviewOverlay';
import ProductBrowserManager             from '@components/ProductBrowserManager';
import ProductNavigationKeyHandler       from '@components/ProductNavigationKeyHandler';
import resizeListener                    from '@components/ResizeListener';
import RightPaneRouter                   from '@components/RightPaneRouter';
import SalesMailboxOverlay               from '@components/SalesMailboxOverlay';
import SalesMailboxSuccessOverlay        from '@components/SalesMailboxSuccessOverlay';
import SelectedProductImageRenderer      from '@components/SelectedProductImageRenderer';
import SetSelectedDataConfirmOverlay     from '@components/SetSelectedDataConfirmOverlay';
import SplitPanePanel                    from '@components/SplitPanePanel';
import StatusBar                         from '@components/StatusBar';
import SwitchToDuplicatedProductOverlay  from '@components/SwitchToDuplicatedProductOverlay';
import SwitchToReversedProductOverlay    from '@components/SwitchToReversedProductOverlay';
import UnsavedProjectBanner              from '@components/UnsavedProjectBanner';
import ZoomAndPanControl                 from '@components/ZoomAndPanControl';
import Overlays                          from '@constants/Overlays';
import Routes                            from '@constants/Routes';
import SplitPaneConfiguration            from '@constants/SplitPaneConfiguration';
import SplitPaneMode                     from '@constants/SplitPaneMode';
import SplitPanePrimary                  from '@constants/SplitPanePrimary';
import TestIds                           from '@constants/TestIds';
import Cast                              from '@helper/Cast';
import PageTitleHelper                   from '@helper/PageTitle';
import ProjectTitleHelper                from '@helper/ProjectTitle';
import WindowEventHelper                 from '@helper/WindowEvent';
import { DesignerLayoutActions }         from '@slices/designerLayout';
import splitPaneStyle                    from '@styles/splitPaneStyle';
import variables                         from '@styles/variables.scss';

import styles from '../styles.module.scss';

class Screen extends Component {
    componentWillReceiveProps(nextProps, nextContext) {
        console.log(
            'Designer: componentWillReceiveProps: this.props, nextProps',
            this.props,
            nextProps,
        );

        if (this.props.rehydrated && this.props.activeProject) {
            const projectKeys = Object.keys(this.props.activeProject);

            if (projectKeys.length === 0) {
                // This happens mostly when the designer is reached using the browsers history keys
                // while no project is selected. We go back to home in this case.
                this.props.noData();
            }
        }
    }

    getBottomPaneSize() {
        if (this.props.bottomPaneVisible) {
            return this.props.bottomPaneSize;
        }

        return 0;
    }

    getLeftPaneSize() {
        if (this.props.leftPaneVisible) {
            return this.props.leftPaneSize;
        }

        return 0;
    }

    getRightPaneSize() {
        if (this.props.rightPaneVisible) {
            return this.props.rightPaneSize;
        }

        return 0;
    }

    getBottomPaneResizerStyle = () => {
        return this.getHorizontalPaneResizerStyle(this.props.bottomPaneVisible);
    };

    getHorizontalPaneResizerStyle = (visible) => {
        if (visible) {
            return splitPaneStyle.resizerHorizontal;
        }

        return splitPaneStyle.resizerHorizontalHidden;
    };

    getLeftPaneResizerStyle = () => {
        return this.getVerticalPaneResizerStyle(this.props.leftPaneVisible);
    };

    /**
     * Returns the calculated top pane size. Only necessary on iPad since
     * the height calculation driven by css fails for unknown reasons when
     * the bottom pane is toggled.
     *
     * @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-342
     * @returns {null|number}
     */
    getTopPaneSize = () => {
        if (
            (
                isTablet && isIOS
            )
            ||
            isSafari
        ) {
            const bottomPaneSize = this.getBottomPaneSize();

            return (
                this.props.windowHeight -
                bottomPaneSize -
                Cast.int(variables.headerHeight)
            );
        }

        return null;
    };

    getRightPaneResizerStyle = () => {
        return this.getVerticalPaneResizerStyle(this.props.rightPaneVisible);
    };

    getVerticalPaneResizerStyle = (visible) => {
        if (visible) {
            return splitPaneStyle.resizerVertical;
        }

        return splitPaneStyle.resizerVerticalHidden;
    };

    onBottomPaneChanged = (newSize) => {
        this.props.bottomPaneSizeChanged({
            size: newSize,
        });
    };

    onLeftPaneChanged = (newSize) => {
        this.props.leftPaneSizeChanged({
            size: newSize,
        });
    };

    onPaneChange = (newSize) => {
        WindowEventHelper.fakeResize();
    };

    onRightPaneChanged = (newSize) => {
        this.props.rightPaneSizeChanged({
            size: newSize,
        });
    };

    render() {
        const bottomPaneSize         = this.getBottomPaneSize();
        const bottomPaneResizerStyle = this.getBottomPaneResizerStyle();
        const leftPaneSize           = this.getLeftPaneSize();
        const leftPaneResizerStyle   = this.getLeftPaneResizerStyle();
        const rightPaneSize          = this.getRightPaneSize();
        const rightPaneResizerStyle  = this.getRightPaneResizerStyle();
        const topPaneSize            = this.getTopPaneSize();

        return (
            <div className={styles.designer}>
                <Helmet>
                    <title>{PageTitleHelper.getPageTitle(ProjectTitleHelper.getProjectTitle(this.props.title))}</title>
                </Helmet>
                <Header mode={HeaderMode.designer} />
                <FilterToggleKeyHandler />
                {this.renderProductNavigationKeyHandler()}
                {this.renderOverlays()}
                <SplitPane
                    className={styles.designerMainSplitPane}
                    split={SplitPaneMode.vertical}
                    onDragFinished={this.onLeftPaneChanged}
                    minSize={SplitPaneConfiguration.left.minimumSize}
                    maxSize={SplitPaneConfiguration.left.maximumSize}
                    defaultSize={leftPaneSize}
                    size={leftPaneSize}
                    primary={SplitPanePrimary.first}
                    resizerStyle={leftPaneResizerStyle}
                    onChange={this.onPaneChange}
                    allowResize={!isTablet}
                >
                    <SplitPanePanel
                        testId={TestIds.leftPane}
                    >
                        <LeftPaneRouter />
                    </SplitPanePanel>
                    <SplitPane
                        split={SplitPaneMode.vertical}
                        onDragFinished={this.onRightPaneChanged}
                        minSize={SplitPaneConfiguration.right.minimumSize}
                        maxSize={SplitPaneConfiguration.right.maximumSize}
                        defaultSize={rightPaneSize}
                        size={rightPaneSize}
                        primary={SplitPanePrimary.second}
                        resizerStyle={rightPaneResizerStyle}
                        onChange={this.onPaneChange}
                        allowResize={!isTablet}
                    >
                        <SplitPanePanel>
                            <SplitPane
                                split={SplitPaneMode.horizontal}
                                onDragFinished={this.onBottomPaneChanged}
                                minSize={SplitPaneConfiguration.bottom.minimumSize}
                                maxSize={SplitPaneConfiguration.bottom.maximumSize}
                                defaultSize={bottomPaneSize}
                                size={bottomPaneSize}
                                primary={SplitPanePrimary.second}
                                resizerStyle={bottomPaneResizerStyle}
                                onChange={this.onPaneChange}
                                allowResize={!isTablet}
                            >
                                <SplitPanePanel height={topPaneSize}>
                                    {this.renderMainPaneContent()}
                                </SplitPanePanel>
                                <SplitPanePanel
                                    testId={TestIds.bottomPane}
                                >
                                    <ProductBrowserManager />
                                </SplitPanePanel>
                            </SplitPane>
                        </SplitPanePanel>
                        <SplitPanePanel
                            testId={TestIds.rightPane}
                        >
                            <RightPaneRouter />
                        </SplitPanePanel>
                    </SplitPane>
                </SplitPane>
            </div>
        );
    }

    renderProductNavigationKeyHandler = () => {
        if (!this.props.pathname.endsWith(Routes.designerProductSingleOrders)) {
            return (
                <ProductNavigationKeyHandler />
            );
        }

        return null;
    };

    renderMainPaneContent = () => {
        return [
            this.renderOptionalChassisFanIndicator(),
            this.renderUnsavedProjectBanner(),
            <StatusBar
                key={'StatusBar'}
            />,
            <ZoomAndPanControl
                key={'ZoomAndPanControl'}
                renderChildren={this.renderMainPaneContentChildren}
            />,
        ];
    };

    renderOptionalChassisFanIndicator = () => {
        return (
            <OptionalChassisFanIndicator
                offsetIcon={this.props.projectIsUnsaved}
                key={'optionalChassisFanIndicator'}
            />
        );
    };

    renderUnsavedProjectBanner = () => {
        if (this.props.projectIsUnsaved) {
            return (
                <UnsavedProjectBanner />
            );
        }

        return null;
    };

    renderMainPaneContentChildren = (x, y, scale) => {
        return (
            <SelectedProductImageRenderer
                x={x}
                y={y}
                scale={scale}
            />
        );
    };

    renderOverlays = () => {
        return (
            <OverlayManager
                overlays={{
                    [Overlays.chooseFanType]:              ChooseFanTypeOverlay,
                    [Overlays.closeProject]:               CloseProjectOverlay,
                    [Overlays.confirmOverwrite]:           ConfirmOverwriteSlotOverlay,
                    [Overlays.deleteModules]:              DeleteModulesOverlay,
                    [Overlays.deleteProduct]:              DeleteProductOverlay,
                    [Overlays.deleteSingleOrderSlot]:      DeleteSingleOrderSlotOverlay,
                    [Overlays.deleteSfp]:                  DeleteSfpOverlay,
                    [Overlays.deleteSlot]:                 DeleteSlotOverlay,
                    [Overlays.download]:                   DownloadOverlay,
                    [Overlays.duplicateProduct]:           DuplicateProductOverlay,
                    [Overlays.export]:                     ExportOverlay,
                    [Overlays.exportSuccess]:              ExportSuccessOverlay,
                    [Overlays.fillSlots]:                  FillSlotsOverlay,
                    [Overlays.importSuccessActiveProject]: ImportSuccessActiveProjectOverlay,
                    [Overlays.endOfLifeProduct]:           EndOfLifeProductOverlay,
                    [Overlays.incompatibleProduct]:        IncompatibleProductOverlay,
                    [Overlays.hitLimitation]:              HitLimitationOverlay,
                    [Overlays.partsList]:                  PartsListOverlay,
                    [Overlays.portOverview]:               PortOverviewOverlay,
                    [Overlays.printPreview]:               PrintPreviewOverlay,
                    [Overlays.salesMailbox]:               SalesMailboxOverlay,
                    [Overlays.salesMailboxSuccess]:        SalesMailboxSuccessOverlay,
                    [Overlays.setSelectedDataConfirm]:     SetSelectedDataConfirmOverlay,
                    [Overlays.switchToDuplicatedProduct]:  SwitchToDuplicatedProductOverlay,
                    [Overlays.switchToReversedProduct]:    SwitchToReversedProductOverlay,
                }}
            />
        );
    };
}

const mapStateToProps = (state) => {
    let lastExportDate              = state.activeProject.lastExportDate;
    const hideUnsavedProjectWarning = state.activeProject.hideUnsavedProjectWarning;
    let projectIsUnsaved            = false;

    if (!hideUnsavedProjectWarning) {
        if (lastExportDate) {
            const now        = new Date();
            lastExportDate   = new Date(lastExportDate);
            const difference = now - lastExportDate;

            if (difference >= 60 * 60 * 24 * 1000) {
                projectIsUnsaved = true;
            }
        } else {
            projectIsUnsaved = true;
        }
    }

    return {
        activeProject:     state.activeProject,
        bottomPaneSize:    state.designerLayout.bottomPaneSize,
        bottomPaneVisible: state.designerLayout.bottomPaneVisible,
        leftPaneVisible:   state.designerLayout.leftPaneVisible,
        leftPaneSize:      state.designerLayout.leftPaneSize,
        pathname:          state.router.location.pathname,
        rightPaneVisible:  state.designerLayout.rightPaneVisible,
        rightPaneSize:     state.designerLayout.rightPaneSize,
        title:             _.get(state, 'activeProject.metaData.title', ''),
        rehydrated:        state._persist.rehydrated,
        projectIsUnsaved,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(
    ActiveProjectActions,
    DesignerLayoutActions,
), dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(resizeListener(Screen)));

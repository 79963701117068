//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { routerMiddleware }                      from 'connected-react-router';
import { createBrowserHistory }                  from 'history';
import localforage                               from 'localforage';
import { compose, createStore, applyMiddleware } from 'redux';
import { persistStore }                          from 'redux-persist';
import { persistReducer }                        from 'redux-persist';
import withReady                                 from 'redux-ready';
import createSagaMiddleware                      from 'redux-saga';

import designerLayoutFilterMiddleware    from './middlewares/designerLayoutFilter';
import overlaySkipperMiddleware          from './middlewares/locationFilter';
import locationFilterMiddleware          from './middlewares/overlaySkipper';
import setSelectedProductMiddleware      from './middlewares/setSelectedProduct';
import slotOverwriteQuestionerMiddleware from './middlewares/slotOverwriteQuestioner';
import smartActionRedirectorMiddleware   from './middlewares/smartActionRedirector';
import rootReducer                       from './reducer';
import sagas                             from './sagas/index';

export const history        = createBrowserHistory();
export const sagaMiddleware = createSagaMiddleware();

const persistConfig    = {
    key:       'root',
    storage:   {
        async getItem(key) {
            if (key === 'persist:root') {
                const migratedToIndexDB = await localforage.getItem('migratedToIndexDB');

                if (!migratedToIndexDB) {
                    const item = localStorage.getItem(key);

                    await localforage.setItem('migratedToIndexDB', true);

                    return item;
                }
            }

            const item = await localforage.getItem(key);

            return item;
        },
        setItem:    localforage.setItem,
        removeItem: localforage.removeItem,
        getAllKeys: localforage.getAllKeys,
        getAll:     localforage.getAll,
        clear:      localforage.clear,
    },
};
const persistedReducer = persistReducer(persistConfig, rootReducer(history));

export default (initialState) => {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
    const store            = createStore(
        persistedReducer,
        initialState,
        composeEnhancers(
            withReady,
            applyMiddleware(
                designerLayoutFilterMiddleware,
                locationFilterMiddleware,
                overlaySkipperMiddleware,
                setSelectedProductMiddleware,
                slotOverwriteQuestionerMiddleware,
                smartActionRedirectorMiddleware,
                sagaMiddleware,
                routerMiddleware(history),
            ),
        ),
    );

    store.ready().then((state) => {
        sagaMiddleware.run(sagas.root);
        sagaMiddleware.run(sagas.startUp);
    });

    const persistor = persistStore(store);

    return {
        store,
        persistor,
    };
};

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames          from 'classnames';
import { withTranslation } from 'react-i18next';

import SerialTypes     from '@constants/SerialTypes';
import SelectionHelper from '@helper/SelectionHelper';

import SerialLabelAlignment  from './SerialLabelAlignment';
import styles                from './styles.module.scss';
import Icon                  from '../Icon';
import IconType              from '../Icon/IconType';
import ProductDataValueLabel from '../ProductDataValueLabel';
import PropTypes             from '../PropTypes';

const Component = ({
    alignment,
    serial,
    value,
    t,
}) => {
    const getIconType = () => {
        return SelectionHelper.get(
            serial,
            {
                [SerialTypes.rs232]: IconType.serialRs232,
                [SerialTypes.rs422]: IconType.serialRs422,
            },
        );
    };
    const getTitle    = () => {
        return SelectionHelper.getTranslation(
            serial,
            {
                [SerialTypes.rs232]: 'serialRs232',
                [SerialTypes.rs422]: 'serialRs422',
            },
        );
    };
    const renderIcon  = () => {
        const iconType = getIconType();

        if (iconType) {
            return (
                <span className={styles.serialLabelIconWrapper}>
                    <Icon iconType={iconType} />
                </span>
            );
        }

        return null;
    };

    if (serial) {
        const title = getTitle();

        if (!title) {
            console.warn('Unsupported serial', serial);
        }

        return (
            <span
                className={classNames(
                    styles.serialLabel,
                    SelectionHelper.get(
                        alignment,
                        {
                            [SerialLabelAlignment.left]:  styles.serialLabelLeft,
                            [SerialLabelAlignment.right]: styles.serialLabelRight,
                        },
                    ),
                )}
                title={title}
            >
                {renderIcon()}
                <span className={styles.serialText}>
                    {title}
                </span>
            </span>
        );
    }

    return (
        <ProductDataValueLabel value={value} />
    );
};

Component.propTypes = {
    alignment: PropTypes.oneOf(Object.values(SerialLabelAlignment)),
    serial:    PropTypes.string,
    t:         PropTypes.any,
    value:     PropTypes.any,
};

Component.defaultProps = {
    alignment: null,
    serial:    null,
};

export default withTranslation()(Component);

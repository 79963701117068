import React from 'react';

import { ConnectedRouter } from 'connected-react-router';
import { Switch } from 'react-router';
import { Route } from 'react-router';

import Routes from '@constants/Routes';
import { history } from '@store/index';

import styles from './styles.module.scss';
import PrettyScrollBar from '../PrettyScrollBar';
import ProductDetailsActionPane from '../ProductDetailsActionPane';
import ProductDetailsPane from '../ProductDetailsPane';
import ProjectDetailsPane from '../ProjectDetailsPane';
import RightEmptyPane from '../RightEmptyPane';
import SingleOrderProductDetailsActionPane from '../SingleOrderProductDetailsActionPane';
import SingleOrderProductDetailsPane from '../SingleOrderProductDetailsPane';
import SlotDetailsPane from '../SlotDetailsPane';

const Component = () => {
    return (
        <div className={styles.rightPaneRouter}>
            <PrettyScrollBar>
                <div className={styles.rightPaneRouterContent}>
                    <ConnectedRouter history={history}>
                        <Switch>
                            <Route
                                exact={true}
                                path={Routes.designerProjectDetails}
                                component={ProjectDetailsPane}
                            />
                            <Route
                                exact={true}
                                path={Routes.designerProductSingleOrders}
                                component={SingleOrderProductDetailsPane}
                            />
                            <Route
                                exact={true}
                                path={Routes.designerProductDetails}
                                component={ProductDetailsPane}
                            />
                            <Route
                                exact={true}
                                path={Routes.designerProductSlotDetails}
                                component={SlotDetailsPane}
                            />
                            <Route
                                component={RightEmptyPane}
                            />
                        </Switch>
                    </ConnectedRouter>
                </div>
            </PrettyScrollBar>
            <div className={styles.rightPaneRouterActions}>
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route
                            exact={true}
                            path={Routes.designerProductDetails}
                            component={ProductDetailsActionPane}
                        />
                        <Route
                            exact={true}
                            path={Routes.designerProductSingleOrders}
                            component={SingleOrderProductDetailsActionPane}
                        />
                    </Switch>
                </ConnectedRouter>
            </div>
        </div>
    );
};

Component.propTypes = {};

Component.defaultProps = {};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import MainboardTypeLabel          from '../MainboardTypeLabel';
import MainboardTypeLabelAlignment from '../MainboardTypeLabel/MainboardTypeLabelAlignment';
import PropTypes                   from '../PropTypes';

const Component = ({ value }) => {
    return (
        <MainboardTypeLabel
            alignment={MainboardTypeLabelAlignment.left}
            mainboardType={value}
        />
    );
};

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// We use "dsd-api" instead of "api.dsd" for some domain origin policy reasons
// @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-753
// export const PRODUCTION_BACKEND_URL = 'https://api.dsd.ihse.com';
import { getProductionBackendUrl } from '@helper/Configuration';
import { getStagingBackendUrl }    from '@helper/Configuration';

export const PRODUCTION_BACKEND_URL = getProductionBackendUrl();
export const STAGING_BACKEND_URL    = getStagingBackendUrl();
export const LOCAL_BACKEND_URL      = 'https://draco-system-designer-backend.test';

// TODO-Skeleton start
export default class Api {
    static getBackendUrl() {
        const url                       = window.location.href;
        const localBackendUrlIndicators = [
            '.test',
            'localhost',
            '127.0.0.1',
        ];

        for (const localBackendUrlIndicator of localBackendUrlIndicators) {
            if (url.indexOf(localBackendUrlIndicator) > -1) {
                return LOCAL_BACKEND_URL;
            }
        }

        if (url.indexOf('staging.') > -1) {
            return STAGING_BACKEND_URL;
        }

        return PRODUCTION_BACKEND_URL;
    }

    /**
     * @returns {boolean}
     */
    static isStaging() {
        return (
            this.getBackendUrl() === STAGING_BACKEND_URL
        );
    }

    /**
     * @param host
     */
    static checkBackendUrl(host) {
        if (host.endsWith('/')) {
            console.error('⚠️⚠️⚠️ Warning: Backend url should not end with a /');
        }

        if (host !== PRODUCTION_BACKEND_URL) {
            console.warn('⚠️⚠️⚠️ Warning: App is not in production mode! ⚠️⚠️⚠️');
        }

        if (host.indexOf('https://') === -1) {
            console.error('⚠️⚠️⚠️ Warning: Backend url is not secure ⚠️⚠️⚠️');
        }
    }
}

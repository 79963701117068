import React from 'react';

import DataTransferLabel from '../DataTransferLabel';
import DataTransferLabelAlignment from '../DataTransferLabel/DataTransferLabelAlignment';
import PropTypes from '../PropTypes';

const Component = ({ value }) => (
    <DataTransferLabel
        alignment={DataTransferLabelAlignment.left}
        dataTransfer={value}
    />
);

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

export default Component;

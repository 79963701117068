export default {
    // Proof that the indices are used at their right positions and working after changing them here

    mainExtenderTabVario:   0,
    mainExtenderTabCompact: 1,
    mainExtenderTabSecure:  2,
    mainExtenderTabMisc:    3,

    mainMatrixTabEnterprise: 0,
    mainMatrixTabFlex:       1,
    mainMatrixTabCompact:    2,

    mainFullIpFullHdTab: 0,
    mainFullIp4KTab:     1,

    subTabFullHdEasyline:         0,
    subTabFullHdFlexline:         1,
    subTabFullHdUsb:              2,
    subTabFullHdAccessories:      3,
    subTabFullHdMatrixManagement: 4,

    subTab4KMedia4Connect:    0,
    subTab4KScalableline:     1,
    subTab4KAccessories:      2,
    subTab4KMatrixManagement: 3,

    subTabCompactChassis:            0,
    subTabCompactChassisAccessories: 1,

    subTabEnterpriseChassis:     0,
    subTabEnterpriseIOBoards:    1,
    subTabEnterpriseCPUBBoards:  2,
    subTabEnterpriseAccessories: 3,

    subTabFlexChassis:             0,
    subTabFlexChassisCustomDesign: 1,
    subTabFlexFrontPlates:         2,
    subTabFlexAccessories:         3,
    subTabFlexIOBoards:            4,

    subTabVarioChassis:            0,
    subTabVarioChassisAccessories: 1,
    subTabVarioMainboards:         2,
    subTabVarioAddOnModules:       3,
    subTabVarioOtherModules:       4,
    subTabVarioUsb20Modules:       5,
};

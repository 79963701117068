//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import SelectionHelper from '@helper/SelectionHelper';

import WarningListTheme from './WarningListTheme';
import styles           from './styles.module.scss';
import PropTypes        from '../PropTypes';
import WarningListItem  from '../WarningListItem';

const Component = ({
    theme    = '',
    warnings = [],
}) => (
    <ul
        className={
            classNames(
                styles.warningList,
                SelectionHelper.get(
                    theme,
                    {
                        [WarningListTheme.gray]:   styles.warningListGray,
                        [WarningListTheme.orange]: styles.warningListOrange,
                    },
                ),
            )
        }
    >
        {renderItems(warnings, theme)}
    </ul>
);

function renderItems(warnings, theme) {
    const items = [];

    for (const warningIndex in warnings) {
        const warning = warnings[warningIndex];

        items.push(<WarningListItem
            data={warning}
            key={warningIndex}
            theme={theme}
        />);
    }

    return items;
}

Component.propTypes = {
    theme:    PropTypes.oneOf(Object.values(WarningListTheme)),
    warnings: PropTypes.array,
};

export default Component;

/**
 * @see This file also exists in the backend src/Entity/SlotDefaultBounds.php
 */
const SlotDefaultBounds = {
    extender:             {
        height: 56,
        width:  360,
    },
    matrix:               {
        height: 28,
        width:  237,
    },
    matrixCompactSfp:     {
        height: 37,
        width:  82,
    },
    matrixFlexFrontPlate: {
        height: 63,
        width:  623,
    },
    sfp:                  {
        height: 19,
        width:  18,
    },
};

module.exports = SlotDefaultBounds;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { Component } from 'react';
import React         from 'react';

import I18n        from 'i18next';
import { Helmet }  from 'react-helmet';
import { connect } from 'react-redux';

import Header          from '@components/Header';
import TextContainer   from '@components/TextContainer';
import TitleBanner     from '@components/TitleBanner';
import TitleBannerMode from '@components/TitleBanner/TitleBannerMode';
import Languages       from '@constants/Languages';
import PageTitleHelper from '@helper/PageTitle';
import SelectionHelper from '@helper/SelectionHelper';

class Screen extends Component {
    /**
     * This texts are from https://www.ihse.de/impressum/
     * @see https://www.ihse.de/impressum/
     *
     * @returns {*}
     */
    renderDe = () => {
        return (
            <TextContainer autoStyleChildren={true}>
                <h3>
                    Anbieterkennzeichnung nach § 6 Teledienstgesetz
                </h3>
                <p>
                    IHSE GmbH
                    <br />
                    Benzstraße 1
                    <br />
                    88094 Oberteuringen
                    <br />
                    Deutschland
                    <br />
                    Telefon: <a href={'tel:+49754692480'}>+497546-9248-0</a>
                    <br />
                    Fax: +49 7546-9248-48
                    <br />
                    E-Mail: <a href={'mailto:info@ihse.de'}>info@ihse.de</a>
                    <br />
                    Web: <a
                        href={'https://ihse.de'}
                        target={'_blank'}
                        rel="noreferrer"
                    >https://ihse.de</a>
                </p>
                <p>
                    Handelsregister beim Amtsgericht Freiburg, HRB 580617
                    <br />
                    UStId-Nr: DE146965085
                    <br />
                    Steuer.Nr.: 61020/03500
                    <br />
                    Finanzamt Friedrichshafen
                </p>
                <p>
                    Hersteller nach §3 Abs.12 Satz 2 ElektroG: WEEE-Reg.-Nr. DE39900275
                </p>
                <p>
                    Geschäftsführer: Dr. Enno Littmann, Michael Spatny ▪ Prokurist: Olaf Trittel
                </p>
                <h3>
                    Verantwortlicher gemäß § 10 Abs. 3 MDStV:
                </h3>
                <p>
                    Dr. Enno Littmann
                    <br />
                    Benzstraße 1
                    <br />
                    88094 Oberteuringen
                    <br />
                    Deutschland
                </p>
                <h3>
                    Externe Links
                </h3>
                <p>
                    Die Webseite enthält Verlinkungen zu anderen Webseiten, auf deren Inhalt wir keinen Einfluss haben.
                    Aus diesem Grund können wir für diese Inhalte auch keine Gewähr übernehmen.
                </p>
                <p>
                    Für die Inhalte und Richtigkeit der bereitgestellten Informationen ist der jeweilige Anbieter der
                    verlinkten Webseite verantwortlich. Zum Zeitpunkt der Verlinkung waren keine Rechtsverstöße
                    erkennbar. Bei Bekanntwerden einer solchen Rechtsverletzung wird der Link umgehend entfernen.
                </p>
                <h3>
                    Rechtshinweise
                </h3>
                <p>
                    Der Internetauftritt der IHSE GmbH sowie auch die darin enthaltenen und referenzierten Informationen
                    dienen ausschließlich zu Informationszwecken.
                </p>
                <p>
                    Die IHSE GmbH ist bemüht, aktuelle und korrekte Informationen auf dem Internetauftritt zu
                    veröffentlichen. Dennoch übernehmen wir keinerlei Garantien, Zusicherungen oder Haftungen für die
                    Richtigkeit, Vollständigkeit, Nutzbarkeit etc. der auf dieser Homepage dargestellten bzw.
                    referenzierten Informationen.
                </p>
                <p>
                    Weder die IHSE GmbH noch Dritte (Personen, Unternehmen), die bei der Informationseingabe, der
                    Datenweitergabe oder der Erstellung des Internetauftritts involviert sind, haften in irgendeiner
                    Weise für Schäden im Zusammenhang mit dem Zugang, der Nutzung oder Irrtümern betreffend den Inhalt
                    dieses Internetauftritts.
                </p>
                <h3>
                    Programmierung und technische Umsetzung:
                </h3>
                <p>
                    Lulububu Software GmbH
                    <br />
                    web: <a
                        href={'https://lulububu.de'}
                        target={'_blank'}
                        rel="noreferrer"
                    >https://lulububu.de</a>
                </p>
            </TextContainer>
        );
    };

    /**
     * This texts are from https://www.ihse.com/imprint/
     * @see https://www.ihse.com/imprint/
     *
     * @returns {*}
     */
    renderEn = () => {
        return (
            <TextContainer autoStyleChildren={true}>
                <h3>
                    Supplier identification according to § 6 of the German Teleservices Act
                </h3>
                <p>
                    IHSE GmbH
                    <br />
                    Benzstraße 1
                    <br />
                    D-88094 Oberteuringen
                    <br />
                    Germany
                    <br />
                    phone: <a href={'tel:+49754692480'}>+497546-9248-0</a>
                    <br />
                    fax: +49 7546 9248-48
                    <br />
                    e-mail: <a href={'mailto:info@ihse.de'}>info@ihse.de</a>
                    <br />
                    web: <a
                        href={'https://ihse.de'}
                        target={'_blank'}
                        rel="noreferrer"
                    >https://ihse.de</a>
                </p>
                <p>
                    Trade Register at Country Court Freiburg, HRB 580617
                    <br />
                    VAT-No: DE146965085
                    <br />
                    Tax-No.: 61020/03500
                    <br />
                    Tax Office: Friedrichshafen
                </p>
                <p>
                    Manufacturer according to §3 Abs.12 Satz 2 ElektroG: WEEE-Reg.-Nr. DE39900275
                </p>
                <p>
                    Managing Directors: Dr. Enno Littmann, Michael Spatny ▪ Authorized Officer: Olaf Trittel
                </p>
                <h3>
                    Liable according to § 10 Abs. 3 MDStV:
                </h3>
                <p>
                    Dr. Enno Littmann
                    <br />
                    Benzstraße 1
                    <br />
                    88094 Oberteuringen
                    <br />
                    Germany
                </p>
                <h3>
                    External links
                </h3>
                <p>
                    The website contains links to other websites over whose content we have no control. For this reason,
                    we cannot accept any liability for these contents.
                </p>
                <p>
                    The respective provider of the linked website is responsible for the content and accuracy of the
                    information provided. At the time of linking, no legal violations were discernible. On becoming
                    aware of such a violation of the law, the link will be removed immediately.
                </p>
                <h3>
                    Legal notice
                </h3>
                <p>
                    The website of IHSE GmbH, as well as the information and information referred to in this, serves
                    solely for information purposes.
                </p>
                <p>
                    IHSE GmbH does its utmost to ensure that the information given here is up-to-date and correct. We do
                    not, nevertheless, assume any guarantee, assurance or liable for the correctness, completeness,
                    availability etc of the information given on this website or the information which is referred to
                    here.
                </p>
                <p>
                    Neither the IHSE GmbH or any third parties (individuals, businesses) involved in information entry,
                    data forwarding or compilation of the website, are in any way liable for damages arising as a result
                    of access, use or errors regarding the content of this website.
                </p>
                <h3>
                    Programming and technical implementation:
                </h3>
                <p>
                    Lulububu Software GmbH
                    <br />
                    web: <a
                        href={'https://lulububu.de'}
                        target={'_blank'}
                        rel="noreferrer"
                    >https://lulububu.de</a>
                </p>
            </TextContainer>
        );
    };

    render() {
        return (
            <div>
                <Helmet>
                    <title>{PageTitleHelper.getPageTitle(I18n.t('imprintPageTitle'))}</title>
                </Helmet>
                <Header />
                <TitleBanner
                    mode={TitleBannerMode.text}
                    text={I18n.t('imprint')}
                />
                {this.renderLocalizedContent()}
            </div>
        );
    }

    renderLocalizedContent = () => {
        return SelectionHelper.get(
            this.props.language,
            {
                [Languages.german]:  this.renderDe(),
                [Languages.english]: this.renderEn(),
            },
            this.renderEn(),
        );
    };
}

const mapStateToProps = (state) => {
    return {
        language: state.settings.language,
    };
};

export default connect(
    mapStateToProps,
    null,
)(Screen);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import KeyboardEventHandler   from 'react-keyboard-event-handler';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';

const Component = ({
    selectedProduct,
    lastSelectedProduct,
    setLastSelectedProduct,
    selectProduct,
    closeProductDetails,
}) => {
    const downKeyPressed          = () => {
        console.log('ProductNavigationKeyHandler: downKeyPressed');
        selectNextProduct(false);
    };
    const enterKeyPressed         = () => {
        console.log('ProductNavigationKeyHandler: enterKeyPressed');
        selectProductIfPossible(true);
    };
    const leftKeyPressed          = () => {
        console.log('ProductNavigationKeyHandler: leftKeyPressed');
        if (selectedProduct.categoryType) {
            closeProductDetails();
        }
    };
    const rightKeyPressed         = () => {
        console.log('ProductNavigationKeyHandler: rightKeyPressed');
        selectProductIfPossible(true);
    };
    const upKeyPressed            = () => {
        console.log('ProductNavigationKeyHandler: upKeyPressed');
        selectPreviousProduct();
    };
    const spaceKeyPressed         = () => {
        console.log('ProductNavigationKeyHandler: spaceKeyPressed');
        selectProductIfPossible(true);
    };
    const selectNextProduct       = () => {
        setLastSelectedProduct({
            index: lastSelectedProduct.index + 1,
        });
        selectProductIfPossible(false);
    };
    const selectPreviousProduct   = () => {
        setLastSelectedProduct({
            index: lastSelectedProduct.index - 1,
        });
        selectProductIfPossible(false);
    };
    const selectProductIfPossible = (openDetails) => {
        if (selectedProduct.categoryType) {
            selectProduct({
                index:        lastSelectedProduct.index,
                categoryType: lastSelectedProduct.categoryType,
                openDetails,
            });
        }
    };

    return (
        [
            <KeyboardEventHandler
                key={'keyboardEventHandlerUpKeyDown'}
                handleEventType={'keydown'}
                handleKeys={['up']}
                onKeyEvent={upKeyPressed}
            />,
            <KeyboardEventHandler
                key={'keyboardEventHandlerDownKeyDown'}
                handleEventType={'keydown'}
                handleKeys={['down']}
                onKeyEvent={downKeyPressed}
            />,
            <KeyboardEventHandler
                key={'keyboardEventHandlerLeftKeyDown'}
                handleEventType={'keydown'}
                handleKeys={['left']}
                onKeyEvent={leftKeyPressed}
            />,
            <KeyboardEventHandler
                key={'keyboardEventHandlerRightKeyDown'}
                handleEventType={'keydown'}
                handleKeys={['right']}
                onKeyEvent={rightKeyPressed}
            />,
            <KeyboardEventHandler
                key={'keyboardEventHandlerEnterKeyDown'}
                handleEventType={'keydown'}
                handleKeys={['enter']}
                onKeyEvent={enterKeyPressed}
            />,
            <KeyboardEventHandler
                key={'keyboardEventHandlerSpaceKeyDown'}
                handleEventType={'keydown'}
                handleKeys={['space']}
                onKeyEvent={spaceKeyPressed}
            />,
        ]
    );
};

const mapStateToProps = (state) => (
    {
        selectedProduct:     state.activeProject.selectedProduct,
        lastSelectedProduct: state.activeProject.lastSelectedProduct,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Component);

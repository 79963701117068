//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

import WarningType from '@constants/WarningType';

import ActiveProjectsFactory from '../factories/activeProjects';

class limitationEndOfLife {
    static getEndOfLifeWarning = () => {
        return ActiveProjectsFactory.getWarning(
            WarningType.information,
            I18n.t('limitationEndOfLife'),
        );
    };

    static updateProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        productWarnings,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        const productData = product.productData;

        if (productData.isEndOfLife) {
            productWarnings.push(this.getEndOfLifeWarning());

            return true;
        }

        return false;
    };

    static updateSubProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        subProduct,
        subProductIndex,
        productWarnings,
        subProductWarnings,
        subProductCategory,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        const productData = subProduct.productData;

        if (productData.isEndOfLife) {
            subProductWarnings.push(this.getEndOfLifeWarning());

            return true;
        }

        return false;
    };
}

export default limitationEndOfLife;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import DropResult      from '@constants/DropResult';
import ProductSlotType from '@constants/ProductSlotType';
import SlotType        from '@constants/SlotType';

import defaultDragDropConfiguration from './default';

export default {
    dragBehavior: {
        beginDrag: (props) => {
            console.log('Slot: DragSource: beginDrag (props)', props);

            props.dragStart();

            return (
                {
                    fromSlotAccessoryType: _.get(props, 'data.productData.type', null),
                    fromSlotIndex:         props.index,
                    fromSlotIsDoubleSlot:  props.isDoubleSlot,
                    fromSlotType:          props.type,
                    productId:             props.data.productData.partNo,
                }
            );
        },
        endDrag(props, monitor, component) {
            const dropResult = monitor.getDropResult();
            props.dragStop();

            if (dropResult) {
                const payload    = monitor.getItem();
                const dropEffect = dropResult.dropEffect;

                console.log('Slot: DragSource: endDrag');
                console.table(payload);
                console.table(dropResult);

                if (!_.isUndefined(dropResult.toSlotIndex)) {
                    const actionPayload = {
                        confirmed:             false,
                        fromSlotAccessoryType: dropResult.fromSlotAccessoryType,
                        fromSlotIndex:         dropResult.fromSlotIndex,
                        fromSlotType:          dropResult.fromSlotType,
                        toSlotIndex:           dropResult.toSlotIndex,
                        toSlotType:            dropResult.toSlotType,
                    };

                    if (dropEffect === DropResult.copy) {
                        props.copySlot(actionPayload);
                    } else {
                        props.moveSlot(actionPayload);
                    }
                }
            }
        },
    },
    dragCollect:  defaultDragDropConfiguration.dragCollect,
    dropBehavior: {
        canDrop(props, monitor) {
            const payload = monitor.getItem();

            console.log(
                'Slot: canDrop: payload, props',
                payload,
                props,
            );

            // We can not drop to our self (so the index has to be different)
            const isDifferentSlotIndex = (
                !_.isUndefined(payload.fromSlotIndex) &&
                payload.fromSlotIndex !== props.index &&
                (
                    !payload.fromSlotIsDoubleSlot ||
                    // Also prevent dragging to the previous slot when the
                    // current slot is a double slot since this then will
                    // also cover the previous slot
                    payload.fromSlotIndex - 1 !== props.index
                )
            );

            // We are dragging a new item that is not a chassis
            const isNotChassis  = (
                payload.productSlotType &&
                payload.productSlotType !== ProductSlotType.chassis &&
                // Check for the correct slot types
                (
                    (
                        props.type === SlotType.slot &&
                        payload.productSlotType !== ProductSlotType.chassisAccessory
                    )
                    ||
                    (
                        (
                            props.type === SlotType.accessory ||
                            props.type === SlotType.fan ||
                            props.type === SlotType.powerSupplyUnit
                        ) &&
                        payload.productSlotType === ProductSlotType.chassisAccessory
                    )
                )
            );
            const isCorrectType = (
                (
                    _.isUndefined(payload.fromSlotType) ||
                    props.type === payload.fromSlotType
                ) &&
                // Make sure we only can drop accessories to the correct type.
                // This check is only necessary here since after the slot was dropped
                // once the SlotType from the Component itself is used for the comparision.
                (
                    !props.accessoryType ||
                    payload.productAccessoryType === props.accessoryType ||
                    payload.fromSlotAccessoryType === props.accessoryType
                )
            );
            const canDrop       = (
                (
                    isDifferentSlotIndex ||
                    isNotChassis
                )
                &&
                isCorrectType
            );

            return canDrop;
        },
        drop: (props, monitor, component) => {
            const payload             = monitor.getItem();
            const clientOffset        = monitor.getClientOffset();
            const initialClientOffset = monitor.getInitialClientOffset();

            console.log('Slot: DropTarget: drop', payload, clientOffset, initialClientOffset, props, component);

            // This is true when the user drags a new card from the product table to
            // the left panel
            if (!payload.fromSlotIndex && payload.fromSlotIndex !== 0) {
                props.setProductSlot({
                    accessoryType:   props.data.type,
                    confirmed:       false,
                    isBulk:          false,
                    productId:       payload.productId,
                    productSlotType: payload.productSlotType,
                    slotIndex:       props.index,
                    targetSlotType:  props.type,
                });
            }

            return {
                ...payload,
                toAccessoryType: props.accessoryType,
                toSlotIndex:     props.index,
                toSlotType:      props.type,
            };
        },
    },
    dropCollect:  defaultDragDropConfiguration.dropCollect,
};

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                from 'i18next';
import _                   from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect }         from 'react-redux';
import Select              from 'react-select';
import { components }      from 'react-select';

import colors   from '@styles/colors.scss';
import zIndexes from '@styles/zIndexes.scss';

import styles    from './styles.module.scss';
import Icon      from '../Icon';
import IconType  from '../Icon/IconType';
import PropTypes from '../PropTypes';

const customStyles = {
    clearIndicator:      () => (
        {
            'position':       'absolute',
            'right':          6,
            'width':          10,
            'height':         10,
            'top':            '50%',
            'marginTop':      -5,
            'zIndex':         zIndexes.zIndexFilterClearIndicator,
            'cursor':         'pointer',
            'display':        'flex',
            'justifyContent': 'center',
            'alignItems':     'center',
            '&:hover':        {
                'svg': {
                    '*': {
                        fill: colors.colorOrange,
                    },
                },
            },
        }
    ),
    container:           () => (
        {
            width:    '80%',
            position: 'relative',
        }
    ),
    control:             (provided, state, test) => {
        return (
            {
                borderRadius: 3,
                background:   colors.colorWhite,
                height:       30,
                color:        colors.colorGrayLight,
            }
        );
    },
    dropdownIndicator:   () => (
        {
            'cursor':         'pointer',
            'width':          '36px',
            'height':         '100%',
            'position':       'absolute',
            'top':            1,
            'left':           2,
            'display':        'flex',
            'alignItems':     'center',
            'paddingRight':   10,
            'justifyContent': 'flex-end',
            'boxSizing':      'border-box',
            '&:hover':        {
                'svg': {
                    '*': {
                        fill: colors.colorOrange,
                    },
                },
            },
        }
    ),
    indicatorsContainer: () => (
        {}
    ),
    indicatorSeparator:  () => (
        {
            display: 'none',
        }
    ),
    input:               (provided, state) => {
        return {
            position:        'absolute',
            top:             -2,
            overflow:        'hidden',
            left:            0,
            width:           '100%',
            backgroundColor: (
                state.value.length > 0 ?
                    colors.colorWhite :
                    colors.colorTransparent
            ),
        };
    },
    menu:                (provided) => (
        {
            ...provided,
            zIndex:       zIndexes.zIndexFilterMenu,
            boxShadow:    '0 5px 10px 0 #0000000c',
            marginTop:    -2,
            marginBottom: -3,
        }
    ),
    menuList:            () => (
        {
            background: colors.colorWhite,
            padding:    2,
        }
    ),
    option:
                         (provided, state) => {
                             return {
                                 backgroundColor: (
                                     state.isSelected || state.isFocused ?
                                         colors.colorOrangeLighter2 :
                                         colors.colorGrayLighter2
                                 ),
                                 color:           colors.colorGrayLight,
                                 cursor:          'pointer',
                                 position:        'relative',
                                 borderRadius:    3,
                                 fontFamily:      colors.fontSourceSansPro,
                                 fontSize:        13,
                                 fontWeight:      'normal',
                                 paddingLeft:     10,
                                 paddingRight:    10,
                                 paddingTop:      2,
                                 paddingBottom:   2,
                                 overflow:        'hidden',
                                 textOverflow:    'ellipsis',
                                 marginBottom:    2,
                                 textTransform:   'none',
                                 transition:      `background-color ${colors.defaultTransitionSpeed} linear`,
                             };
                         },
    placeholder:
                         () => (
                             {
                                 color:         colors.colorGrayLight,
                                 fontSize:      14,
                                 textTransform: 'none',
                                 fontWeight:    'normal',
                                 fontFamily:    colors.fontSourceSansPro,
                                 position:      'absolute',
                                 top:           3,
                                 left:          28,
                             }
                         ),
    valueContainer:
                         () => (
                             {
                                 color:         colors.colorGrayLight,
                                 fontSize:      14,
                                 textTransform: 'none',
                                 fontWeight:    'normal',
                                 fontFamily:    colors.fontSourceSansPro,
                                 position:      'absolute',
                                 top:           3,
                                 left:          28,
                             }
                         ),
};

const ClearIndicator = (props) => {
    return (
        components.ClearIndicator && (
            <components.ClearIndicator {...props}>
                <Icon iconType={IconType.delete} />
            </components.ClearIndicator>
        )
    );
};

const DropdownIndicator = (props) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <Icon iconType={IconType.arrowDown} />
            </components.DropdownIndicator>
        )
    );
};

const Option = (props) => {
    return (
        props.value
            ? (
                <div
                    style={{
                        position: 'relative',
                    }}
                >
                    <components.Option {...props} />
                    {
                        props.isSelected
                            ? (
                                <div
                                    style={{
                                        position:  'absolute',
                                        top:       '50%',
                                        right:     5,
                                        width:     9,
                                        height:    9,
                                        marginTop: -4,
                                    }}
                                >
                                    <Icon iconType={IconType.check} />
                                </div>
                            )
                            : null
                    }
                </div>

            )
            : null
    );
};

const ValueContainer = ({ children, ...props }) => {
    const values      = props.getValue();
    const filterCount = values.length;

    return (
        <components.ValueContainer {...props}>
            {children}
            {filterCount}
            {' '}
            {
                filterCount === 1 ?
                    I18n.t('filterActiveSingular') :
                    I18n.t('filterActivePlural')
            }
        </components.ValueContainer>
    );
};

class Component extends React.Component {
    getMenuPlacement = (optionKeys) => {
        const bottomPaneSize = this.props.bottomPaneSize;
        const dropDownSize   = optionKeys.length * 60 + 5;

        if (dropDownSize <= bottomPaneSize) {
            return 'bottom';
        }

        return 'top';
    };

    render() {
        if (this.props.options) {
            const optionKeys = Object.keys(this.props.options);

            if (optionKeys.length > 0) {
                const menuPlacement = this.getMenuPlacement(optionKeys);

                return (
                    <Select
                        controlShouldRenderValue={false}
                        closeMenuOnSelect={false}
                        components={{
                            ClearIndicator,
                            DropdownIndicator,
                            Option,
                            ValueContainer,
                        }}
                        hideSelectedOptions={false}
                        placeholder={''}
                        styles={customStyles}
                        noOptionsMessage={() => {
                            return I18n.t('tableInputFilterNoMatches');
                        }}
                        autosize={false}
                        menuPosition={'fixed'}
                        menuPlacement={menuPlacement}
                        className={styles.tableSelect}
                        escapeClearsValue={true}
                        isMulti={this.props.isMulti}
                        name={this.props.name}
                        onChange={this.props.onChange}
                        options={this.props.options}
                        value={this.props.filters}
                    />
                );
            }
        }

        return null;
    }
}

const mapStateToProps = (state) => (
    {
        bottomPaneSize: state.designerLayout.bottomPaneSize,
    }
);

Component.propTypes = {
    bottomPaneSize: PropTypes.number,
    isMulti:        PropTypes.bool,
    name:           PropTypes.string,
    onChange:       PropTypes.func,
    options:        PropTypes.array,
    value:          PropTypes.any,
};

Component.defaultProps = {
    bottomPaneSize: 0,
    isMulti:        false,
    name:           '',
    onChange:       _.noop,
    options:        [],
    value:          null,
};

export default connect(mapStateToProps)(withTranslation()(Component));

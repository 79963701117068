//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { TabPanel }           from 'react-tabs';
import { bindActionCreators } from 'redux';

import { ExtenderProductBrowserActions } from '@slices/extenderProductBrowser';
import ProductSubCategoryType            from '@constants/ProductSubCategoryType';
import Routes                            from '@constants/Routes';
import TabIndices                        from '@constants/TabIndices';
import TestIds                           from '@constants/TestIds';
import DataProvider                      from '@helper/DataProvider';
import StateHelper                       from '@helper/State';

import styles                             from './styles.module.scss';
import ExtenderCompactAccessoriesTable    from '../ExtenderCompactAccessoriesTable';
import ExtenderCompactTable               from '../ExtenderCompactTable';
import ExtenderMiscTable                  from '../ExtenderMiscTable';
import ExtenderSecureTable                from '../ExtenderSecureTable';
import ExtenderVarioAddOnModuleTable      from '../ExtenderVarioAddOnModuleTable';
import ExtenderVarioChassisAccessoryTable from '../ExtenderVarioChassisAccessoryTable';
import ExtenderVarioChassisTable          from '../ExtenderVarioChassisTable';
import ExtenderVarioMainboardTable        from '../ExtenderVarioMainboardTable';
import ExtenderVarioOtherModuleTable      from '../ExtenderVarioOtherModuleTable';
import ExtenderVarioUsb20ModuleTable      from '../ExtenderVarioUsb20ModuleTable';
import MainLevelTabButton                 from '../MainLevelTabButton';
import MainLevelTabList                   from '../MainLevelTabList';
import SubLevelTabButton                  from '../SubLevelTabButton';
import SubLevelTabList                    from '../SubLevelTabList';
import tabPaneStyle                       from '../TabPanel/styles.module.scss';
import Tabs                               from '../Tabs';

class Component extends React.Component {
    mainTabChanged = (tabIndex) => {
        this.props.setActiveMainTabIndex({
            activeMainTabIndex: tabIndex,
        });
    };

    // https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-847
    productIsSelected = () => {
        if (!this.props.selectedProductMeta) {
            return false;
        }

        if (!this.props.selectedProductMeta.categoryType) {
            return false;
        }

        if (!this.props.pathname.startsWith(Routes.designerProductDetails)) {
            return false;
        }

        return true;
    };

    selectedProductIsSubCategoryType = (subCategoryType) => {
        if (!this.productIsSelected()) {
            return false;
        }

        if (!this.props.selectedProduct.productData) {
            return false;
        }

        return this.props.selectedProduct.productData.subCategoryType === subCategoryType;
    };

    compactSubTabChanged = (tabIndex) => {
        this.props.setActiveSubTabCompactIndex({
            activeSubTabCompactIndex: tabIndex,
        });
    };

    render() {
        const productIsSelected = this.productIsSelected();

        return (
            <div className={styles.extenderProductBrowser}>
                <Tabs
                    selectedIndex={this.props.activeMainTabIndex}
                    onSelect={this.mainTabChanged}
                >
                    {this.renderMainLevelTabList(productIsSelected)}
                    {this.renderVarioTabPanel()}
                    {this.renderCompactTabPanel()}
                    {this.renderSecureTabPanel()}
                    {this.renderMiscTabPanel()}
                </Tabs>
            </div>
        );
    }

    renderCompactTabPanel = () => {
        const shouldEnableNonChassisTabs = this.shouldEnableNonChassisTabs();
        const activeSubTabCompactIndex   = this.props.activeSubTabCompactIndex;

        return (
            <TabPanel className={tabPaneStyle.tabPanel}>
                <Tabs
                    selectedIndex={activeSubTabCompactIndex}
                    onSelect={this.compactSubTabChanged}
                >
                    <SubLevelTabList>
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonExtenderCompactChassis}
                            disabled={false}
                            selected={activeSubTabCompactIndex === TabIndices.subTabCompactChassis}
                            text={I18n.t('chassis')}
                        />
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonExtenderCompactChassisAccessory}
                            disabled={!shouldEnableNonChassisTabs}
                            selected={activeSubTabCompactIndex === TabIndices.subTabCompactChassisAccessories}
                            text={I18n.t('chassisAccessories')}
                        />
                    </SubLevelTabList>
                    <TabPanel>
                        <ExtenderCompactTable />
                    </TabPanel>
                    <TabPanel>
                        <ExtenderCompactAccessoriesTable />
                    </TabPanel>
                </Tabs>
            </TabPanel>
        );
    };

    renderMainLevelTabButton = (mainTabIndex, text, productSubCategoryType, productIsSelected) => {
        const disabled = productIsSelected && !this.selectedProductIsSubCategoryType(productSubCategoryType);

        return (
            <MainLevelTabButton
                testId={`main-level-tab-button-extender-${productSubCategoryType}`}
                disabled={disabled}
                selected={this.props.activeMainTabIndex === mainTabIndex}
                text={text}
            />
        );
    };

    renderMainLevelTabList = (productIsSelected) => {
        return (
            <MainLevelTabList>
                {
                    this.renderMainLevelTabButton(
                        TabIndices.mainExtenderTabVario,
                        I18n.t('extenderTabVario'),
                        ProductSubCategoryType.vario,
                        productIsSelected,
                    )
                }
                {
                    this.renderMainLevelTabButton(
                        TabIndices.mainExtenderTabCompact,
                        I18n.t('extenderTabCompact'),
                        ProductSubCategoryType.compact,
                        productIsSelected,
                    )
                }
                {
                    this.renderMainLevelTabButton(
                        TabIndices.mainExtenderTabSecure,
                        I18n.t('extenderTabSecure'),
                        ProductSubCategoryType.secure,
                        productIsSelected,
                    )
                }
                {
                    this.renderMainLevelTabButton(
                        TabIndices.mainExtenderTabMisc,
                        I18n.t('extenderTabMisc'),
                        ProductSubCategoryType.misc,
                        productIsSelected,
                    )
                }
            </MainLevelTabList>
        );
    };

    renderSecureTabPanel = () => {
        return (
            <TabPanel className={tabPaneStyle.tabPanel}>
                <ExtenderSecureTable />
            </TabPanel>
        );
    };

    renderMiscTabPanel = () => {
        return (
            <TabPanel className={tabPaneStyle.tabPanel}>
                <ExtenderMiscTable />
            </TabPanel>
        );
    };

    renderVarioTabPanel = () => {
        const shouldEnableNonChassisTabs = this.shouldEnableNonChassisTabs();
        const activeSubTabVarioIndex     = (
            shouldEnableNonChassisTabs ?
                this.props.activeSubTabVarioIndex :
                0
        );
        const singleOrderContext         = this.props.pathname === Routes.designerProductSingleOrders;

        return (
            <TabPanel className={tabPaneStyle.tabPanel}>
                <Tabs
                    selectedIndex={activeSubTabVarioIndex}
                    onSelect={this.varioSubTabChanged}
                >
                    <SubLevelTabList>
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonExtenderVarioChassis}
                            disabled={false}
                            selected={activeSubTabVarioIndex === TabIndices.subTabVarioChassis}
                            text={I18n.t('chassis')}
                        />
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonExtenderVarioChassisAccessory}
                            disabled={!shouldEnableNonChassisTabs}
                            selected={activeSubTabVarioIndex === TabIndices.subTabCompactChassisAccessories}
                            text={I18n.t('chassisAccessories')}
                        />
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonExtenderVarioMainboard}
                            disabled={!shouldEnableNonChassisTabs}
                            selected={activeSubTabVarioIndex === TabIndices.subTabVarioMainboards}
                            text={I18n.t('mainboards')}
                        />
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonExtenderVarioAddOnModule}
                            disabled={!shouldEnableNonChassisTabs}
                            selected={activeSubTabVarioIndex === TabIndices.subTabVarioAddOnModules}
                            text={I18n.t('addOnModules')}
                        />
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonExtenderVarioOtherModule}
                            disabled={!shouldEnableNonChassisTabs}
                            selected={activeSubTabVarioIndex === TabIndices.subTabVarioOtherModules}
                            text={I18n.t('otherModules')}
                        />
                        <SubLevelTabButton
                            testId={TestIds.subLevelTabButtonExtenderVarioUsb20Module}
                            disabled={!shouldEnableNonChassisTabs}
                            selected={activeSubTabVarioIndex === TabIndices.subTabVarioUsb20Modules}
                            text={I18n.t('usb20Modules')}
                        />
                    </SubLevelTabList>
                    <TabPanel>
                        <ExtenderVarioChassisTable />
                    </TabPanel>
                    <TabPanel>
                        <ExtenderVarioChassisAccessoryTable
                            rejectProdcutTypeList={singleOrderContext
                                ? DataProvider.emptyProductTypeRejectList
                                : DataProvider.defaultProductTypeRejectList}
                        />
                    </TabPanel>
                    <TabPanel>
                        <ExtenderVarioMainboardTable />
                    </TabPanel>
                    <TabPanel>
                        <ExtenderVarioAddOnModuleTable />
                    </TabPanel>
                    <TabPanel>
                        <ExtenderVarioOtherModuleTable />
                    </TabPanel>
                    <TabPanel>
                        <ExtenderVarioUsb20ModuleTable />
                    </TabPanel>
                </Tabs>
            </TabPanel>
        );
    };

    /**
     * @returns {boolean|*}
     * @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-327
     */
    shouldEnableNonChassisTabs = () => {
        const productIsSelected  = this.productIsSelected();
        const singleOrderContext = this.props.pathname === Routes.designerProductSingleOrders;

        return (
            singleOrderContext ||
            productIsSelected
        );
    };

    varioSubTabChanged = (tabIndex) => {
        this.props.setActiveSubTabVarioIndex({
            activeSubTabVarioIndex: tabIndex,
        });
    };
}

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        activeMainTabIndex:       state.extenderProductBrowser.activeMainTabIndex,
        activeSubTabCompactIndex: state.extenderProductBrowser.activeSubTabCompactIndex,
        activeSubTabVarioIndex:   state.extenderProductBrowser.activeSubTabVarioIndex,
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-327
        pathname:            state.router.location.pathname,
        selectedProduct:     StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
        selectedProductMeta: state.activeProject.selectedProduct,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(ExtenderProductBrowserActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';
import { withTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import ProductDataValueLabel from '../ProductDataValueLabel';
import PropTypes from '../PropTypes';

const Component = ({
    milliwatts,
    value,
}) => {
    if (milliwatts) {
        return (
            <span
                className={styles.milliwattsLabel}
                title={`${milliwatts} ${I18n.t('milliwatts')}`}
            >
                {milliwatts}
                {' '}
                {I18n.t('milliwattsShort')}
            </span>
        );
    }

    return (
        <ProductDataValueLabel value={value} />
    );
};

Component.propTypes = {
    milliwatts: PropTypes.number,
};

Component.defaultProps = {
    milliwatts: 0,
};

export default withTranslation()(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// We just "extend" the original class
// @see https://github.com/adazzle/react-data-grid/blob/master/packages/react-data-grid-addons/src/cells/headerCells/filters/MultiSelectFilter.js
import React from 'react';

import { Filters } from 'react-data-grid-addons';

import TableAutoCompleteFilter from '../TableAutoCompleteFilter';

Filters.MultiSelectFilter.prototype.render = function() {
    return (
        <TableAutoCompleteFilter
            {...this.props}
            multiSelection={true}
        />
    );
};

export default Filters.MultiSelectFilter;

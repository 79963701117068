//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ProductCategoryType    from './ProductCategoryType';
import ProductSlotType        from './ProductSlotType';
import ProductSubCategoryType from './ProductSubCategoryType';

const TestIds = {
    backButton:                                        'back-button',
    bottomPane:                                        'pane-bottom',
    buttonbarButtonNewProject:                         'button-bar-button-new-project',
    headerButtonCloseProject:                          'header-button-close-project',
    headerButtonDataProtection:                        'header-button-data-protection',
    headerButtonExport:                                'header-button-export',
    headerButtonImport:                                'header-button-import',
    headerButtonPartsList:                             'header-button-parts-list',
    headerButtonPrint:                                 'header-button-print',
    headerButtonRequestQuote:                          'header-button-request-quote',
    leftPane:                                          'pane-left',
    leftPaneAddButton:                                 'left-pane-add-button',
    leftPaneProductCategory:                           'left-pane-product-category-',
    mainLevelTabButtonMatrixCompact:                   `main-level-tab-button-${ProductCategoryType.matrix}-${ProductSubCategoryType.compact}`,
    mainLevelTabButtonMatrixEnterprise:                `main-level-tab-button-${ProductCategoryType.matrix}-${ProductSubCategoryType.enterprise}`,
    mainLevelTabButtonMatrixFlex:                      `main-level-tab-button-${ProductCategoryType.matrix}-${ProductSubCategoryType.flex}`,
    mainLevelTabList:                                  'main-level-tab-list',
    overlay:                                           'overlay',
    overlayDontShowAgainCheckbox:                      'overlay-dont-show-again-checkbox',
    overlayOkButton:                                   'overlay-ok-button',
    productCategorySelectionPane:                      'product-category-selection-pane',
    productSlotListSlot:                               'product-slot-list-slot',
    projectOverviewEquipmentAndSingleOrders:           'project-overview-pane-equipment-and-single-orders',
    projectOverviewProductCategoryExtender:            `project-overview-pane-product-category-${ProductCategoryType.extender}`,
    projectOverviewProductCategoryMatrix:              `project-overview-pane-product-category-${ProductCategoryType.matrix}`,
    rightPane:                                         'pane-right',
    rightPaneProductTitle:                             'right-pane-product-title',
    singleOrderExtenderShowButton:                     `single-order-show-button-${ProductCategoryType.extender}`,
    singleOrderMatrixShowButton:                       `single-order-show-button-${ProductCategoryType.matrix}`,
    singleOrderShowButton:                             'single-order-show-button-',
    slotSfpList:                                       'slot-sfp-list',
    slotSfpListSlot:                                   'slot-sfp-list-slot',
    specificationList:                                 'specification-list',
    specificationListItemLeft:                         'specification-list-item-left',
    specificationListItemRight:                        'specification-list-item-right',
    statusBar:                                         'status-bar',
    statusBarConPorts:                                 'status-bar-con-ports',
    statusBarCpuPorts:                                 'status-bar-cpu-ports',
    statusBarDetailsButton:                            'status-bar-details-button',
    statusBarExtenderPorts:                            'status-bar-extender-ports',
    statusBarMatrixPorts:                              'status-bar-matrix-ports',
    statusBarMiscPorts:                                'status-bar-misc-ports',
    subLevelTabButtonExtenderCompactChassis:           `sub-level-tab-button-${ProductCategoryType.extender}-${ProductSubCategoryType.compact}-${ProductSlotType.chassis}`,
    subLevelTabButtonExtenderCompactChassisAccessory:  `sub-level-tab-button-${ProductCategoryType.extender}-${ProductSubCategoryType.compact}-${ProductSlotType.chassisAccessory}`,
    subLevelTabButtonExtenderVarioAddOnModule:         `sub-level-tab-button-${ProductCategoryType.extender}-${ProductSubCategoryType.vario}-${ProductSlotType.addOnModule}`,
    subLevelTabButtonExtenderVarioChassis:             `sub-level-tab-button-${ProductCategoryType.extender}-${ProductSubCategoryType.vario}-${ProductSlotType.chassis}`,
    subLevelTabButtonExtenderVarioChassisAccessory:    `sub-level-tab-button-${ProductCategoryType.extender}-${ProductSubCategoryType.vario}-${ProductSlotType.chassisAccessory}`,
    subLevelTabButtonExtenderVarioCpuBoard:            `sub-level-tab-button-${ProductCategoryType.extender}-${ProductSubCategoryType.vario}-${ProductSlotType.cpuBoard}`,
    subLevelTabButtonExtenderVarioEquipment:           `sub-level-tab-button-${ProductCategoryType.extender}-${ProductSubCategoryType.vario}-${ProductSlotType.equipment}`,
    subLevelTabButtonExtenderVarioIoBoard:             `sub-level-tab-button-${ProductCategoryType.extender}-${ProductSubCategoryType.vario}-${ProductSlotType.ioBoard}`,
    subLevelTabButtonExtenderVarioMainboard:           `sub-level-tab-button-${ProductCategoryType.extender}-${ProductSubCategoryType.vario}-${ProductSlotType.mainboard}`,
    subLevelTabButtonExtenderVarioOtherModule:         `sub-level-tab-button-${ProductCategoryType.extender}-${ProductSubCategoryType.vario}-${ProductSlotType.otherModule}`,
    subLevelTabButtonExtenderVarioUsb20Module:         `sub-level-tab-button-${ProductCategoryType.extender}-${ProductSubCategoryType.vario}-${ProductSlotType.usb20Module}`,
    subLevelTabButtonMatrixCompactChassis:             `sub-level-tab-button-${ProductCategoryType.matrix}-${ProductSubCategoryType.compact}-${ProductSlotType.chassis}`,
    subLevelTabButtonMatrixCompactChassisAccessory:    `sub-level-tab-button-${ProductCategoryType.matrix}-${ProductSubCategoryType.compact}-${ProductSlotType.chassisAccessory}`,
    subLevelTabButtonMatrixEnterpriseChassis:          `sub-level-tab-button-${ProductCategoryType.matrix}-${ProductSubCategoryType.enterprise}-${ProductSlotType.chassis}`,
    subLevelTabButtonMatrixEnterpriseChassisAccessory: `sub-level-tab-button-${ProductCategoryType.matrix}-${ProductSubCategoryType.enterprise}-${ProductSlotType.chassisAccessory}`,
    subLevelTabButtonMatrixEnterpriseCpuBoard:         `sub-level-tab-button-${ProductCategoryType.matrix}-${ProductSubCategoryType.enterprise}-${ProductSlotType.cpuBoard}`,
    subLevelTabButtonMatrixEnterpriseIoBoard:          `sub-level-tab-button-${ProductCategoryType.matrix}-${ProductSubCategoryType.enterprise}-${ProductSlotType.ioBoard}`,
    subLevelTabButtonMatrixFlexChassis:                `sub-level-tab-button-${ProductCategoryType.matrix}-${ProductSubCategoryType.flex}-${ProductSlotType.chassis}`,
    subLevelTabButtonMatrixFlexChassisAccessory:       `sub-level-tab-button-${ProductCategoryType.matrix}-${ProductSubCategoryType.flex}-${ProductSlotType.chassisAccessory}`,
    subLevelTabButtonMatrixFlexChassisCustomDesign:    `sub-level-tab-button-${ProductCategoryType.matrix}-${ProductSubCategoryType.flex}-${ProductSlotType.chassisCustomDesign}`,
    subLevelTabButtonMatrixFlexChassisFrontPlate:      `sub-level-tab-button-${ProductCategoryType.matrix}-${ProductSubCategoryType.flex}-${ProductSlotType.frontPlate}`,
    subLevelTabButtonFullHdEasyline:                   `sub-level-tab-button-${ProductCategoryType.fullIp}-${ProductSubCategoryType.fullIpFullHd}-${ProductSlotType.easyline}`,
    subLevelTabButtonFullHdFlexline:                   `sub-level-tab-button-${ProductCategoryType.fullIp}-${ProductSubCategoryType.fullIpFullHd}-${ProductSlotType.flexline}`,
    subLevelTabButtonFullHdUsb:                        `sub-level-tab-button-${ProductCategoryType.fullIp}-${ProductSubCategoryType.fullIpFullHd}-${ProductSlotType.usb}`,
    subLevelTabButtonFullHdAccessories:                `sub-level-tab-button-${ProductCategoryType.fullIp}-${ProductSubCategoryType.fullIpFullHd}-${ProductSlotType.accessory}`,
    subLevelTabButtonFullHdMatrixManagement:           `sub-level-tab-button-${ProductCategoryType.fullIp}-${ProductSubCategoryType.fullIpFullHd}-${ProductSlotType.matrixManagement}`,
    subLevelTabButton4KMedia4Connect:                  `sub-level-tab-button-${ProductCategoryType.fullIp}-${ProductSubCategoryType.fullIp4K}-${ProductSlotType.media4Connect}`,
    subLevelTabButton4KScalableline:                   `sub-level-tab-button-${ProductCategoryType.fullIp}-${ProductSubCategoryType.fullIp4K}-${ProductSlotType.scalableline}`,
    subLevelTabButton4KAccessories:                    `sub-level-tab-button-${ProductCategoryType.fullIp}-${ProductSubCategoryType.fullIp4K}-${ProductSlotType.accessory}`,
    subLevelTabButton4KMatrixManagement:               `sub-level-tab-button-${ProductCategoryType.fullIp}-${ProductSubCategoryType.fullIp4K}-${ProductSlotType.matrixManagement}`,
    subSlotList:                                       'sub-slot-list',
    subSlotListSlot:                                   'sub-slot-list-slot',
    tableFilterButton:                                 'table-filter-button',
    tableRow:                                          'table-row',
    table:                                             'table',
    togglePaneButton:                                  'toggle-pane-button-',
    togglePaneButtonBottom:                            'toggle-pane-button-bottom',
    togglePaneButtonLeft:                              'toggle-pane-button-left',
    togglePaneButtonRight:                             'toggle-pane-button-right',
};

export default TestIds;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n   from 'i18next';
import moment from 'moment';

/**
 * This class is also present in the backend project src/Helper/ProjectTitleHelper.php
 */
class ProjectTitleHelper {
    static getProjectFileNameWithoutExtension(projectTitle) {
        projectTitle        = ProjectTitleHelper.getProjectTitle(projectTitle);
        const now           = moment();
        const formattedDate = now.format(I18n.t('fileNameDateFormat'));
        const cleanFileName = projectTitle.replace(/[^a-z0-9-]+/gi, '-').toLowerCase();

        return `${cleanFileName}-${formattedDate}`;
    }

    static getProjectTitle(projectTitle) {
        const fallback = I18n.t('projectFallbackName');

        return projectTitle || fallback;
    }

    static getTitleOfActiveProject = (activeProject) => {
        const { metaData } = activeProject;
        let { title }      = metaData;

        if (!title) {
            title = I18n.t('noProjectNameGiven');
        }

        title = title.replace(/[^a-z0-9]/gi, '_');
        title = title.toLowerCase();
        title = title.replace(/_+/g, '_');

        return title;
    };
}

export default ProjectTitleHelper;

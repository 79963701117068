//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames  from 'classnames';
import { connect } from 'react-redux';

import CardOverlayModes from '@constants/CardOverlayModes';

import styles    from './styles.module.scss';
import PropTypes from '../PropTypes';

const Component = ({
    slotData,
    cardOverlayMode,
    cardOverlayModeRenderExtenderId,
}) => {
    const getTextLeft  = () => {
        switch (cardOverlayMode) {
            // @formatter:off
            case CardOverlayModes.articleNumber: return slotData.productData.partNo;
            case CardOverlayModes.moduleName: return slotData.customName;
      // @formatter:on
        }

        return null;
    };
    const getTextRight = () => {
        if (cardOverlayModeRenderExtenderId) {
            return slotData.extenderId;
        }

        return null;
    };

    if (cardOverlayMode !== CardOverlayModes.none || cardOverlayModeRenderExtenderId) {
        const textLeft  = getTextLeft();
        const textRight = getTextRight();

        if (textLeft || textRight) {
            return (
                <div
                    className={classNames(
                        styles.slotOverlay,
                        styles.slotOverlayTextLeft,
                    )}
                >
                    {
                        textLeft
                            ? (
                                <span
                                    className={classNames(
                                        styles.slotOverlayText,
                                        styles.slotOverlayTextLeft,
                                    )}
                                >
                                    {textLeft}
                                </span>
                            )
                            : null
                    }
                    {
                        textRight
                            ? (
                                <span
                                    className={classNames(
                                        styles.slotOverlayText,
                                        styles.slotOverlayTextRight,
                                    )}
                                >
                                    <span>{textRight}</span>
                                </span>
                            )
                            : null
                    }
                </div>
            );
        }
    }

    return null;
};

Component.propTypes = {
    slotData: PropTypes.object,
};

Component.defaultProps = {
    slotData: null,
};

const mapStateToProps = (state) => {
    return {
        cardOverlayMode:                 state.settings.cardOverlayMode,
        cardOverlayModeRenderExtenderId: state.settings.cardOverlayModeRenderExtenderId,
    };
};

export default connect(mapStateToProps, null)(Component);

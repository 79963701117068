import defaultDragDropConfiguration from './default';

export default {
    dragBehavior: {
        beginDrag(props) {
            console.log('ProductTableRow: beginDrag (props)', props);

            props.dragStart();

            return (
                {
                    productAccessoryType: props.productAccessoryType,
                    productCategoryType:  props.productCategoryType,
                    productId:            props.productId,
                    productSlotType:      props.productSlotType,
                }
            );
        },
        canDrag(props) {
            const productIsIncompatible = props.productIsIncompatible;
            const productIsEndOfLife    = props.productIsEndOfLife;

            return !productIsIncompatible && !productIsEndOfLife;
        },
        endDrag(props, monitor, component) {
            const dropResult = monitor.getDropResult();

            props.dragStop();

            console.log(
                'ProductTableRow: endDrag (props, monitor, component, dropResult)',
                props,
                monitor,
                component,
                dropResult,
            );
        },
    },
    dragCollect:  defaultDragDropConfiguration.dragCollect,
};

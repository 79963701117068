//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import ImageDownloader from '@helper/ImageDownloader';
import StateHelper from '@helper/State';

import productImages from '../../data/dataPrecompiledImage';
import ActionGroup from '../ActionGroup';
import ActionGroupItem from '../ActionGroupItem';
import IconType from '../Icon/IconType';

const Component = ({
    selectedSingleOrderSlot,
}) => {
    function downloadImage(partNo) {
        const imageURL = productImages[partNo];
        const fileName = `${partNo}.png`;

        ImageDownloader.downloadImageByURL(imageURL, fileName);
    }

    function downloadImageButtonPressed() {
        const { productData } = selectedSingleOrderSlot;
        const { partNo } = productData;

        downloadImage(partNo);
    }

    return (
        <ActionGroup>
            {renderDownloadImageButton()}
        </ActionGroup>
    );

    function renderDownloadImageButton() {
        return (
            <ActionGroupItem
                iconType={IconType.import2}
                onClick={downloadImageButtonPressed}
                text={I18n.t('downloadImage')}
                tooltipText={I18n.t('downloadSingleOrderImageTip')}
            />
        );
    }
};

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => ({
    selectedSingleOrderSlot: StateHelper.getSelectedSingleOrderSlot(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(ActiveProjectActions), dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component)));

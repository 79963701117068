import React               from 'react';

import classNames          from 'classnames';
import _                   from 'lodash';

import SelectionHelper     from '@helper/SelectionHelper';

import SegmentControlMode  from './SegmentControlMode';
import SegmentControlTheme from './SegmentControlTheme';
import styles              from './styles.module.scss';

const Component = ({
    mode           = SegmentControlMode.button,
    segmentClicked = _.noop,
    segments       = [],
    selectedKey    = null,
    theme          = SegmentControlTheme.default,
}) => {
    const renderSegments = () => (segments || []).map((segment, segmentIndex) => (
        <div
            className={classNames(
                styles.segmentControlSegment,
                mode === SegmentControlMode.select &&
                selectedKey === segment.key
                    ? styles.segmentControlSegmentSelected
                    : null,
                SelectionHelper.get(theme, {
                    [SegmentControlTheme.default]: styles.default,
                    [SegmentControlTheme.darkBackgroundSmallText]:
                                                   styles.darkBackgroundSmallText,
                }),
            )}
            key={segmentIndex}
            onClick={() => {
                segmentClicked(segmentIndex, segment);
            }}
        >
            {segment.title}
        </div>
    ));

    return (
        <div
            className={classNames(
                styles.segmentControl,
                SelectionHelper.get(theme, {
                    [SegmentControlTheme.default]: styles.default,
                    [SegmentControlTheme.darkBackgroundSmallText]:
                                                   styles.darkBackgroundSmallText,
                }),
            )}
        >
            {renderSegments()}
        </div>
    );
};

export default Component;

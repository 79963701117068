//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import styles    from './styles.module.scss';
import Icon      from '../Icon';
import IconType  from '../Icon/IconType';
import PropTypes from '../PropTypes';

const Component = ({
    eMailAddress = '',
    iconType     = null,
    phoneNumber  = '',
    text         = '',
    title        = '',
}) => {
    function getPhoneNumberClean() {
        if (phoneNumber) {
            const cleanPhoneNumber = phoneNumber.replace(/\D/g, '');

            return `00${cleanPhoneNumber}`;
        }

        return '';
    }

    const cleanPhoneNumber = getPhoneNumberClean();

    return (
        <div className={styles.contactItem}>
            <div className={styles.contactItemIconWrapper}>
                <Icon iconType={iconType} />
            </div>
            <h3>{title}</h3>
            <p>{text}</p>
            <p>
                <a href={`tel:${cleanPhoneNumber}`}>
                    {phoneNumber}
                </a>
                <br />
                <a href={`mailto:${eMailAddress}`}>
                    {eMailAddress}
                </a>
            </p>
        </div>
    );
};

Component.propTypes = {
    eMailAddress: PropTypes.string,
    iconType:     PropTypes.oneOf(Object.values(IconType)),
    phoneNumber:  PropTypes.string,
    text:         PropTypes.string,
    title:        PropTypes.string,
};

export default Component;

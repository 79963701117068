//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                            from 'i18next';
import { browserName, browserVersion } from 'react-device-detect';

import styles          from './styles.module.scss';
import Header          from '../Header';
import HeaderMode      from '../Header/HeaderMode';
import TitleBanner     from '../TitleBanner';
import TitleBannerMode from '../TitleBanner/TitleBannerMode';

const unsupportedChromeVersions = [
    '106',
];

const Component = () => {
    const isUnsupported = () => {
        if (
            browserName === 'Chrome' &&
            unsupportedChromeVersions.includes(browserVersion)
        ) {
            return true;
        }

        return false;
    };

    if (isUnsupported()) {
        return (
            <div
                aria-hidden={'true'}
                className={styles.browserUnsupportedOverlay}
            >
                <Header mode={HeaderMode.smallScreenOverlay} />
                <TitleBanner mode={TitleBannerMode.app} />
                <div className={styles.browserUnsupportedOverlayContent}>
                    <h2>{I18n.t('updateBrowserTitle')}</h2>
                    <p>{I18n.t('updateBrowserText', {
                        browserName,
                        browserVersion,
                    })}</p>
                </div>
            </div>
        );
    }

    return null;
};

Component.propTypes = {};

Component.defaultProps = {};

export default Component;

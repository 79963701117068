//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames          from 'classnames';
import I18n                from 'i18next';
import { withTranslation } from 'react-i18next';

import SelectionHelper from '@helper/SelectionHelper';

import TooltipPosition from './TooltipPosition';
import TooltipTheme    from './TooltipTheme';
import styles          from './styles.module.scss';
import Icon            from '../Icon';
import IconType        from '../Icon/IconType';
import PropTypes       from '../PropTypes';

const Component = ({
    iconType = IconType.tooltip,
    position = TooltipPosition.bottom,
    text     = '',
    theme    = TooltipTheme.gray,
    title    = null,
}) => {
    const getNormalizedPosition      = () => {
        return SelectionHelper.get(
            position,
            {
                [TooltipPosition.bottomRight]: TooltipPosition.bottom,
                [TooltipPosition.topRight]:    TooltipPosition.top,
            },
            position,
        );
    };
    const getOffset                  = () => {
        return SelectionHelper.get(
            position,
            {
                [TooltipPosition.bottomRight]: '{"top": 10, "left": 182}',
                [TooltipPosition.topRight]:    '{"top": 0, "left": 182}',
            },
            '',
        );
    };
    const getTooltipClassForPosition = () => {
        return SelectionHelper.get(
            position,
            {
                [TooltipPosition.bottomRight]: 'bottom-right',
                [TooltipPosition.topRight]:    'top-right',
            },
            '',
        );
    };
    const defaultClasses             = classNames('tooltipStyle', 'tooltipWrapper');
    const extraClass                 = getTooltipClassForPosition();
    const offset                     = getOffset();
    const normalizedPosition         = getNormalizedPosition();
    const renderTitle                = () => {
        if (title) {
            return title;
        }
        return I18n.t('help');
    };

    return (
        <span
            className={classNames(
                styles.tooltip,
                SelectionHelper.get(theme, {
                    [TooltipTheme.gray]:   'tooltipWrapper tooltipWrapperGray',
                    [TooltipTheme.orange]: 'tooltipWrapper tooltipWrapperOrange',
                    [TooltipTheme.white]:  'tooltipWrapper tooltipWrapperWhite',
                }),
            )}
            data-class={`${defaultClasses} ${extraClass}`}
            data-offset={offset}
            data-place={normalizedPosition}
            data-tip={`
        <div class="tooltipContentWrapper">
            <h5>
                ${renderTitle()}
            </h5>
            <p>
                ${text}
            </p>
        </div>
    `}
        >
            <Icon iconType={iconType} />
        </span>
    );
};

Component.propTypes = {
    iconType: PropTypes.oneOf(Object.values(IconType)),
    position: PropTypes.oneOf(Object.values(TooltipPosition)),
    text:     PropTypes.string,
    theme:    PropTypes.oneOf(Object.values(TooltipTheme)),
    title:    PropTypes.string,
};

export default withTranslation()(Component);

import React from 'react';

import TestIds from '@constants/TestIds';

import styles from './styles.module.scss';
import PropTypes from '../PropTypes';
import Tooltip from '../Tooltip';

const Component = ({
    children    = [],
    title       = '',
    tooltipText = '',
}) => (
    <div
        data-testId={TestIds.specificationList}
        className={styles.specificationList}
    >
        {renderTitle(title, tooltipText)}
        {renderList(children)}
    </div>
);

const renderList = (children) => (
    <dl className={styles.specificationListInner}>{children}</dl>
);

const renderTitle = (title, tooltipText) => [
    <h3 className={styles.specificationListTitle} key={'h3'}>
        {title}
    </h3>,
    tooltipText
        ? (
            <div className={styles.specificationListTooltip} key={'tooltip'}>
                <Tooltip text={tooltipText} />
            </div>
        )
        : null,
];

Component.propTypes = {
    children: PropTypes.children,
    title: PropTypes.string,
    tooltipText: PropTypes.string,
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import ProductCategoryType      from '@constants/ProductCategoryType';
import Routes                   from '@constants/Routes';
import TestIds                  from '@constants/TestIds';
import { isKVMTecEnabled }      from '@helper/Configuration';

import styles                  from './styles.module.scss';
import LeftPaneLinkButton      from '../LeftPaneLinkButton';
import LeftPaneProductCategory from '../LeftPaneProductCategory';

const Component = ({
    deleteModeActive,
    singleOrders,
    singleOrdersEnabled,
}) => {
    function getSingleOrderCount() {
        let singleOrderCount        = 0;
        const singleOrderCategories = singleOrders;

        if (singleOrderCategories) {
            for (const singleOrderCategoryKey in singleOrderCategories) {
                const singleOrderCategory = singleOrderCategories[singleOrderCategoryKey];

                for (const singleOrder of singleOrderCategory) {
                    if (singleOrder.productData) {
                        singleOrderCount += singleOrder.amount;
                    }
                }
            }

            return singleOrderCount;
        }

        return singleOrderCount;
    }

    function renderFullIpProductCategory() {
        if (!isKVMTecEnabled()) {
            return null;
        }

        return (
            <LeftPaneProductCategory
                testId={TestIds.projectOverviewProductCategoryExtender}
                label={I18n.t('fullIpAndSwitching')}
                productCategoryType={ProductCategoryType.fullIp}
            />
        );
    }

    function renderExtenderProductCategory() {
        return (
            <LeftPaneProductCategory
                testId={TestIds.projectOverviewProductCategoryExtender}
                label={I18n.t('extender')}
                productCategoryType={ProductCategoryType.extender}
            />
        );
    }

    function renderMatrixProductCategory() {
        return (
            <LeftPaneProductCategory
                testId={TestIds.projectOverviewProductCategoryMatrix}
                label={I18n.t('matrixSwitch')}
                productCategoryType={ProductCategoryType.matrix}
            />
        );
    }

    function renderSingleOrdersButton() {
        const singleOrderCount = getSingleOrderCount();

        return (
            <LeftPaneLinkButton
                testId={TestIds.projectOverviewEquipmentAndSingleOrders}
                badeCount={singleOrderCount}
                label={
                    singleOrdersEnabled ?
                        I18n.t('equipmentAndSingleOrders') :
                        I18n.t('equipment')
                }
                route={Routes.designerProductSingleOrders}
            />
        );
    }

    return (
        <div className={styles.projectOverviewPane}>
            <LeftPaneLinkButton
                label={I18n.t('project')}
                route={Routes.designerProjectDetails}
            />
            {renderSingleOrdersButton()}
            {renderMatrixProductCategory()}
            {renderExtenderProductCategory()}
            {renderFullIpProductCategory()}
        </div>
    );
};

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => (
    {
        deleteModeActive:    state.activeProject.deleteModeActive,
        singleOrders:        state.activeProject.singleOrders,
        singleOrdersEnabled: state.settings.singleOrdersEnabled,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(ActiveProjectActions), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';
import _    from 'lodash';

import Limitations from '@constants/Limitations';
import SlotType    from '@constants/SlotType';
import WarningType from '@constants/WarningType';
import Cast        from '@helper/Cast';

import ActiveProjectsFactory from '../factories/activeProjects';

class LimitationRequiresFanInChassisCalculator {
    static doesNotRequireFan(product) {
        if (product) {
            for (const slotIndex in product.subProducts.slot) {
                const slot = product.subProducts.slot[slotIndex];

                if (slot.productData) {
                    for (const limitation of slot.productData.limitations) {
                        if (
                            limitation.type === Limitations.requiresFanInChassis &&
                            limitation.partNo === product.productData.partNo
                        ) {
                            return false;
                        }
                    }
                }
            }
        }

        return true;
    }

    static getModuleFanCount(product) {
        let moduleFanCount = 0;

        if (product) {
            for (const slotIndex in product.subProducts.slot) {
                const slot = product.subProducts.slot[slotIndex];

                if (slot.productData && slot.productData.type === SlotType.fan) {
                    ++moduleFanCount;
                }
            }
        }

        return moduleFanCount;
    }

    static getModuleFanPosition(product) {
        if (product) {
            for (const slotIndex in product.subProducts.slot) {
                const slot = product.subProducts.slot[slotIndex];

                if (slot.productData && slot.productData.type === SlotType.fan) {
                    return Cast.int(slotIndex);
                }
            }
        }

        return -1;
    }

    static hasChassisFan(product) {
        const chassisFanProductData = _.get(product, 'subProducts.fan[0].productData');

        return chassisFanProductData;
    }

    static hasModuleFanOnPosition(product, position) {
        if (product) {
            for (const slotIndex in product.subProducts.slot) {
                const slot = product.subProducts.slot[slotIndex];

                if (slot.productData && slot.productData.type === SlotType.fan) {
                    const slotIndexInt = Cast.int(slotIndex);

                    if (slotIndexInt === position) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    static updateProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        productWarnings,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        return false;
    };

    static updateSubProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        subProduct,
        subProductIndex,
        productWarnings,
        subProductWarnings,
        subProductCategory,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        const doesNotRequireFan = this.doesNotRequireFan(product);

        if (limitation.partNo === product.productData.partNo) {
            const chassisFanPosition  = this.getModuleFanPosition(product);
            const bestModuleSlotIndex = Cast.int(limitation.value);

            if (chassisFanPosition === -1 && this.doesNotRequireFan(product)) {
                productWarnings.push(ActiveProjectsFactory.getWarning(
                    WarningType.information,
                    I18n.t('limitationRecommendsModuleFanInChassis1', {
                        slot: bestModuleSlotIndex,
                    }),
                ));

                return true;
            }

            if (
                chassisFanPosition > -1 &&
                chassisFanPosition + 1 !== bestModuleSlotIndex &&
                !this.hasModuleFanOnPosition(product, bestModuleSlotIndex - 1) &&
                !this.hasChassisFan(product)
            ) {
                const moduleFanCount = this.getModuleFanCount(product);

                productWarnings.push(ActiveProjectsFactory.getWarning(
                    WarningType.error,
                    I18n.t(
                        (
                            moduleFanCount < 2 ?
                                'limitationRecommendsModuleFanInChassis2' :
                                'limitationRecommendsModuleFanInChassis3'
                        ),
                        {
                            slot: bestModuleSlotIndex,
                        },
                    ),
                ));

                return true;
            }
        }

        return false;
    };
}

export default LimitationRequiresFanInChassisCalculator;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { SettingActions }  from '@slices/settings';
import AssemblyOrderHelper from '@helper/AssemblyOrder';

import styles              from './styles.module.scss';
import PropTypes           from '../PropTypes';
import SegmentControl      from '../SegmentControl';
import SegmentControlMode  from '../SegmentControl/SegmentControlMode';
import SegmentControlTheme from '../SegmentControl/SegmentControlTheme';

class AssemblyOrderSegmentControl extends React.Component {
    render() {
        return (
            <div
                className={styles.assemblyOrderSegmentControl}
            >
                <div
                    title={I18n.t('assemblyOrder')}
                    className={styles.label}
                >
                    {I18n.t('assemblyOrder')}
                </div>
                <SegmentControl
                    mode={SegmentControlMode.select}
                    segments={this.getSegments()}
                    theme={SegmentControlTheme.darkBackgroundSmallText}
                    segmentClicked={this.orderChanged}
                    selectedKey={this.props.assemblyOrder}
                />
            </div>
        );
    }

    orderChanged = (segmentIndex, segment) => {
        this.props.setAssemblyOrder({
            assemblyOrder: segment.key,
        });
    };

    getSegments = () => {
        const choices = AssemblyOrderHelper.getChoices();

        return _.map(
            choices,
            (choice) => (
                {
                    title: I18n.t(`assemblyOrder${choice}`),
                    key:   choice,
                }
            ),
        );
    };
}

AssemblyOrderSegmentControl.propTypes = {
    assemblyOrder: PropTypes.string,
};

AssemblyOrderSegmentControl.defaultProps = {
    assemblyOrder: AssemblyOrderHelper.getDefaultAssemblyOrder(),
};

const mapStateToProps = (state) => (
    {
        assemblyOrder: _.get(state, 'settings.assemblyOrder'),
        leftPaneSize:  _.get(state, 'designerLayout.leftPaneSize'),
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(SettingActions), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(AssemblyOrderSegmentControl));

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import AccessoryTypes             from '@constants/AccessoryTypes';
import DropResult                 from '@constants/DropResult';
import DropValidation             from '@constants/DropValidation';
import DataProvider               from '@helper/DataProvider';
import KeyHandler                 from '@helper/KeyHandler';
import LimitationsCalculator      from '@store/calculators/limitations';
import PowerConsumptionCalculator from '@store/calculators/powerConsumption';
import ActiveProjectsFactory      from '@store/factories/activeProjects';

const getDropValidation = (dragItemData, dropTargetProps, indexToAdd, dropEffect) => {
    if (
        dragItemData &&
        dragItemData.productId &&
        dropTargetProps
    ) {
        if (indexToAdd > -1) {
            const dragItemProductData = DataProvider.getById(dragItemData.productId);
            const selectedProduct     = dropTargetProps.selectedProduct;
            const index               = dropTargetProps.index + indexToAdd;

            if (
                selectedProduct &&
                dropTargetProps.type &&
                index !== null &&
                dragItemProductData.type !== AccessoryTypes.sfp
            ) {
                const targetSlotIsDoubleSlot   = dropTargetProps.isDoubleSlot;
                const keyHandler               = KeyHandler.getInstance();
                const futureSelectedProduct    = _.cloneDeep(selectedProduct);
                const futureSelectedSubProduct = futureSelectedProduct.subProducts[dropTargetProps.type][dropTargetProps.index];

                if (targetSlotIsDoubleSlot) {
                    return DropValidation.forbidden;
                }

                if (futureSelectedSubProduct) {
                    futureSelectedSubProduct.productData = dragItemProductData;

                    if (
                        dragItemData.fromSlotIndex &&
                        dragItemData.fromSlotType &&
                        (
                            dropEffect !== DropResult.copy &&
                            // This is a workaround because we dont know if the user
                            // is holding the alt key here or not
                            //
                            // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-761
                            !keyHandler.getAltKeyPressed()
                        )
                    ) {
                        futureSelectedProduct.subProducts[dragItemData.fromSlotType][dragItemData.fromSlotIndex] = ActiveProjectsFactory.getEmptyProductSlot();
                    }

                    futureSelectedProduct.powerConsumptionInMilliAmps = PowerConsumptionCalculator.getPowerConsumption(
                        futureSelectedProduct,
                        futureSelectedProduct.subProducts,
                    );

                    const slotIsValid = LimitationsCalculator.slotIsValid(
                        futureSelectedProduct,
                        futureSelectedSubProduct,
                        futureSelectedProduct.powerConsumptionInMilliAmps,
                        dropTargetProps.type,
                        index,
                    );

                    console.log(
                        'getDropStatus: selectedProduct, dragItemData, dragItemProductData, dropTargetProps, dropTargetProps.type, dropTargetProps.index, slotIsValid',
                        selectedProduct,
                        dragItemData,
                        dragItemProductData,
                        dropTargetProps,
                        dropTargetProps.type,
                        index,
                        slotIsValid,
                    );

                    if (slotIsValid) {
                        return DropValidation.allowed;
                    }

                    return DropValidation.forbidden;
                }
            }
        }

        return DropValidation.forbidden;
    }

    return DropValidation.unknown;
};

export default getDropValidation;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { DesignerLayoutActions } from '@slices/designerLayout';

const Component = ({ tableFilterToggleVisibility }) => {
    const toggleFilterButtonPressed = () => {
        tableFilterToggleVisibility();
    };

    return (
        [
            <KeyboardEventHandler
                key={'keyboardEventHandlerShiftAltFKeyDown'}
                handleEventType={'keydown'}
                handleKeys={['shift+alt+f']}
                onKeyEvent={toggleFilterButtonPressed}
            />,
        ]
    );
};

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(DesignerLayoutActions), dispatch);

export default connect(
    null,
    mapDispatchToProps,
)(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import ProductCategoryType from '@constants/ProductCategoryType';
import SlotType            from '@constants/SlotType';
import Voltages            from '@constants/Voltages';
import Cast                from '@helper/Cast';

class HeatDissipationCalculator {
    static getHeatDissipation = (product, currentPowerConsumptionInMilliamps) => {
        const heatDissipation = {
            chassis:     0,
            externalPsu: 0,
        };
        const productData     = product.productData;
        const productFanData  = _.get(product, 'subProducts.fan[0].productData', null);
        const calculationData = {
            currentPowerConsumptionInMilliamps,
            efficiency:                                 Cast.int(productData.efficiency) / 100,
            fanPowerConsumptionInMilliwatts:            (
                productFanData ?
                    Cast.int(productFanData.powerConsumptionInMilliwatts) :
                    Cast.int(productData.fanPowerConsumptionInMilliwatts)
            ),
            filledSlotCount:                            0,
            hasInternalPsu:                             (
                Cast.bool(productData.hasInternalPsu) ||
                                                            productData.categoryType === ProductCategoryType.matrix
            ),
            recommendedPowerConsumptionInMilliamps:     Cast.int(productData.recommendedPowerConsumptionInMilliamps),
            redundancyBoardPowerConsumptionInMilliamps: Cast.int(productData.redundancyBoardPowerConsumptionInMilliamps),
            slotCount:                                  product.subProducts[SlotType.slot].length,
        };

        if (productData.categoryType === ProductCategoryType.matrix) {
            heatDissipation.chassis = (
                currentPowerConsumptionInMilliamps * Voltages.matrix
            ) / calculationData.efficiency;
        } else {
            heatDissipation.chassis = (
                currentPowerConsumptionInMilliamps * Voltages.extender
            ) / calculationData.efficiency;
        }

        heatDissipation.externalPsu = (
            calculationData.hasInternalPsu ?
                0 :
                (
                    heatDissipation.chassis * (
                        1 - calculationData.efficiency
                    )
                )
        );

        heatDissipation.chassis -= heatDissipation.externalPsu;

        // Transform to watt
        heatDissipation.chassis     = Math.ceil(heatDissipation.chassis / 1000);
        heatDissipation.externalPsu = Math.ceil(heatDissipation.externalPsu / 1000);

        return heatDissipation;
    };
}

export default HeatDissipationCalculator;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                from 'i18next';
import _                   from 'lodash';
import Dropzone            from 'react-dropzone';
import { withTranslation } from 'react-i18next';

import styles              from './styles.module.scss';
import Icon                from '../Icon';
import IconType            from '../Icon/IconType';
import PropTypes           from '../PropTypes';
import UploadedFilePreview from '../UploadedFilePreview';

const Component = ({ filesChanged, multiple }) => {
    const [files, setFiles]         = React.useState(null);
    const deleteFile                = (file) => {
        const updatedFiles = _.without(files, file);
        setFiles(updatedFiles);
    };
    const onDrop                    = (acceptedFiles) => {
        let uploadedFiles = [];

        if (acceptedFiles.length > 0) {
            uploadedFiles = acceptedFiles;
        } else {
            uploadedFiles = [acceptedFiles];
        }

        console.log('FileDropZone: onDrop', uploadedFiles);
        setFiles(uploadedFiles);
        filesChanged(uploadedFiles);
    };
    const renderUploadedFilePreview = (file) => {
        const deleteFilePressed = () => {
            deleteFile(file);
        };

        return (
            <div
                className={styles.fileDropZoneUploadedFilePreviewWrapper}
                key={file.name}
            >
                <UploadedFilePreview
                    deleteButtonPressed={deleteFilePressed}
                    fileName={file.name}
                />
            </div>
        );
    };

    return (
        <div className={styles.fileDropZone}>
            <Dropzone
                multiple={multiple}
                onDrop={onDrop}
            >
                {({ getRootProps, getInputProps }) => (
                    <div className={styles.fileDropZoneOuter}>
                        <div {...getRootProps({
                            className: styles.fileDropZoneInner,
                        })}>
                            <input {...getInputProps()} />
                            <span
                                className={styles.fileDropZoneInnerIcon}
                            >
                                <Icon iconType={IconType.dropZone} />
                            </span>
                            <span className={styles.fileDropZoneInnerText}>
                                {I18n.t('fileDropZoneText')}
                            </span>
                        </div>
                    </div>
                )}
            </Dropzone>
            {_.map(files, renderUploadedFilePreview)}
        </div>
    );
};

Component.propTypes = {
    filesChanged: PropTypes.func,
    multiple:     PropTypes.bool,
};

Component.defaultProps = {
    filesChanged: _.noop,
    multiple:     false,
};

export default withTranslation()(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames          from 'classnames';
import { withTranslation } from 'react-i18next';

import AudioTypes      from '@constants/AudioTypes';
import SelectionHelper from '@helper/SelectionHelper';

import AudioLabelAlignment   from './AudioLabelAlignment';
import styles                from './styles.module.scss';
import Icon                  from '../Icon';
import IconType              from '../Icon/IconType';
import ProductDataValueLabel from '../ProductDataValueLabel';
import PropTypes             from '../PropTypes';

function Component({
    alignment,
    audio,
    value,
}) {
    function getIconType() {
        return SelectionHelper.get(
            audio,
            {
                [AudioTypes.audioAnalogBidirectional]:   IconType.audioAnalogBidirectional,
                [AudioTypes.audioBalanced]:              IconType.audioBalanced,
                [AudioTypes.audioAnalogDigital]:         IconType.audioAnalogDigital,
                [AudioTypes.audioDigitalBidirectional]:  IconType.audioDigitalBidirectional,
                [AudioTypes.audioDigitalUnidirectional]: IconType.audioDigitalUnidirectional,
            },
        );
    }

    function getTitle() {
        return SelectionHelper.getTranslation(
            audio,
            {
                [AudioTypes.audioAnalogBidirectional]:   'audioAnalogBidirectional',
                [AudioTypes.audioBalanced]:              'audioBalanced',
                [AudioTypes.audioAnalogDigital]:         'audioAnalogDigital',
                [AudioTypes.audioDigitalBidirectional]:  'audioDigitalBidirectional',
                [AudioTypes.audioDigitalUnidirectional]: 'audioDigitalUnidirectional',
            },
        );
    }

    if (audio) {
        const title = getTitle();

        if (!title) {
            console.warn('Unsupported audio', audio);
        }

        return (
            <span
                className={classNames(
                    styles.audioLabel,
                    SelectionHelper.get(
                        alignment,
                        {
                            [AudioLabelAlignment.left]:  styles.audioLabelLeft,
                            [AudioLabelAlignment.right]: styles.audioLabelRight,
                        },
                    ),
                )}
                title={title}
            >
                {renderIconAndText(title)}
            </span>
        );
    }

    return (
        <ProductDataValueLabel value={value} />
    );

    function renderIcon(iconType) {
        if (iconType) {
            return (
                <span className={styles.audioLabelIconWrapper}>
                    <Icon iconType={iconType} />
                </span>
            );
        }

        return null;
    }

    function renderIconAndText(title) {
        const iconType = getIconType();

        return (
            <>
                {renderIcon(iconType)}
                <span
                    className={classNames(
                        styles.audioText,
                        {
                            [styles.withoutIcon]: !iconType,
                        },
                    )}
                >
                    {title}
                </span>
            </>
        );
    }
}

Component.propTypes = {
    alignment: PropTypes.oneOf(Object.values(AudioLabelAlignment)),
    audio:     PropTypes.string,
};

Component.defaultProps = {
    alignment: null,
    audio:     null,
};

export default withTranslation()(Component);

import React from 'react';

import ProductCategoryType from '@constants/ProductCategoryType';
import ProductSlotType from '@constants/ProductSlotType';
import ProductSubCategoryTypes from '@constants/ProductSubCategoryType';
import DataProvider from '@helper/DataProvider';

import Table from '../Table';
import DefaultColumns from '../Table/DefaultColumns';

const getColumnConfiguration = () => {
    const defaultColumns = DefaultColumns.get(
        ProductCategoryType.fullIp,
        ProductSubCategoryTypes.fullIpFullHd,
    );

    return [
        defaultColumns.displayPartNumber,
        defaultColumns.description,
        defaultColumns.videoConnector,
        defaultColumns.resolution,
        defaultColumns.supportedVideoChannels,
        defaultColumns.vga,
        defaultColumns.interlacedUpgrade,
        defaultColumns.deviceType,
        defaultColumns.redundant,
        defaultColumns.powerRedundancy,
        defaultColumns.powerOverEthernet,
        defaultColumns.powerOverFiber,
        defaultColumns.usb,
        defaultColumns.usbMemoryDeviceSupport,
        defaultColumns.serial,
        defaultColumns.audio,
        defaultColumns.matrixEndPoints,
        defaultColumns.dataTransfer,
        defaultColumns.powerConsumptionInMilliamps,
    ];
};

const Component = () => (
    <Table
        columnConfiguration={getColumnConfiguration()}
        productCategoryType={ProductCategoryType.fullIp}
        productSlotType={ProductSlotType.chassis}
        rawData={DataProvider.getFullIpFullHdFlexLine()}
    />
);

Component.propTypes = {};

Component.defaultProps = {};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import defaultDragDropConfiguration from './default';

export default {
    dropBehavior: {
        canDrop(props, monitor) {
            const payload = monitor.getItem();

            return (
                payload.productSlotType === props.productSlotType &&
                payload.productCategoryType === props.productCategoryType
            );
        },
        drop: (props, monitor, component) => {
            const dropResult = monitor.getDropResult();
            const payload    = monitor.getItem();

            console.log(
                'ProductAdd: drop (payload, props, component, dropResult)',
                payload,
                props,
                component,
                dropResult,
            );

            props.addProduct({
                categoryType:    props.productCategoryType,
                fromSmartAction: false,
                productId:       payload.productId,
            });

            return {};
        },
    },
    dropCollect:  defaultDragDropConfiguration.dropCollect,
};

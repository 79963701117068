//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import { DropTarget } from 'react-dnd';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import DragTypes from '@constants/DragTypes';
import ProductCategoryType from '@constants/ProductCategoryType';
import ProductSlotType from '@constants/ProductSlotType';

import StandAloneDropZoneMode from './StandAloneDropZoneMode';
import styles from './styles.module.scss';
import dragDropConfiguration from '../../dragDrop/productAdd';
import Icon from '../Icon';
import IconType from '../Icon/IconType';
import PropTypes from '../PropTypes';

const Component = ({ mode, productCategoryType, productSlotType, text, isOver, connectDropTarget }) => {
    return connectDropTarget(<div className={classNames(
        styles.standAloneDropZone,
        isOver
            ? styles.standAloneDropZoneHover
            : null,
        mode === StandAloneDropZoneMode.overlay
            ? styles.standAloneDropZoneOverlay
            : null,
        mode === StandAloneDropZoneMode.overlay && canDrop
            ? styles.standAloneDropZoneOverlayCanDrop
            : null,
    )}>
        <div className={classNames(
            styles.standAloneDropZoneInner,
            isOver
                ? styles.standAloneDropZoneInnerHover
                : null,
        )}>
            <span className={styles.standAloneDropZoneIcon}>
                <Icon iconType={IconType.dropZone} />
            </span>
            <span className={classNames(
                styles.standAloneDropZoneText,
                isOver
                    ? styles.standAloneDropZoneTextHover
                    : null,
            )}>
                {text}
            </span>
        </div>
    </div>);
};

Component.propTypes = {
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool,
    mode: PropTypes.oneOf(Object.values(StandAloneDropZoneMode)),
    productCategoryType: PropTypes.oneOf(Object.values(ProductCategoryType)),
    productSlotType: PropTypes.oneOf(Object.values(ProductSlotType)),
    text: PropTypes.string,
};

Component.defaultProps = {
    mode: StandAloneDropZoneMode.default,
    productCategoryType: null,
    productSlotType: null,
    text: '',
    alert: 'alert',
};

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

const enhance = compose(
    connect(null, mapDispatchToProps),
    DropTarget(DragTypes.add, dragDropConfiguration.dropBehavior, dragDropConfiguration.dropCollect),
);

export default enhance(Component);

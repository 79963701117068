//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';

import Routes  from '@constants/Routes';
import TestIds from '@constants/TestIds';

import styles    from './styles.module.scss';
import Icon      from '../Icon';
import IconType  from '../Icon/IconType';
import Link      from '../Link';
import PropTypes from '../PropTypes';

const Component = ({ route }) => {
    return (
        <div
            data-testId={TestIds.backButton}
            className={styles.leftPaneBackButtonWrapper}
        >
            <Link
                className={styles.leftPaneBackButton}
                to={route}
            >
                <span className={styles.leftPaneBackButtonIcon}>
                    <Icon iconType={IconType.chevronLeft} />
                </span>
                {I18n.t('back')}
            </Link>
        </div>
    );
};

Component.propTypes = {
    route: PropTypes.oneOf(Object.values(Routes)),
};

Component.defaultProps = {
    route: '',
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import { ActiveProjectActions } from '@slices/activeProject';
import Overlays                 from '@constants/Overlays';
import DataProvider             from '@helper/DataProvider';

const setSelectedProduct = (store) => (next) => (action) => {
    if (action.type === ActiveProjectActions.setSelectedProductData().type) {
        const state              = store.getState();
        const activeProjectState = state.activeProject;
        const currentProduct     = activeProjectState.products[activeProjectState.selectedProduct.categoryType][activeProjectState.selectedProduct.index];
        const currentProductData = currentProduct.productData;
        const hiddenOverlays     = state.overlays.hiddenOverlays;
        const nextProductData    = DataProvider.getById(action.productId);

        if (
            !hiddenOverlays[Overlays.setSelectedDataConfirm] &&
            currentProductData.slotCount > nextProductData.slotCount
        ) {
            const newAction = _.cloneDeep(action);
            newAction.type  = ActiveProjectActions.setSelectedProductDataCheck().type;

            next(newAction);

            return;
        }
    }

    next(action);
};

export default setSelectedProduct;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import SlotDefaultBounds from '@constants/SlotDefaultBounds';

import GapCalculator from './GapCalculator';
import SlotAlignment from '../../SelectedProductSlotImageRenderer/SlotAlignment';
import ChassisLayout from '../ChassisLayout';

const MatrixSlotDefaultBounds = SlotDefaultBounds.matrix;

/**
 * @see This file also exists in the backend src/BoundsCalculators/MatrixSlotLeftTopToRightBottom.php
 */
class MatrixSlotTopRightToBottomToLeft {
    static getBoundsChassisLayoutHorizontalX = (bounds, layoutDefinition) => {
        let x = 0;

        // @formatter:off
        x += layoutDefinition.start.x;                                                       //
        x -= MatrixSlotDefaultBounds.width;                                                  //
        x -= GapCalculator.getGap(layoutDefinition.gap.row, bounds.column);                  //
        x -= (layoutDefinition.columnCount - bounds.column) * MatrixSlotDefaultBounds.width; //
        // @formatter:on

        return x;
    };

    static getBoundsChassisLayoutVerticalX = (bounds, layoutDefinition) => {
        const x = 0;

        // @formatter:off
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-561
        // @formatter:on

        return x;
    };

    static getBoundsChassisLayoutHorizontalY = (bounds, layoutDefinition) => {
        let y = 0;

        // @formatter:off
        y += layoutDefinition.start.y;                                   //
        y += GapCalculator.getGap(layoutDefinition.gap.row, bounds.row); //
        y += (bounds.row - 1) * MatrixSlotDefaultBounds.height;          //
        // @formatter:on

        return y;
    };

    static getBoundsChassisLayoutVerticalY = (bounds, layoutDefinition) => {
        const y = 0;

        // @formatter:off
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-561
        // @formatter:on

        return y;
    };

    static getCurrentColumnChassisLayoutHorizontal = (slotIndexStartingBy1, layoutDefinition) => {
        return (
            layoutDefinition.columnCount
            -
            (
                Math.ceil(slotIndexStartingBy1 / layoutDefinition.rowCount)
                -
                1
            )
        );
    };

    static getCurrentColumnChassisLayoutVertical = (slotIndexStartingBy1, layoutDefinition) => {
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-561
    };

    static getCurrentRowChassisLayoutHorizontal = (slotIndexStartingBy1, layoutDefinition) => {
        return (
            (
                (
                    slotIndexStartingBy1 - 1
                )
                %
                layoutDefinition.rowCount
            )
            +
            1
        );
    };

    static getCurrentRowChassisLayoutVertical = (slotIndexStartingBy1, layoutDefinition) => {
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-561
    };

    static getBounds = (slotIndexStartingBy1, layoutDefinition, productData) => {
        const bounds = this.getDefaultBounds(layoutDefinition);

        switch (layoutDefinition.chassisLayout) {
            case ChassisLayout.horizontal:
                this.updateBoundsChassisLayoutHorizontal(
                    slotIndexStartingBy1,
                    layoutDefinition,
                    bounds,
                );

                break;

            case ChassisLayout.vertical:
                this.updateBoundsChassisLayoutVertical(
                    slotIndexStartingBy1,
                    layoutDefinition,
                    bounds,
                );

                break;
        }

        return bounds;
    };

    static getDefaultBounds = (layoutDefinition) => {
        return {
            alignTo:       SlotAlignment.top,
            chassisLayout: layoutDefinition.chassisLayout,
            height:        MatrixSlotDefaultBounds.height,
            x:             0,
            y:             0,
            width:         MatrixSlotDefaultBounds.width,
        };
    };

    static updateBoundsChassisLayoutHorizontal = (slotIndexStartingBy1, layoutDefinition, bounds) => {
        bounds.column = this.getCurrentColumnChassisLayoutHorizontal(slotIndexStartingBy1, layoutDefinition);
        bounds.row    = this.getCurrentRowChassisLayoutHorizontal(slotIndexStartingBy1, layoutDefinition);
        bounds.x      = this.getBoundsChassisLayoutHorizontalX(bounds, layoutDefinition);
        bounds.y      = this.getBoundsChassisLayoutHorizontalY(bounds, layoutDefinition);
    };

    static updateBoundsChassisLayoutVertical = (slotIndexStartingBy1, layoutDefinition, bounds) => {
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-561
    };
}

export default MatrixSlotTopRightToBottomToLeft;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

import WarningType from '@constants/WarningType';

import ActiveProjectsFactory from '../factories/activeProjects';

class limitationShowPowerConsumptionOverloadWarning {
    static updateProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        productWarnings,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        if (product.productData.maximumPowerConsumptionInMilliamps < powerConsumptionInMilliamps) {
            productWarnings.push(ActiveProjectsFactory.getWarning(
                WarningType.error,
                I18n.t(
                    'limitationShowPowerConsumptionOverloadWarning',
                    {
                        maximumPowerConsumptionInMilliamps: product.productData.maximumPowerConsumptionInMilliamps,
                        powerConsumptionInMilliamps,
                    },
                ),
            ));

            return true;
        }

        return false;
    };

    static updateSubProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        subProduct,
        subProductIndex,
        productWarnings,
        subProductWarnings,
        subProductCategory,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        return false;
    };
}

export default limitationShowPowerConsumptionOverloadWarning;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames  from 'classnames';
import I18n        from 'i18next';
import _           from 'lodash';
import Collapsible from 'react-collapsible';
import { connect } from 'react-redux';

import WarningType           from '@constants/WarningType';
import Cast                  from '@helper/Cast';
import SelectionHelper       from '@helper/SelectionHelper';
import StateHelper           from '@helper/State';
import ActiveProjectsFactory from '@store/factories/activeProjects';

import styles           from './styles.module.scss';
import Icon             from '../Icon';
import IconType         from '../Icon/IconType';
import WarningList      from '../WarningList';
import WarningListTheme from '../WarningList/WarningListTheme';

class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            warningCategories: this.recalculateWarningCategories(),
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log(
            'ProductWarningList: componentWillReceiveProps: this.props, nextProps',
            this.props,
            nextProps,
        );

        if (
            nextProps.selectedProduct !== this.props.selectedProduct ||
            nextProps.selectedProduct.fanAddedAutomatically !== this.props.selectedProduct.fanAddedAutomatically
        ) {
            this.setState({
                warningCategories: this.recalculateWarningCategories(nextProps),
            });
        }
    }

    getTitleForWarningCategory = (category) => {
        return SelectionHelper.getTranslation(
            category,
            {
                [WarningType.error]:       'warningErrors',
                [WarningType.information]: 'warningInformation',
            },
        );
    };

    getWarnings = (props) => {
        if (!props) {
            props = this.props;
        }

        const warnings       = _.get(props, 'selectedProduct.warnings') || [];
        const copyOfWarnings = [...warnings];
        const subProducts    = _.get(props, 'selectedProduct.subProducts') || [];
        const subProductKeys = Object.keys(subProducts);

        for (const subProductCategoryKey of subProductKeys) {
            const subProducts = props.selectedProduct.subProducts[subProductCategoryKey];

            for (const subProductIndex in subProducts) {
                const subProductIndexInt = Cast.int(subProductIndex);
                const subProduct         = subProducts[subProductIndex];

                if (subProduct.warnings && subProduct.warnings.length > 0) {
                    for (const subProductWarning of subProduct.warnings) {
                        const slotText = [
                            I18n.t('slot'),
                            ' ',
                            (
                                subProduct.productData.heightUnits > 1 ?
                                    `${subProductIndexInt}+` :
                                    ''
                            ),
                            (
                                subProductIndexInt + 1
                            ),
                            ': ',
                            subProductWarning.text,
                        ].join('');

                        copyOfWarnings.push(ActiveProjectsFactory.getWarning(
                            subProductWarning.type,
                            slotText,
                        ));
                    }
                }
            }
        }

        if (_.get(props, 'selectedProduct.fanAddedAutomatically')) {
            copyOfWarnings.push(ActiveProjectsFactory.getWarning(
                WarningType.information,
                I18n.t('fanAddedAutomaticallyHint'),
            ));
        }

        return copyOfWarnings;
    };

    hasWarnings = () => {
        return (
            this.state.warningCategories &&
            (
                this.state.warningCategories[WarningType.error].length > 0 ||
                this.state.warningCategories[WarningType.information].length > 0
            )
        );
    };

    recalculateWarningCategories = (props) => {
        if (!props) {
            props = this.props;
        }

        const warnings = this.getWarnings(props);
        const data     = {
            [WarningType.error]:       [],
            [WarningType.information]: [],
        };

        for (const warning of warnings) {
            data[warning.type].push(warning);
        }

        return data;
    };

    render() {
        if (this.hasWarnings()) {
            return (
                <div className={styles.productWarningList}>
                    {this.renderCategories()}
                </div>
            );
        }

        return null;
    }

    renderCategories = () => {
        const categories = [];

        for (const warningCategoryKey in this.state.warningCategories) {
            if (!this.state.warningCategories.hasOwnProperty(warningCategoryKey)) {
                continue;
            }

            const warningCategory = this.state.warningCategories[warningCategoryKey];

            console.log('warningCategoryKey', warningCategoryKey, warningCategory);

            if (warningCategory.length > 0) {
                const categoryTitle = this.getTitleForWarningCategory(warningCategoryKey);
                const itemCount     = warningCategory.length;

                categories.push(<div
                    className={styles.productWarningListWrapper}
                    key={warningCategoryKey}
                >
                    <Collapsible
                        open={true}
                        transitionTime={100}
                        trigger={this.renderToggleControl(categoryTitle, itemCount, false)}
                        triggerWhenOpen={this.renderToggleControl(categoryTitle, itemCount, true)}
                    >
                        <div className={styles.productWarningListWrapperInner}>
                            <WarningList
                                theme={WarningListTheme.gray}
                                warnings={warningCategory}
                            />
                        </div>
                    </Collapsible>
                </div>);
            }
        }

        return categories;
    };

    renderToggleControl = (categoryTitle, entries, isOpen) => {
        return (
            <div className={styles.productWarningListToggleControl}>
                <span
                    className={classNames(
                        styles.productWarningListToggleControlIcon,
                        (
                            isOpen ?
                                styles.productWarningListToggleControlIconOpen :
                                null
                        ),
                    )}
                >
                    <Icon iconType={IconType.arrowDown} />
                </span>
                <span className={styles.productWarningListToggleControlText}>
                    {categoryTitle}
                    <span className={styles.productWarningListToggleControlBadge}>
                        {entries}
                    </span>
                </span>
            </div>
        );
    };
}

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        selectedProduct: StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
    };
};

export default connect(
    mapStateToProps,
    null,
)(Component);

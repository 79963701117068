//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// We just "extend" the original class
// @see https://github.com/adazzle/react-data-grid/blob/master/packages/react-data-grid-addons/src/cells/headerCells/filters/NumericFilter.js
import React from 'react';

import I18n                from 'i18next';
import _                   from 'lodash';
import { withTranslation } from 'react-i18next';

import styles    from './styles.module.scss';

const TableTextFilter = (props) => {
    const columnKey    = _.get(props, 'column.key');
    const inputKey     = `header-filter-${columnKey}`;
    const filterValues = (row, columnFilter, columnKey) => {
        const lowerCaseSearchTerm   = columnFilter.rawValue.toLowerCase().trim();
        const lowerCaseSearchTarget = row[columnKey].toLowerCase();

        return lowerCaseSearchTarget.indexOf(lowerCaseSearchTerm) > -1;
    };
    const handleChange = (event) => {
        const value = event.target.value;

        props.onChange({
            filterTerm: value.length > 0,
            column:     props.column,
            rawValue:   value,
            filterValues,
        });
    };

    return (
        <input
            className={styles.textFilter}
            key={inputKey}
            type={'text'}
            placeholder={I18n.t('enterTextPlaceholder')}
            onChange={handleChange}
        />
    );
};

export default withTranslation()(TableTextFilter);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import { bindActionCreators } from 'redux';
import update                 from 'immutability-helper';
import AssemblyOrderHelper    from '@helper/AssemblyOrder';
import CardOverlayModes       from '@constants/CardOverlayModes';
import LanguageHelper         from '@helper/Language';

const initialState = {
    assemblyOrder:                   AssemblyOrderHelper.getDefaultAssemblyOrder(),
    cardOverlayMode:                 CardOverlayModes.none,
    cardOverlayModeRenderExtenderId: false,
    groupSimilarProducts:            false,
    previewGroupSimilarProducts:     false,
    endOfLifeProductsVisible:        false,
    language:                        LanguageHelper.getConfigDefaultLanguage(),
    offerFieldVisible:               false,
    otherCommentFieldVisible:        false,
    singleOrdersEnabled:             true,
};

const SettingSlice = createSlice({
    name:     'setting',
    initialState,
    reducers: {
        setGroupSimilarProducts:           (state, action) => {
            return update(state, {
                groupSimilarProducts: {
                    $set: action.payload.groupSimilarProducts,
                },
            });
        },
        setPreviewGroupSimilarProducts:    (state, action) => {
            return update(state, {
                previewGroupSimilarProducts: {
                    $set: action.payload.previewGroupSimilarProducts,
                },
            });
        },
        setEndOfLifeProductsVisibility:    (state, action) => {
            return update(state, {
                endOfLifeProductsVisible: {
                    $set: action.payload.visible,
                },
            });
        },
        setCardOverlayMode:                (state, action) => {
            return update(state, {
                cardOverlayMode: {
                    $set: action.payload.cardOverlayMode,
                },
            });
        },
        setAssemblyOrder:                  (state, action) => {
            return update(state, {
                assemblyOrder: {
                    $set: action.payload.assemblyOrder,
                },
            });
        },
        setLanguage:                       (state, action) => {
            return update(state, {
                language: {
                    $set: action.payload.language,
                },
            });
        },
        setOfferFieldVisibility:           (state, action) => {
            return update(state, {
                offerFieldVisible: {
                    $set: action.payload.visible,
                },
            });
        },
        setOtherCommentFieldVisibility:    (state, action) => {
            return update(state, {
                otherCommentFieldVisible: {
                    $set: action.payload.visible,
                },
            });
        },
        setSingleOrdersEnabled:            (state, action) => {
            return update(state, {
                singleOrdersEnabled: {
                    $set: action.payload.enabled,
                },
            });
        },
        toggleCardOverlayRenderExtenderId: (state, action) => {
            // We don't use $toggle here since we do know if "cardOverlayModeRenderExtenderId"
            // exists in older states
            return update(state, {
                cardOverlayModeRenderExtenderId: {
                    $set: !state.cardOverlayModeRenderExtenderId,
                },
            });
        },
    },
});

/*
case DebugActions.resetAll().type:
return update(state, {
    $set: initialState,
});
 */

export const SettingActions = SettingSlice.actions;

export const SettingReducer = SettingSlice.reducer;

export const useSetting = (dispatch) => bindActionCreators(SettingActions, dispatch);

export default SettingSlice;

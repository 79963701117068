//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { LOCATION_CHANGE } from 'connected-react-router';

const locationFilter = (store) => (next) => (action) => {
    let nextAllowed = true;

    if (action.type === LOCATION_CHANGE) {
        const state        = store.getState();
        const router       = state.router;
        const fromLocation = router.location;
        const toLocation   = action.payload.location;

        if (
            (
                fromLocation.pathname === toLocation.pathname &&
                fromLocation.search === toLocation.search
            )
        ) {
            nextAllowed = false;
        }
    }

    if (nextAllowed) {
        next(action);
    }
};

export default locationFilter;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push }   from 'connected-react-router';
import _          from 'lodash';
import { select } from 'redux-saga/effects';
import { put }    from 'redux-saga/effects';
import { delay }  from 'redux-saga/effects';

import { getPathForOverlayKey }          from '@components/OverlayManager';
import Overlays                          from '@constants/Overlays';
import ProductCategoryType               from '@constants/ProductCategoryType';
import ProductSlotType                   from '@constants/ProductSlotType';
import ProductSubCategoryType            from '@constants/ProductSubCategoryType';
import Routes                            from '@constants/Routes';
import SlotType                          from '@constants/SlotType';
import TabIndices                        from '@constants/TabIndices';
import Base64                            from '@helper/Base64';
import DataProvider                      from '@helper/DataProvider';
import LimitationHelper                  from '@helper/Limitations';
import PrintPreview                      from '@helper/PrintPreview';
import ProductHelper                     from '@helper/Product';
import ProductDataUpdateChecker          from '@helper/ProductDataUpdateChecker';
import ProjectTitle                      from '@helper/ProjectTitle';
import SelectionHelper                   from '@helper/SelectionHelper';
import { ActiveProjectActions }          from '@slices/activeProject';
import { ExtenderProductBrowserActions } from '@slices/extenderProductBrowser';
import { FullIpProductBrowserActions }   from '@slices/fullIpProductBrowser';
import { MatrixProductBrowserActions }   from '@slices/matrixProductBrowser';
import { ProjectsActions }               from '@slices/projects';

/**
 * The amount of slots that have to be free after the slot the user is currently setting to force the system
 * to show the "fill more slots" dialog.
 *
 * @type {number}
 */
const freeSlotsThreshold = 2;

const addProduct = function* (action) {
    const { payload }                       = action;
    const { categoryType, fromSmartAction } = payload;

    if (fromSmartAction) {
        const activeProject = yield select((state) => state.activeProject);
        const products      = activeProject.products[categoryType];
        const productCount  = products.length;

        yield put(ActiveProjectActions.selectProduct({
            categoryType,
            index:       productCount - 1,
            openDetails: true,
        }));
    }
};

const autoFillSlots = function* () {
    const hiddenOverlays = yield select((state) => state.overlays.hiddenOverlays);

    if (!hiddenOverlays[Overlays.fillSlots]) {
        const route = getPathForOverlayKey(Overlays.fillSlots);

        yield put(push(route));
    }
};

const closeProductDetails = function* (action) {
    yield put(push(Routes.designer));
};

const closeProject = function* (action) {
    const activeProject      = yield select((state) => state.activeProject);
    const { payload }        = action;
    const { closeConfirmed } = payload;

    console.log('sagas: activeProject: closeProject', action);

    // When this is set the <Link> component decided to ask the user if he really wants
    // to close the active projects since he clicked on a link that targets
    // a url that is not part of the designer (eg. the help or imprint)
    if (
        activeProject.routeAfterClose &&
        (
            !action ||
            !closeConfirmed
        )
    ) {
        const route = getPathForOverlayKey(Overlays.closeProject);

        yield put(push(route));
    } else {
        yield put(ProjectsActions.saveThenClearThenRedirectActiveProject({
            projectData:     activeProject,
            routeAfterClose: activeProject.routeAfterClose,
        }));
    }
};

const confirmOverwrite = function* () {
    const route = getPathForOverlayKey(Overlays.confirmOverwrite);

    yield put(push(route));
};

const deleteProduct = function* () {
    const route = getPathForOverlayKey(Overlays.deleteProduct);

    yield put(push(route));
};

const deleteProductConfirm = function* () {
    const activeProject = yield select((state) => state.activeProject);

    if (activeProject.selectedProduct.index === null) {
        yield put(push(Routes.designer));
    }
};

const deleteSingleOrderSlot = function* () {
    const route = getPathForOverlayKey(Overlays.deleteSingleOrderSlot);

    yield put(push(route));
};

const deleteSfp = function* () {
    const route = getPathForOverlayKey(Overlays.deleteSfp);

    yield put(push(route));
};

const deleteSlot = function* () {
    const route = getPathForOverlayKey(Overlays.deleteSlot);

    yield put(push(route));
};

const deleteSlotConfirm = function* () {
    const activeProject = yield select((state) => state.activeProject);

    if (activeProject.selectedSlot.index === activeProject.slotDeletionContext.index) {
        yield put(push(Routes.designerProductDetails));
    }
};

const deselectProduct = function* () {
    yield put(push(Routes.designer));
};

const deselectSlot = function* () {
    yield put(push(Routes.designerProductDetails));
};

const duplicateSelectedProduct = function* (action) {
    const hiddenOverlays  = yield select((state) => state.overlays.hiddenOverlays);
    const { payload }     = action;
    const { confirmed }   = payload;
    const actionConfirmed = confirmed;

    if (!actionConfirmed && !hiddenOverlays[Overlays.duplicateProduct]) {
        const route = getPathForOverlayKey(Overlays.duplicateProduct);

        yield put(push(route));
    }
};

const duplicateSelectedProductConfirm = function* () {
    const hiddenOverlays = yield select((state) => state.overlays.hiddenOverlays);

    if (!hiddenOverlays[Overlays.switchToDuplicatedProduct]) {
        const route = getPathForOverlayKey(Overlays.switchToDuplicatedProduct);

        yield put(push(route));
    } else {
        const activeProject   = yield select((state) => state.activeProject);
        const selectedProduct = activeProject.selectedProduct;
        const products        = activeProject.products[selectedProduct.categoryType];
        const productCount    = products.length;

        yield put(ActiveProjectActions.selectProduct({
            categoryType: selectedProduct.categoryType,
            index:        productCount - 1,
            openDetails:  true,
        }));
    }
};

const loadProject = function* () {
    yield put(push(Routes.designer));

    const activeProject         = yield select((state) => state.activeProject);
    const changedProductNumbers = ProductDataUpdateChecker.hasChanges(activeProject);

    if (changedProductNumbers.length > 0) {
        yield delay(10);
        yield put(ActiveProjectActions.setOutdatedProducts({
            productIds: changedProductNumbers,
        }));
        yield put(ActiveProjectActions.updateOutdatedProductData());
    }
};

const noData = function* () {
    yield put(push(Routes.home));
};

const openDownloadOverlay = function* () {
    const route = getPathForOverlayKey(Overlays.download);

    yield put(push(route));
};

const selectProduct = function* (action) {
    const { openDetails } = action.payload;

    if (openDetails) {
        yield put(push(Routes.designerProductDetails));

        const activeProject              = yield select((state) => state.activeProject);
        const selectedProduct            = (
            activeProject.products
                [activeProject.selectedProduct.categoryType]
                [activeProject.selectedProduct.index]
        );
        const subCategoryType            = _.get(selectedProduct, 'productData.subCategoryType', '');
        const activeMainTabIndexExtender = SelectionHelper.get(
            subCategoryType,
            {
                [ProductSubCategoryType.compact]: TabIndices.mainExtenderTabCompact,
                [ProductSubCategoryType.secure]:  TabIndices.mainExtenderTabSecure,
                [ProductSubCategoryType.misc]:    TabIndices.mainExtenderTabMisc,
                [ProductSubCategoryType.vario]:   TabIndices.mainExtenderTabVario,
            },
            -1,
        );

        if (activeMainTabIndexExtender > -1) {
            yield put(ExtenderProductBrowserActions.setActiveMainTabIndex({
                activeMainTabIndex: activeMainTabIndexExtender,
            }));
        }

        const activeMainTabIndexMatrix = SelectionHelper.get(
            subCategoryType,
            {
                [ProductSubCategoryType.flex]:       TabIndices.mainMatrixTabFlex,
                [ProductSubCategoryType.compact]:    TabIndices.mainMatrixTabCompact,
                [ProductSubCategoryType.enterprise]: TabIndices.mainMatrixTabEnterprise,
            },
            -1,
        );

        if (activeMainTabIndexMatrix > -1) {
            yield put(MatrixProductBrowserActions.setActiveMainTabIndex({
                activeMainTabIndex: activeMainTabIndexMatrix,
            }));

            if (selectedProduct.productData.subCategoryType === ProductSubCategoryType.flex) {
                const selectedProductIsConfigurable = _.get(selectedProduct, 'productData.configurable', false);
                const targetActiveSubTabFlexIndex   = (
                    selectedProductIsConfigurable ?
                        TabIndices.subTabFlexFrontPlates :
                        TabIndices.subTabFlexChassis
                );

                yield put(MatrixProductBrowserActions.setActiveSubTabFlexIndex({
                    activeSubTabFlexIndex: targetActiveSubTabFlexIndex,
                }));
            }
        }

        const activeMainTabIndexFullIp = SelectionHelper.get(
            subCategoryType,
            {
                [ProductSubCategoryType.fullIpFullHd]: TabIndices.mainFullIpFullHdTab,
                [ProductSubCategoryType.fullIp4K]:     TabIndices.mainFullIp4KTab,
            },
            -1,
        );

        if (activeMainTabIndexFullIp > -1) {
            yield put(FullIpProductBrowserActions.setActiveMainTabIndex({
                activeMainTabIndex: activeMainTabIndexFullIp,
            }));
        }
    }
};

const selectSlot = function* (action) {
    const location            = yield select((state) => state.router.location);
    const { payload }         = action;
    const { index, slotType } = payload;

    if (location.pathname.indexOf(Routes.designerProductSlotDetails) === -1) {
        yield put(push(`${Routes.designerProductSlotDetails}?index=${index}&type=${slotType}`));
    }

    const activeProject   = yield select((state) => state.activeProject);
    const slotProductData = (
        activeProject.products
            [activeProject.selectedProduct.categoryType]
            [activeProject.selectedProduct.index].subProducts
            [slotType]
            [index].productData
    );

    if (slotProductData && slotProductData.configurableSfpCount > 0) {
        const selectedProduct = (
            activeProject.products
                [activeProject.selectedProduct.categoryType]
                [activeProject.selectedProduct.index]
        );
        const subCategoryType = _.get(selectedProduct, 'productData.subCategoryType', '');

        // Open the accessories tab when the user selects a card
        // that can be extended using sfp modules
        if (subCategoryType === ProductSubCategoryType.compact) {
            yield put(MatrixProductBrowserActions.setActiveSubTabCompactIndex({
                activeSubTabCompactIndex: TabIndices.subTabCompactChassisAccessories,
            }));
        } else {
            yield put(MatrixProductBrowserActions.setActiveSubTabEnterpriseIndex({
                activeSubTabEnterpriseIndex: TabIndices.subTabEnterpriseAccessories,
            }));
        }
    }
};

const handleRequiresFanInChassis    = function* (selectedProduct, activeProject, targetSlotType, isBulk, slotIndex, productId) {
    const productRequiresFanInChassis = LimitationHelper.productRequiresFanInChassis(selectedProduct);
    const productHasNoFan             = !LimitationHelper.productHasFan(selectedProduct);

    // Check whether the user has to choose a fan type
    if (
        productRequiresFanInChassis &&
        productHasNoFan
    ) {
        const route = getPathForOverlayKey(Overlays.chooseFanType);

        yield put(push(route));
    } else if ( // Check whether the user added a slot to a matrix chassis
        activeProject.selectedProduct.categoryType === ProductCategoryType.matrix &&
        targetSlotType === SlotType.slot &&
        !isBulk
    ) {
        const productData = DataProvider.getById(productId);

        if (productData.productSlotType === ProductSlotType.ioBoard) {
            const freeSlotsAfterSlotIndex = ProductHelper.getEmptySlotCountAfterSlot(
                selectedProduct,
                slotIndex,
            );

            if (freeSlotsAfterSlotIndex >= freeSlotsThreshold) {
                yield put(ActiveProjectActions.autoFillSlots({
                    freeSlotsAfterSlotIndex,
                    productId,
                    slotIndex,
                }));
            }
        }
    }
};
const handleRequiresModuleInChassis = function* (selectedProduct, activeProject) {
    // Check if the user added a module with a required module
    const requiredModuleData = LimitationHelper.getRequiredModuleInChassis(selectedProduct);

    if (requiredModuleData) {
        const { requiredModulePartNo, requiredModuleSlot } = requiredModuleData;
        const slotIndex                                    = requiredModuleSlot - 1;

        yield put(ActiveProjectActions.setProductSlot({
            productId:      requiredModulePartNo,
            slotIndex,
            targetSlotType: SlotType.slot,
        }));
    }
};

const setProductSlot = function* (action) {
    const activeProject   = yield select((state) => state.activeProject);
    const selectedProduct = (
        activeProject.products
            [activeProject.selectedProduct.categoryType]
            [activeProject.selectedProduct.index]
    );

    if (selectedProduct) {
        const { payload } = action;
        const {
                  targetSlotType,
                  isBulk,
                  productId,
                  slotIndex,
              }           = payload;

        yield handleRequiresFanInChassis(selectedProduct, activeProject, targetSlotType, isBulk, slotIndex, productId);
        yield handleRequiresModuleInChassis(selectedProduct, activeProject);
    }
};

const reverseLayoutedProduct = function* () {
    const hiddenOverlays = yield select((state) => state.overlays.hiddenOverlays);

    if (!hiddenOverlays[Overlays.switchToReversedProduct]) {
        const route = getPathForOverlayKey(Overlays.switchToReversedProduct);

        yield put(push(route));
    }
};

const setActiveProductCategoryType = function* () {
    const location = yield select((state) => state.router.location);

    if (location.pathname === Routes.designerProjectDetails) {
        // Deselect the project details when the user clicks on the add button
        // of any product category
        yield put(push(Routes.designer));
    }
};

const setSelectedProductDataCheck = function* () {
    const route = getPathForOverlayKey(Overlays.setSelectedDataConfirm);

    yield put(push(route));
};

const downloadPdf = function* () {
    const activeProject     = yield select((state) => state.activeProject);
    const settings          = yield select((state) => state.settings);
    const activeProjectJson = JSON.stringify(activeProject);
    const encodedString     = Base64.encode(activeProjectJson);
    const printPreviewUrl   = PrintPreview.getPrintPreviewUrl(
        'pdf',
        settings.language,
        null,
        settings.cardOverlayMode,
        settings.previewGroupSimilarProducts,
    );

    yield put(ActiveProjectActions.downloadPdfStarted());

    try {
        const response   = yield fetch(printPreviewUrl, {
            method:  'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body:    JSON.stringify({
                projectPayload: encodedString,
            }),
        });
        const blob       = yield response.blob();
        const dateString = new Date().toISOString().replace(/:/g, '-');
        const blobUrl    = URL.createObjectURL(blob);
        const link       = document.createElement('a');
        const title      = ProjectTitle.getTitleOfActiveProject(activeProject);
        link.href        = blobUrl;
        link.download    = `${title}-${dateString}.pdf`;

        document.body.appendChild(link);
        link.dispatchEvent(new MouseEvent(
            'click',
            {
                bubbles:    true,
                cancelable: true,
                view:       window,
            },
        ));
        document.body.removeChild(link);

        yield put(ActiveProjectActions.downloadPdfSuccess());
    } catch (error) {
        yield put(ActiveProjectActions.downloadPdfFailed());
    }
};

export default {
    addProduct,
    autoFillSlots,
    closeProductDetails,
    closeProject,
    confirmOverwrite,
    deleteProduct,
    deleteProductConfirm,
    deleteSfp,
    deleteSingleOrderSlot,
    deleteSlot,
    deleteSlotConfirm,
    deselectProduct,
    deselectSlot,
    duplicateSelectedProduct,
    duplicateSelectedProductConfirm,
    loadProject,
    noData,
    openDownloadOverlay,
    reverseLayoutedProduct,
    selectProduct,
    selectSlot,
    setActiveProductCategoryType,
    setProductSlot,
    setSelectedProductDataCheck,
    downloadPdf,
};

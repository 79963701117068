//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { Component } from 'react';
import React         from 'react';

import I18n       from 'i18next';
import { Helmet } from 'react-helmet';

import BlackBoxContactContent from '@components/BlackBoxContactContent';
import Header                 from '@components/Header';
import IHSEContactContent     from '@components/IHSEContactContent';
import TitleBanner            from '@components/TitleBanner';
import TitleBannerMode        from '@components/TitleBanner/TitleBannerMode';
import * as Configuration     from '@helper/Configuration';
import PageTitleHelper        from '@helper/PageTitle';

class Screen extends Component {
    render() {
        const contentComponents = {
            'IHSE':      IHSEContactContent,
            'Black Box': BlackBoxContactContent,
        };

        function renderContactContent() {
            const companyName      = Configuration.getCompanyName();
            const ContactComponent = contentComponents[companyName];

            if (!ContactComponent) {
                return null;
            }

            return <ContactComponent />;
        }

        return (
            <div>
                <Helmet>
                    <title>{PageTitleHelper.getPageTitle(I18n.t('contactPageTitle'))}</title>
                </Helmet>
                <Header />
                <TitleBanner
                    mode={TitleBannerMode.text}
                    text={I18n.t('contact')}
                />
                {renderContactContent()}
            </div>
        );
    }
}

export default Screen;

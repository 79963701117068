//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { Tab } from 'react-tabs';

import styles    from './styles.module.scss';
import PropTypes from '../PropTypes';

const Component = ({
    disabled = false,
    selected = false,
    text     = '',
    testId   = '',
}) => (
    <Tab
        data-testId={testId}
        className={styles.mainLevelTabButton}
        disabledClassName={styles.mainLevelTabButtonDisabled}
        disabled={disabled}
        selectedClassName={styles.mainLevelTabButtonSelected}
        selected={selected}
    >
        <h3 className={styles.mainLevelTabButtonText}>{text}</h3>
    </Tab>
);

Component.tabsRole = 'Tab';

Component.propTypes = {
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    testId:   PropTypes.string,
    text:     PropTypes.string,
};

export default Component;

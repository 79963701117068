//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { getExportFilename } from '@helper/Configuration';

const exportFilename = getExportFilename();

export default {
    csv:     'csv',
    dsdjson: exportFilename,
    ec0:     'ec0',
    json:    'json',
    zip:     'zip',
};

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import SlotType from '@constants/SlotType';

class StateHelper {
    // This method exists since the path to the selected product
    // within mapStateToProps is very hard to read
    static getSelectedProduct(state, selectedProduct) {
        if (
            selectedProduct &&
            selectedProduct.categoryType !== null &&
            selectedProduct.index !== null
        ) {
            const categoryType = selectedProduct.categoryType;
            const index        = selectedProduct.index;
            const products     = state.activeProject.products;

            return products[categoryType][index];
        }

        return null;
    }

    // This method exists since the path to the selected slot
    // within mapStateToProps is very hard to read
    static getSelectedSlot(state) {
        if (
            state.activeProject.selectedSlot &&
            state.activeProject.selectedSlot.slotType !== null &&
            state.activeProject.selectedSlot.index !== null &&
            state.activeProject.selectedProduct.categoryType !== null &&
            state.activeProject.selectedProduct.index !== null
        ) {
            const selectedSlotIndex = (
                state.activeProject.selectedSlot.slotType === SlotType.slot ?
                    state.activeProject.selectedSlot.index :
                    0
            );

            return (
                state.activeProject.products[state.activeProject.selectedProduct.categoryType][state.activeProject.selectedProduct.index] ?
                    state.activeProject.products[state.activeProject.selectedProduct.categoryType][state.activeProject.selectedProduct.index].subProducts[state.activeProject.selectedSlot.slotType][selectedSlotIndex] :
                    null
            );
        }
    }

    // This method exists since the path to the selected slot
    // within mapStateToProps is very hard to read
    static getSelectedSingleOrderSlot(state) {
        if (
            state.activeProject.selectedSingleOrderSlot &&
            state.activeProject.selectedSingleOrderSlot.categoryType !== null &&
            state.activeProject.selectedSingleOrderSlot.index !== null
        ) {
            return state.activeProject.singleOrders[state.activeProject.selectedSingleOrderSlot.categoryType][state.activeProject.selectedSingleOrderSlot.index];
        }
    }
}

export default StateHelper;

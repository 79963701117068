//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO: https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-596

/**
 * @see This class also exists in the backend src/BoundsCalculators/MarkerOrientationCalculator.php
 */
class MarkerOrientationCalculator {
    static getMarkerOrientation = (markerOrientations, index) => {
        if (markerOrientations[index]) {
            return markerOrientations[index];
        }

        const previousIndex = index - 1;

        if (markerOrientations[previousIndex]) {
            return markerOrientations[previousIndex];
        }

        return 'top';
    };
}

export default MarkerOrientationCalculator;

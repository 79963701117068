//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

import SlotType    from '@constants/SlotType';
import WarningType from '@constants/WarningType';
import Cast        from '@helper/Cast';
import Language    from '@helper/Language';

import ActiveProjectsFactory from '../factories/activeProjects';

class limitationLimitTo {
    static parseValue = (value) => {
        const parsedValue       = {
            slot:     0,
            products: [],
        };
        const valueWithoutSpace = value.replace(/ /g, '');
        const explodedValue     = valueWithoutSpace.split(',');

        parsedValue.slot = Cast.int(explodedValue[0]);

        explodedValue.shift();

        parsedValue.products = explodedValue;

        return parsedValue;
    };

    static updateProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        productWarnings,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        return false;
    };

    static updateSubProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        subProduct,
        subProductIndex,
        productWarnings,
        subProductWarnings,
        subProductCategory,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        if (subProductCategory === SlotType.powerSupplyUnit) {
            const { slot, products } = this.parseValue(limitation.value);

            if (subProductIndex + 1 === slot) {
                const slotData = product.subProducts[SlotType.powerSupplyUnit][subProductIndex];

                if (
                    slotData.productData && subProductIndex + 1 === slot &&
                    products.indexOf(slotData.productData.partNo) === -1
                ) {
                    const productList = Language.orString(products);

                    subProductWarnings.push(ActiveProjectsFactory.getWarning(
                        WarningType.error,
                        I18n.t('limitationLimitToPsusInSlot', {
                            productList,
                        }),
                    ));

                    return true;
                }
            }
        }

        return false;
    };
}

export default limitationLimitTo;

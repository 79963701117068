//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import styles          from './styles.module.scss';
import Icon            from '../Icon';
import IconType        from '../Icon/IconType';
import PropTypes       from '../PropTypes';
import Tooltip         from '../Tooltip';
import TooltipPosition from '../Tooltip/TooltipPosition';

const Component = ({
    iconType    = null,
    onClick     = _.noop,
    text        = '',
    tooltipText = '',
}) => {
    const renderTooltip = () => {
        if (tooltipText) {
            return (
                <div className={styles.actionGroupItemTooltip}>
                    <Tooltip
                        text={tooltipText}
                        position={TooltipPosition.topRight}
                    />
                </div>
            );
        }

        return null;
    };

    return (
        <li className={styles.actionGroupItem}>
            <span
                className={styles.actionGroupItemLink}
                onClick={onClick}
            >
                <span className={styles.actionGroupItemIconWrapper}>
                    {iconType && <Icon iconType={iconType} />}
                </span>
                <span className={styles.actionGroupItemText}>{text}</span>
            </span>
            {renderTooltip()}
        </li>
    );
};

Component.propTypes = {
    iconType:    PropTypes.oneOf(Object.values(IconType)),
    onClick:     PropTypes.func,
    text:        PropTypes.string,
    tooltipText: PropTypes.string,
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import StateHelper              from '@helper/State';

import styles                       from './styles.module.scss';
import ProductDataSpecificationList from '../ProductDataSpecificationList';
import RightPaneProductTitle        from '../RightPaneProductTitle';

const Component = ({
    selectedSingleOrderSlot,
    selectedSingleOrderSlotMeta,
    deleteSingleOrderSlot,
}) => {
    const deleteButtonPressed = () => {
        deleteSingleOrderSlot({
            categoryType: selectedSingleOrderSlotMeta.categoryType,
            index:        selectedSingleOrderSlotMeta.index,
        });
    };
    const renderSpecification = () => (
        <ProductDataSpecificationList
            data={selectedSingleOrderSlot.productData}
        />
    );

    if (selectedSingleOrderSlot && selectedSingleOrderSlot.productData) {
        return (
            <div className={styles.singleOrderProductDetailsPane}>
                <RightPaneProductTitle
                    deleteButtonPressed={deleteButtonPressed}
                    title={selectedSingleOrderSlot.productData.displayPartNumber}
                />
                {renderSpecification()}
            </div>
        );
    }

    return null;
};

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => (
    {
        selectedSingleOrderSlot:     StateHelper.getSelectedSingleOrderSlot(state),
        selectedSingleOrderSlotMeta: state.activeProject.selectedSingleOrderSlot,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

import React from 'react';

import I18n from 'i18next';
import { withTranslation } from 'react-i18next';

import Overlays from '@constants/Overlays';
import Routes from '@constants/Routes';

import Overlay from '../Overlay';
import OverlayType from '../Overlay/OverlayType';
import TextHeadline from '../TextHeadline';

const Component = () => {
    return (
        <Overlay
            cancelButtonTextKey={null}
            id={Overlays.importSuccessActiveProject}
            nextRoute={Routes.designer}
            overlayType={OverlayType.prompt}
            title={I18n.t('importSuccessActiveProjectTitle')}
        >
            <TextHeadline text={I18n.t('importSuccessActiveProjectText')} />
        </Overlay>
    );
};

Component.propTypes = {};

Component.defaultProps = {};

export default withTranslation()(Component);

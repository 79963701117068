//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import defaultDragDropConfiguration from './default';

export default {
    dropBehavior: {
        drop: (props, monitor, component) => {
            const dropResult = monitor.getDropResult();
            const payload    = monitor.getItem();

            console.log('smart: DropSource: drop');
            console.table(payload);
            console.table(dropResult);

            props.smartAction({
                productId:              payload.productId,
                triggeredByDoubleClick: false,
            });

            return {};
        },
    },
    dropCollect:  defaultDragDropConfiguration.dropCollect,
};

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { DesignerLayoutActions } from '@slices/designerLayout';

const processActions = [
    DesignerLayoutActions.bottomPaneSetVisibility().type,
    DesignerLayoutActions.leftPaneSetVisibility().type,
    DesignerLayoutActions.rightPaneSetVisibility().type,
];

const designerLayoutFilter = (store) => (next) => (action) => {
    let nextAllowed = true;

    if (processActions.indexOf(action.type) > -1) {
        const state           = store.getState();
        const designerLayout  = state.designerLayout;
        const hasStateChanged = (currentValue) => {
            if (currentValue === action.payload.visible) {
                nextAllowed = false;
            }
        };

        switch (action.type) {
            case DesignerLayoutActions.bottomPaneSetVisibility().type:
                hasStateChanged(designerLayout.bottomPaneVisible);

                break;

            case DesignerLayoutActions.leftPaneSetVisibility().type:
                hasStateChanged(designerLayout.leftPaneVisible);

                break;

            case DesignerLayoutActions.rightPaneSetVisibility().type:
                hasStateChanged(designerLayout.rightPaneVisible);

                break;
        }
    }

    if (nextAllowed) {
        next(action);
    }
};

export default designerLayoutFilter;

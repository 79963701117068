//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import ProductCategoryType     from '@constants/ProductCategoryType';
import ProductSlotType         from '@constants/ProductSlotType';
import ProductSubCategoryTypes from '@constants/ProductSubCategoryType';
import DataProvider            from '@helper/DataProvider';

import Table          from '../Table';
import DefaultColumns from '../Table/DefaultColumns';

const Component = () => {
    const getColumnConfiguration = () => {
        const defaultColumns = DefaultColumns.get(ProductCategoryType.extender, ProductSubCategoryTypes.vario);

        return [
            defaultColumns.displayPartNumber,
            defaultColumns.slotCount,
            defaultColumns.maximumPowerConsumptionInMilliamps,
            defaultColumns.description,
        ];
    };

    return (
        <Table
            columnConfiguration={getColumnConfiguration()}
            productCategoryType={ProductCategoryType.extender}
            productSlotType={ProductSlotType.chassis}
            rawData={DataProvider.getExtenderChassis()}
        />
    );
};

Component.propTypes = {};

Component.defaultProps = {};

export default Component;

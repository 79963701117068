//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import update                 from 'immutability-helper';
import { bindActionCreators } from 'redux';

import TabIndices from '@constants/TabIndices';

const initialState = {
    activeMainTabIndex:      TabIndices.mainFullIpFullHdTab,
    activeSubTabFullHdIndex: TabIndices.subTabFullHdEasyline,
    activeSubTab4KIndex:     TabIndices.subTab4KMedia4Connect,
};

const fullIpProductBrowserSlice = createSlice({
    name:     'fullIpProductBrowser',
    initialState,
    reducers: {
        setActiveMainTabIndex:      (state, action) => {
            return update(state, {
                activeMainTabIndex: {
                    $set: action.payload.activeMainTabIndex,
                },
            });
        },
        setActiveSubTabFullHdIndex: (state, action) => {
            return update(state, {
                activeSubTabFullHdIndex: {
                    $set: action.payload.activeSubTabFullHdIndex,
                },
            });
        },
        setActiveSubTab4KIndex:     (state, action) => {
            return update(state, {
                activeSubTab4KIndex: {
                    $set: action.payload.activeSubTab4KIndex,
                },
            });
        },
        resetAll:                   (state) => {
            return update(state, {
                $set: initialState,
            });
        },
    },
});

export const FullIpProductBrowserActions = fullIpProductBrowserSlice.actions;

export const FullIpProductBrowserReducer = fullIpProductBrowserSlice.reducer;

export const useFullIpProductBrowser = (dispatch) => bindActionCreators(FullIpProductBrowserActions, dispatch);

export default fullIpProductBrowserSlice;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import SlotType         from '@constants/SlotType';
import DataProvider     from '@helper/DataProvider';
import LimitationHelper from '@helper/Limitations';

import ActiveProjectsFactory from '../factories/activeProjects';

class limitationAddChassisFanIfPowerConsumptionThresholdReached {
    static updateProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        productWarnings,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        // We check for the existence of changeSet since its possible that this method is called
        // from slotIsValid without a change set
        if (changeSet) {
            if (
                product.productData.recommendedPowerConsumptionInMilliamps < powerConsumptionInMilliamps &&
                !LimitationHelper.productHasChassisFan(product) &&
                !product.fanAddedAutomatically
            ) {
                const chassisFan = DataProvider.getFanForProductId(product.productData.partNo);

                if (chassisFan) {
                    const productRequiresFanInChassis = LimitationHelper.productRequiresFanInChassis(product);
                    const productHasNoFan             = !LimitationHelper.productHasFan(product);

                    if (!productRequiresFanInChassis && productHasNoFan) {
                        changeSet.products[categoryType][productIndex].fanAddedAutomatically = {
                            $set: true,
                        };

                        _.set(
                            changeSet,
                            `products[${categoryType}][${productIndex}].subProducts[${SlotType.fan}][0]`,
                            {
                                $set: ActiveProjectsFactory.getEmptyProductSlot(chassisFan),
                            },
                        );
                    }
                } else {
                    console.warn('Tried to add chassis fan for product but could not find any matching fan', product);
                }
            } else if (
                product.productData.recommendedPowerConsumptionInMilliamps >= powerConsumptionInMilliamps &&
                product.fanAddedAutomatically
            ) {
                changeSet.products[categoryType][productIndex].fanAddedAutomatically = {
                    $set: false,
                };
            }
        }

        return false;
    };

    static updateSubProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        subProduct,
        subProductIndex,
        productWarnings,
        subProductWarnings,
        subProductCategory,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        return false;
    };
}

export default limitationAddChassisFanIfPowerConsumptionThresholdReached;

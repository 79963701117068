//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';
import _    from 'lodash';

import styles           from './styles.module.scss';
import ColorButton      from '../ColorButton';
import ColorButtonTheme from '../ColorButton/ColorButtonTheme';
import TextButton       from '../TextButton';
import TextButtonTheme  from '../TextButton/TextButtonTheme';

const Component = ({
    actionLabel    = '',
    actionsVisible = false,
    confirmLabel   = '',
    onActionClick  = _.noop,
    onCancelClick  = _.noop,
    onConfirmClick = _.noop,
}) => {
    const renderActionButton   = () => {
        if (!actionsVisible) {
            return (
                <TextButton
                    text={actionLabel}
                    theme={TextButtonTheme.black}
                    onClick={onClickActionButton}
                />
            );
        }

        return null;
    };
    const renderCancelButton   = () => {
        if (actionsVisible) {
            return (
                <TextButton
                    text={I18n.t('cancel')}
                    theme={TextButtonTheme.black}
                    onClick={onClickCancelButton}
                />
            );
        }

        return null;
    };
    const renderConfirmButton  = () => {
        if (actionsVisible) {
            return (
                <ColorButton
                    text={confirmLabel}
                    theme={ColorButtonTheme.orange}
                    onClick={onClickConfirmButton}
                />
            );
        }

        return null;
    };
    const onClickConfirmButton = () => {
        if (onConfirmClick) {
            onConfirmClick();
        }
    };
    const onClickActionButton  = () => {
        if (onActionClick) {
            onActionClick();
        }
    };
    const onClickCancelButton  = () => {
        if (onCancelClick) {
            onCancelClick();
        }
    };

    return (
        <div
            className={styles.actionCancelAndConfirmButton}
        >
            {renderActionButton()}
            {renderCancelButton()}
            {renderConfirmButton()}
        </div>
    );
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push }   from 'connected-react-router';
import { put }    from 'redux-saga/effects';
import { call }   from 'redux-saga/effects';
import { select } from 'redux-saga/effects';

import { SalesMailboxActions }  from '@slices/salesMailbox';
import * as Api                 from '@api/index';
import { getPathForOverlayKey } from '@components/OverlayManager';
import Overlays                 from '@constants/Overlays';
import Cast                     from '@helper/Cast';

const submit = function* () {
    const activeProject           = yield select((state) => state.activeProject);
    const salesMailbox            = yield select((state) => state.salesMailbox);
    const settings                = yield select((state) => state.settings);
    const activeProjectJSONString = JSON.stringify(activeProject);
    const postData                = {
        comment:               salesMailbox.comment,
        companyName:           salesMailbox.company,
        eMailAddress:          salesMailbox.eMailAddress,
        firstName:             salesMailbox.firstName,
        ihseProjectNumber:     activeProject.metaData.ihseProjectNumber,
        phoneNumber:           salesMailbox.phoneNumber,
        projectConfiguration:  activeProjectJSONString,
        extendedWarranty:      Cast.bool(salesMailbox.extendedWarranty),
        serviceLevelAgreement: Cast.bool(salesMailbox.serviceLevelAgreement),
        spareParts:            Cast.bool(salesMailbox.spareParts),
        surName:               salesMailbox.lastName,
        language:              settings.language,
        offerNumber:           activeProject.metaData.offerNumber,
    };

    console.log('activeProject activeProject', activeProject, salesMailbox, postData);

    const response = yield call(
        Api.postProjectConfiguration,
        postData.firstName,
        postData.surName,
        postData.companyName,
        postData.eMailAddress,
        postData.phoneNumber,
        postData.ihseProjectNumber,
        postData.extendedWarranty,
        postData.serviceLevelAgreement,
        postData.spareParts,
        postData.projectConfiguration,
        postData.language,
        postData.offerNumber,
        postData.comment,
    );

    if (response.ok) {
        yield put(SalesMailboxActions.submitSucceeded());
    } else {
        yield put(SalesMailboxActions.submitFailed());
    }
};

const submitSuccess = function* () {
    const route = getPathForOverlayKey(Overlays.salesMailboxSuccess);

    yield put(push(route));
};

export default {
    submit,
    submitSuccess,
};

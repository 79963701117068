//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React, { useState } from 'react';

import I18n                from 'i18next';
import _                   from 'lodash';
import { withTranslation } from 'react-i18next';

import styles    from './styles.module.scss';
import Icon      from '../Icon';
import IconType  from '../Icon/IconType';
import PropTypes from '../PropTypes';

const Component = ({ slots }) => {
    const [expanded, setExpanded] = useState(false);
    const getText                 = () => {
        const text = I18n.t('showMoreSlots', {
            slots: slots.length,
        });

        return text;
    };
    const onClick                 = () => {
        setExpanded(true);
    };
    const renderExpandButton      = () => {
        const text = getText();

        return (
            <div
                className={styles.emptySlotsCollapser}
                onClick={onClick}
            >
                <span className={styles.emptySlotsCollapserIconWrapper}>
                    <Icon iconType={IconType.plus} />
                </span>
                <span className={styles.emptySlotsCollapserText}>{text}</span>
            </div>
        );
    };
    const renderSlots             = () => {
        return slots;
    };

    if (_.isArray(slots)) {
        if (expanded) {
            return renderSlots();
        }

        return renderExpandButton();
    }

    return null;
};

Component.propTypes = {
    slots: PropTypes.array,
};

Component.defaultProps = {
    slots: [],
};

export default withTranslation()(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n                 from 'i18next';
import { initReactI18next } from 'react-i18next';

import Languages               from '@constants/Languages';
import ProductSubCategoryTypes from '@constants/ProductSubCategoryType';
import * as Configuration      from '@helper/Configuration';
import Environment             from '@helper/Environment';
import LanguageHelper          from '@helper/Language';

import english from './english';
import german  from './german';

const resources = {
    [Languages.english]: {
        translation: english,
    },
    [Languages.german]:  {
        translation: german,
    },
};

I18n.use(initReactI18next)
    .init({
        resources,
        lng:           LanguageHelper.getConfigDefaultLanguage(),
        fallbackLng:   Languages.english,
        debug:         Environment.isDevelopment(),
        interpolation: {
            escapeValue:      false,
            defaultVariables: {
                applicationName: Configuration.getApplicationName(),
                companyName:     Configuration.getCompanyName(),
                exportFilename:  Configuration.getExportFilename(),
                salesEmail:      Configuration.getSalesEmail(),
                varioAlias:      Configuration.getProductSubCategoryAlias(ProductSubCategoryTypes.vario),
                enterpriseAlias: Configuration.getProductSubCategoryAlias(ProductSubCategoryTypes.enterprise),
                flexAlias:       Configuration.getProductSubCategoryAlias(ProductSubCategoryTypes.flex),
            },
        },
    });
export default I18n;

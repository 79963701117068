// @see https://www.w3schools.com/html/html_form_input_types.asp
export default {
    color:    'color',
    date:     'date',
    email:    'email',
    number:   'number',
    password: 'password',
    tel:      'tel',
    text:     'text',
    url:      'url',
};

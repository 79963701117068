export default {
    chooseFanType:              'chooseFanType',
    closeProject:               'closeProject',
    confirmOverwrite:           'confirmOverwrite',
    deleteModules:              'deleteModules',
    deleteProduct:              'deleteProduct',
    deleteProject:              'deleteProject',
    deleteSfp:                  'deleteSfp',
    deleteSingleOrderSlot:      'deleteSingleOrderSlot',
    deleteSlot:                 'deleteSlot',
    download:                   'download',
    duplicateProduct:           'duplicateProduct',
    endOfLifeProduct:           'endOfLifeProduct',
    export:                     'export',
    exportSuccess:              'exportSuccess',
    fillSlots:                  'fillSlots',
    hitLimitation:              'hitLimitation',
    import:                     'import',
    importActiveProject:        'importActiveProject',
    importSuccessActiveProject: 'importSuccessActiveProject',
    importSuccessNewProject:    'importSuccessNewProject',
    incompatibleProduct:        'incompatibleProduct',
    noInternetConnection:       'noInternetConnection',
    partsList:                  'partsList',
    portOverview:               'portOverview',
    printPreview:               'printPreview',
    salesMailbox:               'salesMailbox',
    salesMailboxSuccess:        'salesMailboxSuccess',
    setSelectedDataConfirm:     'setSelectedDataConfirm',
    settings:                   'settings',
    switchToDuplicatedProduct:  'switchToDuplicatedProduct',
    switchToReversedProduct:    'switchToReversedProduct',
    updateProductData:          'updateProductData',
};
// TODO: mehrspraching?
// TODO: alternativ: hashen?

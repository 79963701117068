//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                from 'i18next';
import { withTranslation } from 'react-i18next';

import styles    from './styles.module.scss';
import Icon      from '../Icon';
import IconType  from '../Icon/IconType';
import PropTypes from '../PropTypes';

const Component = ({
    closeButtonPressed,
    text,
    title,
}) => {
    const renderCloseButton = () => {
        if (closeButtonPressed) {
            return (
                <span
                    className={styles.errorBoxCloseButton}
                    onClick={closeButtonPressed}
                    title={I18n.t('closeOverlay')}
                >
                    <Icon iconType={IconType.close} />
                </span>
            );
        }

        return null;
    };

    return (
        <div className={styles.errorBox}>
            {renderCloseButton()}
            <h4>{title}</h4>
            <p>
                {text}
            </p>
        </div>
    );
};

Component.propTypes = {
    closeButtonPressed: PropTypes.func,
    text:               PropTypes.string,
    title:              PropTypes.string,
};

Component.defaultProps = {
    closeButtonPressed: null,
    text:               '',
    title:              I18n.t('error'),
};

export default withTranslation()(Component);

import React       from 'react';

import { TabList } from 'react-tabs';

import styles      from './styles.module.scss';
import PropTypes   from '../PropTypes';

const Component = ({ children }) => (
    <TabList
        className={styles.subLevelTabList}
    >
        {children}
    </TabList>
);

Component.tabsRole = 'TabList';

Component.propTypes = {
    children: PropTypes.children,
};

Component.defaultProps = {
    children: [],
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames          from 'classnames';
import _                   from 'lodash';
import { withTranslation } from 'react-i18next';

import UsbTypes        from '@constants/UsbTypes';
import SelectionHelper from '@helper/SelectionHelper';

import UsbLabelAlignment     from './UsbLabelAlignment';
import styles                from './styles.module.scss';
import Icon                  from '../Icon';
import IconType              from '../Icon/IconType';
import ProductDataValueLabel from '../ProductDataValueLabel';
import PropTypes             from '../PropTypes';

class Component extends React.Component {
    getIconType = (usb) => {
        return SelectionHelper.get(
            usb,
            {
                [UsbTypes.flexspeed20]: IconType.usbFlexSpeed20,
                [UsbTypes.fullspeed20]: IconType.usbFullSpeed20,
                [UsbTypes.hid]:         IconType.usbHid,
                [UsbTypes.highspeed20]: IconType.usbHighSpeed20,
                [UsbTypes.usb11]:       IconType.usb11,
                [UsbTypes.usb20]:       IconType.usb20,
                [UsbTypes.usb31]:       IconType.usb31,
            },
        );
    };

    getTitle = (usb) => {
        return SelectionHelper.getTranslation(
            usb,
            {
                [UsbTypes.flexspeed20]: 'usbFlexSpeed20',
                [UsbTypes.fullspeed20]: 'usbFullSpeed20',
                [UsbTypes.hid]:         'hid',
                [UsbTypes.highspeed20]: 'usbHighSpeed20',
                [UsbTypes.usb11]:       'usb11',
                [UsbTypes.usb20]:       'usb20',
                [UsbTypes.usb31]:       'usb31',
            },
        );
    };

    render() {
        if (this.props.usb) {
            const labels = [];

            if (_.isArray(this.props.usb)) {
                for (const usb of this.props.usb) {
                    labels.push(this.renderItem(
                        this.props.usb.length,
                        usb,
                    ));
                }
            } else {
                labels.push(this.renderItem(1, this.props.usb));
            }

            if (labels.length > 0) {
                return (
                    <div
                        className={classNames(
                            styles.usbLabelWrapper,
                            SelectionHelper.get(
                                this.props.alignment,
                                {
                                    [UsbLabelAlignment.left]:  styles.usbLabelLeft,
                                    [UsbLabelAlignment.right]: styles.usbLabelRight,
                                },
                            ),
                        )}
                    >
                        {labels}
                    </div>
                );
            }
        }

        return (
            <ProductDataValueLabel value={this.props.usb} />
        );
    }

    renderItem = (usbCount, usb) => {
        const title = this.getTitle(usb);

        if (!title) {
            console.warn('Unsupported usb', usb);

            return (
                <ProductDataValueLabel value={usb} />
            );
        }

        return (
            <span
                className={classNames(
                    styles.usbLabel,
                    SelectionHelper.get(
                        this.props.alignment,
                        {
                            [UsbLabelAlignment.left]:  styles.usbLabelLeft,
                            [UsbLabelAlignment.right]: styles.usbLabelRight,
                        },
                    ),
                )}
                key={usb}
                title={title}
            >
                {this.renderIcon(usb)}
                {
                    usbCount <= 1
                        ? (
                            <span className={styles.usbText}>
                                {title}
                            </span>
                        )
                        : null
                }
            </span>
        );
    };

    renderIcon = (usb) => {
        const iconType = this.getIconType(usb);

        if (iconType) {
            return (
                <span className={styles.usbLabelIconWrapper}>
                    <Icon iconType={iconType} />
                </span>
            );
        }

        return null;
    };
}

Component.propTypes = {
    alignment: PropTypes.oneOf(Object.values(UsbLabelAlignment)),
    usb:       PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
    ]),
};

Component.defaultProps = {
    alignment: null,
    usb:       null,
};

export default withTranslation()(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import { getPathForOverlayKey } from '@components/OverlayManager';
import Overlays                 from '@constants/Overlays';

import styles           from './styles.module.scss';
import ColorButton      from '../ColorButton';
import ColorButtonTheme from '../ColorButton/ColorButtonTheme';
import IconType         from '../Icon/IconType';

const Component = ({
    t,
    hideUnsavedProjectWarning,
}) => {
    const closePressed = () => {
        hideUnsavedProjectWarning();
    };

    return (
        <div
            className={styles.unsavedProjectBanner}
        >
            <div
                className={styles.textButtonContainer}
            >
                <div
                    className={styles.text}
                >
                    {t('configurationIsSavedInBrowserCache')}
                </div>
                <ColorButton
                    text={t('saveNow')}
                    route={getPathForOverlayKey(Overlays.export)}
                    theme={ColorButtonTheme.red}
                />
            </div>
            <ColorButton
                onClick={closePressed}
                icon={IconType.delete}
            />
        </div>
    );
};

Component.propTypes = {};

Component.defaultProps = {};

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

const ConnectedComponent = connect(
    null,
    mapDispatchToProps,
)(Component);

export default withTranslation()(ConnectedComponent);

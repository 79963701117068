//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import Limitations             from '@constants/Limitations';
import SlotType                from '@constants/SlotType';
import LimitationLimitToAmount from '@store/calculators/limitationLimitToAmount';

class LimitationsApplier {
    static filterProductListByLimitTo(productList, targetProductId, noAutoAddOnMissingLimitToLimitation, type) {
        const filteredData = _.filter(productList, (product) => {
            let showProduct          = false;
            let hasLimitToLimitation = false;

            if (product && product.limitations) {
                for (const limitation of product.limitations) {
                    if (
                        limitation.type === Limitations.limitTo ||
                        limitation.type === Limitations.limitToAmount
                    ) {
                        if (limitation.partNo === targetProductId) {
                            showProduct = true;

                            break;
                        }

                        if (!hasLimitToLimitation) {
                            hasLimitToLimitation = true;
                        }
                    }
                }
            }

            if (!showProduct && !hasLimitToLimitation && !noAutoAddOnMissingLimitToLimitation) {
                showProduct = true;

                console.log('noAutoAddOnMissingLimitToLimitation', noAutoAddOnMissingLimitToLimitation, product);
            }

            if (type && product.type !== type) {
                showProduct = false;
            }

            return showProduct;
        });

        console.log(
            'Limitations: filterProductListByLimitTo',
            targetProductId,
            productList.length,
            filteredData.length,
        );

        return filteredData;
    }

    static filterProductListByLimitToAmount(productList, selectedProduct, noAutoAddOnMissingLimitToAmountLimitation, type) {
        const filteredData = _.filter(productList, (product) => {
            let showProduct          = false;
            let hasLimitToLimitation = false;

            if (product && product.limitations) {
                for (const limitation of product.limitations) {
                    if (limitation.type === Limitations.limitToAmount) {
                        if (!LimitationLimitToAmount.hasHitLimitation(
                            limitation,
                            null,
                            null,
                            selectedProduct,
                            product,
                            null,
                            null,
                            null,
                            SlotType.slot,
                        )) {
                            showProduct = true;
                        }

                        if (!hasLimitToLimitation) {
                            hasLimitToLimitation = true;
                        }
                    }
                }
            }

            if (!showProduct && !hasLimitToLimitation && !noAutoAddOnMissingLimitToAmountLimitation) {
                showProduct = true;

                console.log('noAutoAddOnMissingLimitToAmountLimitation', noAutoAddOnMissingLimitToAmountLimitation, product);
            }

            if (type && product.type !== type) {
                showProduct = false;
            }

            return showProduct;
        });

        console.log(
            'Limitations: filterProductListByLimitToAmount',
            selectedProduct,
            productList.length,
            filteredData.length,
        );

        return filteredData;
    }

    static getModuleFanCount(product) {
        let moduleFanCount = 0;

        if (product) {
            for (const slot of product.subProducts.slot) {
                if (slot.productData && slot.productData.type === SlotType.fan) {
                    ++moduleFanCount;
                }
            }
        }

        return moduleFanCount;
    }

    static getSlotLimitation(product, limitationType) {
        if (product) {
            const productData = product.productData;

            if (productData) {
                for (const subProduct of product.subProducts[SlotType.slot]) {
                    const subProductData = subProduct.productData;

                    if (subProductData) {
                        if (subProductData.limitations) {
                            for (const limitation of subProductData.limitations) {
                                console.log(
                                    'getSlotLimitation',
                                    limitation,
                                    limitation.partNo,
                                    productData.partNo,
                                    limitation.type,
                                    limitationType,
                                );

                                if (
                                    limitation.type === limitationType &&
                                    limitation.partNo === productData.partNo
                                ) {
                                    return limitation;
                                }
                            }
                        }
                    }
                }
            }
        }

        return null;
    }

    static getAdditionalPermittedAddons(productData) {
        const foundLimitation      = _.find(productData.limitations, (limitation) => {
            return limitation.type === Limitations.additionalPermitAddOn;
        });
        const partNumbersString    = foundLimitation
            ? foundLimitation.partNo
            : '';
        const permittedPartNumbers = partNumbersString
            .split(',')
            .map((partNumber) => {
                return partNumber.trim();
            });

        return permittedPartNumbers;
    }

    static productHasChassisFan(product) {
        if (product) {
            if (
                product.subProducts[SlotType.fan].length > 0 &&
                product.subProducts[SlotType.fan][0].productData
            ) {
                return true;
            }
        }

        return false;
    }

    static productHasModuleFan(product) {
        if (product) {
            for (const slot of product.subProducts.slot) {
                if (slot.productData && slot.productData.type === SlotType.fan) {
                    return true;
                }
            }
        }

        return false;
    }

    static productHasFan(product) {
        if (product) {
            const productHasChassisFan = this.productHasChassisFan(product);
            const productHasModuleFan  = this.productHasModuleFan(product);

            return (
                productHasChassisFan ||
                productHasModuleFan
            );
        }

        return false;
    }

    static productHasModule(product, modulePartNo) {
        if (product) {
            for (const slot of product.subProducts.slot) {
                if (slot.productData && slot.productData.partNo === modulePartNo) {
                    return true;
                }
            }
        }

        return false;
    }

    static productHasFanWithChangeSet(changeSet, categoryType, productIndex, product) {
        if (product) {
            // This is set when "addChassisFanIfPowerConsumptionThresholdReached" automatically
            // added a fan
            if (_.get(changeSet, `products[${categoryType}][${productIndex}].fanAddedAutomatically`, false)) {
                return true;
            }
        }

        return LimitationsApplier.productHasFan(product);
    }

    static productRequiresFanInChassis(product) {
        if (product) {
            const productData = product.productData;

            if (productData) {
                for (const subProduct of product.subProducts[SlotType.slot]) {
                    const subProductData = subProduct.productData;

                    if (
                        subProductData &&
                        subProductData.limitations
                    ) {
                        for (const limitation of subProductData.limitations) {
                            if (
                                limitation.type === Limitations.requiresFanInChassis &&
                                limitation.partNo === productData.partNo
                            ) {
                                return true;
                            }
                        }
                    }
                }
            }
        }

        return false;
    }

    static getRequiredModuleInChassis(product) {
        if (product) {
            const productData = product.productData;

            if (productData) {
                for (const subProduct of product.subProducts[SlotType.slot]) {
                    const subProductData = subProduct.productData;

                    if (
                        subProductData &&
                        subProductData.limitations
                    ) {
                        for (const limitation of subProductData.limitations) {
                            if (limitation.type === Limitations.requiresModuleInChassis) {
                                const requiredModulePartNo = limitation.partNo;
                                const requiredModuleSlot   = limitation.value;

                                return {
                                    requiredModulePartNo,
                                    requiredModuleSlot,
                                };
                            }
                        }
                    }
                }
            }
        }

        return null;
    }
}

export default LimitationsApplier;

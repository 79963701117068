//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React, { useState } from 'react';

import styles    from './styles.module.scss';
import PropTypes from '../PropTypes';

const Component = ({ children, mouseCoordinates }) => {
    const [prevMouseCoordinates, setPrevMouseCoordinates] = useState(null);
    const [rotation, setRotation]                         = useState(0);
    const xVelocity                                       = mouseCoordinates?.x - prevMouseCoordinates?.x;

    if (
        prevMouseCoordinates &&
        mouseCoordinates &&
        mouseCoordinates !== prevMouseCoordinates &&
        mouseCoordinates.x !== prevMouseCoordinates.x
    ) {
        const newRotation = getRotation(xVelocity);
        setRotation(newRotation);
    }

    const getRotation       = (xVelocity) => {
        const newRotation =
                  rotation * 0.9 +
                  sigmoid(xVelocity) * 1.5;

        if (Math.abs(newRotation) < 0.01) {
            return 0;
        }

        return newRotation;
    };
    const sigmoid           = (x) => {
        return x / (
            1 + Math.abs(x)
        );
    };
    const getTransformStyle = () => {
        return {
            transform: `rotate(${rotation}deg)`,
        };
    };

    return (
        <div
            className={styles.velocityDragView}
            style={getTransformStyle()}
        >
            {children}
        </div>
    );
};

Component.propTypes = {
    children:         PropTypes.children,
    mouseCoordinates: PropTypes.object,
};

Component.defaultProps = {
    children:         [],
    mouseCoordinates: null,
};

export default Component;

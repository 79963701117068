//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { ConnectedRouter } from 'connected-react-router';
import update              from 'immutability-helper';
import { Switch }          from 'react-router';
import { Route }           from 'react-router';
import ReactTooltip        from 'react-tooltip';

import BrowserCheck                from './components/BrowserCheck';
import CacheInvalidator            from './components/CacheInvalidator';
import DragDropContext             from './components/DragDropContext';
import DragLayer                   from './components/DragLayer';
import ImportActiveProjectOverlay  from './components/ImportActiveProjectOverlay';
import ImportOverlay               from './components/ImportOverlay';
import NoInternetConnectionOverlay from './components/NoInternetConnectionOverlay';
import OverlayManager              from './components/OverlayManager';
import ProductPreviewImageLayer    from './components/ProductPreviewImageLayer';
import ScreenToSmallOverlay        from './components/ScreenToSmallOverlay';
import SettingsOverlay             from './components/SettingsOverlay';
import StoreProvider               from './components/StoreProvider';
import Overlays                    from './constants/Overlays';
import Routes                      from './constants/Routes';
import Contact                     from './screens/Contact';
import DataProtection              from './screens/DataProtection';
import Designer                    from './screens/Designer';
import Help                        from './screens/Help';
import Home        from './screens/Home';
import Imprint     from './screens/Imprint';
import NotFound    from './screens/NotFound';
import { history } from './store';
import styles      from './styles.module.scss';

update.extend('$increaseBy', (amount, value) => {
    return value + amount;
});

update.extend('$decreaseBy', (amount, value) => {
    return value - amount;
});

update.extend('$decreaseByStayPositive', (amount, value) => {
    if (value > 1) {
        return value - amount;
    }

    return value;
});

export const DragDropAndStoreWrapper = ({ initialState, children }) => (
    <DragDropContext>
        <StoreProvider initialState={initialState}>
            {children}
        </StoreProvider>
    </DragDropContext>
);

export const App = ({ initialHistory }) => (
    <DragDropContext>
        <StoreProvider>
            <ProductPreviewImageLayer>
                <DragLayer />
                <ReactTooltip
                    html={true}
                />
                <ConnectedRouter history={initialHistory || history}>
                    <ScreenToSmallOverlay />
                    <CacheInvalidator />
                    <BrowserCheck />
                    <div className={styles.appContent}>
                        <OverlayManager
                            overlays={{
                                [Overlays.noInternetConnection]: NoInternetConnectionOverlay,
                                [Overlays.import]:               ImportOverlay,
                                [Overlays.importActiveProject]:  ImportActiveProjectOverlay,
                                [Overlays.settings]:             SettingsOverlay,
                            }}
                        />
                        <Switch>
                            <Route
                                exact={true}
                                path={Routes.home}
                                component={Home}
                            />
                            <Route
                                exact={true}
                                path={Routes.contact}
                                component={Contact}
                            />
                            <Route
                                exact={false}
                                path={Routes.help}
                                component={Help}
                            />
                            <Route
                                exact={true}
                                path={Routes.dataProtection}
                                component={DataProtection}
                            />
                            <Route
                                exact={true}
                                path={Routes.imprint}
                                component={Imprint}
                            />
                            <Route
                                exact={false}
                                path={Routes.designer}
                                component={Designer}
                            />
                            <Route
                                component={NotFound}
                            />
                        </Switch>
                    </div>
                </ConnectedRouter>
            </ProductPreviewImageLayer>
        </StoreProvider>
    </DragDropContext>
);

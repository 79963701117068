//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _           from 'lodash';
import { connect } from 'react-redux';

import AccessoryTypes      from '@constants/AccessoryTypes';
import ProductCategoryType from '@constants/ProductCategoryType';
import SlotType            from '@constants/SlotType';
import Cast                from '@helper/Cast';
import Product             from '@helper/Product';
import Slot                from '@helper/Slot';
import StateHelper         from '@helper/State';

import styles              from './styles.module.scss';
import EmptySlotsCollapser from '../EmptySlotsCollapser';
import ProductSlotListSlot from '../ProductSlotListSlot';

class Component extends React.Component {
    render() {
        return (
            <div
                className={styles.productSlotList}
            >
                {this.renderSlots()}
                {this.renderFans()}
                {this.renderAccessories()}
                {this.renderPowerSupplyUnits()}
            </div>
        );
    }

    renderAccessories = () => {
        if (
            this.props.isExtender ||
            this.props.isFullIp
        ) {
            const accessories = [];

            accessories.push(<ProductSlotListSlot
                accessoryType={AccessoryTypes.accessory}
                data={this.props.selectedProduct.subProducts.accessory[0]}
                key={`accessory${0}`}
                index={0}
                totalCount={1}
                type={SlotType.accessory}
            />);

            return accessories;
        }
    };

    renderFans = () => {
        const selectedProduct = this.props.selectedProduct;

        if (!selectedProduct) {
            return null;
        }

        if (this.props.isExtender) {
            const optionalFanCount = this.props.selectedProduct.productData.optionalFanCount;

            if (optionalFanCount > 0) {
                const fans = [];

                for (let fanIndex = 0; fanIndex < optionalFanCount; fanIndex++) {
                    fans.push(<ProductSlotListSlot
                        accessoryType={AccessoryTypes.fan}
                        data={this.props.selectedProduct.subProducts.fan[fanIndex]}
                        key={`fan${fanIndex}`}
                        index={fanIndex}
                        isLocked={this.props.selectedProduct.fanAddedAutomatically}
                        totalCount={optionalFanCount}
                        type={SlotType.fan}
                    />);
                }

                return fans;
            }
        }

        return null;
    };

    renderPowerSupplyUnits = () => {
        const powerSupplyUnits = [];
        const selectedProduct  = this.props.selectedProduct;

        if (!selectedProduct) {
            return null;
        }

        const selectedProductPowerSupplyUnits = selectedProduct.subProducts.powerSupplyUnit;
        const totalCount                      = _.get(selectedProductPowerSupplyUnits, 'length', 0);

        for (const powerSupplyUnitIndex in selectedProductPowerSupplyUnits) {
            const currentPowerSupplyUnit = selectedProductPowerSupplyUnits[powerSupplyUnitIndex];

            powerSupplyUnits.push(<ProductSlotListSlot
                accessoryType={AccessoryTypes.powerSupplyUnit}
                data={currentPowerSupplyUnit}
                key={`powerSupplyUnit${powerSupplyUnitIndex}`}
                index={Cast.int(powerSupplyUnitIndex)}
                totalCount={totalCount}
                type={SlotType.powerSupplyUnit}
            />);
        }

        return powerSupplyUnits;
    };

    renderSlots = () => {
        const cpuSlots = [];
        let emptySlots = [];
        let slots      = [];

        const selectedProduct = this.props.selectedProduct;

        if (!selectedProduct) {
            return null;
        }

        const totalCount = _.get(selectedProduct, 'subProducts.slot.length', 0);

        for (const slotIndex in selectedProduct.subProducts.slot) {
            const slotIndexInteger = Cast.int(slotIndex);

            if (Slot.hideSlot(slotIndexInteger, selectedProduct)) {
                continue;
            }

            const nextSlotIsDoubleSlot = Product.slotIsDoubleSlot(
                this.props.selectedProduct,
                slotIndexInteger + 1,
            );

            if (!nextSlotIsDoubleSlot) {
                const currentSlot = this.props.selectedProduct.subProducts.slot[slotIndex];
                const slotIsCpu   = this.slotIsCpu(this.props.selectedProduct.productData, slotIndexInteger);
                const slot        = (
                    <ProductSlotListSlot
                        accessoryType={null}
                        productData={this.props.selectedProduct.productData}
                        data={currentSlot}
                        key={`slot${slotIndex}`}
                        index={slotIndexInteger}
                        // This is passed using the hoc but we have to pass it again
                        // to workaround https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-514
                        isDoubleSlot={Product.slotIsDoubleSlot(
                            this.props.selectedProduct,
                            slotIndexInteger,
                        )}
                        totalCount={totalCount}
                        type={SlotType.slot}
                    />
                );

                if (slotIsCpu) {
                    cpuSlots.push(slot);
                } else {
                    const slotIsEmpty = !currentSlot.productData;

                    if (slotIsEmpty && slotIndexInteger > 5) {
                        emptySlots.push(slot);
                    } else {
                        if (emptySlots.length >= 5) {
                            slots.push(<EmptySlotsCollapser
                                key={`slotCollapser${slotIndex}`}
                                slots={emptySlots}
                            />);
                        } else {
                            slots = slots.concat(emptySlots);
                        }

                        emptySlots = [];

                        slots.push(slot);
                    }
                }
            }
        }

        if (emptySlots.length >= 0) {
            if (emptySlots.length >= 5) {
                slots.push(<EmptySlotsCollapser
                    key={'slotCollapserEnd'}
                    slots={emptySlots}
                />);
            } else {
                slots = slots.concat(emptySlots);
            }
        }

        const finalSlotList = [].concat(cpuSlots, slots);

        return finalSlotList;
    };

    slotIsCpu = (productData, index) => {
        // TODO: der Code ist doppelt
        if (productData) {
            const layoutDefinition = productData.layoutDefinition;
            const isCpu            = (
                layoutDefinition &&
                layoutDefinition.unused &&
                layoutDefinition.io &&
                layoutDefinition.unused.indexOf(index + 1) === -1 &&
                layoutDefinition.io.indexOf(index + 1) === -1
            );

            return isCpu;
        }

        return false;
    };
}

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => {
    const selectedProduct = StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct);

    return {
        selectedProduct,
        isExtender: _.get(selectedProduct, 'categoryType') === ProductCategoryType.extender,
        isFullIp:   _.get(selectedProduct, 'categoryType') === ProductCategoryType.fullIp,
        isMatrix:   _.get(selectedProduct, 'categoryType') === ProductCategoryType.matrix,
    };
};

export default connect(
    mapStateToProps,
    null,
)(Component);

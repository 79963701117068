//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import { bindActionCreators } from 'redux';
import update                 from 'immutability-helper';
import Overlays               from '@constants/Overlays';

const initialState = {
    hiddenOverlays: {
        [Overlays.closeProject]:              false,
        [Overlays.deleteProduct]:             false,
        [Overlays.deleteSfp]:                 false,
        [Overlays.deleteSlot]:                false,
        [Overlays.deleteSingleOrderSlot]:     false,
        [Overlays.setSelectedDataConfirm]:    false,
        [Overlays.switchToDuplicatedProduct]: false,
        [Overlays.switchToReversedProduct]:   false,
    },
};

const OverlaySlice = createSlice({
    name:     'overlay',
    initialState,
    reducers: {
        resetAll: (state) => {
            return update(state, {
                $set: initialState,
            });
        },
        resetOverlayVisibilities: (state) => {
            return update(state, {
                $set: initialState,
            });
        },
        setOverlayVisibility: (state, action) => {
            const { payload } = action;
            return update(state, {
                hiddenOverlays: {
                    [payload.overlayId]: {
                        $set: payload.visible,
                    },
                },
            });
        },
    },
});

export const OverlayActions = OverlaySlice.actions;

export const OverlayReducer = OverlaySlice.reducer;

export const useOverlay = (dispatch) => bindActionCreators(OverlayActions, dispatch);

export default OverlaySlice;

import React from 'react';

import _ from 'lodash';

import TestIds from '@constants/TestIds';

import styles from './styles.module.scss';
import Icon from '../Icon';
import IconType from '../Icon/IconType';
import PropTypes from '../PropTypes';

const Component = ({
    onClick = _.noop,
}) => (
    <span
        data-testId={TestIds.tableFilterButton}
        className={styles.tableFilterButton}
        onClick={onClick}
    >
        <Icon iconType={IconType.filter} />
    </span>
);

Component.propTypes = {
    onClick: PropTypes.func,
};

export default Component;

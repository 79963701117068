//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import SlotDefaultBounds from '@constants/SlotDefaultBounds';

import GapCalculator from '../../SelectedProductImageRenderer/BoundsCalculators/GapCalculator';
import ChassisLayout from '../../SelectedProductImageRenderer/ChassisLayout';

/**
 * @see This file also exists in the backend src/BoundsCalculators/SfpTopLeftToBottomToRight.php
 */
class SfpTopLeftToBottomToRight {
    static getBoundsChassisLayoutHorizontalX = (bounds, layoutDefinition) => {
        let x = 0;

        // @formatter:off
        x += layoutDefinition.start.x;                                         // Start position
        x += 2;                                                                // Add
        x += (bounds.column - 1) * bounds.height;                              //
        x += GapCalculator.getGap(layoutDefinition.gap.column, bounds.column); //
        // @formatter:on

        return x;
    };

    static getBoundsChassisLayoutVerticalX = (bounds, layoutDefinition) => {
        // This is the same since the whole card is getting rotated
        return this.getBoundsChassisLayoutHorizontalX(bounds, layoutDefinition);
    };

    static getBoundsChassisLayoutHorizontalY = (bounds, layoutDefinition) => {
        let y = 0;

        // @formatter:off
        y += layoutDefinition.start.y;                                   // Start position
        y += GapCalculator.getGap(layoutDefinition.gap.row, bounds.row); //
        y += (bounds.row - 1) * bounds.width;                            //
        // @formatter:on

        return y;
    };

    static getBoundsChassisLayoutVerticalY = (bounds, layoutDefinition) => {
        // This is the same since the whole card is getting rotated
        return this.getBoundsChassisLayoutHorizontalY(bounds, layoutDefinition);
    };

    static getCurrentColumnChassisLayoutHorizontal = (sfpIndexStartingBy1, layoutDefinition) => {
        return (
            (
                sfpIndexStartingBy1 - 1
            ) % layoutDefinition.rowCount + 1
        );
    };

    static getCurrentColumnChassisLayoutVertical = (sfpIndexStartingBy1, layoutDefinition) => {
        // This is the same since the whole card is getting rotated
        return this.getCurrentColumnChassisLayoutHorizontal(sfpIndexStartingBy1, layoutDefinition);
    };

    static getCurrentRowChassisLayoutHorizontal = (sfpIndexStartingBy1, layoutDefinition) => {
        return Math.ceil(sfpIndexStartingBy1 / layoutDefinition.rowCount);
    };

    static getCurrentRowChassisLayoutVertical = (sfpIndexStartingBy1, layoutDefinition) => {
        // This is the same since the whole card is getting rotated
        return this.getCurrentRowChassisLayoutHorizontal(sfpIndexStartingBy1, layoutDefinition);
    };

    static getBounds = (sfpIndexStartingBy1, layoutDefinition, chassisLayout) => {
        const bounds = this.getDefaultBounds(layoutDefinition);

        switch (chassisLayout) {
            case ChassisLayout.horizontal:
                this.updateBoundsChassisLayoutHorizontal(
                    sfpIndexStartingBy1,
                    layoutDefinition,
                    bounds,
                );

                break;

            case ChassisLayout.vertical:
                this.updateBoundsChassisLayoutVertical(
                    sfpIndexStartingBy1,
                    layoutDefinition,
                    bounds,
                );

                break;
        }

        return bounds;
    };

    static getDefaultBounds = (layoutDefinition) => {
        const sfpDefaultBounds = SlotDefaultBounds.sfp;

        return {
            chassisLayout: ChassisLayout.horizontal,
            height:        sfpDefaultBounds.height,
            x:             0,
            y:             0,
            width:         sfpDefaultBounds.width,
        };
    };

    static updateBoundsChassisLayoutHorizontal = (sfpIndexStartingBy1, layoutDefinition, bounds) => {
        bounds.column = this.getCurrentColumnChassisLayoutHorizontal(sfpIndexStartingBy1, layoutDefinition);
        bounds.row    = this.getCurrentRowChassisLayoutHorizontal(sfpIndexStartingBy1, layoutDefinition);
        bounds.x      = this.getBoundsChassisLayoutHorizontalX(bounds, layoutDefinition);
        bounds.y      = this.getBoundsChassisLayoutHorizontalY(bounds, layoutDefinition);
    };

    static updateBoundsChassisLayoutVertical = (sfpIndexStartingBy1, layoutDefinition, bounds) => {
        // This is the same since the whole card is getting rotated
        return this.updateBoundsChassisLayoutHorizontal(sfpIndexStartingBy1, layoutDefinition, bounds);
    };
}

export default SfpTopLeftToBottomToRight;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default {
    flexspeed20: 'flexspeed20',
    fullspeed20: 'fullspeed20',
    hid:         'hid',
    highspeed20: 'highspeed20',
    usb11:       'usb11',
    usb20:       'usb20',
    usb31:       'usb31',
};

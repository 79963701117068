//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { Buffer } from 'buffer';

class Base64 {
    static encode(input) {
        if (input) {
            const encodedString = new Buffer(input).toString('base64');

            return encodedString;
        }

        return null;
    }
}

export default Base64;

import React from 'react';

import classNames from 'classnames';
import _ from 'lodash';

import SelectionHelper from '@helper/SelectionHelper';

import TextButtonTheme from './TextButtonTheme';
import styles from './styles.module.scss';
import PropTypes from '../PropTypes';

const Component = ({
    onClick,
    testId,
    text,
    theme,
}) => (
    <span
        data-testId={testId}
        className={classNames(
            styles.textButton,
            SelectionHelper.get(theme, {
                [TextButtonTheme.black]: styles.textButtonBlack,
                [TextButtonTheme.graySlim]: styles.textButtonGraySlim,
                [TextButtonTheme.orange]: styles.textButtonOrange,
                [TextButtonTheme.orangeSlim]: styles.textButtonOrangeSlim,
            }),
        )}
        onClick={onClick}
    >
        {text}
    </span>
);

Component.propTypes = {
    onClick: PropTypes.func,
    testId: PropTypes.string,
    text: PropTypes.string,
    theme: PropTypes.oneOf(Object.values(TextButtonTheme)),
};

Component.defaultProps = {
    onClick: _.noop,
    text: '',
    theme: null,
    testId: '',
};

export default Component;

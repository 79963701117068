import React from 'react';

import Cast from '@helper/Cast';

import MilliampsLabel from '../MilliampsLabel';
import PropTypes from '../PropTypes';

const Component = ({
    value,
}) => {
    const milliamps = Cast.int(value);

    return (
        <MilliampsLabel milliamps={milliamps} />
    );
};

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _              from 'lodash';
import queryString    from 'query-string';
import { withRouter } from 'react-router-dom';

const getNextRouteInternal = (location, overlayKey, parsedQueryString, route) => {
    if (!parsedQueryString) {
        parsedQueryString = queryString.parse(location.search);
    }

    const nextParsedQueryString   = _.cloneDeep(parsedQueryString);
    nextParsedQueryString.overlay = overlayKey;
    const nextQueryString         = queryString.stringify(nextParsedQueryString);
    const nextRoute               = (
        (
            route || location.pathname
        ) + (
            nextQueryString && nextQueryString.length > 0
                ? `?${nextQueryString}`
                : ''
        )
    );

    return nextRoute;
};

const getNextRoute = (parsedQueryString, location) => {
    return getNextRouteInternal(location, undefined, parsedQueryString);
};

const Component = ({
    location,
    overlays = {},
}) => {
    const parsedQueryString = queryString.parse(location.search);

    console.log('OverlayManager: render: parsedQueryString', parsedQueryString);
    console.log('OverlayManager: render: overlays', overlays);

    if (parsedQueryString.overlay) {
        for (const currentOverlayKey in overlays) {
            if (currentOverlayKey === parsedQueryString.overlay) {
                console.log('OverlayManager: render: showing overlay', currentOverlayKey);

                const nextRoute       = getNextRoute(parsedQueryString, location);
                const OverlayInstance = overlays[parsedQueryString.overlay];

                return (
                    <OverlayInstance
                        key={currentOverlayKey}
                        nextRoute={nextRoute}
                    />
                );
            }
        }
    }

    return null;
};

export const getPathForOverlayKey = (overlayKey, route) => {
    return getNextRouteInternal(window.location, overlayKey, undefined, route);
};

export default withRouter(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import I18n       from 'i18next';

import DataProvider from '@helper/DataProvider';

import ProductImageRendererMode from './ProductImageRendererMode';
import styles                   from './styles.module.scss';
import Icon                     from '../Icon';
import IconType                 from '../Icon/IconType';
import PropTypes                from '../PropTypes';

class Component extends React.Component {
    getImageStyle = (imageData) => {
        const image  = DataProvider.getImageForProductId(this.props.productData.id, imageData.suffix);
        const height = imageData.height * this.props.scale;
        const width  = imageData.width * this.props.scale;
        const style  = {
            backgroundPosition: '0 0',
            backgroundImage:    `url(${image})`,
            backgroundSize:     `${width}px ${height}px`,
            height,
            width,
        };

        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-301
        if (imageData.suffix) {
            style.position = 'absolute';
            style.top      = 0;

            switch (imageData.suffix) {
                case 'L':
                    style.left = -1 * width;

                    break;

                case 'R':
                    style.right = -1 * width;

                    break;
            }
        }

        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-298
        if (this.props.enableSlotImageWhitespaceWorkaround) {
            // @formatter:off
            const backgroundLeft     = 1 * this.props.scale;
            const backgroundWidth    = (imageData.width) * this.props.scale;

            style.backgroundPosition = `${backgroundLeft}px 0px`;
            style.backgroundSize     = `${backgroundWidth}px ${height}px`;
            // @formatter:on
        }

        return style;
    };

    getMainClassName = () => {
        return classNames(
            styles.productImageRenderer,
            {
                [styles.productImageRendererReadOnly]: this.props.isReadOnly,
            },
        );
    };

    render() {
        if (this.props.productData && this.props.productData.images) {
            if (this.props.mode === ProductImageRendererMode.parallel) {
                return this.renderParallel();
            }

            if (this.props.mode === ProductImageRendererMode.stack) {
                return this.renderStack();
            }
        }

        return this.renderFallback();
    }

    renderFallback = () => {
        if (!this.props.hideFallbackText) {
            return (
                <div className={styles.productImageRendererFallbackWrapper}>
                    <div className={styles.productImageRendererFallbackIconWrapper}>
                        <Icon iconType={IconType.help} />
                    </div>
                    <span className={styles.productImageRendererFallbackText}>
                        {I18n.t('noImageText')}
                    </span>
                </div>
            );
        }

        return null;
    };

    renderParallel = () => {
        const content = [];

        for (const imageDataIndex in this.props.productData.images) {
            const imageData  = this.props.productData.images[imageDataIndex];
            const imageStyle = this.getImageStyle(imageData);

            content.push(<div
                key={imageDataIndex}
                className={this.getMainClassName()}
                style={imageStyle}
            />);
        }

        content.push(this.props.children);

        return (
            <div
                className={styles.productImageRendererWrapper}
                id={this.props.id}
            >
                {content}
            </div>
        );
    };

    renderStack = () => {
        let content = this.props.children;

        for (const imageData of this.props.productData.images) {
            const imageStyle = this.getImageStyle(imageData);

            content = (
                <div
                    className={this.getMainClassName()}
                    style={imageStyle}
                >
                    {content}
                </div>
            );
        }

        return (
            <div
                className={styles.productImageRendererWrapper}
                id={this.props.id}
            >
                {content}
            </div>
        );
    };
}

Component.propTypes = {
    children:                            PropTypes.children,
    enableSlotImageWhitespaceWorkaround: PropTypes.bool,
    hideFallbackText:                    PropTypes.bool,
    id:                                  PropTypes.string,
    isReadOnly:                          PropTypes.bool,
    mode:                                PropTypes.oneOf(Object.values(ProductImageRendererMode)),
    productData:                         PropTypes.object,
    scale:                               PropTypes.number,
};

Component.defaultProps = {
    children:                            null,
    enableSlotImageWhitespaceWorkaround: false,
    hideFallbackText:                    false,
    id:                                  null,
    isReadOnly:                          false,
    productData:                         {},
    mode:                                ProductImageRendererMode.stack,
    scale:                               1,
};

export default Component;

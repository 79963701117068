//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import styles    from './styles.module.scss';
import Icon      from '../Icon';
import IconType  from '../Icon/IconType';
import PropTypes from '../PropTypes';

const Component = ({
    checked        = false,
    noMarginBottom = false,
    text           = '',
    testId         = '',
    valueChanged   = _.noop,
}) => {
    const renderLabel    = () => {
        if (text) {
            return <div className={styles.checkBoxWrapperLabel}>{text}</div>;
        }

        return null;
    };
    const renderCheckBox = () => {
        return (
            <div
                className={classNames(styles.checkBox, checked && styles.checkBoxChecked)}
            >
                {checked
                    ? <Icon iconType={IconType.check} />
                    : null}
            </div>
        );
    };

    return (
        <div className={classNames(styles.checkBoxWrapper, noMarginBottom && styles.checkBoxWrapperNoMarginBottom)}>
            <div
                data-testId={testId}
                className={styles.checkBoxWrapperClickArea}
                onClick={valueChanged}
            >
                {renderCheckBox()}
                {renderLabel()}
            </div>
        </div>
    );
};

Component.propTypes = {
    checked:        PropTypes.bool,
    noMarginBottom: PropTypes.bool,
    testId:         PropTypes.string,
    text:           PropTypes.string,
    valueChanged:   PropTypes.func,
};

export default Component;

import React from 'react';

import classNames from 'classnames';
import I18n from 'i18next';
import { withTranslation } from 'react-i18next';

import YesNoOptional from '@constants/YesNoOptional';
import SelectionHelper from '@helper/SelectionHelper';

import RedundantLabelAlignment from './RedundantLabelAlignment';
import styles from './styles.module.scss';
import PropTypes from '../PropTypes';

const Component = ({
    alignment,
    isRedundant,
}) => {
    const getValue = () => {
        if (isRedundant === YesNoOptional.yes) {
            return I18n.t('redundantYes');
        }

        if (isRedundant === YesNoOptional.no) {
            return I18n.t('redundantNo');
        }

        if (isRedundant === YesNoOptional.optional) {
            return I18n.t('redundantOptional');
        }

        return '-';
    };

    return (
        <span
            className={classNames(
                styles.redundantLabel,
                SelectionHelper.get(
                    alignment,
                    {
                        [RedundantLabelAlignment.left]: styles.redundantLabelLeft,
                        [RedundantLabelAlignment.right]: styles.redundantLabelRight,
                    },
                ),
            )}
        >
            <span className={styles.redundantText}>
                {getValue()}
            </span>
        </span>
    );
};

Component.propTypes = {
    alignment: PropTypes.oneOf(Object.values(RedundantLabelAlignment)),
    isRedundant: PropTypes.string,
};

Component.defaultProps = {
    alignment: null,
    isRedundant: YesNoOptional.no,
};

export default withTranslation()(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// TODO: https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-596
import _ from 'lodash';

/**
 * @see This class also exists in the backend src/BoundsCalculators/GapCalculator.php
 */
class GapCalculator {
    static getGap = (gaps, index) => {
        let gap = 0;

        // Only add an gap if we are after the first item since the start position contains no gap
        if (index > 0) {
            let lastGap    = 0;
            const gapIndex = index - 1;

            for (let index = 0; index < gapIndex; ++index) {
                if (!_.isUndefined(gaps[index])) {
                    // We always use the last known gap for upcoming rows and columns even when the chassis
                    // has more rows and columns that defined in the gap definition
                    lastGap = gaps[index];
                }

                gap += lastGap;
            }
        }

        return gap;
    };
}

export default GapCalculator;

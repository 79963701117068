//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { connect }                                      from 'react-redux';
import { NavLink as ReactRouterDomNavLink, withRouter } from 'react-router-dom';
import { bindActionCreators }                           from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import NavigationHelper         from '@helper/Navigation';

const Component = ({
    children,
    testId,
    location,
    to,
    closeProject,
    activeClassName,
    className,
    params,
}) => {
    function linkClicked(event) {
        const nextUrlAllowed = NavigationHelper.checkForLeavePrompt(
            // TODO: location is not defined
            location?.pathname,
            to,
            closeProject,
        );

        if (!nextUrlAllowed) {
            event.preventDefault();
        }
    }

    return (
        <ReactRouterDomNavLink
            data-testId={testId}
            onClick={linkClicked}
            activeClassName={activeClassName}
            className={className}
            to={to}
            params={params}
        >
            {children}
        </ReactRouterDomNavLink>
    );
};

const mapStateToProps = (state) => (
    {
        pathname: state.router?.location?.pathname,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component));

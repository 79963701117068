//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { Offline }            from 'react-detect-offline';
import { Online }             from 'react-detect-offline';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { SettingActions } from '@slices/settings';
import Overlays           from '@constants/Overlays';
import Connection         from '@helper/Connection';
import Environment        from '@helper/Environment';
import FormPoster         from '@helper/FormPoster';
import PrintPreview       from '@helper/PrintPreview';

import styles                    from './styles.module.scss';
import ColorButton               from '../ColorButton';
import ColorButtonTheme          from '../ColorButton/ColorButtonTheme';
import ErrorBox                  from '../ErrorBox';
import GroupSimilarProductSwitch from '../GroupSimilarProductSwitch';
import IconType                  from '../Icon/IconType';
import Overlay                   from '../Overlay';
import BackType                  from '../Overlay/BackType';
import OverlayType               from '../Overlay/OverlayType';
import OverlayIFrame             from '../OverlayIFrame';
import { ActiveProjectActions }  from '@slices/activeProject';

const iFrameName = 'printPreview';

class Component extends React.Component {
    pollingOptions = {
        url: '/',
    };

    constructor(props) {
        super(props);

        this.setState({
            disabled: false,
        });
    }

    componentDidMount() {
        requestAnimationFrame(() => {
            this.openPrintPreviewUrl(this.props);
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log(
            'PrintPreviewOverlay: componentWillReceiveProps: this.props, nextProps',
            this.props,
            nextProps,
        );

        const projectHasChanged              = nextProps.activeProject !== this.props.activeProject;
        const groupSimilarProductsHasChanged = nextProps.previewGroupSimilarProducts !== this.props.previewGroupSimilarProducts;

        if (projectHasChanged || groupSimilarProductsHasChanged) {
            this.openPrintPreviewUrl(nextProps);
        }
    }

    openPrintPreviewUrl = (props) => {
        if (Connection.isOnline()) {
            const form = PrintPreview.getPrintPreviewForm(
                'html',
                props.activeProject,
                iFrameName,
                this.props.language,
                this.props.cardOverlayMode,
                props.previewGroupSimilarProducts,
            );

            FormPoster.submitAndClean(form);
        }
    };

    openPrintPreviewNewWindow = () => {
        const formName = `${iFrameName}Windowed`;
        const form     = PrintPreview.getPrintPreviewForm(
            'html',
            this.props.activeProject,
            formName,
            this.props.language,
            this.props.cardOverlayMode,
            this.props.previewGroupSimilarProducts,
        );

        window.open('', formName);

        FormPoster.submitAndClean(form);

        // We don't have to close the window here since this is a debug window that
        // should be kept open
    };

    printButtonPressed = () => {
        this.props.downloadPdf();
    };

    previewGroupSimilarProductsChanged = (checked) => {
        this.props.setPreviewGroupSimilarProducts({
            previewGroupSimilarProducts: checked,
        });
    };

    render() {
        return (
            <Overlay
                id={Overlays.printPreview}
                overlayType={OverlayType.dialog}
                okButtonTextKey={'close'}
                backType={BackType.forceCloseOverlay}
                closeButtonPressed={this.closeButtonPressed}
                renderAdditionalFooterButtons={this.renderAdditionalFooterButtons}
                title={I18n.t('printPreview')}
            >

                <Online polling={this.pollingOptions}>
                    <div className={styles.printPreviewOverlayFrameWrapper}>
                        <OverlayIFrame
                            name={iFrameName}
                            url={null}
                        />
                    </div>
                </Online>
                <Offline>
                    <ErrorBox
                        text={I18n.t('printError1')}
                    />
                </Offline>
            </Overlay>
        );
    }

    getPrintButtonText = () => {
        return (
            this.props.downloadInProgress
                ? I18n.t('downloadInProgress')
                : I18n.t('printDialogButton')
        );
    };

    renderAdditionalFooterButtons = () => {
        return [
            <Online polling={this.pollingOptions}>
                <ColorButton
                    icon={IconType.print}
                    key={'printPdf'}
                    disabled={this.props.downloadInProgress}
                    onClick={this.printButtonPressed}
                    text={this.getPrintButtonText()}
                    theme={ColorButtonTheme.orange}
                />
            </Online>,
            this.renderDebugButtons(),
            this.renderGroupSimilarProductsSwitch(),
        ];
    };

    renderGroupSimilarProductsSwitch = () => {
        return (
            <GroupSimilarProductSwitch
                onSwitch={this.previewGroupSimilarProductsChanged}
                checked={this.props.previewGroupSimilarProducts}
            />
        );
    };

    renderDebugButtons = () => {
        if (Environment.isDevelopment()) {
            return (
                <ColorButton
                    icon={IconType.print}
                    key={'printHtml'}
                    onClick={this.openPrintPreviewNewWindow}
                    text={I18n.t('printPreviewHTML')}
                    theme={ColorButtonTheme.orange}
                />
            );
        }

        return null;
    };
}

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        activeProject:               state.activeProject,
        cardOverlayMode:             state.settings.cardOverlayMode,
        language:                    state.settings.language,
        previewGroupSimilarProducts: state.settings.previewGroupSimilarProducts,
        downloadInProgress:          state.activeProject.downloadInProgress,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(
    ActiveProjectActions,
    SettingActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames             from 'classnames';
import _                      from 'lodash';
import { DragSource }         from 'react-dnd';
import { DropTarget }         from 'react-dnd';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { compose }            from 'redux';
import { bindActionCreators } from 'redux';

import { DragDropActions }       from '@slices/dragDrop';
import DragTypes                 from '@constants/DragTypes';
import ProductCategoryType       from '@constants/ProductCategoryType';
import { ActiveProjectActions }  from '@slices/activeProject';
import { DesignerLayoutActions } from '@slices/designerLayout';

import styles                       from './styles.module.scss';
import dragDropConfiguration        from '../../dragDrop/productSort';
import Icon                         from '../Icon';
import IconType                     from '../Icon/IconType';
import ProductInformationDrawer     from '../ProductInformationDrawer';
import ProductInformationDrawerMode from '../ProductInformationDrawer/ProductInformationDrawerMode';
import ProductTitle                 from '../ProductTitle';
import PropTypes                    from '../PropTypes';

class Component extends React.Component {
    arrowRightClicked = (event) => {
        this.selectItem(true);

        event.preventDefault();
        event.stopPropagation();
    };

    itemClicked = () => {
        this.selectItem(false);
    };

    itemDoubleClicked = () => {
        this.selectItem(true);
    };

    productIsLastSelected = () => {
        const productIsLastSelected = (
            this.props.lastSelectedProduct &&
            this.props.lastSelectedProduct.categoryType === this.props.productCategoryType &&
            this.props.lastSelectedProduct.index === this.props.index
        );

        return productIsLastSelected;
    };

    render() {
        const { customName, productData } = this.props.product || {};

        return this.props.connectDropTarget(this.props.connectDragSource(<div
            className={classNames(
                styles.leftPaneProductItem,
                this.props.className,
                (
                    this.props.index % 2 === 0 ?
                        styles.leftPaneProductItemOdd :
                        styles.leftPaneProductItemEven
                ),
                (
                    this.props.isDragging ?
                        styles.leftPaneProductItemDragging :
                        null
                ),
                (
                    this.props.draggingActionActive ?
                        styles.leftPaneProductItemNoHover :
                        null
                ),
                (
                    this.productIsLastSelected() ?
                        styles.leftPaneProductLastSelected :
                        null
                ),
            )}
            onClick={this.itemClicked}
            onDoubleClick={this.itemDoubleClicked}
            ref={this.setReference}
        >
            <ProductTitle
                customName={customName}
                productData={productData}
            />
            {this.renderInformation()}
            <span
                className={styles.leftPaneProductItemCallToAction}
                onClick={this.arrowRightClicked}
            >
                <Icon iconType={IconType.chevronRight} />
            </span>
        </div>));
    }

    renderInformation = () => {
        return (
            <ProductInformationDrawer
                mode={ProductInformationDrawerMode.light}
                product={this.props.product}
                wrapperClassName={classNames(
                    styles.leftPaneProductItemInformationWrapper,
                    (
                        this.props.product && this.props.product.customName ?
                            styles.leftPaneProductItemInformationWrapperWithCustomName :
                            null
                    ),
                )}
            />
        );
    };

    selectItem = (openDetails) => {
        // Check whether the selected item was selected again so we
        // can open the detail page for this single click
        if (
            !openDetails &&
            this.props.selectedProductMeta.categoryType === this.props.productCategoryType &&
            this.props.selectedProductMeta.index === this.props.index
        ) {
            openDetails = true;
        }

        this.props.selectProduct({
            categoryType: this.props.productCategoryType,
            index:        this.props.index,
            openDetails,
        });

        if (openDetails) {
            this.props.setRightPaneSetVisibility({
                visible: true,
            });
        }
    };

    setReference = (reference) => {
        dragDropConfiguration.setDragDropItemReference(reference);
    };
}

Component.propTypes = {
    index:                     PropTypes.number,
    product:                   PropTypes.object,
    productCategoryType:       PropTypes.oneOf(Object.values(ProductCategoryType)),
    selectProduct:             PropTypes.func,
    setRightPaneSetVisibility: PropTypes.func,
};

Component.defaultProps = {
    index:               -1,
    product:             {},
    productCategoryType: null,
};

const mapStateToProps = (state) => (
    {
        draggingActionActive: state.dragDrop.isDragging,
        lastSelectedProduct:  state.activeProject.lastSelectedProduct,
        selectedProductMeta:  state.activeProject.selectedProduct,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(
    ActiveProjectActions,
    DesignerLayoutActions,
    DragDropActions,
), dispatch);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    DropTarget(
        DragTypes.sort,
        dragDropConfiguration.dropBehavior,
        dragDropConfiguration.dropCollect,
    ),
    DragSource(
        DragTypes.sort,
        dragDropConfiguration.dragBehavior,
        dragDropConfiguration.dragCollect,
    ),
)(withTranslation()(Component));

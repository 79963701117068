//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n   from 'i18next';
import moment from 'moment';
import preval from 'preval.macro';

import { getVersion } from '@helper/Configuration';

import styles from './styles.module.scss';

const buildTimestamp = preval`module.exports = new Date().getTime();`;

const Component = () => {
    const getDateString = () => {
        const lastUpdateMoment = moment.unix(buildTimestamp / 1000);
        const formattedDate    = lastUpdateMoment.format(I18n.t('versionNumberDateFormat'));

        return formattedDate;
    };

    return (
        <div className={styles.versionNumberWrapper}>
            <span className={styles.versionNumber}>
                {getVersion()}
                {'.'}
                {buildTimestamp}
            </span>
            {' '}
            {'('}
            {getDateString()}
            {')'}
        </div>
    );
};

Component.propTypes = {};

Component.defaultProps = {};

export default Component;

import React from 'react';

import { withTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import Tip from '../Tip';
import TipStyle from '../Tip/TipStyle';

const Component = ({ t }) => {
    return (
        <div className={styles.rightEmptyPane}>
            <Tip
                style={TipStyle.rightPane}
                text={t('rightPaneEmptyText')}
                title={t('rightPaneEmptyTitle')}
            />
        </div>
    );
};

Component.propTypes = {};

Component.defaultProps = {};

export default withTranslation()(Component);

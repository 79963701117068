//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { Provider } from 'react-redux';

import { ProjectsActions } from '@slices/projects';
import createStore from '@store/index';

import PropTypes from '../PropTypes';

const useUnload = (callback) => {
    const reference = React.useRef(callback);

    React.useEffect(() => {
        const onUnload = reference.current;

        window.addEventListener('beforeunload', onUnload);

        return () => {
            window.removeEventListener('beforeunload', onUnload);
        };
    }, [reference]);
};

const Component = ({ children }) => {
    const { store } = createStore();

    useUnload(() => {
        console.log('Unload:', 'App is unloading. Current project will be saved');

        const state         = store.getState();
        const activeProject = state.activeProject;

        store.dispatch(ProjectsActions.saveProject({
            projectData: activeProject,
        }));

        return null;
    });

    return (
        <Provider store={store}>
            {children}
        </Provider>
    );
};

Component.propTypes = {
    children: PropTypes.children,
};

Component.defaultProps = {
    children: [],
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';
import _    from 'lodash';

import WarningType           from '@constants/WarningType';
import ActiveProjectsFactory from '@store/factories/activeProjects';

import Cast from './Cast';

class ProductWarningHelper {
    static getWarnings(product) {
        const warnings                               = [...product.warnings];
        const { subProducts, fanAddedAutomatically } = product;
        const subProductKeys                         = Object.keys(subProducts);

        for (const subProductCategoryKey of subProductKeys) {
            const subProductsInCategory = subProducts[subProductCategoryKey];

            for (const subProductIndex in subProductsInCategory) {
                if (!subProductsInCategory.hasOwnProperty(subProductIndex)) {
                    continue;
                }

                const subProductIndexInt = Cast.int(subProductIndex);
                const subProduct         = subProductsInCategory[subProductIndex];

                if (subProduct.warnings && subProduct.warnings.length > 0) {
                    for (const subProductWarning of subProduct.warnings) {
                        const slotText = [
                            I18n.t('slot'),
                            ' ',
                            (
                                subProduct.productData.heightUnits > 1 ?
                                    `${subProductIndexInt}+` :
                                    ''
                            ),
                            (
                                subProductIndexInt + 1
                            ),
                            ': ',
                            subProductWarning.text,
                        ].join('');

                        warnings.push(ActiveProjectsFactory.getWarning(
                            subProductWarning.type,
                            slotText,
                        ));
                    }
                }
            }
        }

        if (fanAddedAutomatically) {
            warnings.push(ActiveProjectsFactory.getWarning(
                WarningType.information,
                I18n.t('fanAddedAutomaticallyHint'),
            ));
        }

        return warnings;
    }

    static getWarningText(product) {
        const warnings = this.getWarnings(product);
        const text     = _.map(warnings, (warning) => {
            return warning.text;
        }).join('<br/><br/>');

        return text;
    }

    static containsErrorsOrWarnings(product) {
        const warnings = this.getWarnings(product);

        return warnings.length;
    }

    static containsErrors(product) {
        const warnings     = this.getWarnings(product);
        let containsErrors = false;

        for (let warningIndex = 0; warningIndex < warnings.length; warningIndex++) {
            const warning = warnings[warningIndex];

            if (warning.type === WarningType.error) {
                containsErrors = true;

                break;
            }
        }

        return containsErrors;
    }
}

export default ProductWarningHelper;

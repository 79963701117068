//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import styles    from './styles.module.scss';
import Link      from '../Link';
import PropTypes from '../PropTypes';

const Component = ({
    onClick,
    route,
    routeParameters,
    text,
}) => {
    const [dropDownVisible, setDropDownVisible] = React.useState(false);
    const renderButtonWrapper                   = () => {
        if (route) {
            return (
                <Link
                    to={route}
                    params={routeParameters}
                >
                    {renderButton()}
                </Link>
            );
        }

        return (
            <div
                className={styles.dropDownButtonClickWrapper}
                onClick={onClick}
            >
                {renderButton()}
            </div>
        );
    };
    const renderButton                          = () => {
        return (
            <div className={styles.dropDownButtonInner}>
                {text}
            </div>
        );
    };

    return (
        <div
            className={styles.dropDownButton}
        >
            {renderButtonWrapper()}
        </div>
    );
};

Component.propTypes = {
    onClick:         PropTypes.func,
    route:           PropTypes.string,
    routeParameters: PropTypes.object,
    text:            PropTypes.string,
};

Component.defaultProps = {
    onClick:         _.noop,
    route:           '',
    routeParameters: {},
    text:            '',
};

export default Component;

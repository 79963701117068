//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import LimitationHelper      from '@helper/Limitations';
import WarningType           from '@constants/WarningType';
import I18n                  from 'i18next';
import ActiveProjectsFactory from '@store/factories/activeProjects';

class LimitationRequiresModuleInChassisCalculator {
    static updateProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        productWarnings,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        return false;
    };

    static updateSubProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        subProduct,
        subProductIndex,
        productWarnings,
        subProductWarnings,
        subProductCategory,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        const requiredPartNo   = limitation.partNo;
        const requiredPartSlot = limitation.value;

        if (!LimitationHelper.productHasModule(product, requiredPartNo)) {
            subProductWarnings.push(ActiveProjectsFactory.getWarning(
                WarningType.error,
                I18n.t(
                    'limitationRequiresModuleInChassis',
                    {
                        partNo: requiredPartNo,
                        slot:   requiredPartSlot,
                    },
                ),
            ));

            return true;
        }

        return false;
    };
}

export default LimitationRequiresModuleInChassisCalculator;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push } from 'connected-react-router';
import { put }  from 'redux-saga/effects';

import { getPathForOverlayKey } from '@components/OverlayManager';
import Overlays                 from '@constants/Overlays';

const importToActiveProject = function* () {
    const route = getPathForOverlayKey(Overlays.importSuccessActiveProject);

    yield put(push(route));
};

const importToNewProject = function* () {
    const route = getPathForOverlayKey(Overlays.importSuccessNewProject);

    yield put(push(route));
};

export default {
    importToActiveProject,
    importToNewProject,
};

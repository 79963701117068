//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                from 'i18next';
import _                   from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect }         from 'react-redux';

import styles               from './styles.module.scss';
import ProjectListEmptyItem from '../ProjectListEmptyItem';
import ProjectListItem      from '../ProjectListItem';
import Tooltip              from '../Tooltip';
import TooltipPosition      from '../Tooltip/TooltipPosition';

const Component = ({
    projects = [],
}) => {
    const renderProjects = () => {
        const allProjects = Object.values(projects);

        if (allProjects.length > 0) {
            const sortedProjects = _.sortBy(
                allProjects,
                [
                    function getLastUpdateDate(project) {
                        return new Date(project.lastUpdateDate);
                    },
                ],
            ).reverse();
            const projectsItems  = [];

            for (const project of sortedProjects) {
                const { id } = project;

                // Hide "weird" projects that have been saved accidentally
                // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-776
                if (id) {
                    projectsItems.push(<ProjectListItem
                        key={id}
                        project={project}
                    />);
                }
            }

            return projectsItems;
        }

        return (
            <ProjectListEmptyItem />
        );
    };

    return (
        <div className={styles.projectList}>
            <h3 className={styles.projectListTitle}>
                {I18n.t('yourProjects')}
                <div className={styles.projectListTooltipWrapper}>
                    <span className={styles.projectListTooltipWrapperText}>
                        {I18n.t('projectListSaveTip')}
                    </span>
                    <Tooltip
                        position={TooltipPosition.left}
                        text={I18n.t('projectListSaveTipText')}
                    />
                </div>
            </h3>
            <ul className={styles.projectListInner}>
                {renderProjects()}
            </ul>
        </div>
    );
};

const mapStateToProps = (state) => (
    {
        projects: state.projects.projects,
    }
);

export default connect(
    mapStateToProps,
    null,
)(withTranslation()(Component));

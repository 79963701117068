//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames             from 'classnames';
import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import panAndZoomHoc          from 'react-pan-and-zoom-hoc';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import ProductCategoryType      from '@constants/ProductCategoryType';
import Routes                   from '@constants/Routes';

import styles        from './styles.module.scss';
import Icon          from '../Icon';
import IconType      from '../Icon/IconType';
import PropTypes     from '../PropTypes';
import SmartDropZone from '../SmartDropZone';

const InteractiveDiv = panAndZoomHoc('div');

const scaleBounds = {
    max: 3.0,
    min: 0.5,
};

const initialState = {
    x:     0.5,
    y:     0.5,
    scale: 0.8,
};

class Component extends React.Component {
    state = {
        ...initialState,
    };

    viewReference = null;

    changeScaleBy = (scaleChange) => {
        const nextScale = this.getScaleWithBoundaries(this.state.scale + scaleChange);

        this.setState({
            scale: nextScale,
        });
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (
            nextProps.selectedProduct !== this.props.selectedProduct ||
            nextProps.selectedSingleOrderSlot !== this.props.selectedSingleOrderSlot
        ) {
            this.setState(initialState);
        }
    }

    componentWillUnmount() {
        if (this.viewReference) {
            this.viewReference.removeEventListener('dblclick', this.doubleClick, false);
            this.viewReference.removeEventListener('touchmove', this.stopEventPropagation, false);
        }
    }

    detailButtonPressed = () => {
        this.props.selectProduct({
            index:        this.props.lastSelectedProduct.index,
            categoryType: this.props.lastSelectedProduct.categoryType,
            openDetails:  true,
        });
    };

    doubleClick = (event) => {
        if (event.shiftKey) {
            this.zoomOut();
        } else {
            this.zoomIn();
        }
    };

    getScaleWithBoundaries = (scale) => {
        return Math.min(scaleBounds.max, Math.max(scaleBounds.min, scale));
    };

    getZoomLevel = () => {
        return Math.round(this.state.scale * 100);
    };

    handlePanAndZoom = (x, y, scale, event) => {
        const newScale = this.getScaleWithBoundaries(scale);

        if (this.state.scale !== newScale) {
            console.log('handlePanAndZoom', x, y, scale);

            this.setState({
                x,
                y,
                scale: newScale,
            });
        }

        event.preventDefault();
        event.stopPropagation();
    };

    handlePanMove = (x, y, event) => {
        this.setState({
            x,
            y,
        });

        event.preventDefault();
        event.stopPropagation();
    };

    preventDefault = (event) => {
        event.preventDefault();
    };

    render() {
        const { x, y, scale } = this.state;
        const transformedAxis = this.transformPoint({
            x: 0.5,
            y: 0.5,
        });

        return (
            <div
                className={classNames(
                    styles.zoomAndPanControlWrapper,
                    {
                        [styles.noStatusBar]: this.props.activeProductCategoryType === ProductCategoryType.fullIp,
                    },
                )}
                ref={this.setViewReference}
            >
                {this.renderHints()}
                {this.renderZoomControls()}
                {this.renderDetailButton()}
                <InteractiveDiv
                    className={styles.zoomAndPanControl}
                    disableZoomToMouse={true}
                    minScale={scaleBounds.min}
                    maxScale={scaleBounds.max}
                    onPanAndZoom={this.handlePanAndZoom}
                    onPanMove={this.handlePanMove}
                    renderOnChange={false}
                    scale={scale}
                    scaleFactor={Math.sqrt(1.04)}
                    x={x}
                    y={y}
                >
                    <SmartDropZone />
                    {this.props.renderChildren(
                        transformedAxis.x,
                        transformedAxis.y,
                        scale,
                    )}
                </InteractiveDiv>
            </div>
        );
    }

    renderDetailButton = () => {
        if (this.props.pathname.endsWith(Routes.designer)) {
            return (
                <div
                    className={styles.zoomAndPanControlDetailButton}
                >
                    <span
                        className={classNames(
                            styles.zoomAndPanControlButton,
                            styles.zoomAndPanControlButtonDetails,
                        )}
                        onClick={this.detailButtonPressed}
                        title={I18n.t('showDetails')}
                    >
                        <Icon iconType={IconType.slots} />
                    </span>
                </div>
            );
        }

        return null;
    };

    renderHints = () => {
        const { activeProductCategoryType } = this.props;

        if (activeProductCategoryType === ProductCategoryType.fullIp) {
            return null;
        }

        return (
            <span className={styles.zoomAndPanControlHint}>
                {I18n.t(this.props.pathname === Routes.designer ?
                    'zoomControlDragDropHintPreview' :
                    'zoomControlDragDropHintDetails')}
            </span>
        );
    };

    renderZoomControls = () => {
        if (!this.props.draggingActionActive) {
            return (
                <div className={styles.zoomAndPanControlButtons}>
                    {this.renderZoomLevel()}
                    <span
                        className={classNames(
                            styles.zoomAndPanControlButton,
                            styles.zoomAndPanControlButtonZoomOut,
                        )}
                        onClick={this.zoomOutButtonPressed}
                    >
                        <Icon iconType={IconType.zoomOut} />
                    </span>
                    <span
                        className={classNames(
                            styles.zoomAndPanControlButton,
                            styles.zoomAndPanControlButtonZoomIn,
                        )}
                        onClick={this.zoomInButtonPressed}
                    >
                        <Icon iconType={IconType.zoomIn} />
                    </span>
                </div>
            );
        }

        return null;
    };

    renderZoomLevel = () => {
        const zoomLevel = this.getZoomLevel();

        if (zoomLevel !== 100) {
            return (
                <span className={styles.zoomAndPanControlZoomLevel}>
                    {zoomLevel}%
                </span>
            );
        }

        return null;
    };

    setViewReference = (reference) => {
        this.viewReference = reference;

        if (reference) {
            reference.addEventListener('dblclick', this.doubleClick);
            reference.addEventListener('touchmove', this.preventDefault, {
                passive: false,
            });
        }
    };

    transformPoint({ x, y }) {
        return {
            x: 0.5 + this.state.scale * (
                x - this.state.x
            ),
            y: 0.5 + this.state.scale * (
                y - this.state.y
            ),
        };
    }

    zoomIn = () => {
        this.changeScaleBy(0.2);
    };

    zoomInButtonPressed = () => {
        this.zoomIn();
    };

    zoomOut = () => {
        this.changeScaleBy(-0.2);
    };

    zoomOutButtonPressed = () => {
        this.zoomOut();
    };
}

Component.propTypes = {
    activeProductCategoryType: PropTypes.string,
    renderChildren:            PropTypes.func,
};

Component.defaultProps = {
    activeProductCategoryType: null,
    renderChildren:            _.noop,
};

const mapStateToProps = (state) => (
    {
        activeProductCategoryType: state.activeProject.activeProductCategoryType,
        draggingActionActive:      state.dragDrop.isDragging,
        pathname:                  state.router.location.pathname,
        lastSelectedProduct:       state.activeProject.lastSelectedProduct,
        selectedProduct:           state.activeProject.selectedProduct,
        selectedSingleOrderSlot:   state.activeProject.selectedSingleOrderSlot,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

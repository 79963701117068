//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as JSZip from 'jszip';
import _          from 'lodash';

import CsvImporter     from './csv';
import DsdjsonImporter from './dsdjson';
import Ec0Importer     from './ec0';

/**
 * This is a generic importer that decides internally of the zip contains
 * ec0 or dsdjson content.
 *
 * @see DsdjsonImporter
 * @see Ec0Importer
 */
class ZipImporter {
    static importFile(file, errorCallback, parsedContentCallback, updatedContentCallback = _.noop) {
        const zip = new JSZip();

        return zip.loadAsync(file).then((zip) => {
            if (zip && zip.files) {
                if (DsdjsonImporter.canImport(zip)) {
                    DsdjsonImporter.importFile(file, errorCallback, parsedContentCallback);
                } else if (Ec0Importer.canImport(zip)) {
                    Ec0Importer.importFile(file, errorCallback, parsedContentCallback);
                } else if (CsvImporter.canImport(zip)) {
                    CsvImporter.importFile(file, errorCallback, updatedContentCallback);
                }
            } else {
                errorCallback();
            }
        }, errorCallback);
    }
}

export default ZipImporter;

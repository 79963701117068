//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                from 'i18next';
import { withTranslation } from 'react-i18next';
import { connect }         from 'react-redux';

import Overlays   from '@constants/Overlays';
import FormPoster from '@helper/FormPoster';
import PartList   from '@helper/PartList';
import Product    from '@helper/Product';

import ColorButton      from '../ColorButton';
import ColorButtonTheme from '../ColorButton/ColorButtonTheme';
import IconType         from '../Icon/IconType';
import Overlay          from '../Overlay';
import OverlayType      from '../Overlay/OverlayType';
import Table            from '../Table';
import DefaultColumns   from '../Table/DefaultColumns';

const iFrameName = 'partsList';

class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: this.recalculateData(),
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log(
            'PartsListOverlay: componentWillReceiveProps: this.props, nextProps',
            this.props,
            nextProps,
        );

        if (
            nextProps.products !== this.props.products ||
            nextProps.singleOrders !== this.props.singleOrders
        ) {
            this.setState({
                data: this.recalculateData(nextProps),
            });
        }
    }

    exportCsvButtonPressed = () => {
        const form = PartList.getCsvExportForm(
            this.props.activeProject,
            iFrameName,
            this.props.language,
        );

        FormPoster.submitAndClean(form);
    };

    getColumnConfiguration = () => {
        const defaultColumns = DefaultColumns.get();

        return [
            defaultColumns.displayPartNumber,
            defaultColumns.amount,
            defaultColumns.description,
        ];
    };

    recalculateData = (props) => {
        if (!props) {
            props = this.props;
        }

        console.log(
            'PartsListOverlay: recalculateData: props.products, props.singleOrders',
            props.products,
            props.singleOrders,
        );

        const data       = {};
        const addProduct = function(partNumber, description_deFull, description_enFull, amount) {
            if (!amount) {
                amount = 1;
            }

            if (!data[partNumber]) {
                data[partNumber] = {
                    amount,
                    description_deFull,
                    description_enFull,
                    partNumber,
                    displayPartNumber: partNumber,
                };
            } else {
                data[partNumber].amount += amount;
            }
        };

        if (props.products) {
            const productKeys = Object.keys(props.products);

            for (const productKey of productKeys) {
                const products = props.products[productKey];

                for (const product of products) {
                    addProduct(
                        product.productData.id,
                        product.productData.description_deFull,
                        product.productData.description_enFull,
                    );

                    const subProductSlotKeys = Object.keys(product.subProducts);

                    for (const subProductSlotKey of subProductSlotKeys) {
                        const subProducts = product.subProducts[subProductSlotKey];

                        for (const subProductIndex in subProducts) {
                            const subProduct = subProducts[subProductIndex];

                            if (subProduct.productData) {
                                const isLocked = Product.isLockedInProduct(
                                    product.productData,
                                    subProductSlotKey,
                                    subProductIndex,
                                );

                                // Parts that are shipped with the chassis by default
                                // have to be hidden in the parts list
                                // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-681
                                if (!isLocked) {
                                    addProduct(
                                        subProduct.productData.id,
                                        subProduct.productData.description_deFull,
                                        subProduct.productData.description_enFull,
                                    );
                                }
                            }

                            if (subProduct.sfps) {
                                for (const sfp of subProduct.sfps) {
                                    if (sfp.productData) {
                                        addProduct(
                                            sfp.productData.id,
                                            sfp.productData.description_deFull,
                                            sfp.productData.description_enFull,
                                        );
                                    }
                                }
                            }

                            if (subProduct.subSlots) {
                                for (const subSlot of subProduct.subSlots) {
                                    if (subSlot.productData) {
                                        addProduct(
                                            subSlot.productData.id,
                                            subSlot.productData.description_deFull,
                                            subSlot.productData.description_enFull,
                                        );
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        if (props.singleOrders) {
            const singleOrderKeys = Object.keys(props.singleOrders);

            for (const singleOrderKey of singleOrderKeys) {
                const singleOrderProducts = props.singleOrders[singleOrderKey];

                for (const singleOrderProduct of singleOrderProducts) {
                    if (singleOrderProduct.productData) {
                        addProduct(
                            singleOrderProduct.productData.id,
                            singleOrderProduct.productData.description_deFull,
                            singleOrderProduct.productData.description_enFull,
                            singleOrderProduct.amount,
                        );
                    }
                }
            }
        }

        const values = Object.values(data);

        console.log(
            'PartsListOverlay: recalculateData: done',
            values,
        );

        return values;
    };

    render() {
        return (
            <Overlay
                closeButtonPressed={this.closeButtonPressed}
                id={Overlays.partsList}
                overlayType={OverlayType.table}
                renderAdditionalFooterButtons={this.renderAdditionalFooterButtons}
                okButtonTextKey={'closeOverlay'}
                title={I18n.t('partsList')}
            >
                <Table
                    applyLimitations={false}
                    columnConfiguration={this.getColumnConfiguration()}
                    dragDropEnabled={false}
                    rawData={this.state.data}
                />
            </Overlay>
        );
    }

    renderAdditionalFooterButtons = () => {
        return [
            <ColorButton
                icon={IconType.export}
                key={'partsListExportCsv'}
                onClick={this.exportCsvButtonPressed}
                text={I18n.t('partsListExportCsv')}
                theme={ColorButtonTheme.orange}
            />,
        ];
    };
}

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => (
    {
        activeProject: state.activeProject,
        products:      state.activeProject.products,
        singleOrders:  state.activeProject.singleOrders,
        language:      state.settings.language,
    }
);

export default connect(
    mapStateToProps,
    null,
)(withTranslation()(Component));

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// @see https://stackoverflow.com/questions/1818474/how-to-trigger-the-window-resize-event-in-javascript/18693617
class WindowEventHelper {
    static fakeResize() {
        // Trigger a resize event to make react-data-grid recalculate
        // its boundaries
        requestAnimationFrame(() => {
            const event = window.document.createEvent('UIEvents');

            event.initUIEvent(
                'resize',
                true,
                false,
                window,
                0,
            );

            window.dispatchEvent(event);
        });
    }
}

export default WindowEventHelper;

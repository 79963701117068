//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import Languages         from '@constants/Languages';
import Overlays          from '@constants/Overlays';
import DataProvider      from '@helper/DataProvider';
import { ExportActions } from '@slices/export';

import IconType                 from '../Icon/IconType';
import Overlay                  from '../Overlay';
import OverlayType              from '../Overlay/OverlayType';
import TextHeadline             from '../TextHeadline';
import TitleSubTitleButton      from '../TitleSubTitleButton';
import TitleSubTitleButtonTheme from '../TitleSubTitleButton/TitleSubTitleButtonTheme';

class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
        };
    }

    downloadFileButtonPressed = (category, file) => () => {
        const urlPrefix = DataProvider.getFileTypeUrl(category);
        const url       = urlPrefix + file;

        window.open(url);
    };

    render() {
        return (
            <Overlay
                id={Overlays.export}
                overlayType={OverlayType.dialog}
                title={I18n.t('downloads')}
            >
                <TextHeadline
                    text={I18n.t(
                        'downloadsSelectFile',
                        {
                            partNumber: this.props.partNumber,
                        },
                    )}
                />
                {this.renderFiles()}
            </Overlay>
        );
    }

    renderFiles = () => {
        const { language }    = this.props;
        const files           = [];
        const filesByCategory = _.get(this.props, 'productData.files', null);

        if (_.isObject(filesByCategory)) {
            for (const category in filesByCategory) {
                const filenames      = filesByCategory[category];
                let germanFileExists = false;

                for (const filename of filenames) {
                    if (filename.indexOf('_de') > -1) {
                        germanFileExists = true;

                        break;
                    }
                }

                for (const filename of filenames) {
                    const showFile = (
                        (
                            filename.indexOf('_de') > -1 &&
                            language === Languages.german
                        )
                        ||
                        (
                            (
                                filename.indexOf('_en') > -1 &&
                                language === Languages.english
                            )
                            ||
                            !germanFileExists
                        )
                    );

                    if (showFile) {
                        files.push(<TitleSubTitleButton
                            onClick={this.downloadFileButtonPressed(category, filename)}
                            iconType={IconType.file}
                            theme={TitleSubTitleButtonTheme.orange}
                            title={filename}
                            subTitle={category}
                        />);
                    }
                }
            }
        }

        if (files.length === 0) {
            return this.renderNoFilesText();
        }

        return files;
    };

    renderNoFilesText = () => {
        return (
            <TextHeadline
                text={I18n.t('downloadsNoFilesAvailable')}
            />
        );
    };
}

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => {
    const partNumber  = _.get(state, 'activeProject.downloadOverlayPartNumber');
    const productData = (
        partNumber ?
            DataProvider.getById(partNumber) :
            null
    );

    return {
        language: state.settings.language,
        partNumber,
        productData,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(ExportActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import Overlays                 from '@constants/Overlays';
import ProductCategoryType      from '@constants/ProductCategoryType';
import DataProvider             from '@helper/DataProvider';
import ProductTitleHelper       from '@helper/ProductTitle';
import SelectionHelper          from '@helper/SelectionHelper';
import StateHelper              from '@helper/State';

import Overlay      from '../Overlay';
import OverlayType  from '../Overlay/OverlayType';
import TextHeadline from '../TextHeadline';

const Component = ({
    duplicatedProduct,
    lastDuplicateAmount,
    selectedProduct,
    duplicatedProductMeta,
    selectProduct,
    t,
}) => {
    const closeButtonPressed = () => {
        // https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-238
    };
    const getText            = () => {
        if (duplicatedProduct) {
            const amount          = lastDuplicateAmount;
            const productData     = DataProvider.getById(selectedProduct.productData.id);
            const productName     = ProductTitleHelper.getProductTitle(
                productData,
                selectedProduct.customName,
            );
            const translationData = {
                name: productName,
            };
            let extenderText      = 'duplicatedExtenderText';
            let matrixText        = 'duplicatedMatrixSwitchText';
            let fullIpText        = 'duplicatedFullIpText';

            if (amount && amount > 1) {
                extenderText = 'duplicatedMultiplyExtenderText';
                matrixText   = 'duplicatedMultiplyMatrixSwitchText';
                fullIpText   = 'duplicatedMultiplyFullIpText';
            }

            switch (duplicatedProduct.categoryType) {
                case ProductCategoryType.extender:
                    return t(extenderText, translationData);

                case ProductCategoryType.matrix:
                    return t(matrixText, translationData);

                case ProductCategoryType.fullIp:
                    return t(fullIpText, translationData);

                default:
                    return t('unknownError');
            }
        }

        return t('unknownError');
    };
    const getTitle           = () => {
        return SelectionHelper.getTranslation(
            duplicatedProduct?.categoryType,
            {
                [ProductCategoryType.equipment]: 'duplicatedEquipmentTitle',
                [ProductCategoryType.extender]:  'duplicatedExtenderTitle',
                [ProductCategoryType.matrix]:    'duplicatedMatrixSwitchTitle',
                [ProductCategoryType.fullIp]:    'duplicatedFullIpTitle',
            },
            'unknownError',
        );
    };
    const okButtonPressed    = () => {
        // https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-238
        selectProduct({
            categoryType: duplicatedProductMeta.categoryType,
            index:        duplicatedProductMeta.index,
            openDetails:  true,
        });
    };

    return (
        <Overlay
            allowDontShowAgain={true}
            cancelButtonTextKey={'no'}
            closeButtonPressed={closeButtonPressed}
            id={Overlays.switchToDuplicatedProduct}
            okButtonPressed={okButtonPressed}
            okButtonTextKey={'yes'}
            overlayType={OverlayType.prompt}
            title={getTitle()}
        >
            <TextHeadline text={getText()} />
        </Overlay>
    );
};

const mapStateToProps = (state) => (
    {
        duplicatedProduct:     StateHelper.getSelectedProduct(
            state,
            state.activeProject.duplicatedProduct,
        ),
        duplicatedProductMeta: state.activeProject.duplicatedProduct,
        selectedProduct:       StateHelper.getSelectedProduct(
            state,
            state.activeProject.selectedProduct,
        ),
        lastDuplicateAmount:   state.activeProject.lastDuplicateAmount,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';
import { Link }   from 'react-router-dom';

import SelectionHelper from '@helper/SelectionHelper';

import ColorButtonTheme from './ColorButtonTheme';
import styles           from './styles.module.scss';
import Icon             from '../Icon';

const Component = ({
    icon    = '',
    onClick = _.noop,
    testId  = '',
    text    = '',
    theme   = null,
    disabled = false,
    route,
}) => {
    const getClassNames       = () => {
        const names = classNames(
            styles.colorButton,
            SelectionHelper.get(theme, {
                [ColorButtonTheme.orange]: styles.colorButtonOrange,
                [ColorButtonTheme.red]:    styles.colorButtonRed,
            }),
            {
                [styles.disabled]: disabled,
            },
        );

        return names;
    };
    const renderIcon          = () => {
        if (icon) {
            return (
                <span className={styles.colorButtonIconWrapper}>
                    <Icon iconType={icon} />
                </span>
            );
        }

        return null;
    };
    const renderButtonContent = () => {
        return (
            <span className={styles.colorButtonContent}>
                {renderIcon()}
                <span className={styles.colorButtonTextWrapper}>
                    {text}
                </span>
            </span>
        );
    };

    if (route) {
        return (
            <Link
                data-testId={testId}
                className={getClassNames()}
                to={route}
            >
                {renderButtonContent()}
            </Link>
        );
    }

    return (
        <span
            data-testId={testId}
            role={'button'}
            className={getClassNames()}
            onClick={onClick}
        >
            {renderButtonContent()}
        </span>
    );
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import _          from 'lodash';

import ProductDataKeys from '@constants/ProductDataKeys';
import TestIds         from '@constants/TestIds';
import Cast            from '@helper/Cast';

import SpecificationListItemMode      from './SpecificationListItemMode';
import styles                         from './styles.module.scss';
import AudioLabel                     from '../AudioLabel';
import AudioLabelAlignment            from '../AudioLabel/AudioLabelAlignment';
import DataTransferLabel              from '../DataTransferLabel';
import DataTransferLabelAlignment     from '../DataTransferLabel/DataTransferLabelAlignment';
import DeviceTypeLabel                from '../DeviceTypeLabel';
import DeviceTypeLabelAlignment       from '../DeviceTypeLabel/DeviceTypeLabelAlignment';
import Icon                           from '../Icon';
import IconType                       from '../Icon/IconType';
import MilliampsLabel                 from '../MilliampsLabel';
import MilliwattsLabel                from '../MilliwattsLabel';
import NumberOfMonitorsLabel          from '../NumberOfMonitorsLabel';
import NumberOfMonitorsLabelAlignment from '../NumberOfMonitorsLabel/NumberOfMonitorsLabelAlignment';
import ProductDataValueLabel          from '../ProductDataValueLabel';
import PropTypes                      from '../PropTypes';
import SerialLabel                    from '../SerialLabel';
import SerialLabelAlignment           from '../SerialLabel/SerialLabelAlignment';
import UsbLabel                       from '../UsbLabel';
import UsbLabelAlignment              from '../UsbLabel/UsbLabelAlignment';
import VideoConnectorLabel            from '../VideoConnectorLabel';

class Component extends React.Component {
    getTooltip = () => {
        if (this.props.tooltip) {
            return this.props.tooltip;
        }

        return this.props.title;
    };

    getValue = () => {
        if (_.isArray(this.props.value)) {
            return this.props.value;
        }

        return (
            this.props.value +
            (
                this.props.valuePostfix ?
                    ` ${this.props.valuePostfix}` :
                    ''
            )
        );
    };

    render() {
        const tooltip = this.getTooltip();

        return [
            <dt
                data-testId={TestIds.specificationListItemLeft}
                className={classNames(
                    styles.specificationListItemLeft,
                    {
                        [styles.specificationListItemLeftDefaultAlert]:
                        this.props.mode === SpecificationListItemMode.defaultAlert,
                    },
                    {
                        [styles.specificationListItemLeftLongValue]:
                        this.props.mode === SpecificationListItemMode.longValue,
                    },
                )}
                key={'dt'}
                title={tooltip}
            >
                {this.renderIcon()}
                {this.props.title}
                {':'}
            </dt>,
            <dd
                data-testId={TestIds.specificationListItemRight}
                className={classNames(
                    styles.specificationListItemRight,
                    {
                        [styles.specificationListItemRightDefaultAlert]:
                        this.props.mode === SpecificationListItemMode.defaultAlert,
                    },
                    {
                        [styles.specificationListItemRightLongValue]:
                        this.props.mode === SpecificationListItemMode.longValue,
                    },
                )}
                key={'dd'}
            >
                {
                    this.renderValue()
                }
            </dd>,
        ];
    }

    renderIcon = () => {
        if (this.props.mode === SpecificationListItemMode.defaultAlert) {
            return (
                <span className={styles.specificationListItemLeftIconWrapper}>
                    <Icon iconType={IconType.error} />
                </span>
            );
        }

        return null;
    };

    renderValue = () => {
        const value = this.getValue();

        switch (this.props.dataKey) {
            case ProductDataKeys.AUDIO:
                return (
                    <AudioLabel
                        alignment={AudioLabelAlignment.right}
                        audio={value}
                    />
                );

            case ProductDataKeys.SERIAL:
                return (
                    <SerialLabel
                        alignment={SerialLabelAlignment.right}
                        serial={value}
                    />
                );

            case ProductDataKeys.USB:
                return (
                    <UsbLabel
                        alignment={UsbLabelAlignment.right}
                        usb={this.props.value}
                    />
                );

            case ProductDataKeys.DATA_TRANSFER:
                return (
                    <DataTransferLabel
                        alignment={DataTransferLabelAlignment.right}
                        dataTransfer={value}
                    />
                );

            case ProductDataKeys.NUMBER_OF_MONITORS: {
                const numberOfMonitors = Cast.int(value);

                return (
                    <NumberOfMonitorsLabel
                        alignment={NumberOfMonitorsLabelAlignment.right}
                        numberOfMonitors={numberOfMonitors}
                    />
                );
            }

            case ProductDataKeys.CURRENT_POWER_CONSUMPTION_IN_MILLIAMPS:
            case ProductDataKeys.POWER_CONSUMPTION_IN_MILLIAMPS:
            case ProductDataKeys.REDUNDANCY_BOARD_POWER_CONSUMPTION_IN_MILLIAMPS:
            case ProductDataKeys.MAXIMUM_POWER_CONSUMPTION_IN_MILLIAMPS:
            case ProductDataKeys.RECOMMENDED_POWER_CONSUMPTION_IN_MILLIAMPS: {
                const milliamps = Cast.int(value);

                return (
                    <MilliampsLabel
                        milliamps={milliamps}
                        voltage={this.props.data[ProductDataKeys.SECONDARY_VOLTAGE_IN_VOLT]}
                    />
                );
            }

            case ProductDataKeys.FAN_POWER_CONSUMPTION_IN_MILLIWATTS:
            case ProductDataKeys.POWER_IN_MILLIWATTS: {
                const milliwatts = Cast.int(value);

                return (
                    <MilliwattsLabel milliwatts={milliwatts} />
                );
            }

            case ProductDataKeys.DEVICE_TYPE:
                return (
                    <DeviceTypeLabel
                        deviceType={value}
                        alignment={DeviceTypeLabelAlignment.right}
                    />
                );

            case ProductDataKeys.VIDEO_CONNECTOR:
                return (
                    <VideoConnectorLabel
                        videoConnector={value}
                        alignment={DeviceTypeLabelAlignment.right}
                    />
                );
        }

        return (
            <ProductDataValueLabel value={value} />
        );
    };
}

Component.propTypes = {
    data:         PropTypes.object,
    dataKey:      PropTypes.string,
    mode:         PropTypes.oneOf(Object.values(SpecificationListItemMode)),
    title:        PropTypes.string,
    tooltip:      PropTypes.string,
    value:        PropTypes.any,
    valuePostfix: PropTypes.string,
};

Component.defaultProps = {
    data:         {},
    dataKey:      '',
    mode:         SpecificationListItemMode.default,
    title:        '',
    tooltip:      '',
    value:        '',
    valuePostfix: '',
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames          from 'classnames';
import _                   from 'lodash';
import { withTranslation } from 'react-i18next';

import DataTransferTypes from '@constants/DataTransferTypes';
import SelectionHelper   from '@helper/SelectionHelper';

import DataTransferLabelAlignment from './DataTransferLabelAlignment';
import styles                     from './styles.module.scss';
import Icon                       from '../Icon';
import IconType                   from '../Icon/IconType';
import ProductDataValueLabel      from '../ProductDataValueLabel';
import PropTypes                  from '../PropTypes';

class Component extends React.Component {
    getIconType = (dataTransfer) => {
        return SelectionHelper.get(
            dataTransfer,
            {
                [DataTransferTypes.catx1g]:       IconType.dataTransferCatx1g,
                [DataTransferTypes.catx3g]:       IconType.dataTransferCatx3g,
                [DataTransferTypes.fiber10g]:     IconType.dataTransferFiber10g,
                [DataTransferTypes.fiber1g]:      IconType.dataTransferFiber1g,
                [DataTransferTypes.fiber3g]:      IconType.dataTransferFiber3g,
                [DataTransferTypes.fiber6g]:      IconType.dataTransferFiber6g,
                [DataTransferTypes.ipcatx1g]:     IconType.dataTransferIpcatx1g,
                [DataTransferTypes.ipfiber10gmm]: IconType.dataTransferIpfiber10gmm,
                [DataTransferTypes.ipfiber1g]:    IconType.dataTransferIpfiber1g,
                [DataTransferTypes.uni]:          IconType.dataTransferUni,
            },
        );
    };

    getTitle = (dataTransfer) => {
        return SelectionHelper.getTranslation(
            dataTransfer,
            {
                [DataTransferTypes.catx1g]:       'dataTransferCatx1g',
                [DataTransferTypes.catx3g]:       'dataTransferCatx3g',
                [DataTransferTypes.fiber10g]:     'dataTransferFiber10g',
                [DataTransferTypes.fiber1g]:      'dataTransferFiber1g',
                [DataTransferTypes.fiber3g]:      'dataTransferFiber3g',
                [DataTransferTypes.fiber6g]:      'dataTransferFiber6g',
                [DataTransferTypes.ipcatx1g]:     'dataTransferIpcatx1g',
                [DataTransferTypes.ipfiber10gmm]: 'dataTransferIpfiber10gmm',
                [DataTransferTypes.ipfiber1g]:    'dataTransferIpfiber1g',
                [DataTransferTypes.uni]:          'dataTransferUni',
            },
        );
    };

    render() {
        if (this.props.dataTransfer) {
            const labels = [];

            if (_.isArray(this.props.dataTransfer)) {
                for (const dataTransfer of this.props.dataTransfer) {
                    labels.push(this.renderItem(
                        this.props.dataTransfer.length,
                        dataTransfer,
                    ));
                }
            } else {
                labels.push(this.renderItem(1, this.props.dataTransfer));
            }

            if (labels.length > 0) {
                return (
                    <div
                        className={classNames(
                            styles.dataTransferLabelWrapper,
                            SelectionHelper.get(
                                this.props.alignment,
                                {
                                    [DataTransferLabelAlignment.left]:  styles.dataTransferLabelLeft,
                                    [DataTransferLabelAlignment.right]: styles.dataTransferLabelRight,
                                },
                            ),
                        )}
                    >
                        {labels}
                    </div>
                );
            }
        }

        return (
            <ProductDataValueLabel value={this.props.dataTransfer} />
        );
    }

    renderItem = (dataTransferCount, dataTransfer) => {
        const title = this.getTitle(dataTransfer);

        if (!title) {
            console.warn('Unsupported dataTransfer', dataTransfer);

            return (
                <ProductDataValueLabel value={dataTransfer} />
            );
        }

        return (
            <span
                className={classNames(
                    styles.dataTransferLabel,
                    SelectionHelper.get(
                        this.props.alignment,
                        {
                            [DataTransferLabelAlignment.left]:  styles.dataTransferLabelLeft,
                            [DataTransferLabelAlignment.right]: styles.dataTransferLabelRight,
                        },
                    ),
                )}
                key={dataTransfer}
                title={title}
            >
                {this.renderIcon(dataTransfer)}
                {
                    dataTransferCount <= 1
                        ? (
                            <span className={styles.dataTransferText}>
                                {title}
                            </span>
                        )
                        : null
                }
            </span>
        );
    };

    renderIcon = (dataTransfer) => {
        const iconType = this.getIconType(dataTransfer);

        if (iconType) {
            return (
                <span className={styles.dataTransferLabelIconWrapper}>
                    <Icon iconType={iconType} />
                </span>
            );
        }

        return null;
    };
}

Component.propTypes = {
    alignment:    PropTypes.oneOf(Object.values(DataTransferLabelAlignment)),
    dataTransfer: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
    ]),
};

Component.defaultProps = {
    alignment:    null,
    dataTransfer: null,
};

export default withTranslation()(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _                                          from 'lodash';
import { connect }                                from 'react-redux';
import { Link as ReactRouterDomLink, withRouter } from 'react-router-dom';
import { bindActionCreators }                     from 'redux';

import Overlays                 from '@constants/Overlays';
import Routes                   from '@constants/Routes';
import NavigationHelper         from '@helper/Navigation';
import { ActiveProjectActions } from '@slices/activeProject';

import PropTypes from '../PropTypes';

const getIsRouteLink   = (to) => {
    const routeValues = _.values(Routes);
    let isRouteLink   = _.isObject(to);

    if (_.isString(to)) {
        if (to.includes('?')) {
            to = to.split('?')[0];
        }

        isRouteLink = routeValues.includes(to);
    }

    return isRouteLink;
};

const getIsOverlayLink = (to) => {
    const overlayValues       = _.values(Overlays);
    const hasOverlayParameter = (
        _.isString(to) &&
        to.includes('overlay=')
    );

    if (!hasOverlayParameter) {
        return false;
    }

    const overlayName = to.split('overlay=')[1];

    return overlayValues.includes(overlayName);
};

const Component = ({
    children,
    className,
    closeProjectDialogHidden,
    closeProject,
    location,
    to,
    params,
}) => {
    const linkClicked   = (event) => {
        const currentLocation = location.pathname;
        const targetLocation  = to;

        if (!closeProjectDialogHidden) {
            const nextUrlAllowed = NavigationHelper.checkForLeavePrompt(
                currentLocation,
                targetLocation,
                closeProject,
            );

            if (!nextUrlAllowed) {
                event.preventDefault();
                event.stopPropagation();

                return false;
            }
        } else if (
            currentLocation.startsWith(Routes.designer) &&
            !targetLocation.startsWith(Routes.designer)
        ) {
            const actionPayload = {
                closeConfirmed:  true,
                routeAfterClose: targetLocation,
            };

            // Save the project and prevent the link click
            // The route is changed automatically to the link target using "routeAfterClose"
            closeProject(actionPayload);

            return false;
        }

        return true;
    };
    const isRouteLink   = getIsRouteLink(to);
    const isOverlayLink = getIsOverlayLink(to);

    if (
        isRouteLink ||
        isOverlayLink
    ) {
        return (
            <ReactRouterDomLink
                className={className}
                onClick={linkClicked}
                to={to}
                params={params}
            >
                {children}
            </ReactRouterDomLink>
        );
    }

    return (
        <a
            className={className}
            href={to}
            target="_blank"
            rel="noopener noreferrer"
        >
            {children}
        </a>
    );
};

Component.propTypes = {
    children: PropTypes.children,
};

Component.defaultProps = {
    children: [],
};

const mapStateToProps = (state) => (
    {
        closeProjectDialogHidden: state.overlays.hiddenOverlays[Overlays.closeProject],
        pathname:                 state.router.location.pathname,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component));

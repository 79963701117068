//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _                            from 'lodash';
import { withTranslation }          from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators }       from 'redux';

import { ProjectsActions } from '@slices/projects';
import Overlays            from '@constants/Overlays';
import ProjectTitleHelper  from '@helper/ProjectTitle';
import I18n                from '@language/i18n';

import Overlay      from '../Overlay';
import TextHeadline from '../TextHeadline';

const Component = () => {
    const dispatch                                      = useDispatch();
    const { deleteProjectCancel, deleteProjectConfirm } = bindActionCreators(ProjectsActions, dispatch);
    const projectDeletionContext                        = useSelector((state) => state.projects.projectDeletionContext);
    const project                                       = useSelector((state) => state.projects.projects[projectDeletionContext.id]);
    const closeButtonPressed                            = () => {
        deleteProjectCancel();
    };
    const deleteButtonPressed                           = () => {
        deleteProjectConfirm();
    };
    const getText                                       = () => {
        if (projectDeletionContext) {
            const projectTitle = ProjectTitleHelper.getProjectTitle(_.get(project, 'metaData.title'));

            return I18n.t('deleteProjectText', {
                title: projectTitle,
            });
        }
        return I18n.t('unknownError');
    };

    return (
        <Overlay
            closeButtonPressed={closeButtonPressed}
            id={Overlays.deleteProject}
            okButtonPressed={deleteButtonPressed}
            okButtonTextKey={'deleteProjectButtonText'}
            title={I18n.t('deleteProjectTitle')}
        >
            <TextHeadline text={getText()} />
        </Overlay>
    );
};

Component.defaultProps = {};

export default withTranslation()(Component);

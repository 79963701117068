import React from 'react';

import I18n from 'i18next';
import { withTranslation } from 'react-i18next';

import Environment from '@helper/Environment';

import styles from './styles.module.scss';
import Header from '../Header';
import HeaderMode from '../Header/HeaderMode';
import TitleBanner from '../TitleBanner';
import TitleBannerMode from '../TitleBanner/TitleBannerMode';

const Component = () => {
    if (!Environment.isStaging()) {
        return (
            <div
                aria-hidden={'true'}
                className={styles.screenToSmallOverlay}
            >
                <Header mode={HeaderMode.smallScreenOverlay} />
                <TitleBanner mode={TitleBannerMode.app} />
                <div className={styles.screenToSmallOverlayContent}>
                    <h2>{I18n.t('screenToSmallTitle')}</h2>
                    <p>{I18n.t('screenToSmallText')}</p>
                </div>
            </div>
        );
    }

    return null;
};

Component.propTypes = {};

Component.defaultProps = {};

export default withTranslation()(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                from 'i18next';
import { withTranslation } from 'react-i18next';

import Overlays from '@constants/Overlays';

import Overlay      from '../Overlay';
import BackType     from '../Overlay/BackType';
import OverlayType  from '../Overlay/OverlayType';
import TextHeadline from '../TextHeadline';

const Component = () => {
    return (
        <Overlay
            backType={BackType.forceCloseOverlay}
            cancelButtonTextKey={null}
            id={Overlays.noInternetConnection}
            overlayType={OverlayType.prompt}
            title={I18n.t('noInternetConnectionOverlayTitle')}
        >
            <TextHeadline text={I18n.t('noInternetConnectionOverlayText')} />
        </Overlay>
    );
};

Component.propTypes = {};

Component.defaultProps = {};

export default withTranslation()(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { TabList } from 'react-tabs';

import TestIds from '@constants/TestIds';

import styles    from './styles.module.scss';
import PropTypes from '../PropTypes';

const Component = ({
    children,
}) => (
    <TabList
        data-testId={TestIds.mainLevelTabList}
        className={styles.mainLevelTabList}
    >
        {children}
    </TabList>
);

Component.tabsRole = 'TabList';

Component.propTypes = {
    children: PropTypes.children,
};

Component.defaultProps = {
    children: [],
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import ProductCategoryType    from '@constants/ProductCategoryType';
import ProductSlotType        from '@constants/ProductSlotType';
import ProductSubCategoryType from '@constants/ProductSubCategoryType';

class PowerConsumptionCalculator {
    static getPowerConsumption = (product, subProducts, includeBasePowerConsumption = false) => {
        let powerConsumptionInMilliAmps           = 0;
        const productData                         = product.productData;
        const subProductKeys                      = Object.keys(subProducts);
        let fanPowerConsumptionInMilliwatts       = 0;
        let backplanePowerConsumptionInMilliwatts = 0;

        if (productData.fanPowerConsumptionInMilliwatts) {
            fanPowerConsumptionInMilliwatts = parseInt(
                productData.fanPowerConsumptionInMilliwatts || 0,
                10,
            );
        }

        if (productData.backplanePowerConsumptionInMilliwatts) {
            backplanePowerConsumptionInMilliwatts = parseInt(
                productData.backplanePowerConsumptionInMilliwatts || 0,
                10,
            );
        }

        for (const subProductKey of subProductKeys) {
            const products = subProducts[subProductKey];

            for (const product of products) {
                powerConsumptionInMilliAmps += _.get(
                    product,
                    'productData.powerConsumptionInMilliamps',
                    0,
                ) || 0;
            }
        }

        // Current power consumption formular (((13/36)*56000mW + 200000mW)/12V) + 800mA + 13*1000mA= 32152mA
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-1015
        let slotsFilled                                    = 0;
        let availableSlots                                 = 0;
        const { categoryType, subCategoryType, rackUnits } = productData;

        if (categoryType === ProductCategoryType.matrix) {
            const ioLayoutDefinition = _.get(productData, 'layoutDefinition.io', []);
            availableSlots           = ioLayoutDefinition.length;
        } else if (categoryType === ProductCategoryType.extender) {
            availableSlots = _.get(productData, 'layoutDefinition.slotCount', 0);
        }

        _.forEach(subProducts.slot, (slot) => {
            // Slot is filled with a product which consumes power. So we can skip empty slots or products like covers
            if (_.get(slot, 'productData.productSlotType') === ProductSlotType.ioBoard) {
                slotsFilled++;
            }
        });

        const calculateSlotPowerConsumption = (filledSlots, availableSlots, backplanePowerConsumptionInMilliwatts) => {
            const twelveVolt                = 12;
            let powerConsumptionInMilliAmps = (
                filledSlots / availableSlots
            ) * backplanePowerConsumptionInMilliwatts;
            powerConsumptionInMilliAmps /= twelveVolt;

            if (_.isNaN(powerConsumptionInMilliAmps)) {
                powerConsumptionInMilliAmps = 0;
            }

            return powerConsumptionInMilliAmps;
        };

        if (
            categoryType === ProductCategoryType.matrix &&
            subCategoryType === ProductSubCategoryType.flex
        ) {
            if (productData.mutable === true) {
                // (((NewFilledSlots/MaxSlots) * backPlaneInMilliwatts) / 12V) + 13 * IoInMilliAmpere = Additional PowerConsumption mA
                // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-1342
                availableSlots        = rackUnits * 5;
                let newFilledSlots    = 0;
                let ioInMilliAmpere   = 0;
                const subProductSlots = subProducts?.slot || [];

                for (const slot of subProductSlots) {
                    if (slot?.productData?.productSlotType === ProductSlotType.frontPlate) {
                        const subSlots = slot?.subSlots || [];

                        for (const subSlot of subSlots) {
                            newFilledSlots++;
                            ioInMilliAmpere += _.get(subSlot, 'productData.powerConsumptionInMilliamps', 0);
                        }
                    }
                }

                let customFlexAdditionalPowerConsumptionInMilliAmps = calculateSlotPowerConsumption(
                    newFilledSlots,
                    availableSlots,
                    backplanePowerConsumptionInMilliwatts,
                );
                customFlexAdditionalPowerConsumptionInMilliAmps += ioInMilliAmpere;

                if (includeBasePowerConsumption) {
                    customFlexAdditionalPowerConsumptionInMilliAmps += productData.powerConsumptionInMilliamps;
                }

                return Math.floor(customFlexAdditionalPowerConsumptionInMilliAmps);
            }
            return Math.floor(productData.powerConsumptionInMilliamps);
        }

        if (categoryType === ProductCategoryType.fullIp) {
            return Math.floor(productData.powerConsumptionInMilliamps);
        }

        backplanePowerConsumptionInMilliwatts = calculateSlotPowerConsumption(
            slotsFilled,
            availableSlots,
            backplanePowerConsumptionInMilliwatts,
        );
        const additionalPowerConsumption      = (
            backplanePowerConsumptionInMilliwatts + fanPowerConsumptionInMilliwatts
        ) / 12;

        powerConsumptionInMilliAmps += Math.ceil(additionalPowerConsumption);

        return powerConsumptionInMilliAmps;
    };
}

export default PowerConsumptionCalculator;

export default {
    arrowDown:                  'arrowDown',
    audioAnalogBidirectional:   'audioAnalogBidirectional',
    audioAnalogDigital:         'audioAnalogDigital',
    audioBalanced:              'audioBalanced',
    audioDigitalBidirectional:  'audioDigitalBidirectional',
    audioDigitalUnidirectional: 'audioDigitalUnidirectional',
    cart:                       'cart',
    check:                      'check',
    chevronLeft:                'chevronLeft',
    chevronRight:               'chevronRight',
    close:                      'close',
    copy:                       'copy',
    dataProtection:             'dataProtection',
    dataTransferCatx1g:         'dataTransferCatx1g',
    dataTransferCatx3g:         'dataTransferCatx3g',
    dataTransferFiber10g:       'dataTransferFiber10g',
    dataTransferFiber1g:        'dataTransferFiber1g',
    dataTransferFiber3g:        'dataTransferFiber3g',
    dataTransferFiber6g:        'dataTransferFiber6g',
    dataTransferIpcatx1g:       'dataTransferIpcatx1g',
    dataTransferIpfiber10gmm:   'dataTransferIpfiber10gmm',
    dataTransferIpfiber1g:      'dataTransferIpfiber1g',
    dataTransferUni:            'dataTransferUni',
    delete:                     'delete',
    dots:                       'dots',
    download:                   'download',
    dropZone:                   'dropZone',
    duplicate:                  'duplicate',
    error:                      'error',
    export2:                    'export2',
    export:                     'export',
    fan:                        'fan',
    file:                       'file',
    filter:                     'filter',
    heatDissipation:            'heatDissipation',
    help:                       'help',
    import2:                    'import2',
    import:                     'import',
    lightning:                  'lightning',
    lock:                       'lock',
    minus:                      'minus',
    monitor:                    'monitor',
    offer:                      'offer',
    panelButtonBottom:          'panelButtonBottom',
    panelButtonLeft:            'panelButtonLeft',
    panelButtonRight:           'panelButtonRight',
    partList:                   'partList',
    plus:                       'plus',
    ports:                      'ports',
    print:                      'print',
    requestQuote:               'requestQuote',
    reverse:                    'reverse',
    salesMan:                   'salesMan',
    search:                     'search',
    serialRs232:                'serialRs232',
    serialRs422:                'serialRs422',
    slots:                      'slots',
    tooltip:                    'tooltip',
    trashBin:                   'trashBin',
    usb11:                      'usb11',
    usb20:                      'usb20',
    usb31:                      'usb31',
    usbFlexSpeed20:             'usbFlexSpeed20',
    usbFullSpeed20:             'usbFullSpeed20',
    usbHid:                     'usbHid',
    usbHighSpeed20:             'usbHighSpeed20',
    user:                       'user',
    wrench:                     'wrench',
    zoomIn:                     'zoomIn',
    zoomOut:                    'zoomOut',
};

import React from 'react';

import I18n from 'i18next';
import { withTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import ProductDataValueLabel from '../ProductDataValueLabel';
import PropTypes from '../PropTypes';

const Component = ({ milliamps, voltage, value }) => {
    if (milliamps || milliamps === 0) {
        return (
            <span
                className={styles.milliampsLabel}
                title={`${milliamps} ${I18n.t('milliamps')}`}
            >
                {milliamps}
                {' '}
                {I18n.t('milliampsShort')}
                {
                    voltage
                        ? (
                            ` @${
                                voltage
                            }${I18n.t('voltShort')}`
                        )
                        : ''
                }
            </span>
        );
    }

    return (
        <ProductDataValueLabel value={value} />
    );
};

Component.propTypes = {
    milliamps: PropTypes.number,
    value: PropTypes.any,
    voltage: PropTypes.number,
};

Component.defaultProps = {
    milliamps: 0,
    voltage: 0,
};

export default withTranslation()(Component);

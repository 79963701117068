//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames             from 'classnames';
import I18n                   from 'i18next';
import _                      from 'lodash';
import Collapsible            from 'react-collapsible';
import { DropTarget }         from 'react-dnd';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';

import { ActiveProjectActions }  from '@slices/activeProject';
import DragTypes                 from '@constants/DragTypes';
import ProductCategoryType       from '@constants/ProductCategoryType';
import TestIds                   from '@constants/TestIds';
import Cast                      from '@helper/Cast';
import { DesignerLayoutActions } from '@slices/designerLayout';

import styles                  from './styles.module.scss';
import dragDropConfiguration   from '../../dragDrop/singleOrderAdd';
import Icon                    from '../Icon';
import IconType                from '../Icon/IconType';
import LeftPaneAddButton       from '../LeftPaneAddButton';
import LeftPaneAddButtonTheme  from '../LeftPaneAddButton/LeftPaneAddButtonTheme';
import LeftPaneSingleOrderItem from '../LeftPaneSingleOrderItem';
import PropTypes               from '../PropTypes';
import TextButton              from '../TextButton';
import TextButtonTheme         from '../TextButton/TextButtonTheme';
import TextDropZone            from '../TextDropZone';
import CornerStyle             from '../TextDropZone/CornerStyle';

class Component extends React.Component {
    addButtonPressed = (event) => {
        // Stop the collapsible control collapsing when the add button is pressed
        event.stopPropagation();

        this.props.setActiveProductCategoryType({
            productCategoryType: this.props.productCategoryType,
        });

        this.props.setBottomPaneSetVisibility({
            visible: true,
        });
    };

    addSlotButtonPressed = () => {
        this.props.addSingleOrderSlot({
            categoryType: this.props.productCategoryType,
            productId:    null,
        });
    };

    closeButtonPressed = (event) => {
        // Stop the collapsible control collapsing when the add button is pressed
        event.stopPropagation();

        this.props.setActiveProductCategory({
            productCategoryType: null,
        });
    };

    getSingleOrders = () => {
        if (this.props.singleOrders) {
            return this.props.singleOrders[this.props.productCategoryType];
        }

        return [];
    };

    hasSingleOrders = () => {
        const singleOrders = this.getSingleOrders();

        if (singleOrders) {
            return singleOrders.length > 0;
        }

        return false;
    };

    isActive = () => {
        return this.props.activeProductCategoryType === this.props.productCategoryType;
    };

    render() {
        return (
            <div className={styles.leftPaneSingleOrderCategory}>
                <Collapsible
                    open={true}
                    overflowWhenOpen={'visible'}
                    transitionTime={100}
                    trigger={this.renderToggleControl(false)}
                    triggerWhenOpen={this.renderToggleControl(true)}
                >
                    {this.renderContent()}
                    {this.renderAddSlotButton()}
                </Collapsible>
            </div>
        );
    }

    renderAddSlotButton = () => {
        return this.props.connectDropTarget(<div className={styles.leftPaneSingleOrderCategoryAddButtonWrapper}>
            <div className={styles.leftPaneSingleOrderCategoryAddButtonWrapperInner}>
                <LeftPaneAddButton
                    onClick={this.addSlotButtonPressed}
                    text={I18n.t('addSingleOrderSlot')}
                    theme={LeftPaneAddButtonTheme.singleOrder}
                />
                {this.renderDropZone()}
            </div>
        </div>);
    };

    renderContent = () => {
        return (
            <div className={styles.leftPaneSingleOrderCategoryContent}>
                {this.renderSingleOrders()}
            </div>
        );
    };

    renderDropZone = () => {
        return (
            <TextDropZone
                canDrop={this.props.canDrop}
                cornerStyle={CornerStyle.square}
                isOver={this.props.isOver}
            />
        );
    };

    renderSingleOrders = () => {
        const elements     = [];
        const singleOrders = this.getSingleOrders();

        for (const singleOrderIndex in singleOrders) {
            const singleOrder = singleOrders[singleOrderIndex];

            elements.push(<LeftPaneSingleOrderItem
                key={singleOrderIndex}
                index={Cast.int(singleOrderIndex)}
                productCategoryType={this.props.productCategoryType}
                singleOrder={singleOrder}
            />);
        }

        return elements;
    };

    renderSmallAddButton = () => {
        if (!this.isActive() && this.hasSingleOrders()) {
            return (
                <span
                    className={styles.leftPaneSingleOrderCategoryToggleControlAddButton}
                >
                    <TextButton
                        testId={TestIds.singleOrderShowButton + this.props.productCategoryType}
                        onClick={this.addButtonPressed}
                        text={I18n.t('productCategoryShow')}
                        theme={TextButtonTheme.graySlim}
                    />
                </span>
            );
        }

        return null;
    };

    renderSmallCloseButton = () => {
        if (this.isActive() && this.hasSingleOrders()) {
            return (
                <span
                    className={styles.leftPaneSingleOrderCategoryToggleControlCloseButton}
                >
                    <TextButton
                        onClick={this.closeButtonPressed}
                        text={I18n.t('productCategoryHide')}
                        theme={TextButtonTheme.orangeSlim}
                    />
                </span>
            );
        }

        return null;
    };

    renderToggleControl = (isOpen) => {
        return (
            <div className={styles.leftPaneSingleOrderCategoryToggleControl}>
                <span
                    className={classNames(
                        styles.leftPaneSingleOrderCategoryToggleControlIcon,
                        (
                            isOpen ?
                                styles.leftPaneSingleOrderCategoryToggleControlIconOpen :
                                null
                        ),
                    )}
                >
                    <Icon iconType={IconType.arrowDown} />
                </span>
                <span className={styles.leftPaneSingleOrderCategoryToggleControlText}>
                    {this.props.label}
                </span>
                {this.renderSmallAddButton()}
                {this.renderSmallCloseButton()}
            </div>
        );
    };
}

Component.propTypes = {
    label:               PropTypes.string,
    productCategoryType: PropTypes.oneOf(Object.values(ProductCategoryType)),
};

Component.defaultProps = {
    label:               '',
    productCategoryType: null,
};

const mapStateToProps = (state) => (
    {
        activeProductCategoryType: state.activeProject.activeProductCategoryType,
        singleOrders:              state.activeProject.singleOrders,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(
    ActiveProjectActions,
    DesignerLayoutActions,
), dispatch);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    DropTarget(
        DragTypes.add,
        dragDropConfiguration.dropBehavior,
        dragDropConfiguration.dropCollect,
    ),
)(withTranslation()(Component));

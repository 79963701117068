//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import queryString from 'query-string';

import ApiConfiguration from '@constants/Api';

import Base64         from './Base64';
import FormPoster     from './FormPoster';
import LanguageHelper from './Language';

class PrintPreview {
    static getPrintPreviewUrl = (extension, userLanguage, notAbsolute, cardOverlayMode, groupSimilarProducts) => {
        const language               = LanguageHelper.getBackendLanguage(userLanguage);
        const queryStringParts       = {
            cardOverlayMode,
            language,
            groupSimilarProducts,
        };
        const queryStringPartsString = queryString.stringify(queryStringParts);
        const finalPrintPreviewUrl   = `${!notAbsolute ?
            ApiConfiguration.getBackendUrl() :
            ''
        }/print-preview.${extension}?${queryStringPartsString}`;

        return finalPrintPreviewUrl;
    };

    static getPrintPreviewForm = (extension, activeProject, formName, userLanguage, cardOverlayMode, groupSimilarProducts) => {
        const activeProjectJson = JSON.stringify(activeProject);
        const encodedString     = Base64.encode(activeProjectJson);
        const printPreviewUrl   = this.getPrintPreviewUrl(extension, userLanguage, null, cardOverlayMode, groupSimilarProducts);
        const form              = FormPoster.createForm(
            printPreviewUrl,
            'projectPayload',
            encodedString,
            formName,
        );

        return form;
    };
}

export default PrintPreview;

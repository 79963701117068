//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames          from 'classnames';
import { useState }        from 'react';
import { goBack }          from 'connected-react-router';
import { useEffect }       from 'react';
import I18n                from 'i18next';
import { KEY_ESCAPE }      from 'keycode-js';
import _                   from 'lodash';
import { withTranslation } from 'react-i18next';
import { Modal }           from 'react-overlays';
import { connect }         from 'react-redux';
import { withRouter }      from 'react-router-dom';
import { compose }         from 'redux';

import Overlays        from '@constants/Overlays';
import TestIds         from '@constants/TestIds';
import SelectionHelper from '@helper/SelectionHelper';

import BackType                     from './BackType';
import OverlayType                  from './OverlayType';
import styles                       from './styles.module.scss';
import ColorButton                  from '../ColorButton';
import ColorButtonTheme             from '../ColorButton/ColorButtonTheme';
import Icon                         from '../Icon';
import IconType                     from '../Icon/IconType';
import OverlayDontShowAgainCheckBox from '../OverlayDontShowAgainCheckBox';
import PropTypes                    from '../PropTypes';
import TextButton                   from '../TextButton';
import TextButtonTheme              from '../TextButton/TextButtonTheme';

const Component = (props) => {
    const [mouseDownInModal, setMouseDownInModal] = useState(false);
    const {
              allowDontShowAgain,
              backType,
              cancelButtonTextKey,
              children,
              closable,
              forceCancelButton,
              goBack,
              history,
              id,
              nextRoute,
              okButtonTextKey,
              okButtonValidator,
              overlayType,
              title,
          }                                       = props;
    const goBackOrRemoveOverlay                   = () => {
        if (backType === BackType.back) {
            goBack();
        } else {
            const routeWithoutOverlay = history.location.pathname;

            history.push(routeWithoutOverlay);
        }
    };
    const closeButtonPressed                      = () => {
        if (closable) {
            goBackOrRemoveOverlay();

            props.closeButtonPressed();
        }
    };
    const getTypeClass                            = () => {
        return SelectionHelper.get(
            overlayType,
            {
                [OverlayType.dialog]: styles.modalContainerTypeDialog,
                [OverlayType.prompt]: styles.modalContainerTypePrompt,
                [OverlayType.table]:  styles.modalContainerTypeTable,
            },
        );
    };
    const handleKeyDown                           = (event) => {
        switch (event.keyCode) {
            /* eslint-disable padding-line-between-statements */
            // @formatter:off
            case KEY_ESCAPE: onEscapeKeyDown(); break;
            // @formatter:on
            /* eslint-enable padding-line-between-statements */
        }
    };
    const okButtonPressed                         = async () => {
        if (okButtonValidator === _.noop || await okButtonValidator()) {
            if (nextRoute) {
                history.push(nextRoute);
            } else {
                goBackOrRemoveOverlay();
            }

            props.okButtonPressed();
        }
    };
    const onEscapeKeyDown                         = () => {
        closeButtonPressed();
    };
    const renderContent                           = () => {
        return (
            <div className={styles.modalContent}>
                {children}
                {
                    allowDontShowAgain
                        ? (
                            <OverlayDontShowAgainCheckBox
                                overlayId={id}
                            />
                        )
                        : null
                }
            </div>
        );
    };
    const containerMouseUp                        = (event) => {
        if (!mouseDownInModal) {
            closeButtonPressed();
        }

        setMouseDownInModal(false);
    };
    const modalMouseDown                          = (event) => {
        event.stopPropagation();
        setMouseDownInModal(true);
    };
    const modalMouseUp                            = (event) => {
        event.stopPropagation();
        setMouseDownInModal(false);
    };
    const renderDialog                            = (dialogProps) => {
        return (
            <div
                data-testId={TestIds.overlay}
                {...dialogProps}
                role={null}
                tabIndex={null}
            >
                <div
                    className={classNames(
                        styles.modalWrapper,
                        (
                            !closable ?
                                styles.modalWrapperUnclosable :
                                null
                        ),
                    )}
                    onMouseUp={containerMouseUp}
                >
                    <div
                        className={classNames(
                            styles.modalContainer,
                            getTypeClass(),
                        )}
                        onMouseDown={modalMouseDown}
                        onMouseUp={modalMouseUp}
                    >
                        {renderHeader()}
                        {renderContent()}
                        {renderFooter()}
                    </div>
                </div>
            </div>
        );
    };
    const renderFooter                            = () => {
        return SelectionHelper.get(
            overlayType,
            {
                [OverlayType.dialog]: renderFooterDialog(),
                [OverlayType.prompt]: renderFooterPrompt(),
                [OverlayType.table]:  renderFooterTable(),
            },
        );
    };
    const renderAdditionalFooterButtons           = () => {
        const additionalButtons = props.renderAdditionalFooterButtons();

        if (additionalButtons) {
            return (
                <div className={styles.modalFooterLeft}>
                    {additionalButtons}
                </div>
            );
        }

        return null;
    };
    const renderFooterCancelButton                = () => {
        return (
            cancelButtonTextKey ?
                (
                    <div className={styles.modalFooterLeft}>
                        <TextButton
                            onClick={closeButtonPressed}
                            text={I18n.t(cancelButtonTextKey)}
                            theme={TextButtonTheme.black}
                        />
                    </div>
                )
                : null
        );
    };
    const renderFooterDialog                      = () => {
        return (
            <div className={styles.modalFooter}>
                {
                    forceCancelButton ?
                        renderFooterCancelButton() :
                        renderAdditionalFooterButtons()
                }
                <div className={styles.modalFooterRight}>
                    <ColorButton
                        testId={TestIds.overlayOkButton}
                        onClick={okButtonPressed}
                        text={I18n.t(okButtonTextKey)}
                        theme={ColorButtonTheme.orange}
                    />
                </div>
            </div>
        );
    };
    const renderFooterPrompt                      = () => {
        return (
            <div className={styles.modalFooter}>
                {renderFooterCancelButton()}
                <div className={styles.modalFooterRight}>
                    <ColorButton
                        testId={TestIds.overlayOkButton}
                        onClick={okButtonPressed}
                        text={I18n.t(okButtonTextKey)}
                        theme={ColorButtonTheme.orange}
                    />
                </div>
            </div>
        );
    };
    const renderFooterTable                       = () => {
        const additionalFooterButtons = renderAdditionalFooterButtons();

        if (additionalFooterButtons) {
            return (
                <div className={styles.modalFooter}>
                    {renderAdditionalFooterButtons()}
                    <div className={styles.modalFooterRight}>
                        <ColorButton
                            testId={TestIds.overlayOkButton}
                            onClick={okButtonPressed}
                            text={I18n.t(okButtonTextKey)}
                            theme={ColorButtonTheme.orange}
                        />
                    </div>
                </div>
            );
        }

        return null;
    };
    const renderHeader                            = () => {
        return (
            <div className={styles.modalHeader}>
                <h2>{title}</h2>
                {renderHeaderCloseButton()}
            </div>
        );
    };
    const renderHeaderCloseButton                 = () => {
        if (closable) {
            return (
                <span
                    className={styles.modalHeaderCloseButton}
                    onClick={closeButtonPressed}
                    title={I18n.t('closeOverlay')}
                >
                    <Icon iconType={IconType.close} />
                </span>
            );
        }

        return null;
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <Modal
            onHide={close}
            show={true}
            renderDialog={renderDialog}
        />
    );
};

Component.propTypes = {
    allowDontShowAgain:            PropTypes.bool,
    backType:                      PropTypes.oneOf(Object.values(BackType)),
    cancelButtonTextKey:           PropTypes.string,
    children:                      PropTypes.children,
    closable:                      PropTypes.bool,
    closeButtonPressed:            PropTypes.func,
    forceCancelButton:             PropTypes.bool,
    getOkButtonPressedCallback:    PropTypes.func,
    id:                            PropTypes.oneOf(Object.values(Overlays)),
    nextRoute:                     PropTypes.string,
    okButtonPressed:               PropTypes.func,
    okButtonTextKey:               PropTypes.string,
    okButtonValidator:             PropTypes.func,
    overlayType:                   PropTypes.oneOf(Object.values(OverlayType)),
    renderAdditionalFooterButtons: PropTypes.func,
    title:                         PropTypes.string,
};

Component.defaultProps = {
    allowDontShowAgain:            false,
    backType:                      BackType.back,
    cancelButtonTextKey:           'cancel',
    children:                      [],
    closable:                      true,
    closeButtonPressed:            _.noop,
    id:                            '',
    forceCancelButton:             false,
    getOkButtonPressedCallback:    _.noop,
    nextRoute:                     '',
    okButtonPressed:               _.noop,
    okButtonTextKey:               'ok',
    okButtonValidator:             _.noop,
    overlayType:                   OverlayType.prompt,
    renderAdditionalFooterButtons: _.noop,
    title:                         '',
};

export default compose(
    connect(
        null,
        {
            goBack,
        },
    ),
    withRouter,
)(withTranslation()(Component));

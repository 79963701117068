//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { ConnectedRouter }    from 'connected-react-router';
import I18n                   from 'i18next';
import queryString    from 'query-string';
import { connect }            from 'react-redux';
import { Switch }             from 'react-router';
import { Route }              from 'react-router';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import Routes                   from '@constants/Routes';
import Cast                     from '@helper/Cast';
import { history }              from '@store/index';

import styles                       from './styles.module.scss';
import ActionCancelAndConfirmButton from '../ActionCancelAndConfirmButton';
import HelpPane                     from '../HelpPane';
import PrettyScrollBar              from '../PrettyScrollBar';
import ProductConfigurationPane     from '../ProductConfigurationPane';
import ProjectOverviewPane          from '../ProjectOverviewPane';
import SingleOrderPane              from '../SingleOrderPane';

class Component extends React.Component {
    scrollViewReference = null;

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.props.pathname !== nextProps.pathname) {
            this.scrollToTop();

            if (this.props.search !== nextProps.search) {
                const parsedSearch = queryString.parse(nextProps.search);

                if (parsedSearch.index) {
                    const urlSlotIndex = Cast.int(parsedSearch.index);
                    const urlSlotType  = parsedSearch.type;

                    if (
                        this.props.selectedSlot && (
                            this.props.selectedSlot.index !== urlSlotIndex ||
                            this.props.selectedSlot.slotType !== urlSlotType
                        )
                    ) {
                        this.props.selectSlot({
                            index:    urlSlotIndex,
                            slotType: urlSlotType,
                        });
                    }
                }
            }
        }
    }

    render() {
        return (
            <div
                className={styles.leftPaneRouter}
                ref={this.setScrollViewReference}
            >
                <PrettyScrollBar>
                    <ConnectedRouter history={history}>
                        <Switch>
                            <Route
                                exact={true}
                                path={Routes.designerProductSingleOrders}
                                component={SingleOrderPane}
                            />
                            <Route
                                exact={false}
                                path={Routes.designerProductDetails}
                                component={ProductConfigurationPane}
                            />
                            <Route
                                exact={false}
                                path={Routes.designer}
                                component={ProjectOverviewPane}
                            />
                            <Route
                                exact={false}
                                path={Routes.help}
                                component={HelpPane}
                            />
                        </Switch>
                    </ConnectedRouter>
                </PrettyScrollBar>
                {this.renderMultiDeleteButton()}
            </div>
        );
    }

    renderMultiDeleteButton = () => {
        if (this.props.pathname === Routes.designer) {
            return (
                <ActionCancelAndConfirmButton
                    actionsVisible={this.props.deleteModeActive}
                    confirmLabel={I18n.t('deleteChassis')}
                    onActionClick={this.onActionClick}
                    onCancelClick={this.onCancelClick}
                    onConfirmClick={this.onConfirmClick}
                    actionLabel={I18n.t('selectMultipleChassisAndDeleteThem')}
                />
            );
        }

        return null;
    };

    onActionClick = () => {
        this.props.setProductDeletionMode({
            deleteModeActive: true,
        });
    };

    onCancelClick = () => {
        this.props.toggleProductDeletionMode({
            deleteModeActive: false,
        });
        this.props.deleteSelectedProductsCancel();
    };

    onConfirmClick = () => {
        this.props.toggleProductDeletionMode({
            deleteModeActive: false,
        });
        this.props.deleteSelectedProducts();
    };

    scrollToTop = () => {
        if (this.scrollViewReference) {
            if (this.scrollViewReference.scrollTo) {
                this.scrollViewReference.scrollTo(0, 0);
            } else {
                this.scrollViewReference.scrollTop = 0;
            }
        }
    };

    setScrollViewReference = (scrollViewReference) => {
        this.scrollViewReference = scrollViewReference;
    };

    isSingleOrderMode = () => {
        return this.props.pathname.endsWith(Routes.designerProductSingleOrders);
    };
}

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

const mapStateToProps = (state) => (
    {
        deleteModeActive: state.activeProject.deleteModeActive,
        pathname:         state.router.location.pathname,
        search:           state.router.location.search,
        selectedSlot:     state.activeProject.selectedSlot,
    }
);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n from 'i18next';

import * as Configuration from '@helper/Configuration';

import ContactItem        from '../ContactItem';
import ContactItemWrapper from '../ContactItemWrapper';
import IconType           from '../Icon/IconType';
import TextContainer      from '../TextContainer';
import TextHeadline       from '../TextHeadline';
import TextHeadlineTheme  from '../TextHeadline/TextHeadlineTheme';
import TextLong           from '../TextLong';

class IHSEContactContent extends React.Component {
    render() {
        const sales              = Configuration.getContact('sales');
        const technicalSupport   = Configuration.getContact('technicalSupport');
        const orders             = Configuration.getContact('orders');
        let salesItem            = null;
        let technicalSupportItem = null;
        let ordersItem           = null;

        if (sales) {
            salesItem = (
                <ContactItem
                    eMailAddress={sales.email}
                    iconType={IconType.salesMan}
                    phoneNumber={sales.phone}
                    text={I18n.t('contactSalesConsultingText')}
                    title={I18n.t('contactSalesConsultingTitle')}
                />
            );
        }

        if (technicalSupport) {
            technicalSupportItem = (
                <ContactItem
                    eMailAddress={technicalSupport.email}
                    iconType={IconType.wrench}
                    phoneNumber={technicalSupport.phone}
                    text={I18n.t('contactTechnicalSupportText')}
                    title={I18n.t('contactTechnicalSupportTitle')}
                />
            );
        }

        if (orders) {
            ordersItem = (
                <ContactItem
                    eMailAddress={orders.email}
                    iconType={IconType.cart}
                    phoneNumber={orders.phone}
                    text={I18n.t('contactOrderProcessingText')}
                    title={I18n.t('contactOrderProcessingTitle')}
                />
            );
        }

        return (
            <div>
                <TextContainer>
                    <TextHeadline
                        theme={TextHeadlineTheme.h3BlackBold}
                        text={I18n.t('contactTitle')}
                    />
                    <TextHeadline
                        theme={TextHeadlineTheme.h4Black}
                        text={I18n.t('contactOpeningHours')}
                    />
                    <TextLong text={I18n.t('contactOpeningHoursText1')} />
                    <TextLong
                        breakAfter={true}
                        text={I18n.t('contactOpeningHoursText2')}
                    />
                    <TextLong text={I18n.t('contactOpeningHoursText3')} />
                </TextContainer>
                <ContactItemWrapper>
                    {salesItem}
                    {technicalSupportItem}
                    {ordersItem}
                </ContactItemWrapper>
            </div>
        );
    }
}

IHSEContactContent.propTypes = {};

IHSEContactContent.defaultProps = {};

export default IHSEContactContent;

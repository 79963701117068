// @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-203
export default {
    accessory:       'mtg',
    cover:           'cover',
    fan:             'fan',
    filter:          'fltr',
    option:          'opt',
    powerSupplyUnit: 'psu',
    sfp:             'sfp',
};

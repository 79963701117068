//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';
import I18n       from 'i18next';
import _          from 'lodash';

import AccessoryTypes             from '@constants/AccessoryTypes';
import ProductCategoryType        from '@constants/ProductCategoryType';
import ProductSlotType            from '@constants/ProductSlotType';
import ProductSubCategoryType     from '@constants/ProductSubCategoryType';
import ProductWarningHelper       from '@helper/ProductWarningHelper';
import PowerConsumptionCalculator from '@store/calculators/powerConsumption';

import ProductInformationDrawerMode from './ProductInformationDrawerMode';
import IconType                     from '../Icon/IconType';
import ProductInformation           from '../ProductInformation';
import PropTypes                    from '../PropTypes';

class Component extends React.Component {
    getAvailableSlotCount = () => {
        const { product }      = this.props;
        const layoutDefinition = _.get(product, 'productData.layoutDefinition', null);

        if (layoutDefinition) {
            const { availableRowCount, io } = layoutDefinition;

            if (io) {
                return io.length;
            }

            if (availableRowCount) {
                return availableRowCount;
            }
        }

        return product.productData.slotCount;
    };

    getUsedSlotCount = () => {
        let usedSlotCount = 0;

        for (const slot of this.props.product.subProducts.slot) {
            const { productData } = slot;

            if (
                productData &&
                productData.productSlotType !== ProductSlotType.cpuBoard &&
                productData.heightUnits &&
                productData.type !== AccessoryTypes.cover
            ) {
                usedSlotCount += slot.productData.heightUnits;
            } else if (
                productData &&
                productData.categoryType === ProductCategoryType.matrix &&
                productData.subCategoryType === ProductSubCategoryType.flex &&
                productData.productSlotType === ProductSlotType.frontPlate
            ) {
                usedSlotCount += 1;
            } else if (
                productData &&
                productData.categoryType === ProductCategoryType.fullIp
            ) {
                usedSlotCount += 1;
            }
        }

        return usedSlotCount;
    };

    render() {
        const informations                                  = [];
        const { product, mode, wrapperClassName, children } = this.props;

        if (product && product.productData) {
            const { productData }                   = product;
            const { categoryType, subCategoryType } = productData;

            if (this.props.mode === ProductInformationDrawerMode.full) {
                const includeBasePowerConsumption = (
                    categoryType === ProductCategoryType.matrix &&
                    subCategoryType === ProductSubCategoryType.flex
                );
                const powerConsumption            = PowerConsumptionCalculator.getPowerConsumption(
                    product,
                    product.subProducts,
                    includeBasePowerConsumption,
                );

                if (product.productData.maximumPowerConsumptionInMilliamps) {
                    informations.push(<ProductInformation
                        iconType={IconType.lightning}
                        index={informations.length}
                        key={'lightning'}
                        text={
                            `${powerConsumption
                            }/${
                                product.productData.maximumPowerConsumptionInMilliamps
                            } ${
                                I18n.t('milliampsShort')}`
                        }
                        title={I18n.t('maximumPowerConsumptionTip')}
                    />);
                }

                if (product.productData.powerConsumptionInMilliamps) {
                    informations.push(<ProductInformation
                        iconType={IconType.lightning}
                        index={informations.length}
                        key={'lightning'}
                        text={
                            `${powerConsumption
                            } ${
                                I18n.t('milliampsShort')}`
                        }
                        title={I18n.t('powerConsumption')}
                    />);
                }
            }

            if (
                product.productData.slotCount &&
                product.productData.subCategoryType !== ProductSubCategoryType.compact
            ) {
                const availableSlotCount = this.getAvailableSlotCount();
                const usedSlotCount      = this.getUsedSlotCount();
                let iconType             = IconType.slots;
                let unit                 = (
                    product.productData.slotCount === 1
                        ? I18n.t('slot')
                        : I18n.t('slots')
                );
                let toolTipText          = I18n.t('slotsTip');

                if (product.productData.categoryType === ProductCategoryType.fullIp) {
                    unit        = (
                        product.productData.slotCount === 1
                            ? I18n.t('option')
                            : I18n.t('options')
                    );
                    toolTipText = I18n.t('optionsTip');
                    iconType    = null;
                }

                const text = `${usedSlotCount}/${availableSlotCount} ${unit}`;

                informations.push(<ProductInformation
                    iconType={iconType}
                    index={informations.length}
                    key={'slots'}
                    text={text}
                    title={toolTipText}
                />);
            }

            if (this.props.mode === ProductInformationDrawerMode.full) {
                if (product.productData.portCount) {
                    informations.push(<ProductInformation
                        iconType={IconType.ports}
                        index={informations.length}
                        key={'ports'}
                        text={
                            `${product.productData.portCount
                            } ${

                                product.productData.portCount === 1 ?
                                    I18n.t('port') :
                                    I18n.t('ports')}`
                        }
                        title={I18n.t('portsTip')}
                    />);
                }

                const heatDissipation = product.heatDissipation;

                if (categoryType !== ProductCategoryType.fullIp) {
                    if (heatDissipation.chassis) {
                        informations.push(<ProductInformation
                            iconType={IconType.heatDissipation}
                            index={informations.length}
                            key={'heatDissipationChassis'}
                            text={
                                `${I18n.t('chassis')
                                }: ${
                                    heatDissipation.chassis
                                } ${
                                    I18n.t('wattShort')}`
                            }
                            title={I18n.t('heatDissipationChassisTip')}
                        />);
                    }

                    if (heatDissipation.externalPsu) {
                        informations.push(<ProductInformation
                            iconType={IconType.heatDissipation}
                            index={informations.length}
                            key={'heatDissipationExternalPsu'}
                            text={
                                `${I18n.t('externalPsuShort')
                                }: ${
                                    heatDissipation.externalPsu
                                } ${
                                    I18n.t('wattShort')}`
                            }
                            title={I18n.t('heatDissipationExternalPsuTip')}
                        />);
                    }
                }
            }

            if (mode === ProductInformationDrawerMode.light) {
                const containsErrorsOrWarnings = ProductWarningHelper.containsErrorsOrWarnings(product);

                if (containsErrorsOrWarnings) {
                    const containsErrors = ProductWarningHelper.containsErrors(product);
                    const tooltipText    = ProductWarningHelper.getWarningText(product);

                    if (containsErrors) {
                        informations.push(<ProductInformation
                            iconType={IconType.error}
                            index={informations.length}
                            key={'errors'}
                            title={I18n.t('errorsInYourConfiguration')}
                            tooltipText={tooltipText}
                            containsErrors={true}
                        />);
                    } else {
                        informations.push(<ProductInformation
                            iconType={IconType.error}
                            index={informations.length}
                            key={'warnings'}
                            title={I18n.t('warningsInYourConfiguration')}
                            tooltipText={tooltipText}
                            containsWarnings={true}
                        />);
                    }
                }
            }
        }

        if (informations.length > 0) {
            return (
                <div className={classNames(wrapperClassName)}>
                    {informations}
                    {children}
                </div>
            );
        }

        return null;
    }
}

Component.propTypes = {
    children:         PropTypes.children,
    mode:             PropTypes.oneOf(Object.values(ProductInformationDrawerMode)),
    product:          PropTypes.object,
    wrapperClassName: PropTypes.string,
};

Component.defaultProps = {
    children:         null,
    mode:             ProductInformationDrawerMode.full,
    product:          null,
    wrapperClassName: '',
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { useSelector }        from 'react-redux';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import SubSlotListSlot          from '@components/SubSlotListSlot';
import TestIds                  from '@constants/TestIds';
import Cast                     from '@helper/Cast';
import StateHelper              from '@helper/State';
import { ActiveProjectActions } from '@slices/activeProject';

import styles from './styles.module.scss';

const Component = () => {
    const selectedSlot     = useSelector(StateHelper.getSelectedSlot);
    const selectedSlotMeta = useSelector((state) => state.activeProject.selectedSlot);
    const renderSubSlots   = () => {
        const subSlots     = [];
        const subSlotCount = _.get(selectedSlot, 'productData.slotCount', 0);

        for (let subSlotIndex = 0; subSlotIndex < subSlotCount; ++subSlotIndex) {
            const subSlotIndexInteger = Cast.int(subSlotIndex);

            subSlots.push(<SubSlotListSlot
                key={`slot${subSlotIndex}${selectedSlotMeta.index}`}
                index={subSlotIndexInteger}
            />);
        }

        return subSlots;
    };

    return (
        <div
            data-testId={TestIds.subSlotList}
            className={styles.subSlotListWrapper}
        >
            <h3 className={styles.subSlotListTitle}>
                {I18n.t('dataTransfer')}
            </h3>
            <div className={styles.subSlotList}>
                {renderSubSlots()}
            </div>
        </div>
    );
};

Component.propTypes = {};

Component.defaultProps = {};

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default connect(
    null,
    mapDispatchToProps,
)(withTranslation()(Component));

import React from 'react';

import classNames from 'classnames';

import SelectionHelper from '@helper/SelectionHelper';

import TipStyle  from './TipStyle';
import styles    from './styles.module.scss';
import PropTypes from '../PropTypes';

const Component = ({
    style = null,
    text  = '',
    title = '',
}) => {
    function getTipStyleClass() {
        return SelectionHelper.get(
            style,
            {
                [TipStyle.leftPane]:  styles.tipWrapperLeftPane,
                [TipStyle.rightPane]: styles.tipWrapperRightPane,
            },
        );
    }

    const tipStyleClass = getTipStyleClass();

    return (
        <div
            className={classNames(
                styles.tipWrapper,
                tipStyleClass,
            )}
        >
            <h3>{title}</h3>
            <p>
                {text}
            </p>
        </div>
    );
};

Component.propTypes = {
    style: PropTypes.oneOf(Object.values(TipStyle)),
    text:  PropTypes.string,
    title: PropTypes.string,
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames     from 'classnames';
import _              from 'lodash';
import { connect }    from 'react-redux';
import { withRouter } from 'react-router';

import HeaderButtonTheme from './HeaderButtonTheme';
import styles            from './styles.module.scss';
import Icon              from '../Icon';
import IconType          from '../Icon/IconType';
import Link              from '../Link';
import PropTypes         from '../PropTypes';

class Component extends React.Component {
    reference = null;

    constructor(props) {
        super(props);

        this.state = {
            dropDownVisible: false,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.location !== this.props.location) {
            this.setState({
                dropDownVisible: false,
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    dropDownToggleClicked = () => {
        this.setState({
            dropDownVisible: !this.state.dropDownVisible,
        });
    };

    handleClickOutside = (event) => {
        if (this.reference && !this.reference.contains(event.target)) {
            this.setState({
                dropDownVisible: false,
            });
        }
    };

    render() {
        return (
            <div
                data-testId={this.props.testId}
                className={classNames(
                    styles.headerButton,
                    {
                        [styles.headerButtonDisabled]: this.props.disabled,
                    },
                    {
                        [styles.headerButtonMarginLeft]: this.props.theme === HeaderButtonTheme.leftMargin,
                    },
                    [
                        styles.hideXs,
                    ],
                )}
                ref={this.setReference}
            >
                {this.renderButtonWrapper()}
                {this.renderDropDown()}
            </div>
        );
    }

    renderButton = () => {
        return (
            <div className={styles.headerButtonInner}>
                <div
                    className={styles.headerButtonIconWrapper}
                >
                    <Icon iconType={this.props.icon} />
                </div>
                <span
                    className={classNames(styles.headerButtonText)}
                >
                    {this.props.text}
                </span>
                <span
                    className={classNames(
                        styles.headerButtonText,
                        styles.headerButtonShortText,
                    )}
                >
                    {this.props.shortText || this.props.text}
                </span>
            </div>
        );
    };

    renderButtonWrapper = () => {
        if (this.props.route) {
            return (
                <Link
                    to={this.props.route}
                    params={this.props.routeParameters}
                >
                    {this.renderButton()}
                </Link>
            );
        }

        if (this.props.dropDown) {
            return (
                <div
                    className={styles.headerButtonClickWrapper}
                    onClick={this.dropDownToggleClicked}
                >
                    {this.renderButton()}
                </div>
            );
        }

        return (
            <div
                className={styles.headerButtonClickWrapper}
                onClick={this.props.onClick}
            >
                {this.renderButton()}
            </div>
        );
    };

    renderDropDown = () => {
        if (this.state.dropDownVisible) {
            return this.props.dropDown;
        }

        return null;
    };

    setReference = (reference) => {
        this.reference = reference;
    };
}

Component.propTypes = {
    disabled:        PropTypes.bool,
    dropDown:        PropTypes.node,
    icon:            PropTypes.oneOf(Object.values(IconType)),
    onClick:         PropTypes.func,
    route:           PropTypes.string,
    routeParameters: PropTypes.object,
    shortText:       PropTypes.string,
    testId:          PropTypes.string,
    text:            PropTypes.string,
    theme:           PropTypes.oneOf(Object.values(HeaderButtonTheme)),
};

Component.defaultProps = {
    disabled:        false,
    dropDown:        null,
    icon:            null,
    onClick:         _.noop,
    route:           '',
    routeParameters: {},
    shortText:       '',
    text:            '',
    theme:           HeaderButtonTheme.default,
    testId:          '',
};

const mapStateToProps = (state) => (
    {
        location: state.router.location,
    }
);

export default withRouter(connect(
    mapStateToProps,
    {},
)(Component));

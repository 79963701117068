//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames          from 'classnames';
import _                   from 'lodash';
import { withTranslation } from 'react-i18next';

import styles    from './styles.module.scss';
import Icon      from '../Icon';
import IconType  from '../Icon/IconType';
import PropTypes from '../PropTypes';

const Component = ({
    children,
    className,
    expanded,
    groupName,
    isDragActive,
    onGroupToggle,
}) => {
    const getText            = () => {
        return groupName;
    };
    const onClick            = () => {
        onGroupToggle(!expanded);
    };
    const renderExpandButton = () => {
        const text = getText();

        return (
            <div
                className={classNames(styles.groupedChassisCollapser, className, {
                    [styles.collapsed]: !expanded,
                })}
                onClick={onClick}
            >
                <div className={styles.groupedChassisCollapserText}>
                    <div className={styles.text}>{text}</div>
                    <div className={styles.count}>
                        <div>x {children.length}</div>
                        <Icon iconType={IconType.arrowDown} />
                    </div>
                </div>
            </div>
        );
    };
    const renderChildren     = () => {
        if (children && children.length) {
            if (expanded) {
                return children;
            }
            return _.head(children);
        }
        return null;
    };

    return (
        <div
            className={classNames(styles.groupedChassisCollapserContainer, className, {
                [styles.collapsed]:    !expanded,
                [styles.isDragActive]: isDragActive,
            })}
        >
            {renderExpandButton()}
            <div
                className={classNames(styles.groupedChassisChildrenContainer, className, {
                    [styles.collapsed]: !expanded,
                })}
            >
                {renderChildren()}
            </div>
        </div>
    );
};

Component.propTypes = {
    children:      PropTypes.array,
    className:     PropTypes.string,
    expanded:      PropTypes.bool,
    groupName:     PropTypes.string,
    isDragActive:  PropTypes.bool,
    onGroupToggle: PropTypes.func,
};

Component.defaultProps = {
    className:     '',
    children:      [],
    isDragActive:  false,
    groupName:     '',
    expanded:      false,
    onGroupToggle: _.noop,
};

export default withTranslation()(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames          from 'classnames';
import I18n                from 'i18next';
import { withTranslation } from 'react-i18next';

import SelectionHelper from '@helper/SelectionHelper';

import NumberOfMonitorsLabelAlignment from './NumberOfMonitorsLabelAlignment';
import styles                         from './styles.module.scss';
import Icon                           from '../Icon';
import IconType                       from '../Icon/IconType';
import PropTypes                      from '../PropTypes';

class Component extends React.Component {
    getTitle = () => {
        const languageIndex = `numberOfMonitorsTitle${this.props.numberOfMonitors}`;

        return I18n.t(languageIndex);
    };

    render() {
        const title = this.getTitle();

        return (
            <span
                className={classNames(
                    styles.numberOfMonitorsLabel,
                    SelectionHelper.get(
                        this.props.alignment,
                        {
                            [NumberOfMonitorsLabelAlignment.left]:  styles.numberOfMonitorsLabelLeft,
                            [NumberOfMonitorsLabelAlignment.right]: styles.numberOfMonitorsLabelRight,
                        },
                    ),
                )}
                title={title}
            >
                <span className={styles.numberOfMonitorsText}>
                    {this.props.numberOfMonitors}
                </span>
                {this.renderIcons()}
            </span>
        );
    }

    renderIcons = () => {
        const icons = [];

        for (let index = 0; index < this.props.numberOfMonitors; ++index) {
            icons.push(<span
                className={styles.numberOfMonitorsLabelIconWrapper}
                key={index}
            >
                <Icon iconType={IconType.monitor} />
            </span>);
        }

        return icons;
    };
}

Component.propTypes = {
    alignment:        PropTypes.oneOf(Object.values(NumberOfMonitorsLabelAlignment)),
    numberOfMonitors: PropTypes.number,
};

Component.defaultProps = {
    alignment:        null,
    numberOfMonitors: 0,
};

export default withTranslation()(Component);

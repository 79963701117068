//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { LOCATION_CHANGE }    from 'connected-react-router';
import { DebugActions }       from '@slices/debug';
import Routes                 from '@constants/Routes';
import TabIndices             from '@constants/TabIndices';
import { bindActionCreators } from 'redux';

const initialState = {
    activeMainTabIndex:          TabIndices.mainMatrixTabEnterprise,
    activeSubTabCompactIndex:    TabIndices.subTabCompactChassis,
    activeSubTabFlexIndex:       TabIndices.subTabFlexChassis,
    activeSubTabEnterpriseIndex: TabIndices.subTabEnterpriseIOBoards,
};

const MatrixProductBrowserSlice = createSlice({
    name:     'matrixProductBrowser',
    initialState,
    reducers: {
        setActiveSubTabCompactIndex:    (state, action) => update(state, {
            activeSubTabCompactIndex: {
                $set: action.payload.activeSubTabCompactIndex,
            },
        }),
        setActiveSubTabFlexIndex:       (state, action) => update(state, {
            activeSubTabFlexIndex: {
                $set: action.payload.activeSubTabFlexIndex,
            },
        }),
        setActiveSubTabEnterpriseIndex: (state, action) => update(state, {
            activeSubTabEnterpriseIndex: {
                $set: action.payload.activeSubTabEnterpriseIndex,
            },
        }),
        setActiveMainTabIndex:          (state, action) => update(state, {
            activeMainTabIndex: {
                $set: action.payload.activeMainTabIndex,
            },
        }),
        resetAll:                 (state, action) => {
            if (action.type === DebugActions.resetAll().type) {
                return update(state, {
                    $set: initialState,
                });
            }
            return state;
        },
        locationChange:           (state, action) => {
            if (action.type === LOCATION_CHANGE) {
                const targetPath = _.get(action, 'payload.location.pathname', '');
                if (targetPath === Routes.designer) {
                    return update(state, {
                        activeSubTabCompactIndex:    {
                            $set: initialState.activeSubTabCompactIndex,
                        },
                        activeSubTabFlexIndex:       {
                            $set: initialState.activeSubTabFlexIndex,
                        },
                        activeSubTabEnterpriseIndex: {
                            $set: initialState.activeSubTabEnterpriseIndex,
                        },
                    });
                }
            }
            return state;
        },
    },
});

export const MatrixProductBrowserActions = MatrixProductBrowserSlice.actions;

export const MatrixProductBrowserReducer = MatrixProductBrowserSlice.reducer;

export const useMatrixProductBrowser = (dispatch) => bindActionCreators(MatrixProductBrowserActions, dispatch);

export default MatrixProductBrowserSlice;

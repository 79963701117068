//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import styles    from './styles.module.scss';
import Icon      from '../Icon';
import IconType  from '../Icon/IconType';
import NavLink   from '../NavLink';
import PropTypes from '../PropTypes';

const Component = ({
    badeCount = 0,
    label     = '',
    params    = {},
    route     = '',
    testId    = '',
}) => {
    const renderBadge = () => (
        badeCount > 0
            ? (
                <span className={styles.leftPaneLinkButtonBadge}>{badeCount}</span>
            )
            : null
    );

    return (
        <NavLink
            testId={testId}
            activeClassName={styles.leftPaneLinkButtonActive}
            className={styles.leftPaneLinkButton}
            to={route}
            params={params}
        >
            <span className={styles.leftPaneLinkButtonText}>{label}</span>
            <span className={styles.leftPaneLinkButtonIcon}>
                <Icon iconType={IconType.chevronRight} />
            </span>
            {renderBadge()}
        </NavLink>
    );
};

Component.propTypes = {
    badeCount: PropTypes.number,
    label:     PropTypes.string,
    params:    PropTypes.object,
    route:     PropTypes.string,
    testId:    PropTypes.string,
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default {
    contact:                     '/kontakt',
    dataProtection:              '/datenschutzerklaerung',
    designer:                    '/designer',
    designerProductDetails:      '/designer/produkt-details',
    designerProductSingleOrders: '/designer/einzelbestellungen',
    designerProductSlotDetails:  '/designer/produkt-details/slot',
    designerProjectDetails:      '/designer/project-details',
    help:                        '/hilfe',
    helpDetails:                 '/hilfe',
    home:                        '/',
    imprint:                     '/impressum',
};
// TODO: mehrspraching?

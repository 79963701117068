//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                from 'i18next';
import { withTranslation } from 'react-i18next';
import { connect }         from 'react-redux';

import { getPathForOverlayKey } from '@components/OverlayManager';
import Overlays                 from '@constants/Overlays';
import ProductCategoryType      from '@constants/ProductCategoryType';
import TestIds                  from '@constants/TestIds';

import styles         from './styles.module.scss';
import Icon           from '../Icon';
import IconType       from '../Icon/IconType';
import Link           from '../Link';
import withPortCounts from '../WithPortCounts';

const Component = ({
    activeProductCategoryType = null,
    hasPorts                  = false,
    ports                     = {},
    products                  = [],
}) => {
    if (activeProductCategoryType === ProductCategoryType.fullIp) {
        return null;
    }

    if (hasPorts) {
        return (
            <div
                data-testId={TestIds.statusBar}
                className={styles.statusBar}
            >
                <span className={styles.statusBarInner}>
                    {I18n.t('extenderPorts')}
                    {': '}
                    <span
                        data-testId={TestIds.statusBarExtenderPorts}
                        className={styles.statusBarBadge}
                    >
                        {
                            ports.extenderCpu +
                            ports.extenderCon +
                            ports.extenderMisc
                        }
                    </span>
                    <span>
                        {I18n.t('cpuPorts')}
                        {': '}
                        <span data-testId={TestIds.statusBarCpuPorts}>
                            {ports.extenderCpu}
                        </span>
                        {', '}
                        {I18n.t('conPorts')}
                        {': '}
                        <span data-testId={TestIds.statusBarConPorts}>
                            {ports.extenderCon}
                        </span>
                        {', '}
                        {I18n.t('miscPorts')}
                        {': '}
                        <span data-testId={TestIds.statusBarMiscPorts}>
                            {ports.extenderMisc}
                        </span>
                    </span>
                </span>
                <span
                    data-testId={TestIds.statusBarMatrixPorts}
                    className={styles.statusBarInner}
                >
                    {I18n.t('matrixPorts')}
                    {': '}
                    <span className={styles.statusBarBadge}>
                        {ports.matrix}
                    </span>
                </span>
                <span
                    data-testId={TestIds.statusBarDetailsButton}
                    className={styles.statusBarInner}
                >
                    <Link
                        className={styles.statusBarDetailButton}
                        to={getPathForOverlayKey(Overlays.portOverview)}
                    >
                        <span className={styles.statusBarDetailButtonIconWrapper}>
                            <Icon iconType={IconType.tooltip} />
                        </span>
                        <span className={styles.statusBarDetailButtonIconText}>
                            {I18n.t('portCountButtonText')}
                        </span>
                    </Link>
                </span>
            </div>
        );
    }

    return (
        <div className={styles.statusBar}>
            {I18n.t('statusBarEmptyText')}
        </div>
    );
};

const mapStateToProps = (state) => (
    {
        activeProductCategoryType: state.activeProject.activeProductCategoryType,
        products:                  state.activeProject.products,
    }
);

export default connect(
    mapStateToProps,
    null,
)(withTranslation()(withPortCounts(Component)));

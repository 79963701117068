//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import queryString from 'query-string';

import ApiConfiguration from '@constants/Api';

import Base64         from './Base64';
import FormPoster     from './FormPoster';
import LanguageHelper from './Language';

class PartList {
    static getCsvExportUrl = (userLanguage, notAbsolute) => {
        const language               = LanguageHelper.getBackendLanguage(userLanguage);
        const queryStringParts       = {
            language,
        };
        const queryStringPartsString = queryString.stringify(queryStringParts);
        const finalCsvExportUrl      = `${!notAbsolute ?
            ApiConfiguration.getBackendUrl() :
            ''
        }/parts-list.csv?${queryStringPartsString}`;

        return finalCsvExportUrl;
    };

    static getCsvExportForm = (activeProject, formName, userLanguage) => {
        const activeProjectJson = JSON.stringify(activeProject);
        const encodedString     = Base64.encode(activeProjectJson);
        const csvExportUrl      = this.getCsvExportUrl(userLanguage, null);
        const form              = FormPoster.createForm(
            csvExportUrl,
            'projectPayload',
            encodedString,
            formName,
        );

        return form;
    };
}

export default PartList;

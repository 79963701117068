import React from 'react';

import IconType from '../Icon/IconType';
import ProductDataValueLabel from '../ProductDataValueLabel';
import PropTypes from '../PropTypes';

const Component = ({ value }) => (
    <ProductDataValueLabel
        icon={IconType.error}
        hideIconForEmptyValue={true}
        value={value}
    />
);

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

import WarningType from '@constants/WarningType';
import Cast        from '@helper/Cast';

import ActiveProjectsFactory from '../factories/activeProjects';

class limitationLimitToAmountInChassis {
    static parseValue = (value) => {
        const parsedValue       = {
            limit:        0,
            productGroup: [],
        };
        const valueWithoutSpace = value.replace(/ /g, '');
        const explodedValue     = valueWithoutSpace.split(',');

        parsedValue.limit = Cast.int(explodedValue[0]);

        explodedValue.shift();

        parsedValue.productGroup = explodedValue;

        return parsedValue;
    };

    static updateProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        productWarnings,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        return false;
    };

    static updateSubProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        subProduct,
        subProductIndex,
        productWarnings,
        subProductWarnings,
        subProductCategory,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        if (limitation.amount === undefined) {
            return false;
        }

        const allowedNumberOfProducts = parseInt(limitation.amount, 10);
        let partsInChassis            = 0;

        for (const slot of product.subProducts.slot) {
            if (slot?.productData?.partNo === subProduct?.productData?.partNo) {
                partsInChassis++;
            }
        }

        if (partsInChassis > allowedNumberOfProducts) {
            subProductWarnings.push(ActiveProjectsFactory.getWarning(
                WarningType.error,
                I18n.t(
                    'limitationLimitToAmountInChassis',
                    {
                        allowedNumberOfProducts,
                    },
                ),
            ));

            return true;
        }

        return false;
    };
}

export default limitationLimitToAmountInChassis;

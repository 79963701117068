//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import { ActiveProjectActions } from '@slices/activeProject';
import Overlays                 from '@constants/Overlays';

const processActions = [
    ActiveProjectActions.moveSlot().type,
    ActiveProjectActions.setProductSlot().type,
];

const designerLayoutFilter = (store) => (next) => (action) => {
    let nextAllowed = true;

    if (processActions.indexOf(action.type) > -1) {
        const state          = store.getState();
        const hiddenOverlays = state.overlays.hiddenOverlays;

        if (
            !hiddenOverlays[Overlays.confirmOverwrite] &&
            !action.payload.confirmed
        ) {
            const activeProjectState = state.activeProject;
            const currentProduct     = activeProjectState.products[activeProjectState.selectedProduct.categoryType][activeProjectState.selectedProduct.index];
            const targetSlotPointer  = {
                index: null,
                type:  null,
            };
            let newProductId         = null;

            switch (action.type) {
                case ActiveProjectActions.moveSlot().type:
                    targetSlotPointer.index = action.payload.toSlotIndex;
                    targetSlotPointer.type  = action.payload.fromSlotType;

                    break;

                case ActiveProjectActions.setProductSlot().type:
                    targetSlotPointer.index = action.payload.slotIndex;
                    targetSlotPointer.type  = action.payload.targetSlotType;
                    newProductId            = action.payload.productId;

                    break;
            }

            const targetSlot = currentProduct.subProducts[targetSlotPointer.type][targetSlotPointer.index];

            if (targetSlot.productData) {
                const isSameProduct = (
                    newProductId &&
                    targetSlot.productData.partNo === newProductId
                );

                if (!isSameProduct) {
                    const newAction = {
                        type:    ActiveProjectActions.confirmOverwriteSlot().type,
                        payload: {
                            actionPayload: _.cloneDeep(action.payload),
                            actionType:    action.type,
                        },
                    };

                    next(newAction);
                }

                nextAllowed = false;
            }
        }
    }

    if (nextAllowed) {
        next(action);
    }
};

export default designerLayoutFilter;

import React from 'react';

import ProductCategoryType from '@constants/ProductCategoryType';
import ProductSlotType from '@constants/ProductSlotType';
import ProductSubCategoryTypes from '@constants/ProductSubCategoryType';
import DataProvider from '@helper/DataProvider';

import PropTypes from '../PropTypes';
import Table from '../Table';
import DefaultColumns from '../Table/DefaultColumns';

const Component = ({ rejectProdcutTypeList = DataProvider.defaultProductTypeRejectList }) => {
    const getColumnConfiguration = () => {
        const defaultColumns = DefaultColumns.get(
            ProductCategoryType.extender,
            ProductSubCategoryTypes.vario,
        );

        return [
            defaultColumns.displayPartNumber,
            defaultColumns.powerConsumptionInMilliamps,
            defaultColumns.description,
        ];
    };

    return (
        <Table
            columnConfiguration={getColumnConfiguration()}
            productCategoryType={ProductCategoryType.extender}
            productSlotType={ProductSlotType.chassisAccessory}
            rawData={DataProvider.getExtenderChassisAccessories(rejectProdcutTypeList)}
        />
    );
};

Component.propTypes = {
    rejectProdcutTypeList: PropTypes.arrayOf(PropTypes.string),
};

export default Component;

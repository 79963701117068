//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

class ImageDownloader {
    static downloadImageByURL = (imageURL, fileName) => {
        const xmlHttpRequest = new XMLHttpRequest();

        xmlHttpRequest.open(
            'GET',
            imageURL,
            true,
        );

        xmlHttpRequest.responseType = 'blob';
        xmlHttpRequest.onload       = function() {
            const urlCreator = (
                window.URL ||
                window.webkitURL
            );
            const imageURL   = urlCreator.createObjectURL(this.response);

            ImageDownloader.createLinkAndClickOnIt(
                imageURL,
                fileName,
            );
        };

        xmlHttpRequest.send();
    };

    static createLinkAndClickOnIt = (imageURL, fileName) => {
        const tag      = document.createElement('a');
        tag.href       = imageURL;
        tag.download   = fileName;
        const { body } = document;

        body.appendChild(tag);

        tag.click();

        body.removeChild(tag);
    };
}

export default ImageDownloader;

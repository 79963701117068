//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

import Languages from '@constants/Languages';

import { getEnabledLanguageKeys } from './Configuration';

class Language {
    static andString(values) {
        return this.listString(values, I18n.t('and'));
    }

    static getBackendLanguage = (userLanguage) => {
        if (userLanguage === Languages.german) {
            return 'de';
        }

        return 'en';
    };

    static getDefaultLanguage() {
        const browserLanguage = navigator.language || navigator.userLanguage;
        const defaultLanguage = browserLanguage.startsWith('de')
            ? Languages.german
            : Languages.english;

        return defaultLanguage;
    }

    static getConfigDefaultLanguage() {
        const defaultLanguage  = this.getDefaultLanguage();
        const enabledLanguages = getEnabledLanguageKeys();

        if (enabledLanguages.includes(defaultLanguage)) {
            return defaultLanguage;
        }

        return Languages.english;
    }

    static listString(values, separator) {
        if (values.length > 1) {
            const stringData = [...values];
            const lastItem   = stringData.pop();
            const listString = [
                stringData.join(', '),
                separator,
                lastItem,
            ].join(' ');

            return listString;
        }

        return values[0];
    }

    static orString(values) {
        return this.listString(values, I18n.t('or'));
    }
}

export default Language;

import React     from 'react';

import _         from 'lodash';
import { Tabs }  from 'react-tabs';

import styles from './styles.module.scss';
import PropTypes from '../PropTypes';

const Component = ({
    children,
    onSelect,
    selectedIndex,
}) => (
    <Tabs
        className={styles.tabs}
        selectedIndex={selectedIndex}
        onSelect={onSelect}
    >
        {children}
    </Tabs>
);

Component.propTypes = {
    children:      PropTypes.children,
    onSelect:      PropTypes.func,
    selectedIndex: PropTypes.number,
};

Component.defaultProps = {
    children:      [],
    onSelect:      _.noop,
    selectedIndex: -1,
};

export default Component;

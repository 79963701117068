//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { isFirefox } from 'react-device-detect';

// It seems to be the better way to just submit the form without opening any window
// This has the same effect but the popup blocker does not prevent/block the window.

// @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-826
const forceOpenWindow = false;

class FormPoster {
    static createForm(url, fieldName, fieldValue, formName) {
        const form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', url);
        form.setAttribute('target', formName);

        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', fieldName);
        hiddenField.setAttribute('value', fieldValue);

        form.appendChild(hiddenField);

        document.body.appendChild(form);

        return form;
    }

    static openFormWindowPost(url, fieldName, fieldValue) {
        const formName = 'FormPosterOpenFormWindow';
        const form     = this.createForm(
            url,
            fieldName,
            fieldValue,
        );

        let newWindow = null;

        if (forceOpenWindow) {
            newWindow = window.open('', formName);
        }

        const openFormWindowPostInternal = () => {
            this.submitAndClean(form);

            if (forceOpenWindow) {
                newWindow.close();
            }
        };

        if (isFirefox) {
            // This is a workaround for firefox that needs us to wait a bit to make sure the tab is "completely opened"
            // before we post a form to the tab
            //
            // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-719
            window.setTimeout(openFormWindowPostInternal, 10);
        } else {
            openFormWindowPostInternal();
        }
    }

    static submitAndClean(form) {
        form.submit();

        document.body.removeChild(form);
    }
}

export default FormPoster;

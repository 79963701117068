/**
 * @see also DeviceTypeShortcuts
 */
const DeviceTypes = {
    conUnit: 'conUnit', // Remote (R)
    cpuUnit: 'cpuUnit', // Local (L)
    kit:     'kit',     // Kit (K)
};

module.exports = DeviceTypes;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames             from 'classnames';
import I18n                   from 'i18next';
import _                      from 'lodash';
import { DropTarget }         from 'react-dnd';
import { DragSource }         from 'react-dnd';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { compose }            from 'redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions }  from '@slices/activeProject';
import { DragDropActions }       from '@slices/dragDrop';
import AccessoryTypes            from '@constants/AccessoryTypes';
import DragTypes                 from '@constants/DragTypes';
import DropValidation            from '@constants/DropValidation';
import ProductCategoryType       from '@constants/ProductCategoryType';
import RenderModes               from '@constants/RenderModes';
import SlotType                  from '@constants/SlotType';
import TestIds                   from '@constants/TestIds';
import DataProvider              from '@helper/DataProvider';
import SelectionHelper           from '@helper/SelectionHelper';
import Slot                      from '@helper/Slot';
import StateHelper               from '@helper/State';
import { DesignerLayoutActions } from '@slices/designerLayout';

import styles                 from './styles.module.scss';
import getDropValidation      from '../../dragDrop/getDropValidation';
import dragDropConfiguration  from '../../dragDrop/slot';
import DoubleSlotDropZone     from '../DoubleSlotDropZone';
import Icon                   from '../Icon';
import IconType               from '../Icon/IconType';
import ProductQuickSelect     from '../ProductQuickSelect';
import ProductQuickSelectMode from '../ProductQuickSelect/ProductQuickSelectMode';
import PropTypes              from '../PropTypes';
import slot                   from '../Slot';
import TextDropZone           from '../TextDropZone';
import CornerStyle            from '../TextDropZone/CornerStyle';
import WarningList            from '../WarningList';
import WarningListTheme       from '../WarningList/WarningListTheme';

class Component extends React.Component {
    deleteButtonPressed = (event) => {
        this.props.deleteSlot({
            slotIndex: this.props.index,
            slotType:  this.props.type,
        });

        // Stop the button to select the slot (since this would cancel the delete operation)
        event.stopPropagation();
    };

    getMainClassName = () => {
        return SelectionHelper.get(
            this.props.type,
            {
                [SlotType.accessory]:       styles.productSlotListSlotTypeAccessory,
                [SlotType.fan]:             styles.productSlotListSlotTypeFan,
                [SlotType.powerSupplyUnit]: styles.productSlotListSlotTypePowerSupplyUnit,
                [SlotType.slot]:            (
                    this.props.isCpu ?
                        styles.productSlotListSlotTypeCpu :
                        styles.productSlotListSlotTypeDefault
                ),
            },
        );
    };

    getProductQuickSelectProducts = () => {
        const products                    = DataProvider.staticGetSlotProductsForProductCategoryTypeAndSlotType(
            this.props.selectedProductMeta.categoryType,
            this.props.type,
            _.get(this.props, 'selectedProduct.productData.subCategoryType', null),
        );
        const filteredProducts            = DataProvider.getCompatibleProducts(
            products,
            false,
            true,
            true,
            this.props.selectedProduct,
            null,
            true,
        );
        const productsAfterDropValidation = [];

        for (const product of filteredProducts) {
            const propType             = this.props.type;
            const productType          = product.type;
            const isFan                = (
                propType === SlotType.fan &&
                productType === AccessoryTypes.fan
            );
            const isAccessory          = (
                propType === SlotType.accessory &&
                productType === AccessoryTypes.accessory
            );
            const isPowerSupplyUnit    = (
                propType === SlotType.powerSupplyUnit &&
                productType === AccessoryTypes.powerSupplyUnit
            );
            const hasSupportsPiggyBack = _.has(product, 'supportsPiggyBack');
            const isNothingElse        = (
                propType !== SlotType.fan &&
                productType !== AccessoryTypes.fan &&
                propType !== SlotType.accessory &&
                productType !== AccessoryTypes.accessory &&
                propType !== SlotType.powerSupplyUnit &&
                productType !== AccessoryTypes.powerSupplyUnit
            );

            if (
                isFan ||
                isAccessory ||
                isPowerSupplyUnit ||
                isNothingElse ||
                hasSupportsPiggyBack
            ) {
                const dropValidation = getDropValidation(
                    {
                        productId: product.partNo,
                    },
                    {
                        index:           this.props.index,
                        selectedProduct: this.props.selectedProduct,
                        type:            this.props.type,
                    },
                    0,
                );

                if (dropValidation === DropValidation.allowed) {
                    productsAfterDropValidation.push(product);
                }
            }
        }

        return productsAfterDropValidation;
    };

    getSlotNumber = () => {
        return SelectionHelper.get(
            this.props.type,
            {
                [SlotType.accessory]:       this.getSlotNumberAccessory(),
                [SlotType.fan]:             this.getSlotNumberFan(),
                [SlotType.powerSupplyUnit]: this.getSlotNumberPowerSupplyUnit(),
                [SlotType.slot]:            this.getSlotNumberSlot(),
            },
        );
    };

    getSlotNumberAccessory = () => {
        return I18n.t('slotTypeAccessoryShort');
    };

    getSlotNumberFan = () => {
        return (
            I18n.t('slotTypeFanShort') + (
                this.props.totalCount > 1 ?
                    this.props.index + 1 :
                    ''
            )
        );
    };

    getSlotNumberPowerSupplyUnit = () => {
        return (
            I18n.t('slotTypePowerSupplyUnitShort') + (
                this.props.totalCount > 1 ?
                    this.props.index + 1 :
                    ''
            )
        );
    };

    getSlotNumberSlot = () => {
        const jsonIndex          = this.props.index + 1;
        const productData        = _.get(this, 'props.selectedProduct.productData');
        const layoutDefinition   = _.get(productData, 'layoutDefinition');
        const layoutDefinitionIo = _.get(layoutDefinition, 'io');
        const uniDefinition      = _.get(this, 'props.selectedProduct.productData.uniDefinition');
        const beforeComparer     = (slot) => slot < jsonIndex;

        if (this.props.isCpu) {
            const cpuIndex = Slot.getVisibleCpuSlotIndex(jsonIndex, layoutDefinition, productData);

            return I18n.t('slotTypeCpuShort') + cpuIndex;
        }

        // We got a chassis with only sfp slots
        if (uniDefinition) {
            return `${this.props.index + 1
            }.`;
        }

        if (layoutDefinitionIo) {
            const ioSlotsBefore = _.filter(layoutDefinitionIo, beforeComparer);

            return `${ioSlotsBefore.length + 1
            }.`;
        }

        return (
            `${this.props.index +
            (
                this.props.isDoubleSlot ?
                    0 :
                    1
            )

            }.${

                this.props.isDoubleSlot ?
                    (
                        `\n+\n${

                            this.props.index + 1

                        }.`
                    )
                    : ''}`
        );
    };

    hasWarnings = () => {
        return _.get(this, 'props.data.warnings', []).length > 0;
    };

    productQuickSelectChanged = (option) => {
        this.props.setProductSlot({
            confirmed:      true,
            isBulk:         false,
            productId:      option.value,
            slotIndex:      this.props.index,
            targetSlotType: this.props.type,
        });
    };

    render() {
        if (!this.props.isUnused) {
            const hasProduct = this.hasProduct();
            const isSelected = this.isSelected();
            let baseObject   = (
                <div
                    data-testId={TestIds.productSlotListSlot}
                    className={classNames(
                        styles.productSlotListSlot,
                        (
                            hasProduct ?
                                styles.productSlotListSlotWithItem :
                                null
                        ),
                        (
                            isSelected ?
                                styles.productSlotListSlotSelected :
                                null
                        ),
                        this.getMainClassName(),
                    )}
                    onClick={this.props.slotClicked}
                >
                    {
                        this.props.isLocked ?
                            this.renderLock() :
                            this.renderDeleteButton()
                    }
                    <div
                        className={classNames(
                            styles.productSlotListSlotWrapper,
                            (
                                this.hasWarnings() ?
                                    styles.productSlotListSlotWrapperWithWarning :
                                    null
                            ),
                        )}
                    >
                        {this.renderDropZones()}
                        <div
                            className={classNames(styles.productSlotListSlotContent)}
                        >
                            <div className={styles.productSlotListSlotNumber}>
                                {this.getSlotNumber()}
                            </div>
                            {this.renderTitle()}
                        </div>
                    </div>
                    {this.renderWarnings()}
                </div>
            );

            // This should be removed
            // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-441
            if (
                !this.props.isLocked &&
                !this.props.isDoubleSlot &&
                this.props.dropValidation === DropValidation.allowed
            ) {
                baseObject = this.props.connectDropTarget(baseObject);
            }

            if (hasProduct && this.props.dragSourceEnabled) {
                return this.props.connectDragSource(
                    baseObject,
                    this.props.getDragOptions(this.props.isLocked),
                );
            }

            return baseObject;
        }

        return null;
    }

    hasProduct = () => {
        const hasProduct = this.props.hasProduct
            ? this.props.hasProduct()
            : false;

        return hasProduct;
    };

    isSelected = () => {
        const isSelected = this.props.isSelected
            ? this.props.isSelected()
            : false;

        return isSelected;
    };

    renderDeleteButton = () => {
        const hasProduct = this.hasProduct();

        if (hasProduct) {
            return (
                <span
                    className={styles.productSlotDeleteButton}
                    onClick={this.deleteButtonPressed}
                >
                    <Icon iconType={IconType.trashBin} />
                </span>
            );
        }

        return null;
    };

    renderLock = () => {
        return (
            <span
                className={styles.productSlotLock}
            >
                <Icon iconType={IconType.lock} />
            </span>
        );
    };

    renderDefaultDropZone = () => {
        return (
            <div
                className={classNames(styles.productSlotListSlotDefaultSlotDropZoneWrapper)}
            >
                <TextDropZone
                    canDrop={this.props.canDrop}
                    cornerStyle={CornerStyle.square}
                    dropValidation={this.props.dropValidation}
                    isOver={this.props.isOver}
                />
            </div>
        );
    };

    renderDoubleSlotDropZone = () => {
        if (this.props.isDoubleSlot) {
            return [
                <div
                    className={classNames(
                        styles.productSlotListSlotDoubleSlotDropZoneWrapper,
                        styles.productSlotListSlotDoubleSlotDropZoneWrapperTop,
                    )}
                    key={'dropZoneTop'}
                >
                    <DoubleSlotDropZone
                        accessoryType={this.props.accessoryType}
                        data={this.props.data}
                        dropValidationForward={this.props.dropValidationPrevious}
                        index={this.props.index - 1}
                        isLocked={this.props.isLocked}
                        type={this.props.type}
                    />
                </div>,
                <div
                    className={classNames(
                        styles.productSlotListSlotDoubleSlotDropZoneWrapper,
                        styles.productSlotListSlotDoubleSlotDropZoneWrapperBottom,
                    )}
                    key={'dropZoneBottom'}
                >
                    <DoubleSlotDropZone
                        accessoryType={this.props.accessoryType}
                        data={this.props.data}
                        dropValidationForward={this.props.dropValidation}
                        index={this.props.index}
                        isLocked={this.props.isLocked}
                        type={this.props.type}
                    />
                </div>,
            ];
        }

        return null;
    };

    renderDropZones = () => {
        if (this.props.isDoubleSlot) {
            return this.renderDoubleSlotDropZone();
        }

        return this.renderDefaultDropZone();
    };

    renderExtenderId = () => {
        if (this.props.data.extenderId) {
            return (
                <span
                    className={styles.productSlotListSlotExtenderId}
                    title={I18n.t('extenderId')}
                >
                    {this.props.data.extenderId}
                </span>
            );
        }

        return null;
    };

    renderWarnings = () => {
        const warnings = _.get(this, 'props.data.warnings');

        return (
            <ul className={styles.productSlotListSlotErrorContainer}>
                <WarningList
                    theme={WarningListTheme.orange}
                    warnings={warnings}
                />
            </ul>
        );
    };

    renderTitle = () => {
        const hasProduct = this.hasProduct();

        return (
            <div
                className={classNames(
                    styles.productSlotListSlotTitleWrapper,
                    {
                        [styles.productSlotListSlotTitleWrapperHasTitle]: hasProduct,
                    },
                )}
            >
                {
                    hasProduct ?
                        this.renderTitleWithProduct() :
                        this.renderTitleEmpty()
                }
            </div>
        );
    };

    getProductQuickSelectPlaceholderKey = () => {
        const categoryType = _.get(this.props.productData, 'categoryType');

        if (categoryType === ProductCategoryType.fullIp) {
            return I18n.t('enterOptionNumber');
        }

        return I18n.t('enterProductNumber');
    };

    renderTitleEmpty = () => {
        return (
            <span className={styles.productSlotListSlotTitleEmpty}>
                <ProductQuickSelect
                    onChange={this.productQuickSelectChanged}
                    mode={ProductQuickSelectMode.full}
                    getProducts={this.getProductQuickSelectProducts}
                    placeholderKey={this.getProductQuickSelectPlaceholderKey()}
                />
            </span>
        );
    };

    renderTitleWithProduct = () => {
        const renderMode        = _.get(this.props.data.productData, 'renderMode');
        const displayPartNumber = (
            renderMode === RenderModes.default ?
                this.props.data.productData.displayPartNumber :
                I18n.t('immutableIOBoard')
        );
        const slotDescription   = (
            this.props.data.customName ?
                this.props.data.customName :
                this.props.data.productData[I18n.t('descriptionKey')]
        );

        return [
            (
                slotDescription
                    ? (
                        <span
                            className={classNames(
                                styles.productSlotListSlotTitle,
                                (
                                    this.props.data.customName ?
                                        styles.productSlotListSlotTitleWithCustomText :
                                        null
                                ),
                            )}
                            key={'name'}
                        >
                            {slotDescription}
                        </span>
                    )
                    : null
            ),
            <span
                className={styles.productSlotListSlotTitleSubWrapper}
                key={'displayPartNumberWrapper'}
            >
                <span className={styles.productSlotListSlotTitleSub}>
                    {displayPartNumber}
                </span>
                {this.renderExtenderId()}
            </span>,
        ];
    };
}

Component.propTypes = {
    accessoryType:     PropTypes.oneOf(Object.values(AccessoryTypes)),
    data:              PropTypes.object,
    dragSourceEnabled: PropTypes.bool,
    getDragOptions:    PropTypes.func,
    hasProduct:        PropTypes.func,
    index:             PropTypes.number,
    isCpu:             PropTypes.bool,
    isDoubleSlot:      PropTypes.bool,
    isLocked:          PropTypes.bool,
    isSelected:        PropTypes.func,
    isUnused:          PropTypes.bool,
    productData:       PropTypes.object,
    slotClicked:       PropTypes.func,
    totalCount:        PropTypes.number,
    type:              PropTypes.oneOf(Object.values(SlotType)),
};

Component.defaultProps = {
    accessoryType:     null,
    data:              {},
    dragSourceEnabled: true,
    getDragOptions:    _.noop,
    hasProduct:        _.noop,
    index:             -1,
    isCpu:             false,
    isDoubleSlot:      false,
    isLocked:          false,
    isSelected:        _.noop,
    isUnused:          false,
    productData:       {},
    slotClicked:       _.noop,
    totalCount:        0,
    type:              SlotType.slot,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(
    ActiveProjectActions,
    DesignerLayoutActions,
    DragDropActions,
), dispatch);

const mapStateToProps = (state) => (
    {
        selectedProduct:     StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
        selectedProductMeta: state.activeProject.selectedProduct,
        selectedSlot:        state.activeProject.selectedSlot,
    }
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    DropTarget(
        [
            DragTypes.add,
            DragTypes.sort,
        ],
        dragDropConfiguration.dropBehavior,
        dragDropConfiguration.dropCollect,
    ),
    DragSource(
        DragTypes.sort,
        dragDropConfiguration.dragBehavior,
        dragDropConfiguration.dragCollect,
    ),
)(slot(withTranslation()(Component)));

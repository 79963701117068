//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

import WarningType from '@constants/WarningType';
import Cast        from '@helper/Cast';
import Language    from '@helper/Language';

import ActiveProjectsFactory from '../factories/activeProjects';

class limitationLimitToAmountOfGroupInChassis {
    static parseValue = (value) => {
        const parsedValue       = {
            limit:        0,
            productGroup: [],
        };
        const valueWithoutSpace = value.replace(/ /g, '');
        const explodedValue     = valueWithoutSpace.split(',');

        parsedValue.limit = Cast.int(explodedValue[0]);

        explodedValue.shift();

        parsedValue.productGroup = explodedValue;

        return parsedValue;
    };

    static updateProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        productWarnings,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        return false;
    };

    static updateSubProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        subProduct,
        subProductIndex,
        productWarnings,
        subProductWarnings,
        subProductCategory,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        if (limitation.partNo === product.productData.partNo) {
            let groupCount                = 0;
            const { limit, productGroup } = this.parseValue(limitation.value);

            for (const slot of product.subProducts.slot) {
                if (slot.productData && productGroup.indexOf(slot.productData.partNo) > -1) {
                    ++groupCount;
                }
            }

            if (groupCount > limit) {
                const productGroupString = Language.andString(productGroup);

                subProductWarnings.push(ActiveProjectsFactory.getWarning(
                    WarningType.error,
                    I18n.t(
                        'limitationLimitToAmountOfGroupInChassis',
                        {
                            productNumbers: productGroupString,
                            limit,
                        },
                    ),
                ));
            }
        }

        return false;
    };
}

export default limitationLimitToAmountOfGroupInChassis;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { Scrollbars } from 'react-custom-scrollbars';

import styles    from './styles.module.scss';
import PropTypes from '../PropTypes';

class PrettyScrollBar extends React.Component {
    render() {
        return (
            <Scrollbars
                autoHide={false}
                hideTracksWhenNotNeeded={true}
                renderThumbVertical={this.renderThumbVertical}
                style={{
                    right: 0,
                }}
            >
                {this.props.children}
            </Scrollbars>
        );
    }

    renderThumbVertical = (props) => {
        return (
            <div
                {...props}
                className={styles.thumbVertical}
            />
        );
    };
}

const Component = PrettyScrollBar;

Component.propTypes = {
    children: PropTypes.children,
};

Component.defaultProps = {
    children: [],
};

Component.renderAffectingProps = Object.keys(Component.propTypes);

Component.renderAffectingStates = [];

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import _ from 'lodash';

import styles    from './styles.module.scss';
import Icon      from '../Icon';
import IconType  from '../Icon/IconType';
import Link      from '../Link';
import PropTypes from '../PropTypes';

const Component = ({
    icon            = null,
    onClick         = _.noop,
    route           = '',
    routeParameters = {},
    text            = '',
    testId          = '',
}) => {
    const renderButton        = () => {
        return (
            <div className={styles.buttonBarButtonInner}>
                <div className={styles.buttonBarButtonInnerIconWrapper}>
                    <Icon iconType={icon} />
                </div>
                <span className={styles.buttonBarButtonText}>{text}</span>
            </div>
        );
    };
    const renderButtonWrapper = () => {
        if (route) {
            return (
                <Link
                    data-testId={testId}
                    role={'button'}
                    to={route}
                    params={routeParameters}
                >
                    {renderButton()}
                </Link>
            );
        }
        return (
            <div
                data-testId={testId}
                role={'button'}
                className={styles.buttonBarButtonClickWrapper}
                onClick={onClick}
            >
                {renderButton()}
            </div>
        );
    };

    return (
        <div className={styles.buttonBarButton}>{renderButtonWrapper()}</div>
    );
};

Component.propTypes = {
    icon:            PropTypes.oneOf(Object.values(IconType)),
    onClick:         PropTypes.func,
    route:           PropTypes.string,
    routeParameters: PropTypes.object,
    testId:          PropTypes.string,
    text:            PropTypes.string,
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { create }       from 'apisauce';

import ApiConfiguration from '@constants/Api';

const createInstance = (host, apiPath) => {
    let api = null;

    api = create({
        baseURL: `${host}${apiPath}`,
        timeout: 30 * 1000,
        headers: {
            'Content-Type': 'application/json',
            'accept':       'application/json',
        },
    });

    api.host = host;

    return api;
};

const Api = createInstance(ApiConfiguration.getBackendUrl(), '/');

const postProjectConfiguration = (
    firstName,
    surName,
    companyName,
    eMailAddress,
    phoneNumber,
    ihseProjectNumber,
    extendedWarranty,
    serviceLevelAgreement,
    spareParts,
    projectConfiguration,
    language,
    offerNumber,
    comment,
) => {
    return Api.post(
        'api/project-exports',
        {
            firstName,
            surName,
            companyName,
            eMailAddress,
            phoneNumber,
            ihseProjectNumber,
            projectConfiguration,
            extendedWarranty,
            serviceLevelAgreement,
            spareParts,
            language,
            offerNumber,
            comment,
        },
    );
};

export {
    Api,
    postProjectConfiguration,
};

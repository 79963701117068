//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

import Limitations from '@constants/Limitations';
import WarningType from '@constants/WarningType';
import Cast        from '@helper/Cast';

import ActiveProjectsFactory from '../factories/activeProjects';

class limitationLimitToSlotInChassisCalculator {
    static getAllowedSlotIndexes = (product, subProduct) => {
        const slotIndexes = [];

        if (subProduct.productData) {
            for (const limitation of subProduct.productData.limitations) {
                if (
                    limitation.type === Limitations.limitToSlotInChassis &&
                    limitation.partNo === product.productData.partNo
                ) {
                    slotIndexes.push(Cast.int(limitation.value));
                }
            }
        }

        return slotIndexes;
    };

    static updateProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        productWarnings,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        return false;
    };

    static updateSubProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        subProduct,
        subProductIndex,
        productWarnings,
        subProductWarnings,
        subProductCategory,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        if (limitation.partNo === product.productData.partNo) {
            const allowedSlotIndexes = this.getAllowedSlotIndexes(product, subProduct);

            if (allowedSlotIndexes.indexOf(subProductIndex + 1) === -1) {
                const allowedSlotIndexesString = allowedSlotIndexes.join(', ');

                subProductWarnings.push(ActiveProjectsFactory.getWarning(
                    WarningType.error,
                    I18n.t('limitationLimitToSlotInChassis', {
                        allowedSlotIndexes: allowedSlotIndexesString,
                    }),
                ));

                return true;
            }
        }

        return false;
    };
}

export default limitationLimitToSlotInChassisCalculator;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { OverlayActions }         from '@slices/overlays';
import { SettingActions }         from '@slices/settings';
import CardOverlayModes           from '@constants/CardOverlayModes';
import Overlays                   from '@constants/Overlays';
import { getEnabledLanguageKeys } from '@helper/Configuration';

import CheckBox           from '../CheckBox';
import Overlay            from '../Overlay';
import OverlayType        from '../Overlay/OverlayType';
import OverlayPane        from '../OverlayPane';
import SegmentControl     from '../SegmentControl';
import SegmentControlMode from '../SegmentControl/SegmentControlMode';
import Spacer             from '../Spacer';
import Switch             from '../Switch';
import TextButton         from '../TextButton';
import TextButtonTheme    from '../TextButton/TextButtonTheme';
import VersionNumber      from '../VersionNumber';

class Component extends React.Component {
    cardOverlayModeChanged = (segmentIndex, segment) => {
        this.props.setCardOverlayMode({
            cardOverlayMode: segment.key,
        });
    };

    cardOverlayRenderExtenderIdCheckBoxValueChanged = () => {
        this.props.toggleCardOverlayRenderExtenderId();
    };

    enableSingleOrdersChanged = (checked) => {
        this.props.setSingleOrdersEnabled({
            enabled: checked,
        });
    };

    getCardOverlayModes = () => {
        const cardOverlayModes    = [];
        const cardOverlayModeKeys = Object.values(CardOverlayModes);

        for (const cardOverlayModeKey of cardOverlayModeKeys) {
            cardOverlayModes.push({
                key:   cardOverlayModeKey,
                title: I18n.t(`cardOverlayMode${_.startCase(cardOverlayModeKey).replace(/ /g, '')}`),
            });
        }

        return cardOverlayModes;
    };

    getLanguages = () => {
        const languages    = [];
        const languageKeys = getEnabledLanguageKeys();

        for (const languageKey of languageKeys) {
            languages.push({
                key:   languageKey,
                title: I18n.t(`language${_.startCase(languageKey)}`),
            });
        }

        return languages;
    };

    languageChanged = (segmentIndex, segment) => {
        this.props.setLanguage({
            language: segment.key,
        });
    };

    offerNumberFieldVisibilityChanged = (checked) => {
        this.props.setOfferFieldVisibility({
            visible: checked,
        });
    };

    endOfLifeProductsVisibilityChanged = (checked) => {
        this.props.setEndOfLifeProductsVisibility({
            visible: checked,
        });
    };

    groupSimilarProductsChanged = (checked) => {
        this.props.setGroupSimilarProducts({
            groupSimilarProducts: checked,
        });
    };

    otherCommentFieldVisibilityChanged = (checked) => {
        this.props.setOtherCommentFieldVisibility({
            visible: checked,
        });
    };

    resetDialogsButtonPressed = () => {
        this.props.resetOverlayVisibilities();
    };

    render() {
        return (
            <Overlay
                id={Overlays.settings}
                overlayType={OverlayType.dialog}
                title={I18n.t('settings')}
            >
                {this.renderSelectLanguagePane()}
                {this.renderCardOverlayModePane()}
                {/*
                    @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-478
                    {this.renderEnableSingleOrdersPane()}
                */}
                {this.renderShowOtherCommentFieldPane()}
                {this.renderShowOfferNumberFieldPane()}
                {this.renderEndOfLifeProductsVisibilityFieldPane()}
                {this.renderGroupSimilarProductsPane()}
                {this.renderResetDialogsTextsPane()}
                {this.renderVersionNumber()}
            </Overlay>
        );
    }

    renderEnableSingleOrdersPane = () => {
        return (
            <OverlayPane
                text={I18n.t('enableSingleOrdersText')}
                tooltipText={I18n.t('enableSingleOrdersTooltipText')}
            >
                <Switch
                    checked={this.props.settings.singleOrdersEnabled}
                    onChange={this.enableSingleOrdersChanged}
                />
            </OverlayPane>
        );
    };

    renderResetDialogsTextsPane = () => {
        return (
            <OverlayPane
                text={I18n.t('resetDialogsText')}
                tooltipText={I18n.t('resetDialogsTooltip')}
            >
                <TextButton
                    onClick={this.resetDialogsButtonPressed}
                    text={I18n.t('resetDialogsButtonText')}
                    theme={TextButtonTheme.orange}
                />
            </OverlayPane>
        );
    };

    renderCardOverlayModePane = () => {
        const cardOverlayModes = this.getCardOverlayModes();

        return (
            <OverlayPane
                text={I18n.t('cardOverlay')}
                tooltipText={I18n.t('cardOverlayTooltipText')}
            >
                <SegmentControl
                    mode={SegmentControlMode.select}
                    segments={cardOverlayModes}
                    segmentClicked={this.cardOverlayModeChanged}
                    selectedKey={this.props.settings.cardOverlayMode || CardOverlayModes.none}
                />
                <Spacer height={20} />
                <CheckBox
                    checked={this.props.settings.cardOverlayModeRenderExtenderId}
                    noMarginBottom={true}
                    text={I18n.t('cardOverlayRenderExtenderId')}
                    valueChanged={this.cardOverlayRenderExtenderIdCheckBoxValueChanged}
                />
            </OverlayPane>
        );
    };

    renderSelectLanguagePane = () => {
        const languages = this.getLanguages();

        return (
            <OverlayPane text={I18n.t('language')}>
                <SegmentControl
                    mode={SegmentControlMode.select}
                    segments={languages}
                    segmentClicked={this.languageChanged}
                    selectedKey={this.props.settings.language}
                />
            </OverlayPane>
        );
    };

    renderShowOfferNumberFieldPane = () => {
        return (
            <OverlayPane
                text={I18n.t('showOfferNumberFieldText')}
                tooltipText={I18n.t('showOfferNumberFieldTooltipText')}
            >
                <Switch
                    checked={this.props.settings.offerFieldVisible}
                    onChange={this.offerNumberFieldVisibilityChanged}
                />
            </OverlayPane>
        );
    };

    renderGroupSimilarProductsPane = () => {
        return (
            <OverlayPane
                text={I18n.t('groupSimilarProductsText')}
                tooltipText={I18n.t('groupSimilarProductsTooltipText')}
            >
                <Switch
                    checked={this.props.settings.groupSimilarProducts}
                    onChange={this.groupSimilarProductsChanged}
                />
            </OverlayPane>
        );
    };

    renderEndOfLifeProductsVisibilityFieldPane = () => {
        return (
            <OverlayPane
                text={I18n.t('showEndOfLifeProductsFieldText')}
                tooltipText={I18n.t('showEndOfLifeProductsFieldTooltipText')}
            >
                <Switch
                    checked={this.props.settings.endOfLifeProductsVisible}
                    onChange={this.endOfLifeProductsVisibilityChanged}
                />
            </OverlayPane>
        );
    };

    renderShowOtherCommentFieldPane = () => {
        return (
            <OverlayPane
                text={I18n.t('showOtherCommentFieldText')}
                tooltipText={I18n.t('showOtherCommentFieldTooltipText')}
            >
                <Switch
                    checked={this.props.settings.otherCommentFieldVisible}
                    onChange={this.otherCommentFieldVisibilityChanged}
                />
            </OverlayPane>
        );
    };

    renderVersionNumber = () => {
        return (
            <OverlayPane
                text={I18n.t('version')}
            >
                <VersionNumber />
            </OverlayPane>
        );
    };
}

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => (
    {
        settings: state.settings,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(
    OverlayActions,
    SettingActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

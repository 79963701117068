//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import React from 'react';

import classNames             from 'classnames';
import _                      from 'lodash';
import { DropTarget }         from 'react-dnd';
import { connect }            from 'react-redux';
import ReactTooltip           from 'react-tooltip';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import { DragDropActions } from '@slices/dragDrop';
import { SmartActions }    from '@slices/smart';
import DragTypes           from '@constants/DragTypes';
import ProductCategoryType      from '@constants/ProductCategoryType';
import ProductSlotType          from '@constants/ProductSlotType';
import Routes                   from '@constants/Routes';
import SlotType                 from '@constants/SlotType';
import Cast                     from '@helper/Cast';
import DataProvider             from '@helper/DataProvider';
import Product                  from '@helper/Product';
import SelectionHelper          from '@helper/SelectionHelper';
import Slot                     from '@helper/Slot';
import StateHelper              from '@helper/State';
import ActiveProjectsFactory    from '@store/factories/activeProjects';

import ExtenderSlotLeftBottomToTopRightBoundsCalculator        from './BoundsCalculators/ExtenderSlotLeftBottomToTopRight';
import MatrixPowerSupplyLeftBottomToTopToRightCalculator       from './BoundsCalculators/MatrixPowerSupplyLeftBottomToTopToRightCalculator';
import MatrixPowerSupplyLeftTopToRightBottomBoundsCalculator   from './BoundsCalculators/MatrixPowerSupplyLeftTopToRightBottom';
import MatrixPowerSupplyTopRightToBottomToLeftBoundsCalculator from './BoundsCalculators/MatrixPowerSupplyTopRightToBottomToLeft';
import MatrixSlotLeftBottomToTopToRightCalculator              from './BoundsCalculators/MatrixSlotLeftBottomToTopToRightCalculator';
import MatrixSlotLeftTopToRightBottomBoundsCalculator          from './BoundsCalculators/MatrixSlotLeftTopToRightBottom';
import MatrixSlotTopRightToBottomToLeftBoundsCalculator        from './BoundsCalculators/MatrixSlotTopRightToBottomToLeft';
import PowerSupplyUnitLayout                                   from './PowerSupplyUnitLayout';
import SlotLayout                                              from './SlotLayout';
import styles                                                  from './styles.module.scss';
import dragDropConfiguration                                   from '../../dragDrop/product';
import ProductImageRenderer                                    from '../ProductImageRenderer';
import ProductImageRendererMode                                from '../ProductImageRenderer/ProductImageRendererMode';
import PropTypes                                               from '../PropTypes';
import SelectedProductSlotImageRenderer                        from '../SelectedProductSlotImageRenderer';
import TextDropZone                                            from '../TextDropZone';
import CornerStyle                                             from '../TextDropZone/CornerStyle';

class Component extends React.Component {
    chassisClicked = () => {
        if (this.props.pathname === Routes.designer) {
            this.props.selectProduct({
                categoryType: this.props.selectedProductMeta.categoryType,
                index:        this.props.selectedProductMeta.index,
                openDetails:  true,
            });
        }
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.selectedProduct !== this.props.selectedProduct) {
            // Re-render the tooltips since it may happen that some error
            // tooltips appeared inside a slot
            requestAnimationFrame(ReactTooltip.rebuild);
        }
    }

    getExtenderSlotBoundsForIndexAndLayout = (slotIndex, layoutDefinition, productData) => {
        const layout           = layoutDefinition.layout || layoutDefinition.slotLayout;
        const boundsCalculator = SelectionHelper.get(
            layout,
            {
                [SlotLayout.leftBottomToTopToRight]: ExtenderSlotLeftBottomToTopRightBoundsCalculator,
            },
        );

        if (!boundsCalculator) {
            console.warn('getExtenderSlotBoundsForIndexAndLayout: Layout not supported for extender', layout, productData);
        }

        return boundsCalculator.getBounds(
            slotIndex,
            layoutDefinition,
            productData,
        );
    };

    getMatrixPowerSupplyUnitBoundsForIndexAndLayout = (
        powerSupplyUnitIndexStartingBy1,
        powerSupplyUnitDefinition,
        productData,
    ) => {
        const boundsCalculator = SelectionHelper.get(
            powerSupplyUnitDefinition.layout,
            {
                [PowerSupplyUnitLayout.leftBottomToTopToRight]: MatrixPowerSupplyLeftBottomToTopToRightCalculator,
                [PowerSupplyUnitLayout.leftTopToRightBottom]:   MatrixPowerSupplyLeftTopToRightBottomBoundsCalculator,
                [PowerSupplyUnitLayout.topRightToBottomToLeft]: MatrixPowerSupplyTopRightToBottomToLeftBoundsCalculator,
            },
        );

        if (!boundsCalculator) {
            console.warn('getMatrixPowerSupplyUnitBoundsForIndexAndLayout: Layout not supported', powerSupplyUnitDefinition.layout, productData);
        }

        return boundsCalculator.getBounds(
            powerSupplyUnitIndexStartingBy1,
            powerSupplyUnitDefinition,
            productData,
        );
    };

    getMatrixSlotBoundsForIndexAndLayout = (slotIndex, layoutDefinition, productData) => {
        const layout           = layoutDefinition.layout || layoutDefinition.slotLayout;
        const boundsCalculator = SelectionHelper.get(
            layout,
            {
                [SlotLayout.leftBottomToTopToRight]: MatrixSlotLeftBottomToTopToRightCalculator,
                [SlotLayout.leftTopToRightBottom]:   MatrixSlotLeftTopToRightBottomBoundsCalculator,
                [SlotLayout.topRightToBottomToLeft]: MatrixSlotTopRightToBottomToLeftBoundsCalculator,
            },
        );

        if (!boundsCalculator) {
            console.warn('getMatrixSlotBoundsForIndexAndLayout: Layout not supported', layout, productData);
        }

        return boundsCalculator.getBounds(
            slotIndex,
            layoutDefinition,
            productData,
        );
    };

    getProductData = () => {
        if (this.isSingleOrderMode()) {
            if (this.props.selectedSingleOrderSlot) {
                return this.props.selectedSingleOrderSlot.productData;
            }
        } else if (this.props.selectedProduct) {
            return this.props.selectedProduct.productData;
        }

        return null;
    };

    getMatrixVisibleSlotIndex = (product, index, productData) => {
        const layoutDefinition = productData.layoutDefinition;

        // We add 1 here since the slots in the json all start by 1, but we internally start with 0
        const visibleIndex = index + 1;
        const isCpu        = (
            layoutDefinition.unused &&
            layoutDefinition.unused.indexOf(visibleIndex) === -1 &&
            layoutDefinition.io &&
            layoutDefinition.io.indexOf(visibleIndex) === -1
        );

        if (isCpu) {
            return Slot.getVisibleCpuSlotIndex(visibleIndex, layoutDefinition, productData);
        }

        // We got a chassis with only sfp slots
        if (product.productData.uniDefinition) {
            return (
                index + 1
            );
        }

        if (layoutDefinition.io) {
            return layoutDefinition.io.indexOf(index + 1) + 1;
        }

        return visibleIndex;
    };

    getWrapperStyle = (productData) => {
        if (productData) {
            const firstImage = _.get(productData, 'images[0]');

            if (firstImage) {
                return {
                    marginLeft: firstImage.width / 2 * -1 * this.props.scale,
                    marginTop:  firstImage.height / 2 * -1 * this.props.scale,
                    top:        `${this.props.y * 100}%`,
                    left:       `${this.props.x * 100}%`,
                };
            }
        }

        return {
            // This is used when ProductImageRenderer shows its fallback text
            // @see ProductImageRenderer
            left: '50%',
            top:  '50%',
        };
    };

    isDetailPageOpened = () => {
        const pathname = _.get(this, 'props.pathname', '');

        return pathname.startsWith(Routes.designerProductDetails);
    };

    isSingleOrderMode = () => {
        return this.props.pathname.endsWith(Routes.designerProductSingleOrders);
    };

    /**
     * This method will create a faked list of slots to
     * allow the renderer to render all the default products.
     */
    polyfillSlotDataSingleOrder = (productData) => {
        const slots = ActiveProjectsFactory.getEmptyProductSlots(productData.slotCount);

        if (productData.layoutDefinition && productData.layoutDefinition.cpu) {
            for (const cpu of productData.layoutDefinition.cpu) {
                slots[cpu.slot - 1] = ActiveProjectsFactory.getEmptyProductSlot(DataProvider.getById(cpu.partNo));
            }
        }

        return slots;
    };

    /**
     * This method will create a faked list of power supplies to
     * allow the renderer to render all the default products.
     */
    polyfillPowerSupplyDataSingleOrder = (productData) => {
        let powerSupplyUnits = [];

        if (productData.psuDefinition && productData.psuDefinition.psu) {
            powerSupplyUnits = ActiveProjectsFactory.getEmptyProductSlots(productData.psuDefinition.count);

            for (const powerSupplyUnit of productData.psuDefinition.psu) {
                powerSupplyUnits[powerSupplyUnit.slot - 1] = ActiveProjectsFactory.getEmptyProductSlot(DataProvider.getById(powerSupplyUnit.partNo));
            }
        }

        return powerSupplyUnits;
    };

    render() {
        const productData  = this.getProductData();
        const wrapperStyle = this.getWrapperStyle(productData);

        if (this.isSingleOrderMode()) {
            return (
                <div
                    className={styles.selectedProductImageRenderer}
                    style={wrapperStyle}
                >
                    {
                        productData ?
                            (
                                <ProductImageRenderer
                                    isReadOnly={true}
                                    productData={productData}
                                    scale={this.props.scale}
                                >
                                    {this.renderSlots(
                                        {
                                            productData,
                                            subProducts: {
                                                [SlotType.slot]: this.polyfillSlotDataSingleOrder(productData),
                                            },
                                        },
                                        {
                                            categoryType: productData.categoryType,
                                        },
                                    )}
                                    {this.renderPowerSupplyUnits(
                                        {
                                            productData,
                                            subProducts: {
                                                [SlotType.powerSupplyUnit]: this.polyfillPowerSupplyDataSingleOrder(productData),
                                            },
                                        },
                                        {
                                            categoryType: productData.categoryType,
                                        },
                                    )}
                                </ProductImageRenderer>
                            )
                            : null
                    }
                </div>
            );
        }

        if (this.props.selectedProduct) {
            return this.props.connectDropTarget(<div
                className={
                    classNames(
                        styles.selectedProductImageRenderer,
                        (
                            this.props.pathname === Routes.designer ?
                                styles.selectedProductImageRendererClickable :
                                null
                        ),
                    )
                }
                onClick={this.chassisClicked}
                style={wrapperStyle}
            >
                {
                    productData
                        ? (
                            <ProductImageRenderer
                                id={'selectedProduct'}
                                isReadOnly={false}
                                productData={productData}
                                scale={this.props.scale}
                            >
                                {this.renderSlots(
                                    this.props.selectedProduct,
                                    this.props.selectedProductMeta,
                                )}
                                {this.renderAccessories()}
                                {this.renderPowerSupplyUnits(
                                    this.props.selectedProduct,
                                    this.props.selectedProductMeta,
                                )}
                                {this.renderDropZone()}
                            </ProductImageRenderer>
                        )
                        : null
                }
            </div>);
        }

        return null;
    }

    renderAccessories = () => {
        const accessories = [];

        if (this.props.selectedProduct) {
            const productData      = this.props.selectedProduct.productData;
            const layoutDefinition = productData.layoutDefinition;

            if (layoutDefinition) {
                for (const accessoryIndex in this.props.selectedProduct.subProducts.accessory) {
                    const currentAccessory = this.props.selectedProduct.subProducts.accessory[accessoryIndex];

                    if (currentAccessory.productData) {
                        accessories.push(<ProductImageRenderer
                            isReadOnly={false}
                            key={`accessory${accessoryIndex}`}
                            mode={ProductImageRendererMode.parallel}
                            productData={currentAccessory.productData}
                            scale={this.props.scale}
                        />);
                    }
                }
            }
        }

        return (
            <div className={styles.selectedProductImageRendererAccessory}>
                {accessories}
            </div>
        );
    };

    renderDropZone = () => {
        return (
            <TextDropZone
                canDrop={this.props.canDrop}
                cornerStyle={CornerStyle.round}
                isOver={this.props.isOver}
            />
        );
    };

    renderPowerSupplyUnits = (product, productMeta) => {
        const powerSupplyUnits = [];

        if (productMeta.categoryType === ProductCategoryType.matrix) {
            const productData               = product.productData;
            const powerSupplyUnitDefinition = productData.psuDefinition;

            for (const powerSupplyUnitIndex in product.subProducts[SlotType.powerSupplyUnit]) {
                const currentPowerSupplyUnit          = product.subProducts[SlotType.powerSupplyUnit][powerSupplyUnitIndex];
                const powerSupplyUnitIndexStartingBy1 = Cast.int(powerSupplyUnitIndex) + 1;
                const powerSupplyUnitBounds           = this.getMatrixPowerSupplyUnitBoundsForIndexAndLayout(
                    powerSupplyUnitIndexStartingBy1,
                    powerSupplyUnitDefinition,
                );

                powerSupplyUnits.push(<SelectedProductSlotImageRenderer
                    alignTo={powerSupplyUnitBounds.alignTo}
                    accessoryType={null}
                    categoryType={ProductCategoryType.matrix}
                    chassisLayout={powerSupplyUnitBounds.chassisLayout}
                    data={currentPowerSupplyUnit}
                    dragSourceEnabled={this.isDetailPageOpened()}
                    height={powerSupplyUnitBounds.height}
                    key={`powerSupplyUnit${powerSupplyUnitIndex}`}
                    index={Cast.int(powerSupplyUnitIndex)}
                    visibleIndex={Cast.int(powerSupplyUnitIndex) + 1}
                    scale={this.props.scale}
                    type={SlotType.powerSupplyUnit}
                    width={powerSupplyUnitBounds.width}
                    x={powerSupplyUnitBounds.x}
                    y={powerSupplyUnitBounds.y}
                />);
            }
        }

        return powerSupplyUnits;
    };

    renderSlots = (product, productMeta) => {
        const slots = [];

        if (product) {
            const productData      = product.productData;
            const layoutDefinition = productData.layoutDefinition;
            const isChassis        = productData.productSlotType === ProductSlotType.chassis;

            if (productMeta.categoryType === ProductCategoryType.fullIp) {
                return null;
            }

            if (productMeta.categoryType === ProductCategoryType.extender) {
                for (const slotIndex in product.subProducts[SlotType.slot]) {
                    const currentSlot      = product.subProducts[SlotType.slot][slotIndex];
                    const slotIndexInteger = Cast.int(slotIndex);

                    if (Slot.hideSlot(slotIndexInteger, this.props.selectedProduct)) {
                        continue;
                    }

                    const slotIndexStartingBy1 = slotIndexInteger + 1;
                    const slotBounds           = this.getExtenderSlotBoundsForIndexAndLayout(
                        slotIndexStartingBy1,
                        layoutDefinition,
                        productData,
                    );

                    slots.push(<SelectedProductSlotImageRenderer
                        alignTo={slotBounds.alignTo}
                        accessoryType={null}
                        categoryType={productMeta.categoryType}
                        chassisLayout={slotBounds.chassisLayout}
                        column={slotBounds.column}
                        data={currentSlot}
                        dragSourceEnabled={this.isDetailPageOpened()}
                        height={slotBounds.height}
                        key={`slot${slotIndex}`}
                        index={slotIndexInteger}
                        // This is passed using the hoc but we have to pass it again
                        // to workaround https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-514
                        isDoubleSlot={Product.slotIsDoubleSlot(
                            this.props.selectedProduct,
                            slotIndexInteger,
                        )}
                        nextSlotIsDoubleSlot={Product.slotIsDoubleSlot(
                            this.props.selectedProduct,
                            slotIndexInteger + 1,
                        )}
                        visibleIndex={slotIndexInteger}
                        row={slotBounds.row}
                        scale={this.props.scale}
                        type={SlotType.slot}
                        width={slotBounds.width}
                        x={slotBounds.x}
                        y={slotBounds.y}
                    />);
                }
            } else if (productMeta.categoryType === ProductCategoryType.matrix && isChassis) {
                for (const slotIndex in product.subProducts[SlotType.slot]) {
                    const currentSlot      = product.subProducts[SlotType.slot][slotIndex];
                    const slotIndexInteger = Cast.int(slotIndex);

                    if (Slot.hideSlot(slotIndexInteger, this.props.selectedProduct)) {
                        continue;
                    }

                    const slotIndexStartingBy1 = slotIndexInteger + 1;
                    const slotBounds           = this.getMatrixSlotBoundsForIndexAndLayout(
                        slotIndexStartingBy1,
                        layoutDefinition,
                        productData,
                    );

                    slots.push(<SelectedProductSlotImageRenderer
                        alignTo={slotBounds.alignTo}
                        accessoryType={null}
                        categoryType={productMeta.categoryType}
                        chassisLayout={slotBounds.chassisLayout}
                        column={slotBounds.column}
                        data={currentSlot}
                        dragSourceEnabled={this.isDetailPageOpened()}
                        height={slotBounds.height}
                        key={`slot${slotIndex}`}
                        index={slotIndexInteger}
                        visibleIndex={this.getMatrixVisibleSlotIndex(
                            product,
                            slotIndexInteger,
                            productData,
                        )}
                        row={slotBounds.row}
                        scale={this.props.scale}
                        type={SlotType.slot}
                        width={slotBounds.width}
                        x={slotBounds.x}
                        y={slotBounds.y}
                    />);
                }
            }
        }

        return slots;
    };
}

Component.propTypes = {
    scale: PropTypes.number,
    x:     PropTypes.number,
    y:     PropTypes.number,
};

Component.defaultProps = {
    scale: 1,
    x:     1,
    y:     1,
};

const mapStateToProps = (state) => {
    return {
        pathname:                state.router.location.pathname,
        selectedProduct:         StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
        selectedProductMeta:     state.activeProject.selectedProduct,
        selectedSingleOrderSlot: StateHelper.getSelectedSingleOrderSlot(state),
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(
    ActiveProjectActions,
    DragDropActions,
    SmartActions,
), dispatch);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    DropTarget(
        [
            DragTypes.add,
            DragTypes.sort,
        ],
        dragDropConfiguration.dropBehavior,
        dragDropConfiguration.dropCollect,
    ),
)(Component);

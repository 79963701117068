//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import AccessoryTypes           from '@constants/AccessoryTypes';
import Limitations              from '@constants/Limitations';
import Overlays                 from '@constants/Overlays';
import ProductCategoryType      from '@constants/ProductCategoryType';
import ProductSlotType          from '@constants/ProductSlotType';
import SlotType                 from '@constants/SlotType';
import Cast                     from '@helper/Cast';
import LimitationHelper         from '@helper/Limitations';
import ProductTitleHelper       from '@helper/ProductTitle';
import SelectionHelper          from '@helper/SelectionHelper';
import StateHelper              from '@helper/State';

import Overlay      from '../Overlay';
import TextHeadline from '../TextHeadline';

class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.rebuildState();
    }

    closeButtonPressed = () => {
        this.props.deleteSlotCancel();
    };

    componentDidMount() {
        this.setState(this.rebuildState());
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (
            this.props.slotDeletionContext !== nextProps.slotDeletionContext ||
            this.props.selectedProduct !== nextProps.selectedProduct
        ) {
            this.setState(this.rebuildState());
        }
    }

    deleteButtonPressed = () => {
        this.props.deleteSlotConfirm({
            slotIndex: this.props.slotDeletionContext.index,
            slotType:  this.props.slotDeletionContext.type,
        });
    };

    getCancelButtonTextKey = () => {
        return 'cancel';
    };

    getOkButtonTextKey = () => {
        if (this.props.selectedProductCategoryType === ProductCategoryType.fullIp) {
            return I18n.t('deleteOptionButtonText');
        }

        return 'deleteSlotButtonText';
    };

    getSlot = () => {
        if (
            this.props.slotDeletionContext &&
            this.props.slotDeletionContext.type &&
            this.props.slotDeletionContext.index !== null
        ) {
            return this.props.selectedProduct.subProducts[this.props.slotDeletionContext.type][this.props.slotDeletionContext.index];
        }

        return null;
    };

    getText = () => {
        const slot = this.getSlot();

        if (slot) {
            const slotTypeTextMap = this.getTextSlotTypeTextMap();

            if (slotTypeTextMap) {
                const readableSlotIndex = this.props.slotDeletionContext.index + 1;
                const slotName          = ProductTitleHelper.getProductTitle(slot.productData, slot.customName);

                return SelectionHelper.getTranslation(
                    _.get(this.props, 'slotDeletionContext.type', null),
                    slotTypeTextMap,
                    '',
                    {
                        index: readableSlotIndex,
                        name:  slotName,
                    },
                );
            }
        }

        return I18n.t('unknownError');
    };

    /**
     * This method will return additional warnings when the user tries to remove a chassis or module
     * fan while one product requires such a fan.
     *
     * @returns {string}
     */
    getTextAddition = () => {
        if (this.state.productRequiresFanInChassis) {
            if (this.state.slotIsFan) {
                if (this.state.slotTypeIsAccessory && !this.state.productHasModuleFan) {
                    const recommendsModuleFanInChassisLimitation = LimitationHelper.getSlotLimitation(
                        this.props.selectedProduct,
                        Limitations.recommendsModuleFanInChassis,
                    );
                    const texts                                  = [
                        (
                            recommendsModuleFanInChassisLimitation ?
                                I18n.t(
                                    'deleteSlotMatrixFanTextRequiredInChassisWithSlot',
                                    {
                                        slot: recommendsModuleFanInChassisLimitation.value,
                                    },
                                ) :
                                I18n.t('deleteSlotMatrixFanTextRequiredInChassis')
                        ),
                    ];

                    if (recommendsModuleFanInChassisLimitation) {
                        const recommendedSlotIndex         = Cast.int(recommendsModuleFanInChassisLimitation.value);
                        const recommendedSlotIndexInternal = recommendedSlotIndex - 1;
                        const productSlots                 = this.props.selectedProduct.subProducts[SlotType.slot];

                        if (productSlots.length > recommendedSlotIndexInternal) {
                            const slotToOverwrite            = productSlots[recommendedSlotIndexInternal];
                            const slotToOverwriteProductData = slotToOverwrite.productData;

                            if (slotToOverwriteProductData) {
                                const slotName = ProductTitleHelper.getProductTitle(
                                    slotToOverwriteProductData,
                                    slotToOverwrite.customName,
                                );

                                texts.push(I18n.t(
                                    'deleteSlotMatrixFanTextRequiredInChassisOverwriteSlot',
                                    {
                                        slotName,
                                    },
                                ));
                            }
                        }
                    }

                    return texts.join(' ');
                }

                if (this.state.slotTypeIsOtherModule && !this.state.productHasChassisFan) {
                    return I18n.t('deleteSlotMatrixFanTextRequiredInChassis2');
                }
            }
        }

        return null;
    };

    getTextSlotTypeTextMap = () => {
        switch (this.props.selectedProductCategoryType) {
            case ProductCategoryType.extender:
                return {
                    [SlotType.accessory]:       'deleteSlotExtenderAccessoryText',
                    [SlotType.fan]:             'deleteSlotExtenderFanText',
                    [SlotType.powerSupplyUnit]: 'deleteSlotExtenderPowerSupplyUnitText',
                    [SlotType.slot]:            'deleteSlotExtenderSlotText',
                };

            case ProductCategoryType.matrix:
                return {
                    [SlotType.accessory]:       'deleteSlotMatrixAccessoryText',
                    [SlotType.fan]:             'deleteSlotMatrixFanText',
                    [SlotType.powerSupplyUnit]: 'deleteSlotMatrixPowerSupplyUnitText',
                    [SlotType.slot]:            'deleteSlotMatrixSlotText',
                };

            case ProductCategoryType.fullIp:
                return {
                    [SlotType.slot]: 'deleteSlotFullIpSlotText',
                };
        }

        return null;
    };

    getTitle = () => {
        if (this.props.selectedProductCategoryType === ProductCategoryType.fullIp) {
            return I18n.t('deleteOptionTitle');
        }

        return I18n.t('deleteSlotTitle');
    };

    rebuildState = () => {
        const slot            = this.getSlot();
        const productSlotType = _.get(slot, 'productData.productSlotType');
        const type            = _.get(slot, 'productData.type');
        const nextState       = {
            productHasChassisFan:        LimitationHelper.productHasChassisFan(this.props.selectedProduct),
            productHasModuleFan:         LimitationHelper.productHasModuleFan(this.props.selectedProduct),
            productRequiresFanInChassis: LimitationHelper.productRequiresFanInChassis(this.props.selectedProduct),
            slotTypeIsAccessory:         productSlotType === ProductSlotType.chassisAccessory,
            slotTypeIsOtherModule:       productSlotType === ProductSlotType.otherModule,
            slotIsFan:                   type === AccessoryTypes.fan,
        };

        console.log('DeleteSlotOverlay: rebuildState', nextState, slot);

        return nextState;
    };

    render() {
        if (this.props.slotDeletionContext && this.props.slotDeletionContext.type) {
            const textAddition = this.getTextAddition();

            return (
                <Overlay
                    allowDontShowAgain={true}
                    cancelButtonTextKey={this.getCancelButtonTextKey()}
                    closeButtonPressed={this.closeButtonPressed}
                    id={Overlays.deleteSlot}
                    okButtonPressed={this.deleteButtonPressed}
                    okButtonTextKey={this.getOkButtonTextKey()}
                    title={this.getTitle()}
                    {...this.props}
                >
                    <TextHeadline text={this.getText()} />
                    {
                        textAddition
                            ? (
                                <TextHeadline text={textAddition} />
                            )
                            : null
                    }
                </Overlay>
            );
        }

        return null;
    }
}

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => (
    {
        products:                    _.get(state, 'activeProject.products'),
        selectedProduct:             StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
        selectedProductCategoryType: _.get(state, 'activeProject.selectedProduct.categoryType'),
        slotDeletionContext:         _.get(state, 'activeProject.slotDeletionContext'),
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

import React from 'react';

import classNames from 'classnames';

import styles from './styles.module.scss';
import PropTypes from '../PropTypes';

const Component = ({
    autoStyleChildren,
    children,
}) => (
    <div
        className={classNames(
            styles.textContainer,
            autoStyleChildren
                ? styles.textContainerAutoStyleChildren
                : null,
        )}
    >
        {children}
    </div>
);

Component.propTypes = {
    autoStyleChildren: PropTypes.bool,
    children: PropTypes.children,
};

Component.defaultProps = {
    autoStyleChildren: false,
    children: null,
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames             from 'classnames';
import I18n                   from 'i18next';
import { DropTarget }         from 'react-dnd';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { compose }            from 'redux';
import { bindActionCreators } from 'redux';

import { SmartActions } from '@slices/smart';
import DragTypes        from '@constants/DragTypes';

import styles                from './styles.module.scss';
import dragDropConfiguration from '../../dragDrop/smart';
import Icon                  from '../Icon';
import IconType              from '../Icon/IconType';

const Component = ({
    connectDropTarget,
    canDrop,
    isOver,
}) => {
    return connectDropTarget(<div
        className={classNames(
            styles.smartDropZone,
            (
                canDrop ?
                    styles.smartDropZoneCanDrop :
                    null
            ),
            (
                isOver ?
                    styles.smartDropZoneIsOver :
                    null
            ),
        )}
    >
        <div className={styles.smartDropZoneInner}>
            <span className={styles.smartDropZoneInnerIcon}>
                <Icon iconType={IconType.plus} />
            </span>
            <span className={styles.smartDropZoneInnerText}>
                {I18n.t('smartDropZoneAdd')}
            </span>
        </div>
    </div>);
};

const mapDispatchToProps = (dispatch) => bindActionCreators(SmartActions, dispatch);

export default compose(
    connect(
        null,
        mapDispatchToProps,
    ),
    DropTarget(
        DragTypes.add,
        dragDropConfiguration.dropBehavior,
        dragDropConfiguration.dropCollect,
    ),
)(withTranslation()(Component));

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { OverlayActions } from '@slices/overlays';
import Overlays           from '@constants/Overlays';
import TestIds            from '@constants/TestIds';

import styles    from './styles.module.scss';
import CheckBox  from '../CheckBox';
import PropTypes from '../PropTypes';

const Component = ({
    overlayId,
    hiddenOverlays,
    setOverlayVisibility,
}) => {
    const checkBoxValueChanged = () => {
        setOverlayVisibility({
            overlayId,
            visible: !hiddenOverlays[overlayId],
        });
    };
    const getText              = () => {
        switch (overlayId) {
            // @formatter:off
            case Overlays.switchToDuplicatedProduct: return I18n.t('overlayDontShowAgainSwitchToDuplicatedProduct');
      // @formatter:on
        }

        return I18n.t('overlayDontShowAgain');
    };
    const text                 = getText();

    return (
        <div className={styles.overlayDontShowAgainCheckBox}>
            <CheckBox
                testId={TestIds.overlayDontShowAgainCheckbox}
                checked={hiddenOverlays[overlayId]}
                noMarginBottom={true}
                text={text}
                valueChanged={checkBoxValueChanged}
            />
        </div>
    );
};

Component.propTypes = {
    overlayId: PropTypes.oneOf(Object.values(Overlays)),
};

Component.defaultProps = {
    overlayId: null,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(OverlayActions, dispatch);

const mapStateToProps = (state) => (
    {
        hiddenOverlays: state.overlays.hiddenOverlays,
    }
);

export default connect(mapStateToProps, mapDispatchToProps)(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { Component } from 'react';
import React         from 'react';

import classNames  from 'classnames';
import I18n        from 'i18next';
import { Helmet }  from 'react-helmet';
import { connect } from 'react-redux';

import HelpBulletPoint from '@components/HelpBulletPoint';
import TitleBanner     from '@components/TitleBanner';
import TitleBannerMode from '@components/TitleBanner/TitleBannerMode';
import PageTitleHelper from '@helper/PageTitle';

import styles from '../../styles.module.scss';

class Screen extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>{PageTitleHelper.getPageTitle(I18n.t('helpStepByStepTitle'))}</title>
                </Helmet>
                <TitleBanner
                    mode={TitleBannerMode.text}
                    text={I18n.t('helpStepByStepTitle')}
                />
                <div
                    className={classNames(
                        styles.help,
                        styles.helpSteps,
                    )}
                >
                    <HelpBulletPoint
                        imageSource={require('@assets/images/help/step-by-step-step1.jpg')}
                        number={1}
                        text={I18n.t('helpStepByStep1Text')}
                        title={I18n.t('helpStepByStep1Title')}
                    />
                    <HelpBulletPoint
                        imageSource={require('@assets/images/help/step-by-step-step2.jpg')}
                        number={2}
                        text={I18n.t('helpStepByStep2Text')}
                        title={I18n.t('helpStepByStep2Title')}
                    />
                    <HelpBulletPoint
                        imageSource={require('@assets/images/help/step-by-step-step3.jpg')}
                        number={3}
                        text={I18n.t('helpStepByStep3Text')}
                        title={I18n.t('helpStepByStep3Title')}
                    />
                    <HelpBulletPoint
                        imageSource={require('@assets/images/help/step-by-step-step4.jpg')}
                        number={4}
                        text={I18n.t('helpStepByStep4Text')}
                        title={I18n.t('helpStepByStep4Title')}
                    />
                    <HelpBulletPoint
                        imageSource={require('@assets/images/help/step-by-step-step5.jpg')}
                        number={5}
                        text={I18n.t('helpStepByStep5Text')}
                        title={I18n.t('helpStepByStep5Title')}
                    />
                    <HelpBulletPoint
                        imageSource={require('@assets/images/help/step-by-step-step6.jpg')}
                        number={6}
                        text={I18n.t('helpStepByStep6Text')}
                        title={I18n.t('helpStepByStep6Title')}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        language: state.settings.language,
    };
};

export default connect(
    mapStateToProps,
    null,
)(Screen);

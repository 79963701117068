//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import { isTablet }  from 'react-device-detect';
import { DragLayer } from 'react-dnd';

import DragTypes from '@constants/DragTypes';

import styles           from './styles.module.scss';
import ProductDragView  from '../ProductDragView';
import VelocityDragView from '../VelocityDragView';

function getItemStyles(props) {
    const { initialOffset, sourceClientOffset, clientOffset, isDragging } = props;

    if (!initialOffset || !clientOffset) {
        return {
            display: 'none',
        };
    }

    const { x, y }  = clientOffset;
    const transform = `translate(${x}px, ${y}px)`;

    return {
        transform,
        WebkitTransform: transform,
    };
}

const CustomDragLayer = (props) => {
    const { item, itemType, isDragging, clientOffset, sourceClientOffset } = props;

    if (!isDragging || !clientOffset) {
        return null;
    }

    function renderInnerView() {
        if (
            itemType === DragTypes.add ||
            (
                isTablet && item.productId
            )
        ) {
            return (
                <VelocityDragView mouseCoordinates={clientOffset}>
                    <ProductDragView title={item.productId} />
                </VelocityDragView>
            );
        }

        return null;
    }

    const innerView = renderInnerView();

    if (innerView) {
        return (
            <div className={styles.dragLayer}>
                <div style={getItemStyles(props)}>
                    {innerView}
                </div>
            </div>
        );
    }

    return null;
};

export default DragLayer((monitor) => (
    {
        clientOffset:       monitor.getClientOffset(),
        sourceClientOffset: monitor.getSourceClientOffset(),
        initialOffset:      monitor.getInitialSourceClientOffset(),
        isDragging:         monitor.isDragging(),
        item:               monitor.getItem(),
        itemType:           monitor.getItemType(),
    }
))(CustomDragLayer);

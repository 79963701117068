//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import PropTypes                    from '../PropTypes';
import VideoConnectorLabel          from '../VideoConnectorLabel';
import VideoConnectorLabelAlignment from '../VideoConnectorLabel/VideoConnectorLabelAlignment';

const Component = ({ value }) => (
    <VideoConnectorLabel
        alignment={VideoConnectorLabelAlignment.left}
        videoConnector={value}
    />
);

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames             from 'classnames';
import _                      from 'lodash';
import ReactDataGrid          from 'react-data-grid';
import { Data }               from 'react-data-grid-addons';
import { connect }            from 'react-redux';
import ReactTooltip           from 'react-tooltip';
import { bindActionCreators } from 'redux';

import { ProductActions }        from '@slices/product';
import { SmartActions }          from '@slices/smart';
import ProductCategoryType       from '@constants/ProductCategoryType';
import ProductSlotType           from '@constants/ProductSlotType';
import ProductSlotTypes          from '@constants/ProductSlotType';
import Routes                    from '@constants/Routes';
import SortDirections            from '@constants/SortDirections';
import DataProvider              from '@helper/DataProvider';
import StateHelper               from '@helper/State';
import WindowEventHelper         from '@helper/WindowEvent';
import { DesignerLayoutActions } from '@slices/designerLayout';

import styles            from './styles.module.scss';
import ProductTableRow   from '../ProductTableRow';
import PropTypes         from '../PropTypes';
import TableFilterButton from '../TableFilterButton';
import TableRow          from '../TableRow';
import TestIds           from '@constants/TestIds';

class Component extends React.Component {
    dataGridReference = null;

    constructor(props) {
        super(props);

        const dataFilteredBySelectedProductLimitations = this.getRawDataFilteredBySelectedProductLimitations();

        this.state = {
            data:          [...dataFilteredBySelectedProductLimitations],
            dataFilteredBySelectedProductLimitations,
            filters:       {},
            sortColumn:    null,
            sortDirection: SortDirections.NONE,
        };
    }

    componentDidMount() {
        // This makes react-data-grid draw a horizontal scrollbar when the content is too wide
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-172
        WindowEventHelper.fakeResize();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log('Table: componentWillReceiveProps: nextProps, this.props', nextProps, this.props);

        if (this.dataGridReference) {
            if (this.props.tableFilterVisible !== nextProps.tableFilterVisible) {
                this.dataGridReference.onToggleFilter();

                if (nextProps.tableFilterVisible) {
                    requestAnimationFrame(ReactTooltip.rebuild);
                }
            }

            if (
                // @formatter:off
                this.props.bottomPaneSize    !== nextProps.bottomPaneSize ||
                this.props.bottomPaneVisible !== nextProps.bottomPaneVisible ||
                this.props.leftPaneSize      !== nextProps.leftPaneSize ||
                this.props.leftPaneVisible   !== nextProps.leftPaneVisible ||
                this.props.rightPaneSize     !== nextProps.rightPaneSize ||
                this.props.rightPaneVisible  !== nextProps.rightPaneVisible
                // @formatter:on
            ) {
                WindowEventHelper.fakeResize();
            }
        }

        if (
            nextProps.rawData !== this.props.rawData ||
            nextProps.selectedProduct !== this.props.selectedProduct ||
            nextProps.selectedSlot !== this.props.selectedSlot ||
            nextProps.endOfLifeProductsVisible !== this.props.endOfLifeProductsVisible
        ) {
            const dataFilteredBySelectedProductLimitations = this.getRawDataFilteredBySelectedProductLimitations(nextProps);

            this.setState({
                dataFilteredBySelectedProductLimitations,
            }, this.filterAndSortData);
        }
    }

    filterAndSortData = () => {
        const filteredData = Data.Selectors.getRows({
            rows:    this.state.dataFilteredBySelectedProductLimitations,
            filters: this.state.filters,
        });
        const sortedData   = this.sortRows(filteredData, this.state.sortColumn, this.state.sortDirection);

        this.setData(sortedData);
    };

    getRawDataFilteredBySelectedProductLimitations = (props) => {
        if (!props) {
            props = this.props;
        }

        const releasedData = DataProvider.getCompatibleProducts(
            props.rawData,
            props.endOfLifeProductsVisible,
            (
                props.applyLimitations &&
                !this.isSingleOrderMode()
            ),
            props.productSlotType !== ProductSlotTypes.chassis,
            _.get(props, 'selectedProduct'),
            _.get(props, 'selectedSlot'),
            false,
        );

        return releasedData;
    };

    getRowCount = () => {
        return this.state.data.length;
    };

    getRowData = (rowNumber) => {
        const rowData = this.state.data[rowNumber];

        return rowData;
    };

    getValidFilterValues = (columnId) => {
        const filterValues   = [];
        const addFilterValue = (fliterValue) => {
            if (filterValues.indexOf(fliterValue) === -1) {
                filterValues.push(fliterValue);
            }
        };

        for (const row of this.state.data) {
            const data = row[columnId];

            if (_.isArray(data)) {
                for (const subData of data) {
                    addFilterValue(subData);
                }
            } else {
                addFilterValue(data);
            }
        }

        filterValues.sort();

        return filterValues;
    };

    handleFilterChange = (filter) => {
        const newFilters = {
            ...this.state.filters,
        };

        if (filter.filterTerm) {
            newFilters[filter.column.key] = filter;
        } else {
            delete newFilters[filter.column.key];
        }

        return newFilters;
    };

    isSingleOrderMode = () => {
        return this.props.pathname.endsWith(Routes.designerProductSingleOrders);
    };

    onAddFilter = (filter) => {
        this.setFilters(this.handleFilterChange(filter));
    };

    onClearFilters = () => {
        this.setFilters({});
    };

    onRowDoubleClick = (rowIndex, rowData) => {
        const productId = rowData.partNo;
        const {
                  hitLimitation,
                  isIncompatible,
                  isEndOfLife,
              }         = rowData;

        if (isIncompatible) {
            return this.props.showIncompatibleProductOverlay(rowData);
        }

        if (isEndOfLife) {
            return this.props.showEndOfLifeProductOverlay(rowData);
        }

        if (hitLimitation) {
            return this.props.showHitLimitationOverlay(rowData);
        }

        if (productId) {
            this.props.smartAction({
                productId,
                triggeredByDoubleClick: true,
            });
        }
    };

    onGridSort = (sortColumn, sortDirection) => {
        this.setState({
            sortColumn,
            sortDirection,
        }, this.filterAndSortData);
    };

    render() {
        const rowCount = this.getRowCount();

        console.log('Table: render: rowCount', rowCount);

        return (
            <div
                className={classNames(
                    styles.table,
                    {
                        'without-tab-bar': this.props.activeProductCategoryType === ProductCategoryType.equipment,
                    },
                )}
                data-testId={TestIds.table}
            >
                <TableFilterButton
                    onClick={this.toggleFilterButtonPressed}
                />
                <ReactDataGrid
                    columns={this.props.columnConfiguration}
                    enableCellAutoFocus={false}
                    enableCellSelect={false}
                    headerRowHeight={38}
                    getValidFilterValues={this.getValidFilterValues}
                    minHeight={500}
                    onAddFilter={this.onAddFilter}
                    onClearFilters={this.onClearFilters}
                    onRowDoubleClick={this.onRowDoubleClick}
                    onGridSort={this.onGridSort}
                    ref={this.setGridReference}
                    rowGetter={this.getRowData}
                    rowHeight={30}
                    rowRenderer={this.renderTableRow}
                    rowsCount={rowCount}
                    rowSelection={{
                        showCheckbox:      false,
                        enableShiftSelect: false,
                    }}
                    // This is overwritten by the "width" field in the column configuration
                    // so this only sets the minimum width of the description field since
                    // the description has no minimum width
                    //
                    // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-172
                    minColumnWidth={400}
                />
            </div>
        );
    }

    renderTableRow = ({
                          renderBaseRow,
                          ...canvasProps
                      }) => {
        return (
            <TableRow>
                {
                    this.props.dragDropEnabled
                        ? (
                            <ProductTableRow
                                productAccessoryType={canvasProps.row.type}
                                productCategoryType={this.props.productCategoryType}
                                productId={canvasProps.row.id}
                                productSlotType={this.props.productSlotType}
                                productHitLimitation={canvasProps.row.hitLimitation}
                                productIsIncompatible={canvasProps.row.isIncompatible}
                                productIsEndOfLife={canvasProps.row.isEndOfLife}
                            >
                                {renderBaseRow({
                                    ...canvasProps,
                                })}
                            </ProductTableRow>
                        )
                        : (
                            renderBaseRow({
                                ...canvasProps,
                            })
                        )
                }
            </TableRow>
        );
    };

    setData = (data) => {
        this.setState({
            data,
        });
    };

    setFilters = (filters) => {
        this.setState({
            filters,
        }, this.filterAndSortData);
    };

    setGridReference = (reference) => {
        this.dataGridReference = reference;

        if (reference && this.props.tableFilterVisible) {
            reference.onToggleFilter();
        }
    };

    sortRows = (initialRows, sortColumn, sortDirection) => {
        const sortRowsComparer = (left, right) => {
            if (sortDirection === SortDirections.ASC) {
                return left[sortColumn] > right[sortColumn]
                    ? 1
                    : -1;
            }

            if (sortDirection === SortDirections.DESC) {
                return left[sortColumn] < right[sortColumn]
                    ? 1
                    : -1;
            }
        };
        const sortedRows       = (
            sortDirection === SortDirections.NONE ?
                initialRows :
                [...initialRows].sort(sortRowsComparer)
        );

        return sortedRows;
    };

    toggleFilterButtonPressed = () => {
        this.props.tableFilterToggleVisibility();
    };
}

Component.propTypes = {
    applyLimitations:    PropTypes.bool,
    columnConfiguration: PropTypes.array,
    dragDropEnabled:     PropTypes.bool,
    productCategoryType: PropTypes.oneOf(Object.values(ProductCategoryType)),
    productSlotType:     PropTypes.oneOf(Object.values(ProductSlotType)),
    rawData:             PropTypes.array,
};

Component.defaultProps = {
    applyLimitations:    true,
    columnConfiguration: [],
    dragDropEnabled:     true,
    productCategoryType: null,
    productSlotType:     null,
    rawData:             [],
};

const mapStateToProps = (state) => (
    {
        activeProductCategoryType: state.activeProject.activeProductCategoryType,
        bottomPaneSize:            state.designerLayout.bottomPaneSize,
        bottomPaneVisible:         state.designerLayout.bottomPaneVisible,
        endOfLifeProductsVisible:  state.settings.endOfLifeProductsVisible,
        leftPaneSize:              state.designerLayout.leftPaneSize,
        leftPaneVisible:           state.designerLayout.leftPaneVisible,
        pathname:                  state.router.location.pathname,
        rightPaneSize:             state.designerLayout.rightPaneSize,
        rightPaneVisible:          state.designerLayout.rightPaneVisible,
        selectedProduct:           StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
        selectedSlot:              StateHelper.getSelectedSlot(state),
        tableFilterVisible:        state.designerLayout.tableFilterVisible,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(
    ProductActions,
    DesignerLayoutActions,
    SmartActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);

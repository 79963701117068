//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import styles         from './styles.module.scss';
import PropTypes      from '../PropTypes';
import resizeListener from '../ResizeListener';

class Component extends React.Component {
    getHeight = () => {
        if (this.props.windowHeight < 800) {
            return this.props.windowHeight - 200;
        }

        return 600;
    };

    render() {
        const height = this.getHeight();

        return (
            <iframe
                className={classNames(styles.overlayIFrame)}
                name={this.props.name}
                src={this.props.url}
                style={{
                    height,
                }}
            />
        );
    }
}

Component.propTypes = {
    name: PropTypes.string,
    url:  PropTypes.string,
};

Component.defaultProps = {
    name: '',
    url:  '',
};

export default resizeListener(Component);

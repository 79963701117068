//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React, { useState } from 'react';

import Carousel         from 'react-images';
import { ModalGateway } from 'react-images';
import { Modal }        from 'react-images';

import styles    from './styles.module.scss';
import Icon      from '../Icon';
import IconType  from '../Icon/IconType';
import PropTypes from '../PropTypes';

const Component = ({ imageSource }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const images                        = [
        {
            src: imageSource,
        },
    ];
    const toggleModal                   = (event) => {
        setModalIsOpen(!modalIsOpen);
        event.stopPropagation();
    };

    return (
        <div
            className={styles.imagePreview}
            onClick={toggleModal}
        >
            <img
                className={styles.imagePreviewImage}
                src={imageSource}
            />
            <div className={styles.imagePreviewOverlay}>
                <span className={styles.imagePreviewOverlayIcon}>
                    <Icon iconType={IconType.zoomIn} />
                </span>
            </div>
            <ModalGateway>
                {modalIsOpen
                    ? (
                        <Modal
                            allowFullscreen={false}
                            onClose={toggleModal}
                        >
                            <Carousel views={images} />
                        </Modal>
                    )
                    : null}
            </ModalGateway>
        </div>
    );
};

Component.propTypes = {
    imageSource: PropTypes.string,
};

Component.defaultProps = {
    imageSource: null,
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import { bindActionCreators } from 'redux';

const importSlice = createSlice({
    name:         'Import',
    initialState: {
        importNewProject:      false,
        importToActiveProject: false,
        importProductUpdates:  false,
    },
    reducers:     {
        importNewProject:      (state) => {
            state.importNewProject = true;
        },
        importToActiveProject: (state) => {
            state.importToActiveProject = true;
        },
        importProductUpdates:  (state) => {
            state.importProductUpdates = true;
        },
    },
});

export const ImportActions = importSlice.actions;

export const ImportReducer = importSlice.reducer;

export const useImport = (dispatch) => bindActionCreators(ImportActions, dispatch);

export default importSlice;

import React from 'react';

import ProductCategoryType from '@constants/ProductCategoryType';
import ProductSlotType from '@constants/ProductSlotType';
import ProductSubCategoryTypes from '@constants/ProductSubCategoryType';
import DataProvider from '@helper/DataProvider';

import Table from '../Table';
import DefaultColumns from '../Table/DefaultColumns';

const Component = () => {
    const getColumnConfiguration = () => {
        const defaultColumns = DefaultColumns.get(
            ProductCategoryType.matrix,
            ProductSubCategoryTypes.flex,
        );

        return [
            defaultColumns.displayPartNumber,
            defaultColumns.portCount,
            defaultColumns.dataTransfer,
            defaultColumns.description,
        ];
    };

    return (
        <Table
            columnConfiguration={getColumnConfiguration()}
            productCategoryType={ProductCategoryType.matrix}
            productSlotType={ProductSlotType.ioBoard}
            rawData={DataProvider.getMatrixFlexIoBoards()}
        />
    );
};

Component.propTypes = {};

Component.defaultProps = {};

export default Component;

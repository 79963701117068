const ProductSubCategoryTypes = {
    compact:      'compact',
    enterprise:   'enterprise',
    flex:         'flex',
    fullIp4K:     'fullIp4K',
    fullIpFullHd: 'fullIpFullHd',
    misc:         'misc',
    none:         'none',
    secure:       'secure',
    vario:        'vario',
};

module.exports = ProductSubCategoryTypes;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { createSlice }        from '@reduxjs/toolkit';
import { bindActionCreators } from 'redux';
import update                 from 'immutability-helper';

const initialState = {};

const smartSlice = createSlice({
    name:     'smart',
    initialState,
    reducers: {
        smartAction: (state, action) => {},
    },
});

export const SmartActions = smartSlice.actions;

export const SmartReducer = smartSlice.reducer;

export const useSmart = (dispatch) => bindActionCreators(SmartActions, dispatch);

export default smartSlice;

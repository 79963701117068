export default {
    AUDIO:                                           'audio',
    BLIND_PLATE:                                     'blindPlate',
    CAN_BE_ORDERED_EMPTY:                            'canBeOrderedEmpty',
    CATEGORY_TYPE:                                   'categoryType',
    CONFIGURABLE_SFP_COUNT:                          'configurableSfpCount',
    CURRENT_HEAT_DISSIPATION:                        'currentHeatDissipation',
    CURRENT_POWER_CONSUMPTION_IN_MILLIAMPS:          'currentPowerConsumptionInMilliamps',
    DATA_TRANSFER:                                   'dataTransfer',
    DEPTH_IN_MILLIMETERS:                            'depthInMilliMeters',
    DESCRIPTION:                                     'description',
    DESCRIPTION_SHORT_DE:                            'description_deShort',
    DESCRIPTION_SHORT_DE_FULL:                       'description_deFull',
    DESCRIPTION_SHORT_DE_PREFIX:                     'description_deShortPrefix',
    DESCRIPTION_SHORT_EN:                            'description_enShort',
    DESCRIPTION_SHORT_EN_FULL:                       'description_enFull',
    DESCRIPTION_SHORT_EN_PREFIX:                     'description_enShortPrefix',
    DEVICE_TYPE:                                     'deviceType',
    DISPLAY_PART_NUMBER:                             'displayPartNumber',
    EFFICIENCY:                                      'efficiency',
    FAN_POWER_CONSUMPTION_IN_MILLIWATTS:             'fanPowerConsumptionInMilliwatts',
    HAS_INTERNAL_PSU:                                'hasInternalPsu',
    HEAT_DISSIPATION:                                'heatDissipationInMilliwatts',
    HEIGHT_IN_MILLIMETERS:                           'heightInMilliMeters',
    HEIGHT_UNITS:                                    'heightUnits',
    ID:                                              'id',
    IDENT_NO:                                        'identNo',
    IDENT_NUMBER:                                    'identNumber',
    IMAGE_SIZE:                                      'imageSize',
    IMAGES:                                          'images',
    INTERNAL:                                        'internal',
    IS_REDUNDANT:                                    'redundant',
    IS_REDUNDANT_STRING:                             'isRedundantString',
    LAYOUT_DEFINITION:                               'layoutDefinition',
    LIMITATIONS:                                     'limitations',
    LINK_RESOLUTION:                                 'linkResolution',
    MAX_POWER_CONSUMPTION_ALERT_TYPE:                'maxPowerConsumptionAlertType',
    MAXIMUM_POWER_CONSUMPTION_IN_MILLIAMPS:          'maximumPowerConsumptionInMilliamps',
    MUTABLE:                                         'mutable',
    NUMBER_OF_MONITORS:                              'numberOfMonitors',
    OPTIONAL_FAN_COUNT:                              'optionalFanCount',
    OPTIONAL_PSU_COUNT:                              'optionalPsuCount',
    PART_NO:                                         'partNo',
    PORT_COUNT:                                      'portCount',
    POWER_CONSUMPTION_IN_MILLIAMPS:                  'powerConsumptionInMilliamps',
    POWER_IN_MILLIWATTS:                             'powerInMilliwatts',
    PRODUCT_SLOT_TYPE:                               'productSlotType',
    PSU_COUNT:                                       'psuCount',
    PSU_DEFINITION:                                  'psuDefinition',
    RACK_UNITS:                                      'rackUnits',
    RECOMMENDED_POWER_CONSUMPTION_IN_MILLIAMPS:      'recommendedPowerConsumptionInMilliamps',
    REDUNDANCY_BOARD_POWER_CONSUMPTION_IN_MILLIAMPS: 'redundancyBoardPowerConsumptionInMilliamps',
    RENDER_MODE:                                     'renderMode',
    RESOLUTION:                                      'resolution',
    SECONDARY_VOLTAGE_IN_VOLT:                       'secondaryVoltageInVolt',
    SERIAL:                                          'serial',
    SFP_COUNT:                                       'sfpCount',
    SLOT_COUNT:                                      'slotCount',
    STATUS:                                          'status',
    SUB_CATEGORY_TYPE:                               'subCategoryType',
    SUPPORTS_PIGGY_BACK:                             'supportsPiggyBack',
    TYPE:                                            'type',
    UNI_DEFINITION:                                  'uniDefinition',
    USB:                                             'usb',
    VIDEO_CONNECTOR:                                 'videoConnector',
    WIDTH_IN_MILLIMETERS:                            'widthInMilliMeters',
};

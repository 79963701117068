//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n       from 'i18next';
import ClearCache from 'react-clear-cache';

import styles           from './styles.module.scss';
import ColorButton      from '../ColorButton';
import ColorButtonTheme from '../ColorButton/ColorButtonTheme';

const Component = () => {
    const clearCacheButtonPressed                 = (emptyCacheStorage) => {
        emptyCacheStorage();
    };
    const [updateHintHidden, setUpdateHintHidden] = React.useState(false);
    const laterButtonPressed                      = () => {
        setUpdateHintHidden(true);
    };

    return (
        <ClearCache
            auto={false}
            duration={1000 * 60 * 30}
        >
            {({ isLatestVersion, emptyCacheStorage }) => {
                let currentAppVersion = window.localStorage.getItem('appVersion');

                if (!currentAppVersion) {
                    currentAppVersion = window.localStorage.getItem('APP_VERSION');
                }

                if (
                    !isLatestVersion &&
                    currentAppVersion &&
                    currentAppVersion.length &&
                    currentAppVersion !== '""' &&
                    !updateHintHidden
                ) {
                    return (
                        <div className={styles.clearCacheInvalidator}>
                            <p>{I18n.t('newVersionHintText')}</p>
                            <ColorButton
                                onClick={() => {
                                    clearCacheButtonPressed(emptyCacheStorage);
                                }}
                                theme={ColorButtonTheme.orange}
                                text={I18n.t('newVersionHintUpdateButton')}
                            />
                            <ColorButton
                                onClick={laterButtonPressed}
                                theme={ColorButtonTheme.black}
                                text={I18n.t('newVersionHintLaterButton')}
                            />
                        </div>
                    );
                }

                return null;
            }}
        </ClearCache>
    );
};

Component.propTypes = {};

Component.defaultProps = {};

export default Component;

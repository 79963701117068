import { createSlice } from '@reduxjs/toolkit';
import update from 'immutability-helper';

const initialState = {};

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        showHitLimitationOverlay: (state, action) => {
            return state;
        },
        showEndOfLifeProductOverlay: (state, action) => {
            return state;
        },
        showIncompatibleProductOverlay: (state, action) => {
            return state;
        },
    },
});

export const ProductActions = productSlice.actions;

export const ProductReducer = productSlice.reducer;

export default productSlice;

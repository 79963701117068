import React from 'react';

import ProductCategoryType from '@constants/ProductCategoryType';
import ProductSlotType from '@constants/ProductSlotType';
import ProductSubCategoryTypes from '@constants/ProductSubCategoryType';
import DataProvider from '@helper/DataProvider';

import Table from '../Table';
import DefaultColumns from '../Table/DefaultColumns';

const getColumnConfiguration = () => {
    const defaultColumns = DefaultColumns.get(
        ProductCategoryType.extender,
        ProductSubCategoryTypes.compact,
    );

    return [
        defaultColumns.displayPartNumber,
        defaultColumns.videoConnector,
        defaultColumns.usb,
        defaultColumns.dataTransfer,
        defaultColumns.audio,
        defaultColumns.serial,
        defaultColumns.description,
    ];
};

const Component = () => {
    return (
        <Table
            columnConfiguration={getColumnConfiguration()}
            productCategoryType={ProductCategoryType.extender}
            productSlotType={ProductSlotType.chassis}
            rawData={DataProvider.getExtenderCompact()}
        />
    );
};

Component.propTypes = {};

Component.defaultProps = {};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import AccessoryTypes  from '@constants/AccessoryTypes';
import ProductSlotType from '@constants/ProductSlotType';

import defaultDragDropConfiguration from './default';

export default {
    dragBehavior: {
        beginDrag(props) {
            console.log('Product: DragSource: beginDrag (props)', props);

            props.dragStart();

            return (
                {
                    id:                  props.selectedProduct.id,
                    productCategoryType: props.selectedProductCategoryType,
                    productId:           props.selectedProduct.productData.partNo,
                }
            );
        },
        endDrag(props, monitor, component) {
            const dropResult = monitor.getDropResult();
            props.dragStop();
        },
    },
    dragCollect:  defaultDragDropConfiguration.dragCollect,
    dropBehavior: {
        canDrop(props, monitor) {
            const payload = monitor.getItem();

            console.log('Product: DropTarget: canDrop', payload, props);

            return (
                (
                    payload.productSlotType &&
                    (
                        // Only accept dropping of chassis or
                        // chassis accessories that are mounted automatically
                        // into their correct place.
                        payload.productSlotType === ProductSlotType.chassis ||
                        payload.productSlotType === ProductSlotType.chassisAccessory
                    ) &&
                    // Sfp modules have to be dragged directly to the sfp slots in the card
                    // so this can be ignored here.
                    payload.productAccessoryType !== AccessoryTypes.sfp
                )
            );
        },
        drop: (props, monitor, component) => {
            const payload = monitor.getItem();

            console.log('Product: DropTarget: drop', payload);
            console.table(payload);

            props.smartAction({
                productId:              payload.productId,
                triggeredByDoubleClick: false,
            });

            return {
                ...payload,
            };
        },
    },
    dropCollect:  defaultDragDropConfiguration.dropCollect,
};

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { push } from 'connected-react-router';
import { put }  from 'redux-saga/effects';

import Routes from '@constants/Routes';

const resetAll = function* () {
    yield put(push(Routes.home));
};

export default {
    resetAll,
};

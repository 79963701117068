//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames             from 'classnames';
import _                      from 'lodash';
import { DebounceInput }      from 'react-debounce-input';
import { DragSource }         from 'react-dnd';
import { DropTarget }         from 'react-dnd';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';

import { ActiveProjectActions }  from '@slices/activeProject';
import { DragDropActions }       from '@slices/dragDrop';
import DragTypes                 from '@constants/DragTypes';
import ProductCategoryType       from '@constants/ProductCategoryType';
import Cast                      from '@helper/Cast';
import DataProvider              from '@helper/DataProvider';
import { DesignerLayoutActions } from '@slices/designerLayout';

import styles                from './styles.module.scss';
import dragDropConfiguration from '../../dragDrop/singleOrder';
import Icon                  from '../Icon';
import IconType              from '../Icon/IconType';
import ProductQuickSelect    from '../ProductQuickSelect';
import PropTypes             from '../PropTypes';
import TextDropZone          from '../TextDropZone';
import CornerStyle           from '../TextDropZone/CornerStyle';

class Component extends React.Component {
    inputRef = null;

    amountChanged = (event) => {
        let nextAmount = Cast.int(event.target.value);

        if (!nextAmount || nextAmount < 1) {
            nextAmount = 1;
        }

        this.props.setSingleOrderSlotAmount({
            categoryType: this.props.productCategoryType,
            index:        this.props.index,
            amount:       nextAmount,
        });
    };

    decreaseAmountButtonPressed = () => {
        this.props.decreaseSingleOrderSlotAmount({
            categoryType: this.props.productCategoryType,
            index:        this.props.index,
        });
    };

    deleteButtonPressed = (event) => {
        this.props.deleteSingleOrderSlot({
            categoryType: this.props.productCategoryType,
            index:        this.props.index,
        });

        // Avoid selecting the next single slot after deleting this one
        event.stopPropagation();
    };

    increaseAmountButtonPressed = () => {
        this.props.increaseSingleOrderSlotAmount({
            categoryType: this.props.productCategoryType,
            index:        this.props.index,
        });
    };

    getProductQuickSelectProducts = () => {
        const products = DataProvider.staticGetProductsForProductCategoryType(
            this.props.productCategoryType,
            DataProvider.emptyProductTypeRejectList,
        );

        return products;
    };

    getSingleOrderNumber = () => {
        return `${this.props.index + 1
        }.`;
    };

    getTitle = () => {
        if (this.hasProductData()) {
            return this.props.singleOrder.productData.id;
        }

        return this.renderProductQuickSelect();
    };

    hasProductData = () => {
        return (
            this.props.singleOrder &&
            this.props.singleOrder.productData &&
            this.props.singleOrder.productData.id
        );
    };

    itemClicked = () => {
        if (this.hasProductData()) {
            this.props.selectSingleOrderSlot({
                categoryType: this.props.productCategoryType,
                index:        this.props.index,
            });

            this.props.setRightPaneSetVisibility({
                visible: true,
            });
        }
    };

    productQuickSelectChanged = (option) => {
        this.props.setSingleOrderSlot({
            categoryType:     this.props.productCategoryType,
            productId:        option.value,
            singleOrderIndex: this.props.index,
        });
    };

    render() {
        const baseItem = this.props.connectDropTarget(<div
            className={classNames(
                styles.leftPaneSingleOrderItem,
                (
                    this.hasProductData() ?
                        styles.leftPaneSingleOrderItemHasProduct :
                        null
                ),
                (
                    this.props.index % 2 === 0 ?
                        styles.leftPaneSingleOrderItemOdd :
                        styles.leftPaneSingleOrderItemEven
                ),
                (
                    this.props.isDragging ?
                        styles.leftPaneSingleOrderItemDragging :
                        null
                ),
                (
                    this.props.draggingActionActive ?
                        styles.leftPaneSingleOrderItemNoHover :
                        null
                ),
                (
                    this.props.selectedSingleOrderSlot &&
                    this.props.selectedSingleOrderSlot.categoryType === this.props.productCategoryType &&
                    this.props.selectedSingleOrderSlot.index === this.props.index ?
                        styles.leftPaneSingleOrderItemSelected :
                        null
                ),
            )}
            onClick={this.itemClicked}
        >
            <div className={styles.leftPaneSingleOrderItemSingleOrderNumber}>
                {this.getSingleOrderNumber()}
            </div>
            <div className={styles.leftPaneSingleOrderItemTitle}>
                {this.getTitle()}
            </div>
            {this.renderControls()}
            {this.renderDropZone()}
        </div>);

        if (this.hasProductData()) {
            return this.props.connectDragSource(baseItem);
        }

        return baseItem;
    }

    renderControls = () => {
        return (
            <div className={styles.leftPaneSingleOrderItemControls}>
                {this.renderControlsWithProduct()}
                <div
                    className={styles.leftPaneSingleOrderItemControlsDeleteButtonWrapper}
                    onClick={this.deleteButtonPressed}
                >
                    <Icon iconType={IconType.trashBin} />
                </div>
            </div>
        );
    };

    renderControlsWithProduct = () => {
        if (this.hasProductData()) {
            return [
                <div
                    className={styles.leftPaneSingleOrderItemControlsDecreaseAmountButtonWrapper}
                    key={'decreaseAmountButton'}
                    onClick={this.decreaseAmountButtonPressed}
                >
                    <Icon iconType={IconType.minus} />
                </div>,
                <DebounceInput
                    className={styles.leftPaneSingleOrderItemAmountInput}
                    key={'amountInput'}
                    placeholder={this.props.placeholder}
                    debounceTimeout={300}
                    min={1}
                    draggable={true}
                    inputRef={this.setInputRef}
                    onChange={this.amountChanged}
                    onFocus={this.inputFocused}
                    type={'number'}
                    value={this.props.singleOrder.amount}
                />,
                <div
                    className={styles.leftPaneSingleOrderItemControlsIncreaseAmountButtonWrapper}
                    key={'increaseAmountButton'}
                    onClick={this.increaseAmountButtonPressed}
                >
                    <Icon iconType={IconType.plus} />
                </div>,
            ];
        }

        return null;
    };

    setInputRef = (ref) => {
        this.inputRef = ref;
    };

    inputFocused = () => {
        if (this.inputRef) {
            this.inputRef.ondragstart = this.onDragStart;

            this.inputRef.focus();
            this.inputRef.select();
        }
    };

    onDragStart = (event) => {
        event.preventDefault();

        return false;
    };

    renderDropZone = () => {
        return (
            <TextDropZone
                canDrop={this.props.canDrop}
                cornerStyle={CornerStyle.square}
                isOver={this.props.isOver}
            />
        );
    };

    renderProductQuickSelect = () => {
        return (
            <ProductQuickSelect
                onChange={this.productQuickSelectChanged}
                getProducts={this.getProductQuickSelectProducts}
            />
        );
    };
}

Component.propTypes = {
    index:               PropTypes.number,
    productCategoryType: PropTypes.oneOf(Object.values(ProductCategoryType)),
    singleOrder:         PropTypes.object,
};

Component.defaultProps = {
    index:               -1,
    productCategoryType: null,
    singleOrder:         {},
};

const mapStateToProps = (state) => (
    {
        draggingActionActive:    state.dragDrop.isDragging,
        selectedSingleOrderSlot: state.activeProject.selectedSingleOrderSlot,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(
    ActiveProjectActions,
    DesignerLayoutActions,
    DragDropActions,
), dispatch);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    DropTarget(
        [
            DragTypes.add,
            DragTypes.sort,
        ],
        dragDropConfiguration.dropBehavior,
        dragDropConfiguration.dropCollect,
    ),
    DragSource(
        DragTypes.sort,
        dragDropConfiguration.dragBehavior,
        dragDropConfiguration.dragCollect,
    ),
)(withTranslation()(Component));

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import Overlays                 from '@constants/Overlays';
import ProductCategoryType      from '@constants/ProductCategoryType';
import ProductTitleHelper       from '@helper/ProductTitle';
import SelectionHelper          from '@helper/SelectionHelper';
import StateHelper              from '@helper/State';

import Overlay      from '../Overlay';
import TextHeadline from '../TextHeadline';

const Component = ({
    deleteSfpCancel,
    deleteSfpConfirm,
    selectedSlot,
    sfpDeletionContext,
    selectedProductCategoryType,
    t,
}) => {
    const closeButtonPressed  = () => {
        deleteSfpCancel();
    };
    const deleteButtonPressed = () => {
        deleteSfpConfirm({
            sfpIndex: sfpDeletionContext.index,
        });
    };
    const getSfp              = () => {
        if (
            selectedSlot &&
            selectedSlot.sfps &&
            sfpDeletionContext &&
            sfpDeletionContext.index !== null
        ) {
            return selectedSlot.sfps[sfpDeletionContext.index];
        }

        return null;
    };
    const getText             = () => {
        const slot = selectedSlot;
        const sfp  = getSfp();

        if (sfp) {
            const readableSlotIndex = sfpDeletionContext.index + 1;
            const slotName          = ProductTitleHelper.getProductTitle(slot.productData, slot.customName);

            return SelectionHelper.getTranslation(
                _.get(selectedProductCategoryType, null),
                {
                    [ProductCategoryType.extender]: 'deleteSfpExtenderText',
                    [ProductCategoryType.matrix]:   'deleteSfpExtenderText',
                },
                '',
                {
                    index:            readableSlotIndex,
                    sfpProductNumber: sfp.productData.partNo,
                    slotName,
                },
            );
        }

        return I18n.t('unknownError');
    };

    if (sfpDeletionContext) {
        return (
            <Overlay
                allowDontShowAgain={true}
                closeButtonPressed={closeButtonPressed}
                id={Overlays.deleteSfp}
                okButtonPressed={deleteButtonPressed}
                okButtonTextKey={'deleteSfpButtonText'}
                title={I18n.t('deleteSfpTitle')}
                {...this.props}
            >
                <TextHeadline text={getText()} />
            </Overlay>
        );
    }

    return null;
};

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => (
    {
        selectedProductCategoryType: _.get(state, 'activeProject.selectedProduct.categoryType'),
        sfpDeletionContext:          _.get(state, 'activeProject.sfpDeletionContext'),
        selectedSlot:                StateHelper.getSelectedSlot(state),
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

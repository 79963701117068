//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import * as JSZip from 'jszip';
import _          from 'lodash';

import AccessoryTypes        from '@constants/AccessoryTypes';
import ProductCategoryType   from '@constants/ProductCategoryType';
import SlotType              from '@constants/SlotType';
import Cast                  from '@helper/Cast';
import DataProvider          from '@helper/DataProvider';
import ActiveProjectsFactory from '@store/factories/activeProjects';

class Ec0Importer {
    static canImport(zip) {
        const result = !!this.getFirstEc0FileFromFileList(zip.files);

        console.log('Ec0Importer canImport:', result);

        return result;
    }

    static getEc0FileFromFileList(files) {
        const ec0Files = _.find(files, (file) => {
            return file.name.indexOf('.ec0') > -1;
        });

        return ec0Files;
    }

    static getFirstEc0FileFromFileList(files) {
        const ec0Files = this.getEc0FileFromFileList(files);

        console.log('getFirstEc0FileFromFileList ec0Files', ec0Files);

        if (ec0Files) {
            if (ec0Files[0]) {
                return ec0Files[0];
            }

            return ec0Files;
        }

        return null;
    }

    static importFile(file, errorCallback, parsedContentCallback) {
        const zip = new JSZip();

        return zip.loadAsync(file).then((zip) => {
            if (zip && zip.files && this.canImport(zip)) {
                const firstEc0File = this.getFirstEc0FileFromFileList(zip.files);

                firstEc0File.async('string').then((content) => {
                    this.parseEc0FileContent(content, parsedContentCallback);
                });
            } else {
                errorCallback();
            }
        }, () => {
            // It may happen that the user directly uploads a ec0 file
            // instead of a zip file containing a ec0 file
            const fileReader = new FileReader();

            fileReader.onload = () => {
                const content = fileReader.result;

                this.parseEc0FileContent(content, parsedContentCallback);
            };

            fileReader.readAsText(file);
        });
    }

    static parseEc0FileContent(fileContent, parsedContentCallback) {
        const importedProject     = ActiveProjectsFactory.getInitialState();
        let product               = null;
        const splittedFileContent = fileContent.split('\n');

        for (let splittedFileContentLine of splittedFileContent) {
            splittedFileContentLine = splittedFileContentLine.trim();

            if (splittedFileContentLine.length > 0) {
                const isEc0DefinitionLine = splittedFileContentLine.indexOf('FileReadWriter') === -1;

                if (isEc0DefinitionLine) {
                    const splittedLine    = splittedFileContentLine.split(';');
                    const isProductLine   = !product;
                    const isSlotLine      = splittedLine[1] && splittedLine[1].length > 0;
                    const isAccessoryLine = !isProductLine && !isSlotLine;
                    const productId       = splittedLine[0];
                    const productData     = DataProvider.getById(productId);

                    if (isProductLine) {
                        product = ActiveProjectsFactory.getProduct(
                            ProductCategoryType.extender,
                            productData,
                        );
                    } else if (isAccessoryLine) {
                        let slotType = null;

                        switch (productData.type) {
                            // @formatter:off
                            case AccessoryTypes.accessory:       slotType = SlotType.accessory;

                                break;
                            case AccessoryTypes.fan:             slotType = SlotType.fan;

                                break;
                            case AccessoryTypes.powerSupplyUnit: slotType = SlotType.powerSupplyUnit;

                                break;
                            // @formatter:on
                        }

                        if (slotType) {
                            product.subProducts[slotType][0].productData = productData;
                        } else {
                            console.warn('Unsupported ec0 import slot type: ', productData.type);
                        }
                    } else if (isSlotLine) {
                        if (productData.partNo !== product.productData.blindPlate) {
                            let slotIndexInternal = Cast.int(splittedLine[1]) - 1;

                            if (productData.heightUnits > 1) {
                                slotIndexInternal += 1;
                            }

                            product.subProducts[SlotType.slot][slotIndexInternal].productData = productData;
                        }
                    }
                }
            }
        }

        importedProject.products.extender.push(product);

        parsedContentCallback(importedProject);
    }
}

export default Ec0Importer;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

class SelectionHelper {
    static get(currentTheme, mapping, fallback) {
        const mappingThemes = Object.keys(mapping);

        for (const theme of mappingThemes) {
            if (theme === currentTheme) {
                return mapping[currentTheme];
            }
        }

        return fallback;
    }

    static getTranslation(currentTheme, mapping, fallback, translationParameters) {
        const languageIndex = this.get(currentTheme, mapping, fallback);

        if (languageIndex) {
            return I18n.t(languageIndex, translationParameters);
        }

        return '';
    }
}

export default SelectionHelper;

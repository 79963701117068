//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

/**
 * @see This class is also present in the backend project (src/Helper/ProductTitleHelper.php)
 */
class ProductTitleHelper {
    static getProductFileName(productData, customName, extension) {
        const title           = this.getProductTitle(productData, customName);
        const cleanFileName   = title.replace(/[^a-z0-9-]+/gi, '-').toLowerCase();
        const productFileName = `${cleanFileName}.${extension}`;

        return productFileName;
    }

    static getProductTitle(productData, customName) {
        if (productData) {
            let productName = productData.displayPartNumber;

            if (customName) {
                productName += ` (${customName})`;
            }

            // TODO: sollte das ggf. nicht einfach im State gespeichert werden um es überall einfach abrufen zu können?

            return productName;
        }

        return I18n.t('unknownError');
    }

    static getProductCustomNameOrPartNumber(product) {
        if (product) {
            const productData = product.productData;

            if (productData) {
                const customName = product.customName;

                if (customName) {
                    return customName;
                }
                return productData.displayPartNumber;
            }
        }

        return I18n.t('unknownError');
    }
}

export default ProductTitleHelper;

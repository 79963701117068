/**
 * @see This enum is also present in the backend project src/Entity/ProductCategoryType.php
 */
const ProdcutCategoryType = {
    equipment: 'equipment',
    extender:  'extender',
    fullIp:    'fullIp',
    matrix:    'matrix',
};

module.exports = ProdcutCategoryType;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import Overlays                 from '@constants/Overlays';
import ProductCategoryType      from '@constants/ProductCategoryType';
import ProductTitleHelper       from '@helper/ProductTitle';
import SelectionHelper          from '@helper/SelectionHelper';

import Overlay      from '../Overlay';
import TextHeadline from '../TextHeadline';

const Component = ({
    singleOrderSlotDeletionContext,
    singleOrders,
    deleteSingleOrderSlotConfirm,
    deleteSingleOrderSlotCancel,
    t,
}) => {
    const closeButtonPressed  = () => {
        deleteSingleOrderSlotCancel();
    };
    const deleteButtonPressed = () => {
        deleteSingleOrderSlotConfirm({
            categoryType: singleOrderSlotDeletionContext.categoryType,
            index:        singleOrderSlotDeletionContext.index,
        });
    };
    const getSingleOrderSlot  = () => {
        if (
            singleOrderSlotDeletionContext &&
            singleOrderSlotDeletionContext.categoryType &&
            singleOrderSlotDeletionContext.index !== null
        ) {
            return singleOrders[singleOrderSlotDeletionContext.categoryType][singleOrderSlotDeletionContext.index];
        }

        return null;
    };
    const getText             = () => {
        const singleOrderSlot = getSingleOrderSlot();

        if (singleOrderSlot) {
            const readableSlotIndex = singleOrderSlotDeletionContext.index + 1;
            const productName       = ProductTitleHelper.getProductTitle(singleOrderSlot.productData);

            return SelectionHelper.getTranslation(
                _.get(singleOrderSlotDeletionContext, 'categoryType', null),
                {
                    [ProductCategoryType.equipment]: 'deleteSingleOrderSlotEquipmentText',
                    [ProductCategoryType.extender]:  'deleteSingleOrderSlotExtenderText',
                    [ProductCategoryType.matrix]:    'deleteSingleOrderSlotMatrixText',
                    [ProductCategoryType.fullIp]:    'deleteSingleOrderSlotFullIpText',
                },
                '',
                {
                    index: readableSlotIndex,
                    name:  productName,
                },
            );
        }

        return I18n.t('unknownError');
    };

    if (singleOrderSlotDeletionContext && singleOrderSlotDeletionContext.categoryType) {
        return (
            <Overlay
                allowDontShowAgain={true}
                closeButtonPressed={closeButtonPressed}
                id={Overlays.deleteSingleOrderSlot}
                okButtonPressed={deleteButtonPressed}
                okButtonTextKey={'deleteSingleOrderSlotButtonText'}
                title={I18n.t('deleteSingleOrderSlotTitle')}
            >
                <TextHeadline text={getText()} />
            </Overlay>
        );
    }

    return null;
};

const mapStateToProps = (state) => (
    {
        singleOrders:                   _.get(state, 'activeProject.singleOrders'),
        singleOrderSlotDeletionContext: _.get(state, 'activeProject.singleOrderSlotDeletionContext'),
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

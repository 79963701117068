//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { Component } from 'react';
import React         from 'react';

import { connect }            from 'react-redux';
import { withRouter }         from 'react-router-dom';
import SplitPane              from 'react-split-pane';
import { bindActionCreators } from 'redux';

import Header                    from '@components/Header';
import HeaderMode                from '@components/Header/HeaderMode';
import LeftPaneRouter            from '@components/LeftPaneRouter';
import resizeListener            from '@components/ResizeListener';
import SplitPanePanel            from '@components/SplitPanePanel';
import Routes                    from '@constants/Routes';
import SplitPaneMode             from '@constants/SplitPaneMode';
import SplitPanePrimary          from '@constants/SplitPanePrimary';
import { DesignerLayoutActions } from '@slices/designerLayout';
import splitPaneStyle            from '@styles/splitPaneStyle';

import StepByStep from './help/StepByStep';
import faqData    from '../data/faq';
import styles     from '../styles.module.scss';

class Screen extends Component {
    render() {
        return (
            <div className={styles.designer}>
                <Header mode={HeaderMode.default} />
                <SplitPane
                    className={styles.designerMainSplitPane}
                    split={SplitPaneMode.vertical}
                    minSize={300}
                    maxSize={300}
                    defaultSize={300}
                    size={300}
                    primary={SplitPanePrimary.first}
                    resizerStyle={splitPaneStyle.resizerVertical}
                    allowResize={false}
                >
                    <SplitPanePanel>
                        <LeftPaneRouter />
                    </SplitPanePanel>
                    <SplitPanePanel>
                        {this.renderContent()}
                    </SplitPanePanel>
                </SplitPane>
            </div>
        );
    }

    renderContent() {
        let index         = faqData[0].index;
        const indexByPath = this.props.pathname.replace(Routes.helpDetails, '').replace(/\//g, '');

        if (indexByPath) {
            index = indexByPath;
        }

        return this.renderContentForIndex(index);
    }

    renderContentForIndex(index) {
        switch (index) {
            // @formatter:off
            case 'step-by-step': return <StepByStep />;
            // @formatter:on
        }

        return null;
    }
}

const mapStateToProps = (state) => (
    {
        pathname: state.router.location.pathname,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(DesignerLayoutActions, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps,
)(resizeListener(Screen)));

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.
import update                 from 'immutability-helper';
import _                      from 'lodash';
import { createSlice }        from '@reduxjs/toolkit';
import { diff }               from 'deep-object-diff';
import { bindActionCreators } from 'redux';

import AbasColumns                from '@constants/AbasColumns';
import AccessoryTypes             from '@constants/AccessoryTypes';
import Limitations                from '@constants/Limitations';
import ProductCategoryType        from '@constants/ProductCategoryType';
import ProductSlotType            from '@constants/ProductSlotType';
import ProductSubCategoryTypes    from '@constants/ProductSubCategoryType';
import SlotType                   from '@constants/SlotType';
import Cast                       from '@helper/Cast';
import DataProvider               from '@helper/DataProvider';
import IdGenerator                from '@helper/IdGenerator';
import LimitationHelper           from '@helper/Limitations';
import Product                    from '@helper/Product';
import String                     from '@helper/String';
import { DebugActions }           from '@slices/debug';
import { ExportActions }          from '@slices/export';
import { ImportActions }          from '@slices/import';
import HeatDissipationCalculator  from '@store/calculators/heatDissipation';
import LimitationsCalculator      from '@store/calculators/limitations';
import PowerConsumptionCalculator from '@store/calculators/powerConsumption';
import SlotPositionCalculator     from '@store/calculators/slotPosition';
import ActiveProjectsFactory      from '@store/factories/activeProjects';
import { REHYDRATE }              from 'redux-persist';

const activeProjectInitialState = ActiveProjectsFactory.getInitialState();

const initialState = ActiveProjectsFactory.getEmptyState();

class activeProjectStateHelper {
    static getProductForCategoryTypeAndIndex = (state, categoryType, index) => {
        return state.products[categoryType][index];
    };

    static getSelectedProduct = (state) => {
        return activeProjectStateHelper.getProductForCategoryTypeAndIndex(
            state,
            state.selectedProduct.categoryType,
            state.selectedProduct.index,
        );
    };

    static getNextCategoryType = (categoryTypes, currentCategoryType) => {
        const keys      = Object.keys(categoryTypes);
        const nextIndex = keys.indexOf(currentCategoryType) + 1;

        if (nextIndex >= keys.length) {
            return keys[0];
        }

        return keys[nextIndex];
    };

    static getNextLastSelectedProduct = (products, index, categoryType) => {
        const otherCategoryType = this.getNextCategoryType(products, categoryType);
        const usedCategoryType  = products[otherCategoryType].length
            ? otherCategoryType
            : categoryType;
        let nextCategoryType    = categoryType;
        let nextIndex           = index;

        if (index === -1) {
            nextIndex        = products[usedCategoryType].length - 1;
            nextCategoryType = usedCategoryType;
        } else if (index === products[categoryType].length) {
            nextIndex        = 0;
            nextCategoryType = usedCategoryType;
        }

        return {
            index:        nextIndex,
            categoryType: nextCategoryType,
        };
    };

    static getSlotForTypeAndIndexInSelectedProduct = (state, type, index) => {
        const selectedProduct = activeProjectStateHelper.getSelectedProduct(state);

        return selectedProduct.subProducts[type][index];
    };

    static getSfpForIndexInSelectedSlot = (state, index) => {
        const selectedProduct = activeProjectStateHelper.getSelectedProduct(state);

        return selectedProduct.subProducts[state.selectedSlot.slotType][state.selectedSlot.index].sfps[index];
    };

    static getSingleOrderSlotForTypeAndIndex = (state, type, index) => {
        return state.singleOrders[type][index];
    };

    static getUpdateDataChangeSet = (state) => {
        const changeSet           = {};
        const productCategories   = state.products;
        const productCategoryKeys = Object.keys(productCategories);

        for (const productCategoryKey of productCategoryKeys) {
            const products = productCategories[productCategoryKey];

            for (const productIndex in products) {
                const product     = products[productIndex];
                const productData = product.productData;

                if (productData) {
                    const productPartNo          = productData.partNo;
                    const jsonProductProductData = DataProvider.getById(productPartNo);
                    const productChangeSetPath   = 'products[' + productCategoryKey + '][' + productIndex + ']';
                    const difference             = diff(productData, jsonProductProductData);

                    if (difference) {
                        _.set(
                            changeSet,
                            `${productChangeSetPath}.productData`,
                            {
                                $set: jsonProductProductData,
                            },
                        );
                    }

                    if (product.subProducts) {
                        const subProductCategories   = product.subProducts;
                        const subProductCategoryKeys = Object.keys(subProductCategories);

                        for (const subProductCategory of subProductCategoryKeys) {
                            const subProducts = subProductCategories[subProductCategory];

                            for (const subProductIndex in subProducts) {
                                const subProduct              = subProducts[subProductIndex];
                                const subProductProductData   = subProduct.productData;
                                const subProductChangeSetPath = `${productChangeSetPath
                                }.subProducts[${
                                    subProductCategory
                                }][${
                                    subProductIndex}`;

                                if (subProductProductData) {
                                    const subProductPartNo          = subProductProductData.partNo;
                                    const jsonSubProductProductData = DataProvider.getById(subProductPartNo);

                                    if (diff(subProductProductData, jsonSubProductProductData)) {
                                        _.set(
                                            changeSet,
                                            `${subProductChangeSetPath}.productData`,
                                            {
                                                $set: jsonSubProductProductData,
                                            },
                                        );
                                    }

                                    const sfps = subProduct.sfps;

                                    if (sfps) {
                                        for (const sfpIndex in sfps) {
                                            const sfp            = sfps[sfpIndex];
                                            const sfpProductData = sfp.productData;

                                            if (sfpProductData) {
                                                const sfpPartNo          = sfpProductData.partNo;
                                                const jsonSfpProductData = DataProvider.getById(sfpPartNo);
                                                const sfptChangeSetPath  = (
                                                    `${subProductChangeSetPath
                                                    }.sfps[${
                                                        sfpIndex
                                                    }]`
                                                );

                                                if (diff(sfpProductData, jsonSfpProductData)) {
                                                    _.set(
                                                        changeSet,
                                                        `${sfptChangeSetPath}.productData`,
                                                        {
                                                            $set: jsonSfpProductData,
                                                        },
                                                    );
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        const singleOrderCategories   = state.singleOrders;
        const singleOrderCategoryKeys = Object.keys(singleOrderCategories);

        for (const singleOrderCategoryKey of singleOrderCategoryKeys) {
            const products = singleOrderCategories[singleOrderCategoryKey];

            for (const productIndex in products) {
                const product     = products[productIndex];
                const productData = product.productData;

                if (productData) {
                    const productPartNo          = productData.partNo;
                    const jsonProductProductData = DataProvider.getById(productPartNo);
                    const productChangeSetPath   = `singleOrders[${singleOrderCategoryKey}][${productIndex}]`;

                    if (diff(productData, jsonProductProductData)) {
                        _.set(changeSet, productChangeSetPath + '.productData', {
                            $set: jsonProductProductData,
                        });
                    }
                }
            }
        }

        return changeSet;
    };

    static recalculateAllProductMetaData = (state, changeSet) => {
        let newChangeSet = {};

        if (!changeSet) {
            newChangeSet = {};
        }

        const categoryTypes = Object.keys(state.products);

        for (const categoryType of categoryTypes) {
            const products = state.products[categoryType];

            for (const productIndex in products) {
                newChangeSet = this.recalculateProductMetaData(
                    categoryType,
                    productIndex,
                    state,
                    newChangeSet,
                );
            }
        }

        return newChangeSet;
    };

    static recalculateProductMetaData = (categoryType, index, state, changeSet) => {
        const product     = state.products[categoryType][index];
        const productData = product.productData;
        let newChangeSet  = {};

        console.log(
            'recalculateProductMetaData powerConsumptionInMilliamps 1',
            state,
            categoryType,
            index,
        );

        if (!changeSet) {
            newChangeSet = {};
        }

        _.set(
            newChangeSet,
            `products[${categoryType}][${index}] `,
            {},
        );

        console.log(
            'recalculateProductMetaData powerConsumptionInMilliamps',
            state,
            categoryType,
            index,
        );

        let powerConsumptionInMilliamps = 0;
        let heatDissipation             = {};

        if (
            categoryType === ProductCategoryType.matrix &&
            productData.subCategoryType === ProductSubCategoryTypes.flex
        ) {
            powerConsumptionInMilliamps = productData.powerConsumptionInMilliamps;
            heatDissipation             = {
                chassis:     Math.ceil(productData.heatDissipationInMilliwatts / 1000),
                externalPsu: 0,
            };
        } else {
            powerConsumptionInMilliamps = PowerConsumptionCalculator.getPowerConsumption(
                product,
                product.subProducts,
            );
            heatDissipation             = HeatDissipationCalculator.getHeatDissipation(
                product,
                powerConsumptionInMilliamps,
            );
        }

        const changeSetSelectedProduct                       = newChangeSet.products[categoryType][index];
        changeSetSelectedProduct.heatDissipation             = {
            chassis:     {
                $set: heatDissipation.chassis,
            },
            externalPsu: {
                $set: heatDissipation.externalPsu,
            },
        };
        changeSetSelectedProduct.powerConsumptionInMilliAmps = {
            $set: powerConsumptionInMilliamps,
        };

        LimitationsCalculator.updateChangeSet(
            state,
            newChangeSet,
            powerConsumptionInMilliamps,
            categoryType,
            index,
        );

        return newChangeSet;
    };

    static recalculateSelectedProductMetaData = (state, changeSet) => {
        console.log(
            'recalculateProductMetaData: powerConsumptionInMilliamps',
            state.selectedProduct,
        );

        changeSet = this.recalculateProductMetaData(
            state.selectedProduct.categoryType,
            state.selectedProduct.index,
            state,
            changeSet,
        );

        return changeSet;
    };

    static ensureStateIsCorrect = (state) => {
        let newState = _.cloneDeep(state);

        for (const category of Object.keys(ProductCategoryType)) {
            if (!_.has(newState.products, category)) {
                newState = update(newState, {
                    products: {
                        [category]: {
                            $set: [],
                        },
                    },
                });
            }

            if (!_.has(newState.singleOrders, category)) {
                newState = update(newState, {
                    singleOrders: {
                        [category]: {
                            $set: ActiveProjectsFactory.getEmptySingleOrderSlots(5),
                        },
                    },
                });
            }

            if (!_.has(newState.chassisGroupToggleContext, category)) {
                newState = update(newState, {
                    chassisGroupToggleContext: {
                        [category]: {
                            $set: {},
                        },
                    },
                });
            }

            if (!_.has(newState.multipleProductsDeletionContext, category)) {
                newState = update(newState, {
                    multipleProductsDeletionContext: {
                        [category]: {
                            $set: {},
                        },
                    },
                });
            }
        }

        return newState;
    };
}

const setSelectedProductData = (state, action) => {
    const { productId }                    = action.payload;
    const setSelectedProductCurrentProduct = state.products[state.selectedProduct.categoryType][state.selectedProduct.index];

    // Check whether the user is about to change the product at all
    // (it may happen that he just sets the already selected product again)
    if (setSelectedProductCurrentProduct.productData.partNo === productId) {
        return state;
    }

    const nextProductData = DataProvider.getById(productId);

    // Prevent changing of the selected product if the category type does not match.
    // This may happen when the user double clicks on a matrix chassis in the data table while a
    // extender chassis is selected.
    if (
        state.selectedProduct.categoryType === nextProductData.categoryType &&
        setSelectedProductCurrentProduct.productId !== productId
    ) {
        const currentSubProducts = setSelectedProductCurrentProduct.subProducts;
        let newSubProductsUpdate = {};

        if (nextProductData.categoryType === ProductCategoryType.fullIp) {
            newSubProductsUpdate = {
                $set: {
                    accessory:       ActiveProjectsFactory.getEmptyProductSlots(1),
                    fan:             ActiveProjectsFactory.getEmptyProductSlots(0),
                    powerSupplyUnit: ActiveProjectsFactory.getEmptyProductSlots(nextProductData.psuCount),
                    slot:            ActiveProjectsFactory.getEmptyProductSlots(nextProductData.slotCount),
                },
            };
        } else {
            newSubProductsUpdate = {
                $set: {
                    // Make sure to also extend factories/activeProject.js at
                    //     getProduct()
                    // when you add new categories here.
                    //
                    // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-535
                    accessory:       ActiveProjectsFactory.getEmptyProductSlots(1),
                    fan:             ActiveProjectsFactory.getEmptyProductSlots(
                        nextProductData.optionalFanCount,
                        currentSubProducts[SlotType.fan],
                    ),
                    powerSupplyUnit: ActiveProjectsFactory.getTransferredPsuSlots(
                        nextProductData,
                        nextProductData.psuCount,
                        nextProductData.psuDefinition,
                        currentSubProducts[SlotType.powerSupplyUnit],
                    ),
                    slot:            ActiveProjectsFactory.getEmptyProductSlots(
                        nextProductData.slotCount,
                        ActiveProjectsFactory.getTransferredSlots(
                            currentSubProducts[SlotType.slot],
                            setSelectedProductCurrentProduct.productData,
                            nextProductData,
                        ),
                    ),
                },
            };
        }

        const setSelectedProductDataNextStateConfiguration = {
            products:                    {
                [state.selectedProduct.categoryType]: {
                    [state.selectedProduct.index]: {
                        fanAddedAutomatically: {
                            $set: false,
                        },
                        productData:           {
                            $set: nextProductData,
                        },
                        subProducts:           newSubProductsUpdate,
                    },
                },
            },
            setSelectedProductIdConfirm: {
                $set: null,
            },
        };
        const setSelectedProductDataNextState              = update(state, setSelectedProductDataNextStateConfiguration);

        return update(
            setSelectedProductDataNextState,
            activeProjectStateHelper.recalculateSelectedProductMetaData(setSelectedProductDataNextState),
        );
    }

    return state;
};

const activeProjectSlice = createSlice({
    name:          'activeProject',
    initialState,
    reducers:      {
        hideUnsavedProjectWarning:          (state, action) => {
            return update(state, {
                hideUnsavedProjectWarning: {
                    $set: true,
                },
            });
        },
        addProduct:                         (state, action) => {
            const { categoryType, productId } = action.payload;
            const newState                    = activeProjectStateHelper.ensureStateIsCorrect(state);
            const stateAfterProductAdded      = update(newState, {
                products: {
                    [categoryType]: {
                        $push: [
                            ActiveProjectsFactory.getProduct(
                                state.activeProductCategoryType,
                                DataProvider.getById(productId),
                            ),
                        ],
                    },
                },
            });

            return update(
                stateAfterProductAdded,
                activeProjectStateHelper.recalculateAllProductMetaData(stateAfterProductAdded),
            );
        },
        addSingleOrderSlot:                 (state, action) => {
            const { categoryType, productId }   = action.payload;
            const addSingleOrderSlotNextIndex   = state.singleOrders[categoryType].length;
            const addSingleOrderSlotProductData = (
                productId ?
                    DataProvider.getById(productId) :
                    null
            );

            return update(state, {
                selectedSingleOrderSlot: {
                    categoryType: {
                        $set: categoryType,
                    },
                    index:        {
                        $set: addSingleOrderSlotNextIndex,
                    },
                },
                singleOrders:            {
                    [categoryType]: {
                        $push: [
                            ActiveProjectsFactory.getEmptySingleOrderSlot(addSingleOrderSlotProductData),
                        ],
                    },
                },
            });
        },
        applyModuleDeletion:                (state, action) => {
            const applyModuleDeletionSlotChangeSet = {};
            const applyModulsSelectedProduct       = state.products[state.selectedProduct.categoryType][state.selectedProduct.index];
            const applyModuleSlots                 = applyModulsSelectedProduct.subProducts[SlotType.slot];

            for (const currentSlotIndex in applyModuleSlots) {
                const slotData = applyModuleSlots[currentSlotIndex];

                if (
                    slotData &&
                    slotData.productData &&
                    state.deleteModulesContext.modules[slotData.productData.partNo]
                ) {
                    const isLocked = Product.isLockedInProduct(
                        applyModulsSelectedProduct.productData,
                        SlotType.slot,
                        currentSlotIndex,
                    );

                    if (!isLocked) {
                        applyModuleDeletionSlotChangeSet[currentSlotIndex] = {
                            $set: ActiveProjectsFactory.getEmptyProductSlot(),
                        };
                    }
                }
            }

            const newState = update(state, {
                deleteModulesContext: {
                    $set: activeProjectInitialState.deleteModulesContext,
                },
                products:             {
                    [state.selectedProduct.categoryType]: {
                        [state.selectedProduct.index]: {
                            subProducts: {
                                [SlotType.slot]: applyModuleDeletionSlotChangeSet,
                            },
                            warnings:    {
                                $set: [],
                            },
                        },
                    },
                },
            });

            return newState;
        },
        autoFillSlots:                      (state, action) => {
            const { productId, slotIndex, freeSlotsAfterSlotIndex } = action.payload;
            let nextState                                           = state;

            // Update the product structure
            // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-559
            if (!nextState.autoFillSlots) {
                nextState = update(nextState, {
                    autoFillSlots: {
                        $set: activeProjectInitialState.autoFillSlots,
                    },
                });
            }

            return update(nextState, {
                autoFillSlots: {
                    freeSlotsAfterSlotIndex: {
                        $set: freeSlotsAfterSlotIndex,
                    },
                    productId:               {
                        $set: productId,
                    },
                    slotIndex:               {
                        $set: slotIndex,
                    },
                },
            });
        },
        clearProject:                       (state, action) => {
            return update(state, {
                $set: initialState,
            });
        },
        duplicateSelectedProduct:           (state, action) => {
        },
        noData:                             (state, action) => {
        },
        closeProductDetails:                (state, action) => {
        },
        closeProject:                       (state, action) => {
            const { routeAfterClose } = action.payload;

            return update(state, {
                routeAfterClose: {
                    $set: routeAfterClose,
                },
            });
        },
        confirmOverwriteSlot:               (state, action) => {
            const { actionPayload, actionType } = action.payload;

            return update(state, {
                slotOverwriteContext: {
                    actionPayload: {
                        $set: actionPayload,
                    },
                    actionType:    {
                        $set: actionType,
                    },
                },
            });
        },
        copySfp:                            (state, action) => {
            const { fromSfpIndex, toSfpIndex }  = action.payload;
            const copySfpFromSfp                = activeProjectStateHelper.getSfpForIndexInSelectedSlot(
                state,
                fromSfpIndex,
            );
            const copySfpNextStateConfiguration = {
                products: {
                    [state.selectedProduct.categoryType]: {
                        [state.selectedProduct.index]: {
                            subProducts: {
                                [state.selectedSlot.slotType]: {
                                    [state.selectedSlot.index]: {
                                        sfps: {
                                            [toSfpIndex]: {
                                                $set: copySfpFromSfp,
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            };

            return update(state, copySfpNextStateConfiguration);
        },
        copySingleOrderSlot:                (state, action) => {
            const { fromIndex, toIndex, categoryType } = action.payload;
            const newState                             = activeProjectStateHelper.ensureStateIsCorrect(state);

            return update(newState, {
                singleOrders: {
                    [categoryType]: {
                        [toIndex]: {
                            $set: {
                                ...activeProjectStateHelper.getSingleOrderSlotForTypeAndIndex(
                                    state,
                                    categoryType,
                                    fromIndex,
                                ),
                            },
                        },
                    },
                },
            });
        },
        copySlot:                           (state, action) => {
            const { fromSlotType, fromSlotIndex, toSlotIndex }             = action.payload;
            const copySlotFromSlot                                         = activeProjectStateHelper.getSlotForTypeAndIndexInSelectedProduct(
                state,
                fromSlotType,
                fromSlotIndex,
            );
            const copySlotSelectedProduct                                  = activeProjectStateHelper.getSelectedProduct(state);
            const copySlotProductData                                      = copySlotSelectedProduct.productData;
            const copySlotStateOperation                                   = SlotPositionCalculator.calculateNextState(
                {
                    $set: copySlotFromSlot,
                },
                state,
                toSlotIndex,
                fromSlotType,
                copySlotProductData,
                copySlotFromSlot.productData,
            );
            const copySlotNextState                                        = update(state, copySlotStateOperation);
            const copySlotNextStateWithRecalculatedMetaData                = update(
                copySlotNextState,
                activeProjectStateHelper.recalculateSelectedProductMetaData(copySlotNextState),
            );
            const copySlotNextStateSelectedProductWithRecalculatedMetaData = activeProjectStateHelper.getSelectedProduct(copySlotNextStateWithRecalculatedMetaData);

            // This is a workaround to don't apply the copy action of a slot when it would
            // exceed the maximum power consumption
            // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-761
            if (
                copySlotNextStateSelectedProductWithRecalculatedMetaData.powerConsumptionInMilliAmps >
                copySlotNextStateSelectedProductWithRecalculatedMetaData.productData.maximumPowerConsumptionInMilliamps
            ) {
                return state;
            }

            return copySlotNextStateWithRecalculatedMetaData;
        },
        decreaseSingleOrderSlotAmount:      (state, action) => {
            const { categoryType, index } = action.payload;

            return update(state, {
                singleOrders: {
                    [categoryType]: {
                        [index]: {
                            amount: {
                                $decreaseByStayPositive: 1,
                            },
                        },
                    },
                },
            });
        },
        deleteProduct:                      (state, action) => {
            const { categoryType, id } = action.payload;

            return update(state, {
                productDeletionContext: {
                    categoryType: {
                        $set: categoryType,
                    },
                    id:           {
                        $set: id,
                    },
                },
            });
        },
        deleteProductCancel:                (state, action) => {
            return update(state, {
                productDeletionContext: {
                    $set: activeProjectInitialState.productDeletionContext,
                },
            });
        },
        deleteProductConfirm:               (state, action) => {
            const { id, categoryType }               = action.payload;
            const deletedProductIndex                = _.findIndex(
                state.products[categoryType],
                {
                    id,
                },
            );
            const deleteProductConfirmStateOperation = {
                products:               {
                    [categoryType]: (products) => {
                        return products.filter((product, index) => {
                            return product.id !== id;
                        });
                    },
                },
                productDeletionContext: {
                    $set: activeProjectInitialState.productDeletionContext,
                },
            };

            if (deletedProductIndex === Cast.int(state.selectedProduct.index)) {
                deleteProductConfirmStateOperation.selectedProduct = {
                    $set: activeProjectInitialState.selectedProduct,
                };
            }

            return update(state, deleteProductConfirmStateOperation);
        },
        deleteSelectedProducts:             (state, action) => {
            const { categoryType }           = action.payload;
            const getIndicesFromCategoryType = (innerState, type) => {
                // Get all keys where the value is true
                let indices = _.keys(_.pickBy(innerState.multipleProductsDeletionContext[type], _.identity));

                // Reverse indices for later so we can splice the product array from back to forth
                indices = indices.sort().reverse();

                return indices;
            };
            const removeProductsByIndex      = (innerState, type, allIndices) => {
                const indices  = allIndices[type];
                const products = _.clone(innerState.products[type]);

                // Remove products from back to forth
                for (let index = 0; index < indices.length; index++) {
                    const referenceIndex = indices[index];

                    products.splice(referenceIndex, 1);
                }

                return products;
            };

            // Get all indices grouped by their type
            const indices = {
                [ProductCategoryType.extender]:  getIndicesFromCategoryType(state, ProductCategoryType.extender),
                [ProductCategoryType.equipment]: getIndicesFromCategoryType(state, ProductCategoryType.equipment),
                [ProductCategoryType.matrix]:    getIndicesFromCategoryType(state, ProductCategoryType.matrix),
                [ProductCategoryType.fullIp]:    getIndicesFromCategoryType(state, ProductCategoryType.fullIp),
            };

            // Remove products in every group by the index the user previously selected
            const productsWithoutDeletedProducts = {
                [ProductCategoryType.extender]:  removeProductsByIndex(state, ProductCategoryType.extender, indices),
                [ProductCategoryType.equipment]: removeProductsByIndex(state, ProductCategoryType.equipment, indices),
                [ProductCategoryType.matrix]:    removeProductsByIndex(state, ProductCategoryType.matrix, indices),
                [ProductCategoryType.fullIp]:    removeProductsByIndex(state, ProductCategoryType.fullIp, indices),
            };

            return update(state, {
                products:                        {
                    $set: productsWithoutDeletedProducts,
                },
                multipleProductsDeletionContext: {
                    $set: activeProjectInitialState.multipleProductsDeletionContext,
                },
            });
        },
        deleteSelectedProductsCancel:       (state, action) => {
            return update(state, {
                multipleProductsDeletionContext: {
                    $set: activeProjectInitialState.multipleProductsDeletionContext,
                },
            });
        },
        deleteSingleOrderSlot:              (state, action) => {
            const { categoryType, index } = action.payload;

            return update(state, {
                singleOrderSlotDeletionContext: {
                    index:        {
                        $set: index,
                    },
                    categoryType: {
                        $set: categoryType,
                    },
                },
            });
        },
        deleteSingleOrderSlotCancel:        (state, action) => {
            return update(state, {
                singleOrderSlotDeletionContext: {
                    $set: activeProjectInitialState.singleOrderSlotDeletionContext,
                },
            });
        },
        deleteSingleOrderSlotConfirm:       (state, action) => {
            const { categoryType, index }                       = action.payload;
            const deleteSingleOrderSlotConfirmSingleOrdersCount = state.singleOrders[categoryType].length;
            let deleteSingleOrderSlotConfirmStateOperation      = null;

            if (deleteSingleOrderSlotConfirmSingleOrdersCount > 1) {
                // We remove the whole single order slots since
                // there are enough left
                deleteSingleOrderSlotConfirmStateOperation = {
                    singleOrders: {
                        [categoryType]: {
                            $splice: [
                                [
                                    index,
                                    1,
                                ],
                            ],
                        },
                    },
                };
            } else {
                // We keep at least one empty single order slot so
                // in this case we just empty its data
                deleteSingleOrderSlotConfirmStateOperation = {
                    singleOrders: {
                        [categoryType]: {
                            [index]: {
                                $set: ActiveProjectsFactory.getEmptySingleOrderSlot(),
                            },
                        },
                    },
                };
            }

            deleteSingleOrderSlotConfirmStateOperation.singleOrderSlotDeletionContext = {
                $set: activeProjectInitialState.singleOrderSlotDeletionContext,
            };

            if (
                categoryType === state.selectedSingleOrderSlot.categoryType &&
                index === state.selectedSingleOrderSlot.index
            ) {
                deleteSingleOrderSlotConfirmStateOperation.selectedSingleOrderSlot = {
                    $set: activeProjectInitialState.selectedSingleOrderSlot,
                };
            }

            return update(state, deleteSingleOrderSlotConfirmStateOperation);
        },
        deleteSfp:                          (state, action) => {
            const { sfpIndex } = action.payload;

            return update(state, {
                sfpDeletionContext: {
                    index: {
                        $set: sfpIndex,
                    },
                },
            });
        },
        deleteSfpCancel:                    (state, action) => {
            return update(state, {
                sfpDeletionContext: {
                    $set: activeProjectInitialState.sfpDeletionContext,
                },
            });
        },
        deleteSfpConfirm:                   (state, action) => {
            const { sfpIndex }                   = action.payload;
            const deleteSfpConfirmStateOperation = {
                products:           {
                    [state.selectedProduct.categoryType]: {
                        [state.selectedProduct.index]: {
                            subProducts: {
                                [state.selectedSlot.slotType]: {
                                    [state.selectedSlot.index]: {
                                        sfps: {
                                            [sfpIndex]: {
                                                $set: ActiveProjectsFactory.getEmptyProductSlot(),
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
                sfpDeletionContext: {
                    $set: activeProjectInitialState.sfpDeletionContext,
                },
            };

            return update(state, deleteSfpConfirmStateOperation);
        },
        deleteSlot:                         (state, action) => {
            const { slotIndex, slotType } = action.payload;

            return update(state, {
                slotDeletionContext: {
                    index: {
                        $set: slotIndex,
                    },
                    type:  {
                        $set: slotType,
                    },
                },
            });
        },
        deleteSlotCancel:                   (state, action) => {
            return update(state, {
                slotDeletionContext: {
                    $set: activeProjectInitialState.slotDeletionContext,
                },
            });
        },
        deleteSlotConfirm:                  (state, action) => {
            const { slotIndex, slotType }         = action.payload;
            const deleteSlotConfirmStateOperation = {
                slotDeletionContext: {
                    $set: activeProjectInitialState.slotDeletionContext,
                },
            };

            // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-514
            let slotLocked                      = false;
            const deleteSlotSlotSelectedProduct = activeProjectStateHelper.getSelectedProduct(state);

            if (slotType === SlotType.slot) {
                const deleteSlotCpuDefinition = _.find(
                    _.get(
                        deleteSlotSlotSelectedProduct,
                        'productData.layoutDefinition.cpu',
                        false,
                    ),
                    {
                        slot: slotIndex + 1,
                    },
                );

                if (
                    deleteSlotCpuDefinition &&
                    deleteSlotCpuDefinition.fix
                ) {
                    slotLocked = true;
                }
            } else if (slotType === SlotType.powerSupplyUnit) {
                const deleteSlotPsuDefinition = _.find(
                    _.get(
                        deleteSlotSlotSelectedProduct,
                        'productData.psuDefinition.psu',
                        false,
                    ),
                    {
                        slot: slotIndex + 1,
                    },
                );

                if (
                    deleteSlotPsuDefinition &&
                    deleteSlotPsuDefinition.fix
                ) {
                    slotLocked = true;
                }
            } else if (slotType === SlotType.fan) {
                const deleteSlotFanAddedAutomatically = _.get(
                    deleteSlotSlotSelectedProduct,
                    'fanAddedAutomatically',
                    false,
                );

                if (deleteSlotFanAddedAutomatically) {
                    slotLocked = deleteSlotFanAddedAutomatically;
                }
            }

            if (!slotLocked) {
                deleteSlotConfirmStateOperation.products = {
                    [state.selectedProduct.categoryType]: {
                        [state.selectedProduct.index]: {
                            subProducts: {
                                [slotType]: {
                                    [slotIndex]: {
                                        $set: ActiveProjectsFactory.getEmptyProductSlot(),
                                    },
                                },
                            },
                        },
                    },
                };

                if (slotIndex === state.selectedSlot.index) {
                    deleteSlotConfirmStateOperation.selectedSlot = {
                        $set: activeProjectInitialState.selectedSlot,
                    };
                }
            }

            //
            // <AutoAddFans>
            //
            const deleteSlotSelectedProduct = activeProjectStateHelper.getSelectedProduct(state);

            if (LimitationHelper.productRequiresFanInChassis(deleteSlotSelectedProduct)) {
                const deleteSlotSlot = activeProjectStateHelper.getSlotForTypeAndIndexInSelectedProduct(
                    state,
                    slotType,
                    slotIndex,
                );

                if (
                    slotType === SlotType.fan &&
                    !LimitationHelper.productHasModuleFan(deleteSlotSelectedProduct)
                ) {
                    const recommendsModuleFanInChassisLimitation = LimitationHelper.getSlotLimitation(
                        deleteSlotSelectedProduct,
                        Limitations.recommendsModuleFanInChassis,
                    );

                    if (recommendsModuleFanInChassisLimitation) {
                        const recommendedModuleFanSlot         = Cast.int(recommendsModuleFanInChassisLimitation.value);
                        const recommendedModuleFanSlotInternal = recommendedModuleFanSlot - 1;

                        _.set(
                            deleteSlotConfirmStateOperation,
                            (
                                `products[${
                                    state.selectedProduct.categoryType
                                }][${
                                    state.selectedProduct.index}].subProducts.slot[${
                                    recommendedModuleFanSlotInternal
                                }].productData`
                            ),
                            {
                                $set: DataProvider.getById('474-MODFAN'),  // TODO: Should not be hardcoded
                            },
                        );
                    }
                } else if (
                    slotType === SlotType.slot &&
                    deleteSlotSlot.type !== AccessoryTypes.fan &&
                    // Only add a chassis fan if the user is about to delete the
                    // last module fan
                    LimitationHelper.getModuleFanCount(deleteSlotSelectedProduct) === 1
                ) {
                    const extenderChassisAccessories = DataProvider.getExtenderChassisAccessories();
                    const matchingProducts           = LimitationHelper.filterProductListByLimitTo(
                        extenderChassisAccessories,
                        deleteSlotSelectedProduct.productData.partNo,
                        false,
                        SlotType.fan,
                    );

                    if (matchingProducts.length > 0) {
                        const fanToAdd = matchingProducts[0];

                        _.set(
                            deleteSlotConfirmStateOperation,
                            (
                                `products[${
                                    state.selectedProduct.categoryType
                                }][${
                                    state.selectedProduct.index}].subProducts.fan[0].productData`
                            ),
                            {
                                $set: fanToAdd,
                            },
                        );
                    }
                }
            }
            //
            // </AutoAddFans>
            //

            const deleteSlotConfirmNextState = update(state, deleteSlotConfirmStateOperation);

            return update(
                deleteSlotConfirmNextState,
                activeProjectStateHelper.recalculateSelectedProductMetaData(deleteSlotConfirmNextState),
            );
        },
        deselectProduct:                    (state, action) => {
            return update(state, {
                selectedProduct: {
                    $set: activeProjectInitialState.selectedProduct,
                },
            });
        },
        deselectSlot:                       (state, action) => {
            return update(state, {
                selectedSlot: {
                    $set: activeProjectInitialState.selectedSlot,
                },
            });
        },
        duplicateSelectedProductConfirm:    (state, action) => {
            const { amount }                                     = action.payload;
            const newState                                       = activeProjectStateHelper.ensureStateIsCorrect(state);
            const duplicateSelectedProductAmount                 = amount;
            const duplicateSelectedProductCurrentSelectedProduct = newState.products[newState.selectedProduct.categoryType][newState.selectedProduct.index];
            const duplicateSelectedProductNextState              = update(newState, {
                products:            {
                    [newState.selectedProduct.categoryType]: {
                        $push: ActiveProjectsFactory.duplicateProducts(
                            duplicateSelectedProductCurrentSelectedProduct,
                            duplicateSelectedProductAmount,
                        ),
                    },
                },
                lastDuplicateAmount: {
                    $set: duplicateSelectedProductAmount,
                },
            });
            const duplicateSelectedProductProductCount           = duplicateSelectedProductNextState.products[duplicateSelectedProductNextState.selectedProduct.categoryType].length;

            return update(duplicateSelectedProductNextState, {
                duplicatedProduct: {
                    categoryType: {
                        $set: duplicateSelectedProductNextState.selectedProduct.categoryType,
                    },
                    index:        {
                        $set: duplicateSelectedProductProductCount - 1,
                    },
                },
            });
        },
        increaseSingleOrderSlotAmount:      (state, action) => {
            const { categoryType, index } = action.payload;
            const newState                = activeProjectStateHelper.ensureStateIsCorrect(state);

            return update(newState, {
                singleOrders: {
                    [categoryType]: {
                        [index]: {
                            amount: {
                                $increaseBy: 1,
                            },
                        },
                    },
                },
            });
        },
        loadProject:                        (state, action) => {
            const { projectData } = action.payload;
            const loadedState     = update(state, {
                $set: projectData,
            });
            let updatedState      = activeProjectStateHelper.ensureStateIsCorrect(loadedState);
            updatedState          = update(updatedState, {
                downloadInProgress: {
                    $set: false,
                },
            });

            return updatedState;
        },
        toggleChassisGroup:                 (state, action) => {
            const { categoryType, groupIndex } = action.payload;
            let newState                       = _.clone(state);

            if (!newState.chassisGroupToggleContext) {
                newState = update(newState, {
                    chassisGroupToggleContext: {
                        $set: activeProjectInitialState.chassisGroupToggleContext,
                    },
                });
            }

            if (!newState.chassisGroupToggleContext[categoryType]) {
                newState = update(newState, {
                    chassisGroupToggleContext: {
                        [categoryType]: {
                            $set: {},
                        },
                    },
                });
            }

            newState = update(newState, {
                chassisGroupToggleContext: {
                    [categoryType]: {
                        $toggle: [groupIndex],
                    },
                },
            });

            return newState;
        },
        toggleDeletionOfProduct:            (state, action) => {
            const { categoryType, productIndex } = action.payload;
            let newState                         = activeProjectStateHelper.ensureStateIsCorrect(state);

            if (!newState.multipleProductsDeletionContext) {
                newState = update(newState, {
                    multipleProductsDeletionContext: {
                        $set: activeProjectInitialState.multipleProductsDeletionContext,
                    },
                });
            }

            newState = update(newState, {
                multipleProductsDeletionContext: {
                    [categoryType]: {
                        $toggle: [productIndex],
                    },
                },
            });

            return newState;
        },
        moveProduct:                        (state, action) => {
            const { categoryType, fromIndex, toIndex } = action.payload;
            const moveProductStateChange               = {
                products: {
                    [categoryType]: {
                        $splice: [
                            [
                                fromIndex,
                                1,
                            ],
                            [
                                toIndex,
                                0,
                                activeProjectStateHelper.getProductForCategoryTypeAndIndex(
                                    state,
                                    categoryType,
                                    fromIndex,
                                ),
                            ],
                        ],
                    },
                },
            };

            if (state.activeProductCategoryType === categoryType) {
                moveProductStateChange.lastSelectedProduct = {
                    index: {
                        $set: toIndex,
                    },
                };

                moveProductStateChange.selectedProduct = {
                    index: {
                        $set: toIndex,
                    },
                };
            }

            return update(state, moveProductStateChange);
        },
        moveSfp:                            (state, action) => {
            const { fromSfpIndex, toSfpIndex }  = action.payload;
            const moveSfpFromSfp                = activeProjectStateHelper.getSfpForIndexInSelectedSlot(
                state,
                fromSfpIndex,
            );
            const moveSfpNextStateConfiguration = {
                products: {
                    [state.selectedProduct.categoryType]: {
                        [state.selectedProduct.index]: {
                            subProducts: {
                                [state.selectedSlot.slotType]: {
                                    [state.selectedSlot.index]: {
                                        sfps: {
                                            [toSfpIndex]:   {
                                                $set: moveSfpFromSfp,
                                            },
                                            [fromSfpIndex]: {
                                                $set: ActiveProjectsFactory.getEmptyProductSlot(),
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            };

            return update(state, moveSfpNextStateConfiguration);
        },
        moveSingleOrderSlot:                (state, action) => {
            const { categoryType, fromIndex, toIndex } = action.payload;
            const moveSingleOrderSlotStateOperation    = {
                selectedSingleOrderSlot: {
                    categoryType: {
                        $set: categoryType,
                    },
                    index:        {
                        $set: toIndex,
                    },
                },
                singleOrders:            {
                    [categoryType]: {
                        [fromIndex]: {
                            $set: ActiveProjectsFactory.getEmptySingleOrderSlot(),
                        },
                        [toIndex]:   {
                            $set: activeProjectStateHelper.getSingleOrderSlotForTypeAndIndex(
                                state,
                                categoryType,
                                fromIndex,
                            ),
                        },
                    },
                },
            };

            // Check whether the user moved the selected slot away
            if (
                categoryType === state.selectedSingleOrderSlot.categoryType &&
                fromIndex === state.selectedSingleOrderSlot.index
            ) {
                moveSingleOrderSlotStateOperation.selectedSingleOrderSlot = {
                    $set: activeProjectInitialState.selectedSingleOrderSlot,
                };
            }

            return update(state, moveSingleOrderSlotStateOperation);
        },
        moveSlot:                           (state, action) => {
            const { fromSlotType, fromSlotIndex, toSlotIndex } = action.payload;
            const moveSlotFromSlot                             = activeProjectStateHelper.getSlotForTypeAndIndexInSelectedProduct(
                state,
                fromSlotType,
                fromSlotIndex,
            );
            const moveSlotSelectedProduct                      = activeProjectStateHelper.getSelectedProduct(state);
            const moveSlotProductData                          = moveSlotSelectedProduct.productData;
            const moveSlotStateOperation                       = SlotPositionCalculator.calculateNextState(
                {
                    $set: moveSlotFromSlot,
                },
                state,
                toSlotIndex,
                fromSlotType,
                moveSlotProductData,
                moveSlotFromSlot.productData,
                fromSlotIndex,
            );

            // Check whether the user moved the selected slot away
            if (fromSlotIndex === state.selectedSlot.index) {
                // Also remove the selected slot since it is empty
                // after the movement process
                moveSlotStateOperation.selectedSlot = {
                    $set: activeProjectInitialState.selectedSlot,
                };
            }

            const moveSlotNextState = update(state, moveSlotStateOperation);

            return update(
                moveSlotNextState,
                activeProjectStateHelper.recalculateSelectedProductMetaData(moveSlotNextState),
            );
        },
        newProject:                         (state, action) => {
            return update(state, {
                // Note: don't use activeProjectInitialState here since this would re-use a previously
                //       generated project id.
                $set: ActiveProjectsFactory.getInitialState(),
            });
        },
        openDownloadOverlay:                (state, action) => {
            const { partNumber } = action.payload;

            return update(state, {
                downloadOverlayPartNumber: {
                    $set: partNumber,
                },
            });
        },
        setOutdatedProducts:                (state, action) => {
            const { productIds } = action.payload;
            const newState       = activeProjectStateHelper.ensureStateIsCorrect(state);

            return update(newState, {
                outdatedProducts: {
                    $set: productIds,
                },
            });
        },
        reverseLayoutedProduct:             (state, action) => {
            const reverseLayoutedProductCurrentSelectedProduct = state.products[state.selectedProduct.categoryType][state.selectedProduct.index];
            const reverseLayoutedProductNextState              = update(state, {
                products: {
                    [state.selectedProduct.categoryType]: {
                        $push: [
                            ActiveProjectsFactory.reverseProduct(reverseLayoutedProductCurrentSelectedProduct),
                        ],
                    },
                },
            });
            const reversedSelectedProductProductCount          = reverseLayoutedProductNextState.products[reverseLayoutedProductNextState.selectedProduct.categoryType].length;
            const updatedStateWithReversedProduct              = update(reverseLayoutedProductNextState, {
                reversedProduct: {
                    categoryType: {
                        $set: reverseLayoutedProductNextState.selectedProduct.categoryType,
                    },
                    index:        {
                        $set: reversedSelectedProductProductCount - 1,
                    },
                },
            });

            return update(
                updatedStateWithReversedProduct,
                activeProjectStateHelper.recalculateAllProductMetaData(updatedStateWithReversedProduct),
            );
        },
        recalculateAllProductMetaData:      (state, action) => {
            const newState = update(
                state,
                activeProjectStateHelper.recalculateAllProductMetaData(state),
            );

            return newState;
        },
        selectProduct:                      (state, action) => {
            const { categoryType, index } = action.payload;

            return update(state, {
                activeProductCategoryType: {
                    $set: categoryType,
                },
                lastSelectedProduct:       {
                    categoryType: {
                        $set: categoryType,
                    },
                    index:        {
                        $set: index,
                    },
                },
                selectedProduct:           {
                    categoryType: {
                        $set: categoryType,
                    },
                    index:        {
                        $set: index,
                    },
                },
                selectedSlot:              {
                    $set: activeProjectInitialState.selectedSlot,
                },
            });
        },
        selectSlot:                         (state, action) => {
            const { index, slotType } = action.payload;

            return update(state, {
                selectedSlot: {
                    index:    {
                        $set: Cast.int(index),
                    },
                    slotType: {
                        $set: slotType,
                    },
                },
            });
        },
        selectSingleOrderSlot:              (state, action) => {
            const { categoryType, index } = action.payload;

            return update(state, {
                selectedSingleOrderSlot: {
                    categoryType: {
                        $set: categoryType,
                    },
                    index:        {
                        $set: Cast.int(index),
                    },
                },
            });
        },
        setActiveProductCategoryType:       (state, action) => {
            const { productCategoryType }               = action.payload;
            const setActiveProductCategoryTypeChangeSet = {
                activeProductCategoryType: {
                    $set: productCategoryType,
                },
            };

            if (
                productCategoryType &&
                state.selectedProduct.categoryType !== productCategoryType
            ) {
                let setActiveProductCategoryTypeChangeSetSelectedProduct = {
                    $set: activeProjectInitialState.selectedProduct,
                };

                if (_.get(state, `products[${productCategoryType}].length`, 0) > 0) {
                    setActiveProductCategoryTypeChangeSetSelectedProduct = {
                        categoryType: {
                            $set: productCategoryType,
                        },
                        index:        {
                            $set: 0,
                        },
                    };
                }

                setActiveProductCategoryTypeChangeSet.selectedProduct     = setActiveProductCategoryTypeChangeSetSelectedProduct;
                setActiveProductCategoryTypeChangeSet.lastSelectedProduct = setActiveProductCategoryTypeChangeSetSelectedProduct;
            }

            return update(state, setActiveProductCategoryTypeChangeSet);
        },
        setDescription:                     (state, action) => {
            const { description } = action.payload;

            return update(state, {
                metaData: {
                    description: {
                        $set: String.removeIllegalCharacters(description),
                    },
                },
            });
        },
        setIhseProjectNumber:               (state, action) => {
            const { ihseProjectNumber } = action.payload;

            return update(state, {
                metaData: {
                    ihseProjectNumber: {
                        $set: String.removeIllegalCharacters(ihseProjectNumber),
                    },
                },
            });
        },
        setNameOfResponsiblePerson:         (state, action) => {
            const { nameOfResponsiblePerson } = action.payload;

            return update(state, {
                metaData: {
                    nameOfResponsiblePerson: {
                        $set: String.removeIllegalCharacters(nameOfResponsiblePerson),
                    },
                },
            });
        },
        setOfferNumber:                     (state, action) => {
            const { offerNumber } = action.payload;

            return update(state, {
                metaData: {
                    offerNumber: {
                        $set: String.removeIllegalCharacters(offerNumber),
                    },
                },
            });
        },
        setSfp:                             (state, action) => {
            const { productId, sfpIndex }      = action.payload;
            const setSfpSlotProductData        = DataProvider.getById(productId);
            const setSfpNextData               = {
                customName:        {
                    $set: '',
                },
                nameChangedByUser: {
                    $set: false,
                },
                productData:       {
                    $set: setSfpSlotProductData,
                },
                warnings:          {
                    $set: [],
                },
            };
            const setSfpNextStateConfiguration = {
                products: {
                    [state.selectedProduct.categoryType]: {
                        [state.selectedProduct.index]: {
                            subProducts: {
                                [state.selectedSlot.slotType]: {
                                    [state.selectedSlot.index]: {
                                        sfps: {
                                            [sfpIndex]: setSfpNextData,
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            };

            return update(state, setSfpNextStateConfiguration);
        },
        setProductSlot:                     (state, action) => {
            const { productId, slotIndex, targetSlotType } = action.payload;
            const setProductSlotSelectedProduct            = activeProjectStateHelper.getSelectedProduct(state);
            const setProductSlotProductData                = setProductSlotSelectedProduct.productData;
            const setProductSlotSlotProductData            = DataProvider.getById(productId);
            const setProductSlotNextSlotData               = {
                customName:        {
                    $set: '',
                },
                nameChangedByUser: {
                    $set: false,
                },
                productData:       {
                    $set: setProductSlotSlotProductData,
                },
                warnings:          {
                    $set: [],
                },
            };

            if (setProductSlotSlotProductData.configurableSfpCount) {
                setProductSlotNextSlotData.sfps = {
                    $set: ActiveProjectsFactory.getEmptyProductSlots(setProductSlotSlotProductData.configurableSfpCount),
                };
            }

            if (
                setProductSlotSlotProductData.categoryType === ProductCategoryType.matrix &&
                setProductSlotSlotProductData.subCategoryType === ProductSubCategoryTypes.flex &&
                setProductSlotSlotProductData.productSlotType === ProductSlotType.frontPlate
            ) {
                const subSlotCount = setProductSlotSlotProductData.slotCount;
                const subSlots     = ActiveProjectsFactory.getEmptyProductSlots(subSlotCount);

                for (let subSlotIndex = 0; subSlotIndex < subSlotCount; ++subSlotIndex) {
                    for (const setProductSlotSlotProductDataLimitation of setProductSlotSlotProductData.limitations) {
                        if (setProductSlotSlotProductDataLimitation.type === Limitations.limitToCardsInSlot) {
                            const splittedLimitToCardsInSlotLimitationValue        = setProductSlotSlotProductDataLimitation.value.split(',');
                            const splittedLimitToCardsInSlotLimitationSubSlotIndex = Cast.int(splittedLimitToCardsInSlotLimitationValue[0]) - 1;

                            if (splittedLimitToCardsInSlotLimitationSubSlotIndex === subSlotIndex) {
                                const splittedLimitToCardsInSlotLimitationPartNumber = splittedLimitToCardsInSlotLimitationValue[1].trim();
                                subSlots[subSlotIndex].productData                   = DataProvider.getById(splittedLimitToCardsInSlotLimitationPartNumber);

                                break;
                            }
                        }
                    }
                }

                setProductSlotNextSlotData.subSlots = {
                    $set: subSlots,
                };
            }

            const setProductSlotNextStateConfiguration = SlotPositionCalculator.calculateNextState(
                setProductSlotNextSlotData,
                state,
                slotIndex,
                targetSlotType,
                setProductSlotProductData,
                setProductSlotSlotProductData,
            );
            const setProductSlotNextState              = update(state, setProductSlotNextStateConfiguration);

            return update(
                setProductSlotNextState,
                activeProjectStateHelper.recalculateSelectedProductMetaData(setProductSlotNextState),
            );
        },
        setSelectedProductCustomName:       (state, action) => {
            const { customName } = action.payload;

            return update(state, {
                products: {
                    [state.selectedProduct.categoryType]: {
                        [state.selectedProduct.index]: {
                            customName:        {
                                $set: String.removeIllegalCharacters(customName),
                            },
                            nameChangedByUser: {
                                $set: true,
                            },
                        },
                    },
                },
            });
        },
        setLastSelectedProduct:             (state, action) => {
            const { index } = action.payload;

            if (state.lastSelectedProduct.categoryType) {
                const nextLastSelectedProduct = activeProjectStateHelper.getNextLastSelectedProduct(
                    state.products,
                    index,
                    state.lastSelectedProduct.categoryType,
                );

                return update(state, {
                    lastSelectedProduct: {
                        $set: nextLastSelectedProduct,
                    },
                    selectedProduct:     {
                        $set: nextLastSelectedProduct,
                    },
                });
            }

            return state;
        },
        setSelectedProductData:             setSelectedProductData,
        setSelectedProductDataCheckConfirm: setSelectedProductData,
        setSelectedProductDataCheck:        (state, action) => {
            const { productId } = action.payload;

            return update(state, {
                setSelectedProductIdConfirm: {
                    $set: productId,
                },
            });
        },
        setSelectedProductDataCheckCancel:  (state, action) => {
            return update(state, {
                setSelectedProductIdConfirm: {
                    $set: null,
                },
            });
        },
        setSelectedProductOtherComment:     (state, action) => {
            const { otherComment } = action.payload;

            return update(state, {
                products: {
                    [state.selectedProduct.categoryType]: {
                        [state.selectedProduct.index]: {
                            otherComment: {
                                $set: String.removeIllegalCharacters(otherComment),
                            },
                        },
                    },
                },
            });
        },
        setSelectedSlotCustomName:          (state, action) => {
            const { customName } = action.payload;

            return update(state, {
                products: {
                    [state.selectedProduct.categoryType]: {
                        [state.selectedProduct.index]: {
                            subProducts: {
                                [state.selectedSlot.slotType]: {
                                    [state.selectedSlot.index]: {
                                        customName:        {
                                            $set: customName,
                                        },
                                        nameChangedByUser: {
                                            $set: true,
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            });
        },
        setSelectedSlotExtenderId:          (state, action) => {
            const { extenderId } = action.payload;

            return update(state, {
                products: {
                    [state.selectedProduct.categoryType]: {
                        [state.selectedProduct.index]: {
                            subProducts: {
                                [state.selectedSlot.slotType]: {
                                    [state.selectedSlot.index]: {
                                        extenderId: {
                                            $set: extenderId,
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            });
        },
        setSelectedSlotOtherComment:        (state, action) => {
            const { otherComment } = action.payload;

            return update(state, {
                products: {
                    [state.selectedProduct.categoryType]: {
                        [state.selectedProduct.index]: {
                            subProducts: {
                                [state.selectedSlot.slotType]: {
                                    [state.selectedSlot.index]: {
                                        otherComment: {
                                            $set: otherComment,
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            });
        },
        setTitle:                           (state, action) => {
            const { title } = action.payload;

            return update(state, {
                metaData: {
                    title: {
                        $set: String.removeIllegalCharacters(title),
                    },
                },
            });
        },
        setSingleOrderSlot:                 (state, action) => {
            const { productId, categoryType, singleOrderIndex } = action.payload;
            const singleSlotProductData                         = DataProvider.getById(productId);

            return update(state, {
                activeProductCategoryType: {
                    $set: categoryType,
                },
                selectedSingleOrderSlot:   {
                    categoryType: {
                        $set: categoryType,
                    },
                    index:        {
                        $set: singleOrderIndex,
                    },
                },
                singleOrders:              {
                    [categoryType]: {
                        [singleOrderIndex]: {
                            amount:      {
                                $set: 1,
                            },
                            productData: {
                                $set: singleSlotProductData,
                            },
                        },
                    },
                },
            });
        },
        setSingleOrderSlotAmount:           (state, action) => {
            const { amount, categoryType, index } = action.payload;

            return update(state, {
                singleOrders: {
                    [categoryType]: {
                        [index]: {
                            amount: {
                                $set: amount,
                            },
                        },
                    },
                },
            });
        },
        setProductDeletionMode:             (state, action) => {
            const { deleteModeActive } = action.payload;

            return update(state, {
                deleteModeActive:                {
                    $set: deleteModeActive,
                },
                multipleProductsDeletionContext: {
                    $set: activeProjectInitialState.multipleProductsDeletionContext,
                },
            });
        },
        setSubSlot:                         (state, action) => {
            const { productId, subSlotIndex }      = action.payload;
            const subSlotProductData               = DataProvider.getById(productId);
            const setSubSlotNextData               = {
                customName:        {
                    $set: '',
                },
                nameChangedByUser: {
                    $set: false,
                },
                productData:       {
                    $set: subSlotProductData,
                },
                warnings:          {
                    $set: [],
                },
            };
            const setSubSlotNextStateConfiguration = {
                products: {
                    [state.selectedProduct.categoryType]: {
                        [state.selectedProduct.index]: {
                            subProducts: {
                                [state.selectedSlot.slotType]: {
                                    [state.selectedSlot.index]: {
                                        subSlots: {
                                            [subSlotIndex]: setSubSlotNextData,
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            };

            return update(state, setSubSlotNextStateConfiguration);
        },
        toggleProductDeletionMode:          (state, action) => {
            const newState = activeProjectStateHelper.ensureStateIsCorrect(state);

            return update(newState, {
                $toggle: ['deleteModeActive'],
            });
        },
        toggleModuleDeletion:               (state, action) => {
            const { partNo, totalCount } = action.payload;
            const allModulesPartNumber   = 'ALL';
            const partNumbers            = Cast.array(partNo);
            let fixedState               = state;

            if (!state.deleteModulesContext) {
                // Fix the state in the case of the user is
                // managing a legacy project
                fixedState = update(fixedState, {
                    deleteModulesContext: {
                        $set: activeProjectInitialState.deleteModulesContext,
                    },
                });
            }

            // Set all modules to the opposite value of the "all" value
            // when the "all" fake part number is part of the set
            // (this makes sense since we want to overwrite all checkbox
            // states with the state from the "all modules" checkbox)
            if (partNumbers.indexOf(allModulesPartNumber) > -1) {
                const toggleChangeSet = {};

                for (const partNumber of partNumbers) {
                    toggleChangeSet[partNumber] = {
                        $set: !fixedState.deleteModulesContext.modules[allModulesPartNumber],
                    };
                }

                return update(fixedState, {
                    deleteModulesContext: {
                        modules: toggleChangeSet,
                    },
                });
            }

            const nextState    = update(fixedState, {
                deleteModulesContext: {
                    modules: {
                        $toggle: partNumbers,
                    },
                },
            });
            const moduleCounts = {
                total:     totalCount,
                checked:   0,
                unchecked: 0,
            };

            for (const moduleNumber in nextState.deleteModulesContext.modules) {
                if (moduleNumber !== allModulesPartNumber) {
                    if (nextState.deleteModulesContext.modules[moduleNumber]) {
                        ++moduleCounts.checked;
                    } else {
                        ++moduleCounts.unchecked;
                    }
                }
            }

            // This will automatically check and uncheck the "all modules" checkbox
            // if the user manually checks all modules or unchecks at least one
            if (
                (
                    !nextState.deleteModulesContext.modules[allModulesPartNumber] &&
                    moduleCounts.total === moduleCounts.checked
                ) ||
                (
                    nextState.deleteModulesContext.modules[allModulesPartNumber] &&
                    moduleCounts.total !== moduleCounts.unchecked
                )
            ) {
                return update(nextState, {
                    deleteModulesContext: {
                        modules: {
                            $toggle: [allModulesPartNumber],
                        },
                    },
                });
            }

            return nextState;
        },
        updateOutdatedProductData:          (state, action) => {
            const changeSet    = activeProjectStateHelper.getUpdateDataChangeSet(state);
            const updatedState = update(state, changeSet);
            const newState     = activeProjectStateHelper.ensureStateIsCorrect(updatedState);

            return update(
                newState,
                activeProjectStateHelper.recalculateAllProductMetaData(updatedState),
            );
        },
        toggleModuleSelection:              (state, action) => {
            const { payload }            = action;
            const { partNo, totalCount } = payload;
            const allModulesPartNumber   = 'ALL';
            const partNumbers            = Cast.array(partNo);
            let fixedState               = state;

            if (!state.deleteModulesContext) {
                // Fix the state in the case of the user is
                // managing a legacy project
                fixedState = update(fixedState, {
                    deleteModulesContext: {
                        $set: activeProjectInitialState.deleteModulesContext,
                    },
                });
            }

            // Set all modules to the opposite value of the "all" value
            // when the "all" fake part number is part of the set
            // (this makes sense since we want to overwrite all checkbox
            // states with the state from the "all modules" checkbox)
            if (partNumbers.indexOf(allModulesPartNumber) > -1) {
                const toggleChangeSet = {};

                for (const partNumber of partNumbers) {
                    toggleChangeSet[partNumber] = {
                        $set: !fixedState.deleteModulesContext.modules[allModulesPartNumber],
                    };
                }

                return update(fixedState, {
                    deleteModulesContext: {
                        modules: toggleChangeSet,
                    },
                });
            } else {
                const nextState = update(fixedState, {
                    deleteModulesContext: {
                        modules: {
                            $toggle: partNumbers,
                        },
                    },
                });

                const moduleCounts = {
                    total:     totalCount,
                    checked:   0,
                    unchecked: 0,
                };

                for (const partNo in nextState.deleteModulesContext.modules) {
                    if (partNo !== allModulesPartNumber) {
                        if (nextState.deleteModulesContext.modules[partNo]) {
                            ++moduleCounts.checked;
                        } else {
                            ++moduleCounts.unchecked;
                        }
                    }
                }

                // This will automatically check and uncheck the "all modules" checkbox
                // if the user manually checks all modules or unchecks at least one
                if (
                    (
                        !nextState.deleteModulesContext.modules[allModulesPartNumber] &&
                        moduleCounts.total === moduleCounts.checked
                    ) ||
                    (
                        nextState.deleteModulesContext.modules[allModulesPartNumber] &&
                        moduleCounts.total !== moduleCounts.unchecked
                    )
                ) {
                    return update(nextState, {
                        deleteModulesContext: {
                            modules: {
                                $toggle: [allModulesPartNumber],
                            },
                        },
                    });
                }

                return nextState;
            }
        },
        setDownloadInProgress:              (state, action) => {
            const { downloadInProgress } = action.payload;

            return update(state, {
                downloadInProgress: {
                    $set: downloadInProgress,
                },
            });
        },
        downloadPdf:                        (state, action) => {
            return state;
        },
        downloadPdfStarted:                 (state, action) => {
            return update(state, {
                downloadInProgress: {
                    $set: true,
                },
            });
        },
        downloadPdfSuccess:                 (state, action) => {
            return update(state, {
                downloadInProgress: {
                    $set: false,
                },
            });
        },
        downloadPdfFailed:                  (state, action) => {
            return update(state, {
                downloadInProgress: {
                    $set: false,
                },
            });
        },
    },
    extraReducers: (builder) => {
        builder.addCase(DebugActions.resetAll().type, (state, action) => {
            return update(state, {
                $set: initialState,
            });
        });
        builder.addCase(ImportActions.importToActiveProject().type, (state, action) => {
            const importToActiveProjectProjectData             = {
                ...action.payload.projectData,
            };
            const importToActiveProjectUpdateProductIds        = (products) => {
                for (const product of products) {
                    product.id = `${IdGenerator.getNewProductId()}-import`;
                }
            };
            const importToActiveProjectRemoveEmptySingleOrders = (singleOrders) => {
                return _.filter(singleOrders, 'productData');
            };

            importToActiveProjectUpdateProductIds(importToActiveProjectProjectData.products.extender);
            importToActiveProjectUpdateProductIds(importToActiveProjectProjectData.products.matrix);
            importToActiveProjectUpdateProductIds(importToActiveProjectProjectData.products.fullIp);

            importToActiveProjectProjectData.singleOrders.equipment = importToActiveProjectRemoveEmptySingleOrders(importToActiveProjectProjectData.singleOrders.equipment);

            importToActiveProjectProjectData.singleOrders.extender = importToActiveProjectRemoveEmptySingleOrders(importToActiveProjectProjectData.singleOrders.extender);

            importToActiveProjectProjectData.singleOrders.matrix = importToActiveProjectRemoveEmptySingleOrders(importToActiveProjectProjectData.singleOrders.matrix);

            importToActiveProjectProjectData.singleOrders.fullIp = importToActiveProjectRemoveEmptySingleOrders(importToActiveProjectProjectData.singleOrders.fullIp);

            const importToActiveProjectStateChange = {
                products:     {
                    extender: {
                        $push: importToActiveProjectProjectData.products.extender,
                    },
                    matrix:   {
                        $push: importToActiveProjectProjectData.products.matrix,
                    },
                    fullIp:   {
                        $push: importToActiveProjectProjectData.products.fullIp,
                    },
                },
                singleOrders: {
                    equipment: {
                        $push: importToActiveProjectProjectData.singleOrders.equipment,
                    },
                    extender:  {
                        $push: importToActiveProjectProjectData.singleOrders.extender,
                    },
                    matrix:    {
                        $push: importToActiveProjectProjectData.singleOrders.matrix,
                    },
                    fullIp:    {
                        $push: importToActiveProjectProjectData.singleOrders.fullIp,
                    },
                },
            };
            const importToActiveProjectNextState   = update(state, importToActiveProjectStateChange);

            return update(
                importToActiveProjectNextState,
                activeProjectStateHelper.recalculateAllProductMetaData(importToActiveProjectNextState),
            );
        });
        builder.addCase(ImportActions.importProductUpdates().type, (state, action) => {
            const { projectData } = action;
            let changedState      = {
                ...state,
            };

            const projectDataChanges = _.map(projectData, (projectDataEntry) => {
                let productName                = _.get(projectDataEntry, AbasColumns.productName, '');
                const slotName                 = _.get(projectDataEntry, AbasColumns.slotName, '');
                const productId                = _.get(projectDataEntry, AbasColumns.productId, '');
                const productTypesToCheck      = [
                    ProductCategoryType.extender,
                    ProductCategoryType.matrix,
                ];
                let productIndex               = Cast.int(_.get(projectDataEntry, AbasColumns.productPosition, ''));
                let slotIndex                  = -1;
                let handleSlotInsteadOfChassis = false;

                if (slotName) {
                    handleSlotInsteadOfChassis = true;
                    productName                = slotName;
                    [productIndex, slotIndex]  = _.get(projectDataEntry, AbasColumns.productAndSlotIndex, '')
                        .split('.')
                        .map((chunk) => Cast.int(chunk));
                    if (productName.toLowerCase().startsWith('i')) {
                        productName = productName.substr(1);
                    }

                    if (productName.indexOf('_') !== -1) {
                        productName = productName.substr(
                            0,
                            productName.indexOf((
                                '_'
                            )),
                        );
                    }
                }

                productIndex = (
                    productIndex / 10
                ) - 1;
                slotIndex -= 1;

                for (let productTypeIndex = 0; productTypeIndex < productTypesToCheck.length; productTypeIndex++) {
                    const productType  = productTypesToCheck[productTypeIndex];
                    let partNumberPath = [
                        'products',
                        productType,
                        productIndex,
                        'productData',
                        'partNo',
                    ];

                    if (handleSlotInsteadOfChassis) {
                        partNumberPath = [
                            'products',
                            productType,
                            productIndex,
                            'subProducts',
                            SlotType.slot,
                            slotIndex,
                            'productData',
                            'partNo',
                        ];
                    }

                    const partNumber = _.get(state, partNumberPath, null);

                    if (
                        partNumber &&
                        partNumber === productName &&
                        handleSlotInsteadOfChassis
                    ) {
                        return {
                            products: {
                                [productType]: {
                                    [productIndex]: {
                                        subProducts: {
                                            [SlotType.slot]: {
                                                [slotIndex]: {
                                                    extenderId: {
                                                        $set: productId,
                                                    },
                                                },
                                            },
                                        },
                                    },
                                },
                            },
                        };
                    }
                }

                return null;
            });

            _.each(projectDataChanges, (changes) => {
                if (changes) {
                    changedState = update(changedState, changes);
                }
            });

            return changedState;
        });
        builder.addCase(ExportActions.exportDsdJsonSuccess().type, (state, action) => {
            return update(state, {
                lastExportDate: {
                    $set: new Date(),
                },
            });
        });
        builder.addCase(ExportActions.exportAbasSuccess().type, (state, action) => {
            return update(state, {
                lastExportDate: {
                    $set: new Date(),
                },
            });
        });
    },
});

export const ActiveProjectReducer = activeProjectSlice.reducer;

export const ActiveProjectActions = activeProjectSlice.actions;

export const useActiveProject = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default activeProjectSlice;

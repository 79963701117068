//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames          from 'classnames';
import { withTranslation } from 'react-i18next';

import VideoConnectorTypes from '@constants/VideoConnectorTypes';
import SelectionHelper     from '@helper/SelectionHelper';

import VideoConnectorLabelAlignment from './VideoConnectorLabelAlignment';
import styles                       from './styles.module.scss';
import Icon                         from '../Icon';
import ProductDataValueLabel        from '../ProductDataValueLabel';
import PropTypes                    from '../PropTypes';

const getIconType = () => {
    // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-705
    return null;
};

const getTitle = (videoConnector) => {
    return SelectionHelper.getTranslation(
        videoConnector,
        {
            [VideoConnectorTypes.videoConnectorTypeBnc]:         'videoConnectorTypeBnc',
            [VideoConnectorTypes.videoConnectorTypeDisplayport]: 'videoConnectorTypeDisplayport',
            [VideoConnectorTypes.videoConnectorTypeDpHdmi]:      'videoConnectorTypeDpHdmi',
            [VideoConnectorTypes.videoConnectorTypeDualhead]:    'videoConnectorTypeDualhead',
            [VideoConnectorTypes.videoConnectorTypeDuallink]:    'videoConnectorTypeDuallink',
            [VideoConnectorTypes.videoConnectorTypeDvid]:        'videoConnectorTypeDvid',
            [VideoConnectorTypes.videoConnectorTypeDvii]:        'videoConnectorTypeDvii',
            [VideoConnectorTypes.videoConnectorTypeHdbnc]:       'videoConnectorTypeHdbnc',
            [VideoConnectorTypes.videoConnectorTypeHdmi]:        'videoConnectorTypeHdmi',
            [VideoConnectorTypes.videoConnectorTypeIp]:          'videoConnectorTypeIp',
        },
    );
};

const Component = ({
    alignment,
    videoConnector,
    value,
}) => {
    const renderIcon        = (iconType) => {
        if (iconType) {
            return (
                <span className={styles.videoConnectorLabelIconWrapper}>
                    <Icon iconType={iconType} />
                </span>
            );
        }
        return null;
    };
    const renderIconAndText = (title) => {
        const iconType = getIconType();

        return (
            <>
                {renderIcon(iconType)}
                <span
                    className={classNames(
                        styles.audioText,
                        {
                            [styles.withoutIcon]: !iconType,
                        },
                    )}
                >
                    {title}
                </span>
            </>
        );
    };

    if (videoConnector) {
        const title = getTitle(videoConnector);

        if (!title) {
            console.warn('Unsupported video connector', videoConnector);
        }
        return (
            <span
                className={classNames(
                    styles.videoConnectorLabel,
                    SelectionHelper.get(
                        alignment,
                        {
                            [VideoConnectorLabelAlignment.left]:  styles.videoConnectorLabelLeft,
                            [VideoConnectorLabelAlignment.right]: styles.videoConnectorLabelRight,
                        },
                    ),
                )}
                title={title}
            >
                {renderIconAndText(title)}
            </span>
        );
    }

    return (
        <ProductDataValueLabel value={value} />
    );
};

Component.propTypes = {
    alignment:      PropTypes.oneOf(Object.values(VideoConnectorLabelAlignment)),
    videoConnector: PropTypes.string,
};

Component.defaultProps = {
    alignment:      null,
    videoConnector: null,
};

export default withTranslation()(Component);

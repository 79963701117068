import React from 'react';

import ProductDataValueLabel from '../ProductDataValueLabel';
import PropTypes from '../PropTypes';

const Component = ({ value }) => (
    <ProductDataValueLabel value={value} />
);

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ProductDataKeys from './ProductDataKeys';

// @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-189
export default [
    ProductDataKeys.VIDEO_CONNECTOR,
    ProductDataKeys.RESOLUTION,
    ProductDataKeys.USB,
    ProductDataKeys.POWER_CONSUMPTION_IN_MILLIAMPS,
    ProductDataKeys.DATA_TRANSFER,
    ProductDataKeys.NUMBER_OF_MONITORS,
    ProductDataKeys.SUPPORTS_PIGGY_BACK,
    ProductDataKeys.LINK_RESOLUTION,

    // Should be always the last since it may be multiline
    ProductDataKeys.DESCRIPTION,
];

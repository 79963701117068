//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n      from 'i18next';
import { Trans } from 'react-i18next';

import * as Configuration from '@helper/Configuration';

import Spacer            from '../Spacer';
import TextContainer     from '../TextContainer';
import TextHeadline      from '../TextHeadline';
import TextHeadlineTheme from '../TextHeadline/TextHeadlineTheme';
import TextLong          from '../TextLong';

class IHSEContactContent extends React.Component {
    render() {
        const getHeadlineText        = () => {
            const salesEmail = Configuration.getSalesEmail();

            return (
                <Trans
                    i18nKey={'contactMailTitle'}
                    values={{
                        salesEmail,
                    }}
                >
                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                    <a href={`mailto:${salesEmail}`} />
                </Trans>
            );
        };
        const getPhoneHintText       = () => {
            const contactOtherLocationsLink = Configuration.getContactOtherLocationsLink();

            return (
                <Trans
                    i18nKey={'contactPhoneHintText'}
                >
                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                    <a href={contactOtherLocationsLink} />
                </Trans>
            );
        };
        const getLocationNumberTexts = () => {
            const phoneItems = Configuration.getContact('phone');

            if (!phoneItems) {
                return null;
            }

            return phoneItems.map((phoneItem, index) => {
                const { number, location } = phoneItem;
                const text                 = `${location} - ${number}`;

                return (
                    <TextLong
                        text={text}
                        key={index}
                    />
                );
            });
        };

        return (
            <div>
                <TextContainer>
                    <TextHeadline
                        theme={TextHeadlineTheme.h3BlackBold}
                        text={getHeadlineText()}
                    />
                    <TextHeadline
                        theme={TextHeadlineTheme.h4Black}
                        text={I18n.t('contactPhoneRequest')}
                    />
                    {getLocationNumberTexts()}
                    <Spacer />
                    <TextLong text={getPhoneHintText()} />
                </TextContainer>
            </div>
        );
    }
}

IHSEContactContent.propTypes = {};

IHSEContactContent.defaultProps = {};

export default IHSEContactContent;

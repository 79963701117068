//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                from 'i18next';
import _                   from 'lodash';
import { withTranslation } from 'react-i18next';

import styles    from './styles.module.scss';
import Icon      from '../Icon';
import IconType  from '../Icon/IconType';
import PropTypes from '../PropTypes';

const Component = ({
    deleteButtonPressed = _.noop,
    fileName            = '',
}) => (
    <div className={styles.uploadedFilePreview}>
        <div className={styles.uploadedFilePreviewFileIcon}>
            <Icon iconType={IconType.file} />
        </div>
        <h4>{I18n.t('chosenFile')}</h4>
        <strong>{fileName}</strong>
        <div
            className={styles.uploadedFilePreviewFileDeleteButton}
            onClick={deleteButtonPressed}
        >
            <Icon iconType={IconType.close} />
        </div>
    </div>
);

Component.propTypes = {
    deleteButtonPressed: PropTypes.func,
    fileName:            PropTypes.string,
};

export default withTranslation()(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { compose }            from 'redux';

import { ActiveProjectActions }   from '@slices/activeProject';
import Overlays                   from '@constants/Overlays';
import ProductSlotType            from '@constants/ProductSlotType';
import Routes                     from '@constants/Routes';
import SlotType                   from '@constants/SlotType';
import Cast                       from '@helper/Cast';
import DataProvider               from '@helper/DataProvider';
import ProductHelper              from '@helper/Product';
import StateHelper                from '@helper/State';
import PowerConsumptionCalculator from '@store/calculators/powerConsumption';

import ErrorBox     from '../ErrorBox';
import Input        from '../Input';
import InputType    from '../Input/InputType';
import Overlay      from '../Overlay';
import OverlayType  from '../Overlay/OverlayType';
import OverlayPane  from '../OverlayPane';
import TextHeadline from '../TextHeadline';

class Component extends React.Component {
    parentOkButtonPressed = null;

    constructor(props) {
        super(props);

        this.state = {
            amount: 1,
            error:  null,
        };
    }

    amountChanged = (event) => {
        this.setState({
            amount: Cast.int(event.target.value),
        });
    };

    errorBoxCloseButtonPressed = () => {
        this.setState({
            error: null,
        });
    };

    getOkButtonPressedCallback = (parentOkButtonPressed) => {
        this.parentOkButtonPressed = parentOkButtonPressed;
    };

    okButtonValidator = () => {
        if (
            this.state.amount !== null &&
            this.state.amount !== '' &&
            this.state.amount > 1
        ) {
            const selectedProductIoSlots = _.get(this.props.selectedProduct, 'productData.layoutDefinition.io', []);
            const autoFillSlots          = this.props.autoFillSlotData;
            let amount                   = Math.min(
                Cast.int(autoFillSlots.freeSlotsAfterSlotIndex),
                this.state.amount - 1,
            );

            if (this.powerConsumptionAfterAutoFillIsUnderThreshold(
                this.props.selectedProduct,
                this.props.autoFillSlotData.productId,
                amount,
                autoFillSlots,
            )) {
                for (let index = 0; index < amount; ++index) {
                    const copySlotIndex = autoFillSlots.slotIndex + index + 1;

                    if (selectedProductIoSlots.indexOf(copySlotIndex + 1) > -1) {
                        this.props.setProductSlot({
                            confirmed:      true,
                            isBulk:         true,
                            productId:      autoFillSlots.productId,
                            slotIndex:      copySlotIndex,
                            targetSlotType: SlotType.slot,
                        });
                    } else {
                        // Increase the amount since we skipped a cpu card
                        ++amount;
                    }
                }
            } else {
                this.powerConsumptionThresholdError();

                return false;
            }
        }

        return true;
    };

    powerConsumptionAfterAutoFillIsUnderThreshold = (selectedProduct, productId, amount, autoFillSlots) => {
        const productData         = DataProvider.getById(productId);
        const selectedProductCopy = _.cloneDeep(selectedProduct);

        for (let index = 0; index < amount; ++index) {
            if (productData.productSlotType === ProductSlotType.ioBoard) {
                const freeSlotsAfterSlotIndex = ProductHelper.getNextEmptySlotIndex(selectedProductCopy);

                if (freeSlotsAfterSlotIndex > 0) {
                    selectedProductCopy.subProducts[SlotType.slot][freeSlotsAfterSlotIndex].productData = {
                        ...productData,
                    };
                }
            }
        }

        const powerConsumption = PowerConsumptionCalculator.getPowerConsumption(
            selectedProductCopy,
            selectedProductCopy.subProducts,
        );

        return (
            powerConsumption
            <=
            this.props.selectedProduct.productData.maximumPowerConsumptionInMilliamps
        );
    };

    powerConsumptionThresholdError = () => {
        this.setError(I18n.t('repeatSlotAmountPowerConsumptionError'));
    };

    render() {
        const autoFillSlots = this.props.autoFillSlotData;

        if (autoFillSlots) {
            return (
                <Overlay
                    allowDontShowAgain={true}
                    id={Overlays.fillSlots}
                    getOkButtonPressedCallback={this.getOkButtonPressedCallback}
                    nextRoute={Routes.designerProductDetails}
                    okButtonValidator={this.okButtonValidator}
                    overlayType={OverlayType.prompt}
                    title={I18n.t('repeatSlotTitle')}
                >
                    <TextHeadline
                        text={I18n.t(
                            'repeatSlotText',
                            {
                                productId: autoFillSlots.productId,
                                slotIndex: autoFillSlots.slotIndex,
                                freeSlots: autoFillSlots.freeSlotsAfterSlotIndex,
                            },
                        )}
                    />
                    {this.renderErrorBox()}
                    {this.renderAmountTextInput()}
                </Overlay>
            );
        }

        return null;
    }

    renderAmountTextInput = () => {
        const autoFillSlots = this.props.autoFillSlotData;

        return (
            <OverlayPane>
                <Input
                    autoFocus={true}
                    max={Cast.int(autoFillSlots.freeSlotsAfterSlotIndex)}
                    min={1}
                    minLength={1}
                    onEnterKeyPress={this.parentOkButtonPressed}
                    placeholder={I18n.t('repeatSlotAmountPlaceholder')}
                    text={I18n.t('repeatSlotAmount')}
                    tooltip={I18n.t(
                        'repeatSlotAmountTip',
                        {
                            productId: autoFillSlots.productId,
                            slotIndex: autoFillSlots.slotIndex,
                        },
                    )}
                    type={InputType.number}
                    value={Cast.string(this.state.amount)}
                    valueChanged={this.amountChanged}
                />
            </OverlayPane>
        );
    };

    renderErrorBox = () => {
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-762
        if (this.state.error) {
            return (
                <ErrorBox
                    closeButtonPressed={this.errorBoxCloseButtonPressed}
                    text={this.state.error}
                />
            );
        }

        return null;
    };

    setError = (error) => {
        this.setState({
            error,
        });
    };
}

const mapStateToProps = (state) => (
    {
        autoFillSlotData: state.activeProject.autoFillSlots,
        selectedProduct:  StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default compose(connect(
    mapStateToProps,
    mapDispatchToProps,
))(withTranslation()(Component));

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import { ReactComponent as IconArrowDown }                  from '@assets/icons/arrowDown.svg';
import { ReactComponent as IconAudioAnalogBidirectional }   from '@assets/icons/audioAnalogBidirectional.svg';
import { ReactComponent as IconAudioAnalogDigital }         from '@assets/icons/audioAnalogDigital.svg';
import { ReactComponent as IconAudioBalanced }              from '@assets/icons/audioBalanced.svg';
import { ReactComponent as IconAudioDigitalBidirectional }  from '@assets/icons/audioDigitalBidirectional.svg';
import { ReactComponent as IconAudioDigitalUnidirectional } from '@assets/icons/audioDigitalUnidirectional.svg';
import { ReactComponent as IconCart }                       from '@assets/icons/cart.svg';
import { ReactComponent as IconCheck }                      from '@assets/icons/check.svg';
import { ReactComponent as IconChevronLeft }                from '@assets/icons/chevronLeft.svg';
import { ReactComponent as IconChevronRight }               from '@assets/icons/chevronRight.svg';
import { ReactComponent as IconClose }                      from '@assets/icons/close.svg';
import { ReactComponent as IconDataProtection }             from '@assets/icons/dataProtection.svg';
import { ReactComponent as IconDataTransferCatx1g }         from '@assets/icons/dataTransferCatx1g.svg';
import { ReactComponent as IconDataTransferCatx3g }         from '@assets/icons/dataTransferCatx3g.svg';
import { ReactComponent as IconDataTransferFiber10g }       from '@assets/icons/dataTransferFiber10g.svg';
import { ReactComponent as IconDataTransferFiber1g }        from '@assets/icons/dataTransferFiber1g.svg';
import { ReactComponent as IconDataTransferFiber3g }        from '@assets/icons/dataTransferFiber3g.svg';
import { ReactComponent as IconDataTransferFiber6g }        from '@assets/icons/dataTransferFiber6g.svg';
import { ReactComponent as IcondataTransferIpcatx1g }       from '@assets/icons/dataTransferIpcatx1g.svg';
import { ReactComponent as IconDataTransferIpcatx10gmm }    from '@assets/icons/dataTransferIpfiber10gmm.svg';
import { ReactComponent as IcondataTransferIpfiber1g }      from '@assets/icons/dataTransferIpfiber1g.svg';
import { ReactComponent as IconDataTransferUni }            from '@assets/icons/dataTransferUni.svg';
import { ReactComponent as IconDelete }                     from '@assets/icons/delete.svg';
import { ReactComponent as IconDots }                       from '@assets/icons/dots.svg';
import { ReactComponent as IconDownload }                   from '@assets/icons/download.svg';
import { ReactComponent as IconDropZone }                   from '@assets/icons/dropZone.svg';
import { ReactComponent as IconDuplicate }                  from '@assets/icons/duplicate.svg';
import { ReactComponent as IconError }                      from '@assets/icons/error.svg';
import { ReactComponent as IconExport }                     from '@assets/icons/export.svg';
import { ReactComponent as IconExport2 }                    from '@assets/icons/export2.svg';
import { ReactComponent as IconFan }                        from '@assets/icons/fan.svg';
import { ReactComponent as IconFile }                       from '@assets/icons/file.svg';
import { ReactComponent as IconFilter }                     from '@assets/icons/filter.svg';
import { ReactComponent as IconHeatDissipation }            from '@assets/icons/heatDissipation.svg';
import { ReactComponent as IconHelp }                       from '@assets/icons/help.svg';
import { ReactComponent as IconImport }                     from '@assets/icons/import.svg';
import { ReactComponent as IconImport2 }                    from '@assets/icons/import2.svg';
import { ReactComponent as IconLightning }                  from '@assets/icons/lightning.svg';
import { ReactComponent as IconLock }                       from '@assets/icons/lock.svg';
import { ReactComponent as IconMinus }                      from '@assets/icons/minus.svg';
import { ReactComponent as IconMonitor }                    from '@assets/icons/monitor.svg';
import { ReactComponent as IconOffer }                      from '@assets/icons/offer.svg';
import { ReactComponent as IconPanelButtonBottom }          from '@assets/icons/panelButtonBottom.svg';
import { ReactComponent as IconPanelButtonLeft }            from '@assets/icons/panelButtonLeft.svg';
import { ReactComponent as IconPanelButtonRight }           from '@assets/icons/panelButtonRight.svg';
import { ReactComponent as IconPartList }                   from '@assets/icons/partList.svg';
import { ReactComponent as IconPlus }                       from '@assets/icons/plus.svg';
import { ReactComponent as IconPorts }                      from '@assets/icons/ports.svg';
import { ReactComponent as IconPrint }                      from '@assets/icons/print.svg';
import { ReactComponent as IconRequestQuote }               from '@assets/icons/requestQuote.svg';
import { ReactComponent as IconReverse }                    from '@assets/icons/reverse.svg';
import { ReactComponent as IconSalesMan }                   from '@assets/icons/salesMan.svg';
import { ReactComponent as IconSearch }                     from '@assets/icons/search.svg';
import { ReactComponent as IconSerialRs232 }                from '@assets/icons/serialRs232.svg';
import { ReactComponent as IconSerialRs422 }                from '@assets/icons/serialRs422.svg';
import { ReactComponent as IconSlots }                      from '@assets/icons/slots.svg';
import { ReactComponent as IconTooltip }                    from '@assets/icons/tooltip.svg';
import { ReactComponent as IconTrashBin }                   from '@assets/icons/trashBin.svg';
import { ReactComponent as IconUsb11 }                      from '@assets/icons/usb11.svg';
import { ReactComponent as IconUsb20 }                      from '@assets/icons/usb20.svg';
import { ReactComponent as IconUsb31 }                      from '@assets/icons/usb31.svg';
import { ReactComponent as IconUsbFlexSpeed20 }             from '@assets/icons/usbFlexSpeed20.svg';
import { ReactComponent as IconUsbFullSpeed20 }             from '@assets/icons/usbFullSpeed20.svg';
import { ReactComponent as IconUsbHid }                     from '@assets/icons/usbHid.svg';
import { ReactComponent as IconUsbHighSpeed20 }             from '@assets/icons/usbHighSpeed20.svg';
import { ReactComponent as IconUser }                       from '@assets/icons/user.svg';
import { ReactComponent as IconWrench }                     from '@assets/icons/wrench.svg';
import { ReactComponent as IconZoomIn }                     from '@assets/icons/zoomIn.svg';
import { ReactComponent as IconZoomOut }                    from '@assets/icons/zoomOut.svg';
import SelectionHelper                                      from '@helper/SelectionHelper';

import IconType  from './IconType';
import styles    from './styles.module.scss';
import PropTypes from '../PropTypes';

class Component extends React.Component {
    render() {
        return (
            <span
                className={classNames([
                    styles.icon,
                ])}
            >
                {this.renderSvg()}
            </span>
        );
    }

    renderSvg = () => {
        return SelectionHelper.get(
            this.props.iconType,
            {
                [IconType.arrowDown]:                  <IconArrowDown />,
                [IconType.audioAnalogBidirectional]:   <IconAudioAnalogBidirectional />,
                [IconType.audioAnalogDigital]:         <IconAudioAnalogDigital />,
                [IconType.audioBalanced]:              <IconAudioBalanced />,
                [IconType.audioDigitalBidirectional]:  <IconAudioDigitalBidirectional />,
                [IconType.audioDigitalUnidirectional]: <IconAudioDigitalUnidirectional />,
                [IconType.cart]:                       <IconCart />,
                [IconType.check]:                      <IconCheck />,
                [IconType.chevronLeft]:                <IconChevronLeft />,
                [IconType.chevronRight]:               <IconChevronRight />,
                [IconType.close]:                      <IconClose />,
                [IconType.dataProtection]:             <IconDataProtection />,
                [IconType.dataTransferCatx1g]:         <IconDataTransferCatx1g />,
                [IconType.dataTransferCatx3g]:         <IconDataTransferCatx3g />,
                [IconType.dataTransferFiber10g]:       <IconDataTransferFiber10g />,
                [IconType.dataTransferFiber1g]:        <IconDataTransferFiber1g />,
                [IconType.dataTransferFiber3g]:        <IconDataTransferFiber3g />,
                [IconType.dataTransferFiber6g]:        <IconDataTransferFiber6g />,
                [IconType.dataTransferIpcatx1g]:       <IcondataTransferIpcatx1g />,
                [IconType.dataTransferIpfiber10gmm]:   <IconDataTransferIpcatx10gmm />,
                [IconType.dataTransferIpfiber1g]:      <IcondataTransferIpfiber1g />,
                [IconType.dataTransferUni]:            <IconDataTransferUni />,
                [IconType.delete]:                     <IconDelete />,
                [IconType.dots]:                       <IconDots />,
                [IconType.download]:                   <IconDownload />,
                [IconType.dropZone]:                   <IconDropZone />,
                [IconType.duplicate]:                  <IconDuplicate />,
                [IconType.error]:                      <IconError />,
                [IconType.export2]:                    <IconExport2 />,
                [IconType.export]:                     <IconExport />,
                [IconType.fan]:                        <IconFan />,
                [IconType.file]:                       <IconFile />,
                [IconType.filter]:                     <IconFilter />,
                [IconType.heatDissipation]:            <IconHeatDissipation />,
                [IconType.help]:                       <IconHelp />,
                [IconType.import2]:                    <IconImport2 />,
                [IconType.import]:                     <IconImport />,
                [IconType.lightning]:                  <IconLightning />,
                [IconType.lock]:                       <IconLock />,
                [IconType.panelButtonRight]:           <IconPanelButtonRight />,
                [IconType.panelButtonLeft]:            <IconPanelButtonLeft />,
                [IconType.panelButtonBottom]:          <IconPanelButtonBottom />,
                [IconType.minus]:                      <IconMinus />,
                [IconType.monitor]:                    <IconMonitor />,
                [IconType.offer]:                      <IconOffer />,
                [IconType.partList]:                   <IconPartList />,
                [IconType.plus]:                       <IconPlus />,
                [IconType.ports]:                      <IconPorts />,
                [IconType.print]:                      <IconPrint />,
                [IconType.requestQuote]:               <IconRequestQuote />,
                [IconType.reverse]:                    <IconReverse />,
                [IconType.salesMan]:                   <IconSalesMan />,
                [IconType.search]:                     <IconSearch />,
                [IconType.serialRs232]:                <IconSerialRs232 />,
                [IconType.serialRs422]:                <IconSerialRs422 />,
                [IconType.slots]:                      <IconSlots />,
                [IconType.tooltip]:                    <IconTooltip />,
                [IconType.trashBin]:                   <IconTrashBin />,
                [IconType.usb11]:                      <IconUsb11 />,
                [IconType.usb20]:                      <IconUsb20 />,
                [IconType.usb31]:                      <IconUsb31 />,
                [IconType.usbFlexSpeed20]:             <IconUsbFlexSpeed20 />,
                [IconType.usbFullSpeed20]:             <IconUsbFullSpeed20 />,
                [IconType.usbHid]:                     <IconUsbHid />,
                [IconType.usbHighSpeed20]:             <IconUsbHighSpeed20 />,
                [IconType.user]:                       <IconUser />,
                [IconType.wrench]:                     <IconWrench />,
                [IconType.zoomIn]:                     <IconZoomIn />,
                [IconType.zoomOut]:                    <IconZoomOut />,
            },
        );
    };
}

Component.propTypes = {
    iconType: PropTypes.oneOf(Object.values(IconType)),
};

Component.defaultProps = {
    iconType: null,
};

export default Component;

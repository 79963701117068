//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import MouseMoveDebounce from '@helper/MouseMoveDebounce';

import defaultDragDropConfiguration from './default';

let dragDropItemReference = null;

export default {
    dragBehavior:             {
        beginDrag(props) {
            console.log('ProductSort: beginDrag (props)', props);

            props.dragStart();

            return (
                {
                    id:                  props.product.id,
                    productId:           props.product.productData.partNo,
                    index:               props.index,
                    productCategoryType: props.productCategoryType,
                }
            );
        },
        endDrag(props, monitor, component) {
            const dropResult = monitor.getDropResult();

            props.dragStop();

            console.log(
                'ProductSort: endDrag (props, monitor, component, dropResult)',
                props,
                monitor,
                component,
                dropResult,
            );
        },
    },
    dragCollect:              defaultDragDropConfiguration.dragCollect,
    dropBehavior:             {
        canDrop(props, monitor) {
            return true;
        },
        drop:  (props, monitor, component) => {
            const dropResult = monitor.getDropResult();
            const payload    = monitor.getItem();

            console.log(
                'ProductSort: DropTarget: drop (payload, props, component, dropResult)',
                payload,
                props,
                component,
                dropResult,
            );

            // This will be available in endDrag in DragSource
            return {};
        },
        hover: (props, monitor, component) => {
            const clientOffset = monitor.getClientOffset();

            if (
                !MouseMoveDebounce.clientOffsetChanged(clientOffset) ||
                !dragDropItemReference
            ) {
                return;
            }

            const draggedItem      = monitor.getItem();
            const draggedItemIndex = draggedItem.index;
            const hoveredItemIndex = props.index;

            if (draggedItemIndex === hoveredItemIndex) {
                return;
            }

            // @formatter:off
            const hoverBoundingRect = dragDropItemReference.getBoundingClientRect();
            const hoverMiddleY      = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const hoverClientY      = clientOffset.y - hoverBoundingRect.top;
            // @formatter:on

            if (
                (
                    draggedItemIndex < hoveredItemIndex && hoverClientY < hoverMiddleY
                ) ||
                (
                    draggedItemIndex > hoveredItemIndex && hoverClientY > hoverMiddleY
                )
            ) {
                return;
            }

            props.moveProduct({
                categoryType: props.productCategoryType,
                fromIndex:    draggedItemIndex,
                toIndex:      hoveredItemIndex,
            });

            draggedItem.index = hoveredItemIndex;
        },
    },
    dropCollect:              defaultDragDropConfiguration.dropCollect,
    setDragDropItemReference: (reference) => {
        dragDropItemReference = reference;
    },
};

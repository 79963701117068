//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import ImportFileFormats from '@constants/ImportFileFormats';
import MimeTypes         from '@constants/MimeTypes';
import Overlays          from '@constants/Overlays';
import Routes            from '@constants/Routes';
import { ImportActions } from '@slices/import';

import CsvImporter     from '../../import/csv';
import DsdjsonImporter from '../../import/dsdjson';
import Ec0Importer     from '../../import/ec0';
import ZipImporter     from '../../import/zip';
import ErrorBox        from '../ErrorBox';
import FileDropZone    from '../FileDropZone';
import Overlay         from '../Overlay';
import PropTypes       from '../PropTypes';
import TextHeadline    from '../TextHeadline';

class Component extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            files: null,
        };
    }

    errorBoxCloseButtonPressed = () => {
        this.setState({
            error: null,
        });
    };

    importError = () => {
        this.setError(I18n.t('importError2'));
    };

    filesChanged = (files) => {
        this.setState({
            files,
        });
    };

    getContextText = () => {
        if (this.isDesignerContext()) {
            return I18n.t('importDialogTextContextDesigner');
        }

        return I18n.t('importDialogTextContextNewProject');
    };

    importProducts = (projectData) => {
        this.props.importToActiveProject({
            projectData,
        });
    };

    importNewProject = (projectData) => {
        this.props.importNewProject({
            projectData,
        });
    };

    importProductUpdates = (projectData) => {
        this.props.importProductUpdates({
            projectData,
        });
    };

    isDesignerContext = () => {
        const designerContext = this.props.pathname.startsWith(Routes.designer);

        return designerContext;
    };

    preValidateCsvFiles = async (files) => {
        for (const file of files) {
            const result = await CsvImporter.importFile(file);

            if (!CsvImporter.contentIsValid(result, this.props.activeProject)) {
                this.setError(I18n.t('importError2'));

                return false;
            }
        }

        return true;
    };

    okButtonValidator = async () => {
        const { files } = this.state;

        if (!files) {
            this.setError(I18n.t('importError1'));

            return false;
        }

        let filesToImport = [];
        const zipFiles    = _.filter(files, (file) => {
            const { type } = file;

            if (
                type === MimeTypes.zip ||
                type === MimeTypes.zipCompressed
            ) {
                return true;
            }

            return false;
        });
        const csvFiles    = _.filter(files, {
            type: MimeTypes.csv,
        });

        if (zipFiles.length > 0) {
            filesToImport = zipFiles;
        } else if (csvFiles.length > 0) {
            filesToImport = csvFiles;

            if (!await this.preValidateCsvFiles(filesToImport)) {
                return false;
            }
        } else if (files.length > 0) {
            filesToImport = files;
        }

        for (const file of filesToImport) {
            const name                  = _.get(file, 'name');
            const fileExtension         = name.split('.').pop().toLowerCase();
            const parsedContentCallback = (
                this.isDesignerContext() ?
                    this.importProducts :
                    this.importNewProject
            );

            switch (fileExtension) {
                case ImportFileFormats.csv:
                    const result = await CsvImporter.importFile(file);

                    this.importProductUpdates(result);

                    break;

                case ImportFileFormats.dsdjson:
                case ImportFileFormats.json:
                    await DsdjsonImporter.importFile(
                        file,
                        this.importError,
                        parsedContentCallback,
                    );

                    break;

                case ImportFileFormats.ec0:
                    await Ec0Importer.importFile(
                        file,
                        this.importError,
                        parsedContentCallback,
                    );

                    break;

                case ImportFileFormats.zip:
                    await ZipImporter.importFile(
                        file,
                        this.importError,
                        parsedContentCallback,
                        this.importProductUpdates,
                    );

                    break;

                default:
                    this.importError();

                    break;
            }
        }
    };

    render() {
        const contextText = this.getContextText();
        const fileFormats = Object.values(ImportFileFormats).join(', ');

        return (
            <Overlay
                id={Overlays.import}
                okButtonValidator={this.okButtonValidator}
                title={I18n.t('import')}
            >
                <TextHeadline text={I18n.t('importDialogText1')} />
                <TextHeadline
                    text={I18n.t('importDialogText2', {
                        fileFormats,
                    })}
                />
                <TextHeadline text={contextText} />
                <FileDropZone
                    filesChanged={this.filesChanged}
                    multiple={true}
                />
                {this.renderErrorBox()}
            </Overlay>
        );
    }

    renderErrorBox = () => {
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-762
        if (this.state.error) {
            return (
                <ErrorBox
                    closeButtonPressed={this.errorBoxCloseButtonPressed}
                    text={this.state.error}
                />
            );
        }

        return null;
    };

    setError = (error) => {
        this.setState({
            error,
        });
    };
}

Component.propTypes = {
    activeProject: PropTypes.object,
};

Component.defaultProps = {
    activeProject: null,
};

const mapStateToProps = (state) => (
    {
        activeProject: state.activeProject,
        pathname:      state.router.location.pathname,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(ImportActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

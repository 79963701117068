//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { Component } from 'react';
import React         from 'react';

export default function withWindowDimensions(WrappedComponent) {
    return class extends Component {
        state = {
            windowHeight: 0,
            windowWidth:  0,
        };

        componentDidMount() {
            this.updateWindowDimensions();

            window.addEventListener('resize', this.updateWindowDimensions);
        }

        componentWillUnmount() {
            window.removeEventListener('resize', this.updateWindowDimensions);
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    windowWidth={this.state.windowWidth}
                    windowHeight={this.state.windowHeight}
                />
            );
        }

        updateWindowDimensions = () => {
            this.setState({
                windowHeight: window.innerHeight,
                windowWidth:  window.innerWidth,
            });
        };
    };
}

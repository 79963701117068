//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

import Limitations from '@constants/Limitations';
import WarningType from '@constants/WarningType';

import ActiveProjectsFactory from '../factories/activeProjects';

class limitationLimitTo {
    static getAllowedParentProductNumbers = (product, subProduct) => {
        const productNumbers = [];

        if (subProduct.productData) {
            for (const limitation of subProduct.productData.limitations) {
                if (limitation.type === Limitations.limitTo) {
                    productNumbers.push(limitation.partNo);
                }
            }
        }

        return productNumbers;
    };

    static updateProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        productWarnings,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        return false;
    };

    static updateSubProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        subProduct,
        subProductIndex,
        productWarnings,
        subProductWarnings,
        subProductCategory,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        const allowedParentProductNumbers = this.getAllowedParentProductNumbers(product, subProduct);

        if (allowedParentProductNumbers.indexOf(product.productData.partNo) === -1) {
            subProductWarnings.push(ActiveProjectsFactory.getWarning(
                WarningType.error,
                I18n.t('limitationLimitTo'),
            ));

            return true;
        }

        return false;
    };
}

export default limitationLimitTo;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

import Limitations     from '@constants/Limitations';
import ProductSlotType from '@constants/ProductSlotType';
import SlotType        from '@constants/SlotType';
import WarningType     from '@constants/WarningType';
import Cast            from '@helper/Cast';
import Language        from '@helper/Language';

import ActiveProjectsFactory from '../factories/activeProjects';

class limitationLimitTo {
    static parseValue = (value) => {
        const parsedValue       = {
            slot:               0,
            limitationProducts: [],
        };
        const valueWithoutSpace = value.replace(/ /g, '');
        const explodedValue     = valueWithoutSpace.split(',');
        parsedValue.slot        = Cast.int(explodedValue[0]);

        explodedValue.shift();

        parsedValue.limitationProducts = explodedValue;

        return parsedValue;
    };

    static updateProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        productWarnings,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        return false;
    };

    static updateSubProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        subProduct,
        subProductIndex,
        productWarnings,
        subProductWarnings,
        subProductCategory,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        if (
            subProductCategory === SlotType.slot &&
            // Don't apply this limitation for matrix flex front plates since this limitation
            // there describes a sub layer of modules placed on the front plate
            // that are not supported in the dsd right now. The current solution has a fixed
            // list of front plates.
            subProduct.productData.productSlotType !== ProductSlotType.frontPlate
        ) {
            const { slot, limitationProducts } = this.parseValue(limitation.value);

            if (subProductIndex + 1 === slot) {
                const slotData = product.subProducts.slot[subProductIndex];

                if (
                    slotData.productData &&
                    subProductIndex + 1 === slot &&
                    limitationProducts.indexOf(slotData.productData.partNo) === -1
                ) {
                    const productList = Language.orString(limitationProducts);

                    subProductWarnings.push(ActiveProjectsFactory.getWarning(
                        WarningType.error,
                        I18n.t('limitationLimitToCardsInSlot', {
                            productList,
                        }),
                    ));

                    return true;
                }
            }

            const allowedSlotIndexes = [];
            const limitations        = product.productData.limitations;

            for (const limitation of limitations) {
                if (limitation.type === Limitations.limitToCardsInSlot) {
                    const { slot, limitationProducts } = this.parseValue(limitation.value);

                    for (const limitationProduct of limitationProducts) {
                        if (limitationProduct === subProduct.productData.partNo) {
                            allowedSlotIndexes.push(slot);
                        }
                    }
                }
            }

            if (
                allowedSlotIndexes.length > 0 &&
                allowedSlotIndexes.indexOf(subProductIndex + 1) === -1
            ) {
                subProductWarnings.push(ActiveProjectsFactory.getWarning(
                    WarningType.error,
                    I18n.t('limitationLimitToCardsInSlotReverse', {
                        allowedSlotIndexes: allowedSlotIndexes.join(', '),
                        partNo:             subProduct.productData.partNo,
                    }),
                ));

                return true;
            }
        }

        return false;
    };
}

export default limitationLimitTo;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import _                      from 'lodash';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import StateHelper              from '@helper/State';

import styles                       from './styles.module.scss';
import Input                        from '../Input';
import InputType                    from '../Input/InputType';
import ProductDataSpecificationList from '../ProductDataSpecificationList';
import RightPaneProductTitle        from '../RightPaneProductTitle';

const Component = ({
    selectedProduct, selectedProductCategoryType,
    otherCommentFieldVisible,
    setSelectedProductCustomName, setSelectedProductOtherComment,
    deleteProduct,
}) => {
    const customNameChanged       = (event) => {
        setSelectedProductCustomName({
            customName: event.target.value,
        });
    };
    const deleteButtonPressed     = (event) => {
        deleteProduct({
            categoryType: selectedProductCategoryType,
            id:           selectedProduct.id,
        });

        event.preventDefault();
        event.stopPropagation();
    };
    const otherCommentChanged     = (event) => {
        setSelectedProductOtherComment({
            otherComment: event.target.value,
        });
    };
    const renderCustomNameInput   = () => {
        return (
            <Input
                placeholder={I18n.t('enterTextPlaceholder')}
                text={I18n.t('customName')}
                tooltip={I18n.t('customNameTip')}
                type={InputType.text}
                value={selectedProduct.customName}
                valueChanged={customNameChanged}
            />
        );
    };
    const renderOtherCommentInput = () => {
        if (otherCommentFieldVisible) {
            return (
                <Input
                    placeholder={I18n.t('enterTextPlaceholder')}
                    text={I18n.t('otherComment')}
                    tooltip={I18n.t('otherCommentTip')}
                    type={InputType.text}
                    value={selectedProduct.otherComment}
                    valueChanged={otherCommentChanged}
                />
            );
        }

        return null;
    };
    const renderSpecification     = () => {
        return (
            <ProductDataSpecificationList
                data={selectedProduct.productData}
            />
        );
    };

    if (selectedProduct) {
        return (
            <div className={styles.productDetailsPane}>
                <RightPaneProductTitle
                    deleteButtonPressed={deleteButtonPressed}
                    title={selectedProduct.productData.displayPartNumber}
                />
                {renderCustomNameInput()}
                {renderOtherCommentInput()}
                {renderSpecification()}
            </div>
        );
    }

    return null;
};

const mapStateToProps = (state) => {
    return {
        otherCommentFieldVisible:    state.settings.otherCommentFieldVisible,
        selectedProduct:             StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
        selectedProductCategoryType: _.get(state, 'activeProject.selectedProduct.categoryType'),
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));

import React from 'react';

import _ from 'lodash';

import TestIds from '@constants/TestIds';

import styles from './styles.module.scss';
import Icon from '../Icon';
import IconType from '../Icon/IconType';
import PropTypes from '../PropTypes';

const Component = ({
    deleteButtonPressed,
    title,
}) => (
    <div
        data-testId={TestIds.rightPaneProductTitle}
        className={styles.rightPaneProductTitleWrapper}
    >
        <h3 className={styles.rightPaneProductTitle}>
            {title}
        </h3>
        <span
            className={styles.rightPaneProductTitleDeleteButton}
            onClick={deleteButtonPressed}
        >
            <Icon iconType={IconType.trashBin} />
        </span>
    </div>
);

Component.propTypes = {
    deleteButtonPressed: PropTypes.func,
    title: PropTypes.string,
};

Component.defaultProps = {
    deleteButtonPressed: _.noop,
    title: '',
};

export default Component;

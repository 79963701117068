//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

// @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-203
// @see This enum is also present in the backend project src/Entity/CardOverlayMode.php
export default {
    none:          'none',
    articleNumber: 'articleNumber',
    moduleName:    'moduleName',
};

export default {
    videoConnectorTypeBnc:         'bnc',
    videoConnectorTypeDisplayport: 'displayport',
    videoConnectorTypeDpHdmi:      'dphdmi',
    videoConnectorTypeDualhead:    'dualhead',
    videoConnectorTypeDuallink:    'duallink',
    videoConnectorTypeDvid:        'dvid',
    videoConnectorTypeDvii:        'dvii',
    videoConnectorTypeHdbnc:       'hdbnc',
    videoConnectorTypeHdmi:        'hdmi',
    videoConnectorTypeIp:          'ip',
};

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames             from 'classnames';
import I18n                   from 'i18next';
import _                      from 'lodash';
import Collapsible            from 'react-collapsible';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import ProductCategoryType       from '@constants/ProductCategoryType';
import ProductSlotType           from '@constants/ProductSlotType';
import TestIds                   from '@constants/TestIds';
import Cast                      from '@helper/Cast';
import Product                   from '@helper/Product';
import SelectionHelper           from '@helper/SelectionHelper';
import { ActiveProjectActions }  from '@slices/activeProject';
import { DesignerLayoutActions } from '@slices/designerLayout';

import styles                  from './styles.module.scss';
import GroupedChassisCollapser from '../GroupedChassisCollapser';
import Icon                    from '../Icon';
import IconType                from '../Icon/IconType';
import IndexAndCheckboxWrapper from '../IndexAndCheckboxWrapper';
import LeftPaneAddButton       from '../LeftPaneAddButton';
import LeftPaneAddButtonTheme  from '../LeftPaneAddButton/LeftPaneAddButtonTheme';
import LeftPaneProductItem     from '../LeftPaneProductItem';
import PropTypes               from '../PropTypes';
import Spacer                  from '../Spacer';
import StandAloneDropZone      from '../StandAloneDropZone';
import StandAloneDropZoneMode  from '../StandAloneDropZone/StandAloneDropZoneMode';
import TextButton              from '../TextButton';
import TextButtonTheme         from '../TextButton/TextButtonTheme';

class Component extends React.Component {
    addButtonPressed = (event) => {
        // Stop the collapsible control collapsing when the add button is pressed
        event.stopPropagation();

        this.props.setActiveProductCategoryType({
            productCategoryType: this.props.productCategoryType,
        });

        this.props.setBottomPaneSetVisibility({
            visible: true,
        });
    };

    closeButtonPressed = (event) => {
        // Stop the collapsible control collapsing when the add button is pressed
        event.stopPropagation();

        this.props.setActiveProductCategoryType({
            productCategoryType: null,
        });
    };

    getDropZoneText = () => {
        return SelectionHelper.getTranslation(
            this.props.productCategoryType,
            {
                [ProductCategoryType.equipment]: 'dropZoneTextEquipment',
                [ProductCategoryType.extender]:  'dropZoneTextExtender',
                [ProductCategoryType.matrix]:    'dropZoneTextMatrix',
            },
            '',
        );
    };

    getProducts = () => {
        if (this.props.products) {
            return this.props.products[this.props.productCategoryType];
        }

        return {};
    };

    hasProducts = () => {
        const products = this.getProducts();

        if (products) {
            return products.length > 0;
        }

        return false;
    };

    isActive = () => {
        return this.props.activeProductCategoryType === this.props.productCategoryType;
    };

    render() {
        return (
            <div
                className={styles.leftPaneProductCategory}
                data-testId={TestIds.leftPaneProductCategory + this.props.productCategoryType}
            >
                <Collapsible
                    open={true}
                    transitionTime={100}
                    trigger={this.renderToggleControl(false)}
                    triggerDisabled={!this.hasProducts()}
                    triggerWhenOpen={this.renderToggleControl(true)}
                >
                    {this.renderContent()}
                </Collapsible>
            </div>
        );
    }

    renderBigAddButton = () => {
        if (!this.isActive()) {
            return (
                <>
                    {
                        this.hasProducts() ?
                            <Spacer height={10} /> :
                            null
                    }
                    <LeftPaneAddButton
                        onClick={this.addButtonPressed}
                        text={I18n.t('add')}
                        theme={LeftPaneAddButtonTheme.products}
                    />
                </>
            );
        }

        return null;
    };

    renderContent = () => {
        return (
            <div className={styles.leftPaneProductCategoryContent}>
                <div
                    className={classNames(
                        styles.leftPaneProductCategoryContentWrapper,
                        (
                            this.isActive() && this.hasProducts() ?
                                styles.leftPaneProductCategoryContentWrapperWithMinHeight :
                                null
                        ),
                    )}
                >
                    {this.renderDropZone()}
                    {this.renderItems()}
                    {this.renderBigAddButton()}
                </div>
            </div>
        );
    };

    renderDropZone = () => {
        if (this.isActive()) {
            const dropZoneText = this.getDropZoneText();

            return (
                <>
                    <StandAloneDropZone
                        mode={StandAloneDropZoneMode.default}
                        productCategoryType={this.props.productCategoryType}
                        productSlotType={ProductSlotType.chassis}
                        text={dropZoneText}
                    />
                    {
                        this.hasProducts() ?
                            <Spacer height={10} /> :
                            null
                    }
                </>
            );
        }

        return null;
    };

    renderItems = () => {
        const items             = [];
        const products          = this.getProducts();
        const groups            = {};
        let groupIndex          = 1;
        let visibleProductIndex = 1;

        if (products) {
            for (const productIndex in products) {
                if (!products.hasOwnProperty(productIndex)) {
                    continue;
                }

                const product = products[productIndex];

                if (product) {
                    const hash = Product.calculateProductHash(product);

                    if (this.props.groupSimilarProducts) {
                        if (!groups[hash]) {
                            groups[hash] = [];
                        }

                        groups[hash].push({
                            productIndex,
                            product,
                        });
                    } else {
                        const component = this.renderProductComponent(
                            product,
                            productIndex,
                            visibleProductIndex,
                        );
                        visibleProductIndex += 1;

                        items.push(component);
                    }
                }
            }
        }

        for (const groupName in groups) {
            const group = groups[groupName];

            if (group.length > 1) {
                const groupComponents = [];

                for (let groupElementIndex = 0; groupElementIndex < group.length; groupElementIndex++) {
                    const groupElement              = group[groupElementIndex];
                    const { productIndex, product } = groupElement;

                    groupComponents.push(this.renderProductComponent(
                        product,
                        productIndex,
                        visibleProductIndex,
                    ));

                    visibleProductIndex += 1;
                }

                const groupToggled = _.get(
                    this,
                    [
                        'props',
                        'chassisGroupToggleContext',
                        this.props.productCategoryType,
                        groupName,
                    ],
                );

                items.push(<GroupedChassisCollapser
                    className={styles.group}
                    isDragActive={this.props.draggingActionActive}
                    expanded={groupToggled}
                    onGroupToggle={this.onGroupToggle(groupName)}
                    groupName={I18n.t('configurationGroup', {
                        groupIndex: groupIndex++,
                    })}
                >
                    {groupComponents}
                </GroupedChassisCollapser>);
            } else if (group.length === 1) {
                const firstProduct              = _.first(group);
                const { productIndex, product } = firstProduct;
                const component                 = this.renderProductComponent(
                    product,
                    productIndex,
                    visibleProductIndex,
                );
                visibleProductIndex += 1;

                items.push(component);
            }
        }

        return items;
    };

    onGroupToggle = (groupName) => {
        return (expanded) => this.props.toggleChassisGroup({
            categoryType: this.props.productCategoryType,
            groupIndex:   groupName,
            expanded,
        });
    };

    renderProductComponent = (product, productIndex, visibleProductIndex = 0) => {
        const isMarkedForDeletion = _.get(
            this,
            [
                'props',
                'multipleProductsDeletionContext',
                this.props.productCategoryType,
                productIndex,
            ],
        );

        return (
            <IndexAndCheckboxWrapper
                className={styles.product}
                label={Cast.string(visibleProductIndex)}
                key={product.id}
                showCheckbox={this.props.deleteModeActive}
                checked={isMarkedForDeletion}
                checkboxChanged={() => {
                    this.props.toggleDeletionOfProduct({
                        categoryType: this.props.productCategoryType,
                        productIndex,
                    });
                }}
            >
                <LeftPaneProductItem
                    index={productIndex}
                    product={product}
                    className={styles.product}
                    productCategoryType={this.props.productCategoryType}
                />
            </IndexAndCheckboxWrapper>
        );
    };

    renderSmallAddButton = () => {
        if (!this.isActive() && this.hasProducts()) {
            return (
                <span
                    className={styles.leftPaneProductCategoryToggleControlAddButton}
                >
                    <TextButton
                        onClick={this.addButtonPressed}
                        text={I18n.t('productCategoryShow')}
                        theme={TextButtonTheme.graySlim}
                    />
                </span>
            );
        }

        return null;
    };

    renderSmallCloseButton = () => {
        if (this.isActive() && this.hasProducts()) {
            return (
                <span
                    className={styles.leftPaneProductCategoryToggleControlCloseButton}
                >
                    <TextButton
                        onClick={this.closeButtonPressed}
                        text={I18n.t('productCategoryHide')}
                        theme={TextButtonTheme.orangeSlim}
                    />
                </span>
            );
        }

        return null;
    };

    renderToggleControl = (isOpen) => {
        return (
            <div className={styles.leftPaneProductCategoryToggleControl}>
                {
                    this.hasProducts() ?
                        (
                            <span
                                className={classNames(
                                    styles.leftPaneProductCategoryToggleControlIcon,
                                    (
                                        isOpen ?
                                            styles.leftPaneProductCategoryToggleControlIconOpen :
                                            null
                                    ),
                                )}
                            >
                                <Icon iconType={IconType.arrowDown} />
                            </span>
                        ) :
                        null
                }
                <span className={styles.leftPaneProductCategoryToggleControlText}>
                    {this.props.label}
                </span>
                {this.renderSmallAddButton()}
                {this.renderSmallCloseButton()}
            </div>
        );
    };
}

Component.propTypes = {
    label:                           PropTypes.string,
    multipleProductsDeletionContext: PropTypes.object,
    productCategoryType:             PropTypes.oneOf(Object.values(ProductCategoryType)),
    setActiveProductCategoryType:    PropTypes.func,
};

Component.defaultProps = {
    label:                           '',
    multipleProductsDeletionContext: {},
    productCategoryType:             null,
};

const mapStateToProps = (state) => (
    {
        activeProductCategoryType:       state.activeProject.activeProductCategoryType,
        chassisGroupToggleContext:       state.activeProject.chassisGroupToggleContext,
        deleteModeActive:                state.activeProject.deleteModeActive,
        draggingActionActive:            state.dragDrop.isDragging,
        dropOperationCounter:            state.dragDrop.operationCounter,
        groupSimilarProducts:            state.settings.groupSimilarProducts,
        multipleProductsDeletionContext: state.activeProject.multipleProductsDeletionContext,
        products:                        state.activeProject.products,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(_.assign(
    ActiveProjectActions,
    DesignerLayoutActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n from 'i18next';

import Limitations from '@constants/Limitations';
import WarningType from '@constants/WarningType';
import Cast        from '@helper/Cast';

import ActiveProjectsFactory from '../factories/activeProjects';

class limitationLimitToAmount {
    static getAllowedParentProductNumbers = (product, subProduct) => {
        const productNumbers = [];

        if (subProduct.productData) {
            for (const limitation of subProduct.productData.limitations) {
                if (limitation.type === Limitations.limitToAmount) {
                    productNumbers.push(limitation.partNo);
                }
            }
        }

        return productNumbers;
    };

    static updateProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        productWarnings,
        powerConsumptionInMilliamps,
        categoryType,
        productIndex,
    ) => {
        return false;
    };

    static hasHitLimitation = (
        limitation,
        state,
        changeSet,
        product,
        subProduct,
        subProductIndex,
        productWarnings,
        subProductWarnings,
        subProductCategory,
    ) => {
        const allowedParentProductNumbers = this.getAllowedParentProductNumbers(product, subProduct);
        const allowedAmount               = Cast.int(limitation.value);
        const usedSlots                   = product.subProducts[subProductCategory];
        let numberOfSlotsWithSameProduct  = 0;

        for (const slot of usedSlots) {
            const slotProductDataPartNo = slot?.productData?.partNo;

            if (
                slotProductDataPartNo &&
                (
                    slotProductDataPartNo === subProduct?.productData?.partNo ||
                    slotProductDataPartNo === subProduct?.partNo
                )
            ) {
                numberOfSlotsWithSameProduct++;
            }
        }

        if (
            allowedParentProductNumbers.indexOf(product.productData.partNo) === -1 ||
            numberOfSlotsWithSameProduct > allowedAmount
        ) {
            return true;
        }

        return false;
    };

    static updateSubProductWarnings = (
        limitation,
        state,
        changeSet,
        product,
        subProduct,
        subProductIndex,
        productWarnings,
        subProductWarnings,
        subProductCategory,
    ) => {
        const hasHitLimitation = this.hasHitLimitation(
            limitation,
            state,
            changeSet,
            product,
            subProduct,
            subProductIndex,
            productWarnings,
            subProductWarnings,
            subProductCategory,
        );

        if (hasHitLimitation) {
            subProductWarnings.push(ActiveProjectsFactory.getWarning(
                WarningType.error,
                I18n.t('limitationLimitToAmount'),
            ));

            return true;
        }

        return false;
    };
}

export default limitationLimitToAmount;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import colors from './colors.scss';

const SplitPaneStyle = {
    resizerHorizontal:       {
        backgroundColor: colors.colorGrayLighter3,
        height:          2,
        cursor:          'ns-resize',
    },
    resizerHorizontalHidden: {
        height: 0,
        cursor: 'default',
    },
    resizerVertical:         {
        backgroundColor: colors.colorGrayLighter3,
        width:           2,
        cursor:          'ew-resize',
    },
    resizerVerticalHidden:   {
        width:  0,
        cursor: 'default',
    },
};

export default SplitPaneStyle;

import React from 'react';

import ProductCategoryType from '@constants/ProductCategoryType';
import ProductSlotType from '@constants/ProductSlotType';
import ProductSubCategoryTypes from '@constants/ProductSubCategoryType';
import DataProvider from '@helper/DataProvider';

import Table from '../Table';
import DefaultColumns from '../Table/DefaultColumns';

const Component = () => {
    const getColumnConfiguration = () => {
        const defaultColumns = DefaultColumns.get(ProductCategoryType.extender, ProductSubCategoryTypes.vario);

        return [
            defaultColumns.displayPartNumber,
            defaultColumns.usb,
            defaultColumns.deviceType,
            defaultColumns.serial,
            defaultColumns.audio,
            defaultColumns.powerConsumptionInMilliamps,
            defaultColumns.description,
        ];
    };

    return (
        <Table
            columnConfiguration={getColumnConfiguration()}
            productCategoryType={ProductCategoryType.extender}
            productSlotType={ProductSlotType.addOnModule}
            rawData={DataProvider.getExtenderAddOnModules()}
        />
    );
};

Component.propTypes = {};

Component.defaultProps = {};

export default Component;

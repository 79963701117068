//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import 'core-js';
import './language/i18n';
import React from 'react';

import ReactDOM from 'react-dom';

import { App }            from './App';
import GoogleAnalytics    from './helper/GoogleAnalytics';
import * as serviceWorker from './serviceWorker';

GoogleAnalytics.initialize();
GoogleAnalytics.trackPageView();

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register();

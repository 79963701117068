//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import DropResult from '@constants/DropResult';

import defaultDragDropConfiguration from './default';

export default {
    dragBehavior: {
        beginDrag: (props) => {
            console.log('SingleOrder: DragSource: beginDrag (props)', props);

            props.dragStart();

            return (
                {
                    isSingleOrder:           true,
                    fromIndex:               props.index,
                    fromProductCategoryType: props.productCategoryType,
                    productId:               props.singleOrder.productData.partNo,
                }
            );
        },
        endDrag(props, monitor, component) {
            const dropResult = monitor.getDropResult();
            props.dragStop();

            if (dropResult) {
                const payload    = monitor.getItem();
                const dropEffect = dropResult.dropEffect;

                console.log('SingleOrder: DragSource: endDrag');
                console.table(payload);
                console.table(dropResult);

                if (!_.isUndefined(dropResult.toIndex)) {
                    const actionPayload = {
                        categoryType: dropResult.fromProductCategoryType,
                        fromIndex:    dropResult.fromIndex,
                        toIndex:      dropResult.toIndex,
                    };

                    if (dropEffect === DropResult.copy) {
                        props.copySingleOrderSlot(actionPayload);
                    } else {
                        props.moveSingleOrderSlot(actionPayload);
                    }
                }
            }
        },
    },
    dragCollect:  defaultDragDropConfiguration.dragCollect,
    dropBehavior: {
        canDrop(props, monitor) {
            const payload = monitor.getItem();

            console.log('SingleOrder: canDrop: payload, props');
            console.table(payload);
            console.table(props);

            return (
                // A matching item is dragged from the data table to the left
                payload.productCategoryType === props.productCategoryType ||
                (
                    // The drag source is a already existing single order item
                    payload.fromProductCategoryType === props.productCategoryType &&
                    // We are not dragging on ourselves
                    payload.fromIndex !== props.index
                )
            );
        },
        drop: (props, monitor, component) => {
            const payload = monitor.getItem();

            console.log('SingleOrder: DropTarget: drop');
            console.table(payload);

            // This is true when the user drags a new card from the product table to
            // the left panel
            if (!payload.fromIndex) {
                props.setSingleOrderSlot({
                    categoryType:     props.productCategoryType,
                    productId:        payload.productId,
                    singleOrderIndex: props.index,
                });
            }

            return {
                ...payload,
                toAccessoryType: props.accessoryType,
                toIndex:         props.index,
                toType:          props.type,
            };
        },
    },
    dropCollect:  defaultDragDropConfiguration.dropCollect,
};

import _ from 'lodash';

class ObjectHelper {
    static pickValues(object, keys = null, blacklist = []) {
        let keysToPick = keys || Object.keys(object);
        keysToPick     = _.difference(keysToPick, blacklist);

        return _.pick(object, keysToPick);
    }

    static containsKeyWithValue(inputArray, searchValue, whitelist = [], blacklist = []) {
        const simplifyString       = (string) => {
            return string.toLowerCase().replace(/[^a-z0-9]/g, '');
        };
        const lowerCaseSearchValue = simplifyString(searchValue);

        for (const value of inputArray) {
            if (
                (
                    _.isArray(value) &&
                    ObjectHelper.containsKeyWithValue(
                        value,
                        searchValue,
                    )
                )
                ||
                (
                    _.isObject(value) &&
                    ObjectHelper.containsKeyWithValue(
                        Object.values(value),
                        searchValue,
                    )
                )
                ||
                (
                    _.isString(value) &&
                    simplifyString(value).indexOf(lowerCaseSearchValue) !== -1
                )
                ||
                (
                    _.isNumber(value) &&
                    simplifyString(value.toString()).indexOf(lowerCaseSearchValue) !== -1
                )
            ) {
                return true;
            }
        }

        return false;
    }
}

export default ObjectHelper;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import _ from 'lodash';

import EmptyTypes      from '@constants/EmptyTypes';
import ProductSlotType from '@constants/ProductSlotType';
import SlotType        from '@constants/SlotType';

import Cast from './Cast';

class ProductDataHelper {
    static getEmptySlotCountAfterSlot(product, slotIndex) {
        let emptySlotCount = 0;
        const slots        = product.subProducts.slot;
        const slotCount    = slots.length;

        for (let index = slotIndex + 1; index < slotCount; ++index) {
            const slot = slots[index];

            if (product.productData.layoutDefinition.io.indexOf(index + 1) > -1) {
                if (!slot.productData) {
                    ++emptySlotCount;
                } else if (slot.productData.productSlotType !== ProductSlotType.cpuBoard) {
                    // Return as far as we found a slot
                    return emptySlotCount;
                }
            }
        }

        return emptySlotCount;
    }

    static getNextEmptySlotIndex(product) {
        const slots     = product.subProducts.slot;
        const slotCount = slots.length;

        for (let index = 1; index < slotCount; ++index) {
            const slot = slots[index];

            if (product.productData.layoutDefinition.io.indexOf(index + 1) > -1) {
                if (!slot.productData) {
                    return index;
                }
            }
        }

        return -1;
    }

    static isLockedForCategory(currentSlotType, currentSlotIndex, slotType, lockDefinition) {
        if (
            currentSlotType === slotType &&
            lockDefinition
        ) {
            const slotDefinitionGlobal = _.find(
                lockDefinition,
                {
                    slot: -1,
                },
            );

            if (slotDefinitionGlobal && slotDefinitionGlobal.fix) {
                return true;
            }

            const slotNumber     = Cast.int(currentSlotIndex) + 1;
            const slotDefinition = _.find(
                lockDefinition,
                {
                    slot: slotNumber,
                },
            );

            if (slotDefinition && slotDefinition.fix) {
                return true;
            }
        }

        return false;
    }

    static isLockedInProduct(productData, currentSlotType, currentSlotIndex) {
        return (
            this.isLockedForCategory(
                currentSlotType,
                currentSlotIndex,
                SlotType.powerSupplyUnit,
                _.get(productData, 'psuDefinition.psu'),
            )
            ||
            this.isLockedForCategory(
                currentSlotType,
                currentSlotIndex,
                SlotType.slot,
                _.get(productData, 'layoutDefinition.cpu'),
            )
            ||
            this.isLockedForCategory(
                currentSlotType,
                currentSlotIndex,
                SlotType.slot,
                _.get(productData, 'layoutDefinition.uni'),
            )
        );
    }

    static slotIsDoubleSlot(product, slotIndex) {
        if (product && slotIndex > -1) {
            const slotIndexInteger = Cast.int(slotIndex);
            const slots            = product.subProducts.slot;

            if (slots.length > slotIndexInteger) {
                const slot = slots[slotIndexInteger];

                if (slot.productData) {
                    return slot.productData.heightUnits > 1;
                }
            }
        }

        return false;
    }

    static calculateProductHash(product) {
        const hash               = [];
        const subProductSlotKeys = Object.keys(product.subProducts);
        const partNo             = _.get(product, 'productData.partNo');

        if (partNo) {
            hash.push(partNo);
        } else {
            hash.push(EmptyTypes.emptyProduct);
        }

        for (const subProductSlotKey of subProductSlotKeys) {
            const subProducts = product.subProducts[subProductSlotKey];

            for (const subProductIndex in subProducts) {
                if (!subProducts.hasOwnProperty(subProductIndex)) {
                    continue;
                }

                const subProduct       = subProducts[subProductIndex];
                const subProductPartNo = _.get(subProduct, 'productData.partNo');

                if (subProductPartNo) {
                    hash.push(subProductPartNo);
                } else {
                    hash.push(EmptyTypes.emptySubProduct);
                }

                if (subProduct.sfps) {
                    for (const sfp of subProduct.sfps) {
                        if (sfp.productData) {
                            hash.push(sfp.productData.partNo);
                        } else {
                            hash.push(EmptyTypes.emptySfp);
                        }
                    }
                }

                if (subProduct.subSlots) {
                    for (const subSlot of subProduct.subSlots) {
                        if (subSlot.productData) {
                            hash.push(subSlot.productData.partNo);
                        } else {
                            hash.push(EmptyTypes.emptySubSlot);
                        }
                    }
                }
            }
        }

        return hash.join('-');
    }
}

export default ProductDataHelper;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import Cast from '@helper/Cast';

import NumberOfMonitorsLabel          from '../NumberOfMonitorsLabel';
import NumberOfMonitorsLabelAlignment from '../NumberOfMonitorsLabel/NumberOfMonitorsLabelAlignment';
import PropTypes                      from '../PropTypes';

const Component = ({ value }) => {
    const numberOfMonitors = Cast.int(value);

    return (
        <NumberOfMonitorsLabel
            alignment={NumberOfMonitorsLabelAlignment.left}
            numberOfMonitors={numberOfMonitors}
        />
    );
};

Component.propTypes = {
    value: PropTypes.any,
};

Component.defaultProps = {
    value: [],
};

export default Component;

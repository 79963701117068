//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import { Component } from 'react';
import React         from 'react';

import I18n                   from 'i18next';
import { Helmet }             from 'react-helmet';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ProjectsActions }            from '@slices/projects';
import ButtonBar                      from '@components/ButtonBar';
import ButtonBarButton                from '@components/ButtonBarButton';
import DeleteProjectOverlay           from '@components/DeleteProjectOverlay';
import Header                         from '@components/Header';
import IconType                       from '@components/Icon/IconType';
import ImportSuccessNewProjectOverlay from '@components/ImportSuccessNewProjectOverlay';
import OverlayManager                 from '@components/OverlayManager';
import { getPathForOverlayKey }       from '@components/OverlayManager';
import ProjectList                    from '@components/ProjectList';
import TitleBanner                    from '@components/TitleBanner';
import TitleBannerMode                from '@components/TitleBanner/TitleBannerMode';
import Overlays                       from '@constants/Overlays';
import Routes                         from '@constants/Routes';
import TestIds                        from '@constants/TestIds';
import PageTitleHelper                from '@helper/PageTitle';

import styles from '../styles.module.scss';

class Screen extends Component {
    newProjectButtonPressed = () => {
        this.props.newProject();
    };

    render() {
        return (
            <div className={styles.home}>
                <Helmet>
                    <title>{PageTitleHelper.getPageTitle(I18n.t('homePageTitle'))}</title>
                </Helmet>
                {this.renderOverlays()}
                <Header />
                <TitleBanner mode={TitleBannerMode.app} />
                <ButtonBar>
                    <ButtonBarButton
                        testId={TestIds.buttonbarButtonNewProject}
                        icon={IconType.plus}
                        onClick={this.newProjectButtonPressed}
                        text={I18n.t('newProject')}
                    />
                    <ButtonBarButton
                        icon={IconType.import2}
                        route={getPathForOverlayKey(Overlays.import)}
                        text={I18n.t('import')}
                    />
                    <ButtonBarButton
                        icon={IconType.help}
                        route={Routes.help}
                        text={I18n.t('help')}
                    />
                </ButtonBar>
                <ProjectList />
            </div>
        );
    }

    renderOverlays = () => {
        return (
            <OverlayManager
                overlays={{
                    [Overlays.deleteProject]:           DeleteProjectOverlay,
                    [Overlays.importSuccessNewProject]: ImportSuccessNewProjectOverlay,
                }}
            />
        );
    };
}

const mapDispatchToProps = (dispatch) => bindActionCreators(ProjectsActions, dispatch);

export default connect(
    null,
    mapDispatchToProps,
)(Screen);

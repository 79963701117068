//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import styles    from './styles.module.scss';
import PropTypes from '../PropTypes';

const Component = ({
    height = 0,
    width  = 0,
}) => {
    const getStyle = () => {
        const style = {};

        if (height) {
            style.height = height;
        }

        if (width) {
            style.width = width;
        }

        return style;
    };
    const style    = getStyle();

    return (
        <span
            className={styles.spacer}
            style={style}
        ></span>
    );
};

Component.propTypes = {
    height: PropTypes.number,
    width:  PropTypes.number,
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames from 'classnames';

import styles    from './styles.module.scss';
import Icon      from '../Icon';
import IconType  from '../Icon/IconType';
import PropTypes from '../PropTypes';
import Tooltip   from '../Tooltip';

const Component = ({
    containsErrors,
    containsWarnings,
    iconType,
    index,
    tooltipText,
    text,
    title,
}) => {
    const getTextClassForIconType = () => {
        switch (iconType) {
            // @formatter:off
        // @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-591
            case IconType.slots:
                return styles.productInformationTextIconTypeSlots;
        // @formatter:on
        }

        return null;
    };
    const renderIcon              = () => {
        if (!iconType) {
            return null;
        }

        if (tooltipText) {
            return (
                <Tooltip
                    title={title}
                    text={tooltipText}
                    iconType={iconType}
                />
            );
        }

        return (
            <div
                className={styles.productInformationIconWrapper}
                title={title}
            >
                <Icon iconType={iconType} />
            </div>
        );
    };
    const renderText              = () => {
        if (text) {
            const textClassForIconType = getTextClassForIconType();

            return (
                <span
                    className={classNames(
                        styles.productInformationText,
                        textClassForIconType,
                    )}
                >
                    {text}
                </span>
            );
        }

        return null;
    };

    return (
        <div
            className={classNames(
                styles.productInformation,
                index % 2 === 1
                    ? styles.productInformationOdd
                    : styles.productInformationEven,
                title
                    ? styles.productInformationWithHelp
                    : null,
                containsWarnings
                    ? styles.productInformationWarning
                    : null,
                containsErrors
                    ? styles.productInformationError
                    : null,
            )}
        >
            {renderIcon()}
            {renderText()}
        </div>
    );
};

Component.propTypes = {
    containsErrors:   PropTypes.bool,
    containsWarnings: PropTypes.bool,
    iconType:         PropTypes.oneOf(Object.values(IconType)),
    index:            PropTypes.number,
    text:             PropTypes.string,
    title:            PropTypes.string,
};

Component.defaultProps = {
    containsErrors:   false,
    containsWarnings: false,
    iconType:         null,
    index:            -1,
    text:             '',
    title:            '',
};

export default Component;

//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import classNames             from 'classnames';
import I18n                   from 'i18next';
import { DropTarget }         from 'react-dnd';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { compose }            from 'redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectActions } from '@slices/activeProject';
import DragTypes                from '@constants/DragTypes';

import styles                from './styles.module.scss';
import dragDropConfiguration from '../../dragDrop/delete';
import Icon                  from '../Icon';
import IconType              from '../Icon/IconType';

class Component extends React.Component {
    render() {
        return this.props.connectDropTarget(<div
            className={classNames(
                styles.headerDropZone,
                (
                    this.props.canDrop ?
                        styles.headerDropZoneCanDrop :
                        null
                ),
                (
                    this.props.isOver ?
                        styles.headerDropZoneIsOver :
                        null
                ),
            )}
        >
            {/*
                    The headerDropZoneExpand is just invisible 30px higher than its parent
                    to allow the drop action a little "earlier".

                    @see https://lulububu.atlassian.net/browse/IHSEDRACOSYDES-464
                */}
            <div className={styles.headerDropZoneExpand}>
                <div className={styles.headerDropZoneInner}>
                    <span className={styles.headerDropZoneInnerIcon}>
                        <Icon iconType={IconType.trashBin} />
                    </span>
                    <span className={styles.headerDropZoneInnerText}>
                        {I18n.t('dropZoneTextDelete')}
                    </span>
                </div>
            </div>
        </div>);
    }
}

Component.propTypes = {};

Component.defaultProps = {};

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default compose(
    connect(
        null,
        mapDispatchToProps,
    ),
    DropTarget(
        DragTypes.sort,
        dragDropConfiguration.dropBehavior,
        dragDropConfiguration.dropCollect,
    ),
)(withTranslation()(Component));

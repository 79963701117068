//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React from 'react';

import I18n                   from 'i18next';
import { withTranslation }    from 'react-i18next';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';

import { ActiveProjectTypes }   from '@slices/activeProject';
import { ActiveProjectActions } from '@slices/activeProject';
import Overlays                 from '@constants/Overlays';
import ProductTitleHelper       from '@helper/ProductTitle';
import StateHelper              from '@helper/State';

import Overlay      from '../Overlay';
import OverlayType  from '../Overlay/OverlayType';
import TextHeadline from '../TextHeadline';

const Component = ({
    selectedProduct,
    slotOverwriteContext,
    moveSlot,
    setProductSlot,
}) => {
    const okButtonPressed       = () => {
        const actionPayload = {
            ...slotOverwriteContext.actionPayload,
            confirmed: true,
        };

        switch (slotOverwriteContext.actionType) {
            // @formatter:off
            case ActiveProjectActions.moveSlot().type:        moveSlot(actionPayload);

                break;
            case ActiveProjectActions.setProductSlot().type: setProductSlot(actionPayload);

                break;
            // @formatter:on
        }
    };
    const getText               = () => {
        const actionPayload = slotOverwriteContext.actionPayload;

        switch (slotOverwriteContext.actionType) {
            // @formatter:off
            case ActiveProjectActions.moveSlot().type:        return getTextMoveSlot(actionPayload);
            case ActiveProjectActions.setProductSlot().type: return getTextSetProductSlot(actionPayload);
            // @formatter:on
        }

        return I18n.t('unknownError');
    };
    const getTextMoveSlot       = (actionPayload) => {
        const selectedSlotSubProducts        = selectedProduct.subProducts;
        const selectedSlotSubProductsForType = selectedSlotSubProducts[actionPayload.fromSlotType];
        const fromSlot                       = selectedSlotSubProductsForType[actionPayload.fromSlotIndex];
        const toSlot                         = selectedSlotSubProductsForType[actionPayload.toSlotIndex];
        const fromSlotName                   = ProductTitleHelper.getProductTitle(
            fromSlot.productData,
            fromSlot.customName,
        );
        const toSlotName                     = ProductTitleHelper.getProductTitle(
            toSlot.productData,
            toSlot.customName,
        );

        return I18n.t(
            'confirmOverwriteSlotOkTextMoveSlot',
            {
                fromSlot:      fromSlotName,
                fromSlotIndex: (
                    actionPayload.fromSlotIndex + 1
                ),
                toSlot:        toSlotName,
                toSlotIndex:   (
                    actionPayload.toSlotIndex + 1
                ),
            },
        );
    };
    const getTextSetProductSlot = (actionPayload) => {
        const selectedSlotSubProducts        = selectedProduct.subProducts;
        const selectedSlotSubProductsForType = selectedSlotSubProducts[actionPayload.targetSlotType];
        const toSlot                         = selectedSlotSubProductsForType[actionPayload.slotIndex];
        const newProductNumber               = actionPayload.productId;
        const toSlotName                     = ProductTitleHelper.getProductTitle(
            toSlot.productData,
            toSlot.customName,
        );

        return I18n.t(
            'confirmOverwriteSlotOkTextSetSlot',
            {
                toSlot:      toSlotName,
                toSlotIndex: (
                    actionPayload.slotIndex + 1
                ),
                newProductNumber,
            },
        );
    };

    if (slotOverwriteContext) {
        const text = getText();

        return (
            <Overlay
                allowDontShowAgain={true}
                id={Overlays.confirmOverwrite}
                okButtonPressed={okButtonPressed}
                okButtonTextKey={'confirmOverwriteSlotOkButton'}
                overlayType={OverlayType.prompt}
                title={I18n.t('confirmOverwriteSlotOkTitle')}
            >
                <TextHeadline text={text} />
            </Overlay>
        );
    }

    return null;
};

Component.propTypes = {};

Component.defaultProps = {};

const mapStateToProps = (state) => (
    {
        selectedProduct:      StateHelper.getSelectedProduct(state, state.activeProject.selectedProduct),
        slotOverwriteContext: state.activeProject.slotOverwriteContext,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators(ActiveProjectActions, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(Component));
